@use '../web/scss/settings' as *;

@keyframes rkt-Progress-Bar--buffer-dots {
  0% {
    transform: $rkt-progress-bar-buffer-bar-keyframes-transform;
  }
}

@keyframes rkt-Progress-Bar--buffer-dots-thin {
  0% {
    transform: $rkt-progress-bar-thin-buffer-bar-keyframes-transform;
  }
}

.rkt-ProgressBar,
.rkt-ProgressBar .MuiLinearProgress-bar2Buffer {
  background-color: $rkt-progress-bar-background-color;
  border-radius: $rkt-progress-bar-border-radius;
  height: $rkt-progress-bar-height;
}

.rkt-ProgressBar.mat-progress-bar[mode="buffer"],
.rkt-ProgressBar.mat-progress-bar[mode="buffer"] .mat-progress-bar-background,
.rkt-ProgressBar.MuiLinearProgress-buffer {
  background-color: $rkt-progress-bar-buffer-bar-background-color;
}

.rkt-ProgressBar.mat-progress-bar .mat-progress-bar-fill::after {
  border-radius: $rkt-progress-bar-border-radius;
}

.rkt-ProgressBar .mat-progress-bar .mat-progress-bar-fill::after,
.rkt-ProgressBar .mat-progress-bar-buffer,
.rkt-ProgressBar .mdc-linear-progress__buffer-bar {
  background-color: $rkt-progress-bar-track-background-color;
}

.rkt-ProgressBar .mdc-linear-progress__buffer-bar {
  border: $rkt-progress-bar-buffer-bar-border;
}

.rkt-ProgressBar .MuiLinearProgress-barColorPrimary {
  background-color: $rkt-progress-bar-inner-bar-background-color;
}

.rkt-ProgressBar.mdc-linear-progress {
  height: $rkt-progress-bar-height;
}

// Angular 16+ compatibility
.rkt-ProgressBar .mdc-linear-progress__bar.mdc-linear-progress__primary-bar,
.rkt-ProgressBar .mdc-linear-progress__bar.mdc-linear-progress__secondary-bar {
  bottom: $rkt-progress-bar-bottom;
}

.rkt-ProgressBar .mdc-linear-progress__bar-inner {
  border-top-width: $rkt-progress-bar-inner-bar-border-top-width;
  border-color: $rkt-progress-bar-inner-bar-border-color;
}

.rkt-ProgressBar .mdc-linear-progress__buffer-dots {
  background-position-y: $rkt-progress-bar-buffer-bar-background-position-y;
  background-image: $rkt-progress-bar-buffer-bar-background-image;
  background-size: $rkt-progress-bar-buffer-bar-background-size;
  animation: $rkt-progress-bar-buffer-bar-animation;

  // Angular 16+ compatibility
  --webkit-mask-image: $rkt-progress-bar-buffer-bar-webkit-mask-image;
  mask-image: $rkt-progress-bar-buffer-bar-mask-image;
  background-color: $rkt-progress-bar-buffer-bar-background-color;
}

.rkt-ProgressBar .MuiLinearProgress-dashed {
  z-index: $rkt-progress-bar-buffer-bar-z-index;
  background-position-y: $rkt-progress-bar-buffer-bar-background-position-y;
  background-image: $rkt-progress-bar-buffer-bar-background-image;
  background-size: $rkt-progress-bar-buffer-bar-background-size;
  background-repeat: $rkt-progress-bar-buffer-bar-background-repeat;
  transform: $rkt-progress-bar-buffer-bar-transform;
  animation: $rkt-progress-bar-buffer-bar-animation;
}

.rkt-ProgressBar--thin.mdc-linear-progress {
  height: $rkt-progress-bar-thin-height;

  .mdc-linear-progress__bar-inner {
    border-top-width: $rkt-progress-bar-thin-inner-bar-border-top-width;
  }

  .mdc-linear-progress__buffer-dots {
    background-size: $rkt-progress-bar-thin-buffer-bar-background-size;
    background-image: $rkt-progress-bar-thin-buffer-bar-background-image;
    animation: $rkt-progress-bar-thin-buffer-bar-animation;
  }
}

.rkt-ProgressBar--thin {
  height: $rkt-progress-bar-thin-height;

  .MuiLinearProgress-bar2Buffer {
    height: $rkt-progress-bar-thin-height;
  }

  .MuiLinearProgress-dashed {
    background-size: $rkt-progress-bar-thin-buffer-bar-background-size;
    background-image: $rkt-progress-bar-thin-buffer-bar-background-image;
    animation: $rkt-progress-bar-thin-buffer-bar-animation;
  }
}

.rkt-ProgressBar__label {
  color: $rkt-progress-bar-label-color;
  font-size: $rkt-progress-bar-label-font-size;
  font-family: $rkt-progress-bar-label-font-family;
  font-style: $rkt-progress-bar-label-font-style;
  font-weight: $rkt-progress-bar-label-font-weight;
  line-height: $rkt-progress-bar-label-line-height;
}