@use '../web/scss/settings' as *;
// BEGIN FILE UPLOADER ACTION STYLES
.rkt-FileUploaderAction {
  margin: $rkt-file-uploader-action-margin;
  @media (min-width: $rkt-split-breakpoint-5) {
    display: $rkt-file-uploader-action-breakpoint-5-display;
  }
}

.rkt-FileUploaderAction--is-always-visible {
  display: $rkt-file-uploader-action-is-always-visible-display;
}

.rkt-FileUploaderAction__item {
  // Button Reset
  box-sizing: $rkt-file-uploader-action-item-box-sizing;
  cursor: $rkt-file-uploader-action-item-cursor;
  background: $rkt-file-uploader-action-item-background;
  display: $rkt-file-uploader-action-item-display;
  flex-grow: $rkt-file-uploader-action-item-flex-grow;
  flex-direction: $rkt-file-uploader-action-item-flex-direction;
  align-items: $rkt-file-uploader-action-item-align-items;
  border-radius: $rkt-file-uploader-action-item-border-radius;
  border: $rkt-file-uploader-action-item-border;
  box-shadow: $rkt-file-uploader-action-item-box-shadow;
  padding: $rkt-file-uploader-action-item-padding;

  &:focus-visible {
    box-shadow: $rkt-file-uploader-action-item-focus-visible-box-shadow;
    outline: $rkt-file-uploader-action-item-focus-visible-outline;
  }
}

.rkt-FileUploaderAction__item--is-disabled {
  background: $rkt-file-uploader-action-item-is-disabled-background;
  cursor: $rkt-file-uploader-action-item-is-disabled-cursor;

  .rkt-FileUploaderAction__icon {
    color: $rkt-file-uploader-action-item-is-disabled-icon-color;
  }
}

.rkt-FileUploaderAction__item--is-invalid {
  box-shadow: $rkt-file-uploader-action-item-is-invalid-box-shadow;
}

.rkt-FileUploaderAction__item-text {
  font-family: $rkt-file-uploader-action-item-text-font-family;
  font-size: $rkt-file-uploader-action-item-text-font-size;
  font-weight: $rkt-file-uploader-action-item-text-font-weight;
  line-height: $rkt-file-uploader-action-item-text-line-height;
  color: $rkt-file-uploader-action-item-text-color;
}

.rkt-FileUploaderAction__item-text--is-disabled {
  color: $rkt-file-uploader-action-item-text-is-disabled-color;
}

.rkt-FileUploaderAction__icon {
  height: $rkt-file-uploader-action-icon-height;
  width: $rkt-file-uploader-action-icon-width;
  margin: $rkt-file-uploader-action-icon-margin;
  color: $rkt-file-uploader-action-icon-color;
}

.rkt-FileUploaderAction__icon--is-disabled {
  color: $rkt-file-uploader-action-icon-is-disabled-color;
}

.rkt-FileUploaderAction__instructions {
  margin: $rkt-file-uploader-action-instructions-margin;
  font-family: $rkt-file-uploader-action-instructions-font-family;
  font-size: $rkt-file-uploader-action-instructions-font-size;
  font-weight: $rkt-file-uploader-action-instructions-font-weight;
  line-height: $rkt-file-uploader-action-instructions-line-height;
  color: $rkt-file-uploader-action-instructions-color;
  text-align: $rkt-file-uploader-action-instructions-text-align;
}

// END FILE UPLOADER ACTION STYLES

// BEGIN FILE UPLOADER DROP AREA STYLES
.rkt-FileUploaderDropArea {
  border: $rkt-file-uploader-drop-area-border;
  border-radius: $rkt-file-uploader-drop-area-border-radius;
  padding: $rkt-file-uploader-drop-area-padding;
  margin: $rkt-file-uploader-drop-area-margin;
  flex-direction: $rkt-file-uploader-drop-area-flex-direction;
  align-items: $rkt-file-uploader-drop-area-align-items;
  display: $rkt-file-uploader-drop-area-display;

  @media (min-width: $rkt-split-breakpoint-5) {
    display: $rkt-file-uploader-drop-area-breakpoint-5-display;
  }
}

.rkt-FileUploaderDropArea--is-always-visible {
  display: $rkt-file-uploader-drop-area-is-always-visible-display;
}

.rkt-FileUploaderDropArea--is-dragging {
  // first linear gradient color and border color must match
  // This works by creating a gradient border background-clip
  // Then putting a dashed border. So it's actually the border
  // poking through the dashes
  border: $rkt-file-uploader-drop-area-is-dragging-border;
  background: $rkt-file-uploader-drop-area-is-dragging-background;
  cursor: $rkt-file-uploader-drop-area-is-dragging-cursor;

  .rkt-FileUploaderDropArea__icon {
    color: $rkt-file-uploader-drop-area-is-dragging-icon-color;
  }
}

.rkt-FileUploaderDropArea--is-invalid {
  border: $rkt-file-uploader-drop-area-is-invalid-border;
}

.rkt-FileUploaderDropArea--is-disabled {
  border: $rkt-file-uploader-drop-area-is-disabled-border;
  background: $rkt-file-uploader-drop-area-is-disabled-background;
  cursor: $rkt-file-uploader-drop-area-is-disabled-cursor;

  .rkt-FileUploaderDropArea__icon {
    color: $rkt-file-uploader-drop-area-is-disabled-icon-color;
  }
}

.rkt-FileUploaderDropArea__icon {
  color: $rkt-file-uploader-drop-area-icon-color;
  margin: $rkt-file-uploader-drop-area-icon-margin;
}

.rkt-FileUploaderDropArea__action {
  margin: $rkt-file-uploader-drop-area-action-margin;
  color: $rkt-file-uploader-drop-area-action-color;
  font-family: $rkt-file-uploader-drop-area-action-font-family;
  font-size: $rkt-file-uploader-drop-area-action-font-size;
  font-weight: $rkt-file-uploader-drop-area-action-font-weight;
  line-height: $rkt-file-uploader-drop-area-action-line-height;
}

.rkt-FileUploaderDropArea__action--is-disabled {
  color: $rkt-file-uploader-drop-area-action-is-disabled-color;
}

.rkt-FileUploaderDropArea__instructions {
  margin: $rkt-file-uploader-drop-area-instructions-margin;
  font-family: $rkt-file-uploader-drop-area-instructions-font-family;
  font-size: $rkt-file-uploader-drop-area-instructions-font-size;
  font-weight: $rkt-file-uploader-drop-area-instructions-font-weight;
  line-height: $rkt-file-uploader-drop-area-instructions-line-height;
  color: $rkt-file-uploader-drop-area-instructions-color;
}

.rkt-FileUploaderDropArea__instructions--is-disabled {
  color: $rkt-file-uploader-drop-area-instructions-is-disabled-color;
}

// END FILE UPLOADER DROP AREA STYLES

// BEGIN FILE UPLOADER LIST STYLES
.rkt-FileUploaderList {
  background: $rkt-file-uploader-background;
  border-radius: $rkt-file-uploader-border-radius;
  overflow: $rkt-file-uploader-overflow;
  padding: $rkt-file-uploader-padding;
  box-shadow: $rkt-file-uploader-box-shadow;
}
.rkt-FileUploaderList__content {
  list-style: $rkt-file-uploader-list-content-list-style;
  margin: $rkt-file-uploader-list-content-margin;
  padding: $rkt-file-uploader-list-content-padding;
}

// START UPLOADER LIST LAYOUT
.rkt-FileUploaderList__item {
  display: $rkt-file-uploader-list-item-display;
  grid-template-rows: $rkt-file-uploader-list-item-grid-template-rows;
  grid-template-columns: $rkt-file-uploader-list-item-grid-template-columns;
  align-items: $rkt-file-uploader-list-item-align-items;
  column-gap: $rkt-file-uploader-list-item-column-gap;
  padding: $rkt-file-uploader-list-item-padding;
  box-sizing: $rkt-file-uploader-list-item-box-sizing;
  position: $rkt-file-uploader-list-item-position;

  // Apply a bottom border (::after) only if the item has no error
  // and is not directly followed by an item with an error.
  &:not(.rkt-FileUploaderList__item--has-error):not(
      :has(+ .rkt-FileUploaderList__item--has-error)
    )::after,
  &:last-of-type::after {
    position: $rkt-file-uploader-list-item-after-position;
    bottom: $rkt-file-uploader-list-item-after-bottom;
    right: $rkt-file-uploader-list-item-after-right;
    content: '';
    height: $rkt-file-uploader-list-item-after-height;
    background: $rkt-file-uploader-list-item-after-background;
    padding: $rkt-file-uploader-list-item-after-padding;
  }

  // Apply a bottom border (::after) only if the item has no error
  // and is not directly followed by an item with an error.
  &:not(.rkt-FileUploaderList__item--has-error):not(
      :has(+ .rkt-FileUploaderList__item--has-error)
    )::after {
    width: $rkt-file-uploader-list-item-after-error-width;
  }

  @media (min-width: $rkt-file-uploader-list-680-breakpoint) {
    // Full-width bottom border for the last item in the list
    // Specificity needed to override very specific selector above
    &.rkt-FileUploaderList__item:last-of-type::after {
      width: $rkt-file-uploader-list-item-after-last-item-breakpoint-width;
      padding: $rkt-file-uploader-list-item-after-last-item-breakpoint-padding;
    }
  }
}

.rkt-FileUploaderList__item--has-error {
  border-radius: $rkt-file-uploader-list-item-error-border-radius;
  background: $rkt-file-uploader-list-item-error-background-color;
}

.rkt-FileUploaderList__item-status {
  display: $rkt-file-uploader-list-item-status-display;
}

.rkt-FileUploaderList__item-status-icon {
  color: $rkt-file-uploader-list-item-status-icon-color;
}

.rkt-FileUploaderList__item-status-icon--error {
  color: $rkt-file-uploader-list-item-status-icon-error-color;
}

.rkt-FileUploaderList__item-status-icon--in-progress {
  width: $rkt-file-uploader-list-item-status-in-progress-width;
  height: $rkt-file-uploader-list-item-status-in-progress-height;
  display: $rkt-file-uploader-list-item-status-in-progress-display;
  align-items: $rkt-file-uploader-list-item-status-in-progress-align-items;
  justify-content: $rkt-file-uploader-list-item-status-in-progress-justify-content;
}

.rkt-FileUploaderList__item-header {
  grid-row-start: $rkt-file-uploader-list-item-header-grid-row-start;
  grid-column-start: $rkt-file-uploader-list-item-header-grid-column-start;
}

.rkt-FileUploaderList__item-sub-header {
  display: $rkt-file-uploader-list-item-sub-header-display;
  justify-content: $rkt-file-uploader-list-item-sub-header-justify-content;
  grid-row-start: $rkt-file-uploader-list-item-sub-header-grid-row-start;
  grid-column-start: $rkt-file-uploader-list-item-sub-header-grid-column-start;
  gap: $rkt-file-uploader-list-item-sub-header-gap;
}

.rkt-FileUploaderList__item-actions {
  display: $rkt-file-uploader-list-item-actions-display;
  gap: $rkt-file-uploader-list-item-actions-gap;
}

@media (min-width: $rkt-file-uploader-list-680-breakpoint) {
  .rkt-FileUploaderList__item-sub-header {
    justify-content: $rkt-file-uploader-list-item-680-breakpoint-sub-header-justify-content;
  }

  .rkt-FileUploaderList__item-status,
  .rkt-FileUploaderList__item-header,
  .rkt-FileUploaderList__item-sub-header {
    grid-row-start: $rkt-file-uploader-list-item-680-breakpoint-grid-row-start;
    // Place all items in the first (and only) row //
  }

  .rkt-FileUploaderList__item-header,
  .rkt-FileUploaderList__item-sub-header {
    grid-column-start: $rkt-file-uploader-list-item-680-breakpoint-grid-column-start;
    // Reset column start to allow natural flow //
  }

  .rkt-FileUploaderList__item-size--in-progress {
    padding: $rkt-file-uploader-list-item-size-680-breakpoint-padding;
  }
}

.rkt-FileUploaderList__item-name {
  font-family: $rkt-file-uploader-list-item-name-font-family;
  font-weight: $rkt-file-uploader-list-item-name-font-weight;
  font-size: $rkt-file-uploader-list-item-name-font-size;
  color: $rkt-file-uploader-list-item-name-color;
  word-break: $rkt-file-uploader-list-item-name-word-break;
}

.rkt-FileUploaderList__item-size {
  font-family: $rkt-file-uploader-list-item-size-font-family;
  font-size: $rkt-file-uploader-list-item-size-font-size;
  line-height: $rkt-file-uploader-list-item-size-line-height;
  color: $rkt-file-uploader-list-item-size-color;
}

.rkt-FileUploaderList__error-message {
  color: $rkt-file-uploader-list-error-message-color;
  font-family: $rkt-file-uploader-list-error-message-font-family;
  font-size: $rkt-file-uploader-list-error-message-font-size;
  line-height: $rkt-file-uploader-list-error-message-line-height;
  padding: $rkt-file-uploader-list-error-message-padding;
}

.rkt-FileUploaderList__item-action {
  font-weight: $rkt-file-uploader-list-item-action-font-weight;
  line-height: $rkt-file-uploader-list-item-action-line-height;
}

.rkt-FileUploaderList__size-tally {
  display: $rkt-file-uploader-list-size-tally-display;
  align-items: $rkt-file-uploader-list-size-tally-align-items;
  justify-content: $rkt-file-uploader-list-size-tally-justify-content;
  color: $rkt-file-uploader-list-size-tally-color;

  // Caption/Medium/12 //
  font-family: $rkt-file-uploader-list-size-tally-font-family;
  font-size: $rkt-file-uploader-list-size-tally-font-size;
  font-style: $rkt-file-uploader-list-size-tally-font-style;
  font-weight: $rkt-file-uploader-list-size-tally-font-weight;
  line-height: $rkt-file-uploader-list-size-tally-line-height;

  @media (min-width: $rkt-file-uploader-list-680-breakpoint) {
     align-items: $rkt-file-uploader-list-size-tally-breakpoint-align-items;
  }
}

.rkt-FileUploaderList__size-tally--is-invalid {
  color: $rkt-file-uploader-list-size-tally-is-invalid-color;
}

.rkt-FileUploaderList__size-tally-text {
  padding: $rkt-file-uploader-list-size-tally-padding;

  @media (min-width: $rkt-file-uploader-list-680-breakpoint) {
    padding: $rkt-file-uploader-list-size-tally-breakpoint-padding;
  }
}

// END FILE UPLOADER LIST STYLES
