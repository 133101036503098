@use '../web/scss/settings' as *;

.rkt-CreditScoreChart {
  display: $rkt-credit-score-chart-display;
  flex-direction: $rkt-credit-score-chart-flex-direction;
}

.rkt-CreditScoreChart__dial {
  display: $rkt-credit-score-chart-dial-display;
  align-items: $rkt-credit-score-chart-dial-align-items;
  justify-content: $rkt-credit-score-chart-dial-justify-content;
}

.rkt-CreditScoreChart__score {
  position: $rkt-credit-score-chart-score-position;
  font-size: $rkt-credit-score-chart-score-font-size;
  font-weight: $rkt-credit-score-chart-score-font-weight;
  line-height: $rkt-credit-score-chart-score-line-height;
  font-family: $rkt-credit-score-chart-score-font-family;
  transform: $rkt-credit-score-chart-score-transform;
  color: $rkt-credit-score-chart-score-color;
  letter-spacing: $rkt-credit-score-chart-score-letter-spacing;
}

.rkt-CreditScoreChart--small .rkt-CreditScoreChart__score {
  font-size: $rkt-credit-score-chart-score-small-font-size;
  font-weight: $rkt-credit-score-chart-score-small-font-weight;
  line-height: $rkt-credit-score-chart-score-small-line-height;
  transform: $rkt-credit-score-chart-score-small-transform;
}

.rkt-CreditScoreChart__content {
  transform: $rkt-credit-score-chart-content-transform;
}

.rkt-CreditScoreChart__label-container {
  margin-bottom: $rkt-credit-score-chart-label-container-margin-bottom;
  justify-content: $rkt-credit-score-chart-label-container-justify-content;
  align-items: $rkt-credit-score-chart-label-container-align-items;
  min-height: $rkt-credit-score-chart-label-container-min-height;
}

.rkt-CreditScoreChart--small .rkt-CreditScoreChart__label-container {
  justify-content: $rkt-credit-score-chart-label-container-small-justify-content;
}

.rkt-CreditScoreChart__change-indicator {
  max-width: $rkt-credit-score-chart-change-indicator-max-width;
}

.rkt-CreditScoreChart--small .rkt-CreditScoreChart__change-indicator {
  align-items: $rkt-credit-score-chart-change-indicator-small-align-items;
  max-width: $rkt-credit-score-chart-change-indicator-small-max-width;
}

.rkt-CreditScoreChart--small .rkt-CreditScoreChart__change-indicator-text {
  flex: $rkt-credit-score-chart-change-indicator-text-flex;
  font-size: $rkt-credit-score-chart-change-indicator-text-font-size;
  font-weight: $rkt-credit-score-chart-change-indicator-text-font-weight;
  line-height: $rkt-credit-score-chart-change-indicator-text-line-height;
  font-family: $rkt-credit-score-chart-change-indicator-text-font-family;
  color: $rkt-credit-score-chart-change-indicator-text-color;
}

.rkt-CreditScoreChart__change-indicator-icon {
  margin-right: $rkt-credit-score-chart-change-indicator-icon-margin-right;
  color: $rkt-credit-score-chart-change-indicator-icon-color;
}

.rkt-CreditScoreChart__change-indicator-icon--increase {
  color: $rkt-credit-score-chart-change-indicator-icon-increase-color;
}

.rkt-CreditScoreChart__change-indicator-icon--decrease {
  color: $rkt-credit-score-chart-change-indicator-icon-decrease-color;
}

.rkt-CreditScoreChart__label,
.rkt-CreditScoreChart__status {
  font-size: $rkt-credit-score-chart-label-font-size;
  font-weight: $rkt-credit-score-chart-label-font-weight;
  line-height: $rkt-credit-score-chart-label-line-height;
  color: $rkt-credit-score-chart-label-color;
  font-family: $rkt-credit-score-chart-label-font-family;
  margin: $rkt-credit-score-chart-label-margin;
}

.rkt-CreditScoreChart__status {
  text-align: $rkt-credit-score-chart-status-text-align;
  max-width: $rkt-credit-score-chart-status-max-width;
}

.rkt-CreditScoreChart__dial-path {
  stroke: $rkt-credit-score-chart-dial-path-stroke;
  stroke-width: $rkt-credit-score-chart-dial-path-stroke-width;
  stroke-linecap: $rkt-credit-score-chart-dial-path-stroke-linecap;
}

.rkt-CreditScoreChart__dial-path--is-filled {
  stroke: $rkt-credit-score-chart-dial-path-is-filled-stroke;
}