@use '../web/scss/color';
@use '../web/scss/space';
@use '../web/scss/settings';
// Placeholder for icon styles
// .rkt-Icon {

// }

.rkt-Icon--disabled {
  color: color.$rkt-gray-400;
}

// Adds styles for
// Icons that serve as toggle controls.
.rkt-Icon--toggle,
.MuiSvgIcon-root.rkt-Icon--toggle {
  transform-origin: center;
  transition: settings.$rkt-icon-toggle-transition;
}

// Rotates Icon.
// Should be used in conjunction
// with .rkt-Icon--toggle.
.rkt-Icon--is-open,
.MuiSvgIcon-root.rkt-Icon--is-open {
  transform: settings.$rkt-icon-toggle-transform;
}
