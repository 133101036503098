@use '../web/scss/settings';

.rkt-Pagination .mat-mdc-paginator-range-label,
.rkt-Pagination .mat-mdc-icon-button:not(.mat-mdc-icon-button[disabled]),
.rkt-Pagination .mat-mdc-select-value-text,
.rkt-Pagination .mat-mdc-select-arrow,
.rkt-Pagination .mat-mdc-paginator-page-size-label,
.rkt-Pagination .MuiTablePagination-displayedRows,
.rkt-Pagination .mat-mdc-paginator-range-label {
  color: settings.$rkt-pagination-page-size-label-color;
}

.rkt-Pagination .mat-mdc-paginator-page-size-label,
.rkt-Pagination .MuiTablePagination-displayedRows,
.rkt-Pagination .mat-mdc-paginator-range-label  {
  font-family: settings.$rkt-pagination-page-size-label-font-family;
  font-size: settings.$rkt-pagination-page-size-label-font-size;
  font-weight: settings.$rkt-pagination-page-size-label-font-weight;
  line-height: settings.$rkt-pagination-page-size-label-line-height;
  margin: settings.$rkt-pagination-page-size-label-margin;
}

.rkt-Pagination .mat-mdc-icon-button .mat-ripple,
.rkt-Pagination .mat-mdc-icon-button .mat-mdc-button-persistent-ripple,
.rkt-Pagination .MuiTouchRipple-root,
.rkt-Pagination .MuiTablePagination-spacer {
  display: settings.$rkt-pagination-ripple-display;
}

.rkt-Pagination .mat-mdc-paginator-range-actions,
.rkt-Pagination__actions.MuiTablePagination-actions {
  display: settings.$rkt-pagination-actions-display;
  align-items: settings.$rkt-pagination-actions-align-items;
  justify-content: settings.$rkt-pagination-actions-justify-content;
  margin: settings.$rkt-pagination-actions-margin;
}

.rkt-Pagination .MuiTablePagination-toolbar {
  gap: settings.$rkt-pagination-toolbar-gap;
}

.rkt-Pagination .mat-mdc-paginator-container,
.rkt-Pagination .MuiTablePagination-toolbar {
  padding: settings.$rkt-pagination-container-padding;
  min-height: settings.$rkt-pagination-container-min-height;
}

.rkt-Pagination .mat-mdc-paginator-container {
  justify-content: settings.$rkt-pagination-container-justify-content;
}

.rkt-Pagination .rkt-Icon,
.rkt-Pagination .mat-mdc-icon-button .mat-mdc-paginator-icon,
.rkt-Pagination .mat-mdc-icon-button svg {
  width: settings.$rkt-pagination-icon-width;
  height: settings.$rkt-pagination-icon-height;
}

.rkt-Pagination .mat-mdc-paginator-range-label {
  margin-right: settings.$rkt-pagination-range-label-margin-right;
}

.rkt-Pagination .mat-mdc-icon-button,
.rkt-Pagination__actions .MuiIconButton-root {
  color: settings.$rkt-pagination-button-color;
  width: settings.$rkt-pagination-button-width;
  height: settings.$rkt-pagination-button-height;
  padding: settings.$rkt-pagination-button-padding;
  display: settings.$rkt-pagination-button-display;
  align-items: settings.$rkt-pagination-button-align-items;
  justify-content: settings.$rkt-pagination-button-justify-content;
  border-radius: settings.$rkt-pagination-button-border-radius;
  box-sizing: settings.$rkt-pagination-button-box-sizing;
  text-align: settings.$rkt-pagination-button-text-align;
  border: settings.$rkt-pagination-button-border;
  background-color: settings.$rkt-pagination-button-background-color;
  cursor: settings.$rkt-pagination-button-cursor;
}

.rkt-Pagination .mdc-icon-button:hover,
.rkt-Pagination__actions .MuiIconButton-root:hover {
  background-color: settings.$rkt-pagination-button-hover-background-color;
}

.rkt-Pagination .mdc-icon-button:focus-visible,
.rkt-Pagination__actions .MuiIconButton-root:focus-visible {
  background-color: settings.$rkt-pagination-button-focus-visible-background-color;
  box-shadow: settings.$rkt-pagination-button-focus-visible-box-shadow;
  outline: settings.$rkt-pagination-button-focus-visible-outline;
}

.rkt-Pagination .MuiSvgIcon-root,
.rkt-Pagination .mat-mdc-paginator-icon {
  color: settings.$rkt-pagination-icon-color;
}

.rkt-Pagination__actions .MuiIconButton-root.Mui-disabled .MuiSvgIcon-root,
.rkt-Pagination .mat-mdc-icon-button[disabled],
.rkt-Pagination .mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon {
  color: settings.$rkt-pagination-button-disabled-color;
}

// Below are Select variant styles for table, revisit with table component work
.rkt-Pagination .mat-form-field-appearance-fill .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: settings.$rkt-pagination-background-color;
}

.mat-select-panel .mat-option:hover:not(.mat-option-disabled, .mdc-list-item--disabled),
.mat-mdc-select-panel .mat-mdc-option:hover:not(.mat-option-disabled, .mdc-list-item--disabled),
.mat-mdc-select-panel .mat-mdc-option:hover:not(.mat-option-disabled, .mdc-list-item--disabled),
.rkt-Pagination__select-item.MuiTablePagination-menuItem:hover {
  background-color: settings.$rkt-pagination-select-option-background-color-hover;
}

.rkt-Pagination .MuiInputBase-root {
  border-bottom: settings.$rkt-pagination-select-bottom-border;
}
