@use '../web/scss/settings' as *;

.rkt-Banner,
.rkt-Banner.MuiAlert-root {
  border-radius: $rkt-banner-border-radius;
  border: $rkt-banner-border;
  background: $rkt-banner-background;
  box-shadow: $rkt-banner-box-shadow;
  padding: $rkt-banner-padding;
  font-family: $rkt-banner-font-family;

  // turns into grid that puts into two rows by default
  display: $rkt-banner-display;
  grid-template-columns: $rkt-banner-grid-template-columns;
  grid-template-rows: $rkt-banner-grid-template-rows;

  // Needed to set the height and width of the container for the dismiss icon button
  .MuiAlert-action {
    height: $rkt-banner-dismiss-icon-height-mui;
    width: $rkt-banner-dismiss-icon-width-mui;
  }

  @media (min-width: $rkt-banner-medium-breakpoint) {
    .rkt-Banner__action {
      grid-column: 3 / 4;
      display: $rkt-banner-action-medium-display;
      align-items: $rkt-banner-action-medium-align-items;
      justify-content: $rkt-banner-action-medium-justify-content;
    }

    .rkt-Banner__icon-indicator {
      display: $rkt-banner-icon-indicator-medium-display;
      align-self: $rkt-banner-icon-indicator-medium-align-self;
      height: $rkt-banner-icon-indicator-medium-height;
      width: $rkt-banner-icon-indicator-medium-width;
      margin: $rkt-banner-icon-indicator-medium-margin;
    }

    .rkt-Banner__icon-indicator svg path,
    .rkt-Banner__icon-indicator use {
      stroke-width: $rkt-banner-icon-indicator-stroke-width;
    }

    .rkt-Banner__icon-button {
      display: $rkt-banner-icon-button-display;
      align-self: $rkt-banner-icon-button-align-self;
    }

    // For dismiss icon in MUI
    .MuiAlert-action {
      align-self: $rkt-banner-dismiss-icon-align-self-mui;
      padding: $rkt-banner-dismiss-icon-medium-padding;
    }
  }

  // one row on larger viewports
  @media (min-width: $rkt-banner-wide-breakpoint) {
    grid-template-columns: $rkt-banner-grid-template-columns-wide-breakpoint;
    grid-template-rows: $rkt-banner-grid-template-rows-wide-breakpoint;
    padding: $rkt-banner-wide-padding;

    .rkt-Banner__icon-indicator {
      margin: $rkt-banner-icon-indicator-wide-margin;
    }

    .rkt-Banner__action {
      .rkt-Button.mat-mdc-button-base,
      .MuiButtonBase-root  {
        padding: $rkt-banner-action-wide-content-padding;
      }
    }

    .rkt-Banner__dismiss-icon {
      grid-column: 4 / 5;
    }

    .rkt-Banner__text {
      font-size: $rkt-banner-text-wide-font-size;
      line-height: $rkt-banner-text-wide-line-height;
    }
  }
}

// specificity required for ng Material
.rkt-Banner__icon-indicator {
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  margin: $rkt-banner-icon-indicator-margin;
  height: $rkt-banner-icon-indicator-height;
  width: $rkt-banner-icon-indicator-width;
  color: $rkt-banner-icon-indicator-color;
}

.rkt-Banner__content {
  display: $rkt-banner-content-display;
  grid-column: 2 / 3;
  grid-row: 1 / 2;
  margin: $rkt-banner-content-margin;

  @media (min-width: $rkt-banner-medium-breakpoint) {
    margin: $rkt-banner-content-medium-margin;
    align-items: $rkt-banner-content-medium-align-items;
  }
}

.rkt-Banner .MuiAlert-message,
.rkt-Banner .MuiAlert-icon {
  margin: $rkt-banner-content-margin-mui;
  padding: $rkt-banner-content-padding-mui;

  @media (min-width: $rkt-banner-medium-breakpoint) {
    align-self: $rkt-banner-icon-button-align-self;
  }
}

.rkt-Banner .MuiAlert-icon {
  opacity: $rkt-banner-icon-indicator-opacity;
}

.rkt-Banner__action {
  margin: $rkt-banner-action-margin;

  .rkt-Button.mat-mdc-button-base,
  .MuiButtonBase-root  {
    padding: $rkt-banner-action-content-padding;
    height: $rkt-banner-action-height;
    width: $rkt-banner-action-width;
  }

  @media (min-width: $rkt-banner-medium-breakpoint) {
    margin: $rkt-banner-action-medium-margin;
  }
}

// For closing banner
.rkt-Banner__icon-button {
  grid-column: 4 / 4;
  grid-row: 1 / 2;
  cursor: $rkt-banner-icon-button-cursor;
  background-color: $rkt-banner-icon-button-background-color;
  border: $rkt-banner-icon-button-border;
  padding: $rkt-banner-icon-button-padding;
  border-radius: $rkt-banner-icon-button-border-radius;
  height: $rkt-banner-icon-button-height;
  width: $rkt-banner-icon-button-width;
  display: $rkt-banner-icon-button-display;
  justify-content: $rkt-banner-icon-button-justify-content;
  align-items: $rkt-banner-icon-button-align-items;

  &:hover {
    background: $rkt-banner-icon-button-hover-background;
  }
  &:focus-visible{
    box-shadow: $rkt-banner-icon-button-focus-box-shadow;
    background: $rkt-banner-icon-button-focus-background;
    outline: $rkt-banner-icon-button-focus-outline;
  }
  &:active {
    background: $rkt-banner-icon-button-active-background;
  }
}

// specificity required for ng Material
.rkt-Banner .rkt-Banner__dismiss-icon {
  height: $rkt-banner-dismiss-icon-height;
  width: $rkt-banner-dismiss-icon-width;
  color: $rkt-banner-dismiss-icon-color;
  margin: $rkt-banner-dismiss-icon-margin;
}

.rkt-Banner__text {
  color: $rkt-banner-text-color;
  margin: $rkt-banner-text-margin;

  /* Body/Medium/16 */
  font-family: $rkt-banner-text-font-family;
  font-size: $rkt-banner-text-font-size;
  font-style: $rkt-banner-text-font-style;
  font-weight: $rkt-banner-text-font-weight;
  line-height: $rkt-banner-text-line-height;

  // These to center align the text with the variant icon and dismiss icon (on small viewports)
  min-height: $rkt-banner-text-min-height;
  display: $rkt-banner-text-display;
  align-items: $rkt-banner-text-align-items;
}

.rkt-Banner--is-hidden,
.rkt-Banner--is-hidden.MuiAlert-root {
  display: $rkt-banner-display-hidden;
}

.rkt-Banner--success,
.rkt-Banner--success.MuiAlert-root {
  background: $rkt-banner-success-background;
}

.rkt-Banner--info,
.rkt-Banner--info.MuiAlert-root {
  background: $rkt-banner-info-background;
}

.rkt-Banner--warn,
.rkt-Banner--warn.MuiAlert-root {
  background: $rkt-banner-warn-background;
}

.rkt-Banner--white,
.rkt-Banner--white.MuiAlert-root {
  background: $rkt-banner-white-background;
}

.rkt-Banner--primary,
.rkt-Banner--primary.MuiAlert-root {
  background: $rkt-banner-primary-background;

  .rkt-Banner__text {
    color: $rkt-banner-primary-text-color;
  }

  .rkt-Banner__icon-indicator {
    color: $rkt-banner-primary-icon-indicator-color;
  }

  .rkt-Banner__action {
    .rkt-Button.mat-mdc-button-base,
    .MuiButtonBase-root  {
      color: $rkt-banner-primary-action-color;
      background-color: $rkt-banner-primary-action-background;

      &:hover {
        color: $rkt-banner-primary-action-hover-color;
        background-color: $rkt-banner-primary-action-hover-background;
      }

      &:focus-visible {
        box-shadow: $rkt-banner-primary-action-focus-visible-box-shadow;
      }

      &:active {
        color: $rkt-banner-primary-action-active-color;
        background-color: $rkt-banner-primary-action-active-background-color;
      }

      &:disabled {
        color: $rkt-banner-primary-action-disabled-color;
        background: $rkt-banner-primary-action-disabled-background;
      }
    }
  }

  // Angular
  .rkt-Banner__dismiss-icon {
    color: $rkt-banner-primary-dismiss-color;
  }

  .rkt-Banner__icon-button {
    &:hover {
      background-color: $rkt-banner-primary-dismiss-hover-background-color;
    }
    
    &:focus-visible {
      box-shadow: $rkt-banner-primary-dismiss-focus-visible-box-shadow;
      background-color: $rkt-banner-primary-dismiss-focus-visible-background-color;
    }

    &:active {
      background-color: $rkt-banner-primary-dismiss-active-background-color;
    }
  }

  // React
  .MuiAlert-action .rkt-Banner__icon-button {
    color: $rkt-banner-primary-dismiss-color;

    &:hover {
      background-color: $rkt-banner-primary-dismiss-hover-background-color;
    }
    
    &:focus-visible {
      box-shadow: $rkt-banner-primary-dismiss-focus-visible-box-shadow;
      background-color: $rkt-banner-primary-dismiss-focus-visible-background-color;
    }

    &:active {
      background-color: $rkt-banner-primary-dismiss-active-background-color;
    }
  }
}

.rkt-Banner--secondary,
.rkt-Banner--secondary.MuiAlert-root {
  background: $rkt-banner-secondary-background;

  .rkt-Banner__text {
    color: $rkt-banner-secondary-text-color;
  }

  .rkt-Banner__icon-indicator {
    color: $rkt-banner-secondary-icon-indicator-color;
  }

  .rkt-Banner__action {
    .rkt-Button.mat-mdc-button-base,
    .MuiButtonBase-root  {
      color: $rkt-banner-secondary-action-color;
      background-color: $rkt-banner-secondary-action-background;

      &:hover {
        color: $rkt-banner-secondary-action-hover-color;
        background-color: $rkt-banner-secondary-action-hover-background;
      }
      
      &:focus-visible {
        box-shadow: $rkt-banner-secondary-action-focus-visible-box-shadow;
      }
      
      &:active {
        color: $rkt-banner-secondary-action-active-color;
        background-color: $rkt-banner-secondary-action-active-background-color;
      }

      &:disabled {
        color: $rkt-banner-secondary-action-disabled-color;
        background: $rkt-banner-secondary-action-disabled-background;
      }
    }
  }

  // Angular
  .rkt-Banner__dismiss-icon {
    color: $rkt-banner-secondary-dismiss-color;
  }
  
  .rkt-Banner__icon-button {
    &:hover {
      background-color: $rkt-banner-secondary-dismiss-hover-background-color;
    }
    
    &:focus-visible{
      box-shadow: $rkt-banner-secondary-dismiss-focus-visible-box-shadow;
      background-color: $rkt-banner-secondary-dismiss-focus-visible-background-color;
    }
    
    &:active {
      background-color: $rkt-banner-secondary-dismiss-active-background-color;
    }
  }

  // React
  .MuiAlert-action .rkt-Banner__icon-button {
    color: $rkt-banner-secondary-dismiss-color;

    &:hover {
      background-color: $rkt-banner-secondary-dismiss-hover-background-color;
    }
    
    &:focus-visible{
      box-shadow: $rkt-banner-secondary-dismiss-focus-visible-box-shadow;
      background-color: $rkt-banner-secondary-dismiss-focus-visible-background-color;
    }
    
    &:active {
      background-color: $rkt-banner-secondary-dismiss-active-background-color;
    }
  }
}

// React MUI Specific dismiss classes
.rkt-Banner .MuiAlert-action {
  margin: $rkt-banner-dismiss-icon-margin;
  .MuiTouchRipple-root {
    display: $rkt-banner-dismiss-icon-display-mui;
  }
}
