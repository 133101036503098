@use '../web/scss/settings';

// React background-color set here
.rkt-Tooltip {
  background-color: settings.$rkt-tooltip-background-color;
  font-weight: settings.$rkt-tooltip-font-weight;
  font-size: settings.$rkt-tooltip-font-size;
}

// Padding only needed in React, it causes a bug if set in Angular
.rkt-Tooltip.MuiTooltip-tooltip {
  padding: settings.$rkt-tooltip-padding;
  border-radius: settings.$rkt-tooltip-border-radius;
  display: settings.$rkt-tooltip-display;
}

// Make React tooltip spacing match Angular
.rkt-Tooltip.MuiTooltip-tooltipPlacementTop.MuiTooltip-tooltip  {
  margin-bottom: settings.$rkt-tooltip-placement-margin;
}

.rkt-Tooltip.MuiTooltip-tooltipPlacementBottom.MuiTooltip-tooltip {
  margin-top: settings.$rkt-tooltip-placement-margin;
}

.rkt-Tooltip.MuiTooltip-tooltipPlacementRight.MuiTooltip-tooltip {
  margin-left: settings.$rkt-tooltip-placement-margin;
}

.rkt-Tooltip.MuiTooltip-tooltipPlacementLeft.MuiTooltip-tooltip {
  margin-right: settings.$rkt-tooltip-placement-margin;
}

// Angular background-color unset so there
// is not an extra square behind tooltip
.rkt-Tooltip.mat-mdc-tooltip {
  background-color: unset;
}

// Angular background-color set here
.rkt-Tooltip.mat-mdc-tooltip .mdc-tooltip__surface {
  background-color: settings.$rkt-tooltip-background-color;
  border-radius: settings.$rkt-tooltip-border-radius;
  padding: settings.$rkt-tooltip-padding;
  font-size: settings.$rkt-tooltip-font-size;
  font-style: settings.$rkt-tooltip-font-style;
  font-weight: settings.$rkt-tooltip-font-weight;
  line-height: settings.$rkt-tooltip-line-height;
  color: settings.$rkt-tooltip-color;
}

// Angular tooltip spacing
.rkt-Tooltip--above.mat-mdc-tooltip {
  top: settings.$rkt-tooltip-top;
}

.rkt-Tooltip--below.mat-mdc-tooltip {
  bottom: settings.$rkt-tooltip-bottom;
}

.rkt-Tooltip--in-table.rkt-Tooltip--below.mat-mdc-tooltip {
  bottom: settings.$rkt-tooltip-in-table-bottom;
}

.rkt-Tooltip--left.mat-mdc-tooltip {
  left: settings.$rkt-tooltip-left;
}

.rkt-Tooltip--right.mat-mdc-tooltip {
  right: settings.$rkt-tooltip-right;
}

.rkt-TooltipPopper {
  box-sizing: settings.$rkt-tooltip-popper-box-sizing;
  color: settings.$rkt-tooltip-popper-color;
  border-radius: settings.$rkt-tooltip-popper-border-radius;
  flex-shrink: settings.$rkt-tooltip-popper-flex-shrink;
  padding: settings.$rkt-tooltip-popper-padding;
}

.rkt-TooltipPopper .MuiTouchRipple-root {
  display: settings.$rkt-tooltip-popper-ripple-display;
}

.rkt-TooltipPopper:hover {
  background-color: settings.$rkt-tooltip-popper-background-color-hover;
  cursor: settings.$rkt-tooltip-popper-cursor-hover;
}

.rkt-TooltipPopper:active,
.rkt-TooltipPopper:focus,
.rkt-TooltipPopper.cdk-keyboard-focused {
  outline: settings.$rkt-tooltip-popper-focus-visible-outline;
  background-color: settings.$rkt-tooltip-popper-background-color-focus;
}

.rkt-Tooltip--data,
.rkt-Tooltip--data.mat-mdc-tooltip .mdc-tooltip__surface {
  background-color: settings.$rkt-tooltip-data-background-color;
  color: settings.$rkt-tooltip-data-color;
}

// This is used in React
.rkt-Tooltip__title {
  color: settings.$rkt-tooltip-color;
  font-family: settings.$rkt-tooltip-font-family;
  line-height: settings.$rkt-tooltip-line-height;
  font-style: settings.$rkt-tooltip-font-style;
}

.rkt-Tooltip--data .rkt-Tooltip__title,
.rkt-Tooltip--on-dark .rkt-Tooltip__title {
  color: settings.$rkt-tooltip-data-color;
}

.rkt-Tooltip--on-dark,
.rkt-Tooltip--on-dark.mat-mdc-tooltip .mdc-tooltip__surface {
  background-color: settings.$rkt-tooltip-on-dark-background-color;
  color: settings.$rkt-tooltip-on-dark-color;
}

.rkt-Tooltip--data.mat-mdc-tooltip .mdc-tooltip__surface,
.rkt-Tooltip--data.MuiTooltip-tooltip {
  box-shadow: settings.$rkt-tooltip-data-box-shadow;
}

.rkt-Tooltip--on-dark.mat-mdc-tooltip .mdc-tooltip__surface,
.rkt-Tooltip--on-dark.MuiTooltip-tooltip {
  box-shadow: settings.$rkt-tooltip-on-dark-box-shadow;
}

.rkt-TooltipPopper--on-dark {
  color: settings.$rkt-tooltip-popper-on-dark-color;

  &:hover {
    background-color: settings.$rkt-tooltip-popper-on-dark-hover-background-color;
  }

  &:active,
  &:focus {
    background-color: settings.$rkt-tooltip-popper-on-dark-focus-background-color;
  }
}

.rkt-TooltipPopper--on-dark:focus .MuiTouchRipple-root .MuiTouchRipple-child {
  background-color: settings.$rkt-tooltip-popper-on-dark-focus-background-color;
}

