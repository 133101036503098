$rkt-spacing-0: 0 !default;
$rkt-spacing-4: 4px !default;
$rkt-spacing-8: 8px !default;
$rkt-spacing-12: 12px !default;
$rkt-spacing-16: 16px !default;
$rkt-spacing-20: 20px !default;
$rkt-spacing-24: 24px !default;
$rkt-spacing-28: 28px !default;
$rkt-spacing-32: 32px !default;
$rkt-spacing-36: 36px !default;
$rkt-spacing-40: 40px !default;
$rkt-spacing-44: 44px !default;
$rkt-spacing-48: 48px !default;
$rkt-spacing-52: 52px !default;
$rkt-spacing-56: 56px !default;
$rkt-spacing-60: 60px !default;
$rkt-spacing-64: 64px !default;
$rkt-spacing-68: 68px !default;
$rkt-spacing-72: 72px !default;
$rkt-spacing-76: 76px !default;
$rkt-spacing-80: 80px !default;