@use '../web/scss/settings';

.rkt-Sidenav,
.rkt-Sidenav-bar {
  background-color: settings.$rkt-sidenav-background-color;
}

.rkt-Sidenav-container {
  flex-direction: settings.$rkt-sidenav-container-flex-direction;

  @media screen and (min-width: settings.$rkt-sidenav-breakpoint) {
    flex-direction: settings.$rkt-sidenav-container-flex-direction-large;
  }
}

.rkt-Sidenav .mat-drawer-backdrop {
  position: settings.$rkt-sidenav-backdrop-position;
}

.rkt-Sidenav-bar {
  box-shadow: settings.$rkt-sidenav-bar-box-shadow;
  position: settings.$rkt-sidenav-bar-position;
  top: settings.$rkt-sidenav-bar-top;
  transition: settings.$rkt-sidenav-bar-transition;
  z-index: settings.$rkt-sidenav-bar-layer;
  min-height: settings.$rkt-sidenav-bar-min-height;
  display: settings.$rkt-sidenav-bar-display;

  @media screen and (min-width: settings.$rkt-sidenav-breakpoint) {
    display: settings.$rkt-sidenav-bar-display-large;
  }
}

.rkt-Sidenav__drawer > .MuiPaper-root {
  border-right: settings.$rkt-sidenav-drawer-border-right;
  width: settings.$rkt-sidenav-drawer-width;
  box-shadow: settings.$rkt-sidenav-drawer-box-shadow;
}

.rkt-Sidenav__drawer {
  border-right: settings.$rkt-sidenav-drawer-border-right;
  width: settings.$rkt-sidenav-drawer-width;
  flex-shrink: settings.$rkt-sidenav-drawer-flex-shrink;
}

.rkt-Sidenav__drawer--pop-over,
.rkt-Sidenav--pop-over,
.rkt-Sidenav__drawer.mat-drawer.rkt-Sidenav__drawer--pop-over.mat-sidenav-fixed,
.rkt-Sidenav--pop-over.mat-drawer-container {
  display: settings.$rkt-sidenav-drawer-popover-display;

  @media screen and (min-width: settings.$rkt-sidenav-breakpoint) {
    display: settings.$rkt-sidenav-drawer-popover-display-large;
  }
}

.rkt-Sidenav__drawer--fixed,
.rkt-Sidenav__drawer--fixed.mat-sidenav-fixed,
.rkt-Sidenav--fixed,
.rkt-Sidenav--fixed.mat-drawer-container {
  display: settings.$rkt-sidenav-drawer-fixed-display;

  @media screen and (min-width: settings.$rkt-sidenav-breakpoint) {
    display: settings.$rkt-sidenav-drawer-fixed-display-large;
  }
}

.rkt-Sidenav__drawer .MuiToolbar-root {
  min-height: settings.$rkt-sidenav-react-toolbar-min-height;
}

.rkt-Sidenav__drawer.mat-drawer {
  box-shadow: settings.$rkt-sidenav-drawer-box-shadow;
}

.rkt-Sidenav__client-details {
  max-width: settings.$rkt-sidenav-client-details-max-width;
}

.rkt-Sidenav__input {
  padding-top: settings.$rkt-sidenav-input-padding-top;
}

.rkt-Sidenav__logo {
  display: settings.$rkt-sidenav-logo-display;
  max-width: settings.$rkt-sidenav-logo-max-width;
  min-width: settings.$rkt-sidenav-logo-min-width;
  max-height:settings.$rkt-sidenav-logo-max-height;
}

.rkt-Sidenav__rewards,
.rkt-Sidenav__rewards.MuiButton-root,
// @deprecated selector in v4, remove in v6 - task #3552468
.rkt-Sidenav__rewards.mat-stroked-button,
.rkt-Sidenav__rewards.mat-mdc-outlined-button.rkt-Button {
  border-color: settings.$rkt-sidenav-rewards-border-color;
  border-radius: settings.$rkt-sidenav-rewards-border-radius;
  padding: settings.$rkt-sidenav-rewards-padding;
  text-align: settings.$rkt-sidenav-rewards-text-align;
  width: settings.$rkt-sidenav-rewards-width;

  &:hover {
    border-color: settings.$rkt-sidenav-rewards-border-color-hover;
    background-color: settings.$rkt-sidenav-rewards-background-color-hover;
  }
}

.rkt-Sidenav__rewards.mat-mdc-outlined-button.rkt-Button {
  height: settings.$rkt-sidenav-rewards-height;
}

.rkt-Sidenav__rewards .mdc-button__label {
  align-items: settings.$rkt-sidenav-rewards-align-items;
  display: settings.$rkt-sidenav-rewards-display;
  flex: settings.$rkt-sidenav-rewards-flex;
}

// Adds font styles to the links when they are in expansion panels
.rkt-Sidenav .rkt-AccordionPanel .mat-expansion-panel-header-title,
// Adds font styles to the links when they are not in expansion panels
.rkt-Sidenav__item-link {
  font-family: settings.$rkt-sidenav-item-link-font-family;
  font-size: settings.$rkt-sidenav-item-link-font-size;
  font-weight: settings.$rkt-sidenav-item-link-font-weight;
  color: settings.$rkt-sidenav-item-link-color;
  line-height: settings.$rkt-sidenav-item-link-line-height;
}

.rkt-Sidenav__item-link {
  border-radius: settings.$rkt-sidenav-item-link-border-radius;
  padding: settings.$rkt-sidenav-item-link-header-padding;
  align-items: settings.$rkt-sidenav-item-link-align-items;
}

.rkt-Sidenav__item-link:hover {
  background-color: settings.$rkt-sidenav-item-link-hover-background-color;
}

// Angular only - keeps icon to left of text from shrinking when text wraps
// in "WITHOUT EXPANSION PANELS" variant
.rkt-Sidenav__item-link .mat-icon {
  flex-shrink: settings.$rkt-sidenav-item-icon-flex-shrink;
}

.rkt-Sidenav__icon {
  color: settings.$rkt-sidenav-icon-color;
}

.rkt-Sidenav__item-link--is-active  {
  background-color: settings.$rkt-sidenav-item-link-active-background-color;
}

.rkt-Sidenav__item-link--is-active .rkt-Sidenav__icon {
  color: settings.$rkt-sidenav-item-link-active-icon-color;
}

.rkt-Sidenav__section-heading {
  color: settings.$rkt-sidenav-section-heading-color;
  font-family: settings.$rkt-sidenav-section-heading-font-family;
  font-size: settings.$rkt-sidenav-section-heading-font-size;
  font-weight: settings.$rkt-sidenav-section-heading-font-weight;
  line-height: settings.$rkt-sidenav-section-heading-line-height;
  letter-spacing: settings.$rkt-sidenav-section-heading-letter-spacing;
  text-transform: settings.$rkt-sidenav-section-heading-text-transform;
  margin: settings.$rkt-sidenav-section-heading-margin;
}

.rkt-Sidenav__section-description {
  color: settings.$rkt-sidenav-section-description-color;
  font-family: settings.$rkt-sidenav-section-description-font-family;
  font-size: settings.$rkt-sidenav-section-description-font-size;
  font-weight: settings.$rkt-sidenav-section-description-font-weight;
  line-height: settings.$rkt-sidenav-section-description-line-height;
  margin: settings.$rkt-sidenav-section-description-margin;
}

.rkt-Sidenav__subtext {
  color: settings.$rkt-sidenav-subtext-color;
  font-family: settings.$rkt-sidenav-subtext-font-family;
  font-size: settings.$rkt-sidenav-subtext-font-size;
  font-weight: settings.$rkt-sidenav-subtext-font-weight;
  line-height: settings.$rkt-sidenav-subtext-line-height;
  margin: settings.$rkt-sidenav-subtext-margin;
}