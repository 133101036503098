@use './color' as *;
@use './color-dark' as *;
@use './typography' as *;
@use './backdrop' as *;
$rkt-tag-text-color-light: $rkt-white !default;
$rkt-tag-text-color-warning: $rkt-yellow-900 !default;
$rkt-tag-text-color-info: $rkt-blue-700 !default;
$rkt-tag-text-color-success: $rkt-green-700 !default;
$rkt-tag-text-color-primary: $rkt-white !default;
$rkt-tag-text-color-secondary: $rkt-white !default;
$rkt-tag-text-font-size: $rkt-typography-font-size-label-14 !default;
$rkt-tag-text-font-weight: $rkt-typography-font-weight-label-14 !default;
$rkt-tag-text-line-height: 14px !default;
$rkt-tag-text-color: $rkt-gray-800 !default;
$rkt-tag-text-font-family: $rkt-typography-font-family-label-14 !default;
$rkt-tag-background-color: $rkt-gray-100 !default;
$rkt-tag-display: inline-flex !default;
$rkt-tag-align-items: center !default;
$rkt-tag-justify-content: center !default;
$rkt-tag-box-sizing: border-box !default;
$rkt-tag-border-radius: 8px !default;
$rkt-tag-height: 28px !default;
$rkt-tag-min-width: 49px !default;
$rkt-tag-padding: 0 10px !default;
$rkt-tag-has-icon-left-padding-left: 8px !default;
$rkt-tag-has-icon-right-padding-right: 8px !default;
$rkt-tag-icon-color: $rkt-gray-800 !default;
$rkt-tag-icon-color-warning: $rkt-yellow-900 !default;
$rkt-tag-icon-color-info: $rkt-blue-700 !default;
$rkt-tag-icon-color-success: $rkt-green-700 !default;
$rkt-tag-icon-color-light: $rkt-white !default;
$rkt-tag-icon-color-primary: $rkt-white !default;
$rkt-tag-icon-color-secondary: $rkt-white !default;
$rkt-tag-icon-stroke-width: 2.5 !default;
$rkt-tag-bg-color-info: $rkt-blue-50 !default;
$rkt-tag-bg-color-secondary: $rkt-red-500 !default;
$rkt-tag-bg-color-success: $rkt-green-50 !default;
$rkt-tag-bg-color-warning: $rkt-yellow-50 !default;
$rkt-tag-bg-color-light: $rkt-white-opacity-300 !default;
$rkt-tag-bg-color-primary: $rkt-gray-800 !default;
$rkt-tag-icon-width: 16px !default;
$rkt-tag-icon-height: 16px !default;
$rkt-tag-has-icon-right-text-margin-right: 4px !default;
$rkt-tag-has-icon-left-icon-margin-right: 4px !default;