@use '../web/scss/settings';

.rkt-ButtonLink {
  box-sizing: settings.$rkt-button-link-box-sizing;
  cursor: settings.$rkt-button-link-cursor;
  background-color: settings.$rkt-button-link-background-color;
  padding: settings.$rkt-button-link-padding;
  border: settings.$rkt-button-link-border;
  align-items: settings.$rkt-button-link-align-items;
  display: settings.$rkt-button-link-display;
  text-decoration: settings.$rkt-button-link-text-decoration;

  &:focus-visible {
    border-radius: settings.$rkt-button-link-border-radius;
    outline: settings.$rkt-button-link-focus-visible-outline;
  }

  &:hover {
    .rkt-ButtonLink__text {
      color: settings.$rkt-button-link-hover-text-color;
    }

    .rkt-ButtonLink__icon-container::after {
      width: settings.$rkt-button-link-icon-container-hover-width;
      height: settings.$rkt-button-link-icon-container-hover-height;
    }
  }

  &:active {
    color: settings.$rkt-button-link-active-color;

    .rkt-ButtonLink__icon-container::after {
      background: settings.$rkt-button-link-active-icon-background;
    }
  }

  &:visited .rkt-ButtonLink__text {
    color: settings.$rkt-button-link-text-visited-color;
    text-decoration: settings.$rkt-button-link-text-decoration;
  }
}

.rkt-ButtonLink__text {
  font-family: settings.$rkt-button-link-text-font-family;
  font-size: settings.$rkt-button-link-text-font-size;
  font-style: settings.$rkt-button-link-text-font-style;
  font-weight: settings.$rkt-button-link-text-font-weight;
  line-height: settings.$rkt-button-link-text-line-height;
  margin: settings.$rkt-button-link-text-margin;
  color: settings.$rkt-button-link-text-color;
}

.rkt-ButtonLink__icon-container {
  border-radius: settings.$rkt-button-link-icon-container-border-radius;
  width: settings.$rkt-button-link-icon-container-width;
  height: settings.$rkt-button-link-icon-container-height;
  display: settings.$rkt-button-link-icon-container-display;
  justify-content: settings.$rkt-button-link-icon-container-justify-content;
  align-items: settings.$rkt-button-link-icon-container-align-items;

  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    width: settings.$rkt-button-link-icon-container-width;
    height: settings.$rkt-button-link-icon-container-height;
    background: settings.$rkt-button-link-icon-container-background;
    border-radius: settings.$rkt-button-link-icon-container-border-radius;
  }
}

.rkt-ButtonLink__icon {
  color: settings.$rkt-button-link-icon-color;
  fill: settings.$rkt-button-link-icon-fill;

  &.MuiSvgIcon-root,
  &.mat-icon {
    width: settings.$rkt-button-link-icon-size-width;
    height: settings.$rkt-button-link-icon-size-height;
  }
}

.rkt-ButtonLink--is-small {
  padding: settings.$rkt-button-link-small-padding;

  .rkt-ButtonLink__text {
    margin: settings.$rkt-button-link-small-text-margin;
    font-size: settings.$rkt-button-link-small-text-font-size;
    line-height: settings.$rkt-button-link-small-text-line-height;
  }

  .rkt-ButtonLink__icon-container,
  .rkt-ButtonLink__icon-container::after {
    width: settings.$rkt-button-link-small-icon-container-width;
    height: settings.$rkt-button-link-small-icon-container-height;
  }

  &:hover .rkt-ButtonLink__icon-container::after {
    width: settings.$rkt-button-link-small-icon-container-hover-width;
    height: settings.$rkt-button-link-small-icon-container-hover-height;
  }

  &.rkt-ButtonLink--has-icon-left .rkt-ButtonLink__text {
    margin: settings.$rkt-button-link-small-text-icon-left-margin;
  }
}

.rkt-ButtonLink--has-icon-left {
  padding: settings.$rkt-button-link-left-padding;

  .rkt-ButtonLink__text {
    margin: settings.$rkt-button-link-left-text-margin;
  }
}
