@use '../web/scss/settings';

.rkt-BottomSheet .mat-bottom-sheet-container,
.rkt-BottomSheet {
  border-top-right-radius: settings.$rkt-bottom-sheet-border-top-right-radius;
  border-top-left-radius: settings.$rkt-bottom-sheet-border-top-left-radius;
  margin-right: settings.$rkt-bottom-sheet-margin-right;
  margin-left: settings.$rkt-bottom-sheet-margin-left;
}

.rkt-BottomSheet .mat-bottom-sheet-container {
  padding: settings.$rkt-bottom-sheet-padding;
}

.rkt-BottomSheet .rkt-List, .rkt-BottomSheet .rkt-List.mdc-list {
  margin: settings.$rkt-bottom-sheet-list-margin;
}

.rkt-BottomSheet .rkt-List__item,
.rkt-BottomSheet .rkt-List .rkt-List__item.mdc-list-item,
.rkt-BottomSheet .rkt-List__button,
.rkt-BottomSheet .rkt-List__button.mat-mdc-list-item,
.rkt-BottomSheet .rkt-List__button.mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines,
.rkt-BottomSheet .rkt-List__button.mdc-list-item.mdc-list-item--with-two-lines,
.rkt-BottomSheet .rkt-List__button.mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  max-width: settings.$rkt-bottom-sheet-button-max-width;
}

// Preserves shadow in react
.rkt-BottomSheet.MuiList-root {
  box-shadow: settings.$rkt-bottom-sheet-box-shadow;
}

// @deprecated in v4, removal in v6 task #3530308
.rkt-BottomSheet .mat-list-base {
  padding: 0;
}

// @deprecated in v4, removal in v6 task #3530308
.rkt-BottomSheet .mat-list-option:hover,
// @deprecated in v4, removal in v6 task #3530308
.rkt-BottomSheet .mat-list-option:focus,
.rkt-BottomSheet .mat-mdc-list-item:hover,
.rkt-BottomSheet .mat-mdc-list-item:focus,
// @deprecated in v4, removal in v6 task #3530308
.mat-nav-list .mat-list-item:hover,
.rkt-BottomSheet .mat-nav-list .mat-list-item:focus,
.rkt-BottomSheet .mat-action-list .mat-list-item:hover,
.rkt-BottomSheet .mat-action-list .mat-list-item:focus,
.rkt-BottomSheet .MuiListItem-root:hover:not(.Mui-disabled),
.rkt-BottomSheet .MuiListItem-root:focus:not(.Mui-disabled) {
  background-color: settings.$rkt-bottom-sheet-item-hover-background-color;
}

// @deprecated in v4, removal in v6 task #3530308
// now uses rkt-List__item-icon
.rkt-BottomSheet__icon {
  margin: settings.$rkt-bottom-sheet-icon-margin;
}

// @deprecated in v4, removal in v6 task #3530308
// now uses rkt-List__item-title
.rkt-BottomSheet .mat-list-text .mat-line.rkt-Body-0,
.rkt-BottomSheet .mat-list-text .mat-line.rkt-Label-16 {
  color: settings.$rkt-bottom-sheet-item-label-color;
  font-family: settings.$rkt-bottom-sheet-item-label-font-family;
  font-size: settings.$rkt-bottom-sheet-item-label-font-size;
  font-weight: settings.$rkt-bottom-sheet-item-label-font-weight;
  line-height: settings.$rkt-bottom-sheet-item-label-line-height;
}

// @deprecated in v4, removal in v6 task #3530308
// now uses rkt-List__item-title
.rkt-BottomSheet .mat-list-text .mat-line.rkt-Body-3,
.rkt-BottomSheet .mat-list-text .mat-line.rkt-Body-14 {
  color: settings.$rkt-bottom-sheet-item-body-color;
  font-family: settings.$rkt-bottom-sheet-item-body-font-family;
  font-size: settings.$rkt-bottom-sheet-item-body-font-size;
  font-weight: settings.$rkt-bottom-sheet-item-body-font-weight;
  line-height: settings.$rkt-bottom-sheet-item-body-line-height;
}

@media (max-width: settings.$rkt-bottom-sheet-medium-breakpoint) {
  .rkt-BottomSheet {
    width: settings.$rkt-bottom-sheet-small-width;
  }
}

@media (min-width: settings.$rkt-bottom-sheet-medium-breakpoint) and (max-width: settings.$rkt-bottom-sheet-large-breakpoint) {
  .rkt-BottomSheet {
    width: settings.$rkt-bottom-sheet-medium-width;
  }
}

@media (min-width: settings.$rkt-bottom-sheet-large-breakpoint) and (max-width: settings.$rkt-bottom-sheet-extra-large-breakpoint) {
  .rkt-BottomSheet {
    width: settings.$rkt-bottom-sheet-large-width;
  }
}

@media (min-width: settings.$rkt-bottom-sheet-extra-large-breakpoint) {
  .rkt-BottomSheet {
    width: settings.$rkt-bottom-sheet-extra-large-width;
  }
}