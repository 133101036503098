@use './color' as *;
@use './color-dark' as *;
@use './space' as *;
@use './spacing' as *;
@use './layer' as *;
@use './typography' as *;
@use './object' as *;
@use './elevation' as *;
@use './backdrop' as *;
$rkt-accordion-panel-background: $rkt-white !default;
$rkt-accordion-panel-box-shadow: 0 0 0 1px $rkt-black-opacity-15 !default;
$rkt-accordion-panel-border: none !default;
$rkt-accordion-panel-border-radius: 12px !default;
$rkt-accordion-panel-last-item-margin: 0 0 16px 0 !default;
$rkt-accordion-panel-hover-background: $rkt-gray-100 !default;
$rkt-accordion-panel-header-title-font-family: $rkt-typography-font-family-body-14 !default;
$rkt-accordion-panel-header-title-font-size: $rkt-typography-font-size-body-14 !default;
$rkt-accordion-panel-header-title-font-weight: $rkt-typography-font-weight-heading-18 !default;
$rkt-accordion-panel-header-title-font-style: normal !default;
$rkt-accordion-panel-header-title-color: $rkt-typography-color-body-14 !default;
$rkt-accordion-panel-header-title-line-height: $rkt-typography-line-height-body-14 !default;
$rkt-accordion-panel-header-title-margin: 0 16px 0 0 !default;
$rkt-accordion-panel-header-title-breakpoint-font-size: $rkt-typography-font-size-heading-18 !default;
$rkt-accordion-panel-header-title-breakpoint-font-weight: $rkt-typography-font-weight-heading-18 !default;
$rkt-accordion-panel-header-title-breakpoint-line-height: $rkt-typography-line-height-heading-18 !default;
$rkt-accordion-panel-header-padding: 20px 24px 20px 24px !default;
$rkt-accordion-panel-header-height: auto !default;
$rkt-accordion-panel-header-min-height: 24px !default;
$rkt-accordion-panel-header-transition: all 175ms ease-out !default;
$rkt-accordion-panel-header-title-align-items: start !default;
$rkt-accordion-panel-header-expanded-padding: 20px 24px 12px 24px !default;
$rkt-accordion-panel-header-icon-align-self: start !default;
$rkt-accordion-panel-header-icon-flex-shrink: 0 !default;
$rkt-accordion-panel-header-icon-margin-right: 16px !default;
$rkt-accordion-panel-header-focus-background: transparent !default;
$rkt-accordion-panel-header-focus-visible-outline: 3px solid $rkt-gray-800 !default;
$rkt-accordion-panel-body-padding: 0 24px 24px 24px !default;
$rkt-accordion-panel-body-opacity: 0 !default;
$rkt-accordion-panel-body-transition: all 175ms ease-out !default;
$rkt-accordion-panel-body-expanded-opacity: 1 !default;
$rkt-accordion-panel-content-font-family: $rkt-typography-font-family-body-14 !default;
$rkt-accordion-panel-content-font-size: $rkt-typography-font-size-body-14 !default;
$rkt-accordion-panel-content-font-weight: $rkt-typography-font-weight-body-16 !default;
$rkt-accordion-panel-content-font-style: normal !default;
$rkt-accordion-panel-content-color: $rkt-gray-600 !default;
$rkt-accordion-panel-content-line-height: $rkt-typography-line-height-body-14 !default;
$rkt-accordion-panel-content-margin: 0 !default;
$rkt-accordion-panel-content-breakpoint-font-size: 16px !default;
$rkt-accordion-panel-content-breakpoint-line-height: 24px !default;
$rkt-accordion-panel-slim-box-shadow: none !default;
$rkt-accordion-panel-slim-expansion-panel-header-padding: 0 !default;
$rkt-accordion-panel-slim-hover-background: transparent !default;
$rkt-accordion-panel-slim-hover-caret-color: $rkt-red-500 !default;
$rkt-accordion-panel-slim-margin: 0 0 24px 0 !default;
$rkt-accordion-panel-slim-mat-expansion-panel-body-margin-top: 12px !default;
$rkt-accordion-panel-slim-details-padding: 0 24px 24px 40px !default;
$rkt-accordion-panel-slim-border-radius: 8px !default;
$rkt-accordion-panel-slim-title-flex: auto !default;
$rkt-accordion-expansion-indicator-display: flex !default;
$rkt-accordion-expansion-indicator-justify-content: center !default;
$rkt-accordion-expansion-indicator-width: 24px !default;
$rkt-accordion-expansion-indicator-after-padding: 0 !default;
$rkt-accordion-expansion-indicator-after-color: $rkt-gray-800 !default;
$rkt-accordion-expansion-indicator-after-height: 7px !default;
$rkt-accordion-expansion-indicator-after-width: 7px !default;
$rkt-accordion-expansion-indicator-after-margin-bottom: 3px !default;
$rkt-accordion-expansion-indicator-after-border-width: 0px 3px 3px 0 !default;
$rkt-accordion-expansion-indicator-after-transform: rotate(45deg) scale(0.74) !default;
$rkt-accordion-expansion-indicator-after-border-radius: 2px !default;
$rkt-accordion-breakpoint: $rkt-split-breakpoint-4 !default;
$rkt-accordion-react-panel-content-margin: 0 !default;
$rkt-accordion-react-panel-content-display: flex !default;
$rkt-accordion-react-panel-content-align-items: center !default;
$rkt-accordion-react-panel-content-before-display: none !default;
$rkt-accordion-react-summary-padding: 20px 24px 20px 24px !default;
$rkt-accordion-react-summary-transition: all 150ms ease-out !default;
$rkt-accordion-react-summary-icon-color: $rkt-gray-800 !default;
$rkt-accordion-react-title-focus-visible-background: none !default;
$rkt-accordion-react-expanded-title-padding: 20px 24px 12px 24px !default;
$rkt-accordion-react-expanded-title-min-height: auto !default;
$rkt-accordion-react-details-padding: 0 24px 24px 24px !default;
$rkt-alert-box-sizing: border-box !default;
$rkt-alert-flex-direction: column !default;
$rkt-alert-display: flex !default;
$rkt-alert-align-items: center !default;
$rkt-alert-heading-color: $rkt-gray-800 !default;
$rkt-alert-heading-font-size: 1rem !default;
$rkt-alert-heading-medium-breakpoint-font-size: 1.125rem !default;
$rkt-alert-heading-large-breakpoint-font-size: 1.125rem !default;
$rkt-alert-heading-font-weight: $rkt-typography-font-weight-heading-18 !default;
$rkt-alert-heading-line-height: 150% !default;
$rkt-alert-heading-margin: 0 !default;
$rkt-alert-heading-font-family: $rkt-typography-font-family-heading-18 !default;
$rkt-alert-border-radius: 16px !default;
$rkt-alert-mui-flex-direction: row !default;
$rkt-alert-display-hidden: none !default;
$rkt-alert-border-color: $rkt-gray-800 !default;
$rkt-alert-border-width: 1px !default;
$rkt-alert-border-style: solid !default;
$rkt-alert-border: $rkt-alert-border-width $rkt-alert-border-style $rkt-alert-border-color !default;
$rkt-alert-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.02) !default;
$rkt-alert-color: $rkt-gray-800 !default;
$rkt-alert-max-width: 343px !default;
$rkt-alert-medium-breakpoint-max-width: 520px !default;
$rkt-alert-large-breakpoint-max-width: 906px !default;
$rkt-alert-medium-breakpoint: $rkt-split-breakpoint-4 !default;
$rkt-alert-large-breakpoint: $rkt-split-breakpoint-7 !default;
$rkt-alert-full-width-max-width: 100% !default;
$rkt-alert-font-family: $rkt-typography-font-family-body-16 !default;
$rkt-alert-font-size: $rkt-typography-font-size-body-14 !default;
$rkt-alert-font-size-medium-breakpoint: $rkt-typography-font-size-body-16 !default;
$rkt-alert-font-size-large-breakpoint: $rkt-typography-font-size-body-16 !default;
$rkt-alert-font-weight: $rkt-typography-font-weight-body-16 !default;
$rkt-alert-line-height: 1.25rem !default;
$rkt-alert-medium-breakpoint-line-height: 1.5rem !default;
$rkt-alert-large-breakpoint-line-height: 1.5rem !default;
$rkt-alert-padding: $rkt-spacing-20 $rkt-spacing-20 $rkt-spacing-20 $rkt-spacing-20 !default;
$rkt-alert-medium-breakpoint-padding: $rkt-spacing-24 $rkt-spacing-24 $rkt-spacing-24 $rkt-spacing-24 !default;
$rkt-alert-large-breakpoint-padding: $rkt-spacing-24 $rkt-spacing-24 $rkt-spacing-24 $rkt-spacing-24 !default;
$rkt-alert-content-padding: 0 !default;
$rkt-alert-dismiss-padding: 0 !default;
$rkt-alert-dismiss-icon-color: $rkt-gray-800 !default;
$rkt-alert-dismiss-icon-height: 24px !default;
$rkt-alert-dismiss-icon-width: 24px !default;
$rkt-alert-dismiss-icon-margin: 0 !default;
$rkt-alert-dismiss-container-height: 24px !default;
$rkt-alert-dismiss-container-width: 24px !default;
$rkt-alert-dismiss-container-padding: 0 !default;
$rkt-alert-dismiss-container-margin: 0 0 0 $rkt-spacing-16 !default;
$rkt-alert-dismiss-medium-breakpoint-container-margin: 0 0 0 $rkt-spacing-20 !default;
$rkt-alert-dismiss-large-breakpoint-container-margin: 0 0 0 $rkt-spacing-20 !default;
$rkt-alert-dismiss-container-display: flex !default;
$rkt-alert-dismiss-container-justify-content: center !default;
$rkt-alert-dismiss-button-align-self: flex-start !default;
$rkt-alert-dismiss-button-flex: 0 0 auto !default;
$rkt-alert-dismiss-button-padding: 0 !default;
$rkt-alert-dismiss-button-cursor: pointer !default;
$rkt-alert-dismiss-button-box-sizing: border-box !default;
$rkt-alert-dismiss-button-display: flex !default;
$rkt-alert-dismiss-button-border: 0 !default;
$rkt-alert-dismiss-button-border-radius: 100px !default;
$rkt-alert-dismiss-button-background-color: transparent !default;
$rkt-alert-dismiss-button-hover-background-color: rgba(0, 0, 0, 0.08) !default;
$rkt-alert-dismiss-button-active-background-color: rgba(0, 0, 0, 0.15) !default;
$rkt-alert-dismiss-button-focus-background-color: rgba(0, 0, 0, 0.08) !default;
$rkt-alert-dismiss-button-focus-outline: 3px solid $rkt-gray-800 !default;
$rkt-alert-icon-flex: 0 0 auto !default;
$rkt-alert-icon-align-self: flex-start !default;
$rkt-alert-icon-margin-top: 0 !default;
$rkt-alert-icon-margin-bottom: 0 !default;
$rkt-alert-icon-margin-left: 0 !default;
$rkt-alert-icon-margin: $rkt-alert-icon-margin-top $rkt-spacing-16 $rkt-alert-icon-margin-bottom $rkt-alert-icon-margin-left !default;
$rkt-alert-icon-medium-breakpoint-margin: $rkt-alert-icon-margin-top $rkt-spacing-20 $rkt-alert-icon-margin-bottom $rkt-alert-icon-margin-left !default;
$rkt-alert-icon-large-breakpoint-margin: $rkt-alert-icon-margin-top $rkt-spacing-20 $rkt-alert-icon-margin-bottom $rkt-alert-icon-margin-left !default;
$rkt-alert-icon-height: 24px !default;
$rkt-alert-icon-width: 24px !default;
$rkt-alert-icon-color-info: $rkt-blue-500 !default;
$rkt-alert-icon-color-success: $rkt-green-600 !default;
$rkt-alert-icon-color-warn: $rkt-yellow-600 !default;
$rkt-alert-border-info: 1px solid $rkt-blue-200 !default;
$rkt-alert-border-success: 1px solid $rkt-green-200 !default;
$rkt-alert-border-warn: 1px solid $rkt-yellow-200 !default;
$rkt-alert-bg-color: $rkt-white !default;
$rkt-alert-bg-color-warn: $rkt-yellow-50 !default;
$rkt-alert-bg-color-success: $rkt-green-50 !default;
$rkt-alert-bg-color-info: $rkt-blue-50 !default;
$rkt-alert-text-flex: 1 !default;
$rkt-alert-text-align-items: center !default;
$rkt-alert-text-color-warn: $rkt-gray-800 !default;
$rkt-alert-text-color-success: $rkt-gray-800 !default;
$rkt-alert-text-color-info: $rkt-gray-800 !default;
$rkt-alert-text-margin: 0 !default;
$rkt-alert-text-width: 100% !default;
$rkt-alert-action-button-padding: $rkt-spacing-8 $rkt-spacing-16 !default;
$rkt-alert-action-button-line-height: 20px !default;
$rkt-alert-action-button-display: flex !default;
$rkt-alert-action-button-group-margin: 0 0 0 $rkt-spacing-40 !default;
$rkt-alert-action-button-group-medium-breakpoint-margin: 0 0 0 $rkt-spacing-44 !default;
$rkt-alert-action-button-group-large-breakpoint-margin: 0 0 0 $rkt-spacing-44 !default;
$rkt-alert-action-button-group-align-self: start !default;
$rkt-alert-action-button-icon-display: none !default;
$rkt-autocomplete-background: $rkt-gray-50 !default;
$rkt-autocomplete-border-radius: 16px !default;
$rkt-autocomplete-placeholder-font-weight: $rkt-typography-font-weight-400 !default;
$rkt-autocomplete-width: 100% !default;
$rkt-autocomplete-hover-background: $rkt-gray-50 !default;
$rkt-autocomplete-disabled-color: $rkt-gray-300;
$rkt-autocomplete-with-chips-input-height: auto !default;
$rkt-autocomplete-with-chips-input-margin: 0 !default;
$rkt-autocomplete-with-chips-input-padding: 0 20px 0 20px !default;
$rkt-autocomplete-with-chips-input-min-height: 68px !default;
$rkt-autocomplete-with-chips-width: 444px !default;
$rkt-autocomplete-with-chips-mdc-list-item-primary-text-display: flex !default;
$rkt-autocomplete-with-chips-mdc-list-item-primary-text-align-items: center !default;
$rkt-autocomplete-with-chips-mdc-list-item-primary-text-justify-content: space-between !default;
$rkt-autocomplete-with-chips-mdc-list-item-primary-text-width: 100% !default;
$rkt-autocomplete-with-chips-selected-background: $rkt-white !default;
$rkt-autocomplete-with-chips-selected-color: $rkt-gray-800 !default;
$rkt-autocomplete-with-chips-pseudo-checkbox-display: none !default;
$rkt-autocomplete-with-chips-checkbox-padding: 0 !default;
$rkt-autocomplete-with-chips-checkbox-margin: 0 !default;
$rkt-autocomplete-with-chips-checkbox-width: 24px !default;
$rkt-autocomplete-with-chips-checkbox-height: 24px !default;
$rkt-autocomplete-chips-margin: 0 12px 0 0 !default;
$rkt-autocomplete-chips-no-chips-margin: 0 0 0 -1px !default;
$rkt-autocomplete-chips-flex-shrink: 1 !default;
$rkt-autocomplete-pane-background-color: $rkt-white !default;
$rkt-autocomplete-pane-container-border-radius: 16px !default;
$rkt-autocomplete-pane-border-radius: 8px !default;
$rkt-autocomplete-pane-overflow: hidden !default;
$rkt-autocomplete-pane-margin: 8px 0 0 0 !default;
$rkt-autocomplete-pane-padding: 8px !default;
$rkt-autocomplete-pane-box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.1) !default;
$rkt-autocomplete-panel-max-width: 100% !default;
$rkt-autocomplete-panel-box-shadow: none !default;
$rkt-autocomplete-panel-padding: 0 !default;
$rkt-autocomplete-panel-overflow-y: auto !default;
$rkt-autocomplete-option-border-radius: 8px !default;
$rkt-autocomplete-option-display: flex !default;
$rkt-autocomplete-option-align-items: center !default;
$rkt-autocomplete-option-font-family: $rkt-typography-font-family-body-16 !default;
$rkt-autocomplete-option-font-size: $rkt-typography-font-size-body-16 !default;
$rkt-autocomplete-option-font-weight: $rkt-typography-font-weight-500 !default;
$rkt-autocomplete-option-color: $rkt-typography-color-body-16 !default;
$rkt-autocomplete-option-line-height: $rkt-typography-line-height-body-16 !default;
$rkt-autocomplete-option-min-height: auto !default;
$rkt-autocomplete-option-padding: 0 !default;
$rkt-autocomplete-option-content-display: flex !default;
$rkt-autocomplete-option-content-padding: 20px 12px !default;
$rkt-autocomplete-option-content-width: 100% !default;
$rkt-autocomplete-option-content-justify-content: space-between !default;
$rkt-autocomplete-option-content-margin-right: -3px !default;
$rkt-autocomplete-option-label-with-image-display: flex !default;
$rkt-autocomplete-option-label-with-image-align-items: center !default;
$rkt-autocomplete-option-position: relative !default;
$rkt-autocomplete-option-after-display: block !default;
$rkt-autocomplete-option-after-width: calc(100% - 12px) !default;
$rkt-autocomplete-option-after-height: 1px !default;
$rkt-autocomplete-option-after-background-color: $rkt-gray-100 !default;
$rkt-autocomplete-option-after-position: absolute !default;
$rkt-autocomplete-option-after-bottom: 0 !default;
$rkt-autocomplete-option-after-left: 50% !default;
$rkt-autocomplete-option-after-transform: translateX(-50%) !default;
$rkt-autocomplete-option-after-last-item: hidden !default;
$rkt-autocomplete-option-focus-visible-box-shadow: inset 0 0 0 3px $rkt-gray-800 !default;
$rkt-autocomplete-option-focus-visible-background: $rkt-white !default;
$rkt-autocomplete-option-focus-visible-after-visibility: hidden !default;
$rkt-autocomplete-option-single-selected-background: $rkt-green-50 !default;
$rkt-autocomplete-option-single-selected-color: $rkt-green-700 !default;
$rkt-autocomplete-option-single-hover-background: $rkt-gray-50 !default;
$rkt-autocomplete-option-single-pseudo-checkbox-display: block !default;
$rkt-autocomplete-option-single-pseudo-checkbox-height: 24px !default;
$rkt-autocomplete-option-single-pseudo-checkbox-width: 24px !default;
$rkt-autocomplete-option-single-pseudo-checkbox-margin: 0 12px 0 16px !default;
$rkt-autocomplete-option-single-pseudo-checkbox-after-border-radius: 1px !default;
$rkt-autocomplete-option-single-pseudo-checkbox-after-color: $rkt-green-700 !default;
$rkt-autocomplete-option-single-pseudo-checkbox-after-height: 4px !default;
$rkt-autocomplete-option-single-pseudo-checkbox-after-width: 8px !default;
$rkt-autocomplete-option-single-pseudo-checkbox-after-top: 0 !default;
$rkt-autocomplete-option-hover-background: $rkt-gray-50 !default;
$rkt-autocomplete-option-group-label-padding: 0 0 0 12px !default;
$rkt-autocomplete-option-group-label-font-weight: $rkt-typography-font-weight-body-16 !default;
$rkt-autocomplete-option-group-label-font-size: $rkt-typography-font-size-body-16 !default;
$rkt-autocomplete-option-group-label-color: $rkt-gray-600 !default;
$rkt-autocomplete-option-image-margin: 0 16px 0 0 !default;
$rkt-autocomplete-react-background: transparent !default;
$rkt-autocomplete-react-popup-indicator-display: none !default;
$rkt-autocomplete-react-font-size: $rkt-typography-font-size-label-16 !default;
$rkt-autocomplete-react-label-top: 8.5px !default;
$rkt-autocomplete-react-options-margin: 8px 0 0 0 !default;
$rkt-autocomplete-react-options-padding: 8px !default;
$rkt-autocomplete-react-options-subheader-padding: 0 12px 0 12px !default;
$rkt-autocomplete-react-options-subheader-font-size: $rkt-typography-font-size-body-16 !default;
$rkt-autocomplete-react-options-subheader-font-weight: $rkt-typography-font-weight-400 !default;
$rkt-autocomplete-react-options-list-padding: 0 !default;
$rkt-autocomplete-react-options-list-max-height: 250px !default;
$rkt-autocomplete-react-options-option-min-width: 100% !default;
$rkt-autocomplete-react-options-option-padding: 20px 12px !default;
$rkt-autocomplete-react-options-option-focused-background: $rkt-gray-50 !default;
$rkt-autocomplete-react-options-option-focus-visible-after-visibility: hidden !default;
$rkt-autocomplete-react-options-option-icon-color: $rkt-gray-800 !default;
$rkt-autocomplete-react-options-option-icon-min-width: unset !default;
$rkt-autocomplete-react-options-option-icon-margin: 0 16px 0 0 !default;
$rkt-autocomplete-react-options-option-selected-after-visibility: visible !default;
$rkt-autocomplete-react-options-option-selected-hover-after-visibility: hidden !default;
$rkt-autocomplete-react-options-option-selected-background: $rkt-white !default;
$rkt-autocomplete-react-options-option-selected-color: $rkt-gray-800 !default;
$rkt-autocomplete-react-group-label-right: 12px !default;
$rkt-autocomplete-react-label-text-margin: 0 !default;
$rkt-autocomplete-react-label-left: 0 !default;
$rkt-autocomplete-react-checkbox-margin: 0 !default;
$rkt-autocomplete-react-checkbox-padding: 0 !default;
$rkt-autocomplete-react-checkbox-visibility: hidden !default;
$rkt-autocomplete-react-checkbox-selected-color: transparent !default;
$rkt-autocomplete-react-checkbox-selected-visibility: visible !default;
$rkt-autocomplete-react-checkbox-selected-width: 24px !default;
$rkt-autocomplete-react-checkbox-selected-height: 24px !default;
$rkt-autocomplete-react-with-chips-input-left: 4px !default;
$rkt-autocomplete-react-with-chips-input-top: 7px !default;
$rkt-autocomplete-react-with-chips-input-padding: 8px 0 8px 8px !default;
$rkt-autocomplete-react-with-chips-input-label-left: 9px !default;
$rkt-autocomplete-react-with-chips-input-label-top: 10px !default;
$rkt-autocomplete-react-with-chips-input-label-chips-present-top: 4px !default;
$rkt-autocomplete-react-with-chips-paper-position: absolute !default;
$rkt-autocomplete-react-with-chips-paper-width: 428px !default;
$rkt-autocomplete-react-with-chips-paper-top: -14px !default;
$rkt-autocomplete-react-with-chips-chip-left: 8px !default;
$rkt-autocomplete-react-with-chips-chip-top: 9px !default;
$rkt-autocomplete-react-with-chips-checkbox-visibility: visible !default;
$rkt-autocomplete-react-with-chips-checkbox-color: $rkt-green-600 !default;
$rkt-autocomplete-react-with-chips-checkbox-stroke: $rkt-white !default;
$rkt-autocomplete-react-input-placeholder-color: $rkt-gray-500 !default;
$rkt-autocomplete-react-input-left: 8px !default;
$rkt-autocomplete-react-input-top: 8px !default;
$rkt-autocomplete-react-input-base-height: auto !default;
$rkt-autocomplete-react-input-base-padding-bottom: 28px !default;
$rkt-autocomplete-react-input-base-top: 15px !default;
$rkt-autocomplete-react-popper-transform: translate(0, 68px) !default;
$rkt-autocomplete-react-error-box-shadow: inset 0 0 0 1.5px $rkt-yellow-600 !default;
$rkt-badge-has-large-value-width: 33px !default;
$rkt-badge-has-large-value-right: -13px !default;
$rkt-badge-has-large-value-transform: scale(1) translate(51.5%, -50%) !default;
$rkt-badge-has-large-value-has-label-right: -19px !default;
$rkt-badge-has-large-value-has-icon-right: -22px !default;
$rkt-badge-has-large-value-has-label-content-right: -30px !default;
$rkt-badge-has-button-right: 4px !default;
$rkt-badge-has-button-top: 6px !default;
$rkt-badge-has-label-right: -16px !default;
$rkt-badge-has-label-top: 2px !default;
$rkt-badge-has-icon-badge-content-right: -17px !default;
$rkt-badge-has-icon-badge-content-top: -10px !default;
$rkt-badge-has-icon-right: -5px !default;
$rkt-badge-has-icon-top: 2px !default;
$rkt-badge-border-radius: 20px !default;
$rkt-badge-font-size: 0.75rem !default;
$rkt-badge-font-family: 'Wintle Text', Helvetica, 'Helvetica Neue', Arial, sans-serif !default;
$rkt-badge-label-padding-right: 6px !default;
$rkt-badge-label-font-family: 'Wintle Text', Helvetica, 'Helvetica Neue', Arial, sans-serif !default;
$rkt-badge-label-margin: 0 !default;
$rkt-badge-label-color: $rkt-gray-800 !default;
$rkt-badge-label-font-size: 1rem !default;
$rkt-badge-label-font-weight: $rkt-typography-font-weight-label-16 !default;
$rkt-badge-label-line-height: 150% !default;
$rkt-badge-line-height: 24px !default;
$rkt-badge-height: 24px !default;
$rkt-badge-width: 24px !default;
$rkt-badge-color: $rkt-white !default;
$rkt-badge-font-weight: $rkt-typography-font-weight-label-16 !default;
$rkt-badge-background-color: $rkt-red-500 !default;
$rkt-badge-info-background-color: $rkt-blue-600 !default;
$rkt-badge-info-color: $rkt-blue-50 !default;
$rkt-badge-warn-background-color: $rkt-yellow-700 !default;
$rkt-badge-warn-color: $rkt-yellow-50 !default;
$rkt-badge-success-background-color: $rkt-green-700 !default;
$rkt-badge-success-color: $rkt-green-50 !default;
$rkt-badge-content-right: -8px !default;
$rkt-badge-content-top: -6px !default;
$rkt-badge-content-margin: 0 !default;
$rkt-badge-content-left: unset !default;
$rkt-badge-transition: none !default;
$rkt-badge-info-light-background-color: $rkt-blue-50 !default;
$rkt-badge-info-light-color: $rkt-blue-700 !default;
$rkt-badge-success-light-background-color: $rkt-green-50 !default;
$rkt-badge-success-light-color: $rkt-green-700 !default;
$rkt-badge-warn-light-background-color: $rkt-yellow-50 !default;
$rkt-badge-warn-light-color: $rkt-yellow-700 !default;
$rkt-badge-primary-background-color: $rkt-gray-800 !default;
$rkt-badge-primary-color: $rkt-white !default;
$rkt-banner-wide-breakpoint: 1080px !default;
$rkt-banner-medium-breakpoint: 680px !default;
$rkt-banner-border-radius: 20px !default;
$rkt-banner-border: 1px solid $rkt-black-opacity-04 !default;
$rkt-banner-background: $rkt-gray-50 !default;
$rkt-banner-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.02) !default;
$rkt-banner-padding: $rkt-spacing-20 !default;
$rkt-banner-wide-padding: 24px 40px !default;
$rkt-banner-display: grid !default;
$rkt-banner-grid-template-columns: auto 1fr auto !default;
$rkt-banner-grid-template-rows: auto auto !default;
$rkt-banner-grid-template-columns-wide-breakpoint: auto 1fr auto auto !default;
$rkt-banner-grid-template-rows-wide-breakpoint: auto !default;
$rkt-banner-display-hidden: none !default;
$rkt-banner-font-family: $rkt-typography-font-family-heading-18 !default;
$rkt-banner-icon-indicator-medium-height: 34px !default;
$rkt-banner-icon-indicator-medium-width: 34px !default;
$rkt-banner-icon-indicator-height: 24px !default;
$rkt-banner-icon-indicator-width: 24px !default;
$rkt-banner-icon-indicator-color: $rkt-gray-800 !default;
$rkt-banner-icon-indicator-wide-margin: 0 20px 0 0 !default;
$rkt-banner-icon-indicator-medium-margin: 0 16px 0 0 !default;
$rkt-banner-icon-indicator-margin: 0 !default;
$rkt-banner-icon-indicator-margin-mui: 0 !default;
$rkt-banner-icon-indicator-padding-mui: 0 !default;
$rkt-banner-icon-indicator-medium-display: flex !default;
$rkt-banner-icon-indicator-medium-align-self: center !default;
$rkt-banner-icon-indicator-opacity: 1 !default;
$rkt-banner-icon-indicator-stroke-width: 1.05 !default;
$rkt-banner-dismiss-icon-height: 24px !default;
$rkt-banner-dismiss-icon-width: 24px !default;
$rkt-banner-dismiss-icon-color: $rkt-gray-800 !default;
$rkt-banner-dismiss-icon-margin: 0 !default;
$rkt-banner-dismiss-icon-display-mui: none !default;
$rkt-banner-dismiss-icon-height-mui: 32px !default;
$rkt-banner-dismiss-icon-width-mui: 32px !default;
$rkt-banner-dismiss-icon-align-self-mui: center !default;
$rkt-banner-dismiss-icon-medium-padding: 4px 0 0 0 !default;
$rkt-banner-text-color: $rkt-gray-800 !default;
$rkt-banner-text-margin: 0 !default;
$rkt-banner-text-font-family: $rkt-typography-font-family-body-16 !default;
$rkt-banner-text-font-style: normal !default;
$rkt-banner-text-font-weight: $rkt-typography-font-weight-500 !default;
$rkt-banner-text-font-size: $rkt-typography-font-size-label-16 !default;
$rkt-banner-text-wide-font-size: $rkt-typography-font-size-heading-20 !default;
$rkt-banner-text-line-height: $rkt-typography-line-height-label-16 !default;
$rkt-banner-text-wide-line-height: $rkt-typography-line-height-heading-20 !default;
$rkt-banner-text-min-height: 24px !default;
$rkt-banner-text-display: flex !default;
$rkt-banner-text-align-items: center !default;
$rkt-banner-content-margin: 0 12px !default;
$rkt-banner-content-medium-margin: 0 !default;
$rkt-banner-content-margin-mui: 0 !default;
$rkt-banner-content-padding-mui: 0 !default;
$rkt-banner-content-medium-align-items: center !default;
$rkt-banner-content-display: grid !default;
$rkt-banner-action-height: auto !default;
$rkt-banner-action-width: auto !default;
$rkt-banner-action-medium-margin: 0 24px !default;
$rkt-banner-action-margin: 20px 0 0 0 !default;
$rkt-banner-action-wide-content-padding: 16px 24px !default;
$rkt-banner-action-content-padding: 8px 16px !default;
$rkt-banner-action-medium-display: flex !default;
$rkt-banner-action-medium-align-items: center !default;
$rkt-banner-action-medium-justify-content: end !default;
$rkt-banner-icon-button-border: 0 !default;
$rkt-banner-icon-button-border-radius: 100% !default;
$rkt-banner-icon-button-height: 32px !default;
$rkt-banner-icon-button-width: 32px !default;
$rkt-banner-icon-button-padding: 0 !default;
$rkt-banner-icon-button-margin: 0 0 0 $rkt-spacing-20 !default;
$rkt-banner-icon-button-hover-background: $rkt-black-opacity-08 !default;
$rkt-banner-icon-button-active-background: $rkt-black-opacity-15 !default;
$rkt-banner-icon-button-focus-background: $rkt-black-opacity-15 !default;
$rkt-banner-icon-button-focus-box-shadow: inset 0 0 0 3px $rkt-gray-800 !default;
$rkt-banner-icon-button-focus-outline: none !default;
$rkt-banner-icon-button-cursor: pointer !default;
$rkt-banner-icon-button-background-color: transparent !default;
$rkt-banner-icon-button-justify-content: center !default;
$rkt-banner-icon-button-align-items: center !default;
$rkt-banner-icon-button-display: flex !default;
$rkt-banner-icon-button-align-self: center !default;
$rkt-banner-success-background: $rkt-green-50 !default;
$rkt-banner-info-background: $rkt-blue-50 !default;
$rkt-banner-warn-background:  #FFF5E4 !default;
$rkt-banner-white-background: $rkt-white !default;
$rkt-banner-primary-background: $rkt-gray-800 !default;
$rkt-banner-primary-text-color: $rkt-white !default;
$rkt-banner-primary-icon-indicator-color: $rkt-white !default;
$rkt-banner-primary-action-color: $rkt-gray-800 !default;
$rkt-banner-primary-action-background: $rkt-white !default;
$rkt-banner-primary-action-hover-color: rgba(255, 255, 255, 0.88) !default;
$rkt-banner-primary-action-hover-background: $rkt-white-opacity-400 !default;
$rkt-banner-primary-action-focus-visible-box-shadow: inset 0 0 0 3px $rkt-black-opacity-50 !default;
$rkt-banner-primary-action-active-color: $rkt-gray-800 !default;
$rkt-banner-primary-action-active-background-color: #B1B1B0 !default;
$rkt-banner-primary-action-disabled-color: $rkt-gray-800 !default;
$rkt-banner-primary-action-disabled-background: $rkt-white-opacity-300 !default;
$rkt-banner-primary-dismiss-color: $rkt-white !default;
$rkt-banner-primary-dismiss-hover-background-color: $rkt-white-opacity-300 !default;
$rkt-banner-primary-dismiss-focus-visible-box-shadow: inset 0 0 0 3px $rkt-white !default;
$rkt-banner-primary-dismiss-focus-visible-background-color: $rkt-white-opacity-300 !default;
$rkt-banner-primary-dismiss-active-background-color: $rkt-white-opacity-400 !default;
$rkt-banner-secondary-background: $rkt-red-500 !default;
$rkt-banner-secondary-text-color: $rkt-white !default;
$rkt-banner-secondary-icon-indicator-color: $rkt-white !default;
$rkt-banner-secondary-action-color: $rkt-gray-800 !default;
$rkt-banner-secondary-action-background: $rkt-white !default;
$rkt-banner-secondary-action-hover-color: rgba(255, 255, 255, 0.88) !default;
$rkt-banner-secondary-action-hover-background: $rkt-white-opacity-400 !default;
$rkt-banner-secondary-action-focus-visible-box-shadow: inset 0 0 0 3px $rkt-gray-400 !default;
$rkt-banner-secondary-action-active-color: $rkt-gray-800 !default;
$rkt-banner-secondary-action-active-background-color: #B1B1B0 !default;
$rkt-banner-secondary-action-disabled-color: $rkt-gray-800 !default;
$rkt-banner-secondary-action-disabled-background: $rkt-white-opacity-300 !default;
$rkt-banner-secondary-dismiss-color: $rkt-white !default;
$rkt-banner-secondary-dismiss-hover-background-color: $rkt-white-opacity-300 !default;
$rkt-banner-secondary-dismiss-focus-visible-box-shadow: inset 0 0 0 3px $rkt-white !default;
$rkt-banner-secondary-dismiss-focus-visible-background-color: $rkt-white-opacity-300 !default;
$rkt-banner-secondary-dismiss-active-background-color: $rkt-white-opacity-400 !default;
$rkt-bottom-sheet-small-width: 100% !default;
$rkt-bottom-sheet-medium-width: 384px !default;
$rkt-bottom-sheet-large-width: 512px !default;
$rkt-bottom-sheet-extra-large-width: 576px !default;
$rkt-bottom-sheet-medium-breakpoint: 960px !default;
$rkt-bottom-sheet-large-breakpoint: 1280px !default;
$rkt-bottom-sheet-extra-large-breakpoint: 1920px !default;
$rkt-bottom-sheet-margin-left: auto !default;
$rkt-bottom-sheet-margin-right: auto !default;
$rkt-bottom-sheet-list-margin: 8px !default;
$rkt-bottom-sheet-button-max-width: unset !default;
$rkt-bottom-sheet-border-top-right-radius: 12px !default;
$rkt-bottom-sheet-padding: 0 !default;
$rkt-bottom-sheet-item-hover-background-color: $rkt-gray-100 !default;
$rkt-bottom-sheet-border-top-left-radius: 12px !default;
$rkt-bottom-sheet-box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !default;
$rkt-bottom-sheet-icon-margin: 0 auto !default;
$rkt-bottom-sheet-item-label-color: $rkt-typography-color-label-16 !default;
$rkt-bottom-sheet-item-label-font-family: $rkt-typography-font-family-label-16 !default;
$rkt-bottom-sheet-item-label-font-size: $rkt-typography-font-size-label-16 !default;
$rkt-bottom-sheet-item-label-font-weight: $rkt-typography-font-weight-600 !default;
$rkt-bottom-sheet-item-label-line-height: $rkt-typography-line-height-label-16 !default;
$rkt-bottom-sheet-item-body-color: $rkt-gray-600 !default;
$rkt-bottom-sheet-item-body-font-family: $rkt-typography-font-family-body-14 !default;
$rkt-bottom-sheet-item-body-font-size: $rkt-typography-font-size-body-14 !default;
$rkt-bottom-sheet-item-body-font-weight: $rkt-typography-font-weight-body-14 !default;
$rkt-bottom-sheet-item-body-line-height: $rkt-typography-line-height-body-14 !default;
$rkt-breadcrumbs-display: flex !default;
$rkt-breadcrumbs-margin: 0 !default;
$rkt-breadcrumbs-padding: 0 !default;
$rkt-breadcrumbs-list-style: none !default;
$rkt-breadcrumbs-align-items: center !default;
$rkt-breadcrumbs-flex-wrap: wrap !default;
$rkt-breadcrumbs-font-family: $rkt-typography-font-family-label-14 !default;
$rkt-breadcrumbs-font-size: $rkt-typography-font-size-label-14 !default;
$rkt-breadcrumbs-font-weight: $rkt-typography-font-weight-label-14 !default;
$rkt-breadcrumbs-line-height: $rkt-typography-line-height-label-14 !default;
$rkt-breadcrumbs-crumb-color: $rkt-gray-600 !default;
$rkt-breadcrumbs-crumb-separator-margin: 0 8px !default;
$rkt-breadcrumbs-crumb-text-decoration: none !default;
$rkt-breadcrumbs-crumb-text-decoration-hover: underline !default;
$rkt-breadcrumbs-crumb-selected-text-decoration-hover: none !default;
$rkt-breadcrumbs-crumb-separator-color: $rkt-gray-600 !default;
$rkt-breadcrumbs-crumb-selected-color: $rkt-gray-800 !default;
$rkt-button-link-box-sizing: border-box !default;
$rkt-button-link-cursor: pointer !default;
$rkt-button-link-background-color: transparent !default;
$rkt-button-link-padding: 3px 3px 3px 0 !default;
$rkt-button-link-border: none !default;
$rkt-button-link-border-radius: 1000px !default;
$rkt-button-link-align-items: center !default;
$rkt-button-link-display: inline-flex !default;
$rkt-button-link-text-decoration: none !default;
$rkt-button-link-focus-visible-border: 3px solid $rkt-gray-800 !default;
$rkt-button-link-focus-visible-outline: 3px solid $rkt-gray-800 !default;
$rkt-button-link-hover-text-color: $rkt-red-500 !default;
$rkt-button-link-active-color: $rkt-gray-800 !default;
$rkt-button-link-active-icon-background: $rkt-gray-200 !default;
$rkt-button-link-text-font-family: 'Wintle Text', Helvetica, 'Helvetica Neue', Arial, sans-serif !default;
$rkt-button-link-text-font-size: $rkt-typography-font-size-heading-24 !default;
$rkt-button-link-text-font-style: normal !default;
$rkt-button-link-text-font-weight: $rkt-typography-font-weight-heading-24 !default;
$rkt-button-link-text-line-height: $rkt-typography-line-height-heading-24 !default;
$rkt-button-link-text-margin: 0 16px 0 24px !default;
$rkt-button-link-text-color: $rkt-gray-800 !default;
$rkt-button-link-text-visited-color: $rkt-gray-800 !default;
$rkt-button-link-text-visited-text-decoration: none !default;
$rkt-button-link-icon-container-background: $rkt-gray-100 !default;
$rkt-button-link-icon-container-border-radius: 100% !default;
$rkt-button-link-icon-container-width: 48px !default;
$rkt-button-link-icon-container-height: 48px !default;
$rkt-button-link-icon-container-display: flex !default;
$rkt-button-link-icon-container-justify-content: center !default;
$rkt-button-link-icon-container-align-items: center !default;
$rkt-button-link-icon-container-hover-width: 40px !default;
$rkt-button-link-icon-container-hover-height: 40px !default;
$rkt-button-link-icon-color: $rkt-gray-800 !default;
$rkt-button-link-icon-fill: $rkt-gray-800 !default;
$rkt-button-link-icon-size-width: 24px !default;
$rkt-button-link-icon-size-height: 24px !default;
$rkt-button-link-left-padding: 3px 0 3px 3px !default;
$rkt-button-link-left-text-margin: 0 24px 0 16px !default;
$rkt-button-link-small-padding: 3px 3px 3px 0 !default;
$rkt-button-link-small-text-margin: 0 16px 0 20px !default;
$rkt-button-link-small-text-icon-left-margin: 0 20px 0 16px !default;
$rkt-button-link-small-text-font-size: $rkt-typography-font-size-body-16 !default;
$rkt-button-link-small-text-line-height: $rkt-typography-line-height-body-16 !default;
$rkt-button-link-small-icon-container-width: 40px !default;
$rkt-button-link-small-icon-container-height: 40px !default;
$rkt-button-link-small-icon-container-hover-width: 32px !default;
$rkt-button-link-small-icon-container-hover-height: 32px !default;
$rkt-button-toggle-background-color: $rkt-gray-50 !default;
$rkt-button-toggle-border-radius: 1000px !default;
$rkt-button-toggle-button-selected-background-color: $rkt-white !default;
$rkt-button-toggle-button-selected-color: $rkt-gray-800 !default;
$rkt-button-toggle-button-selected-checkbox-display: none !default;
$rkt-button-toggle-button-selected-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.05) !default;
$rkt-button-toggle-button-selected-border: 1.5px solid $rkt-black-opacity-04 !default;
$rkt-button-toggle-button-selected-hover-background-color: $rkt-button-toggle-button-selected-background-color !default;
$rkt-button-toggle-button-selected-hover-border-color: $rkt-black-opacity-08 !default;
$rkt-button-toggle-button-selected-hover-box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.05) !default;
$rkt-button-toggle-button-selected-hover-keyboard-focus-box-shadow: inset 0 0 0 3px $rkt-gray-800, 0px 4px 12px 0px rgba(0, 0, 0, 0.05) !default;
$rkt-button-toggle-box-sizing: border-box !default;
$rkt-button-toggle-border: 1px solid $rkt-gray-200 !default;
$rkt-button-toggle-padding: 4px 5px !default;
$rkt-button-toggle-gap: 4px !default;
$rkt-button-toggle-button-group-gap: 5px !default;
$rkt-button-toggle-align-items: center !default;
$rkt-button-toggle-display: inline-flex !default;
$rkt-button-toggle-min-height: 48px !default;
$rkt-button-toggle-color: $rkt-gray-600 !default;
$rkt-button-toggle-button-keyboard-focus-background-color: $rkt-white !default;
$rkt-button-toggle-button-keyboard-focus-box-shadow: inset 0 0 0 3px $rkt-gray-900 !default;
$rkt-button-toggle-button-hover-background-color: $rkt-gray-200 !default;
$rkt-button-toggle-button-box-sizing: border-box !default;
$rkt-button-toggle-button-border-radius: 1000px !default;
$rkt-button-toggle-button-padding: 9px 24px 11px 24px !default;
$rkt-button-toggle-button-root-padding: 10px 24px 11px 24px !default;
$rkt-button-toggle-button-align-items: center !default;
$rkt-button-toggle-button-display: flex !default;
$rkt-button-toggle-button-border: 1.5px solid transparent !default;
$rkt-button-toggle-button-border-left: 0 !default;
$rkt-button-toggle-button-background-color: $rkt-button-toggle-background-color !default;
$rkt-button-toggle-button-color: $rkt-gray-600 !default;
$rkt-button-toggle-button-overlay-opacity: 0 !default;
$rkt-button-toggle-button-content-line-height: unset !default;
$rkt-button-toggle-button-content-padding: 0 !default;
$rkt-button-toggle-button-line-height: 16px !default;
$rkt-button-toggle-text-line-height: 20px !default;
$rkt-button-toggle-text-vertical-align: unset !default;
$rkt-button-toggle-font-size: $rkt-typography-font-size-label-14 !default;
$rkt-button-toggle-font-weight: $rkt-typography-font-weight-label-14 !default;
$rkt-button-toggle-font-family: $rkt-typography-font-family-label-14 !default;
$rkt-button-toggle-on-dark-button-selected-background-color: $rkt-gray-100 !default;
$rkt-button-toggle-on-dark-color: $rkt-white !default;
$rkt-button-toggle-on-dark-background-color: $rkt-white-opacity-50 !default;
$rkt-button-toggle-on-dark-hover-background-color: $rkt-white-opacity-300 !default;
$rkt-button-toggle-on-dark-border-color: $rkt-white !default;
$rkt-button-icon-on-dark-active-background-color: $rkt-white-opacity-400 !default;
$rkt-button-icon-on-dark-icon-focus-color: rgba(255, 255, 255, 0.88) !default;
$rkt-button-icon-on-dark-focus-box-shadow: inset 0 0 0 3px $rkt-gray-400 !default;
$rkt-button-icon-on-dark-focus-background-color: $rkt-white-opacity-300 !default;
$rkt-button-icon-on-dark-spinner-stroke: rgba(255, 255, 255, 0.88) !default;
$rkt-button-icon-on-dark-disabled-icon-color: $rkt-white-opacity-300 !default;
$rkt-button-icon-on-dark-spinner-icon-color: rgba(255, 255, 255, 0.88) !default;
$rkt-button-icon-on-dark-icon-hover-color: rgba(255, 255, 255, 0.88) !default;
$rkt-button-icon-on-dark-hover-background-color: $rkt-white-opacity-300 !default;
$rkt-button-icon-on-dark-disabled-background-color: $rkt-white-opacity-300 !default;
$rkt-button-icon-on-dark-disabled-color: $rkt-white-opacity-300 !default;
$rkt-button-icon-on-dark-background-color: rgba(255, 255, 255, 0.88) !default;
$rkt-button-icon-color: $rkt-gray-800 !default;
$rkt-button-icon-position: absolute !default;
$rkt-button-icon-content: '' !default;
$rkt-button-icon-cursor: pointer !default;
$rkt-button-icon-background-color: $rkt-gray-100 !default;
$rkt-button-icon-height: 48px !default;
$rkt-button-icon-width: 48px !default;
$rkt-button-icon-padding: 4px !default;
$rkt-button-icon-display: flex !default;
$rkt-button-icon-align-items: center !default;
$rkt-button-icon-justify-content: center !default;
$rkt-button-icon-border-radius: 50% !default;
$rkt-button-icon-hover-height: 40px !default;
$rkt-button-icon-hover-width: 40px !default;
$rkt-button-icon-focus-box-shadow: inset 0 0 0 3px $rkt-gray-800 !default;
$rkt-button-icon-active-background-color: $rkt-gray-200 !default;
$rkt-button-icon-large-height: 60px !default;
$rkt-button-icon-large-width: 60px !default;
$rkt-button-icon-large-hover-height: 52px !default;
$rkt-button-icon-large-hover-width: 52px !default;
$rkt-button-icon-disabled-color: $rkt-gray-400 !default;
$rkt-button-icon-disabled-background-color: $rkt-black-opacity-04 !default;
$rkt-button-icon-in-inputs-transition: none !default;
$rkt-button-icon-in-inputs-background: transparent !default;
$rkt-button-icon-in-inputs-hover-background: $rkt-gray-200 !default;
$rkt-button-icon-in-inputs-active-background: $rkt-gray-300 !default;
$rkt-button-icon-in-inputs-angular-border: none !default;
$rkt-button-icon-in-inputs-angular-background: transparent !default;
$rkt-button-icon-in-inputs-outline: none !default;
$rkt-button-icon-z-index: 1 !default;
$rkt-button-icon-svg-icon-root-z-index: 2 !default;
$rkt-button-icon-mat-icon-z-index: 2 !default;
$rkt-button-border: none !default;
$rkt-button-border-radius: 1000px !default;
$rkt-button-font-size: $rkt-typography-font-size-label-16 !default;
$rkt-button-font-weight: $rkt-typography-font-weight-label-16 !default;
$rkt-button-padding: 18.5px $rkt-spacing-20 !default;
$rkt-button-focus-box-shadow: inset 0 0 0 3px $rkt-red-500 !default;
$rkt-button-line-height: $rkt-typography-line-height-button !default;
$rkt-button-height: 48px !default;
$rkt-button-has-icon-height: 24px !default;
$rkt-button-has-icon-width: 24px !default;
$rkt-button-has-left-icon-padding-left: 18px !default;
$rkt-button-has-left-icon-margin-left: 0 !default;
$rkt-button-has-left-icon-margin-right: $rkt-spacing-8 !default;
$rkt-button-has-right-icon-padding-right: 18px !default;
$rkt-button-has-right-icon-margin-left: $rkt-spacing-8 !default;
$rkt-button-has-right-icon-margin-right: 0 !default;
$rkt-button-disabled-color: $rkt-gray-400 !default;
$rkt-button-disabled-background-color: $rkt-black-opacity-04 !default;
$rkt-button-disabled-on-dark-color: $rkt-gray-800 !default;
$rkt-button-disabled-on-dark-background-color: $rkt-white-opacity-300 !default;
$rkt-button-large-padding: $rkt-spacing-16 $rkt-spacing-24 !default;
$rkt-button-large-height: 56px !default;
$rkt-button-large-has-left-icon-padding-left: 18px !default;
$rkt-button-large-has-right-icon-padding-right: 18px !default;
$rkt-button-large-icon-width: 24px !default;
$rkt-button-large-icon-height: 24px !default;
$rkt-button-primary-background-color: $rkt-gray-800 !default;
$rkt-button-primary-color: $rkt-white !default;
$rkt-button-primary-hover-background-color: $rkt-red-500 !default;
$rkt-button-primary-active-background-color: $rkt-red-600 !default;
$rkt-button-primary-spinning-background-color: $rkt-gray-800 !default;
$rkt-button-primary-spinning-color: $rkt-white !default;
$rkt-button-primary-spinning-spinner-color: $rkt-white !default;
$rkt-button-primary-on-dark-active-color: $rkt-gray-800 !default;
$rkt-button-primary-on-dark-active-background-color: $rkt-white-opacity-600 !default;
$rkt-button-primary-on-dark-focus-color: $rkt-gray-800 !default;
$rkt-button-primary-on-dark-background-color: $rkt-white !default;
$rkt-button-primary-on-dark-color: $rkt-gray-800 !default;
$rkt-button-primary-on-dark-hover-background-color: $rkt-white-opacity-400 !default;
$rkt-button-primary-on-dark-hover-color: rgba(255, 255, 255, 0.88) !default;
$rkt-button-primary-on-dark-focus-background-color: $rkt-white !default;
$rkt-button-primary-on-dark-focus-box-shadow: 0 0 0 3px $rkt-white-opacity-600 !default;
$rkt-button-primary-on-dark-spinning-spinner-color: $rkt-gray-800 !default;
$rkt-button-secondary-color: $rkt-gray-800 !default;
$rkt-button-secondary-background-color: transparent !default;
$rkt-button-secondary-box-shadow: inset 0 0 0 1.5px $rkt-gray-800 !default;
$rkt-button-secondary-hover-background-color: $rkt-gray-100 !default;
$rkt-button-secondary-focus-box-shadow: inset 0 0 0 3px $rkt-gray-800 !default;
$rkt-button-secondary-active-background-color: $rkt-gray-200 !default;
$rkt-button-secondary-disabled-box-shadow: inset 0 0 0 1.5px $rkt-gray-400 !default;
$rkt-button-secondary-disabled-on-dark-color: $rkt-white-opacity-300 !default;
$rkt-button-secondary-disabled-on-dark-background-color: $rkt-gray-800 !default;
$rkt-button-secondary-disabled-on-dark-box-shadow: inset 0 0 0 1px $rkt-white-opacity-300 !default;
$rkt-button-secondary-spinning-background-color: transparent !default;
$rkt-button-secondary-spinning-color: $rkt-gray-800 !default;
$rkt-button-secondary-spinning-spinner-color: $rkt-gray-800 !default;
$rkt-button-secondary-on-dark-background-color: transparent !default;
$rkt-button-secondary-on-dark-box-shadow: inset 0 0 0 1px $rkt-white !default;
$rkt-button-secondary-on-dark-color: $rkt-white !default;
$rkt-button-secondary-on-dark-hover-background-color: $rkt-white !default;
$rkt-button-secondary-on-dark-hover-color: $rkt-gray-800 !default;
$rkt-button-secondary-on-dark-focus-background-color: $rkt-white-opacity-400 !default;
$rkt-button-secondary-on-dark-focus-opacity: unset !default;
$rkt-button-secondary-on-dark-focus-box-shadow: inset 0 0 0 3px $rkt-white !default;
$rkt-button-secondary-on-dark-focus-color: $rkt-white !default;
$rkt-button-secondary-on-dark-active-background-color: $rkt-white-opacity-400 !default;
$rkt-button-secondary-on-dark-active-color: $rkt-white !default;
$rkt-button-secondary-on-dark-spinning-spinner-color: $rkt-white !default;
$rkt-button-secondary-on-dark-spinning-box-shadow: inset 0 0 0 1px $rkt-gray-300 !default;
$rkt-button-tertiary-color: $rkt-gray-800 !default;
$rkt-button-tertiary-background-color: $rkt-white !default;
$rkt-button-tertiary-hover-background-color: $rkt-gray-100 !default;
$rkt-button-tertiary-focus-background-color: $rkt-gray-200 !default;
$rkt-button-tertiary-focus-box-shadow: inset 0 0 0 3px $rkt-gray-800 !default;
$rkt-button-tertiary-active-background-color: $rkt-gray-200 !default;
$rkt-button-tertiary-disabled-background-color: $rkt-black-opacity-04 !default;
$rkt-button-tertiary-spinning-background-color: $rkt-white !default;
$rkt-button-tertiary-spinning-color: $rkt-gray-800 !default;
$rkt-button-tertiary-spinning-spinner-color: $rkt-gray-800 !default;
$rkt-button-warning-outlined-box-shadow: inset 0 0 0 1.5px $rkt-yellow-900 !default;
$rkt-button-warning-outlined-color: $rkt-yellow-800 !default;
$rkt-button-warning-outlined-background-color: $rkt-white !default;
$rkt-button-warning-outlined-spinning-color: $rkt-yellow-800 !default;
$rkt-button-warning-outlined-spinning-background-color: $rkt-white !default;
$rkt-button-warning-outlined-spinning-spinner-color: $rkt-yellow-900 !default;
$rkt-button-warning-outlined-hover-background-color: $rkt-yellow-50 !default;
$rkt-button-warning-outlined-focus-box-shadow: inset 0 0 0 3px $rkt-yellow-900 !default;
$rkt-button-warning-outlined-active-background-color: $rkt-yellow-100 !default;
$rkt-button-warning-outlined-disabled-background-color: $rkt-black-opacity-04 !default;
$rkt-button-warning-outlined-disabled-box-shadow: inset 0 0 0 1.5px $rkt-gray-400 !default;
$rkt-button-warning-background-color: $rkt-yellow-400 !default;
$rkt-button-warning-color: $rkt-yellow-800 !default;
$rkt-button-warning-hover-background-color: $rkt-yellow-50 !default;
$rkt-button-warning-focus-box-shadow: inset 0 0 0 3px $rkt-yellow-900 !default;
$rkt-button-warning-active-background-color: $rkt-yellow-100 !default;
$rkt-button-warning-spinning-background-color: $rkt-yellow-400 !default;
$rkt-button-warning-spinning-color: $rkt-yellow-800 !default;
$rkt-button-warning-spinning-spinner-color: $rkt-yellow-800 !default;
$rkt-card-icon-container-align-items: center !default;
$rkt-card-icon-container-background-color: $rkt-white !default;
$rkt-card-icon-container-border-radius: 50% !default;
$rkt-card-icon-container-color: $rkt-gray-800 !default;
$rkt-card-icon-container-display: flex !default;
$rkt-card-icon-container-height: 48px !default;
$rkt-card-icon-container-justify-content: center !default;
$rkt-card-icon-container-width: 48px !default;
$rkt-card-transition: box-shadow 0.3s ease !default;
$rkt-card-box-sizing: border-box !default;
$rkt-card-width: 100% !default;
$rkt-card-border: 0 !default;
$rkt-card-secondary-color: $rkt-white !default;
$rkt-card-secondary-link-hover-background-color: $rkt-red-600 !default;
$rkt-card-secondary-background-color: $rkt-red-500 !default;
$rkt-card-secondary-border: 0 !default;
$rkt-card-white-link-icon-container-background-color: $rkt-gray-100 !default;
$rkt-card-white-link-hover-background-color: $rkt-white !default;
$rkt-card-white-link-hover-box-shadow: 0 0 0 1px $rkt-gray-300 !default;
$rkt-card-white-background-color: $rkt-white !default;
$rkt-card-white-box-shadow: inset 0 0 0 1px $rkt-gray-200 !default;
$rkt-card-background-color: $rkt-gray-50 !default;
$rkt-card-box-shadow: none !default;
$rkt-card-border-radius: 24px !default;
$rkt-card-padding: 32px !default;
$rkt-card-max-width: 400px !default;
$rkt-card-subtitle-font-family: $rkt-typography-font-family-body-14 !default;
$rkt-card-subtitle-color: $rkt-gray-600 !default;
$rkt-card-subtitle-font-size: $rkt-typography-font-size-body-14 !default;
$rkt-card-subtitle-font-weight: $rkt-typography-font-weight-label-14 !default;
$rkt-card-subtitle-line-height: $rkt-typography-line-height-body-14 !default;
$rkt-card-subtitle-margin: 0 !default;
$rkt-card-link-container-focus-visible-outline: none !default;
$rkt-card-link-container-focus-visible-box-shadow: 0 0 0 3px $rkt-gray-800 !default;
$rkt-card-link-container-max-width: 400px !default;
$rkt-card-link-container-width: 100% !default;
$rkt-card-link-container-box-sizing: border-box !default;
$rkt-card-link-container-display: inline-block !default;
$rkt-card-link-container-text-decoration: none !default;
$rkt-card-link-hover-cursor: pointer !default;
$rkt-card-link-hover-border: 0 !default;
$rkt-card-link-hover-box-shadow: 0 !default;
$rkt-card-link-hover-background-color: $rkt-gray-200 !default;
$rkt-card-image-padding: 0 !default;
$rkt-card-image-height: 220px !default;
$rkt-card-image-object-fit: unset !default;
$rkt-card-media-margin-top: 0 !default;
$rkt-card-heading-font-family: $rkt-typography-font-family-heading-18 !default;
$rkt-card-heading-color: $rkt-gray-800 !default;
$rkt-card-heading-font-size: $rkt-typography-font-size-heading-28 !default;
$rkt-card-heading-font-weight: $rkt-typography-font-weight-heading-28 !default;
$rkt-card-heading-line-height: $rkt-typography-line-height-heading-28 !default;
$rkt-card-heading-margin: 0 !default;
$rkt-card-button-icon-padding: 0 !default;
$rkt-card-content-flex-direction: column !default;
$rkt-card-content-display: flex !default;
$rkt-card-content-box-sizing: border-box !default;
$rkt-card-content-flex: 1 !default;
$rkt-card-content-font-family: $rkt-typography-font-family-body-16 !default;
$rkt-card-content-color: $rkt-gray-600 !default;
$rkt-card-content-font-size: $rkt-typography-font-size-body-18 !default;
$rkt-card-content-font-weight: $rkt-typography-font-weight-body-18 !default;
$rkt-card-content-line-height: $rkt-typography-line-height-body-18 !default;
$rkt-card-content-margin: 0 !default;
$rkt-card-on-dark-background: $rkt-white !default;
$rkt-card-on-dark-as-link-box-shadow: 0px 0px 0px 2px $rkt-white-opacity-100 !default;
$rkt-card-on-dark-as-link-border: 1px solid transparent !default;
$rkt-card-on-dark-as-link-hover-background: $rkt-gray-100 !default;
$rkt-card-on-dark-as-link-hover-box-shadow: 0px 0px 0px 3px $rkt-white-opacity-100 !default;
$rkt-card-on-dark-as-link-hover-border: 2px solid transparent !default;
$rkt-card-tall-min-height: 360px !default;
$rkt-card-tall-max-width: 302px !default;
$rkt-card-short-min-height: 188px !default;
$rkt-card-tiny-min-height: 104px !default;
$rkt-card-tiny-border-radius: 12px !default;
$rkt-card-tiny-content-line-height: $rkt-typography-line-height-body-16 !default;
$rkt-card-tiny-content-font-size: $rkt-typography-font-size-body-16 !default;
$rkt-card-tiny-heading-font-size: $rkt-typography-font-size-heading-20 !default;
$rkt-card-tiny-heading-line-height: $rkt-typography-line-height-heading-20 !default;
$rkt-card-tiny-padding: 24px !default;
$rkt-card-tiny-max-width: 256px !default;
$rkt-card-icon-color: $rkt-gray-800 !default;
$rkt-card-icon-height: 32px !default;
$rkt-card-icon-width: 32px !default;
$rkt-card-icon-stroke-width: 1.125 !default;
$rkt-card-full-width-max-width: 100% !default;
$rkt-centered-column-width: 1440px !default;
$rkt-checkbox-root-margin-right: 8px !default;
$rkt-checkbox-root-height: 40px !default;
$rkt-checkbox-root-width: 40px !default;
$rkt-checkbox-root-color: $rkt-gray-400 !default;
$rkt-checkbox-root-checked-color: $rkt-green-600 !default;
$rkt-checkbox-height: 24px !default;
$rkt-checkbox-width: 24px !default;
$rkt-checkbox-margin: 0 8px 0 0 !default;
$rkt-checkbox-display: flex !default;
$rkt-checkbox-justify-content: center !default;
$rkt-checkbox-stroke-width: 1 !default;
$rkt-checkbox-icon-right-margin: 0 0 0 8px !default;
$rkt-checkbox-icon-right-label-margin-left: 0 !default;
$rkt-checkbox-touch-target-height: 40px !default;
$rkt-checkbox-touch-target-width: 40px !default;
$rkt-checkbox-touch-target-border-radius: 50% !default;
$rkt-checkbox-hover-touch-target-background-color: $rkt-gray-100 !default;
$rkt-checkbox-hover-native-control-background-color: $rkt-white !default;
$rkt-checkbox-hover-checked-touch-target-background-color: $rkt-green-600 !default;
$rkt-checkbox-hover-checked-native-control-background-color: $rkt-green-600 !default;
$rkt-checkbox-focus-outline: none !default;
$rkt-checkbox-focus-touch-target-background-color: $rkt-gray-200 !default;
$rkt-checkbox-focus-native-control-background-color: $rkt-white !default;
$rkt-checkbox-focus-checked-native-control-background-color: $rkt-green-600 !default;
$rkt-checkbox-focus-icon-border-stroke-width: 2.5 !default;
$rkt-checkbox-focus-icon-border-x: 1.5 !default;
$rkt-checkbox-focus-icon-border-y: 1.5 !default;
$rkt-checkbox-focus-icon-border-rx: 6.5 !default;
$rkt-checkbox-focus-icon-border-height: 21px !default;
$rkt-checkbox-focus-icon-border-width: 21px !default;
$rkt-checkbox-checkmark-height: 14px !default;
$rkt-checkbox-checkmark-width: 14px !default;
$rkt-checkbox-checkmark-margin: auto !default;
$rkt-checkbox-indeterminate-width: 12px !default;
$rkt-checkbox-disabled-border-color: $rkt-gray-300 !default;
$rkt-checkbox-disabled-border-width: 1.5px !default;
$rkt-checkbox-disabled-color: $rkt-white !default;
$rkt-checkbox-disabled-label-color: $rkt-gray-400 !default;
$rkt-checkbox-disabled-icon-display: none !default;
$rkt-checkbox-label-font-family: $rkt-typography-font-family-label-16 !default;
$rkt-checkbox-label-padding: 0 !default;
$rkt-checkbox-label-color: $rkt-gray-800 !default;
$rkt-checkbox-label-font-size: $rkt-typography-font-size-label-16 !default;
$rkt-checkbox-label-font-weight: $rkt-typography-font-weight-400 !default;
$rkt-checkbox-label-line-height: $rkt-typography-line-height-label-16 !default;
$rkt-checkbox-frame-border: 1.5px $rkt-gray-400 solid !default;
$rkt-checkbox-frame-border-radius: 8px !default;
$rkt-checkbox-frame-background-color: $rkt-white !default;
$rkt-checkbox-frame-hover-border-color: $rkt-gray-400 !default;
$rkt-checkbox-frame-checked-hover-border-color: $rkt-green-600 !default;
$rkt-checkbox-frame-focus-border-color: $rkt-gray-800 !default;
$rkt-checkbox-frame-focus-box-shadow: inset 0 0 0 1.5px $rkt-gray-800 !default;
$rkt-checkbox-frame-focus-checked-box-shadow: none !default;
$rkt-checkbox-frame-checked-border-color: $rkt-green-600 !default;
$rkt-checkbox-frame-checked-background: $rkt-green-600 !default;
$rkt-checkbox-group-checkbox-margin-bottom: 6px !default;
$rkt-chip-icon-stroke-width: 2.5 !default;
$rkt-chip-cursor: pointer !default;
$rkt-chip-font-size: $rkt-typography-font-size-label-14 !default;
$rkt-chip-font-weight: $rkt-typography-font-weight-label-14 !default;
$rkt-chip-height: 32px !default;
$rkt-chip-padding: 0 14px !default;
$rkt-chip-border-radius: 1000px !default;
$rkt-chip-background-color: $rkt-white !default;
$rkt-chip-box-shadow: inset 0 0 0 1.5px $rkt-gray-800 !default;
$rkt-chip-label-padding: 0 !default;
$rkt-chip-label-color: $rkt-gray-800 !default;
$rkt-chip-hover-background-color: $rkt-gray-100 !default;
$rkt-chip-hover-icon-color: $rkt-chip-label-color !default;
$rkt-chip-hover-icon-background-color: $rkt-chip-hover-background-color !default;
$rkt-chip-hover-icon-box-shadow: 0 0 0 1.5px $rkt-gray-800 !default;
$rkt-chip-hover-icon-border-radius: 50% !default;
$rkt-chip-focus-background-color: $rkt-white !default;
$rkt-chip-focus-box-shadow: inset 0 0 0 3px $rkt-gray-800 !default;
$rkt-chip-focus-outline: none !default;
$rkt-chip-focus-icon-background-color: $rkt-chip-focus-background-color !default;
$rkt-chip-focus-icon-box-shadow: 0 0 0 1.5px $rkt-gray-800 !default;
$rkt-chip-focus-visible-icon-outline: none !default;
$rkt-chip-focus-visible-icon-border-radius: 100% !default;
$rkt-chip-active-background-color: $rkt-gray-200 !default;
$rkt-chip-active-box-shadow: inset 0 0 0 1.5px $rkt-gray-800 !default;
$rkt-chip-active-icon-background-color: $rkt-chip-active-background-color !default;
$rkt-chip-active-icon-box-shadow: 0 0 0 1.5px $rkt-gray-800 !default;
$rkt-chip-with-close-button-padding: 0 0 0 4px !default;
$rkt-chip-with-close-padding-right: 12px !default;
$rkt-chip-with-close-label-margin-right: 4px !default;
$rkt-chip-with-close-icon-margin: 0 !default;
$rkt-chip-with-close-icon-padding: 0 !default;
$rkt-chip-with-close-icon-height: 16px !default;
$rkt-chip-with-close-icon-width: 16px !default;
$rkt-chip-with-close-icon-padding-left: 4px !default;
$rkt-chip-with-close-icon-padding-right: 0 !default;
$rkt-chip-selected-background-color: $rkt-gray-800 !default;
$rkt-chip-selected-box-shadow: none !default;
$rkt-chip-selected-label-color: $rkt-white !default;
$rkt-chip-selected-icon-color: $rkt-white !default;
$rkt-chip-selected-hover-background-color: $rkt-gray-600 !default;
$rkt-chip-selected-focus-background-color: $rkt-gray-800 !default;
$rkt-chip-selected-focus-box-shadow: inset 0 0 0 3px $rkt-red-500 !default;
$rkt-chip-selected-active-background-color: $rkt-gray-200 !default;
$rkt-chip-selected-active-color: $rkt-gray-800 !default;
$rkt-chip-selected-active-box-shadow: inset 0 0 0 1.5px $rkt-gray-800 !default;
$rkt-chip-has-icon-padding-left: 12px !default;
$rkt-chip-has-icon-display: inline-flex !default;
$rkt-chip-has-icon-graphic-padding: 0 !default;
$rkt-chip-has-icon-graphic-width: fit-content !default;
$rkt-chip-has-icon-icon-opacity: 1 !default;
$rkt-chip-has-icon-icon-height: 16px !default;
$rkt-chip-has-icon-icon-width: 16px !default;
$rkt-chip-has-icon-icon-color: $rkt-gray-800 !default;
$rkt-chip-has-icon-icon-margin: 0 !default;
$rkt-chip-has-icon-icon-padding: 0 4px 0 0 !default;
$rkt-chip-has-icon-selected-icon-color: $rkt-white !default;
$rkt-chip-has-selected-icon-graphic-padding: 0 4px 0 0 !default;
$rkt-chip-has-selected-icon-display: inline-flex !default;
$rkt-chip-has-selected-icon-height: 16px !default;
$rkt-chip-has-selected-icon-width: 16px !default;
$rkt-chip-has-selected-icon-margin: 0 !default;
$rkt-chip-has-selected-icon-color: $rkt-white !default;
$rkt-chip-primary-background-color: $rkt-gray-800 !default;
$rkt-chip-primary-label-color: $rkt-white !default;
$rkt-chip-primary-hover-background-color: $rkt-gray-600 !default;
$rkt-chip-primary-hover-box-shadow: none !default;
$rkt-chip-primary-hover-icon-color: $rkt-chip-primary-label-color !default;
$rkt-chip-primary-hover-icon-background-color: $rkt-chip-primary-hover-background-color !default;
$rkt-chip-primary-hover-icon-box-shadow: 0 0 0 1.5px $rkt-white !default;
$rkt-chip-primary-focus-box-shadow: inset 0 0 0 3px $rkt-red-500 !default;
$rkt-chip-primary-focus-icon-background-color: $rkt-gray-800 !default;
$rkt-chip-primary-focus-icon-box-shadow: 0 0 0 1.5px $rkt-red-500 !default;
$rkt-chip-primary-active-background-color: $rkt-red-600 !default;
$rkt-chip-primary-active-box-shadow: none !default;
$rkt-chip-primary-active-icon-background-color: $rkt-chip-primary-active-background-color !default;
$rkt-chip-primary-active-icon-box-shadow: 0 0 0 1.5px $rkt-white !default;
$rkt-chip-disabled-background-color: $rkt-gray-100 !default;
$rkt-chip-disabled-box-shadow: inset 0 0 0 1.5px $rkt-gray-400 !default;
$rkt-chip-disabled-label-color: $rkt-gray-400 !default;
$rkt-chip-disabled-primary-box-shadow: none !default;
$rkt-chip-on-dark-background-color: $rkt-white-opacity-100 !default;
$rkt-chip-on-dark-border: 1px solid $rkt-gray-50 !default;
$rkt-chip-on-dark-hover-background-color: transparent !default;
$rkt-chip-on-dark-focus-background-color: $rkt-white-opacity-400 !default;
$rkt-chip-on-dark-focus-box-shadow: inset 0 0 0 1px $rkt-gray-50 !default;
$rkt-chip-on-dark-text-color: $rkt-white !default;
$rkt-chip-on-dark-icon-color: $rkt-white !default;
$rkt-chip-on-dark-selected-background-color: $rkt-white !default;
$rkt-chip-on-dark-selected-hover-background-color: transparent !default;
$rkt-chip-on-dark-selected-hover-color: $rkt-white !default;
$rkt-chip-on-dark-selected-focus-background-color: $rkt-white-opacity-100 !default;
$rkt-chip-on-dark-selected-focus-box-shadow: inset 0 0 0 1px $rkt-white !default;
$rkt-chip-on-dark-selected-focus-border-color: $rkt-white !default;
$rkt-chip-on-dark-selected-focus-color: $rkt-white !default;
$rkt-chip-on-dark-selected-text-color: $rkt-gray-800 !default;
$rkt-chip-on-dark-selected-icon-color: $rkt-gray-800 !default;
$rkt-chip-on-dark-disabled-background-color: $rkt-white-opacity-50 !default;
$rkt-chip-on-dark-disabled-border: 1px solid $rkt-gray-500 !default;
$rkt-chip-on-dark-disabled-text-color: $rkt-gray-500 !default;
$rkt-chip-on-dark-disabled-icon-color: $rkt-gray-500 !default;
$rkt-credit-score-chart-dial-path-stroke: rgba(0, 0, 0, 0.08) !default;
$rkt-credit-score-chart-dial-path-stroke-width: 8px !default;
$rkt-credit-score-chart-dial-path-stroke-linecap: round !default;
$rkt-credit-score-chart-dial-path-is-filled-stroke: $rkt-blue-400 !default;
$rkt-credit-score-chart-start-gradient-stop-color: $rkt-red-500 !default;
$rkt-credit-score-chart-end-gradient-stop-color: #6B3D90 !default;
$rkt-credit-score-chart-display: flex !default;
$rkt-credit-score-chart-flex-direction: column !default;
$rkt-credit-score-chart-dial-display: flex !default;
$rkt-credit-score-chart-dial-align-items: center !default;
$rkt-credit-score-chart-dial-justify-content: center !default;
$rkt-credit-score-chart-score-color: $rkt-gray-800 !default;
$rkt-credit-score-chart-score-transform: translateY(-10px) !default;
$rkt-credit-score-chart-score-position: absolute !default;
$rkt-credit-score-chart-score-letter-spacing: -1px !default;
$rkt-credit-score-chart-score-font-size: $rkt-typography-font-size-heading-48 !default;
$rkt-credit-score-chart-score-font-weight: $rkt-typography-font-weight-heading-48 !default;
$rkt-credit-score-chart-score-line-height: $rkt-typography-line-height-heading-48 !default;
$rkt-credit-score-chart-score-font-family: $rkt-typography-font-family-heading-48 !default;
$rkt-credit-score-chart-score-small-transform: translateY(-6px) !default;
$rkt-credit-score-chart-score-small-font-size: $rkt-typography-font-size-heading-36 !default;
$rkt-credit-score-chart-score-small-font-weight: $rkt-typography-font-weight-heading-36 !default;
$rkt-credit-score-chart-score-small-line-height: $rkt-typography-line-height-heading-36 !default;
$rkt-credit-score-chart-content-transform: translateY(-40px) !default;
$rkt-credit-score-chart-label-container-margin-bottom: 4px !default;
$rkt-credit-score-chart-label-container-justify-content: space-between !default;
$rkt-credit-score-chart-label-container-align-items: center !default;
$rkt-credit-score-chart-label-container-min-height: 32px !default;
$rkt-credit-score-chart-label-container-small-justify-content: center !default;
$rkt-credit-score-chart-change-indicator-max-width: 135px !default;
$rkt-credit-score-chart-change-indicator-text-flex: 1 !default;
$rkt-credit-score-chart-change-indicator-text-font-size: $rkt-typography-font-size-label-14 !default;
$rkt-credit-score-chart-change-indicator-text-font-weight: $rkt-typography-font-weight-label-14 !default;
$rkt-credit-score-chart-change-indicator-text-font-family: $rkt-typography-font-family-label-14 !default;
$rkt-credit-score-chart-change-indicator-text-line-height: $rkt-typography-line-height-label-14 !default;
$rkt-credit-score-chart-change-indicator-text-color: $rkt-gray-800 !default;
$rkt-credit-score-chart-change-indicator-icon-color: $rkt-gray-800 !default;
$rkt-credit-score-chart-change-indicator-icon-margin-right: $rkt-spacing-4 !default;
$rkt-credit-score-chart-change-indicator-icon-increase-color: $rkt-green-700 !default;
$rkt-credit-score-chart-change-indicator-icon-decrease-color: $rkt-yellow-900 !default;
$rkt-credit-score-chart-change-indicator-small-align-items: center !default;
$rkt-credit-score-chart-change-indicator-small-max-width: 125px !default;
$rkt-credit-score-chart-label-font-size: $rkt-typography-font-size-caption-12 !default;
$rkt-credit-score-chart-label-font-weight: $rkt-typography-font-weight-label-14 !default;
$rkt-credit-score-chart-label-line-height: $rkt-typography-line-height-caption-12 !default;
$rkt-credit-score-chart-label-color: $rkt-gray-600 !default;
$rkt-credit-score-chart-label-font-family: $rkt-typography-font-family-label-14 !default;
$rkt-credit-score-chart-label-margin: 0 !default;
$rkt-credit-score-chart-status-text-align: center !default;
$rkt-credit-score-chart-status-max-width: 185px !default;
$rkt-datepicker-min-width: 320px !default;
$rkt-datepicker-height: unset !default;
$rkt-datepicker-max-height: unset !default;
$rkt-datepicker-margin-top: 8px !default;
$rkt-datepicker-border-radius: 16px !default;
$rkt-datepicker-border: 1px solid $rkt-black-opacity-04 !default;
$rkt-datepicker-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.02) !default;
$rkt-datepicker-bg-color: $rkt-white !default;
$rkt-datepicker-calendar-height: auto !default;
$rkt-datepicker-ripple-opacity: 0 !default;
$rkt-datepicker-ripple-display: none !default;
$rkt-datepicker-divider-display: none !default;
$rkt-datepicker-icon-height: 48px !default;
$rkt-datepicker-icon-width: 48px !default;
$rkt-datepicker-icon-hover-ripple-bg-color: $rkt-gray-200 !default;
$rkt-datepicker-icon-hover-ripple-z-index: -1 !default;
$rkt-datepicker-button-hover-bg-color: $rkt-gray-200 !default;
$rkt-datepicker-button-focus-bg-color: transparent !default;
$rkt-datepicker-button-focus-box-shadow: inset 0 0 0 3px $rkt-gray-800 !default;
$rkt-datepicker-label-opacity: 0 !default;
$rkt-datepicker-header-padding: 24px 14.5px 16px !default;
$rkt-datepicker-header-margin: 0 !default;
$rkt-datepicker-calendar-header-padding: 24px 14px 16px 28px !default;
$rkt-datepicker-header-justify-content: space-between !default;
$rkt-datepicker-weekday-label-color: $rkt-gray-600 !default;
$rkt-datepicker-weekday-label-font-size: $rkt-typography-font-size-caption-12 !default;
$rkt-datepicker-weekday-label-font-weight: $rkt-typography-font-weight-label-14 !default;
$rkt-datepicker-weekday-label-height: 40px !default;
$rkt-datepicker-weekday-label-width: 44px !default;
$rkt-datepicker-weekday-label-padding: 0 0 4px 0 !default;
$rkt-datepicker-controls-margin: 0 !default;
$rkt-datepicker-controls-justify-content: space-between !default;
$rkt-datepicker-controls-text-transform: lowercase !default;
$rkt-datepicker-controls-color: $rkt-gray-800 !default;
$rkt-datepicker-controls-font-size: $rkt-typography-font-size-label-16 !default;
$rkt-datepicker-controls-font-weight: $rkt-typography-font-weight-label-16 !default;
$rkt-datepicker-controls-hover-bg-color: $rkt-gray-300 !default;
$rkt-datepicker-controls-focus-box-shadow: inset 0 0 0 3px $rkt-gray-800 !default;
$rkt-datepicker-controls-icon-fill: currentColor !default;
$rkt-datepicker-controls-icon-width: 24px !default;
$rkt-datepicker-controls-icon-margin: 0 !default;
$rkt-datepicker-controls-label-display: inline-block !default;
$rkt-datepicker-controls-label-padding-right: 6px !default;
$rkt-datepicker-controls-label-first-letter-text-transform: capitalize !default;
$rkt-datepicker-controls-arrow-color: $rkt-gray-800 !default;
$rkt-datepicker-controls-arrow-hover-bg-color: $rkt-gray-300 !default;
$rkt-datepicker-controls-arrow-focus-box-shadow: inset 0 0 0 3px $rkt-gray-800 !default;
$rkt-datepicker-controls-arrow-order: -1 !default;
$rkt-datepicker-current-day-border: 1px dashed $rkt-gray-300 !default;
$rkt-datepicker-selected-box-shadow: none !default;
$rkt-datepicker-selected-color: $rkt-white !default;
$rkt-datepicker-selected-bg-color: $rkt-gray-800 !default;
$rkt-datepicker-selected-border: none !default;
$rkt-datepicker-day-focus-bg-color: $rkt-white !default;
$rkt-datepicker-day-focus-color: $rkt-gray-800 !default;
$rkt-datepicker-day-focus-box-shadow: inset 0 0 0 3px $rkt-gray-800 !default;
$rkt-datepicker-content-padding: 0 14.5px 26px !default;
$rkt-datepicker-content-height: auto !default;
$rkt-datepicker-day-calendar-padding: 0 6px !default;
$rkt-datepicker-day-color: $rkt-gray-800 !default;
$rkt-datepicker-day-font-size: $rkt-typography-font-size-label-14 !default;
$rkt-datepicker-day-font-weight: $rkt-typography-font-weight-body-14 !default;
$rkt-datepicker-day-transition: none !default;
$rkt-datepicker-day-height: 44px !default;
$rkt-datepicker-day-width: 44px !default;
$rkt-datepicker-day-margin: 0 !default;
$rkt-datepicker-day-hover-bg-color: $rkt-gray-300 !default;
$rkt-datepicker-day-focus-outline: none !default;
$rkt-datepicker-preview-color: transparent !default;
$rkt-datepicker-preview-background: $rkt-gray-100 !default;
$rkt-datepicker-preview-start-border-top-left-radius: 999px !default;
$rkt-datepicker-preview-start-border-bottom-left-radius: 999px !default;
$rkt-datepicker-preview-start-left: 5% !default;
$rkt-datepicker-preview-start-width: 95% !default;
$rkt-datepicker-preview-end-border-top-right-radius: 999px !default;
$rkt-datepicker-preview-end-border-bottom-right-radius: 999px !default;
$rkt-datepicker-preview-end-width: 95% !default;
$rkt-datepicker-in-range-background: $rkt-gray-200 !default;
$rkt-datepicker-standalone-width: 327px !default;
$rkt-datepicker-standalone-padding: 0 !default;
$rkt-datepicker-standalone-box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.06) !default;
$rkt-datepicker-standalone-border-radius: 16px !default;
$rkt-datepicker-standalone-border: 1px solid rgba(0, 0, 0, 0.04) !default;
$rkt-datepicker-standalone-bg-color: $rkt-white !default;
$rkt-datepicker-standalone-breakpoint: 768px !default;
$rkt-datepicker-standalone-wide-width: 400px !default;
$rkt-datepicker-standalone-header-padding: 32px 40px 16px !default;
$rkt-datepicker-standalone-calendar-header-padding: 32px 40px 16px 48px !default;
$rkt-datepicker-standalone-content-padding: 0 32px 24px !default;
$rkt-datepicker-standalone-day-calendar-padding: 0 6px !default;
$rkt-datepicker-icon-padding-right: 9px !default;
$rkt-datepicker-icon-position: relative !default;
$rkt-datepicker-icon-top: -1px !default;
$rkt-datepicker-icon-padding: 0 !default;
$rkt-datepicker-inline-day-height: 44px !default;
$rkt-datepicker-inline-day-margin: 0 !default;
$rkt-datepicker-inline-day-breakpoint-margin: 0 2px !default;
$rkt-datepicker-inline-header-margin: 0 !default;
$rkt-datepicker-inline-header-breakpoint-margin: 0 2px !default;
$rkt-datepicker-inline-padding: 26px !default;
$rkt-dialog-border-radius: 24px !default;
$rkt-dialog-padding: $rkt-spacing-24 !default;
$rkt-dialog-box-shadow: $rkt-elevation-6 !default;
$rkt-dialog-actions-padding: 0 !default;
$rkt-dialog-actions-button-margin-reset: 0 !default;
$rkt-dialog-content-padding: 0 24px !default;
$rkt-dialog-content-background-color: $rkt-gray-50 !default;
$rkt-dialog-content-margin-bottom: 32px !default;
$rkt-dialog-content-height: 223px !default;
$rkt-dialog-content-box-sizing: border-box !default;
$rkt-dialog-content-text-color: $rkt-gray-800 !default;
$rkt-dialog-content-text-font-size: $rkt-typography-font-size-body-16 !default;
$rkt-dialog-content-text-line-height: $rkt-typography-line-height-body-16 !default;
$rkt-dialog-title-padding: 0 !default;
$rkt-dialog-title-margin-bottom: 8px !default;
$rkt-dialog-title-margin-top: 0 !default;
$rkt-dialog-title-height: unset !default;
$rkt-dialog-title-font-size: $rkt-typography-font-size-heading-24 !default;
$rkt-dialog-title-font-style: normal !default;
$rkt-dialog-title-font-weight: $rkt-typography-font-weight-heading-24 !default;
$rkt-dialog-title-font-family: $rkt-typography-font-family-title !default;
$rkt-dialog-title-line-height: 32px !default;
$rkt-dialog-title-color: $rkt-gray-800 !default;
$rkt-dialog-title-letter-spacing: unset !default;
$rkt-dialog-title-max-width: calc(100% - 24px) !default;
$rkt-dialog-large-padding: $rkt-spacing-24 0 $rkt-spacing-24 0 !default;
$rkt-dialog-large-padding-reset: 0 !default;
$rkt-dialog-large-actions-padding: $rkt-spacing-24 !default;
$rkt-dialog-large-actions-gap: 16px !default;
$rkt-dialog-large-title-padding: $rkt-spacing-24 $rkt-spacing-24 0 !default;
$rkt-dialog-large-title-margin-bottom: 8px !default;
$rkt-dialog-large-width: 768px !default;
$rkt-dialog-max-width: 100% !default;
$rkt-dialog-text-color: $rkt-gray-600 !default;
$rkt-dialog-text-margin: 0 !default;
$rkt-dialog-text-font-family: $rkt-typography-font-family-body-16 !default;
$rkt-dialog-text-font-size: $rkt-typography-font-size-body-16 !default;
$rkt-dialog-text-font-weight: $rkt-typography-font-weight-body-16 !default;
$rkt-dialog-text-line-height: $rkt-typography-line-height-body-16 !default;
$rkt-dialog-text-font-style: normal !default;
$rkt-dialog-box-sizing: border-box !default;
$rkt-dialog-width: 384px !default;
$rkt-dialog-medium-width: 512px !default;
$rkt-dialog-mobile-max-width: calc(100% - 48px) !default;
$rkt-dialog-mobile-margin: 24px !default;
$rkt-dialog-dismiss-button-align-self: flex-start !default;
$rkt-dialog-dismiss-button-margin: 4px 0 !default;
$rkt-dialog-dismiss-button-flex: 0 0 auto !default;
$rkt-dialog-dismiss-button-height: 24px !default;
$rkt-dialog-dismiss-button-focus-visible-box-shadow: 0 0 0 3px $rkt-gray-800 !default;
$rkt-dialog-dismiss-button-focus-visible-border-radius: 100% !default;
$rkt-dialog-dismiss-button-focus-visible-outline: none !default;
$rkt-dialog-dismiss-button-focus-visible-background: $rkt-gray-100 !default;
$rkt-dialog-header-icon-padding: 6.667px 5px !default;
$rkt-dialog-header-icon-width: 40px !default;
$rkt-dialog-header-icon-height: 40px !default;
$rkt-dialog-header-icon-flex-shrink: 0 !default;
$rkt-dialog-header-icon-stroke-width: 0.9 !default;
$rkt-dialog-small-width: 384px !default;
$rkt-dialog-header-height: 40px !default;
$rkt-divider-vertical-border-right-color: $rkt-gray-200 !default;
$rkt-divider-vertical-thick-border-right-color: $rkt-gray-100 !default;
$rkt-divider-vertical-border-right-width: 1px !default;
$rkt-divider-vertical-thick-border-right-width: 6px !default;
$rkt-divider-vertical-dark-border-right-color: $rkt-gray-500 !default;
$rkt-divider-vertical-on-dark-border-right-color: $rkt-divider-vertical-dark-border-right-color !default;
$rkt-divider-horizontal-border-bottom-width: 0 !default;
$rkt-divider-horizontal-thick-border-top-width: 6px !default;
$rkt-divider-horizontal-border-color: $rkt-gray-200 !default;
$rkt-divider-horizontal-thick-border-color: $rkt-gray-100 !default;
$rkt-divider-horizontal-border-top-width: 1px !default;
$rkt-divider-horizontal-on-dark-border-top-color: $rkt-gray-500 !default;
$rkt-fab-button-height: 48px !default;
$rkt-fab-button-width: 48px !default;
$rkt-fab-background-color: $rkt-white !default;
$rkt-fab-box-shadow: 0 10px 12px 0 rgba(0, 0, 0, 0.05) !default;
$rkt-fab-ripple-display: none !default;
$rkt-fab-ripple-color: none !default;
$rkt-fab-hover-background-color: $rkt-white !default;
$rkt-fab-hover-border: 1px solid $rkt-gray-200 !default;
$rkt-fab-hover-box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.08) !default;
$rkt-fab-focus-visible-background-color: $rkt-white !default;
$rkt-fab-focus-visible-border: 3px solid $rkt-gray-800 !default;
$rkt-fab-focus-visible-box-shadow: 0 10px 12px 0 rgba(0, 0, 0, 0.05) !default;
$rkt-fab-active-background-color: $rkt-gray-800 !default;
$rkt-fab-active-box-shadow: 0 10px 12px 0 rgba(0, 0, 0, 0.05) !default;
$rkt-fab-focus-background-color: $rkt-white !default;
$rkt-fab-focus-box-shadow: 0 10px 12px 0 rgba(0, 0, 0, 0.05) !default;
$rkt-fab-icon-color: $rkt-gray-800 !default;
$rkt-fab-icon-active-color: $rkt-white !default;
$rkt-file-uploader-background: $rkt-white !default;
$rkt-file-uploader-border-radius: 20px !default;
$rkt-file-uploader-overflow: hidden !default;
$rkt-file-uploader-padding: 24px !default;
$rkt-file-uploader-box-shadow: inset 0 0 0 1.5px $rkt-gray-300 !default;
$rkt-file-uploader-action-margin: 0 0 16px 0 !default;
$rkt-file-uploader-action-breakpoint-5-display: none !default;
$rkt-file-uploader-action-is-always-visible-display: block !default;
$rkt-file-uploader-action-item-box-sizing: border-box !default;
$rkt-file-uploader-action-item-cursor: pointer !default;
$rkt-file-uploader-action-item-background: $rkt-gray-50 !default;
$rkt-file-uploader-action-item-display: flex !default;
$rkt-file-uploader-action-item-flex-grow: 1 !default;
$rkt-file-uploader-action-item-flex-direction: column !default;
$rkt-file-uploader-action-item-align-items: center !default;
$rkt-file-uploader-action-item-border-radius: 16px !default;
$rkt-file-uploader-action-item-border: none !default;
$rkt-file-uploader-action-item-box-shadow: inset 0 0 0 1.5px $rkt-black-opacity-04 !default;
$rkt-file-uploader-action-item-padding: 16px !default;
$rkt-file-uploader-action-item-focus-visible-box-shadow: inset 0 0 0 3px $rkt-gray-800 !default;
$rkt-file-uploader-action-item-focus-visible-outline: none !default;
$rkt-file-uploader-action-item-is-disabled-background: $rkt-gray-200 !default;
$rkt-file-uploader-action-item-is-disabled-cursor: not-allowed !default;
$rkt-file-uploader-action-item-is-disabled-icon-color: $rkt-gray-400 !default;
$rkt-file-uploader-action-item-is-invalid-box-shadow: inset 0 0 0 1.5px $rkt-yellow-600 !default;
$rkt-file-uploader-action-item-text-font-family: $rkt-typography-font-family-label-16 !default;
$rkt-file-uploader-action-item-text-font-size: $rkt-typography-font-size-label-16 !default;
$rkt-file-uploader-action-item-text-font-weight: $rkt-typography-font-weight-label-16 !default;
$rkt-file-uploader-action-item-text-line-height: $rkt-typography-line-height-label-16 !default;
$rkt-file-uploader-action-item-text-color: $rkt-gray-800 !default;
$rkt-file-uploader-action-item-text-is-disabled-color: $rkt-gray-500 !default;
$rkt-file-uploader-action-icon-height: 24px !default;
$rkt-file-uploader-action-icon-width: 24px !default;
$rkt-file-uploader-action-icon-margin: 0 0 12px 0 !default;
$rkt-file-uploader-action-icon-color: $rkt-gray-800 !default;
$rkt-file-uploader-action-icon-is-disabled-color: $rkt-gray-500 !default;
$rkt-file-uploader-action-instructions-margin: 0 !default;
$rkt-file-uploader-action-instructions-font-family: $rkt-typography-font-family-caption-12 !default;
$rkt-file-uploader-action-instructions-font-size: $rkt-typography-font-size-caption-12 !default;
$rkt-file-uploader-action-instructions-font-weight: $rkt-typography-font-weight-caption-12 !default;
$rkt-file-uploader-action-instructions-line-height: $rkt-typography-line-height-caption-12 !default;
$rkt-file-uploader-action-instructions-color: $rkt-gray-600 !default;
$rkt-file-uploader-action-instructions-text-align: center !default;
$rkt-file-uploader-drop-area-background: $rkt-white !default;
$rkt-file-uploader-drop-area-border: 2px dashed $rkt-gray-300 !default;
$rkt-file-uploader-drop-area-border-radius: 24px !default;
$rkt-file-uploader-drop-area-padding: 24px 16px !default;
$rkt-file-uploader-drop-area-margin: 0 0 16px 0 !default;
$rkt-file-uploader-drop-area-flex-direction: column !default;
$rkt-file-uploader-drop-area-align-items: center !default;
$rkt-file-uploader-drop-area-display: none !default;
$rkt-file-uploader-drop-area-breakpoint-5-display: flex !default;
$rkt-file-uploader-drop-area-is-always-visible-display: flex !default;
$rkt-file-uploader-drop-area-is-dragging-border: 2px dashed $rkt-gray-600 !default;
$rkt-file-uploader-drop-area-is-dragging-background: $rkt-gray-100 !default;
$rkt-file-uploader-drop-area-is-dragging-cursor: pointer !default;
$rkt-file-uploader-drop-area-is-dragging-icon-color: #5B3C9C !default;
$rkt-file-uploader-drop-area-is-invalid-border: 2px dashed $rkt-yellow-600 !default;
$rkt-file-uploader-drop-area-is-disabled-border: 2px dashed $rkt-gray-300 !default;
$rkt-file-uploader-drop-area-is-disabled-background: $rkt-gray-50 !default;
$rkt-file-uploader-drop-area-is-disabled-cursor: no-drop !default;
$rkt-file-uploader-drop-area-is-disabled-icon-color: $rkt-gray-400 !default;
$rkt-file-uploader-drop-area-icon-color: $rkt-gray-800 !default;
$rkt-file-uploader-drop-area-icon-margin: 0 0 16px 0 !default;
$rkt-file-uploader-drop-area-action-margin: 0 !default;
$rkt-file-uploader-drop-area-action-color: $rkt-gray-800 !default;
$rkt-file-uploader-drop-area-action-font-family: $rkt-typography-font-family-label-16 !default;
$rkt-file-uploader-drop-area-action-font-size: $rkt-typography-font-size-label-16 !default;
$rkt-file-uploader-drop-area-action-font-weight: $rkt-typography-font-weight-label-16 !default;
$rkt-file-uploader-drop-area-action-line-height: $rkt-typography-line-height-label-16 !default;
$rkt-file-uploader-drop-area-action-is-disabled-color: $rkt-gray-400 !default;
$rkt-file-uploader-drop-area-instructions-margin: 8px 0 0 0 !default;
$rkt-file-uploader-drop-area-instructions-font-family: $rkt-typography-font-family-caption-12 !default;
$rkt-file-uploader-drop-area-instructions-font-size: $rkt-typography-font-size-caption-12 !default;
$rkt-file-uploader-drop-area-instructions-font-weight: $rkt-typography-font-weight-caption-12 !default;
$rkt-file-uploader-drop-area-instructions-line-height: $rkt-typography-line-height-caption-12 !default;
$rkt-file-uploader-drop-area-instructions-color: $rkt-gray-600 !default;
$rkt-file-uploader-drop-area-instructions-is-disabled-color: $rkt-gray-400 !default;
$rkt-file-uploader-list-680-breakpoint: 680px !default;
$rkt-file-uploader-list-size-tally-padding: 0 0 16px 0 !default;
$rkt-file-uploader-list-size-tally-display: flex !default;
$rkt-file-uploader-list-size-tally-align-items: end !default;
$rkt-file-uploader-list-size-tally-justify-content: flex-end !default;
$rkt-file-uploader-list-size-tally-color: $rkt-gray-600 !default;
$rkt-file-uploader-list-size-tally-font-family: $rkt-typography-font-family-body-14 !default;
$rkt-file-uploader-list-size-tally-font-size: $rkt-typography-font-size-caption-12 !default;
$rkt-file-uploader-list-size-tally-font-style: normal !default;
$rkt-file-uploader-list-size-tally-font-weight: $rkt-typography-font-weight-500 !default;
$rkt-file-uploader-list-size-tally-line-height: $rkt-typography-line-height-caption-12 !default;
$rkt-file-uploader-list-size-tally-is-invalid-color: $rkt-yellow-800 !default;
$rkt-file-uploader-list-size-tally-breakpoint-align-items: center !default;
$rkt-file-uploader-list-size-tally-breakpoint-padding: 0 24px 0 0 !default;
$rkt-file-uploader-list-content-list-style: none !default;
$rkt-file-uploader-list-content-margin: 0 0 16px 0 !default;
$rkt-file-uploader-list-content-padding: 0 !default;
$rkt-file-uploader-list-item-box-sizing: border-box !default;
$rkt-file-uploader-list-item-padding: 16px !default;
$rkt-file-uploader-list-item-display: grid !default;
$rkt-file-uploader-list-item-grid-template-rows: auto 1fr !default;
$rkt-file-uploader-list-item-grid-template-columns: auto 1fr !default;
$rkt-file-uploader-list-item-align-items: center !default;
$rkt-file-uploader-list-item-column-gap: 8px !default;
$rkt-file-uploader-list-item-error-background-color: $rkt-yellow-50 !default;
$rkt-file-uploader-list-item-error-border-radius: 8px !default;
$rkt-file-uploader-list-item-header-grid-row-start: 1 !default;
$rkt-file-uploader-list-item-header-grid-column-start: 2 !default;
$rkt-file-uploader-list-item-sub-header-display: flex !default;
$rkt-file-uploader-list-item-sub-header-justify-content: space-between !default;
$rkt-file-uploader-list-item-sub-header-grid-row-start: 2 !default;
$rkt-file-uploader-list-item-sub-header-grid-column-start: 2 !default;
$rkt-file-uploader-list-item-sub-header-gap: 24px !default;
$rkt-file-uploader-list-item-position: relative !default;
$rkt-file-uploader-list-item-after-position: absolute !default;
$rkt-file-uploader-list-item-after-bottom: 0 !default;
$rkt-file-uploader-list-item-after-right: 0 !default;
$rkt-file-uploader-list-item-after-height: 1px !default;
$rkt-file-uploader-list-item-after-background: $rkt-gray-100 !default;
$rkt-file-uploader-list-item-after-padding: 0 0 0 48px !default;
$rkt-file-uploader-list-item-after-error-width: calc(100% - 94px) !default;
$rkt-file-uploader-list-item-after-last-item-breakpoint-width: 100% !default;
$rkt-file-uploader-list-item-after-last-item-breakpoint-padding: 0 !default;
$rkt-file-uploader-list-item-680-breakpoint-sub-header-justify-content: flex-end !default;
$rkt-file-uploader-list-item-680-breakpoint-grid-row-start: 1 !default;
$rkt-file-uploader-list-item-680-breakpoint-grid-column-start: auto !default;
$rkt-file-uploader-list-item-status-display: flex !default;
$rkt-file-uploader-list-item-status-icon-color: $rkt-green-500 !default;
$rkt-file-uploader-list-item-status-icon-error-color: $rkt-yellow-600 !default;
$rkt-file-uploader-list-item-status-in-progress-width: 24px !default;
$rkt-file-uploader-list-item-status-in-progress-height: 24px !default;
$rkt-file-uploader-list-item-status-in-progress-display: flex !default;
$rkt-file-uploader-list-item-status-in-progress-align-items: center !default;
$rkt-file-uploader-list-item-status-in-progress-justify-content: center !default;
$rkt-file-uploader-list-item-name-font-family: $rkt-typography-font-family-body-14 !default;
$rkt-file-uploader-list-item-name-font-weight: $rkt-typography-font-weight-body-14 !default;
$rkt-file-uploader-list-item-name-font-size: $rkt-typography-font-size-body-14 !default;
$rkt-file-uploader-list-item-name-color: $rkt-gray-800 !default;
$rkt-file-uploader-list-item-name-word-break: break-word !default;
$rkt-file-uploader-list-item-size-font-family: $rkt-typography-font-family-body-14 !default;
$rkt-file-uploader-list-item-size-font-size: $rkt-typography-font-size-body-14 !default;
$rkt-file-uploader-list-item-size-line-height: $rkt-typography-line-height-body-14 !default;
$rkt-file-uploader-list-item-size-color: $rkt-gray-800 !default;
$rkt-file-uploader-list-item-size-680-breakpoint-padding: 0 75px 0 0 !default;
$rkt-file-uploader-list-item-actions-display: flex !default;
$rkt-file-uploader-list-item-actions-gap: 24px !default;
$rkt-file-uploader-list-item-action-font-weight: $rkt-typography-font-weight-500 !default;
$rkt-file-uploader-list-item-action-line-height: $rkt-typography-line-height-body-14 !default;
$rkt-file-uploader-list-error-message-color: $rkt-yellow-800 !default;
$rkt-file-uploader-list-error-message-font-family: $rkt-typography-font-family-body-14 !default;
$rkt-file-uploader-list-error-message-font-size: $rkt-typography-font-size-body-14 !default;
$rkt-file-uploader-list-error-message-line-height: $rkt-typography-line-height-body-14 !default;
$rkt-file-uploader-list-error-message-padding: 0 16px 0 0 !default;
$rkt-footer-divider-border-bottom-color: $rkt-white-opacity-300 !default;
$rkt-footer-divider-border-top-color: $rkt-white-opacity-300 !default;
$rkt-footer-brands-max-width: 343px !default;
$rkt-footer-toggle-color: $rkt-white !default;
$rkt-footer-toggle-hover-color: #E04250 !default;
$rkt-footer-toggle-content-text-color: $rkt-gray-50 !default;
$rkt-footer-toggle-focus-visible-outline-color: $rkt-white !default;
$rkt-footer-link-font-weight: $rkt-typography-font-weight-500 !default;
$rkt-footer-link-visited-color: rgba(255, 255, 255, 0.88) !default;
$rkt-footer-link-color: rgba(255, 255, 255, 0.88) !default;
$rkt-footer-link-focus-visible-text-decoration: none !default;
$rkt-footer-link-hover-color: #E04250 !default;
$rkt-footer-link-hover-text-decoration: underline !default;
$rkt-footer-disclosures-trigger-margin-left: -6px !default;
$rkt-footer-disclosures-box-sizing: border-box !default;
$rkt-footer-disclosures-background-color: $rkt-gray-50 !default;
$rkt-footer-disclosures-margin: 0 auto !default;
$rkt-footer-disclosures-toggle-max-width: 1440px !default;
$rkt-footer-disclosures-toggle-box-sizing: border-box !default;
$rkt-footer-disclosures-toggle-margin: 0 auto !default;
$rkt-footer-disclosures-toggle-padding: 20px 30px !default;
$rkt-footer-disclosures-toggle-content-padding-top: 20px !default;
$rkt-footer-disclosures-toggle-content-padding-bottom: 20px !default;
$rkt-footer-awards-padding: 60px 0 0 0 !default;
$rkt-footer-awards-gap: 60px !default;
$rkt-footer-medium-breakpoint-awards-padding: 120px 0 0 0 !default;
$rkt-footer-medium-breakpoint-awards-gap: 120px !default;
$rkt-footer-section-heading-font-family: $rkt-typography-font-family-body-16 !default;
$rkt-footer-section-heading-font-size: 1.25rem !default;
$rkt-footer-section-heading-font-weight: $rkt-typography-font-weight-500 !default;
$rkt-footer-section-heading-line-height: 28px !default;
$rkt-footer-section-heading-margin: 0 0 24px 0 !default;
$rkt-footer-section-heading-color: $rkt-white !default;
$rkt-footer-apps-gap: 30px !default;
$rkt-footer-apps-padding: 0 0 60px 0 !default;
$rkt-footer-app-google-width: 166.796px !default;
$rkt-footer-app-google-height: 49.421px !default;
$rkt-footer-app-apple-width: 147.848px !default;
$rkt-footer-app-apple-height: 49.421px !default;
$rkt-footer-app-width: 40px !default;
$rkt-footer-app-height: 40px !default;
$rkt-footer-app-position: relative !default;
$rkt-footer-app-hover-color: $rkt-gray-700 !default;
$rkt-footer-app-focus-visible-outline: 0 !default;
$rkt-footer-app-focus-visible-width: 50px !default;
$rkt-footer-app-focus-visible-height: 50px !default;
$rkt-footer-app-focus-visible-border-radius: 50% !default;
$rkt-footer-app-focus-visible-box-shadow: inset 0 0 0 3px $rkt-white !default;
$rkt-footer-app-focus-visible-position: absolute !default;
$rkt-footer-app-color: $rkt-gray-800 !default;
$rkt-footer-app-display: inline-flex !default;
$rkt-footer-medium-breakpoint-app-width: 50px !default;
$rkt-footer-medium-breakpoint-app-height: 50px !default;
$rkt-footer-medium-breakpoint-app-google-width: 189.132px !default;
$rkt-footer-medium-breakpoint-app-google-height: 56px !default;
$rkt-footer-medium-breakpoint-app-apple-width: 168px !default;
$rkt-footer-medium-breakpoint-app-apple-height: 56px !default;
$rkt-footer-medium-breakpoint-apps-gap: 80px !default;
$rkt-footer-medium-breakpoint-apps-padding: 0 0 120px 0 !default;
$rkt-footer-medium-breakpoint: 768px !default;
$rkt-footer-extra-large-breakpoint: 1290px !default;
$rkt-footer-medium-breakpoint-content-padding: 120px 80px 80px 80px !default;
$rkt-footer-medium-breakpoint-disclosures-padding: 30px 80px !default;
$rkt-footer-large-breakpoint-disclosures-toggle-content-padding-bottom: 34px !default;
$rkt-footer-large-breakpoint-disclosures-padding: 30px 80px !default;
$rkt-footer-large-breakpoint-content-padding: 120px 80px 150px 80px !default;
$rkt-footer-medium-breakpoint-section-heading-margin: 0 0 30px 0 !default;
$rkt-footer-medium-breakpoint-section-heading-font-size: 1.25rem !default;
$rkt-footer-medium-breakpoint-section-heading-line-height: 32px !default;
$rkt-footer-large-breakpoint: 1080px !default;
$rkt-footer-content-flex-direction: column !default;
$rkt-footer-content-display: flex !default;
$rkt-footer-content-padding: 60px 24px 60px 24px !default;
$rkt-footer-content-margin: 0 auto !default;
$rkt-footer-content-max-width: 1440px !default;
$rkt-footer-content-box-sizing: border-box !default;
$rkt-footer-brand-image-height: auto !default;
$rkt-footer-brand-image-margin-bottom: 22px !default;
$rkt-footer-background-color: $rkt-gray-800 !default;
$rkt-footer-body-text-color: $rkt-white !default;
$rkt-footer-body-text-font-family: $rkt-typography-font-family-body-14 !default;
$rkt-footer-body-text-font-size: $rkt-typography-font-size-body-14 !default;
$rkt-footer-body-text-font-weight: $rkt-typography-font-weight-body-14 !default;
$rkt-footer-body-text-line-height: $rkt-typography-line-height-body-14 !default;
$rkt-footer-body-text-margin: 0 !default;
$rkt-footer-medium-breakpoint-link-sections-gap: 100px !default;
$rkt-footer-large-breakpoint-link-sections-gap: 200px !default;
$rkt-footer-link-sections-gap: 60px !default;
$rkt-footer-link-sections-padding-bottom: 80px !default;
$rkt-footer-link-columns-gap: 56px !default;
$rkt-footer-medium-breakpoint-link-columns-gap: 64px !default;
$rkt-highlight-board-breakpoint: 30rem !default;
$rkt-highlight-board-content-width: 30rem !default;
$rkt-highlight-board-type-reduction-percentage: 0.8 !default;
$rkt-highlight-board-height: 31.25rem !default;
$rkt-highlight-board-color: $rkt-white !default;
$rkt-highlight-board-content-box-color: $rkt-white !default;
$rkt-huge-checkbox-large-breakpoint: 768px !default;
$rkt-huge-checkbox-min-height: 64px !default;
$rkt-huge-checkbox-max-width: 343px !default;
$rkt-huge-checkbox-border-radius: 16px !default;
$rkt-huge-checkbox-justify-content: center !default;
$rkt-huge-checkbox-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.04) !default;
$rkt-huge-checkbox-background: $rkt-gray-50 !default;
$rkt-huge-checkbox-display: flex !default;
$rkt-huge-checkbox-label-color: $rkt-gray-800 !default;
$rkt-huge-checkbox-label-font-size: $rkt-typography-font-size-label-16 !default;
$rkt-huge-checkbox-label-font-style: normal !default;
$rkt-huge-checkbox-label-font-weight: $rkt-typography-font-weight-label-16 !default;
$rkt-huge-checkbox-label-line-height: $rkt-typography-line-height-label-16 !default;
$rkt-huge-checkbox-label-large-font-size: $rkt-typography-font-size-label-18 !default;
$rkt-huge-checkbox-label-text-right: 4px !default;
$rkt-huge-checkbox-label-text-position: relative !default;
$rkt-huge-checkbox-label-text-large-right: 0 !default;
$rkt-huge-checkbox-label-angular-padding: 16px 20px 16px 20px !default;
$rkt-huge-checkbox-label-angular-width: 100% !default;
$rkt-huge-checkbox-label-angular-large-padding: 38px 36px !default;
$rkt-huge-checkbox-label-react-width: 100% !default;
$rkt-huge-checkbox-label-react-display: flex !default;
$rkt-huge-checkbox-label-react-padding: 12px 12px 12px 24px !default;
$rkt-huge-checkbox-label-react-padding-right: 16px !default;
$rkt-huge-checkbox-label-react-margin: 0 !default;
$rkt-huge-checkbox-label-react-justify-content: space-between !default;
$rkt-huge-checkbox-label-react-large-padding: 30px 36px !default;
$rkt-huge-checkbox-checkbox-react-position: relative !default;
$rkt-huge-checkbox-checkbox-react-left: -2px !default;
$rkt-huge-checkbox-checkbox-react-large-left: 7px !default;
$rkt-huge-checkbox-checkbox-react-margin: 0 !default;
$rkt-huge-checkbox-checkbox-angular-width: 100% !default;
$rkt-huge-checkbox-checkbox-angular-form-field-width: 100% !default;
$rkt-huge-checkbox-checkbox-angular-form-field-display: flex !default;
$rkt-huge-checkbox-checkbox-angular-form-field-justify-content: space-between !default;
$rkt-huge-checkbox-checkbox-angular-margin: 0 !default;
$rkt-huge-checkbox-checkbox-angular-padding: 20px !default;
$rkt-huge-checkbox-checkbox-angular-right: 5px !default;
$rkt-huge-checkbox-checkbox-angular-position: relative !default;
$rkt-huge-checkbox-checkbox-angular-large-padding: 36px !default;
$rkt-huge-checkbox-checkbox-angular-large-right: 4px !default;
$rkt-huge-checkbox-disabled-label-color: $rkt-gray-400 !default;
$rkt-huge-checkbox-disabled-background: $rkt-gray-200 !default;
$rkt-huge-checkbox-hover-box-shadow: inset 0 0 0 1px $rkt-gray-400 !default;
$rkt-huge-checkbox-focus-box-shadow: inset 0 0 0 3px $rkt-gray-800 !default;
$rkt-huge-checkbox-selected-background: $rkt-green-50 !default;
$rkt-huge-checkbox-selected-box-shadow: inset 0 0 0 1.5px $rkt-green-600 !default;
$rkt-huge-checkbox-with-description-min-height: 64px !default;
$rkt-huge-checkbox-with-description-label-large-padding: 26px 30px 29px !default;
$rkt-huge-checkbox-with-description-react-margin-right: 0 !default;
$rkt-huge-checkbox-with-description-react-top: 1px !default;
$rkt-huge-checkbox-with-description-react-large-margin-right: 0 !default;
$rkt-huge-checkbox-with-description-react-large-left: -1px !default;
$rkt-huge-checkbox-with-description-react-label-left: 4px !default;
$rkt-huge-checkbox-with-description-react-label-padding: 16px 16px 19px 20px !default;
$rkt-huge-checkbox-with-description-react-label-large-padding: 28px 32px 27px !default;
$rkt-huge-checkbox-with-description-react-label-large-bottom: 2px !default;
$rkt-huge-checkbox-with-description-react-label-position: relative !default;
$rkt-huge-checkbox-with-description-react-label-checkbox-description-position: relative !default;
$rkt-huge-checkbox-with-description-react-label-checkbox-description-right: 4px !default;
$rkt-huge-checkbox-with-description-react-label-checkbox-description-large-right: 0 !default;
$rkt-huge-checkbox-with-description-react-label-checkbox-description-large-top: 1px !default;
$rkt-huge-checkbox-with-description-description-color: $rkt-gray-800 !default;
$rkt-huge-checkbox-with-description-description-font-size: $rkt-typography-font-size-body-16 !default;
$rkt-huge-checkbox-with-description-description-font-style: normal !default;
$rkt-huge-checkbox-with-description-description-font-weight: $rkt-typography-font-weight-body-16 !default;
$rkt-huge-checkbox-with-description-description-line-height: 24px !default;
$rkt-huge-checkbox-with-description-description-disabled-color: $rkt-gray-400 !default;
$rkt-huge-checkbox-with-description-description-top: 1px !default;
$rkt-huge-checkbox-with-description-description-position: relative !default;
$rkt-huge-checkbox-with-description-checkbox-label-padding: 16px 16px 19px 20px !default;
$rkt-huge-checkbox-with-description-checkbox-label-large-position: relative !default;
$rkt-huge-checkbox-with-description-checkbox-label-large-left: 6px !default;
$rkt-huge-checkbox-stylized-angular-input-display: flex !default;
$rkt-huge-checkbox-stylized-angular-input-label-width: 100% !default;
$rkt-huge-checkbox-stylized-angular-input-label-margin: 0 !default;
$rkt-huge-checkbox-stylized-horizontal-angular-padding: 17px 20px !default;
$rkt-huge-checkbox-stylized-horizontal-angular-justify-content: space-between !default;
$rkt-huge-checkbox-stylized-horizontal-angular-position: relative !default;
$rkt-huge-checkbox-stylized-horizontal-angular-top: 3px !default;
$rkt-huge-checkbox-stylized-horizontal-angular-large-padding: 35px 36px 36px !default;
$rkt-huge-checkbox-stylized-horizontal-angular-large-top: 2.5px !default;
$rkt-huge-checkbox-stylized-horizontal-react-checkbox-left: 4px !default;
$rkt-huge-checkbox-stylized-horizontal-react-checkbox-large-left: 8px !default;
$rkt-huge-checkbox-stylized-horizontal-react-checkbox-large-bottom: 1px !default;
$rkt-huge-checkbox-stylized-horizontal-react-checkbox-position: relative !default;
$rkt-huge-checkbox-stylized-horizontal-label-position: relative !default;
$rkt-huge-checkbox-stylized-horizontal-label-padding: 12px 20px !default;
$rkt-huge-checkbox-stylized-horizontal-label-left: 4px !default;
$rkt-huge-checkbox-stylized-horizontal-label-large-left: 0 !default;
$rkt-huge-checkbox-stylized-horizontal-label-large-bottom: -0.5px !default;
$rkt-huge-checkbox-stylized-horizontal-label-large-padding: 30px 36px 30px !default;
$rkt-huge-checkbox-stylized-vertical-checkbox-label-flex-direction: column !default;
$rkt-huge-checkbox-stylized-vertical-checkbox-label-align-items: center !default;
$rkt-huge-checkbox-stylized-vertical-checkbox-label-position: relative !default;
$rkt-huge-checkbox-stylized-vertical-checkbox-label-top: 3px !default;
$rkt-huge-checkbox-stylized-vertical-checkbox-label-padding: 13px 36px 13px 36px !default;
$rkt-huge-checkbox-stylized-vertical-checkbox-label-large-padding: 24px 36px 23px 36px !default;
$rkt-huge-checkbox-stylized-vertical-label-flex-direction: column !default;
$rkt-huge-checkbox-stylized-vertical-label-position: relative !default;
$rkt-huge-checkbox-stylized-vertical-label-padding: 6px 36px 10px 36px !default;
$rkt-huge-checkbox-stylized-vertical-label-left: 4px !default;
$rkt-huge-checkbox-stylized-vertical-label-large-padding: 16px 44px 19px 36px !default;
$rkt-huge-checkbox-stylized-vertical-label-checkbox-left: unset !default;
$rkt-huge-checkbox-stylized-vertical-label-checkbox-right: 4px !default;
$rkt-huge-checkbox-stylized-vertical-label-checkbox-bottom: 4px !default;
$rkt-huge-checkbox-stylized-vertical-label-checkbox-large-left: 0 !default;
$rkt-huge-checkbox-stylized-vertical-label-checkbox-large-bottom: 4px !default;
$rkt-huge-checkbox-stylized-vertical-label-checkbox-icon-position: relative !default;
$rkt-huge-checkbox-stylized-vertical-label-checkbox-icon-bottom: 6px !default;
$rkt-huge-checkbox-stylized-vertical-label-checkbox-icon-large-bottom: 7px !default;
$rkt-huge-checkbox-stylized-icon-color: $rkt-gray-600 !default;
$rkt-huge-checkbox-stylized-icon-disabled-color: $rkt-gray-400 !default;
$rkt-huge-input-container-max-width: 37.5rem !default;
$rkt-huge-input-container-width: 100% !default;
$rkt-huge-radio-radio-background: $rkt-white !default;
$rkt-huge-radio-radio-hover-background: $rkt-gray-200 !default;
$rkt-huge-radio-radio-selected-fill: $rkt-green-600 !default;
$rkt-huge-radio-radio-focus-visible-left: 8.5px !default;
$rkt-huge-radio-flex-direction: column !default;
$rkt-huge-radio-justify-content: center !default;
$rkt-huge-radio-width: 100% !default;
$rkt-huge-radio-content-margin: 0 !default;
$rkt-huge-radio-content-color: $rkt-gray-800 !default;
$rkt-huge-radio-content-font-size: $rkt-typography-font-size-body-16 !default;
$rkt-huge-radio-content-line-height: $rkt-typography-line-height-body-16 !default;
$rkt-huge-radio-content-font-family: $rkt-typography-font-family-body-16 !default;
$rkt-huge-radio-content-font-weight: $rkt-typography-font-weight-body-16 !default;
$rkt-huge-radio-color: $rkt-gray-800 !default;
$rkt-huge-radio-background-color: $rkt-gray-50 !default;
$rkt-huge-radio-min-height: 64px !default;
$rkt-huge-radio-large-breakpoint-min-height: 100px !default;
$rkt-huge-radio-large-breakpoint: 768px !default;
$rkt-huge-radio-max-width: 343px !default;
$rkt-huge-radio-container-max-width: 37.5rem !default;
$rkt-huge-radio-form-field-align-items: stretch !default;
$rkt-huge-radio-align-items: center !default;
$rkt-huge-radio-align-items-input: center !default;
$rkt-huge-radio-padding-input: 20px 16px !default;
$rkt-huge-radio-width-input: 100% !default;
$rkt-huge-radio-display: flex !default;
$rkt-huge-radio-flex-input: 1 !default;
$rkt-huge-radio-display-input: flex !default;
$rkt-huge-radio-transition: border 0.2s ease-in-out, background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out !default;
$rkt-huge-radio-border-radius: 16px !default;
$rkt-huge-radio-flex-basis: 100% !default;
$rkt-huge-radio-line-height: 24px !default;
$rkt-huge-radio-font-family: $rkt-typography-font-family-label-16 !default;
$rkt-huge-radio-font-size: $rkt-typography-font-size-body-16 !default;
$rkt-huge-radio-large-breakpoint-font-size: $rkt-typography-font-size-label-18 !default;
$rkt-huge-radio-font-weight: $rkt-typography-font-weight-label-18 !default;
$rkt-huge-radio-hover-box-shadow: inset 0 0 0 1px $rkt-gray-400 !default;
$rkt-huge-radio-hover-background-color: $rkt-gray-50 !default;
$rkt-huge-radio-keyboard-focus-box-shadow: inset 0 0 0 3px $rkt-gray-900 !default;
$rkt-huge-radio-react-label-padding: 16px 20px !default;
$rkt-huge-radio-react-label-padding-limit: 10px 20px !default;
$rkt-huge-radio-input-padding: 0 !default;
$rkt-huge-radio-focus-input-padding: 8px !default;
$rkt-huge-radio-hover-input-left: 8px !default;
$rkt-huge-radio-input-padding-right: 16px !default;
$rkt-huge-radio-label-padding: 16px 16px 16px 20px !default;
$rkt-huge-radio-radio-padding: 20px 20px 20px 0 !default;
$rkt-huge-radio-radio-align-self: center !default;
$rkt-huge-radio-large-breakpoint-radio-padding: 37px 36px 37px 0 !default;
$rkt-huge-radio-margin: 0 !default;
$rkt-huge-radio-large-breakpoint-angular-label-padding: 24px 16px 24px 36px !default;
$rkt-huge-radio-large-breakpoint-label-padding: 24px 36px !default;
$rkt-huge-radio-box-shadow: inset 0 0 0 1px rgba(0, 0, 0, 0.04) !default;
$rkt-huge-radio-selected-background-color: $rkt-green-50 !default;
$rkt-huge-radio-selected-box-shadow: inset 0 0 0 1.5px $rkt-green-600 !default;
$rkt-huge-radio-stylized-icon-label-flex: 1 !default;
$rkt-huge-radio-stylized-label-flex-direction: row !default;
$rkt-huge-radio-stylized-label-justify-content: unset !default;
$rkt-huge-radio-stylized-label-padding: 20px !default;
$rkt-huge-radio-stylized-large-breakpoint-label-padding: 20px 36px !default;
$rkt-huge-radio-stylized-icon-color: $rkt-gray-600 !default;
$rkt-huge-radio-stylized-vertical-min-height: 80px !default;
$rkt-huge-radio-stylized-vertical-justify-content: center !default;
$rkt-huge-radio-stylized-vertical-flex-direction: column !default;
$rkt-huge-radio-stylized-vertical-padding: 10px 36px 10px 36px !default;
$rkt-huge-radio-stylized-vertical-large-breakpoint-padding: 16px 36px !default;
$rkt-huge-radio-stylized-vertical-text-align: center !default;
$rkt-huge-radio-stylized-vertical-form-field-padding: 0 !default;
$rkt-huge-radio-stylized-vertical-icon-margin-bottom: 12px !default;
$rkt-huge-radio-stylized-label-padding-right: 0 !default;
$rkt-huge-radio-icon-flex-shrink: 0 !default;
$rkt-huge-radio-disabled-color: $rkt-gray-400 !default;
$rkt-huge-radio-disabled-box-shadow: $rkt-huge-radio-box-shadow !default;
$rkt-huge-radio-disabled-background-color: $rkt-gray-200 !default;
$rkt-huge-radio-disabled-stroke: $rkt-gray-300 !default;
$rkt-icon-toggle-transition: transform 0.3s !default;
$rkt-icon-toggle-transform: rotate(-180deg)  !default;
$rkt-input-border-radius: 16px !default;
$rkt-input-background-color: $rkt-gray-50 !default;
$rkt-input-hover-background-color: $rkt-gray-100 !default;
$rkt-input-hover-placeholder-color: $rkt-gray-600 !default;
$rkt-input-disabled-background-color: $rkt-gray-50 !default;
$rkt-input-width: 278px !default;
$rkt-input-height: 68px !default;
$rkt-input-border-bottom: none !default;
$rkt-input-label-color: $rkt-gray-800 !default;
$rkt-input-label-font-size: $rkt-typography-font-size-caption-12 !default;
$rkt-input-label-font-weight: $rkt-typography-font-weight-500 !default;
$rkt-input-label-line-height: 16px !default;
$rkt-input-label-disabled-color: $rkt-gray-300 !default;
$rkt-input-placeholder-color: $rkt-gray-500 !default;
$rkt-input-placeholder-font-size: $rkt-typography-font-size-label-16 !default;
$rkt-input-placeholder-font-weight: $rkt-typography-font-weight-400 !default;
$rkt-input-placeholder-line-height: 24px !default;
$rkt-input-placeholder-focus-color: transparent !default;
$rkt-input-placeholder-disabled-color: $rkt-gray-300 !default;
$rkt-input-prefix-color: $rkt-gray-800 !default;
$rkt-input-prefix-font-size: $rkt-typography-font-size-label-16 !default;
$rkt-input-prefix-font-weight: $rkt-typography-font-weight-500 !default;
$rkt-input-prefix-line-height: $rkt-typography-line-height-label-16 !default;
$rkt-input-suffix-react-padding-right: 9px !default;
$rkt-input-suffix-angular-padding-right: 4px !default;
$rkt-input-suffix-angular-position: relative !default;
$rkt-input-suffix-angular-left: 19px !default;
$rkt-input-suffix-angular-icon-padding: 12px !default;
$rkt-input-caret-color: $rkt-red-500 !default;
$rkt-input-text-color: $rkt-gray-800 !default;
$rkt-input-text-font-size: $rkt-typography-font-size-label-16 !default;
$rkt-input-text-font-weight: $rkt-typography-font-weight-400 !default;
$rkt-input-text-line-height: 24px !default;
$rkt-input-text-disabled-color: $rkt-gray-300 !default;
$rkt-input-text-filled-color: $rkt-gray-800 !default;
$rkt-input-text-filled-hover-color: $rkt-gray-600 !default;
$rkt-input-text-focus-color: $rkt-gray-800 !default;
$rkt-input-angular-floating-label-transform: translateY(-106%) scale(1) !default;
$rkt-input-angular-top: 1px !default;
$rkt-input-angular-position: relative !default;
$rkt-input-angular-padding: 0 20px !default;
$rkt-input-angular-label-padding-bottom: 3px !default;
$rkt-input-react-textarea-floating-label-transform: translate(0, -1.5px) scale(1) !default;
$rkt-input-react-floating-label-transform: translate(12px, 7px) scale(1) !default;
$rkt-input-react-padding: 0 8px !default;
$rkt-input-react-label-top: 7px !default;
$rkt-input-react-label-left: 8px !default;
$rkt-input-textarea-textarea-control-resize: none !default;
$rkt-input-textarea-icon-color: $rkt-gray-800 !default;
$rkt-input-search-cancel-button-appearance: none !default;
$rkt-input-autofill-background-color-reset: 0 0 0 30px $rkt-gray-50 inset !default;
$rkt-input-autofill-hover-background-color-reset: 0 0 0 30px $rkt-gray-100 inset !default;
$rkt-input-autofill-input-container-overflow: hidden !default;
$rkt-form-field-text-field-wrapper-align-items: center !default;
$rkt-form-field-has-left-icon-icon-margin-top: 0 !default;
$rkt-form-field-has-left-icon-label-left: 33px !default;
$rkt-form-field-has-left-icon-react-input-padding-left: 12px !default;
$rkt-form-field-has-left-icon-angular-icon-padding-right: 4px !default;
$rkt-form-field-has-left-icon-input-adornment-margin-top: 0 !default;
$rkt-form-field-has-prefix-react-prefix-left: 12px !default;
$rkt-form-field-has-prefix-react-prefix-position: relative !default;
$rkt-form-field-has-prefix-react-input-position: relative !default;
$rkt-form-field-has-prefix-react-input-left: 8px !default;
$rkt-form-field-has-prefix-react-input-bottom: 1px !default;
$rkt-form-field-has-prefix-react-input-offset-width: 35px !default;
$rkt-form-field-has-prefix-react-has-tooltip-input-left: 4px;
$rkt-form-field-has-prefix-angular-prefix-padding-left: 12px !default;
$rkt-form-field-has-prefix-angular-prefix-padding-right: 0 !default;
$rkt-form-field-has-prefix-angular-input-left: 4px !default;
$rkt-form-field-has-prefix-angular-input-position: relative !default;
$rkt-form-field-has-suffix-angular-text-field-wrapper-padding-right: 1px !default;
$rkt-form-field-has-tooltip-tooltip-popper-fill: $rkt-gray-600 !default;
$rkt-form-field-has-tooltip-tooltip-popper-angular-left: 3px !default;
$rkt-form-field-has-tooltip-tooltip-popper-angular-outline: none !default;
$rkt-form-field-has-tooltip-tooltip-popper-react-right: 1px !default;
$rkt-form-field-has-tooltip-react-input-position: relative !default;
$rkt-form-field-has-tooltip-react-input-bottom: 1px !default;
$rkt-form-field-has-tooltip-react-adornment-padding-right: 4px !default;
$rkt-form-field-has-textarea-angular-text-field-wrapper-padding: 8px 20px !default;
$rkt-form-field-has-textarea-angular-textarea-min-height: 80px !default;
$rkt-form-field-has-textarea-angular-label-top: 24px !default;
$rkt-form-field-has-textarea-angular-error-text-margin-top: 0 !default;
$rkt-form-field-has-textarea-angular-error-text-left: -3px !default;
$rkt-form-field-has-textarea-angular-error-text-top: 7px !default;
$rkt-form-field-has-textarea-angular-error-text-position: relative !default;
$rkt-form-field-has-textarea-react-padding: 20px !default;
$rkt-form-field-has-textarea-react-label-top: 13px !default;
$rkt-form-field-has-textarea-react-input-padding-left: 0 !default;
$rkt-form-field-has-textarea-react-input-padding-right: 0 !default;
$rkt-form-field-has-textarea-react-input-margin-top: 13px !default;
$rkt-form-field-has-textarea-react-textarea-min-height: 63px !default;
$rkt-form-field-has-textarea-react-textarea-position: relative !default;
$rkt-form-field-has-textarea-react-textarea-bottom: 0.5px !default;
$rkt-form-field-has-textarea-react-filled-input-margin-top: 9px !default;
$rkt-form-field-has-textarea-icon-align-self: flex-start !default;
$rkt-form-field-has-textarea-icon-padding: 0 !default;
$rkt-form-field-has-textarea-with-icon-right-angular-icon-padding: 7px 0 0 !default;
$rkt-form-field-has-textarea-with-icon-right-react-icon-bottom: 10px !default;
$rkt-form-field-has-textarea-with-icon-right-react-icon-position: relative !default;
$rkt-form-field-has-textarea-width: 100%;
$rkt-form-field-has-textarea-max-width: 278px;
$rkt-form-field-has-textarea-box-sizing: border-box;
$rkt-form-field-has-textarea-min-height: 128px;
$rkt-form-field-input-placeholder-opacity: 1 !default;
$rkt-form-field-input-has-error-box-shadow: inset 0 0 0 1.5px $rkt-yellow-600 !default;
$rkt-form-field-input-has-error-focus-box-shadow: inset 0 0 0 3px $rkt-yellow-600 !default;
$rkt-form-field-input-has-error-prefix-color: $rkt-yellow-600 !default;
$rkt-form-field-full-width-input-width: 100%;
$rkt-form-field-invalid-label-color: $rkt-gray-800 !default;
$rkt-form-field-hint-text-react-margin-top: 16px !default;
$rkt-form-field-react-input-position: relative !default;
$rkt-form-field-react-input-top: 0.5px !default;
$rkt-form-field-angular-wrapper-has-hint-text-padding-left: 20px !default;
$rkt-form-field-angular-wrapper-has-hint-text-has-hint-text-link-padding-left: 17px !default;
$rkt-form-field-button-icon-left: 3px !default;
$rkt-form-field-button-icon-in-inputs-left: 3px !default;
$rkt-error-text-margin: 0 !default;
$rkt-error-text-font-family: $rkt-typography-font-family-body-14 !default;
$rkt-error-text-font-size: $rkt-typography-font-size-label-14 !default;
$rkt-error-text-font-weight: $rkt-typography-font-weight-500 !default;
$rkt-error-text-line-height: 0.875 !default;
$rkt-error-text-color: $rkt-yellow-700 !default;
$rkt-error-text-angular-margin-top: 11px !default;
$rkt-error-text-angular-left: 2px !default;
$rkt-error-text-angular-position: relative !default;
$rkt-error-text-angular-min-height: 45px !default;
$rkt-error-text-react-margin-top: 16px !default;
$rkt-error-text-react-left: 5px !default;
$rkt-error-text-react-position: relative !default;
$rkt-error-text-react-min-height: 40px !default;
$rkt-hint-text-link-text-decoration: none !default;
$rkt-hint-text-margin: 0 !default;
$rkt-hint-text-font-family: $rkt-typography-font-family-body-14 !default;
$rkt-hint-text-font-size: $rkt-typography-font-size-body-14 !default;
$rkt-hint-text-font-weight: $rkt-typography-font-weight-400 !default;
$rkt-hint-text-line-height: 0.875 !default;
$rkt-hint-text-color: $rkt-gray-600 !default;
$rkt-hint-text-margin-top: 11px !default;
$rkt-form-label-font-family: $rkt-typography-font-family-body-16 !default;
$rkt-form-label-font-size: $rkt-typography-font-size-body-16 !default;
$rkt-form-label-font-weight: $rkt-typography-font-weight-400 !default;
$rkt-form-label-line-height: $rkt-typography-line-height-body-16 !default;
$rkt-form-label-color: $rkt-gray-600 !default;
$rkt-legend-invalid-color: $rkt-yellow-700 !default;
$rkt-legend-color: $rkt-gray-800 !default;
$rkt-legend-font-size: $rkt-typography-font-size-label-16 !default;
$rkt-legend-font-weight: $rkt-typography-font-weight-label-16 !default;
$rkt-legend-font-family: $rkt-typography-font-family-label-16 !default;
$rkt-legend-line-height: $rkt-typography-line-height-label-16 !default;
$rkt-tooltip-angular-bottom: 17px;
$rkt-tooltip-angular-left: 1px;
$rkt-tooltip-data-background-color: $rkt-white !default;
$rkt-tooltip-data-color: $rkt-gray-800 !default;
$rkt-tooltip-data-box-shadow: $rkt-elevation-3 !default;
$rkt-tooltip-on-dark-background-color: $rkt-white !default;
$rkt-tooltip-on-dark-color: $rkt-gray-800 !default;
$rkt-tooltip-on-dark-box-shadow: $rkt-elevation-3 !default;
$rkt-tooltip-background-color: $rkt-gray-800 !default;
$rkt-tooltip-placement-margin: -5px !default;
$rkt-tooltip-font-weight: $rkt-typography-font-weight-500 !default;
$rkt-tooltip-font-size: $rkt-typography-font-size-caption-12 !default;
$rkt-tooltip-font-family: $rkt-typography-font-family-caption-12 !default;
$rkt-tooltip-font-style: normal !default;
$rkt-tooltip-line-height: 16px !default;
$rkt-tooltip-color: $rkt-white !default;
$rkt-tooltip-padding: 12px !default;
$rkt-tooltip-border-radius: 8px !default;
$rkt-tooltip-top: 13px !default;
$rkt-tooltip-bottom: 13px !default;
$rkt-tooltip-left: 13px !default;
$rkt-tooltip-right: 13px !default;
$rkt-tooltip-display: flex !default;
$rkt-tooltip-popper-focus-visible-outline: none !default;
$rkt-tooltip-popper-box-sizing: content-box !default;
$rkt-tooltip-popper-flex-shrink: 0 !default;
$rkt-tooltip-popper-padding: $rkt-spacing-8 !default;
$rkt-tooltip-popper-color: $rkt-gray-800 !default;
$rkt-tooltip-popper-background-color-hover: $rkt-black-opacity-04 !default;
$rkt-tooltip-popper-cursor-hover: pointer !default;
$rkt-tooltip-popper-background-color-focus: $rkt-black-opacity-08 !default;
$rkt-tooltip-popper-border-radius: 50% !default;
$rkt-tooltip-popper-on-dark-color: $rkt-white !default;
$rkt-tooltip-popper-on-dark-hover-background-color: $rkt-white-opacity-300 !default;
$rkt-tooltip-popper-on-dark-focus-background-color: $rkt-white-opacity-400 !default;
$rkt-tooltip-popper-ripple-display: none !default;
$rkt-tooltip-in-table-bottom: 5px !default;
$rkt-link-line-height: $rkt-typography-line-height-label-16 !default;
$rkt-link-focus-box-shadow: 0 -4px $rkt-red-500, 0 4px $rkt-red-500, 0 -3px $rkt-red-500, inset 0 0 0px 4px $rkt-red-500 !default;
$rkt-link-focus-background-color: $rkt-red-500 !default;
$rkt-link-focus-color: $rkt-white !default;
$rkt-link-focus-box-decoration-break: clone !default;
$rkt-link-focus-border-radius: 8px !default;
$rkt-link-focus-outline: none !default;
$rkt-link-position: relative !default;
$rkt-link-font-family: $rkt-typography-font-family-label-16 !default;
$rkt-link-font-size: $rkt-typography-font-size-label-16 !default;
$rkt-link-font-weight: $rkt-typography-font-weight-label-16 !default;
$rkt-link-font-line-height: $rkt-typography-line-height-label-16 !default;
$rkt-link-color: $rkt-gray-800 !default;
$rkt-link-text-decoration: underline !default;
$rkt-link-hover-color: $rkt-red-500 !default;
$rkt-link-hover-text-decoration: underline !default;
$rkt-link-visited-color: $rkt-link-color !default;
$rkt-link-visited-text-decoration: $rkt-link-text-decoration !default;
$rkt-link-simple-text-decoration: none !default;
$rkt-link-simple-focus-text-decoration: none !default;
$rkt-link-simple-visited-color: $rkt-link-color !default;
$rkt-link-simple-visited-text-decoration: $rkt-link-simple-text-decoration !default;
$rkt-link-simple-disabled-text-decoration: none !default;
$rkt-link-on-dark-simple-focus-outline-color: $rkt-white !default;
$rkt-link-on-dark-simple-focus-text-decoration: none !default;
$rkt-link-on-dark-simple-disabled-color: $rkt-gray-500 !default;
$rkt-link-on-dark-simple-hover-font-weight: $rkt-typography-font-weight-label-16 !default;
$rkt-link-on-dark-simple-hover-after-left: 0% !default;
$rkt-link-on-dark-simple-hover-after-width: 100% !default;
$rkt-link-on-dark-simple-hover-text-decoration: underline !default;
$rkt-link-on-dark-simple-visited-text-decoration: none !default;
$rkt-link-on-dark-simple-font-weight: $rkt-typography-font-weight-label-16 !default;
$rkt-link-on-dark-simple-text-decoration: none !default;
$rkt-link-has-icon-transition: none !default;
$rkt-link-has-icon-text-decoration: none !default;
$rkt-link-has-icon-display: inline-flex !default;
$rkt-link-has-icon-align-items: center !default;
$rkt-link-has-icon-color-icon: $rkt-gray-800 !default;
$rkt-link-has-icon-focus-color: $rkt-white !default;
$rkt-link-has-icon-hover-color: $rkt-red-500 !default;
$rkt-link-has-icon-hover-text-decoration: underline !default;
$rkt-link-has-icon-visited-color: $rkt-link-color !default;
$rkt-link-has-icon-visited-text-decoration: $rkt-link-has-icon-text-decoration !default;
$rkt-link-has-icon-disabled-text-decoration: none !default;
$rkt-link-has-icon-on-dark-color: $rkt-white !default;
$rkt-link-inline-18-font-size: $rkt-typography-font-size-body-18 !default;
$rkt-link-inline-14-font-size: $rkt-typography-font-size-body-14 !default;
$rkt-link-inline-12-font-size: $rkt-typography-font-size-caption-12 !default;
$rkt-link-disabled-focus-outline: none !default;
$rkt-link-disabled-color: $rkt-gray-400 !default;
$rkt-link-disabled-text-decoration: underline !default;
$rkt-link-disabled-hover-color: $rkt-gray-400 !default;
$rkt-link-on-dark-disabled-color: $rkt-gray-500 !default;
$rkt-link-on-dark-disabled-hover-color: $rkt-gray-500 !default;
$rkt-link-on-dark-color: $rkt-white !default;
$rkt-link-on-dark-hover-font-weight: $rkt-typography-font-weight-label-16 !default;
$rkt-link-on-dark-hover-color: $rkt-white !default;
$rkt-link-on-dark-hover-text-decoration: none !default;
$rkt-link-on-dark-visited-text-decoration: none !default;
$rkt-link-on-dark-font-weight: $rkt-typography-font-weight-label-16 !default;
$rkt-list-button-icon-min-width: auto !default;
$rkt-list-button-with-icon-selected-background-color: $rkt-gray-200 !default;
$rkt-list-button-with-icon-selected-color: $rkt-gray-800 !default;
$rkt-list-button-selected-background-color: $rkt-green-50 !default;
$rkt-list-button-selected-color: $rkt-green-700 !default;
$rkt-list-button-selected-item-background-color: unset !default;
$rkt-list-button-hover-background-color: unset !default;
$rkt-list-button-hover-item-background-color: $rkt-gray-100 !default;
$rkt-list-button-padding: 16px 20px !default;
$rkt-list-button-background-color: $rkt-white !default;
$rkt-list-button-focus-visible-outline: 0 !default;
$rkt-list-button-focus-visible-box-shadow: inset 0 0 0 3px $rkt-gray-800 !default;
$rkt-list-margin: 0 !default;
$rkt-list-padding: 0 !default;
$rkt-list-list-style-type: none !default;
$rkt-list-item-content-display: flex !default;
$rkt-list-item-content-flex-direction: column !default;
$rkt-list-item-content-gap: 2px !default;
$rkt-list-item-content-margin: 0 !default;
$rkt-list-item-text-text-overflow: unset !default;
$rkt-list-item-text-overflow: visible !default;
$rkt-list-item-text-white-space: normal !default;
$rkt-list-item-padding: 0 !default;
$rkt-list-item-overflow: visible !default;
$rkt-list-item-line-margin: 0 !default;
$rkt-list-item-line-color: $rkt-gray-600 !default;
$rkt-list-item-line-font-family: 'Wintle Text', Helvetica, 'Helvetica Neue', Arial, sans-serif !default;
$rkt-list-item-line-font-size: 0.875rem !default;
$rkt-list-item-line-font-weight: $rkt-typography-font-weight-body-14 !default;
$rkt-list-item-line-line-height: 20px !default;
$rkt-list-item-title-margin: 0 !default;
$rkt-list-item-title-color: $rkt-gray-800 !default;
$rkt-list-item-title-font-family: 'Wintle Text', Helvetica, 'Helvetica Neue', Arial, sans-serif !default;
$rkt-list-item-title-font-size: 1rem !default;
$rkt-list-item-title-font-weight: $rkt-typography-font-weight-label-16 !default;
$rkt-list-item-title-line-height: 24px !default;
$rkt-list-item-icon-margin-right: 14px !default;
$rkt-list-item-icon-margin-top: 0 !default;
$rkt-list-item-icon-align-self: center !default;
$rkt-list-item-icon-color: $rkt-gray-800 !default;
$rkt-list-item-icon-margin-left: 0 !default;
$rkt-list-item-text-padding-right: 34px !default;
$rkt-list-item-height: auto !default;
$rkt-list-item-min-height: 64.01px !default;
$rkt-list-item-border-radius: 8px !default;
$rkt-list-item-box-sizing: border-box !default;
$rkt-list-item-flex: 0 0 auto !default;
$rkt-list-item-width: 100% !default;
$rkt-list-item-max-width: 272px !default;
$rkt-list-checkbox-icon-svg-width: 23px !default;
$rkt-list-checkbox-icon-transform: translateY(-50%) !default;
$rkt-list-checkbox-icon-top: 50% !default;
$rkt-list-checkbox-icon-background-repeat: no-repeat !default;
$rkt-list-checkbox-icon-right: 20px !default;
$rkt-list-checkbox-icon-position: absolute !default;
$rkt-list-checkbox-icon-width: 24px !default;
$rkt-list-checkbox-icon-height: 24px !default;
$rkt-list-checkbox-icon-display: block !default;
$rkt-list-checkbox-icon-background-image: url('data:image/svg+xml,<svg fill="none" viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg"><path stroke="%230B5E36" d="M7.75 12.981l2.67 2.67 5.83-5.83" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>') !default;
$rkt-list-checkbox-margin-left: 14px !default;
$rkt-list-checkbox-margin-right: 0 !default;
$rkt-list-checkbox-padding: 0 !default;
$rkt-list-checkbox-margin: 0 !default;
$rkt-list-checkbox-item-width: unset !default;
$rkt-list-checkbox-item-height: unset !default;
$rkt-list-checkbox-width: 24px !default;
$rkt-list-checkbox-height: 24px !default;
$rkt-list-checkbox-position: absolute !default;
$rkt-list-checkbox-right: 20px !default;
$rkt-list-checkbox-top: 50% !default;
$rkt-list-checkbox-transform: translateY(-50%) !default;
$rkt-list-divider-right: 0 !default;
$rkt-list-divider-bottom: 0 !default;
$rkt-list-divider-border: 0 !default;
$rkt-list-divider-opacity: 1 !default;
$rkt-list-divider-width: calc(100% - 40px) !default;
$rkt-list-divider-height: 1px !default;
$rkt-list-divider-background-color: $rkt-gray-100 !default;
$rkt-list-divider-left: 20px !default;
$rkt-list-divider-top: -1px !default;
$rkt-list-divider-display: block !default;
$rkt-list-divider-position: absolute !default;
$rkt-list-display: none !default;
$rkt-masthead-nav-collapsible-padding-left: 8px !default;
$rkt-masthead-nav-collapsible-padding-right: 8px !default;
$rkt-masthead-nav-bar-item-cursor: pointer !default;
$rkt-masthead-background-color-is-open: $rkt-backdrop-background !default;
$rkt-masthead-branding-margin: 0 auto !default;
$rkt-masthead-branding-flex-grow: 2 !default;
$rkt-masthead-branding-flex-grow-large: 0 !default;
$rkt-masthead-branding-flex-shrink: 1 !default;
$rkt-masthead-branding-flex-basis: auto !default;
$rkt-masthead-branding-text-align: center !default;
$rkt-masthead-menu-flex-grow: 1 !default;
$rkt-masthead-menu-display-large: none !default;
$rkt-masthead-menu-flex-shrink: 1 !default;
$rkt-masthead-menu-flex-basis: auto !default;
$rkt-masthead-nav-collapsible-bg-color: $rkt-white !default;
$rkt-masthead-nav-collapsible-display-large: none !default;
$rkt-masthead-nav-bar-display-large: inherit !default;
$rkt-masthead-nav-bar-display: none !default;
$rkt-masthead-nav-collapsible-width: 100% !default;
$rkt-masthead-nav-collapsible-height: 100% !default;
$rkt-masthead-nav-collapsible-display-is-collapsed: none !default;
$rkt-masthead-nav-collapsible-overflow: auto !default;
$rkt-masthead-nav-collapsible-z-index: -1 !default;
$rkt-masthead-position: sticky !default;
$rkt-masthead-nav-item-display: flex !default;
$rkt-masthead-nav-item-justify-content: flex-end !default;
$rkt-masthead-input-display: flex !default;
$rkt-masthead-input-justify-content: center !default;
$rkt-masthead-input-width: 440px !default;
$rkt-masthead-input-align-self: center !default;
$rkt-masthead-nav-bar-item-display: block !default;
$rkt-masthead-nav-bar-item-margin: 0 auto !default;
$rkt-masthead-nav-bar-button-item-height: 36px !default;
$rkt-masthead-content-margin: 0 auto !default;
$rkt-masthead-left-is-open: 0 !default;
$rkt-masthead-right-is-open: 0 !default;
$rkt-masthead-height-is-open: 100% !default;
$rkt-masthead-height-is-open-large: auto !default;
$rkt-masthead-position-is-open: fixed !default;
$rkt-masthead-position-is-open-large: sticky !default;
$rkt-masthead-top: 0 !default;
$rkt-masthead-site-links-border-right: 2px solid $rkt-gray-200 !default;
$rkt-masthead-link-color: $rkt-gray-800 !default;
$rkt-masthead-link-visited-color: $rkt-gray-800 !default;
$rkt-masthead-link-hover-color: $rkt-red-700 !default;
$rkt-masthead-link-font-family: $rkt-typography-font-family-body-16 !default;
$rkt-masthead-link-font-size: $rkt-typography-font-size-body-16 !default;
$rkt-masthead-link-font-weight: $rkt-typography-font-weight-500 !default;
$rkt-masthead-link-border-bottom: 0 !default;
$rkt-masthead-link-transition: none !default;
$rkt-masthead-link-border-bottom-hover: 0 !default;
$rkt-masthead-link-text-decoration: none !default;
$rkt-masthead-link-hover-text-decoration-style: solid !default;
$rkt-masthead-link-hover-text-decoration-line: underline !default;
$rkt-masthead-link-hover-text-decoration-color: $rkt-red-700 !default;
$rkt-masthead-link-hover-text-decoration-thickness: 2px !default;
$rkt-masthead-link-text-underline-offset: 4px !default;
$rkt-masthead-narrow-link-font-size: $rkt-typography-font-size-label-14 !default;
$rkt-masthead-menu-icon-width: 24px !default;
$rkt-masthead-menu-icon-fill: currentColor !default;
$rkt-masthead-menu-icon-color: $rkt-gray-800 !default;
$rkt-masthead-menu-icon-height: 24px !default;
$rkt-masthead-menu-icon-hover-background-color: transparent !default;
$rkt-masthead-content-min-height: 64px !default;
$rkt-masthead-content-padding: 0 $rkt-spacing-8 !default;
$rkt-masthead-content-padding-wide: $rkt-spacing-16 !default;
$rkt-masthead-content-item-padding-top: $rkt-spacing-8 !default;
$rkt-masthead-content-item-padding-top-wide: $rkt-spacing-8 !default;
$rkt-masthead-content-item-padding-bottom: $rkt-spacing-8 !default;
$rkt-masthead-content-item-padding-bottom-wide: $rkt-spacing-8 !default;
$rkt-masthead-content-item-padding-right: $rkt-spacing-8 !default;
$rkt-masthead-content-item-padding-right-wide: $rkt-spacing-16 !default;
$rkt-masthead-content-item-padding-left: $rkt-spacing-8 !default;
$rkt-masthead-content-item-padding-left-wide: $rkt-spacing-16 !default;
$rkt-masthead-column-width: 89rem !default;
$rkt-masthead-column-width-full: 100% !default;
$rkt-masthead-narrow-height: 81px !default;
$rkt-masthead-bg-color: $rkt-white !default;
$rkt-masthead-breakpoint-medium: 48rem !default;
$rkt-masthead-nav-collapsible-width-medium: 50% !default;
$rkt-masthead-breakpoint: 992px !default;
$rkt-masthead-item-link-border-radius: 12px !default;
$rkt-masthead-item-link-padding: 12px !default;
$rkt-masthead-item-link-align-items: center !default;
$rkt-masthead-item-link-hover-background-color: $rkt-gray-50 !default;
$rkt-masthead-item-link-icon-flex-shrink: 0 !default;
$rkt-masthead-nav-link-default-color: $rkt-typography-color-label-16 !default;
$rkt-masthead-nav-link-font-family: $rkt-typography-font-family-label-16 !default;
$rkt-masthead-nav-link-font-size: $rkt-typography-font-size-label-16 !default;
$rkt-masthead-nav-link-font-weight: $rkt-typography-font-weight-label-16 !default;
$rkt-masthead-nav-link-line-height: $rkt-typography-line-height-label-16 !default;
$rkt-masthead-nav-link-background-color: $rkt-white !default;
$rkt-masthead-nav-link-color: $rkt-red-700 !default;
$rkt-masthead-nav-link-active-color: $rkt-gray-800 !default;
$rkt-masthead-nav-link-hover-color: $rkt-red-700 !default;
$rkt-masthead-nav-link-border-radius: 0 !default;
$rkt-masthead-nav-link-border-bottom: 2px solid transparent !default;
$rkt-masthead-nav-link-border-bottom-active: 2px solid $rkt-red-700 !default;
$rkt-masthead-nav-link-active-padding: 8px !default;
$rkt-masthead-nav-link-flex-direction: row-reverse !default;
$rkt-masthead-nav-link-icon-margin: 0 !default;
$rkt-masthead-nav-link-text-decoration: none !default;
$rkt-masthead-nav-bar-bg: $rkt-white !default;
$rkt-masthead-nav-bar-items-column-width: 64rem !default;
$rkt-masthead-nav-bar-items-height: 38px !default;
$rkt-masthead-border-bottom-size: 0 !default;
$rkt-masthead-border-bottom-style: solid !default;
$rkt-masthead-border-bottom: 0 !default;
$rkt-masthead-border-bottom-wide: 0 !default;
$rkt-masthead-logo-max-width: 136px !default;
$rkt-masthead-logo-max-height: 25px !default;
$rkt-masthead-logo-min-width: 136px !default;
$rkt-masthead-shadow: 1px 0 0 1px $rkt-black-opacity-04 !default;
$rkt-masthead-shadow-wide: none !default;
$rkt-masthead-shadow-scroll: 0 0 40px rgba(0, 0, 0, 0.1) !default;
$rkt-masthead-shadow-narrow: $rkt-elevation-4 !default;
$rkt-masthead-transition: all 0.3s ease-in !default;
$rkt-masthead-site-links-list-style: none !default;
$rkt-masthead-site-links-position: relative !default;
$rkt-masthead-site-links-width: auto !default;
$rkt-masthead-site-links-padding-left: 0 !default;
$rkt-masthead-nav-items-flex-wrap: wrap !default;
$rkt-masthead-nav-items-position: relative !default;
$rkt-masthead-nav-bar-items-padding: 0 !default;
$rkt-masthead-nav-bar-items-margin: 0 auto !default;
$rkt-masthead-nav-bar-items-position: relative !default;
$rkt-masthead-nav-bar-items-list-style: none !default;
$rkt-masthead-collapsible-nav-button-padding: 0 !default;
$rkt-masthead-collapsible-nav-button-margin: 0 !default;
$rkt-masthead-collapsible-nav-button-cursor: pointer !default;
$rkt-masthead-collapsible-nav-button-border: 0 !default;
$rkt-masthead-collapsible-nav-button-background-color: transparent !default;
$rkt-masthead-static-breakpoint: 1006px !default;
$rkt-masthead-static-border-bottom: 0 !default;
$rkt-masthead-static-content-min-height: 80px !default;
$rkt-masthead-static-content-padding: 0 24px !default;
$rkt-masthead-static-content-wide-padding: 0 48px !default;
$rkt-masthead-is-open-nav-item-display: none !default;
$rkt-masthead-is-open-branding-text-align: left !default;
$rkt-masthead-is-open-branding-link-justify-content: left !default;
$rkt-masthead-is-open-branding-link-width: fit-content !default;
$rkt-masthead-is-open-content-flex-direction: row-reverse !default;
$rkt-masthead-is-open-menu-flex: 0 0 auto !default;
$rkt-masthead-branding-wide-padding: 28px !default;
$rkt-masthead-branding-link-display: flex !default;
$rkt-masthead-branding-link-align-items: center !default;
$rkt-masthead-branding-link-justify-content: center !default;
$rkt-masthead-branding-link-focus-visible-outline-color: $rkt-gray-800 !default;
$rkt-masthead-static-logo-min-width: 25px !default;
$rkt-masthead-logo-height: 20px !default;
$rkt-masthead-logo-width: 110px !default;
$rkt-masthead-logo-wide-height: 24px !default;
$rkt-masthead-logo-wide-width: 132px !default;
$rkt-masthead-nav-items-justify-content: space-between !default;
$rkt-masthead-nav-items-align-items: center !default;
$rkt-masthead-site-links-margin: 0 !default;
$rkt-masthead-site-links-padding: 0 !default;
$rkt-masthead-site-links-align-items: center !default;
$rkt-masthead-static-link-color: $rkt-gray-800 !default;
$rkt-masthead-static-link-font-weight: $rkt-typography-font-weight-500 !default;
$rkt-masthead-link-display: flex !default;
$rkt-masthead-link-align-items: center !default;
$rkt-masthead-link-padding: 0 16px !default;
$rkt-masthead-link-margin: 0 4px !default;
$rkt-masthead-utility-link-color: $rkt-gray-800 !default;
$rkt-masthead-utility-link-focus-color: $rkt-white !default;
$rkt-masthead-utility-link-padding: 0 !default;
$rkt-masthead-utility-link-margin-right: 28px !default;
$rkt-masthead-utility-link-margin-left: 0 !default;
$rkt-masthead-utility-link-hover-color: $rkt-red-500 !default;
$rkt-masthead-utility-link-hover-text-decoration: none !default;
$rkt-masthead-nav-item-button-cursor: pointer !default;
$rkt-masthead-nav-item-button-background-color: transparent !default;
$rkt-masthead-nav-item-button-border: 0 !default;
$rkt-masthead-nav-item-button-border-radius: 50% !default;
$rkt-masthead-nav-item-button-height: 48px !default;
$rkt-masthead-nav-item-button-margin: 0 !default;
$rkt-masthead-nav-item-button-padding: 0 !default;
$rkt-masthead-nav-item-button-min-width: 48px !default;
$rkt-masthead-nav-item-button-width: 48px !default;
$rkt-masthead-nav-item-button-display: flex !default;
$rkt-masthead-nav-item-button-align-items: center !default;
$rkt-masthead-nav-item-button-justify-content: center !default;
$rkt-masthead-nav-item-button-position: relative !default;
$rkt-masthead-nav-item-button-color: $rkt-gray-800 !default;
$rkt-masthead-nav-item-button-focus-box-shadow: inset 0 0 0 3px $rkt-gray-800 !default;
$rkt-masthead-nav-item-button-focus-outline: none !default;
$rkt-masthead-nav-collapsible-box-sizing: border-box !default;
$rkt-masthead-nav-collapsible-static-width: 100% !default;
$rkt-masthead-nav-collapsible-top: 80px !default;
$rkt-masthead-nav-collapsible-position: absolute !default;
$rkt-masthead-nav-collapsible-padding: 24px !default;
$rkt-masthead-link-menu-container-position: absolute !default;
$rkt-masthead-link-menu-container-width: 100px !default;
$rkt-masthead-link-menu-container-padding: 0 !default;
$rkt-masthead-link-menu-container-top: 104px !default;
$rkt-masthead-link-menu-background-transform: scaleY(0) !default;
$rkt-masthead-link-menu-background-transform-origin: top !default;
$rkt-masthead-link-menu-background-opacity: 0 !default;
$rkt-masthead-link-menu-background-transition: opacity .25s ease-in !default;
$rkt-masthead-link-menu-background-position: absolute !default;
$rkt-masthead-link-menu-background-z-index: 1 !default;
$rkt-masthead-link-menu-background-bg-color: $rkt-white !default;
$rkt-masthead-link-menu-background-border-radius: 24px !default;
$rkt-masthead-link-menu-background-box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.10) !default;
$rkt-masthead-link-menu-background-display: flex !default;
$rkt-masthead-link-menu-background-width: max-content !default;
$rkt-masthead-link-menu-background-left: -32px !default;
$rkt-masthead-link-menu-background-tabs-border: none !default;
$rkt-masthead-link-menu-background-tabs-padding: 24px !default;
$rkt-masthead-link-menu-background-tabs-indicator-display: none !default;
$rkt-masthead-open-link-menu-background-top: 24px !default;
$rkt-masthead-open-link-menu-background-transform: scaleY(1) !default;
$rkt-masthead-open-link-menu-background-opacity: 1 !default;
$rkt-masthead-open-link-menu-container-padding: 16px !default;
$rkt-masthead-link-menu-list-style: none !default;
$rkt-masthead-site-link-item-display: flex !default;
$rkt-masthead-site-link-item-align-items: center !default;
$rkt-masthead-site-link-item-border-bottom: 0 !default;
$rkt-masthead-site-link-item-height: 104px !default;
$rkt-masthead-site-link-item-hover-text-decoration: none !default;
$rkt-masthead-site-link-item-hover-cursor: pointer !default;
$rkt-masthead-site-link-item-hover-border-bottom: 0 !default;
$rkt-masthead-site-link-item-hover-background-image: url('data:image/svg+xml,<svg fill="none" viewBox="0 0 32 2" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg"><path d="m.657166 2c0-1.10457.895434-2 2.000004-2h27.00003c1.1045 0 2 .89543 2 2z" fill="%23de3341"/></svg>') !default;
$rkt-masthead-site-link-item-hover-background-repeat: no-repeat !default;
$rkt-masthead-site-link-item-hover-background-position: bottom center !default;
$rkt-masthead-site-link-item-hover-background-size: calc(100% - 32px) 2px !default;
$rkt-masthead-site-link-item-hover-color: $rkt-red-500 !default;
$rkt-masthead-site-link-item-focus-outline: none !default;
$rkt-masthead-site-link-item-focus-color: $rkt-gray-800 !default;
$rkt-masthead-site-link-item-focus-background-color: $rkt-gray-50 !default;
$rkt-masthead-site-link-item-focus-border-radius: 4px !default;
$rkt-masthead-site-link-item-focus-box-shadow: 0 0 0 3px $rkt-gray-800 !default;
$rkt-masthead-link-menu-item-padding: 16px 24px !default;
$rkt-masthead-link-menu-item-min-width: 192px !default;
$rkt-masthead-link-menu-item-border-bottom: 1px solid transparent !default;
$rkt-masthead-link-menu-item-color: $rkt-gray-800 !default;
$rkt-masthead-link-menu-item-align-items: flex-start !default;
$rkt-masthead-link-menu-item-font-size: $rkt-typography-font-size-body-16 !default;
$rkt-masthead-link-menu-item-font-weight: $rkt-typography-font-weight-500 !default;
$rkt-masthead-link-menu-item-line-height: 24px !default;
$rkt-masthead-link-menu-item-hover-border-radius: 8px !default;
$rkt-masthead-link-menu-item-hover-background-color: $rkt-gray-50 !default;
$rkt-masthead-link-menu-item-hover-color: $rkt-gray-800 !default;
$rkt-masthead-link-menu-item-hover-border-bottom: none !default;
$rkt-masthead-link-menu-item-hover-after-content: '' !default;
$rkt-masthead-link-menu-item-hover-after-display: block !default;
$rkt-masthead-link-menu-item-hover-after-position: relative !default;
$rkt-masthead-link-menu-item-hover-after-top: 16px !default;
$rkt-masthead-link-menu-item-hover-after-height: 1px !default;
$rkt-masthead-link-menu-item-hover-after-width: 100% !default;
$rkt-masthead-link-menu-item-hover-after-background-color: $rkt-gray-200 !default;
$rkt-masthead-link-menu-item-active-background-color: $rkt-gray-100 !default;
$rkt-masthead-link-menu-item-active-color: $rkt-gray-800 !default;
$rkt-masthead-link-menu-item-focus-border-radius: 8px !default;
$rkt-masthead-link-menu-item-focus-box-shadow: inset 0 0 0 3px $rkt-gray-800 !default;
$rkt-masthead-link-menu-item-focus-background-color: $rkt-white !default;
$rkt-masthead-link-menu-item-focus-color: $rkt-gray-800 !default;
$rkt-masthead-content-links-container-padding: 24px 32px 32px !default;
$rkt-masthead-content-links-container-min-width: 320px !default;
$rkt-masthead-content-main-link-padding: 16px 0 !default;
$rkt-masthead-content-cross-link-title-margin: 16px 0 !default;
$rkt-masthead-content-cross-link-title-font-size: $rkt-typography-font-size-caption-12 !default;
$rkt-masthead-content-cross-link-title-font-weight: $rkt-typography-font-weight-500 !default;
$rkt-masthead-content-cross-link-title-line-height: 16px !default;
$rkt-masthead-content-cross-link-title-color: $rkt-gray-600 !default;
$rkt-masthead-content-cross-link-container-list-style: none !default;
$rkt-masthead-content-cross-link-container-padding: 0 !default;
$rkt-masthead-content-cross-link-padding: 0 0 8px 0 !default;
$rkt-masthead-avatar-border-radius: 1000px !default;
$rkt-masthead-avatar-color: $rkt-white !default;
$rkt-masthead-avatar-background-color: $rkt-red-500 !default;
$rkt-masthead-avatar-margin-left: 8px !default;
$rkt-masthead-avatar-height: 32px !default;
$rkt-masthead-avatar-width: 32px !default;
$rkt-masthead-avatar-display: flex !default;
$rkt-masthead-avatar-align-items: center !default;
$rkt-masthead-avatar-justify-content: center !default;
$rkt-masthead-signed-in-menu-border-radius: 16px !default;
$rkt-masthead-signed-in-menu-box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.12) !default;
$rkt-masthead-signed-in-menu-padding: 8px !default;
$rkt-masthead-signed-in-menu-list-padding: 0 !default;
$rkt-masthead-signed-in-menu-item-border-bottom: 1px solid transparent !default;
$rkt-masthead-signed-in-menu-item-hover-border-bottom: 1px solid $rkt-gray-100 !default;
$rkt-masthead-signed-in-menu-item-padding: 20px !default;
$rkt-masthead-signed-in-menu-item-font-weight: $rkt-typography-font-weight-500 !default;
$rkt-masthead-signed-in-menu-item-icon-color: $rkt-gray-800 !default;
$rkt-masthead-signed-in-menu-item-icon-height: 24px !default;
$rkt-masthead-signed-in-menu-item-icon-width: 24px !default;
$rkt-masthead-signed-in-menu-item-icon-margin-right: 16px !default;
$rkt-masthead-signed-in-menu-item-hover-border-radius: 8px !default;
$rkt-masthead-signed-in-menu-item-hover-color: $rkt-gray-800 !default;
$rkt-masthead-signed-in-menu-item-hover-background-color: $rkt-gray-50 !default;
$rkt-masthead-signed-in-menu-item-focus-background-color: $rkt-white !default;
$rkt-masthead-signed-in-menu-item-focus-border-radius: 8px !default;
$rkt-masthead-signed-in-menu-item-focus-box-shadow: inset 0 0 0 3px $rkt-gray-800 !default;
$rkt-masthead-signed-in-menu-item-active-background-color: $rkt-gray-100 !default;
$rkt-masthead-signed-in-menu-item-hover-has-button-background-color: $rkt-white !default;
$rkt-masthead-signed-in-menu-item-hover-has-button-cursor: default !default;
$rkt-masthead-signed-in-menu-item-hover-has-button-box-shadow: none !default;
$rkt-masthead-signed-in-menu-item-hover-has-button-hover-cursor: pointer !default;
$rkt-masthead-signed-in-menu-item-focus-has-button-focus-box-shadow: inset 0 0 0 3px $rkt-red-500 !default;
$rkt-masthead-signed-in-menu-item-ripple-display: none !default;
$rkt-masthead-signed-in-menu-item-has-button-justify-content: center !default;
$rkt-masthead-signed-in-menu-item-has-button-width: 100% !default;
$rkt-masthead-signed-in-menu-item-has-button-display: flex !default;
$rkt-masthead-signed-in-menu-item-has-button-align-items: center !default;
$rkt-masthead-signed-in-menu-item-has-button-padding: 0 !default;
$rkt-masthead-nav-collapsible-menu-list-style: none !default;
$rkt-masthead-nav-collapsible-menu-margin: 0 !default;
$rkt-masthead-nav-collapsible-menu-padding: 0 !default;
$rkt-masthead-nav-collapsible-menu-item-border-bottom: 1px solid transparent !default;
$rkt-masthead-nav-collapsible-menu-item-hover-border-bottom: 1px solid $rkt-gray-100 !default;
$rkt-masthead-nav-collapsible-menu-item-padding: 0px 24px !default;
$rkt-masthead-nav-collapsible-menu-item-margin-bottom: 8px !default;
$rkt-masthead-nav-collapsible-menu-item-hover-border-radius: 8px !default;
$rkt-masthead-nav-collapsible-menu-item-hover-background-color: $rkt-gray-50 !default;
$rkt-masthead-nav-collapsible-menu-item-hover-color: $rkt-gray-800 !default;
$rkt-masthead-nav-collapsible-menu-item-active-background-color: $rkt-gray-100 !default;
$rkt-masthead-nav-collapsible-menu-item-active-color: $rkt-gray-800 !default;
$rkt-masthead-nav-collapsible-menu-item-focus-border-radius: 8px !default;
$rkt-masthead-nav-collapsible-menu-item-focus-background-color: $rkt-white !default;
$rkt-masthead-nav-collapsible-menu-item-focus-color: $rkt-gray-800 !default;
$rkt-masthead-nav-collapsible-menu-item-focus-box-shadow: inset 0 0 0 3px $rkt-gray-800 !default;
$rkt-masthead-nav-collapsible-menu-item-focus-outline: none !default;
$rkt-masthead-nav-collapsible-menu-item-last-margin: 0 !default;
$rkt-masthead-nav-collapsible-menu-link-display: flex !default;
$rkt-masthead-nav-collapsible-menu-link-height: 56px !default;
$rkt-masthead-nav-collapsible-menu-link-text-decoration: none !default;
$rkt-masthead-nav-collapsible-menu-link-font-size: $rkt-typography-font-size-label-16 !default;
$rkt-masthead-nav-collapsible-menu-link-line-height: $rkt-typography-line-height-label-16 !default;
$rkt-masthead-nav-collapsible-menu-link-font-weight: $rkt-typography-font-weight-500 !default;
$rkt-masthead-nav-collapsible-menu-link-color: $rkt-gray-800 !default;
$rkt-masthead-nav-collapsible-menu-text-flex-grow: 1 !default;
$rkt-masthead-nav-collapsible-menu-icon-display: flex !default;
$rkt-masthead-nav-collapsible-drawer-top: 80px !default;
$rkt-masthead-nav-collapsible-drawer-height: calc(100vh - 216px) !default;
$rkt-masthead-nav-collapsible-drawer-width: 100% !default;
$rkt-masthead-nav-collapsible-drawer-box-shadow: none !default;
$rkt-masthead-nav-collapsible-drawer-content-padding: 24px !default;
$rkt-masthead-nav-collapsible-drawer-content-box-shadow: none !default;
$rkt-masthead-nav-collapsible-drawer-content-background: $rkt-gray-50 !default;
$rkt-masthead-nav-collapsible-drawer-content-last-margin: 0 0 8px 0 !default;
$rkt-masthead-nav-collapsible-drawer-content-expanded-margin-top: 0 !default;
$rkt-masthead-nav-collapsible-drawer-content-title-padding: 16px 24px !default;
$rkt-masthead-nav-collapsible-drawer-content-title-font-size: $rkt-typography-font-size-label-16 !default;
$rkt-masthead-nav-collapsible-drawer-content-icon-transform: rotate(-90deg) !default;
$rkt-masthead-nav-collapsible-drawer-content-icon-expanded-transform: rotate(-180deg) !default;
$rkt-masthead-nav-collapsible-drawer-content-details-padding: 0 24px !default;
$rkt-masthead-nav-collapsible-drawer-content-details-hover-text-decoration: none !default;
$rkt-masthead-nav-collapsible-breadcrumb-padding: 12px 0 !default;
$rkt-masthead-nav-collapsible-breadcrumb-margin-bottom: 24px !default;
$rkt-masthead-nav-collapsible-breadcrumb-width: 100% !default;
$rkt-masthead-nav-collapsible-breadcrumb-icon-color: $rkt-gray-600 !default;
$rkt-masthead-nav-collapsible-utilities-position: fixed !default;
$rkt-masthead-nav-collapsible-utilities-left: 0px !default;
$rkt-masthead-nav-collapsible-utilities-bottom: 0px !default;
$rkt-masthead-nav-collapsible-utilities-height: 112px !default;
$rkt-masthead-nav-collapsible-utilities-width: 100% !default;
$rkt-masthead-nav-collapsible-utilities-justify-content: end !default;
$rkt-masthead-nav-collapsible-utilities-align-items: center !default;
$rkt-masthead-nav-collapsible-utilities-background-color: $rkt-white !default;
$rkt-masthead-nav-collapsible-utilities-item-width: 100% !default;
$rkt-masthead-nav-collapsible-utilities-item-display: flex !default;
$rkt-masthead-nav-collapsible-utilities-item-justify-content: center !default;
$rkt-masthead-nav-collapsible-utilities-item-button-width: 100% !default;
$rkt-masthead-nav-collapsible-utilities-item-button-margin: 0 24px 24px !default;
$rkt-menu-item-subtext-margin: 0 !default;
$rkt-menu-item-subtext-font-size: $rkt-typography-font-size-caption-12 !default;
$rkt-menu-item-subtext-color: $rkt-gray-800 !default;
$rkt-menu-item-subtext-font-weight: $rkt-typography-font-weight-caption-12 !default;
$rkt-menu-item-subtext-font-family: $rkt-typography-font-family-caption-12 !default;
$rkt-menu-item-subtext-line-height: $rkt-typography-line-height-caption-12 !default;
$rkt-menu-notification-status-background-color: $rkt-blue-50 !default;
$rkt-menu-notification-status-message-color: $rkt-blue-500 !default;
$rkt-menu-notification-status-message-font-family: $rkt-typography-font-family-tracked-12 !default;
$rkt-menu-notification-status-message-font-size: $rkt-typography-font-size-tracked-12 !default;
$rkt-menu-notification-status-message-margin: 0 !default;
$rkt-menu-notification-status-message-font-weight: $rkt-typography-font-weight-tracked-12 !default;
$rkt-menu-notification-status-message-line-height: $rkt-typography-line-height-tracked-12 !default;
$rkt-menu-notification-status-message-letter-spacing: $rkt-typography-letter-spacing-tracked-12 !default;
$rkt-menu-notification-status-message-text-transform: $rkt-typography-text-transform-tracked-12 !default;
$rkt-menu-notification-status-padding: 12px 16px !default;
$rkt-menu-nav-hover-active-color: $rkt-red-500 !default;
$rkt-menu-nav-hover-color: $rkt-red-500 !default;
$rkt-menu-nav-active-border-left: 2px solid $rkt-red-500 !default;
$rkt-menu-nav-min-width: 146px !default;
$rkt-menu-nav-item-text-color: $rkt-gray-600 !default;
$rkt-menu-nav-item-text-active-color: $rkt-gray-800 !default;
$rkt-menu-nav-item-text-font-size: 0.875rem !default;
$rkt-menu-nav-item-icon-fill: $rkt-gray-600 !default;
$rkt-menu-nav-icon-color: $rkt-gray-800 !default;
$rkt-menu-nav-link-hover-color: $rkt-red-500 !default;
$rkt-menu-nav-elevation: $rkt-elevation-2 !default;
$rkt-menu-elevation: $rkt-elevation-5 !default;
$rkt-menu-border-radius: 16px !default;
$rkt-menu-border: 1px solid rgba(0, 0, 0, 0.04) !default;
$rkt-menu-margin-top-nested-mui: -10px !default;
$rkt-menu-padding: 8px 8px !default;
$rkt-menu-display: flex !default;
$rkt-menu-flex-direction: column !default;
$rkt-menu-align-items: center !default;
$rkt-menu-margin-top: 1px !default;
$rkt-menu-item-text-color: $rkt-typography-color-label-16 !default;
$rkt-menu-item-text-font-family: $rkt-typography-font-family-label-16 !default;
$rkt-menu-item-text-font-size: $rkt-typography-font-size-label-16 !default;
$rkt-menu-item-text-font-weight: $rkt-typography-font-weight-label-16 !default;
$rkt-menu-item-text-line-height: $rkt-typography-line-height-label-16 !default;
$rkt-menu-item-text-font-style: normal !default;
$rkt-menu-item-text-overflow: hidden !default;
$rkt-menu-item-text-text-overflow: ellipsis !default;
$rkt-menu-item-text-white-space: nowrap !default;
$rkt-menu-item-text-display: block !default;
$rkt-menu-item-text-max-width: 220px !default;
$rkt-menu-item-text-flex-grow-with-tag: 1 !default;
$rkt-menu-item-text-margin-top: 1px !default;
$rkt-menu-item-text-group-display: inline-flex !default;
$rkt-menu-item-text-group-flex-direction: column !default;
$rkt-menu-item-text-group-justify-content: center !default;
$rkt-menu-item-text-group-align-items: flex-start !default;
$rkt-menu-item-text-group-flex-grow: 1 !default;
$rkt-menu-item-center-item-display: inline-flex !default;
$rkt-menu-item-center-item-align-items: center !default;
$rkt-menu-item-center-item-flex: 1 0 auto !default;
$rkt-menu-item-caption-color: $rkt-gray-600 !default;
$rkt-menu-item-caption-font-family: $rkt-typography-font-family-body-14 !default;
$rkt-menu-item-caption-font-size: $rkt-typography-font-size-body-14 !default;
$rkt-menu-item-caption-font-style: normal !default;
$rkt-menu-item-caption-font-weight: $rkt-typography-font-weight-body-14 !default;
$rkt-menu-item-caption-line-height: $rkt-typography-line-height-body-14 !default;
$rkt-menu-item-caption-overflow: hidden !default;
$rkt-menu-item-caption-text-overflow: ellipsis !default;
$rkt-menu-item-caption-white-space: nowrap !default;
$rkt-menu-item-caption-display: block !default;
$rkt-menu-item-caption-max-width: 220px !default;
$rkt-menu-item-icon-display: flex !default;
$rkt-menu-item-icon-align-items: center !default;
$rkt-menu-item-icon-justify-content: center !default;
$rkt-menu-item-icon-color: $rkt-gray-800 !default;
$rkt-menu-item-icon-height: 24px !default;
$rkt-menu-item-icon-width: 24px !default;
$rkt-menu-item-icon-margin-right: 16px !default;
$rkt-menu-item-submenu-icon-margin-right: 0 !default;
$rkt-menu-item-submenu-icon-display: none !default;
$rkt-menu-item-submenu-image-content: ' ' !default;
$rkt-menu-item-submenu-image-display: block !default;
$rkt-menu-item-submenu-image-background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M10.5 15.7637L14.5 11.7637L10.5 7.76367" stroke="%23111111" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>') !default;
$rkt-menu-item-submenu-image-width: 24px !default;
$rkt-menu-item-submenu-image-height: 24px !default;
$rkt-menu-item-submenu-image-position: absolute !default;
$rkt-menu-item-submenu-image-top: 20px !default;
$rkt-menu-item-submenu-image-right: 20px !default;
$rkt-menu-item-submenu-image-left: 204px !default;
$rkt-menu-item-submenu-image-background-repeat: no-repeat !default;
$rkt-menu-item-submenu-image-disabled-opacity: 0.2 !default;
$rkt-menu-item-disabled-opacity: unset !default;
$rkt-menu-item-disabled-background-color: $rkt-white !default;
$rkt-menu-item-disabled-color: $rkt-gray-400 !default;
$rkt-menu-item-disabled-icon-opacity: 0.38 !default;
$rkt-menu-item-disabled-tag-opacity: 0.2 !default;
$rkt-menu-item-hover-color: $rkt-gray-800 !default;
$rkt-menu-item-hover-background-color: $rkt-gray-50 !default;
$rkt-menu-item-focus-visible-box-shadow: inset 0 0 0 3px $rkt-gray-800 !default;
$rkt-menu-item-focus-visible-background-color: $rkt-white !default;
$rkt-menu-item-active-background-color: $rkt-gray-100 !default;
$rkt-menu-item-border-radius: 8px !default;
$rkt-menu-item-padding: 20px !default;
$rkt-menu-item-padding-with-tag: 18px 20px !default;
$rkt-menu-item-height: 64px !default;
$rkt-menu-item-min-width: 248px !default;
$rkt-menu-item-align-items: center !default;
$rkt-menu-item-align-items-with-button: center !default;
$rkt-menu-item-align-self: stretch !default;
$rkt-menu-item-width: 100% !default;
$rkt-menu-item-display: flex !default;
$rkt-menu-item-color: $rkt-gray-800 !default;
$rkt-menu-item-button-item-height: auto !default;
$rkt-menu-item-button-item-width: 100% !default;
$rkt-menu-item-button-item-ripple-display: none !default;
$rkt-menu-item-button-item-background-color: $rkt-white !default;
$rkt-menu-item-button-item-cursor: default !default;
$rkt-menu-item-button-item-cursor-active: pointer !default;
$rkt-menu-item-button-item-box-shadow: none !default;
$rkt-menu-item-button-item-button-box-shadow: inset 0 0 0 3px $rkt-red-500 !default;
$rkt-menu-item-tag-max-width: 70.07px !default;
$rkt-menu-item-tag-min-width: 0 !default;
$rkt-menu-divider-border-color: $rkt-gray-100 !default;
$rkt-menu-divider-indent: 12px !default;
$rkt-menu-divider-width: calc(100% - (2 * $rkt-menu-divider-indent)) !default;
$rkt-menu-divider-margin: 0 !default;
$rkt-menu-notification-item-display: flex !default;
$rkt-menu-notification-item-height: auto !default;
$rkt-menu-notification-item-align-items: flex-start !default;
$rkt-menu-notification-item-padding-top: 12px !default;
$rkt-menu-notification-item-padding-bottom: 12px !default;
$rkt-menu-notification-item-border-bottom: 1px solid $rkt-gray-100 !default;
$rkt-menu-item-notification-icon-color: $rkt-white !default;
$rkt-menu-item-notification-icon-margin-right: $rkt-spacing-16 !default;
$rkt-menu-item-notification-icon-background-color-border-radius: 50% !default;
$rkt-menu-item-notification-icon-flex-shrink: 0 !default;
$rkt-menu-item-notification-icon-padding: 8px !default;
$rkt-pagination-button-padding: 0 !default;
$rkt-pagination-button-align-items: center !default;
$rkt-pagination-button-cursor: pointer !default;
$rkt-pagination-button-background-color: transparent !default;
$rkt-pagination-button-border: 0 !default;
$rkt-pagination-button-text-align: center !default;
$rkt-pagination-button-box-sizing: border-box !default;
$rkt-pagination-button-border-radius: 20px !default;
$rkt-pagination-button-justify-content: center !default;
$rkt-pagination-button-display: flex !default;
$rkt-pagination-button-width: 40px !default;
$rkt-pagination-button-height: 40px !default;
$rkt-pagination-button-color: $rkt-gray-800 !default;
$rkt-pagination-button-disabled-color: $rkt-gray-400 !default;
$rkt-pagination-button-hover-background-color: $rkt-gray-100 !default;
$rkt-pagination-button-focus-visible-box-shadow: inset 0 0 0 3px $rkt-gray-900 !default;
$rkt-pagination-button-focus-visible-outline: none !default;
$rkt-pagination-button-focus-visible-background-color: transparent !default;
$rkt-pagination-icon-color: $rkt-gray-800 !default;
$rkt-pagination-icon-width: 24px !default;
$rkt-pagination-icon-height: 24px !default;
$rkt-pagination-background-color: $rkt-white !default;
$rkt-pagination-page-size-label-margin: 0 !default;
$rkt-pagination-page-size-label-font-family: $rkt-typography-font-family-body-14 !default;
$rkt-pagination-page-size-label-font-size: $rkt-typography-font-size-body-14 !default;
$rkt-pagination-page-size-label-font-weight: $rkt-typography-font-weight-body-14 !default;
$rkt-pagination-page-size-label-color: $rkt-gray-800 !default;
$rkt-pagination-page-size-label-color-disabled: $rkt-gray-400 !default;
$rkt-pagination-page-size-label-line-height: $rkt-typography-line-height-body-14 !default;
$rkt-pagination-select-option-background-color-hover: $rkt-gray-100 !default;
$rkt-pagination-select-bottom-border: 1px solid $rkt-gray-800 !default;
$rkt-pagination-actions-display: flex !default;
$rkt-pagination-actions-align-items: center !default;
$rkt-pagination-actions-justify-content: center !default;
$rkt-pagination-actions-margin: 0 !default;
$rkt-pagination-ripple-display: none !default;
$rkt-pagination-toolbar-gap: 36px !default;
$rkt-pagination-range-label-margin-right: 36px !default;
$rkt-pagination-container-padding: 0 !default;
$rkt-pagination-container-min-height: unset !default;
$rkt-pagination-container-justify-content: flex-start !default;
$rkt-progress-bar-buffer-bar-keyframes-transform: rotate(180deg) translateX(-21px) !default;
$rkt-progress-bar-buffer-bar-border: none !default;
$rkt-progress-bar-buffer-bar-background-color: transparent !default;
$rkt-progress-bar-buffer-bar-background-position-y: center !default;
$rkt-progress-bar-buffer-bar-background-size: 21px 4px !default;
$rkt-progress-bar-buffer-bar-background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="4" height="4" viewBox="0 0 4 4" fill="none"><circle cx="2" cy="2" r="2" fill="%23C1C1C1"/></svg>') !default;
$rkt-progress-bar-buffer-bar-z-index: 1 !default;
$rkt-progress-bar-buffer-bar-background-repeat: repeat-x !default;
$rkt-progress-bar-buffer-bar-transform: rotate(180deg) !default;
$rkt-progress-bar-buffer-bar-animation: rkt-Progress-Bar--buffer-dots 250ms linear 0s infinite !default;
$rkt-progress-bar-buffer-bar-webkit-mask-image: none !default;
$rkt-progress-bar-buffer-bar-mask-image: none !default;
$rkt-progress-bar-track-background-color: $rkt-gray-100 !default;
$rkt-progress-bar-border-radius: 100px !default;
$rkt-progress-bar-background-color: $rkt-gray-100 !default;
$rkt-progress-bar-height: 16px !default;
$rkt-progress-bar-bottom: auto !default;
$rkt-progress-bar-inner-bar-background-color: $rkt-red-500 !default;
$rkt-progress-bar-inner-bar-border-color: $rkt-red-500 !default;
$rkt-progress-bar-inner-bar-border-top-width: 16px !default;
$rkt-progress-bar-thin-height: 6px !default;
$rkt-progress-bar-thin-inner-bar-border-top-width: 6px !default;
$rkt-progress-bar-thin-buffer-bar-keyframes-transform: rotate(180deg) translateX(-23px) !default;
$rkt-progress-bar-thin-buffer-bar-background-size: 23px 2px !default;
$rkt-progress-bar-thin-buffer-bar-background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="3" height="2" viewBox="0 0 3 2" fill="none"><circle cx="1.42969" cy="1" r="1" fill="%23C1C1C1"/></svg>') !default;
$rkt-progress-bar-thin-buffer-bar-animation: rkt-Progress-Bar--buffer-dots-thin 250ms linear 0s infinite !default;
$rkt-progress-bar-label-color: $rkt-gray-800 !default;
$rkt-progress-bar-label-font-size: $rkt-typography-font-size-body-14 !default;
$rkt-progress-bar-label-font-family: $rkt-typography-font-family-body-14 !default;
$rkt-progress-bar-label-font-style: normal !default;
$rkt-progress-bar-label-font-weight: $rkt-typography-font-weight-500 !default;
$rkt-progress-bar-label-line-height: $rkt-typography-line-height-body-14 !default;
$rkt-promo-content-text-margin: 0 !default;
$rkt-promo-content-text-font-family: $rkt-typography-font-family-body-16 !default;
$rkt-promo-content-text-line-height: $rkt-typography-line-height-body-16 !default;
$rkt-promo-content-text-font-size: $rkt-typography-font-size-body-16 !default;
$rkt-promo-content-text-font-weight: $rkt-typography-font-weight-body-16 !default;
$rkt-promo-content-text-color: $rkt-gray-600 !default;
$rkt-promo-background-color: $rkt-white !default;
$rkt-promo-margin: 0 auto !default;
$rkt-promo-max-width: 42.5rem !default;
$rkt-promo-content-padding: $rkt-spacing-32 !default;
$rkt-promo-flag-content-padding: $rkt-spacing-16 !default;
$rkt-promo-max-width-full: 100% !default;
$rkt-promo-breakpoint: 42.5rem !default;
$rkt-promo-image-container-wide-max-width: 25% !default;
$rkt-promo-image-narrow-max-height: 20rem !default;
$rkt-promo-image-object-fit: cover !default;
$rkt-promo-image-height: 100% !default;
$rkt-promo-image-width: 100% !default;
$rkt-promo-image-flag-narrow-max-width: 50% !default;
$rkt-promo-image-flag-padding: $rkt-spacing-16 !default;
$rkt-promo-image-flag-height: auto !default;
$rkt-promo-image-flag-width: auto !default;
$rkt-promo-heading-margin: 0 !default;
$rkt-promo-heading-font-color: $rkt-gray-800 !default;
$rkt-promo-heading-font-weight: $rkt-typography-font-weight-heading-28 !default;
$rkt-promo-heading-font-family: $rkt-typography-font-family-heading-28 !default;
$rkt-promo-heading-font-size: $rkt-typography-font-size-heading-28 !default;
$rkt-promo-heading-font-size-medium: $rkt-typography-font-size-heading-24 !default;
$rkt-promo-heading-font-size-small: $rkt-typography-font-size-heading-20 !default;
$rkt-promo-heading-line-height: $rkt-typography-line-height-heading-28 !default;
$rkt-promo-heading-line-height-medium: $rkt-typography-line-height-heading-24 !default;
$rkt-promo-heading-line-height-small: $rkt-typography-line-height-heading-20 !default;
$rkt-promo-subheading-font-color: $rkt-black !default;
$rkt-promo-subheading-font-weight: $rkt-typography-font-weight-400 !default;
$rkt-radio-disabled-label-color: $rkt-gray-400 !default;
$rkt-radio-padding: $rkt-spacing-8 !default;
$rkt-radio-label-font-size: $rkt-typography-font-size-label-16 !default;
$rkt-radio-label-font-weight: $rkt-typography-font-weight-400 !default;
$rkt-radio-label-line-height: $rkt-typography-line-height-label-16 !default;
$rkt-radio-label-color: $rkt-gray-800 !default;
$rkt-radio-label-right-positioning-margin:  0 16px 0 0 !default;
$rkt-radio-label-padding: 0 !default;
$rkt-radio-label-mui-padding: 0 0 0 7px !default;
$rkt-radio-label-mui-margin: -9px -9px 7px -9px !default;
$rkt-radio-label-min-height: 42px !default;
$rkt-radio-circle-size: 24px !default;
$rkt-radio-circle-background: $rkt-white !default;
$rkt-radio-circle-border-width: 1.5px !default;
$rkt-radio-circle-border-color: $rkt-gray-400 !default;
$rkt-radio-circle-padding: 0 16px 0 0 !default;
$rkt-radio-circle-active-color: $rkt-green-600 !default;
$rkt-radio-circle-active-border-width: 4px !default;
$rkt-radio-circle-active-inner-border-width: 12px !default;
$rkt-radio-circle-active-inner-transform: scale(0.55) !default;
$rkt-radio-circle-active-inner-background-color: $rkt-green-600 !default;
$rkt-radio-circle-active-inner-border-color: $rkt-green-600 !default;
$rkt-radio-circle-hover-border-color: $rkt-gray-800 !default;
$rkt-radio-circle-hover-border-width: 3px !default;
$rkt-radio-circle-disabled-color: $rkt-gray-300 !default;
$rkt-radio-circle-focus-stroke: $rkt-gray-800 !default;
$rkt-radio-circle-focus-background: $rkt-black-opacity-08 !default;
$rkt-radio-circle-focus-border-radius: 24px !default;
$rkt-radio-circle-focus-stroke-width: 4.5px !default;
$rkt-radio-circle-focus-padding: 8px !default;
$rkt-radio-circle-focus-margin-left: 1px !default;
$rkt-radio-circle-focus-margin-right: 1px !default;
$rkt-radio-ripple-background-color: $rkt-gray-100 !default;
$rkt-radio-ripple-focus-selected-background: $rkt-black-opacity-08 !default;
$rkt-radio-right-positioning-label-padding: 0 7px 0 0 !default;
$rkt-radio-right-positioning-label-margin: -9px 0px 7px 0px !default;
$rkt-select-form-field-padding: 12px 20px !default;
$rkt-select-form-field-width: 278px !default;
$rkt-select-form-field-label-font-family: $rkt-typography-font-family-label-14 !default;
$rkt-select-form-field-label-left: 8.5px !default;
$rkt-select-form-field-label-asterisk-display: none !default;
$rkt-select-form-field-error-margin: 16px 0 0 0 !default;
$rkt-select-form-field-error-margin-mui: 17px 0 0 16px !default;
$rkt-select-input-width: unset !default;
$rkt-select-input-border-radius: 16px !default;
$rkt-select-input-background-color: $rkt-gray-50 !default;
$rkt-select-input-background-mui: none !default;
$rkt-select-input-padding: 0 !default;
$rkt-select-input-margin: 15.5px 0 0 0 !default;
$rkt-select-input-icon-display: none !default;
$rkt-select-input-disabled-webkit-text-fill-color: unset !default;
$rkt-select-input-focus-background-color: $rkt-gray-100 !default;
$rkt-select-input-error-box-shadow: inset 0 0 0 1.5px $rkt-yellow-600 !default;
$rkt-select-input-error-focused-box-shadow: inset 0 0 0 3px $rkt-yellow-600 !default;
$rkt-select-input-hover-background-color: $rkt-gray-100 !default;
$rkt-select-input-placeholder-color: $rkt-gray-500 !default;
$rkt-select-input-placeholder-font-family: $rkt-typography-font-family-label-16 !default;
$rkt-select-input-placeholder-font-size: $rkt-typography-font-size-label-16 !default;
$rkt-select-input-placeholder-font-weight: $rkt-typography-font-weight-400 !default;
$rkt-select-input-placeholder-font-style: normal !default;
$rkt-select-input-placeholder-line-height: 24px !default;
$rkt-select-input-placeholder-focus-color: $rkt-gray-800 !default;
$rkt-select-input-placeholder-hover-color: $rkt-gray-600 !default;
$rkt-select-input-placeholder-disabled-color: $rkt-gray-300 !default;
$rkt-select-input-selected-value-color: $rkt-gray-800 !default;
$rkt-select-input-arrow-display: none !default;
$rkt-select-input-chevron-display: block !default;
$rkt-select-input-chevron-width: 24px !default;
$rkt-select-input-chevron-height: 24px !default;
$rkt-select-input-chevron-position: absolute !default;
$rkt-select-input-chevron-top: -8px !default;
$rkt-select-input-chevron-top-mui: 22px !default;
$rkt-select-input-chevron-right: 0px !default;
$rkt-select-input-chevron-right-mui: 20px !default;
$rkt-select-input-chevron-background-repeat: no-repeat !default;
$rkt-select-input-chevron-background-position: center !default;
$rkt-select-input-chevron-default-background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M8 10L12 14L16 10" stroke="%23111111" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>') !default;
$rkt-select-input-chevron-default-expanded-background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none"><path d="M9 5L5 1L1 5" stroke="%23111111" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>') !default;
$rkt-select-input-chevron-disabled-background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M8 10L12 14L16 10" stroke="%23949494" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>') !default;
$rkt-select-input-chevron-invalid-background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M8 10L12 14L16 10" stroke="%23A56A06" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>') !default;
$rkt-select-input-chevron-invalid-expanded-background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="6" viewBox="0 0 10 6" fill="none"><path d="M9 5L5 1L1 5" stroke="%23A56A06" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>') !default;
$rkt-select-panel-border-radius: 16px !default;
$rkt-select-panel-border: 1px solid rgba(0, 0, 0, 0.04) !default;
$rkt-select-panel-background-color: $rkt-white !default;
$rkt-select-panel-box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.10) !default;
$rkt-select-panel-padding: 8px !default;
$rkt-select-panel-margin: 8px 0 0 0 !default;
$rkt-select-panel-max-height: 397px !default;
$rkt-select-panel-right: 16px !default;
$rkt-select-panel-left: 16px !default;
$rkt-select-panel-width: 260px !default;
$rkt-select-panel-width-when-full-width: unset !default;
$rkt-select-panel-list-box-padding: 0 !default;
$rkt-select-panel-list-item-padding: 20px 12px !default;
$rkt-select-panel-list-item-border-radius: 8px !default;
$rkt-select-panel-list-item-min-height: unset !default;
$rkt-select-panel-list-item-background-color: $rkt-white !default;
$rkt-select-panel-list-item-overflow: hidden !default;
$rkt-select-panel-list-item-text-overflow: ellipsis !default;
$rkt-select-panel-list-item-white-space: nowrap !default;
$rkt-select-panel-list-item-color: $rkt-gray-800 !default;
$rkt-select-panel-list-item-font-family: $rkt-typography-font-family-label-16 !default;
$rkt-select-panel-list-item-font-size: $rkt-typography-font-size-label-16 !default;
$rkt-select-panel-list-item-font-weight: $rkt-typography-font-weight-label-16 !default;
$rkt-select-panel-list-item-font-style: normal !default;
$rkt-select-panel-list-item-line-height: $rkt-typography-line-height-label-16 !default;
$rkt-select-panel-list-item-display: block !default;
$rkt-select-panel-list-item-hover-background-color: $rkt-gray-50 !default;
$rkt-select-panel-list-item-selected-background-color: $rkt-green-50 !default;
$rkt-select-panel-list-item-selected-color: $rkt-green-700 !default;
$rkt-select-panel-list-item-selected-checkmark-display: block !default;
$rkt-select-panel-list-item-selected-checkmark-background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M8 12.4238L10.6706 15.0943L16.5 9.26367" stroke="%230B5E36" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>') !default;
$rkt-select-panel-list-item-selected-checkmark-width: 24px !default;
$rkt-select-panel-list-item-selected-checkmark-height: 24px !default;
$rkt-select-panel-list-item-selected-checkmark-position: absolute !default;
$rkt-select-panel-list-item-selected-checkmark-top: 20px !default;
$rkt-select-panel-list-item-selected-checkmark-right: 12px !default;
$rkt-select-panel-list-item-selected-checkmark-background-repeat: no-repeat !default;
$rkt-select-panel-list-item-selected-checkmark-background-position: center !default;
$rkt-select-panel-list-item-disabled-opacity: 1 !default;
$rkt-select-panel-list-item-disabled-color: $rkt-gray-400 !default;
$rkt-select-panel-list-item-mat-checkbox-position: absolute !default;
$rkt-select-panel-list-item-mat-checkbox-right: 12px !default;
$rkt-select-panel-list-item-mat-checkbox-margin: 0 !default;
$rkt-select-panel-list-item-mat-checkbox-width: 24px !default;
$rkt-select-panel-list-item-mat-checkbox-height: 24px !default;
$rkt-select-panel-list-item-mat-checkbox-border-radius: 8px !default;
$rkt-select-panel-list-item-mat-checkbox-border: 1.5px solid $rkt-gray-400 !default;
$rkt-select-panel-list-item-mat-checkbox-background-color: $rkt-white !default;
$rkt-select-panel-list-item-mat-checkbox-checked-background-color: $rkt-green-600 !default;
$rkt-select-panel-list-item-mat-checkbox-checked-border: none !default;
$rkt-select-panel-list-item-mat-checkbox-disabled-border: 1.5px solid $rkt-gray-300 !default;
$rkt-select-panel-list-item-mui-checkbox-display: block !default;
$rkt-select-panel-list-item-mui-checkbox-width: 24px !default;
$rkt-select-panel-list-item-mui-checkbox-height: 24px !default;
$rkt-select-panel-list-item-mui-checkbox-position: absolute !default;
$rkt-select-panel-list-item-mui-checkbox-top: 20px !default;
$rkt-select-panel-list-item-mui-checkbox-right: 12px !default;
$rkt-select-panel-list-item-mui-checkbox-background-repeat: no-repeat !default;
$rkt-select-panel-list-item-mui-checkbox-background-position: center !default;
$rkt-select-panel-list-item-mui-checkbox-default-background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><rect x="0.75" y="0.75" width="22.5" height="22.5" rx="7.25" fill="white"/><rect x="0.75" y="0.75" width="22.5" height="22.5" rx="7.25" stroke="%23949494" stroke-width="1.5"/></svg>') !default;
$rkt-select-panel-list-item-mui-checkbox-selected-background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><rect width="24" height="24" rx="8" fill="%230A9142"/><path d="M8 12.4238L10.6706 15.0943L16.5 9.26367" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>') !default;
$rkt-select-panel-list-item-mui-checkbox-disabled-background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><rect x="0.75" y="0.75" width="22.5" height="22.5" rx="7.25" fill="white"/><rect x="0.75" y="0.75" width="22.5" height="22.5" rx="7.25" stroke="%23C1C1C1" stroke-width="1.5"/></svg>') !default;
$rkt-select-panel-list-item-divider-display: block !default;
$rkt-select-panel-list-item-divider-position: absolute !default;
$rkt-select-panel-list-item-divider-top: 0 !default;
$rkt-select-panel-list-item-divider-left: 12px !default;
$rkt-select-panel-list-item-divider-right: 12px !default;
$rkt-select-panel-list-item-divider-height: 1px !default;
$rkt-select-panel-list-item-divider-background-color: $rkt-gray-100 !default;
$rkt-select-panel-list-item-divider-opacity: 1 !default;
$rkt-select-panel-list-item-divider-border: 0 !default;
$rkt-selection-bg-color: rgba(153, 214, 189, 0.40) !default;
$rkt-selection-text-color: $rkt-black !default;
$rkt-pre-overflow: auto !default;
$rkt-border-radius: 5px !default;
$rkt-sidenav-section-heading-margin: 0 !default;
$rkt-sidenav-section-heading-font-size: $rkt-typography-font-size-tracked-12 !default;
$rkt-sidenav-section-heading-color: $rkt-gray-600 !default;
$rkt-sidenav-section-heading-font-weight: $rkt-typography-font-weight-tracked-12 !default;
$rkt-sidenav-section-heading-line-height: $rkt-typography-line-height-tracked-12 !default;
$rkt-sidenav-section-heading-font-family: $rkt-typography-font-family-tracked-12 !default;
$rkt-sidenav-section-heading-text-transform: $rkt-typography-text-transform-tracked-12 !default;
$rkt-sidenav-section-heading-letter-spacing: $rkt-typography-letter-spacing-tracked-12 !default;
$rkt-sidenav-section-description-margin: 0 !default;
$rkt-sidenav-section-description-font-size: $rkt-typography-font-size-caption-12 !default;
$rkt-sidenav-section-description-color: $rkt-gray-600 !default;
$rkt-sidenav-section-description-font-weight: $rkt-typography-font-weight-caption-12 !default;
$rkt-sidenav-section-description-font-family: $rkt-typography-font-family-caption-12 !default;
$rkt-sidenav-section-description-line-height: $rkt-typography-line-height-caption-12 !default;
$rkt-sidenav-subtext-margin: 0 !default;
$rkt-sidenav-subtext-font-size: $rkt-typography-font-size-caption-12 !default;
$rkt-sidenav-subtext-color: $rkt-gray-600 !default;
$rkt-sidenav-subtext-font-weight: $rkt-typography-font-weight-caption-12 !default;
$rkt-sidenav-subtext-font-family: $rkt-typography-font-family-caption-12 !default;
$rkt-sidenav-subtext-line-height: $rkt-typography-line-height-caption-12 !default;
$rkt-sidenav-icon-color: $rkt-gray-600 !default;
$rkt-sidenav-item-link-active-icon-color: $rkt-red-500 !default;
$rkt-sidenav-item-link-active-background-color: $rkt-gray-50 !default;
$rkt-sidenav-item-link-border-radius: 12px !default;
$rkt-sidenav-item-link-header-padding: 12px !default;
$rkt-sidenav-item-link-align-items: center !default;
$rkt-sidenav-item-link-hover-background-color: $rkt-gray-100 !default;
$rkt-sidenav-background-color: $rkt-white !default;
$rkt-sidenav-container-flex-direction: column !default;
$rkt-sidenav-container-flex-direction-large: row !default;
$rkt-sidenav-bar-box-shadow: 0 0 40px rgba(0, 0, 0, 0.1) !default;
$rkt-sidenav-bar-display: flex !default;
$rkt-sidenav-bar-display-large: none !default;
$rkt-sidenav-drawer-popover-display-large: none !default;
$rkt-sidenav-drawer-popover-display: block !default;
$rkt-sidenav-drawer-flex-shrink: 0 !default;
$rkt-sidenav-drawer-fixed-display-large: block !default;
$rkt-sidenav-drawer-fixed-display: none !default;
$rkt-sidenav-bar-transition: box-shadow 0.3s ease-in !default;
$rkt-sidenav-bar-layer: $rkt-layer-height-s !default;
$rkt-sidenav-bar-min-height: 64px !default;
$rkt-sidenav-backdrop-position: fixed !default;
$rkt-sidenav-bar-position: sticky !default;
$rkt-sidenav-bar-top: 0 !default;
$rkt-sidenav-drawer-border-top: 1px solid $rkt-gray-200 !default;
$rkt-sidenav-drawer-border-right: 0 !default;
$rkt-sidenav-drawer-box-shadow: 0px 12px 12px 0px rgba(0, 0, 0, 0.06) !default;
$rkt-sidenav-drawer-width: 264px !default;
$rkt-sidenav-client-details-max-width: 140px !default;
$rkt-sidenav-input-padding-top: 22.75px !default;
$rkt-sidenav-logo-max-width: 136px !default;
$rkt-sidenav-logo-display: inline-block !default;
$rkt-sidenav-logo-min-width: 136px !default;
$rkt-sidenav-logo-max-height: 25px !default;
$rkt-sidenav-rewards-border-radius: 8px !default;
$rkt-sidenav-rewards-align-items: center !default;
$rkt-sidenav-rewards-display: flex !default;
$rkt-sidenav-rewards-flex: 1 !default;
$rkt-sidenav-rewards-width: 100% !default;
$rkt-sidenav-rewards-padding: 10px 8px 10px 12px !default;
$rkt-sidenav-rewards-border-color: $rkt-gray-100 !default;
$rkt-sidenav-rewards-text-align: left !default;
$rkt-sidenav-rewards-border-color-hover: $rkt-gray-50 !default;
$rkt-sidenav-rewards-background-color-hover: $rkt-gray-50 !default;
$rkt-sidenav-rewards-height: 38px !default;
$rkt-sidenav-breakpoint: 768px !default;
$rkt-sidenav-react-toolbar-min-height: auto !default;
$rkt-sidenav-item-icon-flex-shrink: 0 !default;
$rkt-sidenav-item-link-font-family: $rkt-typography-font-family-label-16 !default;
$rkt-sidenav-item-link-font-size: $rkt-typography-font-size-label-16 !default;
$rkt-sidenav-item-link-font-weight: $rkt-typography-font-weight-label-16 !default;
$rkt-sidenav-item-link-color: $rkt-typography-color-label-16 !default;
$rkt-sidenav-item-link-line-height: $rkt-typography-line-height-label-16 !default;
$rkt-slide-toggle-bottom-label-margin-bottom: $rkt-spacing-8 !default;
$rkt-slide-toggle-bottom-label-flex-direction: column !default;
$rkt-slide-toggle-theme-disabled-thumb-opacity: 0.38 !default;
$rkt-slide-toggle-theme-unchecked-track-color: $rkt-blue-300 !default;
$rkt-slide-toggle-theme-icon-width: 16px !default;
$rkt-slide-toggle-theme-icon-height: 16px !default;
$rkt-slide-toggle-theme-icon-left: 16px !default;
$rkt-slide-toggle-theme-icon-top: 16px !default;
$rkt-slide-toggle-theme-icons-left: 2px !default;
$rkt-slide-toggle-theme-icons-top: 2px !default;
$rkt-slide-toggle-theme-icon-position: absolute !default;
$rkt-slide-toggle-theme-icon-content: '' !default;
$rkt-slide-toggle-theme-thumb-background: url("data:image/svg+xml,<svg viewBox='0 0 24 24' id='day-outlined' xmlns='http://www.w3.org/2000/svg'><path d='M12 15c.833 0 1.542-.292 2.125-.875S15 12.833 15 12s-.292-1.542-.875-2.125S12.833 9 12 9s-1.542.292-2.125.875S9 11.167 9 12s.292 1.542.875 2.125A2.893 2.893 0 0 0 12 15zm0 2c-1.383 0-2.563-.487-3.538-1.462S7 13.383 7 12s.487-2.563 1.462-3.538S10.617 7 12 7s2.563.487 3.538 1.462S17 10.617 17 12s-.487 2.563-1.462 3.538S13.383 17 12 17zm-7-4H1v-2h4zm18 0h-4v-2h4zM11 5V1h2v4zm0 18v-4h2v4zM6.4 7.75L3.875 5.325 5.3 3.85l2.4 2.5zm12.3 12.4l-2.425-2.525L17.6 16.25l2.525 2.425zM16.25 6.4l2.425-2.525L20.15 5.3l-2.5 2.4zM3.85 18.7l2.525-2.425L7.75 17.6l-2.425 2.525z' /></svg>") !default;
$rkt-slide-toggle-theme-thumb-background-disabled: url("data:image/svg+xml,<svg viewBox='0 0 24 24' id='day-outlined' xmlns='http://www.w3.org/2000/svg' opacity='0.3'><path d='M12 15c.833 0 1.542-.292 2.125-.875S15 12.833 15 12s-.292-1.542-.875-2.125S12.833 9 12 9s-1.542.292-2.125.875S9 11.167 9 12s.292 1.542.875 2.125A2.893 2.893 0 0 0 12 15zm0 2c-1.383 0-2.563-.487-3.538-1.462S7 13.383 7 12s.487-2.563 1.462-3.538S10.617 7 12 7s2.563.487 3.538 1.462S17 10.617 17 12s-.487 2.563-1.462 3.538S13.383 17 12 17zm-7-4H1v-2h4zm18 0h-4v-2h4zM11 5V1h2v4zm0 18v-4h2v4zM6.4 7.75L3.875 5.325 5.3 3.85l2.4 2.5zm12.3 12.4l-2.425-2.525L17.6 16.25l2.525 2.425zM16.25 6.4l2.425-2.525L20.15 5.3l-2.5 2.4zM3.85 18.7l2.525-2.425L7.75 17.6l-2.425 2.525z' /></svg>") !default;
$rkt-slide-toggle-theme-checked-thumb-background: url("data:image/svg+xml,<svg viewBox='0 0 24 24' id='night-outlined' xmlns='http://www.w3.org/2000/svg'><path d='M12 21c-2.5 0-4.625-.875-6.375-2.625S3 14.5 3 12s.875-4.625 2.625-6.375S9.5 3 12 3a9.304 9.304 0 0 1 1.35.1c-.683.483-1.23 1.113-1.637 1.887S11.1 6.6 11.1 7.5c0 1.5.525 2.775 1.575 3.825S15 12.9 16.5 12.9c.917 0 1.758-.204 2.525-.613s1.392-.954 1.875-1.637A8.505 8.505 0 0 1 21 12c0 2.5-.875 4.625-2.625 6.375S14.5 21 12 21zm0-2c1.467 0 2.783-.404 3.95-1.212s2.017-1.863 2.55-3.163c-.333.083-.667.15-1 .2s-.667.075-1 .075c-2.05 0-3.796-.72-5.237-2.163C9.82 11.296 9.1 9.55 9.1 7.5c0-.333.025-.667.075-1s.117-.667.2-1c-1.3.533-2.354 1.383-3.163 2.55S5 10.533 5 12c0 1.933.683 3.583 2.05 4.95S10.067 19 12 19z' /></svg>") !default;
$rkt-slide-toggle-label-left-margin-right: $rkt-spacing-12 !default;
$rkt-slide-toggle-label-right-margin-left: $rkt-spacing-12 !default;
$rkt-slide-toggle-label-font-size: $rkt-typography-font-size-label-16 !default;
$rkt-slide-toggle-label-font-weight: $rkt-typography-font-weight-400 !default;
$rkt-slide-toggle-default-label-color: $rkt-gray-800 !default;
$rkt-slide-toggle-default-checked-track-color: $rkt-green-600 !default;
$rkt-slide-toggle-default-unchecked-track-color: $rkt-gray-200 !default;
$rkt-slide-toggle-default-border: 1px solid $rkt-black-opacity-04 !default;
$rkt-slide-toggle-default-box-sizing: border-box !default;
$rkt-slide-toggle-disabled-label-color: $rkt-gray-400 !default;
$rkt-slide-toggle-disabled-checked-thumb-color: $rkt-gray-300 !default;
$rkt-slide-toggle-disabled-track-color: $rkt-gray-100 !default;
$rkt-slide-toggle-disabled-icon-fill: $rkt-gray-400 !default;
$rkt-slide-toggle-disabled-thumb-display: none !default;
$rkt-slide-toggle-disabled-switch-handle-unchecked-box-shadow: none !default;
$rkt-slide-toggle-disabled-switch-handle-unchecked-color: $rkt-white !default;
$rkt-slide-toggle-disabled-border: none !default;
$rkt-slide-toggle-switch-width: 42px !default;
$rkt-slide-toggle-switch-height: 24px !default;
$rkt-slide-toggle-switch-border-radius: 12px !default;
$rkt-slide-toggle-switch-ripple-height: 34px !default;
$rkt-slide-toggle-switch-ripple-width: 34px !default;
$rkt-slide-toggle-switch-ripple-background-color: unset !default;
$rkt-slide-toggle-switch-ripple-keyboard-focused-display: none !default;
$rkt-slide-toggle-switch-ripple-hover-background-color: $rkt-black-opacity-04 !default;
$rkt-slide-toggle-switch-ripple-hover-border-radius: 24px !default;
$rkt-slide-toggle-switch-ripple-active-background-color: $rkt-black-opacity-08 !default;
$rkt-slide-toggle-switch-ripple-active-border-radius: 24px !default;
$rkt-slide-toggle-switch-ripple-transition: background-color 0.15s ease !default;
$rkt-slide-toggle-switch-handle-background-color: $rkt-white !default;
$rkt-slide-toggle-switch-handle-width: 18px !default;
$rkt-slide-toggle-switch-handle-height: 18px !default;
$rkt-slide-toggle-switch-handle-left: 11px !default;
$rkt-slide-toggle-switch-handle-top: 11px !default;
$rkt-slide-toggle-switch-handle-padding: 8px !default;
$rkt-slide-toggle-switch-handle-keyboard-focused-border: 2px solid $rkt-gray-800 !default;
$rkt-slide-toggle-switch-handle-keyboard-focused-box-sizing: border-box !default;
$rkt-slide-toggle-switch-handle-unchecked-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.52) !default;
$rkt-slide-toggle-switch-handle-checked-box-shadow: none !default;
$rkt-slide-toggle-switch-handle-checked-display: none !default;
$rkt-slide-toggle-width: 72px !default;
$rkt-slide-toggle-height: 56px !default;
$rkt-slide-toggle-padding: 16px !default;
$rkt-slide-toggle-unchecked-icon-display: none !default;
$rkt-slide-toggle-unchecked-icon-opacity: 0 !default;
$rkt-slide-toggle-unchecked-handle-left: 3px !default;
$rkt-slide-toggle-checked-handle-left: -1px !default;
$rkt-slide-toggle-checked-icon-fill: $rkt-gray-800 !default;
$rkt-slide-toggle-checked-icon-width: 16px !default;
$rkt-slide-toggle-checked-icon-height: 16px !default;
$rkt-slide-toggle-checked-icon-left: 16px !default;
$rkt-slide-toggle-checked-icon-top: 16px !default;
$rkt-slide-toggle-checked-thumb-background: url("data:image/svg+xml,<svg viewBox='0 0 24 24' id='check-two_tone' xmlns='http://www.w3.org/2000/svg'><path d='M8.8 15.87L4.63 11.7l-1.42 1.41L8.8 18.7l12-12-1.41-1.41z' /></svg>") !default;
$rkt-slide-toggle-checked-switch-handle-color: $rkt-white !default;
$rkt-slide-toggle-checked-switch-handle-left: 7px !default;
$rkt-slide-toggle-checked-transform: translateX(24px) !default;
$rkt-slider-focus-visible-filter: none !default;
$rkt-slider-small-label-container-margin-top: -3px !default;
$rkt-slider-small-label-container-content-margin-top: -6px !default;
$rkt-slider-small-mark-height: 2px !default;
$rkt-slider-small-mark-width: 2px !default;
$rkt-slider-secondary-track-background-color: $rkt-red-500 !default;
$rkt-slider-secondary-track-border-color: $rkt-red-500 !default;
$rkt-slider-secondary-track-border-top-color: $rkt-red-500 !default;
$rkt-slider-primary-track-background-color: $rkt-gray-800 !default;
$rkt-slider-primary-track-border-color: $rkt-gray-800 !default;
$rkt-slider-primary-track-border-top-color: $rkt-gray-800 !default;
$rkt-slider-success-track-background-color: $rkt-green-600 !default;
$rkt-slider-success-track-border-color: $rkt-green-600 !default;
$rkt-slider-success-track-border-top-color: $rkt-green-700 !default;
$rkt-slider-success-value-indicator-background-color: $rkt-green-700 !default;
$rkt-slider-info-track-background-color: $rkt-blue-400 !default;
$rkt-slider-info-track-border-color: $rkt-blue-400 !default;
$rkt-slider-info-track-border-top-color: $rkt-blue-900 !default;
$rkt-slider-info-value-indicator-background-color: $rkt-blue-900 !default;
$rkt-slider-value-indicator-font-size: 1rem !default;
$rkt-slider-value-indicator-z-index: 1000 !default;
$rkt-slider-value-indicator-bottom: -6px !default;
$rkt-slider-value-indicator-border-top-width: 7px !default;
$rkt-slider-value-indicator-border-top-color: $rkt-red-500 !default;
$rkt-slider-value-indicator-open-top: -17px !default;
$rkt-slider-value-indicator-left: 30px !default;
$rkt-slider-value-indicator-background-color: $rkt-red-500 !default;
$rkt-slider-value-indicator-justify-content: center !default;
$rkt-slider-value-indicator-opacity: 1 !default;
$rkt-slider-value-indicator-padding: 4px 12px 6px 12px !default;
$rkt-slider-value-indicator-border-radius: 8px !default;
$rkt-slider-value-indicator-height: auto !default;
$rkt-slider-value-indicator-line-height: 1 !default;
$rkt-slider-padding: 9px 0 0 0 !default;
$rkt-slider-small-padding: 10px 0 !default;
$rkt-slider-label-container-display: flex !default;
$rkt-slider-label-container-justify-content: space-between !default;
$rkt-slider-label-container-padding: 0 8px 0 8px !default;
$rkt-slider-label-container-margin-top: -2px !default;
$rkt-slider-width: calc(100% - 16px) !default;
$rkt-slider-color: $rkt-gray-800 !default;
$rkt-slider-label-font-size: $rkt-typography-font-size-body-14 !default;
$rkt-slider-label-font-family: $rkt-typography-font-family-caption-12 !default;
$rkt-slider-label-font-weight: $rkt-typography-font-weight-500 !default;
$rkt-slider-label-line-height: 150% !default;
$rkt-slider-label-color: $rkt-gray-800 !default;
$rkt-slider-label-disabled-color: $rkt-gray-400 !default;
$rkt-slider-label-margin: 0 !default;
$rkt-slider-handle-disabled-background-color: $rkt-gray-200 !default;
$rkt-slider-handle-disabled-border-color: $rkt-gray-100 !default;
$rkt-slider-handle-disabled-border-width: 7px !default;
$rkt-slider-handle-disabled-border-style: solid !default;
$rkt-slider-handle-disabled-filter: drop-shadow(0px 3px 15px rgba(43, 43, 43, 0.05)) !default;
$rkt-slider-handle-transform: scale(1) !default;
$rkt-slider-handle-top: 14px !default;
$rkt-slider-handle-flex-shrink: 0 !default;
$rkt-slider-handle-box-shadow: none !default;
$rkt-slider-handle-filter: drop-shadow(0px 3px 15px rgba(43, 43, 43, 0.20)) !default;
$rkt-slider-handle-border-style: solid !default;
$rkt-slider-handle-border-color: $rkt-white !default;
$rkt-slider-handle-background-color: $rkt-gray-200 !default;
$rkt-slider-handle-border-width: 7px !default;
$rkt-slider-handle-height: 20px !default;
$rkt-slider-handle-width: 20px !default;
$rkt-slider-handle-disabled-border: 2px solid $rkt-gray-300 !default;
$rkt-slider-handle-disabled-inner-color: $rkt-white !default;
$rkt-slider-handle-disabled-box-shadow: none !default;
$rkt-slider-handle-label-font-size: 0.75rem !default;
$rkt-slider-handle-label-font-weight: $rkt-typography-font-weight-500 !default;
$rkt-slider-handle-label-line-height: 150% !default;
$rkt-slider-handle-label-color: $rkt-white !default;
$rkt-slider-handle-active-thumb-box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.08) !default;
$rkt-slider-handle-hover-thumb-box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.04) !default;
$rkt-slider-handle-left: -29px !default;
$rkt-slider-thumb-label-top: 10px !default;
$rkt-slider-thumb-ripple-display: none !default;
$rkt-slider-track-color: $rkt-gray-100 !default;
$rkt-slider-track-border-bottom-right-radius: 0 !default;
$rkt-slider-track-border-top-right-radius: 0 !default;
$rkt-slider-track-opacity: 1 !default;
$rkt-slider-track-height: 16px !default;
$rkt-slider-small-track-height: 6px !default;
$rkt-slider-track-active-top: 0 !default;
$rkt-slider-track-background-color: $rkt-gray-800 !default;
$rkt-slider-track-border-radius: 100px 0 0 100px !default;
$rkt-slider-track-border-color: $rkt-gray-800 !default;
$rkt-slider-track-inactive-background-color: $rkt-gray-100 !default;
$rkt-slider-track-inactive-opacity: 1 !default;
$rkt-slider-track-border: 0 !default;
$rkt-slider-disabled-opacity: 1 !default;
$rkt-slider-disabled-pointer-events: none !default;
$rkt-slider-mark-opacity: 1 !default;
$rkt-slider-mark-active-margin-left: 3px !default;
$rkt-slider-mark-active-background-color: rgba(255, 255, 255, 0.40) !default;
$rkt-slider-mark-small-active-background-color: rgba(255, 255, 255, 0.60) !default;
$rkt-slider-mark-background-color: rgba(0, 0, 0, 0.15) !default;
$rkt-slider-mark-small-background-color: $rkt-gray-300 !default;
$rkt-slider-mark-margin-left: -8px !default;
$rkt-slider-mark-left: -3px !default;
$rkt-slider-mark-width: 4px !default;
$rkt-slider-mark-height: 4px !default;
$rkt-slider-mark-border-radius: 50% !default;
$rkt-snackbar-titled-message-margin: 0 !default;
$rkt-snackbar-title-font-family: $rkt-typography-font-family-body-16 !default;
$rkt-snackbar-title-font-size: $rkt-typography-font-size-body-16 !default;
$rkt-snackbar-title-font-weight: $rkt-typography-font-weight-body-16 !default;
$rkt-snackbar-title-line-height: $rkt-typography-line-height-body-16 !default;
$rkt-snackbar-title-color: $rkt-gray-400 !default;
$rkt-snackbar-title-margin: 0 !default;
$rkt-snackbar-background-color: #1C1B1A !default;
$rkt-snackbar-border: 1px solid $rkt-gray-dark-700 !default;
$rkt-snackbar-border-radius: 12px !default;
$rkt-snackbar-width: 100% !default;
$rkt-snackbar-container-width: 100vw !default;
$rkt-snackbar-padding: 16px 24px !default;
$rkt-snackbar-align-items: center !default;
$rkt-snackbar-content-background-color: #1C1B1A !default;
$rkt-snackbar-content-box-shadow: none !default;
$rkt-snackbar-content-padding: 0 !default;
$rkt-snackbar-content-border-radius: 0 !default;
$rkt-snackbar-content-flex-wrap: nowrap !default;
$rkt-snackbar-content-width: 100% !default;
$rkt-snackbar-content-min-width: unset !default;
$rkt-snackbar-content-action-padding: 0 !default;
$rkt-snackbar-content-action-margin: 0 !default;
$rkt-snackbar-left: 8px !default;
$rkt-snackbar-left-breakpoint-custom: auto !default;
$rkt-snackbar-bottom: 8px !default;
$rkt-snackbar-right: 8px !default;
$rkt-snackbar-label-color: $rkt-white !default;
$rkt-snackbar-label-font-size: $rkt-typography-font-size-body-16 !default;
$rkt-snackbar-label-font-style: normal !default;
$rkt-snackbar-label-font-weight: $rkt-typography-font-weight-body-16 !default;
$rkt-snackbar-label-font-family: $rkt-typography-font-family-body-16 !default;
$rkt-snackbar-label-line-height: $rkt-typography-line-height-body-16 !default;
$rkt-snackbar-label-padding: 0 !default;
$rkt-snackbar-label-margin-right: 16px !default;
$rkt-snackbar-label-flex-grow: 1 !default;
$rkt-snackbar-button-border-radius: 1000px !default;
$rkt-snackbar-button-color: $rkt-gray-800 !default;
$rkt-snackbar-button-background-color: $rkt-white !default;
$rkt-snackbar-button-font-family: 'Wintle Text', Helvetica, 'Helvetica Neue', Arial, sans-serif !default;
$rkt-snackbar-button-font-size: 16px !default;
$rkt-snackbar-button-font-weight: $rkt-typography-font-weight-500 !default;
$rkt-snackbar-button-line-height: 24px !default;
$rkt-snackbar-button-padding: 18.5px 20px !default;
$rkt-snackbar-button-height: 48px !default;
$rkt-snackbar-button-hover-color: rgba(255, 255, 255, 0.88) !default;
$rkt-snackbar-button-hover-background-color: $rkt-white-opacity-400 !default;
$rkt-snackbar-button-focus-visible-color: $rkt-gray-800 !default;
$rkt-snackbar-button-focus-visible-box-shadow: 0 0 0 3px $rkt-white-opacity-600 !default;
$rkt-snackbar-button-focus-visible-background-color: $rkt-white !default;
$rkt-snackbar-button-active-color: $rkt-gray-800 !default;
$rkt-snackbar-button-active-background-color: $rkt-white-opacity-600 !default;
$rkt-snackbar-button-ripple-display: none !default;
$rkt-snackbar-button-ripple-color: transparent !default;
$rkt-snackbar-surface-padding-right: 0 !default;
$rkt-snackbar-surface-border: 0 !default;
$rkt-snackbar-surface-box-shadow: none !default;
$rkt-snackbar-surface-border-radius: 0 !default;
$rkt-snackbar-surface-background-color: #1C1B1A !default;
$rkt-snackbar-surface-min-width: unset !default;
$rkt-snackbar-surface-width: 100% !default;
$rkt-snackbar-surface-max-width: unset !default;
$rkt-snackbar-tall-padding: 16px 24px 24px 24px !default;
$rkt-snackbar-tall-display: grid !default;
$rkt-snackbar-tall-justify-items: start !default;
$rkt-snackbar-tall-label-margin: 0 0 16px 0 !default;
$rkt-snackbar-tall-label-grid-row-start: 1 !default;
$rkt-snackbar-tall-label-grid-row-end: 2 !default;
$rkt-snackbar-tall-action-grid-row-start: 2 !default;
$rkt-snackbar-tall-action-grid-row-end: 2 !default;
$rkt-snackbar-breakpoint-custom: 496px !default;
$rkt-snackbar-overlay-flex: unset !default;
$rkt-snackbar-angular-max-width: 480px !default;
$rkt-snackbar-react-max-width: 430px !default;
$rkt-snackbar-react-width: unset !default;
$rkt-snackbar-tablet-react-width: 100% !default;
$rkt-spinner-stroke: $rkt-red-500 !default;
$rkt-spinner-stroke-linecap: round !default;
$rkt-spinner-stroke-linejoin: round !default;
$rkt-stepper-step-z-index: 1 !default;
$rkt-stepper-step-height: 40px !default;
$rkt-stepper-step-width: fit-content !default;
$rkt-stepper-step-border-radius: 48px !default;
$rkt-stepper-step-padding: 0 12px !default;
$rkt-stepper-step-header-icon-text-font-size: $rkt-typography-font-size-label-14 !default;
$rkt-stepper-step-header-icon-text-font-style: normal !default;
$rkt-stepper-step-header-icon-text-font-weight: $rkt-typography-font-weight-500 !default;
$rkt-stepper-step-header-icon-margin-right: 16px !default;
$rkt-stepper-step-header-icon-container-padding-right: 16px !default;
$rkt-stepper-step-label-font-family: $rkt-typography-font-family-label-14 !default;
$rkt-stepper-step-label-font-size: $rkt-typography-font-size-label-16 !default;
$rkt-stepper-step-label-font-style: normal !default;
$rkt-stepper-step-label-font-weight: $rkt-typography-font-weight-500 !default;
$rkt-stepper-step-label-color: $rkt-gray-600 !default;
$rkt-stepper-step-label-active-color: $rkt-gray-800 !default;
$rkt-stepper-step-label-line-height: 24px !default;
$rkt-stepper-step-label-icon-fill: $rkt-gray-200 !default;
$rkt-stepper-step-label-icon-text-fill: $rkt-gray-400 !default;
$rkt-stepper-step-label-icon-completed-color: $rkt-gray-800 !default;
$rkt-stepper-step-icon-default-background-color: $rkt-gray-200 !default;
$rkt-stepper-step-icon-content-color: $rkt-gray-400 !default;
$rkt-stepper-step-icon-content-font-size: $rkt-typography-font-size-label-14 !default;
$rkt-stepper-step-icon-content-font-style: normal !default;
$rkt-stepper-step-icon-content-font-weight: $rkt-typography-font-weight-500 !default;
$rkt-stepper-step-icon-content-line-height: 20px !default;
$rkt-stepper-step-line-width: 2px !default;
$rkt-stepper-step-line-color: $rkt-gray-200 !default;
$rkt-stepper-step-hover-background-color: $rkt-gray-50 !default;
$rkt-stepper-step-focus-box-shadow: inset 0 0 0 3px $rkt-gray-800 !default;
$rkt-stepper-step-ripple-display: none !default;
$rkt-stepper-step-vertical-padding: 6px 0 !default;
$rkt-stepper-step-horizontal-padding: 0 20px !default;
$rkt-stepper-step-connector-vertical-margin-left: 15px !default;
$rkt-stepper-horizontal-line-margin: 0 8px !default;
$rkt-stepper-horizontal-step-height: 60px !default;
$rkt-stepper-vertical-stepper-header-margin-bottom: 20px !default;
$rkt-stepper-vertical-content-container-margin-left: 23px !default;
$rkt-stepper-vertical-line-top: -22px !default;
$rkt-stepper-vertical-line-height: 24px !default;
$rkt-stepper-progress-display: flex !default;
$rkt-stepper-progress-flex-direction: column !default;
$rkt-stepper-progress-heading-font-family: $rkt-typography-font-family-label-14 !default;
$rkt-stepper-progress-heading-font-size: $rkt-typography-font-size-label-14 !default;
$rkt-stepper-progress-heading-font-style: normal !default;
$rkt-stepper-progress-heading-font-weight: $rkt-typography-font-weight-400 !default;
$rkt-stepper-progress-heading-line-height: $rkt-typography-line-height-label-14 !default;
$rkt-stepper-progress-link-font-family: $rkt-typography-font-family-label-14 !default;
$rkt-stepper-progress-link-font-size: $rkt-typography-font-size-label-14 !default;
$rkt-stepper-progress-link-font-style: normal !default;
$rkt-stepper-progress-link-font-weight: $rkt-typography-font-weight-500 !default;
$rkt-stepper-progress-link-line-height: $rkt-typography-line-height-label-14 !default;
$rkt-table-row-expandable-border-bottom-width: 0 !default;
$rkt-table-row-expandable-table-cell-padding: 5px 12px !default;
$rkt-table-cell-collapsible-padding: 0 !default;
$rkt-table-collapsible-content-padding: 12px 0 12px 0 !default;
$rkt-table-cell-border-color: $rkt-gray-200 !default;
$rkt-table-collapsible-content-border-bottom: 1px solid $rkt-gray-100 !default;
$rkt-table-row-expandable-hover-background-color: $rkt-gray-50 !default;
$rkt-table-row-expandable-hover-cursor: pointer !default;
$rkt-table-row-height: 48px !default;
$rkt-table-row-expandable-padding: 0 !default;
$rkt-table-row-expandable-height: 48px !default;
$rkt-table-row-expandable-box-sizing: border-box !default;
$rkt-table-row-expandable-hover-first-child-border-top-left-radius: 8px !default;
$rkt-table-row-expandable-hover-first-child-border-bottom-left-radius: 8px !default;
$rkt-table-row-expandable-hover-first-child-background-color: $rkt-gray-50 !default;
$rkt-table-row-expandable-hover-last-child-border-top-right-radius: 8px !default;
$rkt-table-row-expandable-hover-last-child-border-bottom-right-radius: 8px !default;
$rkt-table-row-expandable-hover-last-child-background-color: $rkt-gray-50 !default;
$rkt-table-row-focus-visible-outline: 3px solid $rkt-gray-800 !default;
$rkt-table-row-focus-visible-border-radius: 8px !default;
$rkt-table-row-focus-visible-background: $rkt-gray-50 !default;
$rkt-table-row-focus-visible-expand-icon-visibility: visible !default;
$rkt-table-row-hover-expand-icon-visibility: visible !default;
$rkt-table-row-expand-icon-visibility: hidden !default;
$rkt-table-cell-color: $rkt-typography-color-body-14 !default;
$rkt-table-cell-font-weight: $rkt-typography-font-weight-body-14 !default;
$rkt-table-cell-font-family: $rkt-typography-font-family-body-14 !default;
$rkt-table-cell-footer-font-weight: $rkt-typography-font-weight-500 !default;
$rkt-table-cell-footer-border-top: 1px solid $rkt-gray-100 !default;
$rkt-table-cell-footer-border-bottom: 1px solid $rkt-gray-100 !default;
$rkt-table-cell-font-size: $rkt-typography-font-size-body-14 !default;
$rkt-table-cell-line-height: $rkt-typography-line-height-body-14 !default;
$rkt-table-cell-last-display: flex !default;
$rkt-table-cell-last-justify-content: space-between !default;
$rkt-table-cell-last-align-items: center !default;
$rkt-table-cell-last-height: 48px !default;
$rkt-table-cell-select-border-bottom: none !default;
$rkt-table-cell-select-width: 48px !default;
$rkt-table-cell-border-bottom: 1px solid $rkt-gray-100 !default;
$rkt-table-cell-first-child-padding-left: 16px !default;
$rkt-table-cell-box-sizing: border-box !default;
$rkt-table-width: 100% !default;
$rkt-table-cell-padding: 0 16px 0 0 !default;
$rkt-table-cell-padding-checkbox: 0 !default;
$rkt-table-border-top: 1px solid $rkt-gray-200 !default;
$rkt-table-border-bottom: 1px solid $rkt-gray-200 !default;
$rkt-table-header-color: $rkt-gray-800 !default;
$rkt-table-header-font-weight: $rkt-typography-font-weight-500 !default;
$rkt-table-header-font-size: $rkt-typography-font-size-caption-12 !default;
$rkt-table-header-line-height: $rkt-typography-line-height-caption-12 !default;
$rkt-table-header-background-color: $rkt-gray-50 !default;
$rkt-table-header-height: 48px !default;
$rkt-table-header-border-bottom: none !default;
$rkt-table-header-padding: 0 16px 0 0 !default;
$rkt-table-header-row-height: 48px !default;
$rkt-table-header-row-first-child-border-top-left-radius: 8px !default;
$rkt-table-header-row-first-child-border-bottom-left-radius: 8px !default;
$rkt-table-header-row-first-child-background-color: $rkt-gray-50 !default;
$rkt-table-header-row-last-child-border-top-right-radius: 8px !default;
$rkt-table-header-row-last-child-border-bottom-right-radius: 8px !default;
$rkt-table-header-row-focus-visible-sort-arrow-outline: unset !default;
$rkt-table-header-row-focus-visible-sort-arrow-visibility: hidden !default;
$rkt-table-header-tooltip-display: flex !default;
$rkt-table-header-tooltip-align-items: center !default;
$rkt-table-header-tooltip-height: 24px !default;
$rkt-table-header-tooltip-width: 24px !default;
$rkt-table-header-tooltip-popper-focus-visible-icon-outline: 3px solid $rkt-gray-800 !default;
$rkt-table-header-tooltip-popper-focus-visible-icon-outline-offset: 1px !default;
$rkt-table-header-tooltip-popper-focus-visible-icon-border-radius: 100% !default;
$rkt-table-header-tooltip-popper-focus-visible-background: unset !default;
$rkt-table-header-tooltip-popper-focus-visible-outline: none !default;
$rkt-table-header-tooltip-popper-hover-background: unset !default;
$rkt-table-header-sort-arrow-margin: 0 8px 0 8px !default;
$rkt-table-header-sort-label-icon-margin-left: 5px !default;
$rkt-table-header-sort-label-icon-margin-right: 5px !default;
$rkt-table-header-focus-visible-border-bottom: none !default;
$rkt-table-header-focus-visible-sort-arrow-outline: 3px solid $rkt-gray-800 !default;
$rkt-table-header-focus-visible-sort-arrow-border-radius: 100% !default;
$rkt-table-header-focus-visible-sort-arrow-outline-offset: 3px !default;
$rkt-table-header-focus-visible-sort-label-icon-opacity: unset !default;
$rkt-table-row-background-color-hover: $rkt-white !default;
$rkt-table-row-background-color-hover-gray: $rkt-gray-50 !default;
$rkt-table-border-collapse: separate !default;
$rkt-table-container-max-width: 1141px !default;
$rkt-table-container-padding: 24px !default;
$rkt-table-container-display: inline-block !default;
$rkt-table-container-box-sizing: border-box !default;
$rkt-table-container-width: 100% !default;
$rkt-table-container-overflow: auto !default;
$rkt-table-container-border: 1px solid $rkt-gray-300 !default;
$rkt-table-container-border-radius: 24px !default;
$rkt-table-title-padding: 8px 16px 12px 16px !default;
$rkt-table-title-margin-bottom: 8px !default;
$rkt-table-title-font-weight: $rkt-typography-font-weight-500 !default;
$rkt-table-title-font-size: $rkt-typography-font-size-heading-18 !default;
$rkt-table-title-line-height: $rkt-typography-line-height-heading-18 !default;
$rkt-table-pagination-display: flex !default;
$rkt-table-pagination-justify-content: flex-end !default;
$rkt-table-pagination-margin-top: 16px !default;
$rkt-table-pagination-height: 40px !default;
$rkt-table-checkbox-margin-left: 8px !default;
$rkt-table-checkbox-height: 32px !default;
$rkt-table-checkbox-width: 32px !default;
$rkt-table-checkbox-margin: 0 4px 0 4px !default;
$rkt-table-checkbox-container-width: 48px !default;
$rkt-table-collapsible-content-with-select-margin-left: 48px !default;
$rkt-tabs-label-color: $rkt-gray-800 !default;
$rkt-tabs-icon-color: $rkt-gray-800 !default;
$rkt-tabs-icon-color-active: $rkt-gray-800 !default;
$rkt-tabs-icon-margin-right: 8px !default;
$rkt-tabs-font-weight: $rkt-typography-font-weight-500 !default;
$rkt-tabs-font-size: $rkt-typography-font-size-label-16 !default;
$rkt-tabs-font-weight-active: $rkt-typography-font-weight-500 !default;
$rkt-tabs-font-size-active: $rkt-typography-font-size-label-16 !default;
$rkt-tabs-label-color-active: $rkt-gray-800 !default;
$rkt-tabs-border-bottom: 1px solid $rkt-gray-200 !default;
$rkt-tabs-min-height: 40px !default;
$rkt-tabs-wide-min-width: 144px !default;
$rkt-tabs-narrow-min-width: 112px !default;
$rkt-tabs-narrow-two-tabs-min-width: 50% !default;
$rkt-tabs-narrow-many-tabs-without-icon-min-width: 80px !default;
$rkt-tabs-narrow-breakpoint: 1080px !default;
$rkt-tabs-button-height: 40px !default;
$rkt-tabs-button-horizontal-padding: 16px !default;
$rkt-tabs-margin-top: 6px !default;
$rkt-tabs-margin-bottom: 6px !default;
$rkt-tabs-flex: 0 0 auto !default;
$rkt-tabs-focus-outline: 3px solid $rkt-gray-800 !default;
$rkt-tabs-focus-outline-border-radius: 8px !default;
$rkt-tabs-focus-outline-offset: -3px !default;
$rkt-tabs-hover-background-color: $rkt-gray-100 !default;
$rkt-tabs-hover-border-radius: 8px !default;
$rkt-tabs-underline-background-color: $rkt-gray-800 !default;
$rkt-tabs-underline-height: 4px !default;
$rkt-tabs-underline-border-radius: 2px !default;
$rkt-tabs-underline-border-top-width: 0px !default;
$rkt-tabs-underline-position: absolute !default;
$rkt-tabs-react-underline-top: 50px !default;
$rkt-tabs-angular-underline-top: 44px !default;
$rkt-tabs-underline-transition: 500ms cubic-bezier(0.35, 0, 0.25, 1) !default;
$rkt-tabs-scroll-button-width: 24px !default;
$rkt-tabs-scroll-button-opacity: initial !default;
$rkt-tabs-scroll-button-pointer-events: all !default;
$rkt-tabs-scroll-button-cursor: pointer !default;
$rkt-tabs-scroll-button-padding: 0px !default;
$rkt-tabs-angular-pagination-background-image-width: 24px !default;
$rkt-tabs-angular-pagination-background-image-height: 24px !default;
$rkt-tabs-angular-pagination-before-background-image: url("data:image/svg+xml,<svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M14 8.5L10 12.5L14 16.5' stroke='currentColor' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/></svg>") !default;
$rkt-tabs-angular-pagination-after-background-image: url("data:image/svg+xml,<svg viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'><path d='M11 16.5L15 12.5L11 8.5' stroke='currentColor' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/></svg>") !default;
$rkt-tabs-angular-pagination-border: unset !default;
$rkt-tabs-angular-pagination-before-transform: unset !default;
$rkt-tabs-angular-pagination-after-transform: unset !default;
$rkt-tabs-angular-pagination-disabled-opacity: unset !default;
$rkt-tabs-ripple-display: none !default;
$rkt-tabs-on-dark-label-color: $rkt-gray-200 !default;
$rkt-tabs-on-dark-label-color-active: $rkt-white !default;
$rkt-tabs-on-dark-underline: $rkt-white !default;
$rkt-tabs-on-dark-icon-color: $rkt-gray-200 !default;
$rkt-tabs-on-dark-icon-active-color: $rkt-white !default;
$rkt-tabs-on-dark-disabled-icon-color: $rkt-gray-200 !default;
$rkt-tabs-on-dark-ripple: $rkt-white !default;
$rkt-toggle-large-summary-line-height: 32px !default;
$rkt-toggle-large-summary-font-size: $rkt-typography-font-size-heading-24 !default;
$rkt-toggle-large-summary-font-style: normal !default;
$rkt-toggle-large-content-text-font-size: $rkt-typography-font-size-body-16 !default;
$rkt-toggle-large-trigger-icon-container-hover-width: 28px !default;
$rkt-toggle-large-trigger-icon-container-hover-height: 28px !default;
$rkt-toggle-small-trigger-padding-right: 4px !default;
$rkt-toggle-small-trigger-padding-left: 0 !default;
$rkt-toggle-small-trigger-icon-right-padding-right: 0 !default;
$rkt-toggle-small-trigger-icon-right-padding-left: 4px !default;
$rkt-toggle-small-trigger-gap: 8px !default;
$rkt-toggle-small-trigger-icon-container-after-display: none !default;
$rkt-toggle-small-trigger-icon-container-width: auto !default;
$rkt-toggle-small-trigger-icon-container-height: auto !default;
$rkt-toggle-small-trigger-icon-container-background-color: unset !default;
$rkt-toggle-small-trigger-icon-fill: currentColor !default;
$rkt-toggle-small-content-padding-top: 6px !default;
$rkt-toggle-small-content-padding-bottom: 6px !default;
$rkt-toggle-small-content-text-font-size: 0.75rem !default;
$rkt-toggle-trigger-focus-outline: 3px solid $rkt-gray-800 !default;
$rkt-toggle-trigger-focus-outline-offset: 5px !default;
$rkt-toggle-trigger-focus-border-radius: 1000px !default;
$rkt-toggle-trigger-align-items: center !default;
$rkt-toggle-trigger-flex-direction: row !default;
$rkt-toggle-trigger-display: flex !default;
$rkt-toggle-trigger-box-sizing: border-box !default;
$rkt-toggle-trigger-icon-container-hover-width: 26px !default;
$rkt-toggle-trigger-icon-container-hover-height: 26px !default;
$rkt-toggle-trigger-icon-container-flex: 0 0 auto !default;
$rkt-toggle-trigger-icon-container-align-items: center !default;
$rkt-toggle-trigger-icon-container-justify-content: center !default;
$rkt-toggle-trigger-icon-container-display: flex !default;
$rkt-toggle-trigger-icon-container-border-radius: 50% !default;
$rkt-toggle-trigger-icon-container-background-color: $rkt-gray-100 !default;
$rkt-toggle-trigger-icon-container-width: 32px !default;
$rkt-toggle-trigger-icon-container-height: 32px !default;
$rkt-toggle-trigger-icon-width: 18px !default;
$rkt-toggle-trigger-icon-height: unset !default;
$rkt-toggle-trigger-icon-fill: $rkt-gray-800 !default;
$rkt-toggle-trigger-icon-box-sizing: content-box !default;
$rkt-toggle-trigger-border-top: none !default;
$rkt-toggle-trigger-border-right: none !default;
$rkt-toggle-trigger-border-bottom: 0 !default;
$rkt-toggle-trigger-border-left: none !default;
$rkt-toggle-trigger-transition: 0.3s !default;
$rkt-toggle-trigger-on-dark-color: $rkt-white !default;
$rkt-toggle-trigger-on-dark-hover-color: $rkt-white !default;
$rkt-toggle-trigger-on-dark-focus-color: $rkt-white !default;
$rkt-toggle-trigger-hover-background-color: transparent !default;
$rkt-toggle-trigger-hover-color: $rkt-red-500 !default;
$rkt-toggle-trigger-background-color: transparent !default;
$rkt-toggle-trigger-padding-top: 0 !default;
$rkt-toggle-trigger-padding-right: 8px !default;
$rkt-toggle-trigger-padding-bottom: 0 !default;
$rkt-toggle-trigger-padding-left: 0 !default;
$rkt-toggle-trigger-icon-right-padding-right: 0 !default;
$rkt-toggle-trigger-icon-right-padding-left: 8px !default;
$rkt-toggle-trigger-text-align: left !default;
$rkt-toggle-trigger-color: $rkt-gray-800 !default;
$rkt-toggle-trigger-gap: $rkt-spacing-16 !default;
$rkt-toggle-content-padding-top: $rkt-spacing-12 !default;
$rkt-toggle-content-padding-bottom: $rkt-spacing-12 !default;
$rkt-toggle-content-text-font-family: $rkt-typography-font-family-rocket !default;
$rkt-toggle-content-text-font-size: $rkt-typography-font-size-body-14 !default;
$rkt-toggle-content-text-font-weight: $rkt-typography-font-weight-body-14 !default;
$rkt-toggle-content-text-line-height: 150% !default;
$rkt-toggle-content-text-color: $rkt-gray-600 !default;
$rkt-toggle-content-text-margin: 0 !default;
$rkt-toggle-content-text-on-dark-color: $rkt-gray-200 !default;
$rkt-toggle-transition-timing: 0.3s ease !default;
$rkt-toggle-summary-font-family: $rkt-typography-font-family-rocket !default;
$rkt-toggle-summary-font-size: $rkt-typography-font-size-body-16 !default;
$rkt-toggle-summary-font-weight: $rkt-typography-font-weight-500 !default;
$rkt-toggle-summary-line-height: 150% !default;