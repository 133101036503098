// ==================================================================
// Typography
// ==================================================================
@use '../web/scss/typography' as *;
@use '../web/scss/object' as *;
@use '../web/scss/vars' as *;

::selection {
  background-color: $rkt-selection-bg-color;
}

.rkt-Heading-56,
%rkt-Heading-56 {
  color: $rkt-typography-color-heading-56;
  font-family: $rkt-typography-font-family-heading-56;
  font-size: $rkt-typography-font-size-heading-56;
  font-weight: $rkt-typography-font-weight-heading-56;
  line-height: $rkt-typography-line-height-heading-56;
  letter-spacing: $rkt-typography-letter-spacing-heading-56;
}

.rkt-Heading-48,
%rkt-Heading-48 {
  color: $rkt-typography-color-heading-48;
  font-family: $rkt-typography-font-family-heading-48;
  font-size: $rkt-typography-font-size-heading-48;
  font-weight: $rkt-typography-font-weight-heading-48;
  line-height: $rkt-typography-line-height-heading-48;
  letter-spacing: $rkt-typography-letter-spacing-heading-48;
}

.rkt-Heading-40,
%rkt-Heading-40 {
  color: $rkt-typography-color-heading-40;
  font-family: $rkt-typography-font-family-heading-40;
  font-size: $rkt-typography-font-size-heading-40;
  font-weight: $rkt-typography-font-weight-heading-40;
  line-height: $rkt-typography-line-height-heading-40;
  letter-spacing: $rkt-typography-letter-spacing-heading-40;
}

.rkt-Heading-36,
%rkt-Heading-36 {
  color: $rkt-typography-color-heading-36;
  font-family: $rkt-typography-font-family-heading-36;
  font-size: $rkt-typography-font-size-heading-36;
  font-weight: $rkt-typography-font-weight-heading-36;
  line-height: $rkt-typography-line-height-heading-36;
  letter-spacing: $rkt-typography-letter-spacing-heading-36;
}

.rkt-Heading-32,
%rkt-Heading-32 {
  color: $rkt-typography-color-heading-32;
  font-family: $rkt-typography-font-family-heading-32;
  font-size: $rkt-typography-font-size-heading-32;
  font-weight: $rkt-typography-font-weight-heading-32;
  line-height: $rkt-typography-line-height-heading-32;
}

.rkt-Heading-28,
%rkt-Heading-28 {
  color: $rkt-typography-color-heading-28;
  font-family: $rkt-typography-font-family-heading-28;
  font-size: $rkt-typography-font-size-heading-28;
  font-weight: $rkt-typography-font-weight-heading-28;
  line-height: $rkt-typography-line-height-heading-28;
}

.rkt-Heading-24,
%rkt-Heading-24 {
  color: $rkt-typography-color-heading-24;
  font-family: $rkt-typography-font-family-heading-24;
  font-size: $rkt-typography-font-size-heading-24;
  font-weight: $rkt-typography-font-weight-heading-24;
  line-height: $rkt-typography-line-height-heading-24;
}

.rkt-Heading-20,
%rkt-Heading-20 {
  color: $rkt-typography-color-heading-20;
  font-family: $rkt-typography-font-family-heading-20;
  font-size: $rkt-typography-font-size-heading-20;
  font-weight: $rkt-typography-font-weight-heading-20;
  line-height: $rkt-typography-line-height-heading-20;
}

.rkt-Heading-18,
%rkt-Heading-18 {
  color: $rkt-typography-color-heading-18;
  font-family: $rkt-typography-font-family-heading-18;
  font-size: $rkt-typography-font-size-heading-18;
  font-weight: $rkt-typography-font-weight-heading-18;
  line-height: $rkt-typography-line-height-heading-18;
}

.rkt-Label-20,
%rkt-Label-20 {
  color: $rkt-typography-color-label-20;
  font-family: $rkt-typography-font-family-label-20;
  font-size: $rkt-typography-font-size-label-20;
  font-weight: $rkt-typography-font-weight-label-20;
  line-height: $rkt-typography-line-height-label-20;
}

.rkt-Label-18,
%rkt-Label-18 {
  color: $rkt-typography-color-label-18;
  font-family: $rkt-typography-font-family-label-18;
  font-size: $rkt-typography-font-size-label-18;
  font-weight: $rkt-typography-font-weight-label-18;
  line-height: $rkt-typography-line-height-label-18;
}

.rkt-Label-16,
%rkt-Label-16 {
  color: $rkt-typography-color-label-16;
  font-family: $rkt-typography-font-family-label-16;
  font-size: $rkt-typography-font-size-label-16;
  font-weight: $rkt-typography-font-weight-label-16;
  line-height: $rkt-typography-line-height-label-16;
}

.rkt-Label-14,
%rkt-Label-14 {
  color: $rkt-typography-color-label-14;
  font-family: $rkt-typography-font-family-label-14;
  font-size: $rkt-typography-font-size-label-14;
  font-weight: $rkt-typography-font-weight-label-14;
  line-height: $rkt-typography-line-height-label-14;
}

.rkt-Body-18,
%rkt-Body-18 {
  color: $rkt-typography-color-body-18;
  font-family: $rkt-typography-font-family-body-18;
  font-size: $rkt-typography-font-size-body-18;
  font-weight: $rkt-typography-font-weight-body-18;
  line-height: $rkt-typography-line-height-body-18;
}

.rkt-Body-16,
%rkt-Body-16 {
  color: $rkt-typography-color-body-16;
  font-family: $rkt-typography-font-family-body-16;
  font-size: $rkt-typography-font-size-body-16;
  font-weight: $rkt-typography-font-weight-body-16;
  line-height: $rkt-typography-line-height-body-16;
}

.rkt-Body-14,
%rkt-Body-14 {
  color: $rkt-typography-color-body-14;
  font-family: $rkt-typography-font-family-body-14;
  font-size: $rkt-typography-font-size-body-14;
  font-weight: $rkt-typography-font-weight-body-14;
  line-height: $rkt-typography-line-height-body-14;
}

.rkt-Caption-12,
%rkt-Caption-12 {
  color: $rkt-typography-color-caption-12;
  font-family: $rkt-typography-font-family-caption-12;
  font-size: $rkt-typography-font-size-caption-12;
  font-weight: $rkt-typography-font-weight-caption-12;
  line-height: $rkt-typography-line-height-caption-12;
}

.rkt-Caption-10,
%rkt-Caption-10 {
  color: $rkt-typography-color-caption-10;
  font-family: $rkt-typography-font-family-caption-10;
  font-size: $rkt-typography-font-size-caption-10;
  font-weight: $rkt-typography-font-weight-caption-10;
  line-height: $rkt-typography-line-height-caption-10;
}

.rkt-Tracked-16,
%rkt-Tracked-16 {
  color: $rkt-typography-color-tracked-16;
  font-family: $rkt-typography-font-family-tracked-16;
  font-size: $rkt-typography-font-size-tracked-16;
  font-weight: $rkt-typography-font-weight-tracked-16;
  line-height: $rkt-typography-line-height-tracked-16;
  letter-spacing: $rkt-typography-letter-spacing-tracked-16;
  text-transform: $rkt-typography-text-transform-tracked-16;
}

.rkt-Tracked-12,
%rkt-Tracked-12 {
  color: $rkt-typography-color-tracked-12;
  font-family: $rkt-typography-font-family-tracked-12;
  font-size: $rkt-typography-font-size-tracked-12;
  font-weight: $rkt-typography-font-weight-tracked-12;
  line-height: $rkt-typography-line-height-tracked-12;
  letter-spacing: $rkt-typography-letter-spacing-tracked-12;
  text-transform: $rkt-typography-text-transform-tracked-12;
}

.rkt-Decorative-112,
%rkt-Decorative-112 {
  color: $rkt-typography-color-decorative-112;
  font-family: $rkt-typography-font-family-decorative-112;
  font-size: $rkt-typography-font-size-decorative-112;
  font-weight: $rkt-typography-font-weight-decorative-112;
  line-height: $rkt-typography-line-height-decorative-112;
  letter-spacing: $rkt-typography-letter-spacing-decorative-112;
}

.rkt-Decorative-80,
%rkt-Decorative-80 {
  color: $rkt-typography-color-decorative-80;
  font-family: $rkt-typography-font-family-decorative-80;
  font-size: $rkt-typography-font-size-decorative-80;
  font-weight: $rkt-typography-font-weight-decorative-80;
  line-height: $rkt-typography-line-height-decorative-80;
  letter-spacing: $rkt-typography-letter-spacing-decorative-80;
}

.rkt-Decorative-68,
%rkt-Decorative-68 {
  color: $rkt-typography-color-decorative-68;
  font-family: $rkt-typography-font-family-decorative-68;
  font-size: $rkt-typography-font-size-decorative-68;
  font-weight: $rkt-typography-font-weight-decorative-68;
  line-height: $rkt-typography-line-height-decorative-68;
  letter-spacing: $rkt-typography-letter-spacing-decorative-68;
}

.rkt-Heading-56,
.rkt-Heading-48,
.rkt-Heading-40,
.rkt-Heading-36,
.rkt-Heading-32,
.rkt-Heading-28,
.rkt-Heading-24,
.rkt-Heading-20,
.rkt-Heading-18,
.rkt-Body-18,
.rkt-Body-16,
.rkt-Body-14,
.rkt-Caption-10,
.rkt-Caption-12,
.rkt-Decorative-112,
.rkt-Decorative-68,
.rkt-Decorative-80,
.rkt-Label-14,
.rkt-Label-16,
.rkt-Label-18,
.rkt-Label-20,
.rkt-Tracked-12,
.rkt-Tracked-16 {
  margin: 0;
}

// Responsive Type
.rkt-Heading-56--responsive,
%rkt-Heading-56--responsive {
  @media (max-width: $rkt-typography-breakpoint-medium) {
    font-size: $rkt-typography-font-size-heading-36;
    line-height: $rkt-typography-line-height-heading-36;
  }
}

.rkt-Heading-48--responsive,
%rkt-Heading-48--responsive {
  @media (max-width: $rkt-typography-breakpoint-medium) {
    font-size: $rkt-typography-font-size-heading-36;
    line-height: $rkt-typography-line-height-heading-36;
  }
}

.rkt-Heading-40--responsive,
%rkt-Heading-40--responsive {
  @media (max-width: $rkt-typography-breakpoint-medium) {
    font-size: $rkt-typography-font-size-heading-36;
    line-height: $rkt-typography-line-height-heading-36;
  }
}

.rkt-Heading-36--responsive,
%rkt-Heading-36--responsive {
  @media (max-width: $rkt-typography-breakpoint-medium) {
    font-size: $rkt-typography-font-size-heading-32;
    line-height: $rkt-typography-line-height-heading-32;
  }
}

.rkt-Heading-32--responsive,
%rkt-Heading-32--responsive {
  @media (max-width: $rkt-typography-breakpoint-medium) {
    font-size: $rkt-typography-font-size-heading-28;
    line-height: $rkt-typography-line-height-heading-28;
  }
}

.rkt-Heading-28--responsive,
%rkt-Heading-28--responsive {
  @media (max-width: $rkt-typography-breakpoint-medium) {
    font-size: $rkt-typography-font-size-heading-24;
    line-height: $rkt-typography-line-height-heading-24;
  }
}

.rkt-Heading-24--responsive,
%rkt-Heading-24--responsive {
  @media (max-width: $rkt-typography-breakpoint-medium) {
    font-size: $rkt-typography-font-size-heading-20;
    line-height: $rkt-typography-line-height-heading-20;
  }
}

.rkt-Heading-20--responsive,
%rkt-Heading-20--responsive {
  @media (max-width: $rkt-typography-breakpoint-medium) {
    font-size: $rkt-typography-font-size-heading-18;
    line-height: $rkt-typography-line-height-heading-18;
  }
}

.rkt-Body-18--responsive,
%rkt-Body-18--responsive {
  @media (max-width: $rkt-typography-breakpoint-medium) {
    font-size: $rkt-typography-font-size-body-16;
    line-height: $rkt-typography-line-height-body-16;
  }
}

.rkt-Tracked-16--responsive,
%rkt-Tracked-16--responsive {
  @media (max-width: $rkt-typography-breakpoint-medium) {
    font-size: $rkt-typography-font-size-tracked-12;
    line-height: $rkt-typography-line-height-tracked-12;
  }
}

.rkt-Decorative-112--responsive,
%rkt-Decorative-112--responsive {
  @media (max-width: $rkt-typography-breakpoint-medium) {
    font-size: $rkt-typography-font-size-decorative-80;
    line-height: $rkt-typography-line-height-decorative-80;
  }
}

.rkt-Decorative-80--responsive,
%rkt-Decorative-80--responsive {
  @media (max-width: $rkt-typography-breakpoint-medium) {
    font-size: $rkt-typography-font-size-decorative-68;
    line-height: $rkt-typography-line-height-decorative-68;
  }
}

.rkt-Decorative-68--responsive,
%rkt-Decorative-68--responsive {
  @media (max-width: $rkt-typography-breakpoint-medium) {
    font-size: $rkt-typography-font-size-heading-56;
    line-height: $rkt-typography-line-height-heading-56;
  }
}

.rkt-Caption-12--responsive,
%rkt-Caption-12--responsive {
  @media (max-width: $rkt-typography-breakpoint-medium) {
    font-size: $rkt-typography-font-size-caption-10;
    line-height: $rkt-typography-line-height-caption-10;
  }
}
