@use '../web/scss/settings';

.rkt-Breadcrumbs {
  display: settings.$rkt-breadcrumbs-display;
  flex-wrap: settings.$rkt-breadcrumbs-flex-wrap;
  list-style: settings.$rkt-breadcrumbs-list-style;
  align-items: settings.$rkt-breadcrumbs-align-items;
  margin: settings.$rkt-breadcrumbs-margin;
  padding: settings.$rkt-breadcrumbs-padding;
  font-family: settings.$rkt-breadcrumbs-font-family;
  font-size: settings.$rkt-breadcrumbs-font-size;
  font-weight: settings.$rkt-breadcrumbs-font-weight;
  line-height: settings.$rkt-breadcrumbs-line-height;
}

.rkt-Breadcrumbs__crumb {
  color: settings.$rkt-breadcrumbs-crumb-color;
  text-decoration: settings.$rkt-breadcrumbs-crumb-text-decoration;

  &:hover {
    text-decoration: settings.$rkt-breadcrumbs-crumb-text-decoration-hover;
  }
}

.rkt-Breadcrumbs__separator {
  color: settings.$rkt-breadcrumbs-crumb-separator-color;
  margin: settings.$rkt-breadcrumbs-crumb-separator-margin;
}

.rkt-Breadcrumbs__crumb--is-selected,
.rkt-Label-16.rkt-Breadcrumbs__crumb--is-selected {
  color: settings.$rkt-breadcrumbs-crumb-selected-color;

  &:hover {
    text-decoration: settings.$rkt-breadcrumbs-crumb-selected-text-decoration-hover;
  }
}

