@use './web/scss/color';
@use './web/scss/typography';
@use '@angular/material' as mat;
@use 'sass:map';

$rkt-angular-legacy: true !default;

// Color Palettes
$rkt-white: (
  50: color.$rkt-white,
  100: color.$rkt-white,
  200: color.$rkt-white,
  300: color.$rkt-white,
  400: color.$rkt-white,
  500: color.$rkt-white,
  600: color.$rkt-white,
  700: color.$rkt-white,
  800: color.$rkt-white,
  900: color.$rkt-white,
  contrast: (
    50: color.$rkt-white,
    100: color.$rkt-white,
    200: color.$rkt-white,
    300: color.$rkt-white,
    400: color.$rkt-white,
    500: color.$rkt-white,
    600: color.$rkt-white,
    700: color.$rkt-white,
    800: color.$rkt-white,
    900: color.$rkt-white,
    A100: color.$rkt-white,
    A200: color.$rkt-white,
    A400: color.$rkt-white,
    A700: color.$rkt-white,
  ),
);

$rkt-red: (
  50: color.$rkt-red-50,
  100: color.$rkt-red-100,
  200: color.$rkt-red-200,
  300: color.$rkt-red-300,
  400: color.$rkt-red-400,
  500: color.$rkt-red-500,
  600: color.$rkt-red-600,
  700: color.$rkt-red-700,
  800: color.$rkt-red-800,
  900: color.$rkt-red-900,
  contrast: (
    50: color.$rkt-white,
    100: color.$rkt-white,
    200: color.$rkt-white,
    300: color.$rkt-white,
    400: color.$rkt-white,
    500: color.$rkt-white,
    600: color.$rkt-white,
    700: color.$rkt-white,
    800: color.$rkt-white,
    900: color.$rkt-white,
    A100: color.$rkt-white,
    A200: color.$rkt-white,
    A400: color.$rkt-white,
    A700: color.$rkt-white,
  ),
);

$rkt-yellow: (
  50: color.$rkt-yellow-50,
  100: color.$rkt-yellow-100,
  200: color.$rkt-yellow-200,
  300: color.$rkt-yellow-300,
  400: color.$rkt-yellow-400,
  500: color.$rkt-yellow-500,
  600: color.$rkt-yellow-600,
  700: color.$rkt-yellow-700,
  800: color.$rkt-yellow-800,
  900: color.$rkt-yellow-900,
  A100: color.$rkt-white,
  A200: color.$rkt-white,
  A400: color.$rkt-white,
  A700: color.$rkt-white,
  contrast: (
    50: color.$rkt-white,
    100: color.$rkt-white,
    200: color.$rkt-white,
    300: color.$rkt-white,
    400: color.$rkt-white,
    500: color.$rkt-white,
    600: color.$rkt-white,
    700: color.$rkt-white,
    800: color.$rkt-white,
    900: color.$rkt-white,
    A100: color.$rkt-white,
    A200: color.$rkt-white,
    A400: color.$rkt-white,
    A700: color.$rkt-white,
  ),
);

$rkt-green: (
  50: color.$rkt-green-50,
  100: color.$rkt-green-100,
  200: color.$rkt-green-200,
  300: color.$rkt-green-300,
  400: color.$rkt-green-400,
  500: color.$rkt-green-500,
  600: color.$rkt-green-600,
  700: color.$rkt-green-700,
  800: color.$rkt-green-800,
  900: color.$rkt-green-900,
  A100: color.$rkt-white,
  A200: color.$rkt-white,
  A400: color.$rkt-white,
  A700: color.$rkt-white,
  contrast: (
    50: color.$rkt-white,
    100: color.$rkt-white,
    200: color.$rkt-white,
    300: color.$rkt-white,
    400: color.$rkt-white,
    500: color.$rkt-white,
    600: color.$rkt-white,
    700: color.$rkt-white,
    800: color.$rkt-white,
    900: color.$rkt-white,
    A100: color.$rkt-white,
    A200: color.$rkt-white,
    A400: color.$rkt-white,
    A700: color.$rkt-white,
  ),
);

$rkt-blue: (
  50: color.$rkt-blue-50,
  100: color.$rkt-blue-100,
  200: color.$rkt-blue-200,
  300: color.$rkt-blue-300,
  400: color.$rkt-blue-400,
  500: color.$rkt-blue-500,
  600: color.$rkt-blue-600,
  700: color.$rkt-blue-700,
  800: color.$rkt-blue-800,
  900: color.$rkt-blue-900,
  A100: color.$rkt-white,
  A200: color.$rkt-white,
  A400: color.$rkt-white,
  A700: color.$rkt-white,
  contrast: (
    50: color.$rkt-white,
    100: color.$rkt-white,
    200: color.$rkt-white,
    300: color.$rkt-white,
    400: color.$rkt-white,
    500: color.$rkt-white,
    600: color.$rkt-white,
    700: color.$rkt-white,
    800: color.$rkt-white,
    900: color.$rkt-white,
    A100: color.$rkt-white,
    A200: color.$rkt-white,
    A400: color.$rkt-white,
    A700: color.$rkt-white,
  ),
);

$rkt-gray: (
  50: color.$rkt-gray-50,
  100: color.$rkt-gray-100,
  200: color.$rkt-gray-200,
  300: color.$rkt-gray-300,
  400: color.$rkt-gray-400,
  500: color.$rkt-gray-500,
  600: color.$rkt-gray-600,
  700: color.$rkt-gray-700,
  800: color.$rkt-gray-800,
  900: color.$rkt-gray-900,
  contrast: (
    50: color.$rkt-white,
    100: color.$rkt-white,
    200: color.$rkt-white,
    300: color.$rkt-white,
    400: color.$rkt-white,
    500: color.$rkt-white,
    600: color.$rkt-white,
    700: color.$rkt-white,
    800: color.$rkt-white,
    900: color.$rkt-white,
    A100: color.$rkt-white,
    A200: color.$rkt-white,
    A400: color.$rkt-white,
    A700: color.$rkt-white,
  ),
);

// Load Typography fonts
$rkt-webfonts: (
  (
      url('https://www.rocketmortgage.com/nsassets/fonts/Wintle_Text_Web_Bold.woff2')
        format('woff2'),
      url('https://www.rocketmortgage.com/nsassets/fonts/Wintle_Text_Web_Bold.woff')
        format('woff')
    ),
    "Wintle Text",
    900
  ),
  (
    (
      url('https://www.rocketmortgage.com/nsassets/fonts/Wintle_Text_Web_DemiBold.woff2')
        format('woff2'),
      url('https://www.rocketmortgage.com/nsassets/fonts/Wintle_Text_Web_DemiBold.woff')
        format('woff')
    ),
    "Wintle Text",
    700
  ),
  (
    (
      url('https://www.rocketmortgage.com/nsassets/fonts/Wintle_Text_Web_Medium.woff2')
        format('woff2'),
      url('https://www.rocketmortgage.com/nsassets/fonts/Wintle_Text_Web_Medium.woff')
        format('woff')
    ),
    "Wintle Text",
    500
  ),
  (
    (
      url('https://www.rocketmortgage.com/nsassets/fonts/Wintle_Text_Web_Regular.woff2')
        format('woff2'),
      url('https://www.rocketmortgage.com/nsassets/fonts/Wintle_Text_Web_Regular.woff')
        format('woff')
    ),
    "Wintle Text",
    400
  );

@each $rkt-src, $rkt-name, $rkt-weight in $rkt-webfonts {
  @font-face {
    font-display: swap;
    font-family: $rkt-name;
    src: $rkt-src;
    font-weight: $rkt-weight;
  }
}

@if $rkt-angular-legacy {
  // Register color palettes
  $rkt-ds-primary: mat.define-palette($rkt-gray, 800);
  // Mat calls secondary "accent"
  $rkt-ds-accent: mat.define-palette($rkt-red, 500);
  $rkt-ds-warn: mat.define-palette($rkt-yellow, 900);

  $rkt-typography: mat.define-typography-config(
    $font-family: typography.$rkt-typography-font-family-rocket,
    $headline-1:
      mat.define-typography-level(
        $font-family: typography.$rkt-typography-font-family-heading-28,
        $font-weight: typography.$rkt-typography-font-weight-heading-28,
        $font-size: typography.$rkt-typography-font-size-heading-28,
        $line-height: typography.$rkt-typography-line-height-heading-28,
        $letter-spacing: normal,
      ),
    $headline-2:
      mat.define-typography-level(
        $font-family: typography.$rkt-typography-font-family-heading-36,
        $font-weight: typography.$rkt-typography-font-weight-500,
        $font-size: typography.$rkt-typography-font-size-heading-36,
        $line-height: typography.$rkt-typography-line-height-heading-36,
        $letter-spacing: normal,
      ),
    $headline-3:
      mat.define-typography-level(
        $font-family: typography.$rkt-typography-font-family-heading-24,
        $font-weight: typography.$rkt-typography-font-weight-heading-24,
        $font-size: typography.$rkt-typography-font-size-heading-24,
        $line-height: typography.$rkt-typography-line-height-heading-24,
        $letter-spacing: normal,
      ),
    $headline-4:
      mat.define-typography-level(
        $font-family: typography.$rkt-typography-font-family-heading-24,
        $font-weight: typography.$rkt-typography-font-weight-heading-24,
        $font-size: typography.$rkt-typography-font-size-heading-24,
        $line-height: typography.$rkt-typography-line-height-heading-24,
        $letter-spacing: normal,
      ),
    $body-1:
      mat.define-typography-level(
        $font-family: typography.$rkt-typography-font-family-body-16,
        $font-weight: typography.$rkt-typography-font-weight-500,
        $font-size: typography.$rkt-typography-font-size-body-16,
        $line-height: typography.$rkt-typography-line-height-body-16,
        $letter-spacing: normal,
      ),
    $body-2:
      mat.define-typography-level(
        $font-family: typography.$rkt-typography-font-family-body-16,
        $font-weight: typography.$rkt-typography-font-weight-700,
        $font-size: typography.$rkt-typography-font-size-body-16,
        $line-height: typography.$rkt-typography-line-height-body-16,
        $letter-spacing: normal,
      ),
    $caption:
      mat.define-typography-level(
        $font-family: typography.$rkt-typography-font-family-caption,
        $font-weight: typography.$rkt-typography-font-weight-caption,
        $font-size: typography.$rkt-typography-font-size-caption,
        $line-height: typography.$rkt-typography-line-height-caption,
        $letter-spacing: normal,
      ),
    $button:
      mat.define-typography-level(
        $font-family: typography.$rkt-typography-font-family-button,
        $font-weight: typography.$rkt-typography-font-weight-button,
        $font-size: typography.$rkt-typography-font-size-button,
        $line-height: typography.$rkt-typography-line-height-button,
        $letter-spacing: normal,
      ),
    $subtitle-1:
      mat.define-typography-level(
        $font-family: typography.$rkt-typography-font-family-subheading-one,
        $font-weight: typography.$rkt-typography-font-weight-subheading-one,
        $font-size: typography.$rkt-typography-font-size-subheading-one,
        $line-height: typography.$rkt-typography-line-height-subheading-one,
        $letter-spacing: normal,
      ),
    $subtitle-2:
      mat.define-typography-level(
        $font-family: typography.$rkt-typography-font-family-subheading-two,
        $font-weight: typography.$rkt-typography-font-weight-subheading-two,
        $font-size: typography.$rkt-typography-font-size-subheading-two,
        $line-height: typography.$rkt-typography-line-height-subheading-two,
        $letter-spacing: normal,
      ),
  );

  $rkt-ds-theme: mat.define-light-theme(
    (
      color: (
        primary: $rkt-ds-primary,
        accent: $rkt-ds-accent,
        warn: $rkt-ds-warn,
      ),
      typography: $rkt-typography,
    )
  );

  $mat-light-theme-color: map_get($rkt-ds-theme, 'color');

  $rkt-ds-theme: map_merge(
    $rkt-ds-theme,
    (
      'color': $mat-light-theme-color,
    )
  );

  @include mat.core();
  @include mat.all-component-themes($rkt-ds-theme);
  @include mat.all-component-typographies($rkt-typography);
} @else {
  $rkt-ds-primary: mat.m2-define-palette($rkt-gray, 800);
  $rkt-ds-accent: mat.m2-define-palette($rkt-red, 500);
  $rkt-ds-warn: mat.m2-define-palette($rkt-yellow, 900);

  $rkt-typography: mat.m2-define-typography-config(
  $font-family: typography.$rkt-typography-font-family-rocket,

  $headline-1:
    mat.m2-define-typography-level(
      $font-family: typography.$rkt-typography-font-family-heading-28,
      $font-weight: typography.$rkt-typography-font-weight-heading-28,
      $font-size: typography.$rkt-typography-font-size-heading-28,
      $line-height: typography.$rkt-typography-line-height-heading-28,
      $letter-spacing: normal,
    ),
  $headline-2:
    mat.m2-define-typography-level(
      $font-family: typography.$rkt-typography-font-family-heading-36,
      $font-weight: typography.$rkt-typography-font-weight-500,
      $font-size: typography.$rkt-typography-font-size-heading-36,
      $line-height: typography.$rkt-typography-line-height-heading-36,
      $letter-spacing: normal,
    ),
  $headline-3:
    mat.m2-define-typography-level(
      $font-family: typography.$rkt-typography-font-family-heading-24,
      $font-weight: typography.$rkt-typography-font-weight-heading-24,
      $font-size: typography.$rkt-typography-font-size-heading-24,
      $line-height: typography.$rkt-typography-line-height-heading-24,
      $letter-spacing: normal,
    ),
  $headline-4:
    mat.m2-define-typography-level(
      $font-family: typography.$rkt-typography-font-family-heading-24,
      $font-weight: typography.$rkt-typography-font-weight-heading-24,
      $font-size: typography.$rkt-typography-font-size-heading-24,
      $line-height: typography.$rkt-typography-line-height-heading-24,
      $letter-spacing: normal,
    ),
  $body-1:
    mat.m2-define-typography-level(
      $font-family: typography.$rkt-typography-font-family-body-16,
      $font-weight: typography.$rkt-typography-font-weight-500,
      $font-size: typography.$rkt-typography-font-size-body-16,
      $line-height: typography.$rkt-typography-line-height-body-16,
      $letter-spacing: normal,
    ),
  $body-2:
    mat.m2-define-typography-level(
      $font-family: typography.$rkt-typography-font-family-body-16,
      $font-weight: typography.$rkt-typography-font-weight-700,
      $font-size: typography.$rkt-typography-font-size-body-16,
      $line-height: typography.$rkt-typography-line-height-body-16,
      $letter-spacing: normal,
    ),
  $caption:
    mat.m2-define-typography-level(
      $font-family: typography.$rkt-typography-font-family-caption,
      $font-weight: typography.$rkt-typography-font-weight-caption,
      $font-size: typography.$rkt-typography-font-size-caption,
      $line-height: typography.$rkt-typography-line-height-caption,
      $letter-spacing: normal,
    ),
  $button:
    mat.m2-define-typography-level(
      $font-family: typography.$rkt-typography-font-family-button,
      $font-weight: typography.$rkt-typography-font-weight-button,
      $font-size: typography.$rkt-typography-font-size-button,
      $line-height: typography.$rkt-typography-line-height-button,
      $letter-spacing: normal,
    ),
  $subtitle-1:
    mat.m2-define-typography-level(
      $font-family: typography.$rkt-typography-font-family-subheading-one,
      $font-weight: typography.$rkt-typography-font-weight-subheading-one,
      $font-size: typography.$rkt-typography-font-size-subheading-one,
      $line-height: typography.$rkt-typography-line-height-subheading-one,
      $letter-spacing: normal,
    ),
  $subtitle-2:
    mat.m2-define-typography-level(
      $font-family: typography.$rkt-typography-font-family-subheading-two,
      $font-weight: typography.$rkt-typography-font-weight-subheading-two,
      $font-size: typography.$rkt-typography-font-size-subheading-two,
      $line-height: typography.$rkt-typography-line-height-subheading-two,
      $letter-spacing: normal,
    ),
  );

  $rkt-ds-theme: mat.m2-define-light-theme(
    (
      color: (
        primary: $rkt-ds-primary,
        accent: $rkt-ds-accent,
        warn: $rkt-ds-warn,
      ),
      typography: $rkt-typography,
    )
  );

  $mat-light-theme-color: map_get($rkt-ds-theme, 'color');

  $rkt-ds-theme: map_merge(
    $rkt-ds-theme,
    (
      'color': $mat-light-theme-color,
    )
  );

  @include mat.core();
  @include mat.all-component-themes($rkt-ds-theme);
  @include mat.all-component-typographies($rkt-typography);
}


html,
body {
  height: 100%;
}
body {
  margin: 0;
}

// Material 15 Global resets

// Angular 15 makes the text visually smaller
// so we unset to correct the visual difference
.mdc-button,
.mat-mdc-standard-chip,
.mdc-form-field,
.mat-mdc-form-field,
.mat-mdc-floating-label,
.mdc-text-field__input,
.mdc-floating-label,
.mat-mdc-menu-content,
.mat-mdc-menu-content .mat-mdc-menu-item .mdc-list-item__primary-text,
.mat-mdc-paginator,
.mat-mdc-select,
.mat-mdc-option,
.mat-mdc-select-panel,
.mdc-data-table__header-cell,
.mdc-data-table__cell,
.mdc-tab {
  -webkit-font-smoothing: unset;
  -moz-osx-font-smoothing: unset;
}
