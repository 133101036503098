// ==================================================================
// Highlight Board
// ==================================================================
@use '../web/scss/settings' as *;

////
/// @group highlight board
////
.rkt-HighlightBoard {
  background-color: $rkt-highlight-board-color;
  position: relative;
}

.rkt-HighlightBoard__image {
  object-fit: cover;

  /* stylelint-disable */
  font-family: 'object-fit: cover;'; // required by the polyfill

  /* stylelint-enable */
  height: $rkt-highlight-board-height;
  width: 100%;
}

.rkt-HighlightBoard__content {
  background-color: $rkt-highlight-board-content-box-color;
  padding: $rkt-space-3;
  text-align: center;
  width: auto;

  @media (min-width: $rkt-highlight-board-breakpoint) {
    padding: $rkt-space-3;
  }
}

.rkt-HighlightBoard__heading {
  @media (max-width: $rkt-highlight-board-breakpoint) {
    font-size: $rkt-typography-font-size-heading-36 * $rkt-highlight-board-type-reduction-percentage;
  }
}

.rkt-HighlightBoard__cta {
  width: 100%;

  @media (min-width: $rkt-highlight-board-breakpoint) {
    width: auto;
  }
}

/// Adds the styles for the Stacked Highlight Board variant.
/// @name .rkt-HighlightBoard--stacked

/// A Rocket utility class that can be used on
/// the Highlight Board to make it become 100% width.
/// @name .rkt-FullWidth

/// Adds the styles for Highlight Boards with an image.
/// @name .rkt-HighlightBoard--has-image
.rkt-HighlightBoard--has-image:not(.rkt-HighlightBoard--stacked)
.rkt-HighlightBoard__content {
  position: absolute;
  bottom: $rkt-space-5;
  left: $rkt-space-5;
  max-width: $rkt-highlight-board-content-width;
  right: $rkt-space-5;
  text-align: left;
}
