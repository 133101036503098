@use './color' as *;
@use './color-dark' as *;
@use './space' as *;
@use './spacing' as *;
@use './layer' as *;
@use './object' as *;
@use './backdrop' as *;
$rkt-elevation-0: none !default;
$rkt-elevation-1: 0 10px 20px 0 rgba(0, 0, 0, 0.02) !default;
$rkt-elevation-2: 0 10px 12px 0 rgba(0, 0, 0, 0.05) !default;
$rkt-elevation-3: 0 10px 20px 0 rgba(0, 0, 0, 0.06) !default;
$rkt-elevation-4: 0 10px 30px 0 rgba(0, 0, 0, 0.08) !default;
$rkt-elevation-5: 0 10px 30px 0 rgba(0, 0, 0, 0.10) !default;
$rkt-elevation-6: 0 10px 30px 0 rgba(0, 0, 0, 0.12) !default;
$rkt-elevation-7: 0 10px 30px 0 rgba(0, 0, 0, 0.14) !default;
$rkt-elevation-8: 0 10px 30px 0 rgba(0, 0, 0, 0.16) !default;
$rkt-elevation-9: 0 10px 30px 0 rgba(0, 0, 0, 0.18) !default;