@use '../web/scss/settings';

.rkt-Card,
.rkt-Card.mat-mdc-card {
  transition: settings.$rkt-card-transition;
  background-color: settings.$rkt-card-background-color;
  box-shadow: settings.$rkt-card-box-shadow;
  max-width: settings.$rkt-card-max-width;
  border-radius: settings.$rkt-card-border-radius;
  border: settings.$rkt-card-border;
  box-sizing: settings.$rkt-card-box-sizing;
  width: settings.$rkt-card-width;
}

.rkt-Card .MuiCardContent-root,
.rkt-Card.mat-mdc-card {
  padding: settings.$rkt-card-padding;
}

.rkt-Card .MuiCardContent-root {
  display: settings.$rkt-card-content-display;
  flex-direction: settings.$rkt-card-content-flex-direction;
  box-sizing: settings.$rkt-card-content-box-sizing;
}

.rkt-Card .rkt-Card__header-icon {
  color: settings.$rkt-card-icon-color;
  height: settings.$rkt-card-icon-height;
  width: settings.$rkt-card-icon-width;

  svg path {
    stroke-width: settings.$rkt-card-icon-stroke-width;
  }
}

.rkt-Card .rkt-Card__icon-container {
  align-items: settings.$rkt-card-icon-container-align-items;
  background-color:settings.$rkt-card-icon-container-background-color;
  border-radius: settings.$rkt-card-icon-container-border-radius;
  color: settings.$rkt-card-icon-container-color;
  display: settings.$rkt-card-icon-container-display;
  height: settings.$rkt-card-icon-container-height;
  justify-content: settings.$rkt-card-icon-container-justify-content;
  width: settings.$rkt-card-icon-container-width;
}

.rkt-CardLinkContainer {
  box-sizing: settings.$rkt-card-link-container-box-sizing;
  width: settings.$rkt-card-link-container-width;
  max-width: settings.$rkt-card-link-container-max-width;
  display: settings.$rkt-card-link-container-display;
  text-decoration: settings.$rkt-card-link-container-text-decoration;

  > .rkt-Card--on-dark.rkt-Card--link {
    box-shadow: settings.$rkt-card-on-dark-as-link-box-shadow;
    border: settings.$rkt-card-on-dark-as-link-border;
  }

  &:focus-visible {
    outline: settings.$rkt-card-link-container-focus-visible-outline;

    > .rkt-Card--link {
      box-shadow: settings.$rkt-card-link-container-focus-visible-box-shadow;
    }
  }
}

.rkt-Card__subtitle {
  font-family: settings.$rkt-card-subtitle-font-family;
  color: settings.$rkt-card-subtitle-color;
  font-size: settings.$rkt-card-subtitle-font-size;
  font-weight: settings.$rkt-card-subtitle-font-weight;
  line-height: settings.$rkt-card-subtitle-line-height;
  margin: settings.$rkt-card-subtitle-margin;
}

.rkt-Card__heading {
  font-family: settings.$rkt-card-heading-font-family;
  color: settings.$rkt-card-heading-color;
  font-size: settings.$rkt-card-heading-font-size;
  font-weight: settings.$rkt-card-heading-font-weight;
  line-height: settings.$rkt-card-heading-line-height;
  margin: settings.$rkt-card-heading-margin;
}

.rkt-Card__content {
  flex: settings.$rkt-card-content-flex;
  font-family: settings.$rkt-card-content-font-family;
  color: settings.$rkt-card-content-color;
  font-size: settings.$rkt-card-content-font-size;
  font-weight: settings.$rkt-card-content-font-weight;
  line-height: settings.$rkt-card-content-line-height;
  margin: settings.$rkt-card-content-margin;
}

.rkt-Card.rkt-Card--image .mat-mdc-card-image {
  width: calc(100% + (settings.$rkt-card-padding * 2));
  margin-top: settings.$rkt-card-media-margin-top;
  margin-right: -(settings.$rkt-card-padding);
  margin-bottom: settings.$rkt-card-padding;
  margin-left: -(settings.$rkt-card-padding);
}

.rkt-Card.rkt-Card--image .MuiCardMedia-root {
  object-fit: settings.$rkt-card-image-object-fit;
}

.rkt-Card.rkt-Card--image .MuiCardMedia-root,
.rkt-Card .mat-mdc-card-image:first-child {
  height: settings.$rkt-card-image-height;
}

.rkt-Card .mat-mdc-card-image:first-child {
  margin-top: -(settings.$rkt-card-padding);
}

.rkt-Card--image.MuiCard-root {
  padding: settings.$rkt-card-image-padding;
}

.rkt-Card.rkt-Card--link,
.rkt-Card.rkt-Card--link.mat-mdc-card  {
  .rkt-ButtonIcon {
    padding: settings.$rkt-card-button-icon-padding;
  }

  &:hover,
  &:active,
  &:focus {
    cursor: settings.$rkt-card-link-hover-cursor;
    background-color: settings.$rkt-card-link-hover-background-color;
  }
}

.rkt-Card--tall,
.rkt-CardLinkContainer:has(.rkt-Card--tall),
.rkt-Card--tall.mat-mdc-card {
  max-width: settings.$rkt-card-tall-max-width;
}

.rkt-Card--tall .MuiCardContent-root,
.rkt-Card--tall,
.rkt-Card--tall.mat-mdc-car {
  min-height: settings.$rkt-card-tall-min-height;
}

// Short Cards
.rkt-Card--short .MuiCardContent-root,
.rkt-Card--short,
.rkt-Card--short.mat-mdc-car {
  min-height: settings.$rkt-card-short-min-height;
}

// Tiny Cards
.rkt-Card--tiny .MuiCardContent-root,
.rkt-Card--tiny.mat-mdc-card {
  padding: settings.$rkt-card-tiny-padding;
}

.rkt-CardLinkContainer:has(.rkt-Card--tiny),
.rkt-Card--tiny,
.rkt-Card--tiny.mat-mdc-card {
  max-width: settings.$rkt-card-tiny-max-width;
}

.rkt-Card--tiny,
.rkt-Card--tiny.mat-mdc-card {
  border-radius: settings.$rkt-card-tiny-border-radius;

  .rkt-Card__heading {
    font-size: settings.$rkt-card-tiny-heading-font-size;
    line-height: settings.$rkt-card-tiny-heading-line-height;
  }

  .rkt-Card__content {
    font-size: settings.$rkt-card-tiny-content-font-size;
    line-height: settings.$rkt-card-tiny-content-line-height;
  }
}

.rkt-Card--tiny .MuiCardContent-root,
.rkt-Card--tiny,
.rkt-Card--tiny.mat-mdc-car {
  min-height: settings.$rkt-card-tiny-min-height;
}

// Secondary
.rkt-Card--secondary,
.rkt-Card--secondary.mat-mdc-card  {
  background-color: settings.$rkt-card-secondary-background-color;
  border: settings.$rkt-card-secondary-border;

  &.rkt-Card--tall,
  &.rkt-Card--short,
  &.rkt-Card--tiny {
    .rkt-Card__subtitle,
    .rkt-Card__heading,
    .rkt-Card__content,
    .rkt-Card__header-icon {
      color: settings.$rkt-card-secondary-color;
    }

    &.rkt-Card--link {
      &:hover,
      &:active,
      &:focus {
        background-color: settings.$rkt-card-secondary-link-hover-background-color;
      }
    }
  }
}

.rkt-Card--white,
.rkt-Card--white.mat-mdc-card  {
  background-color: settings.$rkt-card-white-background-color;
  box-shadow: settings.$rkt-card-white-box-shadow;
}

.rkt-Card--white.rkt-Card--link,
.rkt-Card--white.rkt-Card--link.mat-mdc-card {
  .rkt-Card__icon-container {
    background-color: settings.$rkt-card-white-link-icon-container-background-color;
  }

  &:hover,
  &:active,
  &:focus {
    box-shadow: settings.$rkt-card-white-link-hover-box-shadow;
    background-color: settings.$rkt-card-white-link-hover-background-color;
  }
}

.rkt-Card--on-dark {
  background: settings.$rkt-card-on-dark-background;
}

.rkt-CardLinkContainer.rkt-CardLinkContainer--full-width,
.rkt-CardLinkContainer.rkt-CardLinkContainer--full-width .rkt-Card,
.rkt-CardLinkContainer.rkt-CardLinkContainer--full-width .rkt-Card.mat-mdc-card,
.rkt-Card--full-width,
.rkt-Card--full-width.mat-mdc-card  {
  max-width: settings.$rkt-card-full-width-max-width;
}