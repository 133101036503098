@use '../web/scss/settings';

.rkt-Dialog.cdk-overlay-pane,
.rkt-Dialog .MuiDialog-paper {
  max-width: settings.$rkt-dialog-max-width;
}

.rkt-Dialog .MuiDialog-paper,
.rkt-Dialog .mat-mdc-dialog-surface.mdc-dialog__surface {
  padding: settings.$rkt-dialog-padding;
  border-radius: settings.$rkt-dialog-border-radius;
  box-shadow: settings.$rkt-dialog-box-shadow;
  box-sizing: settings.$rkt-dialog-box-sizing;
  width: settings.$rkt-dialog-width;
}

.rkt-Dialog.rkt-Dialog--medium .MuiDialog-paper,
.rkt-Dialog.rkt-Dialog--medium .mat-mdc-dialog-surface.mdc-dialog__surface {
  width: settings.$rkt-dialog-medium-width;
}

.rkt-Dialog.rkt-Dialog--large .MuiDialog-paper,
.rkt-Dialog.rkt-Dialog--large .mat-mdc-dialog-surface.mdc-dialog__surface {
  width: settings.$rkt-dialog-large-width;
}

.rkt-Dialog .mat-mdc-dialog-content.mdc-dialog__content,
.rkt-Dialog__content.MuiDialogContent-root {
  padding: settings.$rkt-dialog-content-padding;
}

.rkt-Dialog .mat-mdc-dialog-actions {
  padding: settings.$rkt-dialog-actions-padding;
}

// deprecated in v4, remove in v6 - task #3545100
// this class is no longer in use in any of our patterns
.rkt-Dialog__dismiss-button {
  align-self: settings.$rkt-dialog-dismiss-button-align-self;
  flex: settings.$rkt-dialog-dismiss-button-flex;
  margin: settings.$rkt-dialog-dismiss-button-margin;
  height: settings.$rkt-dialog-dismiss-button-height;
}

.rkt-Dialog__header {
  height: settings.$rkt-dialog-header-height;
}

.rkt-Dialog__header > .rkt-Icon {
  width: settings.$rkt-dialog-header-icon-width;
  height: settings.$rkt-dialog-header-icon-height;
  flex-shrink: settings.$rkt-dialog-header-icon-flex-shrink;

  svg path {
    stroke-width: settings.$rkt-dialog-header-icon-stroke-width;
  }
}

.rkt-Dialog .mat-mdc-dialog-title.mdc-dialog__title,
.rkt-Dialog__title {
  padding: settings.$rkt-dialog-title-padding;
  margin-bottom: settings.$rkt-dialog-title-margin-bottom;
  margin-top: settings.$rkt-dialog-title-margin-top;
  font-size: settings.$rkt-dialog-title-font-size;
  font-style: settings.$rkt-dialog-title-font-style;
  font-weight: settings.$rkt-dialog-title-font-weight;
  line-height: settings.$rkt-dialog-title-line-height;
  letter-spacing: settings.$rkt-dialog-title-letter-spacing;
  color: settings.$rkt-dialog-title-color;
  font-family: settings.$rkt-dialog-title-font-family;
  max-width: settings.$rkt-dialog-title-max-width; // For the title to wrap when the text is longer than the dialog
}

// Angular 15 now gives dialog titles a specific height.
// we need to unset this to match our designs
.rkt-Dialog .mdc-dialog__title::before {
  height: settings.$rkt-dialog-title-height;
}

// Material puts a margin-left 8px on buttons by default. we want to set this for our own
.rkt-Dialog .mat-mdc-dialog-actions .mat-button-base+.mat-button-base,
.rkt-Dialog .mat-mdc-dialog-actions .mat-mdc-button-base+.mat-mdc-button-base {
  margin: settings.$rkt-dialog-actions-button-margin-reset;
}

.rkt-Dialog__text {
  color: settings.$rkt-dialog-text-color;
  font-size: settings.$rkt-dialog-text-font-size;
  font-style: settings.$rkt-dialog-text-font-style;
  font-family: settings.$rkt-dialog-text-font-family;
  font-weight: settings.$rkt-dialog-text-font-weight;
  line-height: settings.$rkt-dialog-text-line-height;
  margin: settings.$rkt-dialog-text-margin;
}

.rkt-Dialog__content {
  background-color: settings.$rkt-dialog-content-background-color;
}

.rkt-Dialog__content.mdc-dialog__content,
.rkt-Dialog__content.MuiDialogContent-root {
  margin-bottom: settings.$rkt-dialog-content-margin-bottom;
  height: settings.$rkt-dialog-content-height;
  box-sizing: settings.$rkt-dialog-content-box-sizing;
}

// When text is inside the Dialog content box it should have different styles
.rkt-Dialog__content .rkt-Dialog__text {
  color: settings.$rkt-dialog-content-text-color;
  font-size: settings.$rkt-dialog-content-text-font-size;
  line-height: settings.$rkt-dialog-content-text-line-height;
}

@media (max-width: settings.$rkt-split-breakpoint-7) {
  .rkt-Dialog .mat-mdc-dialog-surface.mdc-dialog__surface,
  .rkt-Dialog .MuiDialog-paper {
    max-width: settings.$rkt-dialog-mobile-max-width;
    margin: settings.$rkt-dialog-mobile-margin;
    width: settings.$rkt-dialog-small-width;
  }
}

.rkt-Dialog__dismiss-button:focus-visible {
  box-shadow: settings.$rkt-dialog-dismiss-button-focus-visible-box-shadow;
  border-radius: settings.$rkt-dialog-dismiss-button-focus-visible-border-radius;
  outline: settings.$rkt-dialog-dismiss-button-focus-visible-outline;
  background: settings.$rkt-dialog-dismiss-button-focus-visible-background;
}
