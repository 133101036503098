@use '@rocketcentral/rocket-design-system-styles/theme-angular' as * with (
  $rkt-angular-legacy: false
);

// Load Rocket custom component styles from styles (this stays the same as above).
@use '@rocketcentral/rocket-design-system-styles/rocket' as *;
@use '@rocketcentral/rocket-design-system-styles/base/typography' as *;

:root {
  --rocket-font-family: 'Wintle Text', Helvetica, 'Helvetica Neue', Arial, sans-serif;
}
/* Font definitions for Wintle Text */

@font-face {
  font-family: "Wintle Text";
  src: url("https://www.rocketmortgage.com/nsassets/fonts/Wintle_Text_Web_Bold.woff2") format("woff2"),
       url("https://www.rocketmortgage.com/nsassets/fonts/Wintle_Text_Web_Bold.woff") format("woff");
  font-weight: 900;
}
@font-face {
  font-family: "Wintle Text";
  src: url("https://www.rocketmortgage.com/nsassets/fonts/Wintle_Text_Web_DemiBold.woff2") format("woff2"),
       url("https://www.rocketmortgage.com/nsassets/fonts/Wintle_Text_Web_DemiBold.woff") format("woff");
  font-weight: 700;
}
@font-face {
  font-family: "Wintle Text";
  src: url("https://www.rocketmortgage.com/nsassets/fonts/Wintle_Text_Web_Medium.woff2") format("woff2"),
       url("https://www.rocketmortgage.com/nsassets/fonts/Wintle_Text_Web_Medium.woff") format("woff");
  font-weight: 500;
}
@font-face {
  font-family: "Wintle Text";
  src: url("https://www.rocketmortgage.com/nsassets/fonts/Wintle_Text_Web_Regular.woff2") format("woff2"),
       url("https://www.rocketmortgage.com/nsassets/fonts/Wintle_Text_Web_Regular.woff") format("woff");
  font-weight: 400;
}
