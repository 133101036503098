@use '../web/scss/settings' as *;

.rkt-Snackbar {
  background-color: $rkt-snackbar-background-color;
  border: $rkt-snackbar-border;
  border-radius: $rkt-snackbar-border-radius;
  --mdc-snackbar-container-color: $rkt-snackbar-background-color;
  width: $rkt-snackbar-width;
  padding: $rkt-snackbar-padding;

  .mat-mdc-simple-snack-bar {
    align-items: $rkt-snackbar-align-items;
  }

  .mdc-snackbar__surface {
    padding-right: $rkt-snackbar-surface-padding-right;
    border: $rkt-snackbar-surface-border;
  }

  .MuiSnackbarContent-root {
    background-color: $rkt-snackbar-content-background-color;
    box-shadow: $rkt-snackbar-content-box-shadow;
    padding: $rkt-snackbar-content-padding;
    border-radius: $rkt-snackbar-content-border-radius;
    width: $rkt-snackbar-content-width;
    min-width: $rkt-snackbar-content-min-width;
    flex-wrap: $rkt-snackbar-content-flex-wrap;
  }

  .mat-mdc-snack-bar-label.mdc-snackbar__label,
  .MuiSnackbarContent-message,
  .rkt-Snackbar__message {
    color: $rkt-snackbar-label-color;
    font-family: $rkt-snackbar-label-font-family;
    font-size: $rkt-snackbar-label-font-size;
    font-style: $rkt-snackbar-label-font-style;
    font-weight: $rkt-snackbar-label-font-weight;
    line-height: $rkt-snackbar-label-line-height;
    padding: $rkt-snackbar-label-padding;
    margin-right: $rkt-snackbar-label-margin-right;
    flex-grow: $rkt-snackbar-label-flex-grow;
  }

  .MuiSnackbarContent-action {
    padding: $rkt-snackbar-content-action-padding;
    margin: $rkt-snackbar-content-action-margin;
  }

  .mat-mdc-button,
  .MuiSnackbarContent-action .rkt-Button {
    color: $rkt-snackbar-button-color;
    background-color: $rkt-snackbar-button-background-color;
    border-radius: $rkt-snackbar-button-border-radius;
    font-family: $rkt-snackbar-button-font-family;
    font-size: $rkt-snackbar-button-font-size;
    font-weight: $rkt-snackbar-button-font-weight;
    line-height: $rkt-snackbar-button-line-height;
    padding: $rkt-snackbar-button-padding;
    height: $rkt-snackbar-button-height;

    &:hover {
      color: $rkt-snackbar-button-hover-color;
      background-color: $rkt-snackbar-button-hover-background-color;
    }

    &:focus-visible,
    &:focus-visible:hover  {
      color: $rkt-snackbar-button-focus-visible-color;
      box-shadow: $rkt-snackbar-button-focus-visible-box-shadow;
      background-color: $rkt-snackbar-button-focus-visible-background-color;
    }

    &:active {
      color: $rkt-snackbar-button-active-color;
      background-color: $rkt-snackbar-button-active-background-color;
    }

    .mat-ripple,
    .MuiTouchRipple-root {
      display: $rkt-snackbar-button-ripple-display;
    }
  }

  // React
  &.MuiSnackbar-root {
    width: $rkt-snackbar-react-width;
    left: $rkt-snackbar-left;
    bottom: $rkt-snackbar-bottom;
    right: $rkt-snackbar-right;
    // React has a different value because the internal components amount to 50px minimum.
    // subtracted 50 from 480 to make up for this difference
    max-width: $rkt-snackbar-react-max-width;

    @media only screen and (min-width: $rkt-snackbar-breakpoint-custom) {
      // this is at 496px because we have to account for 8px margin on both sides.
      // 480 + 8 + 8 = 496
      left: $rkt-snackbar-left-breakpoint-custom;
      width: $rkt-snackbar-tablet-react-width;
    }
  }

  // Angular
  &.mat-mdc-snack-bar-container {
    width: $rkt-snackbar-container-width;
    max-width: $rkt-snackbar-angular-max-width;
  }
}

.rkt-Snackbar.mat-mdc-snack-bar-container {
  .mdc-snackbar__surface {
    box-shadow: $rkt-snackbar-surface-box-shadow;
    border-radius: $rkt-snackbar-surface-border-radius;
    background-color: $rkt-snackbar-surface-background-color;
    max-width: $rkt-snackbar-surface-max-width;
    min-width: $rkt-snackbar-surface-min-width;
    width: $rkt-snackbar-surface-width;
  }

  .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
    color: $rkt-snackbar-button-color;
    --mat-mdc-button-persistent-ripple-color: $rkt-snackbar-button-ripple-color;

    &:hover:not(:active):not(:focus-visible) {
      color: $rkt-snackbar-button-hover-color;
    }
  }
}

// Tall variant
.rkt-Snackbar--tall {
  padding: $rkt-snackbar-tall-padding;

  .mat-mdc-simple-snack-bar {
    display: $rkt-snackbar-tall-display;
    justify-items: $rkt-snackbar-tall-justify-items;
  }

  .MuiSnackbarContent-root {
    display: $rkt-snackbar-tall-display;
  }

  .mat-mdc-snack-bar-label.mdc-snackbar__label,
  .MuiSnackbarContent-message {
    margin: $rkt-snackbar-tall-label-margin;
    grid-row-start: $rkt-snackbar-tall-label-grid-row-start;
    grid-row-end: $rkt-snackbar-tall-label-grid-row-end;
  }

  .mat-mdc-snack-bar-actions.mdc-snackbar__actions,
  .MuiSnackbarContent-action {
    grid-row-start: $rkt-snackbar-tall-action-grid-row-start;
    grid-row-end: $rkt-snackbar-tall-action-grid-row-end;
  }
}

// Angular: Making it so the Snackbar is always on the right side of the screen
.cdk-overlay-pane.mat-mdc-snack-bar-handset:has(.rkt-Snackbar) {
  flex: $rkt-snackbar-overlay-flex;
}

.rkt-Snackbar--titled {
  .rkt-Snackbar__message {
    margin: $rkt-snackbar-titled-message-margin;
  }
}

.rkt-Snackbar__title,
.rkt-Snackbar__title.mat-mdc-snack-bar-label.mdc-snackbar__label {
  color: $rkt-snackbar-title-color;
  font-family: $rkt-snackbar-title-font-family;
  font-size: $rkt-snackbar-title-font-size;
  font-weight: $rkt-snackbar-title-font-weight;
  line-height: $rkt-snackbar-title-line-height;
  margin: $rkt-snackbar-title-margin;
}