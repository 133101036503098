@use '../web/scss/settings' as *;

.rkt-GlobalFooter {
  background-color: $rkt-footer-background-color;
}

.rkt-GlobalFooter__content {
  display: $rkt-footer-content-display;
  box-sizing: $rkt-footer-content-box-sizing;
  flex-direction: $rkt-footer-content-flex-direction;
  padding: $rkt-footer-content-padding;
  margin: $rkt-footer-content-margin;
  max-width: $rkt-footer-content-max-width;

  @media all and (min-width: $rkt-footer-medium-breakpoint) {
    padding: $rkt-footer-medium-breakpoint-content-padding;
  }

  @media all and (min-width: $rkt-footer-large-breakpoint) {
    padding: $rkt-footer-large-breakpoint-content-padding;
  }
}

.rkt-GlobalFooter__disclosures {
  background-color: $rkt-footer-disclosures-background-color;
  box-sizing: $rkt-footer-disclosures-box-sizing;

  .rkt-GlobalFooter__disclosures-toggle {
    box-sizing: $rkt-footer-disclosures-toggle-box-sizing;
    padding: $rkt-footer-disclosures-toggle-padding;
    max-width: $rkt-footer-disclosures-toggle-max-width;
    margin: $rkt-footer-disclosures-toggle-margin;

    .rkt-Toggle__trigger {
      margin-left: $rkt-footer-disclosures-trigger-margin-left;
    }

    &.rkt-Toggle--small .rkt-Toggle__content {
      padding-top: $rkt-footer-disclosures-toggle-content-padding-top;
      padding-bottom: $rkt-footer-disclosures-toggle-content-padding-bottom;

      @media all and (min-width: $rkt-footer-large-breakpoint) {
        padding-bottom: $rkt-footer-large-breakpoint-disclosures-toggle-content-padding-bottom;
      }
    }

    @media all and (min-width: $rkt-footer-medium-breakpoint) {
      padding: $rkt-footer-medium-breakpoint-disclosures-padding;
    }

    @media all and (min-width: $rkt-footer-large-breakpoint) {
      padding: $rkt-footer-large-breakpoint-disclosures-padding;
    }
  }
}

.rkt-GlobalFooter__toggle.rkt-Toggle .rkt-Toggle__trigger,
.rkt-GlobalFooter__toggle.rkt-Toggle .rkt-Toggle__trigger > .rkt-Toggle__summary {
  color: $rkt-footer-toggle-color;
}

.rkt-GlobalFooter__toggle.rkt-Toggle .rkt-Toggle__trigger:hover,
.rkt-GlobalFooter__toggle.rkt-Toggle .rkt-Toggle__trigger:active {
  .rkt-Toggle__summary,
  .rkt-Toggle__trigger-icon {
    color: $rkt-footer-toggle-hover-color;
  }
}

.rkt-GlobalFooter__toggle.rkt-Toggle .rkt-Toggle__content-text {
  color: $rkt-footer-toggle-content-text-color;
}

.rkt-GlobalFooter__toggle.rkt-Toggle .rkt-Toggle__trigger:focus-visible {
  outline-color: $rkt-footer-toggle-focus-visible-outline-color;
}

.rkt-GlobalFooter__link-sections {
  gap: $rkt-footer-link-sections-gap;
  padding-bottom: $rkt-footer-link-sections-padding-bottom;

  @media all and (min-width: $rkt-footer-medium-breakpoint) {
    gap: $rkt-footer-medium-breakpoint-link-sections-gap;
  }

  @media all and (min-width: $rkt-footer-extra-large-breakpoint) {
    gap: $rkt-footer-large-breakpoint-link-sections-gap;
  }
}

.rkt-GlobalFooter__brands {
  @media all and (min-width: $rkt-footer-extra-large-breakpoint) {
    max-width: $rkt-footer-brands-max-width;
  }
}

.rkt-GlobalFooter__divider,
.rkt-GlobalFooter__divider.rkt-HorizontalDivider.mat-divider {
  border-bottom-color: $rkt-footer-divider-border-bottom-color;
  border-top-color: $rkt-footer-divider-border-top-color;
}

.rkt-GlobalFooter__link-columns {
  gap: $rkt-footer-link-columns-gap;

  @media all and (min-width: $rkt-footer-large-breakpoint) {
    gap: $rkt-footer-medium-breakpoint-link-columns-gap;
  }
}

.rkt-GlobalFooter__section-heading {
  color: $rkt-footer-section-heading-color;
  font-family: $rkt-footer-section-heading-font-family;
  font-size: $rkt-footer-section-heading-font-size;
  font-weight: $rkt-footer-section-heading-font-weight;
  line-height: $rkt-footer-section-heading-line-height;
  margin: $rkt-footer-section-heading-margin;

  @media all and (min-width: $rkt-footer-medium-breakpoint) {
    font-size: $rkt-footer-medium-breakpoint-section-heading-font-size;
    line-height: $rkt-footer-medium-breakpoint-section-heading-line-height;
    margin: $rkt-footer-medium-breakpoint-section-heading-margin;
  }
}

.rkt-GlobalFooter__apps {
  padding: $rkt-footer-apps-padding;
  gap: $rkt-footer-apps-gap;

  @media all and (min-width: $rkt-footer-medium-breakpoint) {
    gap: $rkt-footer-medium-breakpoint-apps-gap;
    padding: $rkt-footer-medium-breakpoint-apps-padding;
  }
}

.rkt-GlobalFooter__app,
.rkt-GlobalFooter__app {
  display: $rkt-footer-app-display;
  position: $rkt-footer-app-position;

  .rkt-Icon.mat-icon,
  .rkt-Icon {
    color: $rkt-footer-app-color;
    width: $rkt-footer-app-width;
    height: $rkt-footer-app-height;

    @media all and (min-width: $rkt-footer-medium-breakpoint) {
      width: $rkt-footer-medium-breakpoint-app-width;
      height: $rkt-footer-medium-breakpoint-app-height;
    }
  }

  &:hover,
  &:active,
  &:focus-visible {
    .rkt-Icon.mat-icon,
    .rkt-Icon {
      color: $rkt-footer-app-hover-color;
    }
  }

  &:focus-visible {
    outline: $rkt-footer-app-focus-visible-outline;

    &::after  {
      content: '';
      width: $rkt-footer-app-focus-visible-width;
      height: $rkt-footer-app-focus-visible-height;
      border-radius: $rkt-footer-app-focus-visible-border-radius;
      box-shadow: $rkt-footer-app-focus-visible-box-shadow;
      position: $rkt-footer-app-focus-visible-position;
    }
  }
}

.rkt-GlobalFooter__app-apple {
  width: $rkt-footer-app-apple-width;
  height: $rkt-footer-app-apple-height;

  @media all and (min-width: $rkt-footer-medium-breakpoint) {
    width: $rkt-footer-medium-breakpoint-app-apple-width;
    height: $rkt-footer-medium-breakpoint-app-apple-height;
  }
}

.rkt-GlobalFooter__app-google {
  width: $rkt-footer-app-google-width;
  height: $rkt-footer-app-google-height;

  @media all and (min-width: $rkt-footer-medium-breakpoint) {
    width: $rkt-footer-medium-breakpoint-app-google-width;
    height: $rkt-footer-medium-breakpoint-app-google-height;
  }
}

.rkt-GlobalFooter__awards {
  padding: $rkt-footer-awards-padding;
  gap: $rkt-footer-awards-gap;

  @media all and (min-width: $rkt-footer-medium-breakpoint) {
    gap: $rkt-footer-medium-breakpoint-awards-gap;
    padding: $rkt-footer-medium-breakpoint-awards-padding;
  }
}

.rkt-GlobalFooter__body-text {
  color: $rkt-footer-body-text-color;
  font-family: $rkt-footer-body-text-font-family;
  font-size: $rkt-footer-body-text-font-size;
  font-weight: $rkt-footer-body-text-font-weight;
  line-height: $rkt-footer-body-text-line-height;
  margin: $rkt-footer-body-text-margin;
}

.rkt-GlobalFooter__brand-image {
  height: $rkt-footer-brand-image-height;
  margin-bottom: $rkt-footer-brand-image-margin-bottom;
}

.rkt-GlobalFooter__link,
.rkt-GlobalFooter__link.rkt-Link {
  color: $rkt-footer-link-color;
  font-weight: $rkt-footer-link-font-weight;

  &:visited {
    color: $rkt-footer-link-visited-color;
  }

  &:hover,
  &:active {
    color: $rkt-footer-link-hover-color;
    text-decoration: $rkt-footer-link-hover-text-decoration;
  }

  &:focus-visible {
    text-decoration: $rkt-footer-link-focus-visible-text-decoration;
  }
}