@use '../web/scss/settings' as *;

.rkt-Menu,
.rkt-Menu.mat-mdc-menu-panel,
.rkt-Menu .MuiPaper-root {
  box-shadow: $rkt-menu-elevation;
  border-radius: $rkt-menu-border-radius;
  border: $rkt-menu-border;
}

// Angular: slight adjustment to menu placement from trigger element
.rkt-Menu.mat-mdc-menu-panel {
  margin-top: $rkt-menu-margin-top;
}

// React: To move nested sub-menu up so item is in line with parent menu item
.rkt-Menu.MuiPaper-root {
  margin-top: $rkt-menu-margin-top-nested-mui;
}

.rkt-Menu .mat-mdc-menu-content,
.rkt-Menu .MuiMenu-list {
  padding: $rkt-menu-padding;
  display: $rkt-menu-display;
  flex-direction: $rkt-menu-flex-direction;
  align-items: $rkt-menu-align-items;
}

.rkt-Menu .rkt-Menu__item:hover,
// @deprecated in v4, removal in v6 task #3532785
.rkt-Menu .rkt-Menu__item.mat-menu-item-highlighted:not([disabled]),
.rkt-Menu .rkt-Menu__item--is-expanded {
  background-color: $rkt-menu-item-hover-background-color;
  border-radius: $rkt-menu-item-border-radius;
  padding: $rkt-menu-item-padding;

  &:has(.mdc-list-item__primary-text .rkt-Tag),
  &:has(.mat-mdc-menu-item-text .rkt-Tag) {
    padding: $rkt-menu-item-padding-with-tag;
  }

  &:has(.rkt-Tag) {
    padding: $rkt-menu-item-padding-with-tag;
  }
}

.rkt-Menu__item,
// @deprecated in v4, removal in v6 task #3532785
.rkt-Menu__item.mat-menu-item,
.rkt-Menu__item.mat-mdc-menu-item,
.rkt-Menu__item.mat-mdc-menu-item:has(.material-icons,mat-icon,[matButtonIcon]) {
  height: $rkt-menu-item-height;
  min-width: $rkt-menu-item-min-width;
  padding: $rkt-menu-item-padding;
  align-items: $rkt-menu-item-align-items;
  align-self: $rkt-menu-item-align-self;

  &:has(.mdc-list-item__primary-text .rkt-Tag),
  &:has(.mat-mdc-menu-item-text .rkt-Tag) {
    padding: $rkt-menu-item-padding-with-tag;
  }

  &:has(.rkt-Tag) {
    padding: $rkt-menu-item-padding-with-tag;
  }
}

.rkt-Menu__item.mat-mdc-menu-item.mat-mdc-menu-item-submenu-trigger {
  border-radius: $rkt-menu-item-border-radius;
}

.rkt-Menu__item {
  .rkt-Menu__item-text {
    color: $rkt-menu-item-text-color;
    font-family: $rkt-menu-item-text-font-family;
    font-size: $rkt-menu-item-text-font-size;
    font-weight: $rkt-menu-item-text-font-weight;
    line-height: $rkt-menu-item-text-line-height;
    font-style: $rkt-menu-item-text-font-style;
    overflow: $rkt-menu-item-text-overflow;
    text-overflow: $rkt-menu-item-text-text-overflow;
    white-space: $rkt-menu-item-text-white-space;
    display: $rkt-menu-item-text-display;
    max-width: $rkt-menu-item-text-max-width;
    margin-top: $rkt-menu-item-text-margin-top;
  }

  &:has(.rkt-Tag) {
    .rkt-Menu__item-text {
      flex-grow: $rkt-menu-item-text-flex-grow-with-tag;
    }
  }
}

.rkt-Menu .rkt-Menu__item-text-group {
  display: $rkt-menu-item-text-group-display;
  flex-direction: $rkt-menu-item-text-group-flex-direction;
  justify-content: $rkt-menu-item-text-group-justify-content;
  align-items: $rkt-menu-item-text-group-align-items;
  flex-grow: $rkt-menu-item-text-group-flex-grow;
}

.rkt-Menu .rkt-Menu--center-item {
  display: $rkt-menu-item-center-item-display;
  align-items: $rkt-menu-item-center-item-align-items;
  flex: $rkt-menu-item-center-item-flex;
}

.rkt-Menu__item .rkt-Menu__item-caption {
  color: $rkt-menu-item-caption-color;
  font-family: $rkt-menu-item-caption-font-family;
  font-size: $rkt-menu-item-caption-font-size;
  font-style: $rkt-menu-item-caption-font-style;
  font-weight: $rkt-menu-item-caption-font-weight;
  line-height: $rkt-menu-item-caption-line-height;
  overflow: $rkt-menu-item-caption-overflow;
  text-overflow: $rkt-menu-item-caption-text-overflow;
  white-space: $rkt-menu-item-caption-white-space;
  display: $rkt-menu-item-caption-display;
  max-width: $rkt-menu-item-caption-max-width;
}

.rkt-Menu .mat-mdc-menu-item .mat-icon {
  display: $rkt-menu-item-icon-display;
  align-items: $rkt-menu-item-icon-align-items;
  justify-content: $rkt-menu-item-icon-justify-content;
  color: $rkt-menu-item-icon-color;
}

.rkt-Menu .rkt-HorizontalDivider {
  width: $rkt-menu-divider-width;
  border-color: $rkt-menu-divider-border-color;
  margin: $rkt-menu-divider-margin;
}

// React: For arrow icon to indicate sub-menu
.rkt-Menu__item .rkt-Stack__item:last-child {
  height: $rkt-menu-item-icon-height;

  .rkt-Icon {
    margin-right: $rkt-menu-item-submenu-icon-margin-right;
  }
}

.rkt-Menu__item {
  .mdc-list-item__primary-text:has(.rkt-Tag, .rkt-Button),
  .mat-mdc-menu-item-text:has(.rkt-Tag, .rkt-Button) {
    width: $rkt-menu-item-width;
    display: $rkt-menu-item-display;
    align-items: $rkt-menu-item-align-items-with-button;
  }

  .rkt-Icon {
    color: $rkt-menu-item-icon-color;
    height: $rkt-menu-item-icon-height;
    width: $rkt-menu-item-icon-width;
    margin-right: $rkt-menu-item-icon-margin-right;
  }

  &:hover {
    border-radius: $rkt-menu-item-border-radius;
    color: $rkt-menu-item-hover-color;
    background-color: $rkt-menu-item-hover-background-color;
  }

  &:focus-visible {
    border-radius: $rkt-menu-item-border-radius;
    box-shadow: $rkt-menu-item-focus-visible-box-shadow;
    background-color: $rkt-menu-item-focus-visible-background-color;
    padding: $rkt-menu-item-padding;

    &:has(.mdc-list-item__primary-text .rkt-Tag),
    &:has(.mat-mdc-menu-item-text .rkt-Tag) {
      padding: $rkt-menu-item-padding-with-tag;
    }

    &:has(.rkt-Tag) {
      padding: $rkt-menu-item-padding-with-tag;
    }
  }

  &:active {
    background-color: $rkt-menu-item-active-background-color;
    border-radius: $rkt-menu-item-border-radius;
  }

  &:has(.rkt-Button) {
    height: $rkt-menu-item-button-item-height;

    .rkt-Button {
      width: $rkt-menu-item-button-item-width;
    }

    .mat-ripple {
      display: $rkt-menu-item-button-item-ripple-display;
    }
  }

  &:hover:has(.rkt-Button),
  &:focus:has(.rkt-Button),
  &:active:has(.rkt-Button),
  &.mat-mdc-menu-item:not([disabled]):hover:has(.rkt-Button) {
    background-color: $rkt-menu-item-button-item-background-color;
    cursor: $rkt-menu-item-button-item-cursor;
    box-shadow: $rkt-menu-item-button-item-box-shadow;

    .rkt-Button:hover,
    .rkt-Button:active {
      cursor: $rkt-menu-item-button-item-cursor-active;
    }

    .MuiTouchRipple-root {
      display: $rkt-menu-item-button-item-ripple-display;
    }
  }

  &:focus-visible:has(.rkt-Button) {
    .rkt-Button {
      box-shadow: $rkt-menu-item-button-item-button-box-shadow;
    }
  }

  .rkt-Tag {
    max-width: $rkt-menu-item-tag-max-width;
    min-width: $rkt-menu-item-tag-min-width;
  }

  .mat-ripple {
    border-radius: $rkt-menu-item-border-radius;
  }
}

// Angular: To hide the default arrow (to indicate nested menu options) generated by the framework
.rkt-Menu .rkt-Menu__item .mat-mdc-menu-submenu-icon {
  display: $rkt-menu-item-submenu-icon-display;
}

// Angular: Using an ::after to show our own custom arrow icon for nested menu
.rkt-Menu .rkt-Menu__item.mat-mdc-menu-item.mat-mdc-menu-item-submenu-trigger::after {
  content: $rkt-menu-item-submenu-image-content;
  display: $rkt-menu-item-submenu-image-display;
  background-image: $rkt-menu-item-submenu-image-background-image;
  width: $rkt-menu-item-submenu-image-width;
  height: $rkt-menu-item-submenu-image-height;
  position: $rkt-menu-item-submenu-image-position;
  top: $rkt-menu-item-submenu-image-top;
  right: $rkt-menu-item-submenu-image-right;
  background-repeat: $rkt-menu-item-submenu-image-background-repeat;
}

.rkt-Menu .rkt-Menu__item.mat-mdc-menu-item.mat-mdc-menu-item-submenu-trigger[disabled]::after {
  content: $rkt-menu-item-submenu-image-content;
  display: $rkt-menu-item-submenu-image-display;
  background-image: $rkt-menu-item-submenu-image-background-image;
  width: $rkt-menu-item-submenu-image-width;
  height: $rkt-menu-item-submenu-image-height;
  position: $rkt-menu-item-submenu-image-position;
  top: $rkt-menu-item-submenu-image-top;
  left: $rkt-menu-item-submenu-image-left;
  background-repeat: $rkt-menu-item-submenu-image-background-repeat;
  opacity: $rkt-menu-item-submenu-image-disabled-opacity;
}

.rkt-Menu .rkt-Menu__item.mat-mdc-menu-item.cdk-keyboard-focused:not([disabled]) {
  background: $rkt-menu-item-focus-visible-background-color;
}

// @deprecated in v4, removal in v6 task #3532785
// Ensures the font util works (no tokens needed)
.rkt-Menu__item.mat-menu-item.rkt-Body-4,
.rkt-Menu__item.mat-menu-item.rkt-Label-14 {
  color: $rkt-typography-color-label-14;
  font-family: $rkt-typography-font-family-label-14;
  font-size: $rkt-typography-font-size-label-14;
  font-weight: $rkt-typography-font-weight-label-14;
}

// @deprecated in v4, removal in v6 task #3532785
// Ensures the color util works (no tokens needed)
.rkt-Menu__item.mat-menu-item.rkt-Color--gray-600 {
  color: $rkt-gray-600;
}

.rkt-Menu__item.rkt-Menu__item--is-disabled,
.rkt-Menu__item.rkt-Menu__item--is-disabled:hover {
  background-color: $rkt-menu-item-disabled-background-color;
  padding: $rkt-menu-item-padding;

  .rkt-Menu__item-text,
  .rkt-Icon,
  &.mat-mdc-menu-item .mat-icon {
    color: $rkt-menu-item-disabled-color;
  }

  .rkt-Icon {
    opacity: $rkt-menu-item-disabled-icon-opacity;
  }

  .rkt-Tag {
    opacity: $rkt-menu-item-disabled-tag-opacity;
  }
}


.rkt-Menu__item--is-disabled.Mui-disabled,
.rkt-Menu .rkt-Menu__item--is-disabled.mat-mdc-menu-item:hover,
.rkt-Menu .rkt-Menu__item--is-disabled.mat-mdc-menu-item {
  opacity: $rkt-menu-item-disabled-opacity;
}

.rkt-Menu--nav.mat-mdc-menu-panel.mat-mdc-menu-panel {
  box-shadow: $rkt-menu-nav-elevation;
}

.rkt-Menu--nav .rkt-Menu__item .rkt-Menu__item-text {
  color: $rkt-menu-nav-item-text-color;
  font-size: $rkt-menu-nav-item-text-font-size;
}

.rkt-Menu--nav .rkt-Menu__item .mat-mdc-menu-submenu-icon {
  fill: $rkt-menu-nav-item-icon-fill;
}

.rkt-Menu--nav .rkt-Menu__item.rkt-Menu__item--is-active .rkt-Menu__item-text {
  color: $rkt-menu-nav-item-text-active-color;
}

.rkt-Menu--nav .rkt-Menu__item:hover .rkt-Menu__item-text {
  color: $rkt-menu-nav-link-hover-color;
}

// For nav menus where we want active item styles
.rkt-Menu--nav .rkt-Menu__item--is-active.mat-menu-item,
.rkt-Menu--nav .rkt-Menu__item--is-active {
  border-left: $rkt-menu-nav-active-border-left;

  &:hover {
    color: $rkt-menu-nav-hover-active-color;
  }
}

.rkt-Menu--nav .rkt-Menu .mat-menu-submenu-icon {
  color: $rkt-menu-nav-icon-color;
}

.rkt-Menu--nav .rkt-Menu__item.mat-menu-item {
  display: flex;
  align-items: center;

  &:hover {
    color: $rkt-menu-nav-hover-color;
  }
}

// React specific masthead navigation menu styles
.rkt-Menu--nav {
  &.MuiPaper-root {
    min-width: $rkt-menu-nav-min-width;
  }
  .rkt-Menu__item:hover .MuiTypography-root {
    color: $rkt-menu-nav-hover-color;
  }
}

.rkt-Menu .rkt-Menu__item--notification {
  display: $rkt-menu-notification-item-display;
  height: $rkt-menu-notification-item-height;
  align-items: $rkt-menu-notification-item-align-items;
  padding-top: $rkt-menu-notification-item-padding-top;
  padding-bottom: $rkt-menu-notification-item-padding-bottom;
}

.rkt-Menu__item-notification-icon,
.mat-icon.rkt-Menu__item-notification-icon,
.mat-icon.rkt-Menu__item-notification-icon.mdc-list-item__start,
.rkt-List .mat-mdc-list-item:hover .rkt-Menu__item-notification-icon,
.rkt-Menu__item--notification .rkt-Menu__item-notification-icon.mat-icon {
  color: $rkt-menu-item-notification-icon-color;
  border-radius: $rkt-menu-item-notification-icon-background-color-border-radius;
  flex-shrink: $rkt-menu-item-notification-icon-flex-shrink;
  margin-right: $rkt-menu-item-notification-icon-margin-right;
}

// Angular specific - added this because the util doesn't override the specific style
.mat-list-item .mat-list-icon.rkt-Menu__item-notification-icon.rkt-pa2 {
  padding: $rkt-menu-item-notification-icon-padding;
}

.rkt-Menu .rkt-Menu__item-description {
  border-bottom: $rkt-menu-notification-item-border-bottom;
  padding-bottom: $rkt-menu-notification-item-padding-bottom;
}

.rkt-Menu__item.rkt-Opacity--1,
// below line is to insure our styles are more specific over mui
.rkt-Menu__item.rkt-Opacity--1.Mui-disabled,
.MuiListItem-root.Mui-disabled.rkt-Opacity--1 {
  opacity: 1;
}
// below line is to insure our styles are more specific over mui
.rkt-Menu__item.rkt-BackgroundColor--red-50 {
  background-color: $rkt-red-50;
}

// These are the color classes currently available for the menu/bottom sheet icons
// Teams can add additional custom colors by writing a new style selector
.rkt-Menu__item-notification-icon.rkt-Menu__item-notification-icon--red-400 {
  background-color: $rkt-red-400;
}

.rkt-Menu__item-notification-icon.rkt-Menu__item-notification-icon--green-600 {
  background-color: $rkt-green-600;
}

.rkt-Menu__item-notification-icon.rkt-Menu__item-notification-icon--blue-600 {
  background-color: $rkt-blue-600;
}

.rkt-Menu__item-notification-icon.rkt-Menu__item-notification-icon--blue-900 {
  background-color: $rkt-blue-900;
}

.rkt-Menu__item-notification-icon.rkt-Menu__item-notification-icon--yellow-500 {
  background-color: $rkt-yellow-500;
}


// "UNREAD" or "READ" element container
.rkt-Menu__notification-status,
.rkt-Menu__notification-status.MuiMenuItem-root,
.rkt-Menu__notification-status.MuiListItem-root {
  background-color: $rkt-menu-notification-status-background-color;
  padding: $rkt-menu-notification-status-padding;
}

// MUI overrides, without them the notification status isnt visible
// so we hard-code with no token
.rkt-Menu__notification-status.Mui-disabled.MuiMenuItem-root,
.rkt-Menu__notification-status.Mui-disabled.MuiListItem-root {
  opacity: 1;
  height: auto;
}

.rkt-Menu__notification-status-message {
  color: $rkt-menu-notification-status-message-color;
  font-family: $rkt-menu-notification-status-message-font-family;
  font-size: $rkt-menu-notification-status-message-font-size;
  font-weight: $rkt-menu-notification-status-message-font-weight;
  line-height: $rkt-menu-notification-status-message-line-height;
  letter-spacing: $rkt-menu-notification-status-message-letter-spacing;
  text-transform: $rkt-menu-notification-status-message-text-transform;
  margin: $rkt-menu-notification-status-message-margin;
}

.rkt-Menu__item-subtext {
  color: $rkt-menu-item-subtext-color;
  font-family: $rkt-menu-item-subtext-font-family;
  font-size: $rkt-menu-item-subtext-font-size;
  font-weight: $rkt-menu-item-subtext-font-weight;
  line-height: $rkt-menu-item-subtext-line-height;
  margin: $rkt-menu-item-subtext-margin;
}