
$rkt-red-50: #fee7e7 !default;
$rkt-red-100: #ffcace !default;
$rkt-red-200: #ffacb3 !default;
$rkt-red-300: #f1878f !default;
$rkt-red-400: #e95d68 !default;
$rkt-red-500: #de3341 !default;
$rkt-red-600: #c12a36 !default;
$rkt-red-700: #a3202b !default;
$rkt-red-800: #84161f !default;
$rkt-red-900: #610b12 !default;
$rkt-yellow-50: #fff5e4 !default;
$rkt-yellow-100: #ffe5b8 !default;
$rkt-yellow-200: #fed48d !default;
$rkt-yellow-300: #fecc74 !default;
$rkt-yellow-400: #ffbf51 !default;
$rkt-yellow-500: #d3962d !default;
$rkt-yellow-600: #a56a06 !default;
$rkt-yellow-700: #865404 !default;
$rkt-yellow-800: #6c4103 !default;
$rkt-yellow-900: #492800 !default;
$rkt-blue-50: #edf1ff !default;
$rkt-blue-100: #d2d9f4 !default;
$rkt-blue-200: #adc6f6 !default;
$rkt-blue-300: #95b9fc !default;
$rkt-blue-400: #7392fc !default;
$rkt-blue-500: #5e79d9 !default;
$rkt-blue-600: #4156a1 !default;
$rkt-blue-700: #2a396f !default;
$rkt-blue-800: #293662 !default;
$rkt-blue-900: #21273d !default;
$rkt-green-50: #eff7f3 !default;
$rkt-green-100: #b5ddc6 !default;
$rkt-green-200: #94cfad !default;
$rkt-green-300: #76c196 !default;
$rkt-green-400: #51b179 !default;
$rkt-green-500: #2fa25f !default;
$rkt-green-600: #0a9142 !default;
$rkt-green-700: #0b5e36 !default;
$rkt-green-800: #094a2b !default;
$rkt-green-900: #06361f !default;
$rkt-gray-50: #f7f7f7 !default;
$rkt-gray-100: #f2f2f2 !default;
$rkt-gray-200: #e9e9e9 !default;
$rkt-gray-300: #c1c1c1 !default;
$rkt-gray-400: #949494 !default;
$rkt-gray-500: #7b7b7b !default;
$rkt-gray-600: #545454 !default;
$rkt-gray-700: #2b2b2b !default;
$rkt-gray-800: #111111 !default;
$rkt-gray-900: #050505 !default;
$rkt-white: #ffffff !default;
$rkt-black-opacity-15: rgba(0, 0, 0, 0.15) !default;
$rkt-black-opacity-50: rgba(0, 0, 0, 0.5) !default;
$rkt-black-opacity-04: rgba(0, 0, 0, 0.04) !default;
$rkt-black-opacity-08: rgba(0, 0, 0, 0.08) !default;
$rkt-white-opacity-50: rgba(255, 255, 255, 0.05) !default;
$rkt-white-opacity-100: rgba(255, 255, 255, 0.1) !default;
$rkt-white-opacity-200: rgba(255, 255, 255, 0.15) !default;
$rkt-white-opacity-300: rgba(255, 255, 255, 0.2) !default;
$rkt-white-opacity-400: rgba(255, 255, 255, 0.3) !default;
$rkt-white-opacity-500: rgba(255, 255, 255, 0.4) !default;
$rkt-white-opacity-600: rgba(255, 255, 255, 0.5) !default;
$rkt-white-opacity-700: rgba(255, 255, 255, 0.6) !default;
$rkt-black: #000000 !default;
$rkt-rose-500: #d81b60 !default;
$rkt-tangerine-50: #fff2d7 !default;
$rkt-tangerine-500: #dd781b !default;
$rkt-data-orange-50: #f4e4da !default;
$rkt-data-orange-100: #fdbc96 !default;
$rkt-data-orange-400: #ff9e66 !default;
$rkt-data-orange-600: #d27540 !default;
$rkt-data-orange-900: #732612 !default;
$rkt-data-orange-05-opacity: rgba(115, 38, 18, 0.05) !default;
$rkt-data-blue-50: #edf1ff !default;
$rkt-data-blue-100: #d2d9f4 !default;
$rkt-data-blue-200: #adc6f6 !default;
$rkt-data-blue-400: #7392fc !default;
$rkt-data-blue-900: #21273d !default;
$rkt-data-blue-05-opacity: rgba(41, 54, 98, 0.05) !default;
$rkt-data-purple-50: #fbf0f6 !default;
$rkt-data-purple-100: #f1dbe7 !default;
$rkt-data-purple-400: #f099c7 !default;
$rkt-data-purple-600: #9e3f71 !default;
$rkt-data-purple-900: #441227 !default;
$rkt-data-purple-05-opacity: rgba(68, 18, 39, 0.05) !default;
$rkt-data-green-50: #f0eed9 !default;
$rkt-data-green-100: #dfddc3 !default;
$rkt-data-green-400: #cbc996 !default;
$rkt-data-green-600: #939164 !default;
$rkt-data-green-900: #373620 !default;
$rkt-data-green-25-opacity: rgba(229, 227, 201, 0.25) !default;

$rkt-color-map: (
  'red': (
    '50': $rkt-red-50,
    '100': $rkt-red-100,
    '200': $rkt-red-200,
    '300': $rkt-red-300,
    '400': $rkt-red-400,
    '500': $rkt-red-500,
    '600': $rkt-red-600,
    '700': $rkt-red-700,
    '800': $rkt-red-800,
    '900': $rkt-red-900
  ),
  'yellow': (
    '50': $rkt-yellow-50,
    '100': $rkt-yellow-100,
    '200': $rkt-yellow-200,
    '300': $rkt-yellow-300,
    '400': $rkt-yellow-400,
    '500': $rkt-yellow-500,
    '600': $rkt-yellow-600,
    '700': $rkt-yellow-700,
    '800': $rkt-yellow-800,
    '900': $rkt-yellow-900
  ),
  'blue': (
    '50': $rkt-blue-50,
    '100': $rkt-blue-100,
    '200': $rkt-blue-200,
    '300': $rkt-blue-300,
    '400': $rkt-blue-400,
    '500': $rkt-blue-500,
    '600': $rkt-blue-600,
    '700': $rkt-blue-700,
    '800': $rkt-blue-800,
    '900': $rkt-blue-900
  ),
  'green': (
    '50': $rkt-green-50,
    '100': $rkt-green-100,
    '200': $rkt-green-200,
    '300': $rkt-green-300,
    '400': $rkt-green-400,
    '500': $rkt-green-500,
    '600': $rkt-green-600,
    '700': $rkt-green-700,
    '800': $rkt-green-800,
    '900': $rkt-green-900
  ),
  'gray': (
    '50': $rkt-gray-50,
    '100': $rkt-gray-100,
    '200': $rkt-gray-200,
    '300': $rkt-gray-300,
    '400': $rkt-gray-400,
    '500': $rkt-gray-500,
    '600': $rkt-gray-600,
    '700': $rkt-gray-700,
    '800': $rkt-gray-800,
    '900': $rkt-gray-900
  ),
  'white': $rkt-white,
  'black-opacity': (
    '15': $rkt-black-opacity-15,
    '50': $rkt-black-opacity-50,
    '04': $rkt-black-opacity-04,
    '08': $rkt-black-opacity-08
  ),
  'white-opacity': (
    '50': $rkt-white-opacity-50,
    '100': $rkt-white-opacity-100,
    '200': $rkt-white-opacity-200,
    '300': $rkt-white-opacity-300,
    '400': $rkt-white-opacity-400,
    '500': $rkt-white-opacity-500,
    '600': $rkt-white-opacity-600,
    '700': $rkt-white-opacity-700
  ),
  'black': $rkt-black,
  'rose': (
    '500': $rkt-rose-500
  ),
  'tangerine': (
    '50': $rkt-tangerine-50,
    '500': $rkt-tangerine-500
  ),
  'data-orange': (
    '50': $rkt-data-orange-50,
    '100': $rkt-data-orange-100,
    '400': $rkt-data-orange-400,
    '600': $rkt-data-orange-600,
    '900': $rkt-data-orange-900,
    '05-opacity': $rkt-data-orange-05-opacity
  ),
  'data-blue': (
    '50': $rkt-data-blue-50,
    '100': $rkt-data-blue-100,
    '200': $rkt-data-blue-200,
    '400': $rkt-data-blue-400,
    '900': $rkt-data-blue-900,
    '05-opacity': $rkt-data-blue-05-opacity
  ),
  'data-purple': (
    '50': $rkt-data-purple-50,
    '100': $rkt-data-purple-100,
    '400': $rkt-data-purple-400,
    '600': $rkt-data-purple-600,
    '900': $rkt-data-purple-900,
    '05-opacity': $rkt-data-purple-05-opacity
  ),
  'data-green': (
    '50': $rkt-data-green-50,
    '100': $rkt-data-green-100,
    '400': $rkt-data-green-400,
    '600': $rkt-data-green-600,
    '900': $rkt-data-green-900,
    '25-opacity': $rkt-data-green-25-opacity
  )
);
