@use '../web/scss/settings' as *;

.rkt-FormField .mat-mdc-text-field-wrapper:has(.rkt-Input--datepicker),
.mat-datepicker-content:has(.rkt-Datepicker) .mat-calendar,
.rkt-FormField .rkt-Input--datepicker .MuiInputBase-root {
  min-width: $rkt-datepicker-min-width;
  height: $rkt-datepicker-height;
}

.rkt-Datepicker.MuiDateCalendar-root,
.rkt-Datepicker .MuiDateCalendar-root {
  max-height:  $rkt-datepicker-max-height;
  height: $rkt-datepicker-calendar-height;
}

.mat-datepicker-popup:has(.rkt-Datepicker),
.rkt-Datepicker.MuiPaper-root {
  margin-top: $rkt-datepicker-margin-top;

  .mat-mdc-button-persistent-ripple {
    display: $rkt-datepicker-ripple-display;
  }
}

.mat-datepicker-content:has(.rkt-Datepicker),
.rkt-Datepicker.MuiPaper-root {
  border-radius: $rkt-datepicker-border-radius;
  border: $rkt-datepicker-border;
  box-shadow: $rkt-datepicker-box-shadow;
  background-color: $rkt-datepicker-bg-color;
}

.rkt-FormField:has(.rkt-Input--datepicker) .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.rkt-FormField:has(.rkt-Input--datepicker) .mat-mdc-icon-button .mat-mdc-button-persistent-ripple::before {
  opacity: $rkt-datepicker-ripple-opacity;
}

.rkt-FormField:has(.rkt-Input--datepicker) .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple {
  background-color: $rkt-datepicker-icon-hover-ripple-bg-color;
  z-index: $rkt-datepicker-icon-hover-ripple-z-index;
}

.rkt-FormField:has(.rkt-Input--datepicker) .MuiButtonBase-root {
  height: $rkt-datepicker-icon-height;
  width: $rkt-datepicker-icon-width;
}

.rkt-FormField:has(.rkt-Input--datepicker) .MuiButtonBase-root:hover,
.rkt-Datepicker .MuiPickersYear-yearButton:hover {
  background-color: $rkt-datepicker-button-hover-bg-color;
}

.rkt-Datepicker .MuiPickersDay-root:focus,
.rkt-Datepicker .MuiPickersYear-yearButton:focus {
  background-color: $rkt-datepicker-button-focus-bg-color;
}

.rkt-FormField:has(.rkt-Input--datepicker) .mat-mdc-icon-button.cdk-keyboard-focused,
.rkt-FormField:has(.rkt-Input--datepicker) .MuiButtonBase-root.Mui-focusVisible,
.rkt-Datepicker .MuiPickersYear-yearButton:focus-visible {
  box-shadow: $rkt-datepicker-button-focus-box-shadow;
  background-color: $rkt-datepicker-button-focus-bg-color;

  .MuiTouchRipple-root {
    display: $rkt-datepicker-ripple-display;
  }
}

.rkt-Datepicker .mat-calendar-table-header-divider,
.rkt-Datepicker tr:has(.mat-calendar-body-label):not(:has(.mat-calendar-body-cell-container)):first-of-type,
.rkt-Datepicker .mat-calendar-controls .mat-calendar-spacer {
  display: $rkt-datepicker-divider-display;
}

.rkt-Datepicker tr:has(.mat-calendar-body-label, .mat-calendar-body-cell-container):first-of-type .mat-calendar-body-label {
  opacity: $rkt-datepicker-label-opacity;
}

.rkt-Datepicker .mat-calendar-header {
  padding: $rkt-datepicker-header-padding;
  margin: $rkt-datepicker-header-margin;
}

.rkt-Datepicker .MuiPickersCalendarHeader-root {
  margin: $rkt-datepicker-header-margin;
  padding: $rkt-datepicker-calendar-header-padding;
  justify-content: $rkt-datepicker-header-justify-content;
}

.rkt-Datepicker .mat-calendar-table-header th,
.rkt-Datepicker .MuiDayCalendar-weekDayLabel {
  color: $rkt-datepicker-weekday-label-color;
  font-size: $rkt-datepicker-weekday-label-font-size;
  font-weight: $rkt-datepicker-weekday-label-font-weight;
  height: $rkt-datepicker-weekday-label-height;
  width: $rkt-datepicker-weekday-label-width;
  padding: $rkt-datepicker-weekday-label-padding;
}

.rkt-Datepicker .mat-calendar-controls {
  margin: $rkt-datepicker-controls-margin;
  justify-content: $rkt-datepicker-controls-justify-content;
}

.rkt-Datepicker .mat-calendar-controls .mat-calendar-period-button {
  margin: $rkt-datepicker-controls-margin;

  &:hover {
    background-color: $rkt-datepicker-controls-hover-bg-color;
  }

  &:focus-visible {
    box-shadow: $rkt-datepicker-controls-focus-box-shadow;
    background-color: $rkt-datepicker-button-focus-bg-color;

    .mat-mdc-button-persistent-ripple {
      display: $rkt-datepicker-ripple-display;
    }
  }
}

.rkt-Datepicker .mat-calendar-controls .mdc-button__label {
  text-transform: $rkt-datepicker-controls-text-transform;
  color: $rkt-datepicker-controls-color;
  font-size: $rkt-datepicker-controls-font-size;
  font-weight: $rkt-datepicker-controls-font-weight;

  .mat-calendar-arrow {
    fill: $rkt-datepicker-controls-icon-fill;
    width: $rkt-datepicker-controls-icon-width;
    margin: $rkt-datepicker-controls-icon-margin;
  }
}

.rkt-Datepicker .mat-calendar-controls .mdc-button__label span {
  display: $rkt-datepicker-controls-label-display;
  padding-right: $rkt-datepicker-controls-label-padding-right;

  &::first-letter {
    text-transform: $rkt-datepicker-controls-label-first-letter-text-transform;
  }
}

.rkt-Datepicker .mat-calendar-controls .mat-calendar-previous-button,
.rkt-Datepicker .mat-calendar-controls .mat-calendar-next-button,
.rkt-Datepicker .MuiPickersCalendarHeader-switchViewButton,
.rkt-Datepicker .MuiPickersArrowSwitcher-button {
  color: $rkt-datepicker-controls-arrow-color;

  &:hover {
    background-color: $rkt-datepicker-controls-arrow-hover-bg-color;
  }

  &:focus-visible,
  &.Mui-focusVisible {
    box-shadow: $rkt-datepicker-controls-arrow-focus-box-shadow;

    .MuiTouchRipple-root,
    .mat-mdc-button-persistent-ripple {
      display: $rkt-datepicker-ripple-display;
    }
  }
}

.rkt-Datepicker .mat-calendar-controls .mat-calendar-previous-button,
.rkt-Datepicker .MuiPickersArrowSwitcher-root .MuiPickersArrowSwitcher-button:first-of-type {
  order: $rkt-datepicker-controls-arrow-order;
}

.rkt-Datepicker .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.rkt-Datepicker .MuiPickersDay-today {
  border: $rkt-datepicker-current-day-border;
}

.rkt-Datepicker .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical), 
.rkt-Datepicker .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: $rkt-datepicker-day-focus-bg-color;
}

.rkt-Datepicker .mat-calendar-content {
  padding: $rkt-datepicker-content-padding;
}

.rkt-Datepicker .mat-calendar-body-cell-content,
.rkt-Datepicker .MuiPickersYear-yearButton,
.rkt-Datepicker .MuiPickersDay-root {
  color: $rkt-datepicker-day-color;
  font-size: $rkt-datepicker-day-font-size;
  font-weight: $rkt-datepicker-day-font-weight;
  transition: $rkt-datepicker-day-transition;
}

.rkt-Datepicker .MuiPickersDay-root {
  height: $rkt-datepicker-day-height;
  width: $rkt-datepicker-day-width;
  margin: $rkt-datepicker-day-margin;
}

.rkt-Datepicker .mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.rkt-Datepicker .MuiPickersDay-root:hover {
  background-color: $rkt-datepicker-day-hover-bg-color;
}

.rkt-Datepicker .mat-calendar-body-cell-container:focus-visible,
.rkt-Datepicker .MuiPickersDay-root:focus-visible {
  outline: $rkt-datepicker-day-focus-outline;
}

.rkt-Datepicker .mat-calendar-body-selected,
.rkt-Datepicker .MuiPickersDay-root.Mui-selected,
.rkt-Datepicker .MuiPickersYear-yearButton.Mui-selected {
  color: $rkt-datepicker-selected-color;
  background-color: $rkt-datepicker-selected-bg-color;
  border: $rkt-datepicker-selected-border;
}

.rkt-Datepicker .mat-calendar-body-selected {
  box-shadow: $rkt-datepicker-selected-box-shadow;
}

.rkt-Datepicker .mat-calendar-body-cell:focus-visible:not(.mat-calendar-body-disabled) .mat-calendar-body-cell-content:not(.mat-calendar-body-selected),
.rkt-Datepicker .MuiPickersDay-root.Mui-focusVisible {
  background-color: $rkt-datepicker-day-focus-bg-color;
  color: $rkt-datepicker-day-focus-color;
  box-shadow: $rkt-datepicker-day-focus-box-shadow;
}

.rkt-Datepicker .mat-datepicker-content .mat-calendar {
  height: $rkt-datepicker-content-height;
}

.rkt-Datepicker .mat-calendar-body-in-preview {
  color: $rkt-datepicker-preview-color;
}

.rkt-Datepicker .mat-calendar-body-in-preview::before {
  background: $rkt-datepicker-preview-background;
}

.rkt-Datepicker .mat-calendar-body-preview-start::before {
  border-top-left-radius: $rkt-datepicker-preview-start-border-top-left-radius;
  border-bottom-left-radius: $rkt-datepicker-preview-start-border-bottom-left-radius;
  left: $rkt-datepicker-preview-start-left;
  width: $rkt-datepicker-preview-start-width;
}

.rkt-Datepicker .mat-calendar-body-preview-end::before {
  border-top-right-radius: $rkt-datepicker-preview-end-border-top-right-radius;
  border-bottom-right-radius: $rkt-datepicker-preview-end-border-bottom-right-radius;
  width: $rkt-datepicker-preview-end-width;
}

.rkt-Datepicker .mat-calendar-body-in-range::before {
  background: $rkt-datepicker-in-range-background;
}

.rkt-Card:has(.rkt-Datepicker),
.rkt-Datepicker.MuiDateCalendar-root {
  width: $rkt-datepicker-standalone-width;
  padding: $rkt-datepicker-standalone-padding;
  box-shadow: $rkt-datepicker-standalone-box-shadow;
  border-radius: $rkt-datepicker-standalone-border-radius;
  border: $rkt-datepicker-standalone-border;
  background-color: $rkt-datepicker-standalone-bg-color;

  @media all and (min-width: $rkt-datepicker-standalone-breakpoint) {
    width: $rkt-datepicker-standalone-wide-width;
  }

  .rkt-Datepicker .mat-calendar-header {
    padding: $rkt-datepicker-standalone-header-padding;
  }

  .MuiPickersCalendarHeader-root {
    padding: $rkt-datepicker-standalone-calendar-header-padding;
  }

  .rkt-Datepicker .mat-calendar-content {
    padding: $rkt-datepicker-standalone-content-padding;
  }

  .MuiDayCalendar-root {
    padding: $rkt-datepicker-standalone-day-calendar-padding;
  }

  .MuiDayCalendar-root {
    padding: $rkt-datepicker-day-calendar-padding;
  }
}

.rkt-FormField .rkt-Input--datepicker .MuiInputAdornment-positionEnd {
  padding-right: $rkt-datepicker-icon-padding-right;
}

.rkt-Datepicker--inline .MuiPickersDay-root {
  height: $rkt-datepicker-inline-day-height;
  margin: $rkt-datepicker-inline-day-margin;

  @media all and (min-width: $rkt-datepicker-standalone-breakpoint) {
    margin: $rkt-datepicker-inline-day-breakpoint-margin;
  }
}

.rkt-Datepicker--inline.MuiDayCalendar-header {
  margin: $rkt-datepicker-inline-header-margin;

  @media all and (min-width: $rkt-datepicker-standalone-breakpoint) {
    margin: $rkt-datepicker-inline-header-breakpoint-margin;
  }
}


.rkt-Datepicker--inline.MuiDateCalendar-root .MuiDayCalendar-root {
  padding-bottom: $rkt-datepicker-inline-padding;
}

.rkt-Datepicker .MuiPickersDay-root .MuiTouchRipple-root {
  display: $rkt-datepicker-ripple-display;
}

.rkt-FormField .rkt-Input--datepicker .MuiInputBase-root .MuiSvgIcon-root,
.rkt-FormField:has(.rkt-Input--datepicker) .mat-datepicker-toggle .rkt-FormField__suffix  {
  position: $rkt-datepicker-icon-position;
  top: $rkt-datepicker-icon-top;
  padding: $rkt-datepicker-icon-padding;
}