@use '../web/scss/settings' as *;

.rkt-Fab,
.rkt-Fab.mat-mdc-fab {
  background-color: $rkt-fab-background-color;
  height: $rkt-fab-button-height;
  width: $rkt-fab-button-width;
  box-shadow: $rkt-fab-box-shadow;
  --mat-mdc-button-persistent-ripple-color: $rkt-fab-ripple-color;

  .rkt-Icon {
    color: $rkt-fab-icon-color;
  }
  
  &:hover:not(:active, :focus-visible, :focus) {
    background-color: $rkt-fab-hover-background-color;
    border: $rkt-fab-hover-border;
    box-shadow: $rkt-fab-hover-box-shadow;
  }

  &:focus-visible {
    background-color: $rkt-fab-focus-visible-background-color;
    border: $rkt-fab-focus-visible-border;
    box-shadow: $rkt-fab-focus-visible-box-shadow;
  }

  &:active,
  &:focus:active {
    background-color: $rkt-fab-active-background-color;
    box-shadow: $rkt-fab-active-box-shadow;

    .rkt-Icon {
      color: $rkt-fab-icon-active-color;
    }
  }

  &:focus {
    background-color: $rkt-fab-focus-background-color;
    box-shadow: $rkt-fab-focus-box-shadow;
  }

  .mat-ripple,
  .MuiTouchRipple-root {
    display: $rkt-fab-ripple-display;
  }
}