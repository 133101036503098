@use '../web/scss/settings';

.rkt-Slider {
  color: settings.$rkt-slider-color;
}

.rkt-Slider.mat-mdc-slider {
  width: settings.$rkt-slider-width;
}

.rkt-Slider.MuiSlider-root {
  padding: settings.$rkt-slider-padding;
}

.rkt-Slider.MuiSlider-root.rkt-Slider--small {
  padding: settings.$rkt-slider-small-padding;
}

.rkt-Slider__label-container {
  display: settings.$rkt-slider-label-container-display;
  justify-content: settings.$rkt-slider-label-container-justify-content;
}

.rkt-Slider.mat-mdc-slider + .rkt-Slider__label-container {
  padding: settings.$rkt-slider-label-container-padding;
}

.rkt-Slider.MuiSlider-root + .rkt-Slider__label-container {
  margin-top: settings.$rkt-slider-label-container-margin-top;
}

.rkt-Slider.MuiSlider-root.rkt-Slider--small + .rkt-Slider__label-container {
  margin-top: settings.$rkt-slider-small-label-container-content-margin-top;
}

.rkt-Slider.mat-mdc-slider.rkt-Slider--small + .rkt-Slider__label-container {
  margin-top: settings.$rkt-slider-small-label-container-margin-top;
}

.rkt-Slider.mat-mdc-slider .mdc-slider__value-indicator::before {
  border-top-color: settings.$rkt-slider-value-indicator-border-top-color;
  bottom: settings.$rkt-slider-value-indicator-bottom;
  border-top-width: settings.$rkt-slider-value-indicator-border-top-width;
}

.rkt-Slider .MuiSlider-valueLabelOpen {
  top: settings.$rkt-slider-value-indicator-open-top;
}

.rkt-Slider.mat-mdc-slider .mdc-slider__value-indicator-container {
  left: settings.$rkt-slider-value-indicator-left;
  z-index: settings.$rkt-slider-value-indicator-z-index;
}

.rkt-Slider.mat-mdc-slider .mdc-slider__value-indicator,
.rkt-Slider .MuiSlider-valueLabel {
  background-color: settings.$rkt-slider-value-indicator-background-color;
  font-size: settings.$rkt-slider-value-indicator-font-size;
  justify-content: settings.$rkt-slider-value-indicator-justify-content;
  opacity: settings.$rkt-slider-value-indicator-opacity;
  padding: settings.$rkt-slider-value-indicator-padding;
  border-radius: settings.$rkt-slider-value-indicator-border-radius;
  height: settings.$rkt-slider-value-indicator-height;
  line-height: settings.$rkt-slider-value-indicator-line-height;
}

.rkt-Slider .MuiSlider-valueLabelLabel,
.rkt-Slider.mat-mdc-slider .mdc-slider__value-indicator-text {
  color: settings.$rkt-slider-handle-label-color;
  font-size: settings.$rkt-slider-handle-label-font-size;
  font-weight: settings.$rkt-slider-handle-label-font-weight;
  line-height: settings.$rkt-slider-handle-label-line-height;
}

.rkt-Slider .MuiSlider-thumb,
.rkt-Slider.mat-mdc-slider .mdc-slider__thumb-knob,
.rkt-Slider.mat-mdc-slider .mdc-slider__thumb--focused .mdc-slider__thumb-knob,
.rkt-Slider.mat-mdc-slider .mdc-slider__thumb:hover .mdc-slider__thumb-knob {
  background-color: settings.$rkt-slider-handle-background-color;
  border-color: settings.$rkt-slider-handle-border-color;
  border-width: settings.$rkt-slider-handle-border-width;
  border-style: settings.$rkt-slider-handle-border-style;
  box-shadow: settings.$rkt-slider-handle-box-shadow;
}

.rkt-Slider .MuiSlider-thumb,
.rkt-Slider.mat-mdc-slider .mdc-slider__thumb-knob {
  width: settings.$rkt-slider-handle-width;
  height: settings.$rkt-slider-handle-height;
  filter: settings.$rkt-slider-handle-filter;
  flex-shrink: settings.$rkt-slider-handle-flex-shrink;
}

.rkt-Slider.mat-mdc-slider .mdc-slider__thumb-knob,
.rkt-Slider .MuiSlider-thumb::before,
.rkt-Slider .MuiSlider-thumb,
.rkt-Slider .MuiSlider-valueLabel::before {
  box-shadow: settings.$rkt-slider-handle-box-shadow;
}

.rkt-Slider.mat-mdc-slider .mdc-slider__thumb-knob {
  left: settings.$rkt-slider-handle-width;
  top: settings.$rkt-slider-handle-top;
  transform: settings.$rkt-slider-handle-transform;
  border-width: settings.$rkt-slider-handle-border-width;
}

.rkt-Slider.mat-mdc-slider .mdc-slider__track--active {
  border-bottom-right-radius: settings.$rkt-slider-track-border-bottom-right-radius;
  border-top-right-radius: settings.$rkt-slider-track-border-top-right-radius;
}

.rkt-Slider.mat-mdc-slider .mdc-slider__track,
.rkt-Slider.mat-mdc-slider .mdc-slider__track--inactive,
.rkt-Slider.mat-mdc-slider .mdc-slider__track--active,
.rkt-Slider {
  height: settings.$rkt-slider-track-height;
}

.rkt-Slider.mat-mdc-slider .mdc-slider__track--active_fill {
  border-top-width: settings.$rkt-slider-track-height;
}

.rkt-Slider .MuiSlider-track {
  background-color: settings.$rkt-slider-track-background-color;
  border-radius: settings.$rkt-slider-track-border-radius;
}

.rkt-Slider.mat-mdc-slider .mdc-slider__track--active_fill {
  border-color: settings.$rkt-slider-track-border-color;
}

.rkt-Slider .MuiSlider-track {
  border: settings.$rkt-slider-track-border;
}

.rkt-Slider .MuiSlider-track + .MuiSlider-mark.MuiSlider-markActive {
  margin-left: settings.$rkt-slider-mark-active-margin-left;
}

.rkt-Slider.mat-mdc-slider .mdc-slider__track--active {
  top: settings.$rkt-slider-track-active-top;
}

.rkt-Slider.mat-mdc-slider.mdc-slider--disabled {
  opacity: settings.$rkt-slider-disabled-opacity;
}

.rkt-Slider.mat-mdc-slider.mdc-slider--disabled .mdc-slider__input {
  pointer-events: settings.$rkt-slider-disabled-pointer-events;
}

.rkt-Slider.mat-mdc-slider.mdc-slider--disabled .mdc-slider__track--inactive,
 .rkt-SliderMui-disabled .MuiSlider-rail {
  background-color: settings.$rkt-slider-track-inactive-background-color;
  opacity: settings.$rkt-slider-track-inactive-opacity;
 }

.rkt-Slider.mat-mdc-slider .mat-mdc-slider-visual-thumb .mat-ripple {
  display: settings.$rkt-slider-thumb-ripple-display;
}

.rkt-Slider.mat-mdc-slider.mdc-slider--discrete {
  top: settings.$rkt-slider-thumb-label-top;
}

.rkt-Slider.mat-mdc-slider .mdc-slider__track--inactive,
.rkt-Slider .MuiSlider-rail {
  background-color: settings.$rkt-slider-track-color;
  opacity: settings.$rkt-slider-track-opacity;
}

.rkt-Slider .MuiSlider-mark,
.rkt-Slider.mat-mdc-slider .mdc-slider__tick-mark--inactive,
.rkt-Slider.mat-mdc-slider .mdc-slider__tick-mark--active {
  border-radius: settings.$rkt-slider-mark-border-radius;
  height: settings.$rkt-slider-mark-height;
  opacity: settings.$rkt-slider-mark-opacity;
  width: settings.$rkt-slider-mark-width;
}

.rkt-Slider--small .MuiSlider-mark,
.rkt-Slider--small.mat-mdc-slider .mdc-slider__tick-mark--inactive,
.rkt-Slider--small.mat-mdc-slider .mdc-slider__tick-mark--active {
  height: settings.$rkt-slider-small-mark-height;
  width: settings.$rkt-slider-small-mark-width;
}

.rkt-Slider .MuiSlider-mark:nth-last-child(2) {
  margin-left: settings.$rkt-slider-mark-margin-left;
}

.rkt-Slider.mdc-slider .mdc-slider__thumb {
  left: settings.$rkt-slider-handle-left;
}

.rkt-Slider .mdc-slider__tick-marks .mdc-slider__tick-mark--inactive:last-child {
  left: settings.$rkt-slider-mark-left;
}

.rkt-Slider .MuiSlider-mark,
.rkt-Slider.mat-mdc-slider .mdc-slider__tick-mark--inactive {
  background-color: settings.$rkt-slider-mark-background-color;
}

.rkt-Slider--small .MuiSlider-mark,
.rkt-Slider--small.mat-mdc-slider .mdc-slider__tick-mark--inactive {
  background-color: settings.$rkt-slider-mark-small-background-color;
}

.rkt-Slider .MuiSlider-mark.MuiSlider-markActive,
.rkt-Slider.mat-mdc-slider .mdc-slider__tick-mark--active {
  background-color: settings.$rkt-slider-mark-active-background-color;
}

.rkt-Slider--small .MuiSlider-mark.MuiSlider-markActive,
.rkt-Slider--small.mat-mdc-slider .mdc-slider__tick-mark--active {
  background-color: settings.$rkt-slider-mark-small-active-background-color;
}

.rkt-Slider .MuiSlider-thumb.Mui-disabled,
.rkt-Slider.mat-mdc-slider.mdc-slider--disabled .mdc-slider__thumb-knob {
  background-color: settings.$rkt-slider-handle-disabled-background-color;
  border-color: settings.$rkt-slider-handle-disabled-border-color;
  border-width: settings.$rkt-slider-handle-disabled-border-width;
  border-style: settings.$rkt-slider-handle-disabled-border-style;
  filter: settings.$rkt-slider-handle-disabled-filter;
}

.rkt-Slider .MuiSlider-thumb:hover,
.rkt-Slider.mat-mdc-slider .mdc-slider__thumb:hover .mdc-slider__thumb-knob {
  box-shadow: settings.$rkt-slider-handle-hover-thumb-box-shadow;
}

.rkt-Slider .MuiSlider-thumb.Mui-focusVisible,
.rkt-Slider .MuiSlider-thumb.Mui-active,
.rkt-Slider.mat-mdc-slider .mdc-slider__thumb--focused .mdc-slider__thumb-knob,
.rkt-Slider.mat-mdc-slider .mdc-slider__thumb:active .mdc-slider__thumb-knob {
  box-shadow: settings.$rkt-slider-handle-active-thumb-box-shadow;
}

.rkt-Slider .MuiSlider-thumb.Mui-focusVisible {
  filter: settings.$rkt-slider-focus-visible-filter;
}

.rkt-Slider__label {
  color: settings.$rkt-slider-label-color;
  font-family: settings.$rkt-slider-label-font-family;
  font-size: settings.$rkt-slider-label-font-size;
  font-weight: settings.$rkt-slider-label-font-weight;
  line-height: settings.$rkt-slider-label-line-height;
  margin: settings.$rkt-slider-label-margin;
}

.rkt-Slider__label--is-disabled {
  color: settings.$rkt-slider-label-disabled-color;
}

.rkt-Slider--small.mat-mdc-slider .mdc-slider__track,
.rkt-Slider--small.mat-mdc-slider .mdc-slider__track--inactive,
.rkt-Slider--small.mat-mdc-slider .mdc-slider__track--active,
.rkt-Slider--small {
  height: settings.$rkt-slider-small-track-height;
}

.rkt-Slider--primary {
  .MuiSlider-valueLabel,
  &.mat-mdc-slider .mdc-slider__value-indicator,
  .MuiSlider-track {
    background-color: settings.$rkt-slider-primary-track-background-color;
  }

  &.mat-mdc-slider .mdc-slider__track--active_fill {
    border-color: settings.$rkt-slider-primary-track-border-color;
  }

  &.mat-mdc-slider .mdc-slider__value-indicator::before {
    border-top-color: settings.$rkt-slider-primary-track-border-top-color;
  }
}

.rkt-Slider--secondary {
  &.mat-mdc-slider .mdc-slider__value-indicator,
  .MuiSlider-valueLabel,
  .MuiSlider-track {
    background-color: settings.$rkt-slider-secondary-track-background-color;
  }

  &.mat-mdc-slider .mdc-slider__track--active_fill {
    border-color: settings.$rkt-slider-secondary-track-border-color;
  }

  &.mat-mdc-slider .mdc-slider__value-indicator::before {
    border-top-color: settings.$rkt-slider-secondary-track-border-top-color;
  }
}

.rkt-Slider--success {
  &.mat-mdc-slider .mdc-slider__value-indicator,
  .MuiSlider-valueLabel {
    background-color: settings.$rkt-slider-success-value-indicator-background-color;
  }

  .MuiSlider-track {
    background-color: settings.$rkt-slider-success-track-background-color;
  }

  &.mat-mdc-slider .mdc-slider__track--active_fill {
    border-color: settings.$rkt-slider-success-track-border-color;
  }

  &.mat-mdc-slider .mdc-slider__value-indicator::before {
    border-top-color: settings.$rkt-slider-success-track-border-top-color;
  }
}

.rkt-Slider--info {
  &.mat-mdc-slider .mdc-slider__value-indicator,
  .MuiSlider-valueLabel {
    background-color: settings.$rkt-slider-info-value-indicator-background-color;
  }

  .MuiSlider-track {
    background-color: settings.$rkt-slider-info-track-background-color;
  }

  &.mat-mdc-slider .mdc-slider__track--active_fill {
    border-color: settings.$rkt-slider-info-track-border-color;
  }

  &.mat-mdc-slider .mdc-slider__value-indicator::before {
    border-top-color: settings.$rkt-slider-info-track-border-top-color;
  }
}