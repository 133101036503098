@use '../web/scss/settings';

.rkt-AccordionPanel {
  background: settings.$rkt-accordion-panel-background;
  border-radius: settings.$rkt-accordion-panel-border-radius;
  border: settings.$rkt-accordion-panel-border;
  box-shadow: settings.$rkt-accordion-panel-box-shadow;
  box-sizing: border-box;

  &:first-of-type,
  &:last-of-type {
    border-radius: settings.$rkt-accordion-panel-border-radius;
    border: settings.$rkt-accordion-panel-border;
    box-shadow: settings.$rkt-accordion-panel-box-shadow;
  }

  // spacing between
  &:not(:last-of-type) {
    margin: settings.$rkt-accordion-panel-last-item-margin;
  }

  &:hover {
    background: settings.$rkt-accordion-panel-hover-background;
  }
}

.mat-accordion .mat-expansion-panel.rkt-AccordionPanel {
  &:has(.mat-expanded),
  &:not(.mat-expanded) {
    border: settings.$rkt-accordion-panel-border;
    border-radius: settings.$rkt-accordion-panel-border-radius;
    box-shadow: settings.$rkt-accordion-panel-box-shadow;
  }

  // Hover style of accordion
  &:not(.mat-expanded)
    .mat-expansion-panel-header:hover:not([aria-disabled='true']),
  &:hover {
    background: settings.$rkt-accordion-panel-hover-background;
  }

  // centers carat
  .mat-expansion-indicator {
    justify-content: settings.$rkt-accordion-expansion-indicator-justify-content;
    display: settings.$rkt-accordion-expansion-indicator-display;
    width: settings.$rkt-accordion-expansion-indicator-width;
  }

  // expansion carat icon
  .mat-expansion-indicator::after {
    padding: settings.$rkt-accordion-expansion-indicator-after-padding;
    color: settings.$rkt-accordion-expansion-indicator-after-color;
    height: settings.$rkt-accordion-expansion-indicator-after-height;
    width: settings.$rkt-accordion-expansion-indicator-after-width;
    margin-bottom: settings.$rkt-accordion-expansion-indicator-after-margin-bottom;
    border-width: settings.$rkt-accordion-expansion-indicator-after-border-width;
    transform: settings.$rkt-accordion-expansion-indicator-after-transform;
    border-radius: settings.$rkt-accordion-expansion-indicator-after-border-radius;
  }

  // removes set height from title
  .mat-expansion-panel-header {
    padding: settings.$rkt-accordion-panel-header-padding;
    height: settings.$rkt-accordion-panel-header-height;
    min-height: settings.$rkt-accordion-panel-header-min-height;
    transition: settings.$rkt-accordion-panel-header-transition;
  }

  .mat-expansion-panel-header-title {
    align-items: settings.$rkt-accordion-panel-header-title-align-items;
  }

  .mat-expansion-panel-header.mat-expanded {
    padding: settings.$rkt-accordion-panel-header-expanded-padding;
  }

  // Removes unecessary background from panel header
  .mat-expansion-panel-header.cdk-focused,
  .mat-expansion-panel-header.cdk-keyboard-focused {
    background: settings.$rkt-accordion-panel-header-focus-background;
  }

  // Focus visible state (child is the focused element)
  &:has(.cdk-keyboard-focused) {
    outline: settings.$rkt-accordion-panel-header-focus-visible-outline;
  }
}

.mat-accordion .mat-expansion-panel.rkt-AccordionPanel--slim,
.MuiPaper-root.rkt-AccordionPanel--slim {
  &:not(:last-of-type) {
    margin: settings.$rkt-accordion-panel-slim-margin;
  }

  // slim accordion panels have no box shadow. the added selectors here are for specificity
  &:has(.mat-expanded),
  &:not(.mat-expanded) {
    box-shadow: settings.$rkt-accordion-panel-slim-box-shadow;
    // 8px border radius for slim accordion panels, using the added specificity above to override default accordion radii
    border-radius: settings.$rkt-accordion-panel-slim-border-radius;
  }

  // reset padding on slim accordion panels
  .mat-expansion-panel-header,
  .mat-expansion-panel-header.mat-expanded,
  .MuiButtonBase-root.rkt-AccordionPanel__title,
  .MuiButtonBase-root.rkt-AccordionPanel__title.Mui-expanded {
    padding: settings.$rkt-accordion-panel-slim-expansion-panel-header-padding;
    border-radius: settings.$rkt-accordion-panel-slim-border-radius;
  }

  // slim accordion panels hover style
  &:not(.mat-expanded)
    .mat-expansion-panel-header:hover:not([aria-disabled='true']),
  &:hover {
    background: settings.$rkt-accordion-panel-slim-hover-background;

    .mat-expansion-indicator::after,
    .MuiAccordionSummary-expandIconWrapper .rkt-Icon {
      color: settings.$rkt-accordion-panel-slim-hover-caret-color;
    }
  }

  .MuiAccordionDetails-root,
  .mat-expansion-panel-body {
    padding: settings.$rkt-accordion-panel-slim-details-padding;
  }

  // angular only margin-top. the react margin is on the copy code
  .mat-expansion-panel-body {
    margin-top: settings.$rkt-accordion-panel-slim-mat-expansion-panel-body-margin-top;
  }

  // we need titles to flex auto for longer titles to be able to wrap
  .mat-expansion-panel-header-title.rkt-AccordionPanel__header-title .rkt-Stack__item:has(.rkt-AccordionPanel__content) {
    flex: settings.$rkt-accordion-panel-slim-title-flex;
  }
}

// Container of content
.rkt-AccordionPanel .mat-expansion-panel-body {
  padding: settings.$rkt-accordion-panel-body-padding;
}

// Smooths out animation in angular
.mat-expansion-panel-body .rkt-AccordionPanel__content {
  opacity: settings.$rkt-accordion-panel-body-opacity;
  transition: settings.$rkt-accordion-panel-body-transition;
}

.mat-expanded .rkt-AccordionPanel__content {
  opacity: settings.$rkt-accordion-panel-body-expanded-opacity;
}

.rkt-AccordionPanel__header-title {
  color: settings.$rkt-accordion-panel-header-title-color;
  font-family: settings.$rkt-accordion-panel-header-title-font-family;
  font-size: settings.$rkt-accordion-panel-header-title-font-size;
  font-style: settings.$rkt-accordion-panel-header-title-font-style;
  font-weight: settings.$rkt-accordion-panel-header-title-font-weight;
  line-height: settings.$rkt-accordion-panel-header-title-line-height;
  margin: settings.$rkt-accordion-panel-header-title-margin;

  @media only screen and (min-width: settings.$rkt-accordion-breakpoint) {
    font-size: settings.$rkt-accordion-panel-header-title-breakpoint-font-size;
    font-weight: settings.$rkt-accordion-panel-header-title-breakpoint-font-weight;
    line-height: settings.$rkt-accordion-panel-header-title-breakpoint-line-height;
  }
}

.rkt-AccordionPanel___header-icon {
  align-self: settings.$rkt-accordion-panel-header-icon-align-self;
  flex-shrink: settings.$rkt-accordion-panel-header-icon-flex-shrink;
  margin-right: settings.$rkt-accordion-panel-header-icon-margin-right;
}

.rkt-AccordionPanel__content {
  color: settings.$rkt-accordion-panel-content-color;
  font-family: settings.$rkt-accordion-panel-content-font-family;
  font-size: settings.$rkt-accordion-panel-content-font-size;
  font-style: settings.$rkt-accordion-panel-content-font-style;
  font-weight: settings.$rkt-accordion-panel-content-font-weight;
  line-height: settings.$rkt-accordion-panel-content-line-height;
  margin: settings.$rkt-accordion-panel-content-margin;

  @media only screen and (min-width: settings.$rkt-accordion-breakpoint) {
    font-size: settings.$rkt-accordion-panel-content-breakpoint-font-size;
    line-height: settings.$rkt-accordion-panel-content-breakpoint-line-height;
  }
}

// REACT
.rkt-AccordionPanel {
  &.MuiAccordion-root {
    border-top-left-radius: settings.$rkt-accordion-panel-border-radius;
    border-top-right-radius: settings.$rkt-accordion-panel-border-radius;
  }

  &.MuiAccordion-root::before {
    display: settings.$rkt-accordion-react-panel-content-before-display;
  }

  .MuiAccordionSummary-content {
    margin: settings.$rkt-accordion-react-panel-content-margin;
    display: settings.$rkt-accordion-react-panel-content-display;
    align-items: settings.$rkt-accordion-react-panel-content-align-items;
  }

  .rkt-AccordionPanel__title.MuiAccordionSummary-root.Mui-expanded {
    padding: settings.$rkt-accordion-react-expanded-title-padding;
    min-height: settings.$rkt-accordion-react-expanded-title-min-height;
  }

  .MuiAccordionDetails-root {
    padding: settings.$rkt-accordion-react-details-padding;
  }

  .rkt-AccordionPanel__title.MuiAccordionSummary-root {
    padding: settings.$rkt-accordion-react-summary-padding;
    transition: settings.$rkt-accordion-react-summary-transition;
  }

  .MuiAccordionSummary-expandIconWrapper {
    color: settings.$rkt-accordion-react-summary-icon-color;
  }

  &:has(.Mui-focusVisible) {
    outline: settings.$rkt-accordion-panel-header-focus-visible-outline;
  }
}

.rkt-AccordionPanel__title.Mui-focusVisible {
  background: settings.$rkt-accordion-react-title-focus-visible-background;
}
