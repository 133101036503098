@use '../web/scss/settings';

.rkt-Masthead:not(.rkt-Masthead--react),
.rkt-Masthead:not(.rkt-Masthead--react) .rkt-Masthead__content,
.rkt-Masthead:not(.rkt-Masthead--react) .rkt-Masthead__nav-bar {
  transition: settings.$rkt-masthead-transition;
}

.rkt-Masthead:not(.rkt-Masthead--react) {
  background-color: settings.$rkt-masthead-bg-color;
  border-bottom: settings.$rkt-masthead-border-bottom;
  box-shadow: settings.$rkt-masthead-shadow;
  position: settings.$rkt-masthead-position;
  top: settings.$rkt-masthead-top;
  z-index: settings.$rkt-layer-height-s;

  @media all and (min-width: settings.$rkt-masthead-breakpoint) {
    border-bottom: settings.$rkt-masthead-border-bottom-wide;
  }
}

/// Adds styles for the Masthead when it is open.
.rkt-Masthead--is-open:not(.rkt-Masthead--react) {
  position: settings.$rkt-masthead-position-is-open;
  left: settings.$rkt-masthead-left-is-open;
  right: settings.$rkt-masthead-right-is-open;
  height: settings.$rkt-masthead-height-is-open;

  @media all and (min-width: settings.$rkt-masthead-breakpoint-medium) {
    background-color: settings.$rkt-masthead-background-color-is-open;
  }

  @media all and (min-width: settings.$rkt-masthead-breakpoint) {
    background-color: settings.$rkt-masthead-bg-color;
    position: settings.$rkt-masthead-position-is-open-large;
    height: settings.$rkt-masthead-height-is-open-large;
  }
}

///	Adds styles for the Masthead when the user scrolls past it.
.rkt-Masthead--is-scrolled:not(.rkt-Masthead--react) {
  box-shadow: settings.$rkt-masthead-shadow-scroll;
}

// Keep padding settings consistent for menu,
// logo branding, nav items, and additional nav link
.rkt-Masthead:not(.rkt-Masthead--react) {
  .rkt-Masthead__menu,
  .rkt-Masthead__branding,
  .rkt-Masthead__nav-items,
  .rkt-Masthead__nav-item {
    padding-top: settings.$rkt-masthead-content-item-padding-top;
    padding-left: settings.$rkt-masthead-content-item-padding-left;
    padding-right: settings.$rkt-masthead-content-item-padding-right;
    padding-bottom: settings.$rkt-masthead-content-item-padding-bottom;

    @media all and (min-width: settings.$rkt-masthead-breakpoint) {
      padding-top: settings.$rkt-masthead-content-item-padding-top-wide;
      padding-left: settings.$rkt-masthead-content-item-padding-left-wide;
      padding-right: settings.$rkt-masthead-content-item-padding-right-wide;
      padding-bottom: settings.$rkt-masthead-content-item-padding-bottom-wide;
    }
  }

  .rkt-Masthead__nav-bar-item {
    display: settings.$rkt-masthead-nav-bar-item-display;
    margin: settings.$rkt-masthead-nav-bar-item-margin;
  }

  // Content includes menu, logo,
  // nav items, additional nav link
  .rkt-Masthead__content {
    background-color: settings.$rkt-masthead-bg-color;
    padding: settings.$rkt-masthead-content-padding;
    min-height: settings.$rkt-masthead-content-min-height;

    @media all and (min-width: settings.$rkt-masthead-breakpoint) {
      margin: settings.$rkt-masthead-content-margin;
      max-width: settings.$rkt-masthead-column-width;
      padding: settings.$rkt-masthead-content-padding-wide;
    }
  }

  .rkt-Masthead__content--full-width {
    @media all and (min-width: settings.$rkt-masthead-breakpoint) {
      max-width: settings.$rkt-masthead-column-width-full;
    }
  }

  // When open, adjust box shadow and border styles
  &.rkt-Masthead--is-open .rkt-Masthead__content {
    background-color: settings.$rkt-masthead-bg-color;
    border-bottom: settings.$rkt-masthead-border-bottom;
    box-shadow: settings.$rkt-masthead-shadow-narrow;

    @media all and (min-width: settings.$rkt-masthead-breakpoint) {
      // Prevents narrow nav box-shadow and
      // border styles from showing on large viewports
      // in scenario where nav is open and viewport
      // is expanded
      box-shadow: settings.$rkt-masthead-shadow-wide;
      border-bottom: settings.$rkt-masthead-border-bottom-wide;
    }
  }

  .rkt-Masthead__nav-item {
    display: settings.$rkt-masthead-nav-item-display;
    justify-content: settings.$rkt-masthead-nav-item-justify-content;
  }

  // Hide menu element and additional nav link on larger views
  .rkt-Masthead__menu,
  .rkt-Masthead__nav-item {
    flex-grow: settings.$rkt-masthead-menu-flex-grow;
    flex-shrink: settings.$rkt-masthead-menu-flex-shrink;
    flex-basis: settings.$rkt-masthead-menu-flex-basis;

    @media all and (min-width: settings.$rkt-masthead-breakpoint) {
      display: settings.$rkt-masthead-menu-display-large;
    }
  }

  // Center logo branding
  .rkt-Masthead__branding {
    margin: settings.$rkt-masthead-branding-margin;
    flex-grow: settings.$rkt-masthead-branding-flex-grow;
    flex-shrink: settings.$rkt-masthead-branding-flex-shrink;
    flex-basis: settings.$rkt-masthead-branding-flex-basis;
    text-align: settings.$rkt-masthead-branding-text-align;

    @media (min-width: settings.$rkt-masthead-breakpoint) {
      padding-right: settings.$rkt-masthead-content-item-padding-right-wide;
      flex-grow: settings.$rkt-masthead-branding-flex-grow-large;
    }
  }

  .rkt-Masthead__input.mat-form-field,
  .rkt-Masthead__input.rkt-Stack__item {
    display: settings.$rkt-masthead-input-display;
    justify-content: settings.$rkt-masthead-input-justify-content;
  }

  .rkt-Masthead__input .MuiFormControl-root,
  // Deprecated selector in v4, removal in v6 - task #3551812
  .rkt-Masthead__input .mat-form-field-wrapper,
  .rkt-Masthead__input .mat-mdc-text-field-wrapper {
    width: settings.$rkt-masthead-input-width;
    align-self: settings.$rkt-masthead-input-align-self;
  }

  // Add sizing range for logo
  .rkt-Masthead__logo {
    max-width: settings.$rkt-masthead-logo-max-width;
    min-width: settings.$rkt-masthead-logo-min-width;
    max-height: settings.$rkt-masthead-logo-max-height;
  }

  // // Hide nav bar and nav items until larger view
  .rkt-Masthead__nav-bar,
  .rkt-Masthead__nav-items {
    display: settings.$rkt-masthead-nav-bar-display;

    @media (min-width: settings.$rkt-masthead-breakpoint) {
      display: settings.$rkt-masthead-nav-bar-display-large;
    }
  }

  // If there are too many nav items they'll wrap
  .rkt-Masthead__nav-items {
    flex-wrap: settings.$rkt-masthead-nav-items-flex-wrap;
    position: settings.$rkt-masthead-nav-items-position;
  }

  .rkt-Masthead__site-links {
    list-style: settings.$rkt-masthead-site-links-list-style;
    // Relative pos for child dropdowns
    position: settings.$rkt-masthead-site-links-position;
    width: settings.$rkt-masthead-site-links-width;
    padding-left: settings.$rkt-masthead-site-links-padding-left;

    @media all and (min-width: settings.$rkt-masthead-breakpoint) {
      border-right: settings.$rkt-masthead-site-links-border-right;
    }
  }

  .rkt-Masthead__link {
    color: settings.$rkt-masthead-link-color;
    border-bottom: settings.$rkt-masthead-link-border-bottom;
    font-weight: settings.$rkt-masthead-link-font-weight;
    transition: settings.$rkt-masthead-link-transition;
    text-decoration: settings.$rkt-masthead-link-text-decoration;
    font-family: settings.$rkt-masthead-link-font-family;
    font-size: settings.$rkt-masthead-link-font-size;
    text-underline-offset: settings.$rkt-masthead-link-text-underline-offset;

    &:visited {
      border-bottom: settings.$rkt-masthead-link-border-bottom;
      color: settings.$rkt-masthead-link-visited-color;
    }

    &:hover,
    &:focus {
      border-bottom: settings.$rkt-masthead-link-border-bottom-hover;
      color: settings.$rkt-masthead-link-hover-color;
      // Broken out for React Safari to work
      text-decoration-style: settings.$rkt-masthead-link-hover-text-decoration-style;
      text-decoration-line: settings.$rkt-masthead-link-hover-text-decoration-line;
      text-decoration-color: settings.$rkt-masthead-link-hover-text-decoration-color;
      text-decoration-thickness: settings.$rkt-masthead-link-hover-text-decoration-thickness;
    }
    // Smaller main navigation (sign in) link font-size on Narrow Screens
    @media all and (max-width: settings.$rkt-masthead-breakpoint) {
      font-size: settings.$rkt-masthead-narrow-link-font-size;
    }
  }

  .rkt-Masthead__nav-bar {
    background-color: settings.$rkt-masthead-nav-bar-bg;
  }

  // Nav bar has a max width for child items
  .rkt-Masthead__nav-bar-items {
    // Relative pos for child dropdowns
    position: settings.$rkt-masthead-nav-bar-items-position;
    margin: settings.$rkt-masthead-nav-bar-items-margin;
    padding: settings.$rkt-masthead-nav-bar-items-padding;
    max-width: settings.$rkt-masthead-nav-bar-items-column-width;
    list-style: settings.$rkt-masthead-nav-bar-items-list-style;
    height: settings.$rkt-masthead-nav-bar-items-height;
  }

  .rkt-Masthead__menu .rkt-Masthead__menu-icon {
    color: settings.$rkt-masthead-menu-icon-color;
    fill: settings.$rkt-masthead-menu-icon-fill;
    width: settings.$rkt-masthead-menu-icon-width;
    height: settings.$rkt-masthead-menu-icon-height;

    &:hover {
      background-color: settings.$rkt-masthead-menu-icon-hover-background-color;
    }
  }

  .rkt-Masthead__collapsible-nav-button {
    background-color: settings.$rkt-masthead-collapsible-nav-button-background-color;
    border: settings.$rkt-masthead-collapsible-nav-button-border;
    cursor: settings.$rkt-masthead-collapsible-nav-button-cursor;
    padding: settings.$rkt-masthead-collapsible-nav-button-padding;
    margin: settings.$rkt-masthead-collapsible-nav-button-margin;
  }

  // Fixed container that covers the entire page
  // that contains the Masthead Accordion
  // Starts below the main content (logo branding, menu)
  .rkt-Masthead__nav-collapsible {
    box-sizing: border-box;
    background-color: settings.$rkt-masthead-nav-collapsible-bg-color;
    height: settings.$rkt-masthead-nav-collapsible-height;
    overflow: settings.$rkt-masthead-nav-collapsible-overflow;
    padding-left: settings.$rkt-masthead-nav-collapsible-padding-left;
    padding-right: settings.$rkt-masthead-nav-collapsible-padding-right;
    padding-bottom: settings.$rkt-masthead-narrow-height + settings.$rkt-masthead-content-item-padding-top +
      settings.$rkt-masthead-content-item-padding-top + settings.$rkt-masthead-menu-icon-height +
      settings.$rkt-masthead-border-bottom-size; // So the scroll has enough room to reach the bottom item

    top: settings.$rkt-masthead-narrow-height + settings.$rkt-masthead-content-item-padding-top +
      settings.$rkt-masthead-content-item-padding-top + settings.$rkt-masthead-menu-icon-height +
      settings.$rkt-masthead-border-bottom-size; // Adds extra space to account for logo dropping under menu<320px

    width: settings.$rkt-masthead-nav-collapsible-width;

    @media (min-width: settings.$rkt-masthead-breakpoint-medium) {
      width: settings.$rkt-masthead-nav-collapsible-width-medium;
    }

    @media (min-width: settings.$rkt-split-breakpoint-1) {
      // Extra space for border included
      top: settings.$rkt-masthead-narrow-height + settings.$rkt-masthead-border-bottom-size;
      // Extra space for border included
      padding-bottom: settings.$rkt-masthead-narrow-height + settings.$rkt-masthead-border-bottom-size;
      z-index: settings.$rkt-masthead-nav-collapsible-z-index;
    }

    @media (min-width: settings.$rkt-masthead-breakpoint) {
      // Hide narrow nav on larger views
      display: settings.$rkt-masthead-nav-collapsible-display-large;
    }
  }

  .rkt-Masthead__nav-bar-link-item {
    height: settings.$rkt-masthead-nav-bar-items-height;
  }

  .rkt-Masthead__nav-collapsible--is-collapsed {
    display: settings.$rkt-masthead-nav-collapsible-display-is-collapsed;
  }

  .rkt-Masthead__nav-link,
  .rkt-Masthead__nav-link.MuiButton-root,
  .rkt-Masthead__nav-link.mdc-button {
    font-family: settings.$rkt-masthead-nav-link-font-family;
    font-size: settings.$rkt-masthead-nav-link-font-size;
    font-weight: settings.$rkt-masthead-nav-link-font-weight;
    color: settings.$rkt-masthead-nav-link-default-color;
    line-height: settings.$rkt-masthead-nav-link-line-height;
    text-decoration: settings.$rkt-masthead-nav-link-text-decoration;
  }

  // Deprecated selector in v4, removal in v6 - task #3551812
  // Apply transparent border bottom that we transition to brand color on hover
  .rkt-Masthead__nav-link.mat-button {
    border-bottom: settings.$rkt-masthead-nav-link-border-bottom;
  }

  // Adds font styles to the links when they are in expansion panels
  .rkt-Masthead .rkt-AccordionPanel .mat-expansion-panel-header-title,
  // Adds font styles to the links when they are not in expansion panels
  .rkt-Masthead__item-link {
    font-family: settings.$rkt-masthead-nav-link-font-family;
    font-size: settings.$rkt-masthead-nav-link-font-size;
    font-weight: settings.$rkt-masthead-link-font-weight;
    color: settings.$rkt-masthead-link-color;
    line-height: settings.$rkt-masthead-nav-link-line-height;
  }

  .rkt-Masthead__item-link {
    border-radius: settings.$rkt-masthead-item-link-border-radius;
    padding: settings.$rkt-masthead-item-link-padding;
    align-items: settings.$rkt-masthead-item-link-align-items;
  }

  .rkt-Masthead__item-link:hover {
    background-color: settings.$rkt-masthead-item-link-hover-background-color;
  }

  // Angular only - keeps icon to left of text from shrinking when text wraps
  // in "WITHOUT EXPANSION PANELS" variant
  .rkt-Masthead__item-link .mat-icon {
    flex-shrink: settings.$rkt-masthead-item-link-icon-flex-shrink;
  }

  // Apply brand border bottom to hovered and active items
  // Deprecated selector in v4, removal in v6 - task #3551812
  .rkt-Masthead__nav-link:hover .mat-button-wrapper,
  // Deprecated selector in v4, removal in v6 - task #3551812
  .rkt-Masthead__nav-link--is-active .mat-button-wrapper,
  .rkt-Masthead__nav-link.mat-mdc-button:hover .mdc-button__ripple,
  .rkt-Masthead__nav-link--is-active.mat-mdc-button .mdc-button__ripple {
    border-bottom: settings.$rkt-masthead-nav-link-border-bottom-active;
    padding-bottom: settings.$rkt-masthead-nav-link-active-padding;
  }

  // apply React hover and active border bottom - this has to be a separate block from above
  // because we don't need the padding-bottom from the above on the React side,
  .rkt-Masthead__nav-link--is-active.MuiButton-root,
  // @deprecate in v5, removal in v7 - task #623035
  .Masthead__nav-bar-item--is-active,
  .rkt-Masthead__nav-bar-item--is-active,
  .rkt-Masthead__nav-bar-link-item:hover,
  .rkt-Masthead__nav-bar-button-item:hover {
    border-bottom: settings.$rkt-masthead-nav-link-border-bottom-active;
  }

  .rkt-Masthead__nav-bar-link-item,
  .rkt-Masthead__nav-bar-button-item {
    cursor: settings.$rkt-masthead-nav-bar-item-cursor;
  }

  .rkt-Masthead__nav-bar-button-item:hover .rkt-Masthead__nav-link .MuiTypography-root,
  .rkt-Masthead__nav-bar-button-item:hover .rkt-Masthead__nav-link .MuiSvgIcon-root,
  .rkt-Masthead__nav-bar-link-item:hover .rkt-Masthead__nav-link .MuiTypography-root,
  .rkt-Masthead__nav-bar-link-item:hover .rkt-Masthead__nav-link,
  .rkt-Masthead__nav-bar-button-item:hover .rkt-Masthead__nav-link {
    color: settings.$rkt-masthead-nav-link-color;
  }

  .rkt-Masthead__nav-bar-button-item,
  .rkt-Masthead__nav-bar-button-item .MuiButton-root {
    height: settings.$rkt-masthead-nav-bar-button-item-height;
  }

  // Remove button default gray bg color and radius
  // Deprecated selector in v4, removal in v6 - task #3551812
  .rkt-Masthead__nav-link.mat-button .mat-button-focus-overlay,
  // Deprecated selector in v4, removal in v6 - task #3551812
  .rkt-Masthead__nav-link.mat-mdc-button:hover .mat-button-focus-overlay,
  // Deprecated selector in v4, removal in v6 - task #3551812
  .rkt-Masthead__nav-link.mat-button,
  .rkt-Masthead__nav-link.mat-mdc-button,
  .rkt-Masthead__nav-link.mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before,
  .rkt-Masthead__nav-link.MuiButton-root {
    background-color: settings.$rkt-masthead-nav-link-background-color;
    border-radius: settings.$rkt-masthead-nav-link-border-radius;
  }

  // Apply brand color text to hovered items
  // Deprecated selector in v4, removal in v6 - task #3551812
  .rkt-Masthead__nav-link:hover .mat-body-1,
  // Deprecated selector in v4, removal in v6 - task #3551812
  .rkt-Masthead__nav-link:hover .rkt-Label-16,
  // Deprecated selector in v4, removal in v6 - task #3551812
  .rkt-Masthead__nav-link:hover.mat-button:hover:not(.mat-button-disabled) .mat-button-focus-overlay,
  .rkt-Masthead__nav-link:hover,
  .rkt-Masthead__nav-link:hover.mat-mdc-button:not(:disabled),
  .rkt-Masthead__nav-link:hover .MuiTypography-root,
  .rkt-Masthead__nav-link:hover .MuiSvgIcon-root {
    color: settings.$rkt-masthead-nav-link-color;
  }

  // Apply regular text color to active links
  // Deprecated selector in v4, removal in v6 - task #3551812
  .rkt-Masthead__nav-link--is-active.mat-button,
  .rkt-Masthead__nav-link--is-active.mat-mdc-button {
    color: settings.$rkt-masthead-nav-link-active-color;

    &:hover {
      color: settings.$rkt-masthead-nav-link-color;
    }
  }

  .rkt-Masthead__nav-link.mat-mdc-button {
    border-bottom: settings.$rkt-masthead-nav-link-border-bottom;
    // Mat 15 auto puts the icon on the left side in the markup
    // This overrides to put it on the right side of the label
    flex-direction: settings.$rkt-masthead-nav-link-flex-direction;
  }

  .rkt-Masthead__nav-link.mat-mdc-button>.mat-icon {
    margin: settings.$rkt-masthead-nav-link-icon-margin;
  }
}

.rkt-Masthead--react {
  &.rkt-Masthead {
    background-color: settings.$rkt-masthead-bg-color;
    box-shadow: settings.$rkt-masthead-shadow;
    position: settings.$rkt-masthead-position;
    top: settings.$rkt-masthead-top;
    z-index: settings.$rkt-layer-height-s;
    // Deprecated in v7.1 - remove in v9 - Task #830136
    border-bottom: settings.$rkt-masthead-static-border-bottom;
  }

  /// Adds styles for the Masthead when it is open.
  &.rkt-Masthead--is-open {
    position: settings.$rkt-masthead-position-is-open;
    left: settings.$rkt-masthead-left-is-open;
    right: settings.$rkt-masthead-right-is-open;
    height: settings.$rkt-masthead-height-is-open;

    .rkt-Masthead__nav-item {
      display: settings.$rkt-masthead-is-open-nav-item-display;
    }

    .rkt-Masthead__branding {
      text-align: settings.$rkt-masthead-is-open-branding-text-align;
    }

    .rkt-Masthead__branding-link {
      justify-content: settings.$rkt-masthead-is-open-branding-link-justify-content;
      width: settings.$rkt-masthead-is-open-branding-link-width;
    }

    .rkt-Masthead__content {
      flex-direction: settings.$rkt-masthead-is-open-content-flex-direction;
    }
    .rkt-Masthead__menu {
      flex: settings.$rkt-masthead-is-open-menu-flex;
    }

    @media all and (min-width: settings.$rkt-masthead-static-breakpoint) {
      position: settings.$rkt-masthead-position-is-open-large;
      height: settings.$rkt-masthead-height-is-open-large;
    }
  }

  .rkt-Masthead__nav-bar-item {
    display: settings.$rkt-masthead-nav-bar-item-display;
    margin: settings.$rkt-masthead-nav-bar-item-margin;
  }

  // Content includes menu, logo,
  // nav items, additional nav link
  .rkt-Masthead__content {
    background-color: settings.$rkt-masthead-bg-color;
    min-height: settings.$rkt-masthead-static-content-min-height;
    padding: settings.$rkt-masthead-static-content-padding;

    @media all and (min-width: settings.$rkt-masthead-static-breakpoint) {
      padding: settings.$rkt-masthead-static-content-wide-padding;
    }
  }

  .rkt-Masthead__content--full-width {
    @media all and (min-width: settings.$rkt-masthead-static-breakpoint) {
      max-width: settings.$rkt-masthead-column-width-full;
    }
  }

  // When open, adjust box shadow and border styles
  &.rkt-Masthead--is-open .rkt-Masthead__content {
    background-color: settings.$rkt-masthead-bg-color;
    border-bottom: settings.$rkt-masthead-border-bottom;
    box-shadow: settings.$rkt-masthead-shadow-narrow;

    @media all and (min-width: settings.$rkt-masthead-static-breakpoint) {
      // Prevents narrow nav box-shadow and
      // border styles from showing on large viewports
      // in scenario where nav is open and viewport
      // is expanded
      box-shadow: settings.$rkt-masthead-shadow-wide;
      border-bottom: settings.$rkt-masthead-border-bottom-wide;
    }
  }

  .rkt-Masthead__nav-item {
    display: settings.$rkt-masthead-nav-item-display;
    justify-content: settings.$rkt-masthead-nav-item-justify-content;
  }

  // Hide menu element and additional nav link on larger views
  .rkt-Masthead__menu,
  .rkt-Masthead__nav-item {
    flex-grow: settings.$rkt-masthead-menu-flex-grow;
    flex-shrink: settings.$rkt-masthead-menu-flex-shrink;
    flex-basis: settings.$rkt-masthead-menu-flex-basis;

    @media all and (min-width: settings.$rkt-masthead-static-breakpoint) {
      display: settings.$rkt-masthead-menu-display-large;
    }
  }

  // Center logo branding
  .rkt-Masthead__branding {
    margin: settings.$rkt-masthead-branding-margin;
    flex-grow: settings.$rkt-masthead-branding-flex-grow;
    flex-shrink: settings.$rkt-masthead-branding-flex-shrink;
    flex-basis: settings.$rkt-masthead-branding-flex-basis;
    text-align: settings.$rkt-masthead-branding-text-align;

    @media (min-width: settings.$rkt-masthead-static-breakpoint) {
      padding-right: settings.$rkt-masthead-branding-wide-padding;
      flex-grow: settings.$rkt-masthead-branding-flex-grow-large;
    }
  }

  .rkt-Masthead__branding-link {
    display: settings.$rkt-masthead-branding-link-display;
    align-items: settings.$rkt-masthead-branding-link-align-items;
    justify-content: settings.$rkt-masthead-branding-link-justify-content;

    &:focus-visible {
      outline-color: settings.$rkt-masthead-branding-link-focus-visible-outline-color;
    }
  }

  // Add sizing range for logo
  .rkt-Masthead__logo {
    max-width: settings.$rkt-masthead-logo-max-width;
    min-width: settings.$rkt-masthead-static-logo-min-width;
    max-height: settings.$rkt-masthead-logo-max-height;
    height: settings.$rkt-masthead-logo-height;
    width: settings.$rkt-masthead-logo-width;

    @media (min-width: settings.$rkt-masthead-static-breakpoint) {
      height: settings.$rkt-masthead-logo-wide-height;
      width: settings.$rkt-masthead-logo-wide-width;
    }
  }

  // // Hide nav bar and nav items until larger view
  .rkt-Masthead__nav-bar,
  .rkt-Masthead__nav-items {
    display: settings.$rkt-masthead-nav-bar-display;

    @media (min-width: settings.$rkt-masthead-static-breakpoint) {
      display: settings.$rkt-masthead-nav-bar-display-large;
    }
  }

  // If there are too many nav items they'll wrap
  .rkt-Masthead__nav-items {
    flex-wrap: settings.$rkt-masthead-nav-items-flex-wrap;
    position: settings.$rkt-masthead-nav-items-position;
    justify-content: settings.$rkt-masthead-nav-items-justify-content;
    align-items: settings.$rkt-masthead-nav-items-align-items;
  }

  .rkt-Masthead__site-links,
  .rkt-Masthead__utility-links {
    list-style: settings.$rkt-masthead-site-links-list-style;
    // Relative pos for child dropdowns
    position: settings.$rkt-masthead-site-links-position;
    width: settings.$rkt-masthead-site-links-width;
    margin: settings.$rkt-masthead-site-links-margin;
    padding: settings.$rkt-masthead-site-links-padding;
    align-items: settings.$rkt-masthead-site-links-align-items;
  }


  .rkt-Masthead__link {
    color: settings.$rkt-masthead-static-link-color;
    border-bottom: settings.$rkt-masthead-link-border-bottom;
    font-weight: settings.$rkt-masthead-static-link-font-weight;
    transition: settings.$rkt-masthead-link-transition;
    text-decoration: settings.$rkt-masthead-link-text-decoration;
    font-family: settings.$rkt-masthead-link-font-family;
    font-size: settings.$rkt-masthead-link-font-size;
    text-underline-offset: settings.$rkt-masthead-link-text-underline-offset;
    display: settings.$rkt-masthead-link-display;
    align-items: settings.$rkt-masthead-link-align-items;
    padding: settings.$rkt-masthead-link-padding;
    margin: settings.$rkt-masthead-link-margin;
  }

  .rkt-Masthead__utility-links .rkt-Masthead__link {
    color: settings.$rkt-masthead-utility-link-color;
    padding: settings.$rkt-masthead-utility-link-padding;
    margin-right: settings.$rkt-masthead-utility-link-margin-right;
    margin-left: settings.$rkt-masthead-utility-link-margin-left;

    &:visited:not(:hover, :focus-visible) {
      border-bottom: settings.$rkt-masthead-link-border-bottom;
      color: settings.$rkt-masthead-utility-link-color;
    }

    &:hover {
      color: settings.$rkt-masthead-utility-link-hover-color;
      text-decoration: settings.$rkt-masthead-utility-link-hover-text-decoration;
    }

    &:focus-visible {
      color: settings.$rkt-masthead-utility-link-focus-color;
    }
  }

  .rkt-Masthead__nav-bar {
    background-color: settings.$rkt-masthead-nav-bar-bg;
  }

  // Nav bar has a max width for child items
  .rkt-Masthead__nav-bar-items {
    // Relative pos for child dropdowns
    position: settings.$rkt-masthead-nav-bar-items-position;
    max-width: settings.$rkt-masthead-nav-bar-items-column-width;
    list-style: settings.$rkt-masthead-nav-bar-items-list-style;
    height: settings.$rkt-masthead-nav-bar-items-height;
  }

  .rkt-Masthead__menu .rkt-Masthead__menu-icon {
    color: settings.$rkt-masthead-menu-icon-color;
    fill: settings.$rkt-masthead-menu-icon-fill;
    width: settings.$rkt-masthead-menu-icon-width;
    height: settings.$rkt-masthead-menu-icon-height;

    &:hover {
      background-color: settings.$rkt-masthead-menu-icon-hover-background-color;
    }
  }

  .rkt-Masthead__collapsible-nav-button,
  .rkt-Masthead__nav-item-button,
  .rkt-Masthead__nav-item-button:hover {
    background-color: settings.$rkt-masthead-nav-item-button-background-color;
    border: settings.$rkt-masthead-nav-item-button-border;
    border-radius: settings.$rkt-masthead-nav-item-button-border-radius;
    height: settings.$rkt-masthead-nav-item-button-height;
    margin: settings.$rkt-masthead-nav-item-button-margin;
    padding: settings.$rkt-masthead-nav-item-button-padding;
    min-width: settings.$rkt-masthead-nav-item-button-min-width;
    width: settings.$rkt-masthead-nav-item-button-width;
    display: settings.$rkt-masthead-nav-item-button-display;
    align-items: settings.$rkt-masthead-nav-item-button-align-items;
    justify-content: settings.$rkt-masthead-nav-item-button-justify-content;
    position: settings.$rkt-masthead-nav-item-button-position;
    cursor: settings.$rkt-masthead-nav-item-button-cursor;

    &:focus-visible {
      box-shadow: settings.$rkt-masthead-nav-item-button-focus-box-shadow;
      outline: settings.$rkt-masthead-nav-item-button-focus-outline;
    }
  }

  .rkt-Masthead__nav-item-button,
  .rkt-Masthead__nav-item-button:visited {
    color: settings.$rkt-masthead-nav-item-button-color;
  }

  // Fixed container that covers the entire page
  // that contains the Masthead Accordion
  // Starts below the main content (logo branding, menu)
  .rkt-Masthead__nav-collapsible {
    box-sizing: settings.$rkt-masthead-nav-collapsible-box-sizing;
    background-color: settings.$rkt-masthead-nav-collapsible-bg-color;
    height: settings.$rkt-masthead-nav-collapsible-height;
    overflow: settings.$rkt-masthead-nav-collapsible-overflow;
    width: settings.$rkt-masthead-nav-collapsible-static-width;
    top: settings.$rkt-masthead-nav-collapsible-top;
    position: settings.$rkt-masthead-nav-collapsible-position;
    padding: settings.$rkt-masthead-nav-collapsible-padding;

    @media (min-width: settings.$rkt-masthead-static-breakpoint) {
      // Hide narrow nav on larger views
      display: settings.$rkt-masthead-nav-collapsible-display-large;
    }
  }

  .rkt-Masthead__nav-bar-link-item {
    height: settings.$rkt-masthead-nav-bar-items-height;
  }

  .rkt-Masthead__nav-collapsible--is-collapsed {
    display: settings.$rkt-masthead-nav-collapsible-display-is-collapsed;
  }

  .rkt-Masthead__nav-link,
  .rkt-Masthead__nav-link.MuiButton-root {
    font-family: settings.$rkt-masthead-nav-link-font-family;
    font-size: settings.$rkt-masthead-nav-link-font-size;
    font-weight: settings.$rkt-masthead-nav-link-font-weight;
    color: settings.$rkt-masthead-nav-link-default-color;
    line-height: settings.$rkt-masthead-nav-link-line-height;
    text-decoration: settings.$rkt-masthead-nav-link-text-decoration;
  }

  // Adds font styles to the links when they are not in expansion panels
  .rkt-Masthead__item-link {
    font-family: settings.$rkt-masthead-nav-link-font-family;
    font-size: settings.$rkt-masthead-nav-link-font-size;
    font-weight: settings.$rkt-masthead-link-font-weight;
    color: settings.$rkt-masthead-link-color;
    line-height: settings.$rkt-masthead-nav-link-line-height;
  }

  .rkt-Masthead__item-link {
    border-radius: settings.$rkt-masthead-item-link-border-radius;
    align-items: settings.$rkt-masthead-item-link-align-items;
  }

  .rkt-Masthead__item-link:hover {
    background-color: settings.$rkt-masthead-item-link-hover-background-color;
  }

  // apply React hover and active border bottom - this has to be a separate block from above
  // because we don't need the padding-bottom from the above on the React side,
  .rkt-Masthead__nav-link--is-active.MuiButton-root,
  .rkt-Masthead__nav-bar-item--is-active,
  .rkt-Masthead__nav-bar-link-item:hover,
  .rkt-Masthead__nav-bar-button-item:hover {
    border-bottom: settings.$rkt-masthead-nav-link-border-bottom-active;
  }

  .rkt-Masthead__nav-bar-link-item,
  .rkt-Masthead__nav-bar-button-item {
    cursor: settings.$rkt-masthead-nav-bar-item-cursor;
  }

  .rkt-Masthead__nav-bar-button-item:hover .rkt-Masthead__nav-link .MuiTypography-root,
  .rkt-Masthead__nav-bar-button-item:hover .rkt-Masthead__nav-link .MuiSvgIcon-root,
  .rkt-Masthead__nav-bar-link-item:hover .rkt-Masthead__nav-link .MuiTypography-root,
  .rkt-Masthead__nav-bar-link-item:hover .rkt-Masthead__nav-link,
  .rkt-Masthead__nav-bar-button-item:hover .rkt-Masthead__nav-link {
    color: settings.$rkt-masthead-nav-link-color;
  }

  .rkt-Masthead__nav-bar-button-item,
  .rkt-Masthead__nav-bar-button-item .MuiButton-root {
    height: settings.$rkt-masthead-nav-bar-button-item-height;
  }

  // Remove button default gray bg color and radius
  .rkt-Masthead__nav-link.MuiButton-root {
    background-color: settings.$rkt-masthead-nav-link-background-color;
    border-radius: settings.$rkt-masthead-nav-link-border-radius;
  }

  .rkt-Masthead__nav-link:hover,
  .rkt-Masthead__nav-link:hover .MuiTypography-root,
  .rkt-Masthead__nav-link:hover .MuiSvgIcon-root {
    color: settings.$rkt-masthead-nav-link-color;
  }

}

// MEGA MENU STYLES

.rkt-Masthead__link-menu-container {
  position: settings.$rkt-masthead-link-menu-container-position;
  width: settings.$rkt-masthead-link-menu-container-width;
  padding: settings.$rkt-masthead-link-menu-container-padding;
  top: settings.$rkt-masthead-link-menu-container-top;
}

.rkt-Masthead__link-menu-background {
  transform: settings.$rkt-masthead-link-menu-background-transform;
  opacity: settings.$rkt-masthead-link-menu-background-opacity;
  transform-origin: settings.$rkt-masthead-link-menu-background-transform-origin;
  transition: settings.$rkt-masthead-link-menu-background-transition;
  position: settings.$rkt-masthead-link-menu-background-position;
  z-index: settings.$rkt-masthead-link-menu-background-z-index;
  background-color: settings.$rkt-masthead-link-menu-background-bg-color;
  border-radius: settings.$rkt-masthead-link-menu-background-border-radius;
  box-shadow: settings.$rkt-masthead-link-menu-background-box-shadow;
  display: settings.$rkt-masthead-link-menu-background-display;
  width: settings.$rkt-masthead-link-menu-background-width;
  left: settings.$rkt-masthead-link-menu-background-left;

  .MuiTabs-root {
    border: settings.$rkt-masthead-link-menu-background-tabs-border;
    padding: settings.$rkt-masthead-link-menu-background-tabs-padding;
  }

  .MuiTabs-indicator {
    display: settings.$rkt-masthead-link-menu-background-tabs-indicator-display;
  }
}

.rkt-Masthead__site-link-item:hover .rkt-Masthead__link-menu-background,
.rkt-Masthead__site-link-item:has(.rkt-Masthead__link:focus-visible) .rkt-Masthead__link-menu-background,
.rkt-Masthead__site-link-item .rkt-Masthead__link-menu-background:has(.Mui-focusVisible) {
  top: settings.$rkt-masthead-open-link-menu-background-top;
  transform: settings.$rkt-masthead-open-link-menu-background-transform;
  opacity: settings.$rkt-masthead-open-link-menu-background-opacity;
}

.rkt-Masthead__site-link-item:hover .rkt-Masthead__link-menu-container,
.rkt-Masthead__site-link-item:has(.rkt-Masthead__link:focus-visible) .rkt-Masthead__link-menu-container {
  padding: settings.$rkt-masthead-open-link-menu-container-padding;
}

.rkt-Masthead__link-menu {
  list-style: settings.$rkt-masthead-link-menu-list-style;
}

.rkt-Masthead__site-link-item,
.rkt-Masthead__utility-link-item {
  display: settings.$rkt-masthead-site-link-item-display;
  align-items: settings.$rkt-masthead-site-link-item-align-items;
  // Deprecated in v7.1 - remove in v9 - Task #830136
  border-bottom: settings.$rkt-masthead-site-link-item-border-bottom;
  height: settings.$rkt-masthead-site-link-item-height;

  .rkt-Link:hover {
    text-decoration: settings.$rkt-masthead-site-link-item-hover-text-decoration;
  }
}

.rkt-Masthead__site-link-item:hover,
.rkt-Masthead__site-link-item:has(.rkt-Masthead__link:focus-visible),
.rkt-Masthead__site-link-item:has(.Mui-focusVisible) {
  cursor: settings.$rkt-masthead-site-link-item-hover-cursor;
  // Deprecated in v7.1 - remove in v9 - Task #830136
  border-bottom: settings.$rkt-masthead-site-link-item-hover-border-bottom;
  background-image: settings.$rkt-masthead-site-link-item-hover-background-image;
  background-repeat: settings.$rkt-masthead-site-link-item-hover-background-repeat;
  background-position: settings.$rkt-masthead-site-link-item-hover-background-position;
  background-size: settings.$rkt-masthead-site-link-item-hover-background-size;
}

.rkt-Masthead__site-link-item:hover .rkt-Masthead__link {
  color: settings.$rkt-masthead-site-link-item-hover-color;
}

.rkt-Masthead__site-link-item:has(.rkt-Masthead__link:focus-visible) .rkt-Masthead__link,
.rkt-Masthead__site-link-item:has(.Mui-focusVisible) .rkt-Masthead__link {
  color: settings.$rkt-masthead-site-link-item-focus-color;
  background-color: settings.$rkt-masthead-site-link-item-focus-background-color;
  border-radius: settings.$rkt-masthead-site-link-item-focus-border-radius;
  box-shadow: settings.$rkt-masthead-site-link-item-focus-box-shadow;
}

.rkt-Masthead__site-link-item .rkt-Masthead__link:focus-visible {
  outline: settings.$rkt-masthead-site-link-item-focus-outline;
}

.rkt-Masthead__link-menu-item:not(:last-of-type, .Mui-selected, :has( + .Mui-selected, + :hover), :hover) {
  border-bottom: settings.$rkt-masthead-link-menu-item-hover-border-bottom;
  
  &::after {
    content: settings.$rkt-masthead-link-menu-item-hover-after-content;
    display: settings.$rkt-masthead-link-menu-item-hover-after-display;
    position: settings.$rkt-masthead-link-menu-item-hover-after-position;
    top: settings.$rkt-masthead-link-menu-item-hover-after-top;
    height: settings.$rkt-masthead-link-menu-item-hover-after-height;
    width: settings.$rkt-masthead-link-menu-item-hover-after-width;
    background-color: settings.$rkt-masthead-link-menu-item-hover-after-background-color;
  }    
}

.rkt-Masthead__link-menu-item {
  padding: settings.$rkt-masthead-link-menu-item-padding;
  min-width: settings.$rkt-masthead-link-menu-item-min-width;
  border-bottom: settings.$rkt-masthead-link-menu-item-border-bottom;
  color: settings.$rkt-masthead-link-menu-item-color;
  align-items: settings.$rkt-masthead-link-menu-item-align-items;
  font-size: settings.$rkt-masthead-link-menu-item-font-size;
  font-weight: settings.$rkt-masthead-link-menu-item-font-weight;
  line-height: settings.$rkt-masthead-link-menu-item-line-height;

  &:hover,
  &.Mui-selected {
    border-radius: settings.$rkt-masthead-link-menu-item-hover-border-radius;
    background-color: settings.$rkt-masthead-link-menu-item-hover-background-color;
    color: settings.$rkt-masthead-link-menu-item-hover-color;
  }

  &.active {
    background-color: settings.$rkt-masthead-link-menu-item-active-background-color;
    color: settings.$rkt-masthead-link-menu-item-active-color;
  }

  &:focus-visible {
    border-radius: settings.$rkt-masthead-link-menu-item-focus-border-radius;
    box-shadow: settings.$rkt-masthead-link-menu-item-focus-box-shadow;
    background-color: settings.$rkt-masthead-link-menu-item-focus-background-color;
    color: settings.$rkt-masthead-link-menu-item-focus-color;
  }
}

.rkt-Masthead__content-links-container,
.rkt-Masthead__content-promo {
  padding: settings.$rkt-masthead-content-links-container-padding;
  min-width: settings.$rkt-masthead-content-links-container-min-width;
}

.rkt-Masthead__content-main-link {
  padding: settings.$rkt-masthead-content-main-link-padding;
}

.rkt-Masthead__content-cross-link-title {
  margin: settings.$rkt-masthead-content-cross-link-title-margin;
  font-size: settings.$rkt-masthead-content-cross-link-title-font-size;
  font-weight: settings.$rkt-masthead-content-cross-link-title-font-weight;
  line-height: settings.$rkt-masthead-content-cross-link-title-line-height;
  color: settings.$rkt-masthead-content-cross-link-title-color;
  font-family: settings.$rkt-masthead-link-font-family;
}

.rkt-Masthead__content-cross-link-container {
  list-style: settings.$rkt-masthead-content-cross-link-container-list-style;
  padding: settings.$rkt-masthead-content-cross-link-container-padding;
}

.rkt-Masthead__content-cross-link {
  padding: settings.$rkt-masthead-content-cross-link-padding;
}

.rkt-Masthead__avatar {
  border-radius: settings.$rkt-masthead-avatar-border-radius;
  color: settings.$rkt-masthead-avatar-color;
  background-color: settings.$rkt-masthead-avatar-background-color;
  margin-left: settings.$rkt-masthead-avatar-margin-left;
  height: settings.$rkt-masthead-avatar-height;
  width: settings.$rkt-masthead-avatar-width;
  display: settings.$rkt-masthead-avatar-display;
  align-items: settings.$rkt-masthead-avatar-align-items;
  justify-content: settings.$rkt-masthead-avatar-justify-content;
}

.rkt-Masthead__signed-in-menu {
  border-radius: settings.$rkt-masthead-signed-in-menu-border-radius;
  box-shadow: settings.$rkt-masthead-signed-in-menu-box-shadow;
  padding: settings.$rkt-masthead-signed-in-menu-padding;

  .MuiList-root {
    padding: settings.$rkt-masthead-signed-in-menu-list-padding;
  }
}

.rkt-Masthead__signed-in-menu-item:not(:last-of-type, .Mui-selected, :has( + .Mui-selected, + :hover), :hover),
.rkt-Masthead__signed-in-menu-item:has(+ .rkt-Masthead__signed-in-menu-item .rkt-Button) {
  border-bottom: settings.$rkt-masthead-signed-in-menu-item-hover-border-bottom;
}

.rkt-Masthead__signed-in-menu .rkt-Masthead__signed-in-menu-item {
  border-bottom: settings.$rkt-masthead-signed-in-menu-item-border-bottom;
  padding: settings.$rkt-masthead-signed-in-menu-item-padding;
  font-weight: settings.$rkt-masthead-signed-in-menu-item-font-weight;

  .rkt-Icon {
    color: settings.$rkt-masthead-signed-in-menu-item-icon-color;
    height: settings.$rkt-masthead-signed-in-menu-item-icon-height;
    width: settings.$rkt-masthead-signed-in-menu-item-icon-width;
    margin-right: settings.$rkt-masthead-signed-in-menu-item-icon-margin-right;
  }

  &:hover {
    border-radius: settings.$rkt-masthead-signed-in-menu-item-hover-border-radius;
    color: settings.$rkt-masthead-signed-in-menu-item-hover-color;
    background-color: settings.$rkt-masthead-signed-in-menu-item-hover-background-color;
  }

  &:focus-visible {
    border-radius: settings.$rkt-masthead-signed-in-menu-item-focus-border-radius;
    box-shadow: settings.$rkt-masthead-signed-in-menu-item-focus-box-shadow;
    background-color: settings.$rkt-masthead-signed-in-menu-item-focus-background-color;
  }

  &:active {
    background-color: settings.$rkt-masthead-signed-in-menu-item-active-background-color;
  }

  &:hover:has(.rkt-Button),
  &:focus:has(.rkt-Button),
  &:active:has(.rkt-Button) {
    background-color: settings.$rkt-masthead-signed-in-menu-item-hover-has-button-background-color;
    cursor: settings.$rkt-masthead-signed-in-menu-item-hover-has-button-cursor;
    box-shadow: settings.$rkt-masthead-signed-in-menu-item-hover-has-button-box-shadow;

    .rkt-Button:hover,
    .rkt-Button:active {
      cursor: settings.$rkt-masthead-signed-in-menu-item-hover-has-button-hover-cursor;
    }

    .MuiTouchRipple-root {
      display: settings.$rkt-masthead-signed-in-menu-item-ripple-display;
    }
  }

  &:focus-visible:has(.rkt-Button) {
    .rkt-Button {
      box-shadow: settings.$rkt-masthead-signed-in-menu-item-focus-has-button-focus-box-shadow;
    }
  }
}

.rkt-Masthead__signed-in-menu-item:has(.rkt-Button) {
  justify-content: settings.$rkt-masthead-signed-in-menu-item-has-button-justify-content;

  .rkt-Button {
    width: settings.$rkt-masthead-signed-in-menu-item-has-button-width;
    display: settings.$rkt-masthead-signed-in-menu-item-has-button-display;
    align-items: settings.$rkt-masthead-signed-in-menu-item-has-button-align-items;
    justify-content: settings.$rkt-masthead-signed-in-menu-item-has-button-justify-content;
    padding: settings.$rkt-masthead-signed-in-menu-item-has-button-padding;
  }
}

.rkt-Masthead__nav-collapsible-menu {
  list-style: settings.$rkt-masthead-nav-collapsible-menu-list-style;
  padding: settings.$rkt-masthead-nav-collapsible-menu-padding;
  margin: settings.$rkt-masthead-nav-collapsible-menu-margin;
}

.rkt-Masthead__nav-collapsible-menu-item:not(:last-of-type, :has( + :hover), :hover) {
  border-bottom: settings.$rkt-masthead-nav-collapsible-menu-item-hover-border-bottom;
}

.rkt-Masthead__nav-collapsible-menu-item {
  padding: settings.$rkt-masthead-nav-collapsible-menu-item-padding;
  border-bottom: settings.$rkt-masthead-nav-collapsible-menu-item-border-bottom;
  margin-bottom: settings.$rkt-masthead-nav-collapsible-menu-item-margin-bottom;

  &:hover {
    border-radius: settings.$rkt-masthead-nav-collapsible-menu-item-hover-border-radius;
    background-color: settings.$rkt-masthead-nav-collapsible-menu-item-hover-background-color;
    color: settings.$rkt-masthead-nav-collapsible-menu-item-hover-color;
  }

  &.active {
    background-color: settings.$rkt-masthead-nav-collapsible-menu-item-active-background-color;
    color: settings.$rkt-masthead-nav-collapsible-menu-item-active-color;
  }

  &:has(.rkt-Masthead__nav-collapsible-menu-item-link:focus-visible) {
    border-radius: settings.$rkt-masthead-nav-collapsible-menu-item-focus-border-radius;
    box-shadow: settings.$rkt-masthead-nav-collapsible-menu-item-focus-box-shadow;
    background-color: settings.$rkt-masthead-nav-collapsible-menu-item-focus-background-color;
    color: settings.$rkt-masthead-nav-collapsible-menu-item-focus-color;

    .rkt-Masthead__nav-collapsible-menu-item-link {
      outline: settings.$rkt-masthead-nav-collapsible-menu-item-focus-outline;
    }
  }
}

.rkt-Masthead__nav-collapsible-menu-item:last-of-type {
  margin-bottom: settings.$rkt-masthead-nav-collapsible-menu-item-last-margin;
}

.rkt-Masthead__nav-collapsible-menu-item-link {
  text-decoration: settings.$rkt-masthead-nav-collapsible-menu-link-text-decoration;
  font-size: settings.$rkt-masthead-nav-collapsible-menu-link-font-size;
  line-height: settings.$rkt-masthead-nav-collapsible-menu-link-line-height;
  font-weight: settings.$rkt-masthead-nav-collapsible-menu-link-font-weight;
  font-family: settings.$rkt-masthead-link-font-family;
  color: settings.$rkt-masthead-nav-collapsible-menu-link-color;
  display: settings.$rkt-masthead-nav-collapsible-menu-link-display;
  height: settings.$rkt-masthead-nav-collapsible-menu-link-height;
}

.rkt-Masthead__nav-collapsible-menu-item-text {
  flex-grow: settings.$rkt-masthead-nav-collapsible-menu-text-flex-grow;
}

.rkt-Masthead__nav-collapsible-menu-item-icon {
  display: settings.$rkt-masthead-nav-collapsible-menu-icon-display;
}

.rkt-Masthead__nav-collapsible-drawer {
  top: settings.$rkt-masthead-nav-collapsible-drawer-top;
  height: settings.$rkt-masthead-nav-collapsible-drawer-height;


  .MuiDrawer-paper {
    height: settings.$rkt-masthead-nav-collapsible-drawer-height;
    top: settings.$rkt-masthead-nav-collapsible-drawer-top;
    width: settings.$rkt-masthead-nav-collapsible-drawer-width;
    box-shadow: settings.$rkt-masthead-nav-collapsible-drawer-box-shadow;
  }

  .rkt-Masthead__nav-collapsible-drawer-content {
    padding: settings.$rkt-masthead-nav-collapsible-drawer-content-padding;

    .MuiAccordion-root {
      box-shadow: settings.$rkt-masthead-nav-collapsible-drawer-content-box-shadow;
    }

    .rkt-AccordionPanel:hover,
    .rkt-AccordionPanel.Mui-expanded {
      background: settings.$rkt-masthead-nav-collapsible-drawer-content-background;
    }

    .rkt-AccordionPanel:not(:last-of-type) {
      margin:settings.$rkt-masthead-nav-collapsible-drawer-content-last-margin;
    }

    .rkt-AccordionPanel.MuiAccordion-root.Mui-expanded {
      margin-top: settings.$rkt-masthead-nav-collapsible-drawer-content-expanded-margin-top;
    }

    .rkt-AccordionPanel__title,
    .rkt-AccordionPanel__title.Mui-expanded {
      padding: settings.$rkt-masthead-nav-collapsible-drawer-content-title-padding;

      .rkt-AccordionPanel__header-title {
        font-size: settings.$rkt-masthead-nav-collapsible-drawer-content-title-font-size;
      }
    }

    .MuiAccordionSummary-expandIconWrapper {
      transform: settings.$rkt-masthead-nav-collapsible-drawer-content-icon-transform;
    }

    .rkt-AccordionPanel__title.Mui-expanded .MuiAccordionSummary-expandIconWrapper {
      transform: settings.$rkt-masthead-nav-collapsible-drawer-content-icon-expanded-transform;
    }

    .rkt-AccordionPanel__details {
      padding: settings.$rkt-masthead-nav-collapsible-drawer-content-details-padding;

      .rkt-Link:hover {
        text-decoration: settings.$rkt-masthead-nav-collapsible-drawer-content-details-hover-text-decoration;
      }
    }
  }

  .rkt-Masthead__nav-collapsible-breadcrumb {
    padding: settings.$rkt-masthead-nav-collapsible-breadcrumb-padding;
    margin-bottom: settings.$rkt-masthead-nav-collapsible-breadcrumb-margin-bottom;
    width: settings.$rkt-masthead-nav-collapsible-breadcrumb-width;

    .rkt-Icon {
      color: settings.$rkt-masthead-nav-collapsible-breadcrumb-icon-color;
    }
  }
}

.rkt-Masthead__nav-collapsible-utilities {
  position: settings.$rkt-masthead-nav-collapsible-utilities-position;
  left: settings.$rkt-masthead-nav-collapsible-utilities-left;
  bottom: settings.$rkt-masthead-nav-collapsible-utilities-bottom;
  height: settings.$rkt-masthead-nav-collapsible-utilities-height;
  width: settings.$rkt-masthead-nav-collapsible-utilities-width;
  justify-content: settings.$rkt-masthead-nav-collapsible-utilities-justify-content;
  align-items: settings.$rkt-masthead-nav-collapsible-utilities-align-items;
  background-color: settings.$rkt-masthead-nav-collapsible-utilities-background-color;

  .rkt-Masthead__nav-collapsible-utility-item {
    width: settings.$rkt-masthead-nav-collapsible-utilities-item-width;
    display: settings.$rkt-masthead-nav-collapsible-utilities-item-display;
    justify-content: settings.$rkt-masthead-nav-collapsible-utilities-item-justify-content;

    .rkt-Button {
      width: settings.$rkt-masthead-nav-collapsible-utilities-item-button-width;
      margin: settings.$rkt-masthead-nav-collapsible-utilities-item-button-margin;
    }
  }
}