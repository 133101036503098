@use '../web/scss/settings';

.rkt-Badge.mat-badge-overlap .mat-badge-content,
.rkt-Badge .mat-badge-content,
.rkt-Badge.MuiBadge-badge {
  background-color: settings.$rkt-badge-background-color;
  border-radius: settings.$rkt-badge-border-radius;
  color: settings.$rkt-badge-color;
  font-weight: settings.$rkt-badge-font-weight;
  font-family: settings.$rkt-badge-font-family;
  font-size: settings.$rkt-badge-font-size;
  height: settings.$rkt-badge-height;
  line-height: settings.$rkt-badge-line-height;
  transition: settings.$rkt-badge-transition;
  width: settings.$rkt-badge-width;
}

.rkt-Badge.rkt-Badge--has-large-value .mat-badge-content,
.rkt-Badge--has-large-value.MuiBadge-badge {
  width: settings.$rkt-badge-has-large-value-width;
}

.rkt-Badge.mat-badge.mat-badge-overlap.mat-badge-after.rkt-Badge--has-large-value .mat-badge-content {
  right: settings.$rkt-badge-has-large-value-right;
}

.rkt-Badge--has-large-value.MuiBadge-badge {
  transform: settings.$rkt-badge-has-large-value-transform;
}

.rkt-Badge.rkt-Badge--has-large-value.rkt-Badge--has-label.MuiBadge-badge {
  right: settings.$rkt-badge-has-large-value-has-label-right;
}

.rkt-Badge.mat-badge.mat-badge-overlap.mat-badge-after.rkt-Badge--has-large-value.rkt-Badge--has-icon
.mat-badge-content {
  right: settings.$rkt-badge-has-large-value-has-icon-right;
}

.rkt-Badge.rkt-Badge--has-large-value.rkt-Badge--has-label .mat-badge-content {
  right: settings.$rkt-badge-has-large-value-has-label-content-right;
}

.MuiBadge-root:has(.rkt-Button) .MuiBadge-badge {
  right: settings.$rkt-badge-has-button-right;
  top: settings.$rkt-badge-has-button-top;
}

.MuiBadge-root:has(.rkt-Badge--has-label),
.rkt-Badge--has-label {
  color: settings.$rkt-badge-label-color;
  font-size: settings.$rkt-badge-label-font-size;
  font-family: settings.$rkt-badge-label-font-family;
  font-weight: settings.$rkt-badge-label-font-weight;
  line-height: settings.$rkt-badge-label-line-height;
  margin: settings.$rkt-badge-label-margin;
}

.rkt-Badge--has-label.mat-badge {
  padding-right: settings.$rkt-badge-label-padding-right;
}

.MuiBadge-root:has(.rkt-Badge--has-label) .MuiBadge-badge {
  right: settings.$rkt-badge-has-label-right;
  top: settings.$rkt-badge-has-label-top;
}

.MuiBadge-root:has(.rkt-Badge--has-icon) .MuiBadge-badge {
  top: settings.$rkt-badge-has-icon-top;
  right: settings.$rkt-badge-has-icon-right;
}

.rkt-Badge.mat-badge.mat-badge-overlap.mat-badge-after .mat-badge-content {
  top: settings.$rkt-badge-content-top;
  right: settings.$rkt-badge-content-right;
  left: settings.$rkt-badge-content-left;
  margin: settings.$rkt-badge-content-margin;
}

.rkt-Badge--has-icon.mat-badge.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: settings.$rkt-badge-has-icon-badge-content-right;
  top: settings.$rkt-badge-has-icon-badge-content-top;
}

.rkt-Badge--success.mat-badge .mat-badge-content,
.rkt-Badge--success.MuiBadge-badge {
  background-color: settings.$rkt-badge-success-background-color;
  color: settings.$rkt-badge-success-color;
}

.rkt-Badge--success-light.mat-badge .mat-badge-content,
.rkt-Badge--success-light.MuiBadge-badge {
  background-color: settings.$rkt-badge-success-light-background-color;
  color: settings.$rkt-badge-success-light-color;
}

.rkt-Badge--info.mat-badge .mat-badge-content,
.rkt-Badge--info.MuiBadge-badge {
  background-color: settings.$rkt-badge-info-background-color;
  color: settings.$rkt-badge-info-color;
}

.rkt-Badge--info-light.mat-badge .mat-badge-content,
.rkt-Badge--info-light.MuiBadge-badge {
  background-color: settings.$rkt-badge-info-light-background-color;
  color: settings.$rkt-badge-info-light-color;
}

.rkt-Badge--warn.mat-badge .mat-badge-content,
.rkt-Badge--warn.MuiBadge-badge {
  background-color: settings.$rkt-badge-warn-background-color;
  color: settings.$rkt-badge-warn-color;
}

.rkt-Badge--warn-light.mat-badge .mat-badge-content,
.rkt-Badge--warn-light.MuiBadge-badge {
  background-color: settings.$rkt-badge-warn-light-background-color;
  color: settings.$rkt-badge-warn-light-color;
}

.rkt-Badge--primary.mat-badge .mat-badge-content,
.rkt-Badge--primary.MuiBadge-badge {
  background-color: settings.$rkt-badge-primary-background-color;
  color: settings.$rkt-badge-primary-color;
}