// ==================================================================
// Promo
// ==================================================================
////
/// @group promo
////
@use '../web/scss/settings' as *;

.rkt-Promo {
  background-color: $rkt-promo-background-color;
  box-sizing: border-box;
  max-width: $rkt-promo-max-width;
  margin: $rkt-promo-margin;
}

.rkt-Promo--full {
  max-width: $rkt-promo-max-width-full;
}

.rkt-Promo__heading {
  color: $rkt-promo-heading-font-color;
  font-weight: $rkt-promo-heading-font-weight;
  font-family: $rkt-promo-heading-font-family;
  font-size: $rkt-promo-heading-font-size;
  line-height: $rkt-promo-heading-line-height;
  margin: $rkt-promo-heading-margin;

  // Responsive Type for Heading-28
  @media all and (max-width: $rkt-split-breakpoint-5) {
    font-size: $rkt-promo-heading-font-size-medium;
    line-height: $rkt-promo-heading-line-height-medium;
  }

  @media all and (max-width: $rkt-split-breakpoint-2) {
    font-size: $rkt-promo-heading-font-size-small;
    line-height: $rkt-promo-heading-line-height-small;
  }
}

.rkt-Promo__subheading {
  color: $rkt-promo-subheading-font-color;
  font-weight: $rkt-promo-subheading-font-weight;
}

.rkt-Promo__image {
  box-sizing: border-box;
  height: $rkt-promo-image-height;
  object-fit: $rkt-promo-image-object-fit;
  max-width: 100%;
  width: $rkt-promo-image-width;

  @media (max-width: $rkt-promo-breakpoint) {
    max-height: $rkt-promo-image-narrow-max-height;
  }
}

// React specific
.rkt-Promo__image-container {
  width: 100%;
  @media (min-width: $rkt-promo-breakpoint) {
    max-width: $rkt-promo-image-container-wide-max-width;
  }
}

// /// Adds styles for the image
// /// in the Flag Promo variant.
.rkt-Promo__image--flag {
  height: $rkt-promo-image-flag-height;
  padding: $rkt-promo-image-flag-padding;
  width: $rkt-promo-image-flag-width;

  @media (max-width: $rkt-promo-breakpoint) {
    max-width: $rkt-promo-image-flag-narrow-max-width;
  }
}

.rkt-Promo__content {
  box-sizing: border-box;
  padding: $rkt-promo-content-padding;
}

///	Adds styles for the Flag Promo variant.
.rkt-Promo--flag .rkt-Promo__content {
  padding: $rkt-promo-flag-content-padding;
}

.rkt-Promo__content-text {
  color: $rkt-promo-content-text-color;
  font-size: $rkt-promo-content-text-font-size;
  font-family: $rkt-promo-content-text-font-family;
  font-weight: $rkt-promo-content-text-font-weight;
  line-height: $rkt-promo-content-text-line-height;
  margin: $rkt-promo-content-text-margin;
}
