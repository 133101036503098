@use '../web/scss/settings';

.rkt-HorizontalDivider,
.rkt-HorizontalDivider.mat-divider {
  border-top-width: settings.$rkt-divider-horizontal-border-top-width;
  border-bottom-width: settings.$rkt-divider-horizontal-border-bottom-width;
  border-color: settings.$rkt-divider-horizontal-border-color;
}

.rkt-HorizontalDivider--thick,
.rkt-HorizontalDivider--thick.mat-divider {
  border-top-width: settings.$rkt-divider-horizontal-thick-border-top-width;
  border-color: settings.$rkt-divider-horizontal-thick-border-color;
}

.rkt-VerticalDivider,
.rkt-VerticalDivider.mat-divider.mat-divider-vertical {
  border-right-width: settings.$rkt-divider-vertical-border-right-width;
  border-right-color: settings.$rkt-divider-vertical-border-right-color;
}

.rkt-VerticalDivider--thick,
.rkt-VerticalDivider--thick.mat-divider.mat-divider-vertical {
  border-right-color: settings.$rkt-divider-vertical-thick-border-right-color;
  border-right-width: settings.$rkt-divider-vertical-thick-border-right-width;
}

.rkt-HorizontalDivider--on-dark,
.rkt-HorizontalDivider--on-dark.mat-divider {
  border-bottom-color: settings.$rkt-divider-horizontal-on-dark-border-top-color;
  border-top-color: settings.$rkt-divider-horizontal-on-dark-border-top-color;
}

.rkt-VerticalDivider--on-dark,
.rkt-VerticalDivider--on-dark.mat-divider.mat-divider-vertical {
  border-right-color: settings.$rkt-divider-vertical-on-dark-border-right-color;
}
