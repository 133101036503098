@use '../web/scss/tag';
@use '../web/scss/settings';

.rkt-Tag {
  align-items: tag.$rkt-tag-align-items;
  background-color: tag.$rkt-tag-background-color;
  border-radius: tag.$rkt-tag-border-radius;
  box-sizing: tag.$rkt-tag-box-sizing;
  display: tag.$rkt-tag-display;
  height: tag.$rkt-tag-height;
  justify-content: tag.$rkt-tag-justify-content;
  min-width: tag.$rkt-tag-min-width;
  padding: tag.$rkt-tag-padding;
}

.rkt-Tag__text {
  color: tag.$rkt-tag-text-color;
  font-family: tag.$rkt-tag-text-font-family;
  font-size: tag.$rkt-tag-text-font-size;
  font-weight: tag.$rkt-tag-text-font-weight;
}

.rkt-Tag__text--on-dark {
  color: tag.$rkt-tag-text-color-light;
}

.rkt-Tag--has-icon-left {
  padding-left: tag.$rkt-tag-has-icon-left-padding-left;
}

.rkt-Tag--has-icon-right {
  padding-right: tag.$rkt-tag-has-icon-right-padding-right;
}

.rkt-Tag--has-icon-right .rkt-Tag__text {
  margin-right: tag.$rkt-tag-has-icon-right-text-margin-right;
}

.rkt-Tag--has-icon-left .rkt-Tag__icon {
  margin-right: tag.$rkt-tag-has-icon-left-icon-margin-right;
}

.rkt-Tag .rkt-Tag__icon {
  color: tag.$rkt-tag-icon-color;
  height: tag.$rkt-tag-icon-height;
  width: tag.$rkt-tag-icon-width;

  svg path {
    stroke-width: tag.$rkt-tag-icon-stroke-width;
  }
}

.rkt-Tag--warn {
  background-color: tag.$rkt-tag-bg-color-warning;
}

.rkt-Tag--warn .rkt-Tag__icon {
  color: tag.$rkt-tag-icon-color-warning;
}

.rkt-Tag--warn .rkt-Tag__text {
  color: tag.$rkt-tag-text-color-warning;
}

.rkt-Tag--info {
  background-color: tag.$rkt-tag-bg-color-info;
}

.rkt-Tag--info .rkt-Tag__icon {
  color: tag.$rkt-tag-icon-color-info;
}

.rkt-Tag--info .rkt-Tag__text {
  color: tag.$rkt-tag-text-color-info;
}

.rkt-Tag--success {
  background-color: tag.$rkt-tag-bg-color-success;
}

.rkt-Tag--success .rkt-Tag__icon {
  color: tag.$rkt-tag-icon-color-success;
}

.rkt-Tag--success .rkt-Tag__text {
  color: tag.$rkt-tag-text-color-success;
}

.rkt-Tag--secondary {
  background-color: tag.$rkt-tag-bg-color-secondary;
}

.rkt-Tag--secondary .rkt-Tag__icon {
  color: tag.$rkt-tag-icon-color-secondary;
}

.rkt-Tag--secondary .rkt-Tag__text {
  color: tag.$rkt-tag-text-color-secondary;
}

.rkt-Tag--primary {
  background-color: tag.$rkt-tag-bg-color-primary;
}

.rkt-Tag--primary .rkt-Tag__icon {
  color: tag.$rkt-tag-icon-color-primary;
}

.rkt-Tag--primary .rkt-Tag__text {
  color: tag.$rkt-tag-text-color-primary;
}

.rkt-Tag--on-dark {
  background-color: tag.$rkt-tag-bg-color-light;
}

.rkt-Tag--on-dark .rkt-Tag__icon {
  color: tag.$rkt-tag-icon-color-light;
}
