@use '../web/scss/settings' as *;

.rkt-Checkbox,
.rkt-Checkbox.mat-mdc-checkbox,
.rkt-List .rkt-List__button {
  &.MuiCheckbox-root {
    margin-right: $rkt-checkbox-root-margin-right;
    height: $rkt-checkbox-root-height;
    width: $rkt-checkbox-root-width;
    color: $rkt-checkbox-root-color;
  }

  .mdc-checkbox,
  .mdc-checkbox .mdc-checkbox__native-control,
  .mdc-checkbox__native-control ~ .mdc-checkbox__background {
    height: $rkt-checkbox-height;
    width: $rkt-checkbox-width;
  }

  .mdc-checkbox {
    margin: $rkt-checkbox-margin;
    display: $rkt-checkbox-display;
    justify-content: $rkt-checkbox-justify-content;
  }

  .mdc-form-field--align-end .mdc-checkbox {
    margin: $rkt-checkbox-icon-right-margin;
  }

  .mdc-checkbox .mdc-checkbox__native-control {
    top: unset;
    right: unset;
    left: unset;
  }

  .mdc-checkbox__ripple,
  .mat-mdc-checkbox-ripple,
  .MuiTouchRipple-root {
    display: none;
  }

  .mat-mdc-checkbox-touch-target {
    height: $rkt-checkbox-touch-target-height;
    width: $rkt-checkbox-touch-target-width;
    border-radius: $rkt-checkbox-touch-target-border-radius;
  }

  .mdc-checkbox__checkmark {
    width: $rkt-checkbox-checkmark-width;
    height: $rkt-checkbox-checkmark-height;
    margin: $rkt-checkbox-checkmark-margin;
  }

  .mdc-checkbox__mixedmark {
    width: $rkt-checkbox-indeterminate-width;
  }
}

.rkt-Checkbox.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled~.mdc-checkbox__background,
.rkt-Checkbox.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control[disabled]~.mdc-checkbox__background,
.rkt-List .rkt-List__button .mdc-checkbox .mdc-checkbox__native-control:enabled~.mdc-checkbox__background,
.rkt-List .rkt-List__button .mdc-checkbox .mdc-checkbox__native-control[disabled]~.mdc-checkbox__background {
  border-radius: $rkt-checkbox-frame-border-radius;
  top: unset;
  left: unset;
  transition: unset;
}

.rkt-Checkbox.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background,
.rkt-Checkbox.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control[disabled]:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background,
.rkt-List .rkt-List__button .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background,
.rkt-List .rkt-List__button .mdc-checkbox .mdc-checkbox__native-control[disabled]:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
  border: $rkt-checkbox-frame-border;
  background-color: $rkt-checkbox-frame-background-color;
}

// Label
.rkt-Checkbox__label,
.rkt-Checkbox + .MuiFormControlLabel-label,
.rkt-Checkbox .mdc-form-field label {
  color: $rkt-checkbox-label-color;
  font-family: $rkt-checkbox-label-font-family;
  font-size: $rkt-checkbox-label-font-size;
  font-weight: $rkt-checkbox-label-font-weight;
  line-height: $rkt-checkbox-label-line-height;
  padding: $rkt-checkbox-label-padding;
}

// Checked
.rkt-Checkbox.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
.rkt-Checkbox.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background,
.rkt-List .rkt-List__button .mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
.rkt-List .rkt-List__button .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background  {
  border-color: $rkt-checkbox-frame-checked-border-color;
  background: $rkt-checkbox-frame-checked-background;
}

.rkt-Checkbox.MuiCheckbox-root.Mui-checked,
.rkt-Checkbox.MuiCheckbox-root.MuiCheckbox-indeterminate {
  color: $rkt-checkbox-root-checked-color;
}

// Hover
.rkt-Checkbox.MuiCheckbox-root:hover,
.rkt-Checkbox.mat-mdc-checkbox .mdc-checkbox:hover .mat-mdc-checkbox-touch-target,
.rkt-Checkbox.mat-mdc-checkbox .mdc-checkbox:hover .mat-mdc-checkbox-touch-target:has(+ .mdc-checkbox__native-control:focus-visible) {
  background-color: $rkt-checkbox-hover-touch-target-background-color;
}

.rkt-Checkbox.mat-mdc-checkbox .mdc-checkbox:hover .mdc-checkbox__native-control:not(:focus-visible):enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background,
.rkt-List .rkt-List__button .mdc-checkbox:hover .mdc-checkbox__native-control:not(:focus-visible):enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
  background-color: $rkt-checkbox-hover-native-control-background-color;
  border-color: $rkt-checkbox-frame-hover-border-color;
}

.rkt-Checkbox.mat-mdc-checkbox .mdc-checkbox:hover {
  .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
  .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background {
    background-color: $rkt-checkbox-hover-checked-native-control-background-color;
    border-color: $rkt-checkbox-frame-checked-hover-border-color;
  }
}

// Focus
.rkt-Checkbox.mat-mdc-checkbox .mdc-checkbox__native-control:focus:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
  border-color: $rkt-checkbox-frame-hover-border-color;
}

.rkt-Checkbox.mat-mdc-checkbox .mdc-checkbox:focus-visible,
.rkt-Checkbox.mat-mdc-checkbox .mdc-checkbox:not(:disabled):active {
  outline: $rkt-checkbox-focus-outline;
}

.rkt-Checkbox.mat-mdc-checkbox  .mat-mdc-checkbox-touch-target:has(+ .mdc-checkbox__native-control:focus-visible) {
  background-color: $rkt-checkbox-focus-touch-target-background-color;
}

.rkt-Checkbox.mat-mdc-checkbox .mdc-checkbox__native-control:focus-visible:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background,
.rkt-Checkbox.mat-mdc-checkbox .mdc-checkbox:hover .mdc-checkbox__native-control:focus-visible:enabled:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
  background-color: $rkt-checkbox-focus-native-control-background-color;
  border-color: $rkt-checkbox-frame-focus-border-color;
  box-shadow: $rkt-checkbox-frame-focus-box-shadow;
}

.rkt-Checkbox.mat-mdc-checkbox .mdc-checkbox:not(:disabled):active .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
.rkt-Checkbox.mat-mdc-checkbox .mdc-checkbox:not(:disabled):active .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background,
.rkt-Checkbox.mat-mdc-checkbox .mdc-checkbox__native-control:focus-visible:enabled:checked~.mdc-checkbox__background,
.rkt-Checkbox.mat-mdc-checkbox .mdc-checkbox__native-control:focus-visible:enabled:indeterminate~.mdc-checkbox__background {
  transition: unset;
  background-color: $rkt-checkbox-focus-checked-native-control-background-color;
  border-color: $rkt-checkbox-frame-checked-hover-border-color;
}

.rkt-Checkbox.MuiCheckbox-root:focus-visible,
.rkt-Checkbox.Mui-focusVisible {
  background-color: $rkt-checkbox-focus-touch-target-background-color;

  &:not(.Mui-checked, .MuiCheckbox-indeterminate) {
    .rkt-Checkbox__icon-border {
      stroke: $rkt-checkbox-frame-focus-border-color;
      stroke-width: $rkt-checkbox-focus-icon-border-stroke-width;
      x: $rkt-checkbox-focus-icon-border-x;
      y: $rkt-checkbox-focus-icon-border-y;
      rx: $rkt-checkbox-focus-icon-border-rx;
      height: $rkt-checkbox-focus-icon-border-height;
      width: $rkt-checkbox-focus-icon-border-width;
    }
  }
}

.rkt-Checkbox__icon-border {
  stroke-width: $rkt-checkbox-stroke-width;
}

// Disabled
.rkt-Checkbox.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control[disabled]:checked~.mdc-checkbox__background,
.rkt-Checkbox.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control[disabled]:indeterminate~.mdc-checkbox__background,
.rkt-List .rkt-List__button .mdc-checkbox .mdc-checkbox__native-control[disabled]:indeterminate~.mdc-checkbox__background {
  background-color: $rkt-checkbox-disabled-color;
  border-color: $rkt-checkbox-disabled-border-color;
  border-width: $rkt-checkbox-disabled-border-width;

  .mdc-checkbox__checkmark {
    display: $rkt-checkbox-disabled-icon-display;
  }
}

// Disabled checkbox label color
.rkt-Checkbox.mdc-checkbox--disabled .mdc-form-field label,
.Mui-disabled .rkt-Checkbox__label,
.Mui-disabled + .MuiFormControlLabel-label {
  color: $rkt-checkbox-disabled-label-color;
}

.rkt-Checkbox.MuiCheckbox-root.Mui-disabled.Mui-checked,
.rkt-Checkbox.MuiCheckbox-root.Mui-disabled.MuiCheckbox-indeterminate,
.rkt-Checkbox.Mui-disabled {
  color: $rkt-checkbox-disabled-border-color;
}

.rkt-Checkbox.Mui-disabled.Mui-checked .mdc-checkbox__checkmark,
.rkt-Checkbox.Mui-disabled.MuiCheckbox-indeterminate .mdc-checkbox__checkmark {
  color: $rkt-checkbox-disabled-color;
}

.MuiFormControlLabel-root:has(.rkt-Checkbox) {
  width: fit-content;
  margin-left: unset;
  margin-right: unset;
}

// we want to reduce the vertical spacing between checkboxes in checkbox groups (Angular Only)
.rkt-CheckboxGroup .rkt-Stack__item:has(.rkt-Checkbox) {
  margin-bottom: unset;
}

.MuiFormGroup-root .MuiFormControlLabel-root:has(.rkt-Checkbox) {
  margin-bottom: $rkt-checkbox-group-checkbox-margin-bottom;
}

.rkt-Checkbox[labelposition="before"] label {
  margin-left: $rkt-checkbox-icon-right-label-margin-left;
}
