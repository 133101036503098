@use '../web/scss/settings' as *;

.rkt-FormField:not(.rkt-FormField--has-textarea):not(.rkt-FormField--has-left-icon).mat-mdc-form-field-type-mat-select .mat-mdc-text-field-wrapper,
.rkt-FormField:not(.rkt-FormField--has-textarea):not(.rkt-FormField--has-left-icon) .MuiInputBase-root:has(.rkt-Input.MuiSelect-select)  {
  padding: $rkt-select-form-field-padding;
}

.rkt-FormField.mat-mdc-form-field-type-mat-select .mat-mdc-floating-label {
  font-family: $rkt-select-form-field-label-font-family;
}

.rkt-FormField.mat-mdc-form-field.mat-focused .rkt-Input .mat-mdc-select-placeholder {
  color: $rkt-select-input-placeholder-focus-color;
}

.rkt-FormField:hover .rkt-Input.mat-mdc-select .mat-mdc-select-placeholder {
  color: $rkt-select-input-placeholder-hover-color;
}

.rkt-FormField.mat-input-disabled .rkt-Input.mat-mdc-select-disabled .mat-mdc-select-placeholder {
  color: $rkt-select-input-placeholder-disabled-color;
}

.rkt-FormField:not(.rkt-FormField--full-width):has(.rkt-Input.mat-mdc-select) {
  width: $rkt-select-form-field-width;
}

.rkt-FormField:has(.rkt-Input.mat-mdc-select) .mat-mdc-text-field-wrapper {
  width: $rkt-select-input-width;
}

// Angular Select Input
.rkt-FormField .rkt-Input.mat-mdc-select {
  .mat-mdc-select-placeholder {
    color: $rkt-select-input-placeholder-color;
    font-family: $rkt-select-input-placeholder-font-family;
    font-size: $rkt-select-input-placeholder-font-size;
    font-style: $rkt-select-input-placeholder-font-style;
    font-weight: $rkt-select-input-placeholder-font-weight;
    line-height: $rkt-select-input-placeholder-line-height;
  }

  .mat-mdc-select-value,
  .mat-mdc-select-value-text {
    color: $rkt-select-input-selected-value-color;
  }

  .mat-mdc-select-arrow-wrapper {
    display: $rkt-select-input-arrow-display;
  }

  /*
    Chevron icon pointing down at default state
    Note: the color of the arrow is encoded within the background image SVG url
  */
  &:not(.mat-mdc-select-disabled, .mat-mdc-select-invalid) .mat-mdc-select-trigger::after {
    content: ' ';
    display: $rkt-select-input-chevron-display;
    background-image: $rkt-select-input-chevron-default-background-image;
    width: $rkt-select-input-chevron-width;
    height: $rkt-select-input-chevron-height;
    position: $rkt-select-input-chevron-position;
    top: $rkt-select-input-chevron-top;
    right: $rkt-select-input-chevron-right;
    background-repeat: $rkt-select-input-chevron-background-repeat;
    background-position: $rkt-select-input-chevron-background-position;
  }

  /*
    Chevron icon pointing up when the select is expanded
    Note: the color of the arrow is encoded within the background image SVG url
  */
  &:not(.mat-mdc-select-disabled, .mat-mdc-select-invalid)[aria-expanded="true"] .mat-mdc-select-trigger::after {
    content: ' ';
    display: $rkt-select-input-chevron-display;
    background-image: $rkt-select-input-chevron-default-expanded-background-image;
    width: $rkt-select-input-chevron-width;
    height: $rkt-select-input-chevron-height;
    position: $rkt-select-input-chevron-position;
    top: $rkt-select-input-chevron-top;
    right: $rkt-select-input-chevron-right;
    background-repeat: $rkt-select-input-chevron-background-repeat;
    background-position: $rkt-select-input-chevron-background-position;
  }

  /*
    Chevron icon pointing down when the select is disabled
    Note: the color of the arrow is encoded within the background image SVG url
  */
  &.mat-mdc-select-disabled .mat-mdc-select-trigger::after {
    content: ' ';
    display: $rkt-select-input-chevron-display;
    background-image: $rkt-select-input-chevron-disabled-background-image;
    width: $rkt-select-input-chevron-width;
    height: $rkt-select-input-chevron-height;
    position: $rkt-select-input-chevron-position;
    top: $rkt-select-input-chevron-top;
    right: $rkt-select-input-chevron-right;
    background-repeat: $rkt-select-input-chevron-background-repeat;
    background-position: $rkt-select-input-chevron-background-position;
  }

  /*
    Chevron icon pointing down when the select is invalid
    Note: the color of the arrow is encoded within the background image SVG url
  */
  &.mat-mdc-select-invalid .mat-mdc-select-trigger::after {
    content: ' ';
    display: $rkt-select-input-chevron-display;
    background-image: $rkt-select-input-chevron-invalid-background-image;
    width: $rkt-select-input-chevron-width;
    height: $rkt-select-input-chevron-height;
    position: $rkt-select-input-chevron-position;
    top: $rkt-select-input-chevron-top;
    right: $rkt-select-input-chevron-right;
    background-repeat: $rkt-select-input-chevron-background-repeat;
    background-position: $rkt-select-input-chevron-background-position;
  }

  /*
    Chevron icon pointing up when the select is expanded and invalid
    Note: the color of the arrow is encoded within the background image SVG url
  */
  &.mat-mdc-select-invalid[aria-expanded="true"] .mat-mdc-select-trigger::after {
    content: ' ';
    display: $rkt-select-input-chevron-display;
    background-image: $rkt-select-input-chevron-invalid-expanded-background-image;
    width: $rkt-select-input-chevron-width;
    height: $rkt-select-input-chevron-height;
    position: $rkt-select-input-chevron-position;
    top: $rkt-select-input-chevron-top;
    right: $rkt-select-input-chevron-right;
    background-repeat: $rkt-select-input-chevron-background-repeat;
    background-position: $rkt-select-input-chevron-background-position;
  }
}

.rkt-FormField.mat-mdc-form-field-type-mat-select .rkt-ErrorText.mat-mdc-form-field-error {
  margin: $rkt-select-form-field-error-margin;
}

.rkt-FormField:has(.rkt-Input.MuiSelect-select) .rkt-ErrorText.MuiFormHelperText-root {
  margin: $rkt-select-form-field-error-margin-mui;
}

// Angular Select Panel
.cdk-overlay-pane:not(.mat-mdc-select-panel-above) {
  .mdc-menu-surface.mat-mdc-select-panel.mdc-menu-surface--open {
    border-radius: $rkt-select-panel-border-radius;
    border: $rkt-select-panel-border;
    background-color: $rkt-select-panel-background-color;
    box-shadow: $rkt-select-panel-box-shadow;
    padding: $rkt-select-panel-padding;
    margin: $rkt-select-panel-margin;
    max-height: $rkt-select-panel-max-height;

    .mat-mdc-option.mdc-list-item {
      padding: $rkt-select-panel-list-item-padding;
      border-radius: $rkt-select-panel-list-item-border-radius;
      min-height: $rkt-select-panel-list-item-min-height;

      &:not(.mdc-list-item--disabled):hover,
      &.mat-mdc-option-multiple:not(.mdc-list-item--disabled):hover,
      &.mat-mdc-option-active {
        background-color: $rkt-select-panel-list-item-hover-background-color;
      }

      &.mdc-list-item--selected:not(.mat-mdc-option-multiple) {
        background-color: $rkt-select-panel-list-item-selected-background-color;

        .mdc-list-item__primary-text {
          color: $rkt-select-panel-list-item-selected-color;

          /* 
            Checkmark icon when the option is selected
            Note: the color of the checkmark is encoded within the background image SVG url
          */
          &::after {
            content: ' ';
            display: $rkt-select-panel-list-item-selected-checkmark-display;
            background-image: $rkt-select-panel-list-item-selected-checkmark-background-image;
            width: $rkt-select-panel-list-item-selected-checkmark-width;
            height: $rkt-select-panel-list-item-selected-checkmark-height;
            position: $rkt-select-panel-list-item-selected-checkmark-position;
            top: $rkt-select-panel-list-item-selected-checkmark-top;
            right: $rkt-select-panel-list-item-selected-checkmark-right;
            background-repeat: $rkt-select-panel-list-item-selected-checkmark-background-repeat;
            background-position: $rkt-select-panel-list-item-selected-checkmark-background-position;
          }
        }
      }

      &.mat-mdc-option-multiple:has(.mat-pseudo-checkbox) {
        background-color: $rkt-select-panel-list-item-background-color;

        .mat-pseudo-checkbox {
          position: $rkt-select-panel-list-item-mat-checkbox-position;
          right: $rkt-select-panel-list-item-mat-checkbox-right;
          margin: $rkt-select-panel-list-item-mat-checkbox-margin;
          width: $rkt-select-panel-list-item-mat-checkbox-width;
          height: $rkt-select-panel-list-item-mat-checkbox-height;
          border-radius: $rkt-select-panel-list-item-mat-checkbox-border-radius;
          border: $rkt-select-panel-list-item-mat-checkbox-border;
          background-color: $rkt-select-panel-list-item-mat-checkbox-background-color;

          &.mat-pseudo-checkbox-checked {
            background-color: $rkt-select-panel-list-item-mat-checkbox-checked-background-color;
            border: $rkt-select-panel-list-item-mat-checkbox-checked-border;
          }
        }

        &.mdc-list-item--disabled {
          .mat-pseudo-checkbox {
            border: $rkt-select-panel-list-item-mat-checkbox-disabled-border;
          }
        }

        &.mat-mdc-option-active {
          background-color: $rkt-select-panel-list-item-hover-background-color;
        }
      }

      &.mdc-list-item--disabled {
        opacity: $rkt-select-panel-list-item-disabled-opacity;

        .mdc-list-item__primary-text {
          color: $rkt-select-panel-list-item-disabled-color;
        }
      }

      .mdc-list-item__primary-text {
        overflow: $rkt-select-panel-list-item-overflow;
        text-overflow: $rkt-select-panel-list-item-text-overflow;
        white-space: $rkt-select-panel-list-item-white-space;
        color: $rkt-select-panel-list-item-color;
        font-family: $rkt-select-panel-list-item-font-family;
        font-size: $rkt-select-panel-list-item-font-size;
        font-style: $rkt-select-panel-list-item-font-style;
        font-weight: $rkt-select-panel-list-item-font-weight;
        line-height: $rkt-select-panel-list-item-line-height;
      }
      
      // option divider
      &:not(:first-of-type)::before {
        content: ' ';
        display: $rkt-select-panel-list-item-divider-display;
        position: $rkt-select-panel-list-item-divider-position;
        top: $rkt-select-panel-list-item-divider-top;
        right: $rkt-select-panel-list-item-divider-right;
        left: $rkt-select-panel-list-item-divider-left;
        background-color: $rkt-select-panel-list-item-divider-background-color;
        height: $rkt-select-panel-list-item-divider-height;
        opacity: $rkt-select-panel-list-item-divider-opacity;
        border: $rkt-select-panel-list-item-divider-border;
      }
    }
  }
}

// React Select Input
.rkt-FormField.MuiFormControl-root:not(.rkt-FormField--full-width):has(.MuiSelect-select) {
  width: $rkt-select-form-field-width;
}

.rkt-FormField.MuiFormControl-root:has(.MuiSelect-select) {
  .MuiFormLabel-root {
    left: $rkt-select-form-field-label-left;

    .MuiFormLabel-asterisk {
      display: $rkt-select-form-field-label-asterisk-display;
    }
  }

  .MuiInputBase-root {
    border-radius: $rkt-select-input-border-radius;
    background-color: $rkt-select-input-background-color;
    width: $rkt-select-input-width;

    .MuiSvgIcon-root {
      display: $rkt-select-input-icon-display;
    }

    .rkt-Input.MuiInputBase-input {
      background: $rkt-select-input-background-mui;
      padding: $rkt-select-input-padding;
      margin: $rkt-select-input-margin;

      /*
        Chevron icon pointing down at default state
        Note: the color of the arrow is encoded within the background image SVG url
      */
      &:not(.Mui-disabled, .Mui-error)::after {
        content: ' ';
        display: $rkt-select-input-chevron-display;
        background-image: $rkt-select-input-chevron-default-background-image;
        width: $rkt-select-input-chevron-width;
        height: $rkt-select-input-chevron-height;
        position: $rkt-select-input-chevron-position;
        top: $rkt-select-input-chevron-top-mui;
        right: $rkt-select-input-chevron-right-mui;
        background-repeat: $rkt-select-input-chevron-background-repeat;
        background-position: $rkt-select-input-chevron-background-position;
      }

      &.Mui-disabled {
        -webkit-text-fill-color: $rkt-select-input-disabled-webkit-text-fill-color;

        .rkt-Select__placeholder {
          color: $rkt-select-input-placeholder-disabled-color;
        }

        /*
          Chevron icon pointing down when the select is disabled
          Note: the color of the arrow is encoded within the background image SVG url
        */
        ::after {
          content: ' ';
          display: $rkt-select-input-chevron-display;
          background-image: $rkt-select-input-chevron-disabled-background-image;
          width: $rkt-select-input-chevron-width;
          height: $rkt-select-input-chevron-height;
          position: $rkt-select-input-chevron-position;
          top: $rkt-select-input-chevron-top-mui;
          right: $rkt-select-input-chevron-right-mui;
          background-repeat: $rkt-select-input-chevron-background-repeat;
          background-position: $rkt-select-input-chevron-background-position;
        }
      }

      /*
        Chevron icon pointing down when the select is invalid
        Note: the color of the arrow is encoded within the background image SVG url
      */
      &.Mui-error::after {
        content: ' ';
        display: $rkt-select-input-chevron-display;
        background-image: $rkt-select-input-chevron-invalid-background-image;
        width: $rkt-select-input-chevron-width;
        height: $rkt-select-input-chevron-height;
        position: $rkt-select-input-chevron-position;
        top: $rkt-select-input-chevron-top-mui;
        right: $rkt-select-input-chevron-right-mui;
        background-repeat: $rkt-select-input-chevron-background-repeat;
        background-position: $rkt-select-input-chevron-background-position;
      }

      /*
        Chevron icon pointing up when the select is expanded and invalid
        Note: the color of the arrow is encoded within the background image SVG url
      */
      &.Mui-error[aria-expanded="true"]::after {
        content: ' ';
        display: $rkt-select-input-chevron-display;
        background-image: $rkt-select-input-chevron-invalid-expanded-background-image;
        width: $rkt-select-input-chevron-width;
        height: $rkt-select-input-chevron-height;
        position: $rkt-select-input-chevron-position;
        top: $rkt-select-input-chevron-top-mui;
        right: $rkt-select-input-chevron-right-mui;
        background-repeat: $rkt-select-input-chevron-background-repeat;
        background-position: $rkt-select-input-chevron-background-position;
      }
    }

    // Lines below input that Mui adds
    &::before,
    &::after {
      content: none;
    }

    .rkt-Select__placeholder {
      color: $rkt-select-input-placeholder-color;
      font-family: $rkt-select-input-placeholder-font-family;
      font-size: $rkt-select-input-placeholder-font-size;
      font-style: $rkt-select-input-placeholder-font-style;
      font-weight: $rkt-select-input-placeholder-font-weight;
      line-height: $rkt-select-input-placeholder-line-height;
    }

    &.Mui-focused {
      background-color: $rkt-select-input-focus-background-color;

      .rkt-Select__placeholder {
        color: $rkt-select-input-placeholder-focus-color;
      }
      
      .rkt-Input.MuiInputBase-input[aria-expanded="true"]:not(.Mui-error)::after {
        /*
          Chevron icon pointing up when the select is expanded
          Note: the color of the arrow is encoded within the background image SVG url
        */
        content: ' ';
        display: $rkt-select-input-chevron-display;
        background-image: $rkt-select-input-chevron-default-expanded-background-image;
        width: $rkt-select-input-chevron-width;
        height: $rkt-select-input-chevron-height;
        position: $rkt-select-input-chevron-position;
        top: $rkt-select-input-chevron-top-mui;
        right: $rkt-select-input-chevron-right-mui;
        background-repeat: $rkt-select-input-chevron-background-repeat;
        background-position: $rkt-select-input-chevron-background-position;
      }
    }

    &.Mui-error {
      box-shadow: $rkt-select-input-error-box-shadow;

      &.Mui-focused:not(:has(.rkt-Input.MuiInputBase-input[aria-expanded="true"])) {
        box-shadow: $rkt-select-input-error-focused-box-shadow;
      }
    }
  }

  &:hover {
    .MuiInputBase-root:not(:has(.rkt-Input.Mui-disabled)) {
      background-color: $rkt-select-input-hover-background-color;

      .rkt-Select__placeholder {
        color: $rkt-select-input-placeholder-hover-color;
      }
    }
  }
}

// React Select Panel
.MuiPaper-root:has(.rkt-SelectItem) {
  margin: $rkt-select-panel-margin;
  padding: $rkt-select-panel-padding;
  border-radius: $rkt-select-panel-border-radius;
  border: $rkt-select-panel-border;
  background-color:  $rkt-select-panel-background-color;
  box-shadow: $rkt-select-panel-box-shadow;
  max-height: $rkt-select-panel-max-height;
  width: $rkt-select-panel-width;

  .MuiList-root {
    padding: $rkt-select-panel-list-box-padding;
  
    .rkt-SelectItem {
      padding: $rkt-select-panel-list-item-padding;
      border-radius: $rkt-select-panel-list-item-border-radius;
      min-height: $rkt-select-panel-list-item-min-height;
      background-color: $rkt-select-panel-list-item-background-color;
      overflow: $rkt-select-panel-list-item-overflow;
      text-overflow: $rkt-select-panel-list-item-text-overflow;
      white-space: $rkt-select-panel-list-item-white-space;
      color: $rkt-select-panel-list-item-color;
      font-family: $rkt-select-panel-list-item-font-family;
      font-size: $rkt-select-panel-list-item-font-size;
      font-style: $rkt-select-panel-list-item-font-style;
      font-weight: $rkt-select-panel-list-item-font-weight;
      line-height: $rkt-select-panel-list-item-line-height;
      display: $rkt-select-panel-list-item-display;

      // option divider
      &:not(:first-of-type)::before {
        content: ' ';
        display: $rkt-select-panel-list-item-divider-display;
        position: $rkt-select-panel-list-item-divider-position;
        top: $rkt-select-panel-list-item-divider-top;
        right: $rkt-select-panel-list-item-divider-right;
        left: $rkt-select-panel-list-item-divider-left;
        background-color: $rkt-select-panel-list-item-divider-background-color;
        height: $rkt-select-panel-list-item-divider-height;
        opacity: $rkt-select-panel-list-item-divider-opacity;
        border: $rkt-select-panel-list-item-divider-border;
      }
  
      &:hover,
      &.Mui-focusVisible {
        background-color: $rkt-select-panel-list-item-hover-background-color;
      }
  
      &.Mui-disabled {
        opacity: $rkt-select-panel-list-item-disabled-opacity;
        color: $rkt-select-panel-list-item-disabled-color;
      }

      &.Mui-selected {
        background-color: $rkt-select-panel-list-item-selected-background-color;
        color: $rkt-select-panel-list-item-selected-color;
  
        /* 
          Checkmark icon when the option is selected
          Note: the color of the checkmark is encoded within the background image SVG url
        */
        &::after {
          content: ' ';
          display: $rkt-select-panel-list-item-selected-checkmark-display;
          background-image: $rkt-select-panel-list-item-selected-checkmark-background-image;
          width: $rkt-select-panel-list-item-selected-checkmark-width;
          height: $rkt-select-panel-list-item-selected-checkmark-height;
          position: $rkt-select-panel-list-item-selected-checkmark-position;
          top: $rkt-select-panel-list-item-selected-checkmark-top;
          right: $rkt-select-panel-list-item-selected-checkmark-right;
          background-repeat: $rkt-select-panel-list-item-selected-checkmark-background-repeat;
          background-position: $rkt-select-panel-list-item-selected-checkmark-background-position;
        }
      }
    }

    &[aria-multiselectable="true"] {
      .rkt-SelectItem {
        background-color: $rkt-select-panel-list-item-background-color;
        color: $rkt-select-panel-list-item-color;

        &:hover,
        &.Mui-focusVisible {
          background-color: $rkt-select-panel-list-item-hover-background-color;
        }
        
        /* 
          Checkbox icon outline at default state
          Note: the color of the checkbox is encoded within the background image SVG url
        */
        &::after {
          content: ' ';
          display: $rkt-select-panel-list-item-mui-checkbox-display;
          background-image: $rkt-select-panel-list-item-mui-checkbox-default-background-image;
          width: $rkt-select-panel-list-item-mui-checkbox-width;
          height: $rkt-select-panel-list-item-mui-checkbox-height;
          position: $rkt-select-panel-list-item-mui-checkbox-position;
          top: $rkt-select-panel-list-item-mui-checkbox-top;
          right: $rkt-select-panel-list-item-mui-checkbox-right;
          background-repeat: $rkt-select-panel-list-item-mui-checkbox-background-repeat;
          background-position: $rkt-select-panel-list-item-mui-checkbox-background-position;
        }
  
        &.Mui-selected::after {
          /* 
            Checkbox icon when the multi-select option is selected
            Note: the color of the checkbox is encoded within the background image SVG url
          */
          content: ' ';
          display: $rkt-select-panel-list-item-mui-checkbox-display;
          background-image: $rkt-select-panel-list-item-mui-checkbox-selected-background-image;
          width: $rkt-select-panel-list-item-mui-checkbox-width;
          height: $rkt-select-panel-list-item-mui-checkbox-height;
          position: $rkt-select-panel-list-item-mui-checkbox-position;
          top: $rkt-select-panel-list-item-mui-checkbox-top;
          right: $rkt-select-panel-list-item-mui-checkbox-right;
          background-repeat: $rkt-select-panel-list-item-mui-checkbox-background-repeat;
          background-position: $rkt-select-panel-list-item-mui-checkbox-background-position;
        }
  
        &.Mui-disabled {
          color: $rkt-select-panel-list-item-disabled-color;

          &::after {
            /* 
              Checkbox icon outline when the option is disabled
              Note: the color of the checkbox is encoded within the background image SVG url
            */
            content: ' ';
            display: $rkt-select-panel-list-item-mui-checkbox-display;
            background-image: $rkt-select-panel-list-item-mui-checkbox-disabled-background-image;
            width: $rkt-select-panel-list-item-mui-checkbox-width;
            height: $rkt-select-panel-list-item-mui-checkbox-height;
            position: $rkt-select-panel-list-item-mui-checkbox-position;
            top: $rkt-select-panel-list-item-mui-checkbox-top;
            right: $rkt-select-panel-list-item-mui-checkbox-right;
            background-repeat: $rkt-select-panel-list-item-mui-checkbox-background-repeat;
            background-position: $rkt-select-panel-list-item-mui-checkbox-background-position;
          }
        }
      }
    }
  }
}

.rkt-FormField--is-full-width.MuiPaper-root:has(.rkt-SelectItem) {
  left: $rkt-select-panel-left;
  right: $rkt-select-panel-right;
  width: $rkt-select-panel-width-when-full-width;
}