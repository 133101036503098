@use '../web/scss/settings' as *;

.rkt-Link,
%rkt-Link {
  color: $rkt-link-color;
  font-family: $rkt-link-font-family;
  font-size: $rkt-link-font-size;
  font-weight: $rkt-link-font-weight;
  line-height: $rkt-link-line-height;
  position: $rkt-link-position;
  text-decoration: $rkt-link-text-decoration;

  &:visited {
    color: $rkt-link-visited-color;
  }

  &:hover {
    color: $rkt-link-hover-color;
    text-decoration: $rkt-link-hover-text-decoration;
  }

  &:not(.rkt-Link--is-disabled):focus-visible,
  &:not(.rkt-Link--is-disabled):hover:focus-visible {
    outline: $rkt-link-focus-outline;
    color: $rkt-link-focus-color;
    background-color: $rkt-link-focus-background-color;
    box-shadow: $rkt-link-focus-box-shadow;
    -webkit-box-decoration-break: $rkt-link-focus-box-decoration-break;
    box-decoration-break: $rkt-link-focus-box-decoration-break;
    border-radius: $rkt-link-focus-border-radius;
  }

  &:active {
    color: $rkt-link-hover-color;
    text-decoration: $rkt-link-hover-text-decoration;
  }
}

.rkt-Link--simple,
%rkt-Link--simple {
  text-decoration: $rkt-link-simple-text-decoration;

  &:visited:not(:hover, :focus-visible) {
    color: $rkt-link-simple-visited-color;
    text-decoration: $rkt-link-simple-visited-text-decoration;
  }

  &:focus-visible {
    text-decoration: $rkt-link-simple-focus-text-decoration;
  }
}

.rkt-Link--on-dark:not(.rkt-Link--is-disabled),
%rkt-Link--on-dark {
  color: $rkt-link-on-dark-color;
  font-weight: $rkt-link-on-dark-font-weight;

  &:visited {
    text-decoration: $rkt-link-on-dark-visited-text-decoration;
  }

  &:visited,
  &:hover,
  &:active,
  &:focus-visible {
    color: $rkt-link-on-dark-hover-color;
    font-weight: $rkt-link-on-dark-hover-font-weight;
  }
}

.rkt-Link--simple.rkt-Link--on-dark,
%rkt-Link--simple-on-dark {
  font-weight: $rkt-link-on-dark-simple-font-weight;
  text-decoration: $rkt-link-on-dark-simple-text-decoration;

  &:visited {
    text-decoration: $rkt-link-on-dark-simple-visited-text-decoration;
  }

  &:hover,
  &:active {
    font-weight: $rkt-link-on-dark-simple-hover-font-weight;
    text-decoration: $rkt-link-on-dark-simple-hover-text-decoration;
  }

  &:focus-visible {
    outline-color: $rkt-link-on-dark-simple-focus-outline-color;
    text-decoration: $rkt-link-on-dark-simple-focus-text-decoration;
  }
}

.rkt-Link--inline-18,
%rkt-Link--inline-18 {
  font-size: $rkt-link-inline-18-font-size;
}

.rkt-Link--inline-14,
%rkt-Link--inline-14 {
  font-size: $rkt-link-inline-14-font-size;
}

.rkt-Link--inline-12,
%rkt-Link--inline-12 {
  font-size: $rkt-link-inline-12-font-size;
}

.rkt-Link--has-icon,
%rkt-Link--has-icon {
  align-items: $rkt-link-has-icon-align-items;
  display: $rkt-link-has-icon-display;
  text-decoration: $rkt-link-has-icon-text-decoration;

  .rkt-Link__icon {
    color: $rkt-link-has-icon-color-icon;
    fill: $rkt-link-has-icon-color-icon;
    transition: $rkt-link-has-icon-transition;
  }

  &:visited {
    text-decoration: $rkt-link-has-icon-visited-text-decoration;
    color: $rkt-link-has-icon-visited-color;
  }

  &:hover {
    color: $rkt-link-has-icon-hover-color;
    text-decoration: $rkt-link-has-icon-hover-text-decoration;
  }

  &:hover .rkt-Link__icon {
    color: $rkt-link-has-icon-hover-color;
    fill: $rkt-link-has-icon-hover-color;
  }

  &:focus-visible {
    color: $rkt-link-has-icon-focus-color;
  }

  &:not(.rkt-Link--is-disabled):focus-visible .rkt-Link__icon {
    color: $rkt-link-has-icon-focus-color;
    fill: $rkt-link-has-icon-focus-color;
  }

  &:active {
    color: $rkt-link-has-icon-hover-color;
    text-decoration: $rkt-link-has-icon-hover-text-decoration;
  }

  &:active .rkt-Link__icon {
    color: $rkt-link-has-icon-hover-color;
  }
}

.rkt-Link.rkt-Link--has-icon.rkt-Link--is-disabled {
  text-decoration: $rkt-link-has-icon-disabled-text-decoration;
}

.rkt-Link--is-disabled,
.MuiLink-root.rkt-Link--is-disabled,
%rkt-Link--is-disabled {
  pointer-events: none;
  color: $rkt-link-disabled-color;
  text-decoration: $rkt-link-disabled-text-decoration;

  &:visited,
  &:hover,
  &:focus-visible,
  &:active {
    color: $rkt-link-disabled-hover-color;
  }

  &:focus-visible {
    outline: $rkt-link-disabled-focus-outline;
  }
}

.rkt-Link--simple.rkt-Link--is-disabled,
.rkt-Link--is-disabled .rkt-Link__icon,
%rkt-Link--simple-disabled {
  fill: $rkt-link-disabled-color;
  color: $rkt-link-disabled-color;
  text-decoration: $rkt-link-simple-disabled-text-decoration;
}

.rkt-Link--simple.rkt-Link--on-dark.rkt-Link.rkt-Link--has-icon:not(.rkt-Link--is-disabled),
%rkt-Link--simple-icon-on-dark {
  &:visited,
  &:hover,
  &:focus-visible,
  &:active {
    color: $rkt-link-has-icon-on-dark-color;
  }
}

.rkt-Link--simple.rkt-Link--on-dark.rkt-Link.rkt-Link--has-icon.rkt-Link--is-disabled,
%rkt-Link--simple-icon-disabled-on-dark,
.rkt-Link--on-dark.rkt-Link--is-disabled {
  color: $rkt-link-on-dark-disabled-color;

  &:visited,
  &:hover,
  &:focus-visible,
  &:active {
    color: $rkt-link-on-dark-disabled-hover-color;
  }
}
