@use '../web/scss/settings';

// default huge checkbox styles
.rkt-HugeInputContainer .rkt-CheckboxHuge {
  min-height: settings.$rkt-huge-checkbox-min-height;
  border-radius: settings.$rkt-huge-checkbox-border-radius;
  box-shadow: settings.$rkt-huge-checkbox-box-shadow;
  background: settings.$rkt-huge-checkbox-background;
  display: settings.$rkt-huge-checkbox-display;
  justify-content: settings.$rkt-huge-checkbox-justify-content;
  max-width: settings.$rkt-huge-checkbox-max-width;
}

// default huge checkbox label styles
.rkt-HugeInputContainer .rkt-CheckboxHuge .rkt-Checkbox .mdc-form-field label,
.rkt-CheckboxHuge--stylized .rkt-CheckboxHuge__input .mdc-form-field label,
.rkt-CheckboxHuge .rkt-CheckboxHuge__label .rkt-CheckboxHuge__label-text {
  color: settings.$rkt-huge-checkbox-label-color;
  font-size: settings.$rkt-huge-checkbox-label-font-size;
  font-style: settings.$rkt-huge-checkbox-label-font-style;
  font-weight: settings.$rkt-huge-checkbox-label-font-weight;
  line-height: settings.$rkt-huge-checkbox-label-line-height;

  @media screen and (min-width: settings.$rkt-huge-checkbox-large-breakpoint) {
    font-size: settings.$rkt-huge-checkbox-label-large-font-size;
  }
}

// react adjustments to label
.rkt-CheckboxHuge .rkt-CheckboxHuge__label-text {
  right: settings.$rkt-huge-checkbox-label-text-right;
  position: settings.$rkt-huge-checkbox-label-text-position;

  @media screen and (min-width: settings.$rkt-huge-checkbox-large-breakpoint) {
    right: settings.$rkt-huge-checkbox-label-text-large-right;
  }
}

// react adjustments to checkbox
.rkt-CheckboxHuge .rkt-Checkbox.MuiCheckbox-root {
  position: settings.$rkt-huge-checkbox-checkbox-react-position;
  left: settings.$rkt-huge-checkbox-checkbox-react-left;

  @media screen and (min-width: settings.$rkt-huge-checkbox-large-breakpoint) {
    left: settings.$rkt-huge-checkbox-checkbox-react-large-left;
  }
}

// disabled styles
.rkt-HugeInputContainer .rkt-CheckboxHuge.rkt-CheckboxHuge--is-disabled {
  background: settings.$rkt-huge-checkbox-disabled-background;
}

// disabled checkbox label styles
.rkt-HugeInputContainer .rkt-CheckboxHuge.rkt-CheckboxHuge--is-disabled .rkt-Checkbox .mdc-form-field label,
.rkt-HugeInputContainer .rkt-CheckboxHuge.rkt-CheckboxHuge--is-disabled .rkt-CheckboxHuge__label .rkt-CheckboxHuge__label-text,
.rkt-CheckboxHuge--stylized.rkt-CheckboxHuge--is-disabled .rkt-CheckboxHuge__input .mdc-form-field label {
  color: settings.$rkt-huge-checkbox-disabled-label-color;
}

// hover styles
.rkt-HugeInputContainer .rkt-CheckboxHuge:not(.rkt-CheckboxHuge--is-disabled):not(.rkt-CheckboxHuge--is-selected):not(:has(.mdc-checkbox__native-control:focus-visible)):not(:has(.Mui-focusVisible)):hover {
  box-shadow: settings.$rkt-huge-checkbox-hover-box-shadow;
}

// focus styles
.rkt-HugeInputContainer .rkt-CheckboxHuge:has(.mdc-checkbox__native-control:focus-visible),
.rkt-HugeInputContainer .rkt-CheckboxHuge:has(.MuiCheckbox-root.Mui-focusVisible ) {
  box-shadow: settings.$rkt-huge-checkbox-focus-box-shadow;
}

// selected styles
.rkt-HugeInputContainer .rkt-CheckboxHuge.rkt-CheckboxHuge--is-selected {
  background: settings.$rkt-huge-checkbox-selected-background;
  box-shadow: settings.$rkt-huge-checkbox-selected-box-shadow;
}

// angular checkbox widths
.rkt-CheckboxHuge .rkt-Checkbox:not(.MuiButtonBase-root) {
  width: settings.$rkt-huge-checkbox-checkbox-angular-width;
}

// angular checkbox form fields
.rkt-CheckboxHuge .rkt-Checkbox .mdc-form-field {
  width: settings.$rkt-huge-checkbox-checkbox-angular-form-field-width;
  display: settings.$rkt-huge-checkbox-checkbox-angular-form-field-display;
  justify-content: settings.$rkt-huge-checkbox-checkbox-angular-form-field-justify-content;
}

.rkt-CheckboxHuge--with-description .rkt-Checkbox label {
  padding: settings.$rkt-huge-checkbox-with-description-checkbox-label-padding;
  width: settings.$rkt-huge-checkbox-label-angular-width;
}

// padding makes the label area clickable (angular)
.rkt-CheckboxHuge:not(.rkt-CheckboxHuge--with-description) .rkt-Checkbox .mdc-form-field label {
  padding: settings.$rkt-huge-checkbox-label-angular-padding;
  width: settings.$rkt-huge-checkbox-label-angular-width;
  
  @media screen and (min-width: settings.$rkt-huge-checkbox-large-breakpoint) {
    padding: settings.$rkt-huge-checkbox-label-angular-large-padding;
  }
}

.rkt-CheckboxHuge.rkt-CheckboxHuge--with-description .rkt-Checkbox label {
  @media screen and (min-width: settings.$rkt-huge-checkbox-large-breakpoint) {
    position: settings.$rkt-huge-checkbox-with-description-checkbox-label-large-position;
    left: settings.$rkt-huge-checkbox-with-description-checkbox-label-large-left;
  }
}

// padding makes the label area clickable (react)
.rkt-CheckboxHuge .rkt-CheckboxHuge__label.MuiFormControlLabel-root {
  width: settings.$rkt-huge-checkbox-label-react-width;
  display: settings.$rkt-huge-checkbox-label-react-display;
  margin: settings.$rkt-huge-checkbox-label-react-margin;
  justify-content: settings.$rkt-huge-checkbox-label-react-justify-content;
}

.rkt-CheckboxHuge:not(.rkt-CheckboxHuge--stylized):not(.rkt-CheckboxHuge--with-description) .rkt-CheckboxHuge__label.MuiFormControlLabel-root {
  padding: settings.$rkt-huge-checkbox-label-react-padding;

  @media screen and (min-width: settings.$rkt-huge-checkbox-large-breakpoint) {
    padding: settings.$rkt-huge-checkbox-label-react-large-padding;
  }
}


// react huge checkbox checkbox margin reset
.rkt-CheckboxHuge .rkt-Checkbox.MuiCheckbox-root {
  margin: settings.$rkt-huge-checkbox-checkbox-react-margin;
  @media screen and (min-width: settings.$rkt-huge-checkbox-large-breakpoint) {
    left: settings.$rkt-huge-checkbox-checkbox-react-large-left;
  }
}

.rkt-CheckboxHuge--with-description .rkt-Checkbox label {
  @media screen and (min-width: settings.$rkt-huge-checkbox-large-breakpoint) {
    padding: settings.$rkt-huge-checkbox-with-description-label-large-padding;
  }
}

// with description checkbox adjustments react
.rkt-CheckboxHuge--with-description .rkt-CheckboxHuge__label.MuiFormControlLabel-root .MuiButtonBase-root {
  margin-right: settings.$rkt-huge-checkbox-with-description-react-margin-right;
  top: settings.$rkt-huge-checkbox-with-description-react-top;

  @media screen and (min-width: settings.$rkt-huge-checkbox-large-breakpoint) {
    margin-right: settings.$rkt-huge-checkbox-with-description-react-large-margin-right;
    left: settings.$rkt-huge-checkbox-with-description-react-large-left;
  }
}

.rkt-CheckboxHuge--with-description .rkt-Checkbox__description {
  color: settings.$rkt-huge-checkbox-with-description-description-color;
  font-size: settings.$rkt-huge-checkbox-with-description-description-font-size;
  font-style: settings.$rkt-huge-checkbox-with-description-description-font-style;
  font-weight: settings.$rkt-huge-checkbox-with-description-description-font-weight;
  line-height: settings.$rkt-huge-checkbox-with-description-description-line-height;
}

.rkt-CheckboxHuge--with-description .rkt-Checkbox__description {
  top: settings.$rkt-huge-checkbox-with-description-description-top;
  position: settings.$rkt-huge-checkbox-with-description-description-position;
}

.rkt-CheckboxHuge--with-description.rkt-CheckboxHuge--is-disabled .rkt-Checkbox__description {
  color: settings.$rkt-huge-checkbox-with-description-description-disabled-color;
}

// react with description label adjustments
.rkt-CheckboxHuge--with-description .rkt-CheckboxHuge__label.MuiFormControlLabel-root {
  position: settings.$rkt-huge-checkbox-with-description-react-label-position;
  left: settings.$rkt-huge-checkbox-with-description-react-label-left;
  padding: settings.$rkt-huge-checkbox-with-description-react-label-padding;

  @media screen and (min-width: settings.$rkt-huge-checkbox-large-breakpoint) {
    padding: settings.$rkt-huge-checkbox-with-description-react-label-large-padding;
    bottom: settings.$rkt-huge-checkbox-with-description-react-label-large-bottom;
  }
  
  .rkt-Checkbox__description {
    position: settings.$rkt-huge-checkbox-with-description-react-label-checkbox-description-position;
    right: settings.$rkt-huge-checkbox-with-description-react-label-checkbox-description-right;

    @media screen and (min-width: settings.$rkt-huge-checkbox-large-breakpoint) {
      right: settings.$rkt-huge-checkbox-with-description-react-label-checkbox-description-large-right;
      top:  settings.$rkt-huge-checkbox-with-description-react-label-checkbox-description-large-top;
    }
  }
}

// angular huge checkbox checkbox alignments
.rkt-CheckboxHuge .rkt-Checkbox .mdc-form-field--align-end .mdc-checkbox, 
.rkt-CheckboxHuge .rkt-Checkbox.mat-mdc-checkbox .mdc-form-field--align-end .mdc-checkbox {
  margin: settings.$rkt-huge-checkbox-checkbox-angular-margin;
  padding: settings.$rkt-huge-checkbox-checkbox-angular-padding;
  position: settings.$rkt-huge-checkbox-checkbox-angular-position;
  right: settings.$rkt-huge-checkbox-checkbox-angular-right;

  @media screen and (min-width: settings.$rkt-huge-checkbox-large-breakpoint) {
    padding: settings.$rkt-huge-checkbox-checkbox-angular-large-padding;
    right: settings.$rkt-huge-checkbox-checkbox-angular-large-right;
  }
}

// Hides the checkbox so we can show an icon instead
.rkt-CheckboxHuge--stylized .mdc-checkbox {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

.rkt-CheckboxHuge--stylized .rkt-CheckboxHuge__input,
.rkt-CheckboxHuge--stylized .rkt-CheckboxHuge__input label {
  display: settings.$rkt-huge-checkbox-stylized-angular-input-display;
}

.rkt-CheckboxHuge--stylized {
  .rkt-CheckboxHuge__input, 
  .rkt-CheckboxHuge__input label,
  .mdc-form-field {
    width: settings.$rkt-huge-checkbox-stylized-angular-input-label-width;
  }
}

// huge checkbox stylized labels angular
.rkt-CheckboxHuge--stylized .rkt-CheckboxHuge__input label {
  margin: settings.$rkt-huge-checkbox-stylized-angular-input-label-margin;
}

// stylized horizontal input label adjustments for angular
.rkt-CheckboxHuge--stylized .rkt-CheckboxHuge__input label {
  padding: settings.$rkt-huge-checkbox-stylized-horizontal-angular-padding;
  justify-content: settings.$rkt-huge-checkbox-stylized-horizontal-angular-justify-content;
  top: settings.$rkt-huge-checkbox-stylized-horizontal-angular-top;
  position: settings.$rkt-huge-checkbox-stylized-horizontal-angular-position;

  @media screen and (min-width: settings.$rkt-huge-checkbox-large-breakpoint) {
    padding: settings.$rkt-huge-checkbox-stylized-horizontal-angular-large-padding;
    top: settings.$rkt-huge-checkbox-stylized-horizontal-angular-large-top;
  }
}

// stylized horizontal label adjustments
.rkt-CheckboxHuge--stylized .rkt-CheckboxHuge__label {
  position: settings.$rkt-huge-checkbox-stylized-horizontal-label-position;
  padding: settings.$rkt-huge-checkbox-stylized-horizontal-label-padding;
  left: settings.$rkt-huge-checkbox-stylized-horizontal-label-left;

  @media screen and (min-width: settings.$rkt-huge-checkbox-large-breakpoint) {
    bottom: settings.$rkt-huge-checkbox-stylized-horizontal-label-large-bottom;
    left:  settings.$rkt-huge-checkbox-stylized-horizontal-label-large-left;
    padding: settings.$rkt-huge-checkbox-stylized-horizontal-label-large-padding;
  }
}

.rkt-CheckboxHuge--stylized .rkt-Checkbox.MuiCheckbox-root {
  left: settings.$rkt-huge-checkbox-stylized-horizontal-react-checkbox-left;

  @media screen and (min-width: settings.$rkt-huge-checkbox-large-breakpoint) {
    left: settings.$rkt-huge-checkbox-stylized-horizontal-react-checkbox-large-left;
  }
}

.rkt-CheckboxHuge--stylized.rkt-CheckboxHuge--vertical .rkt-CheckboxHuge__input label {
  flex-direction: settings.$rkt-huge-checkbox-stylized-vertical-checkbox-label-flex-direction;
  align-items: settings.$rkt-huge-checkbox-stylized-vertical-checkbox-label-align-items;
  position: settings.$rkt-huge-checkbox-stylized-vertical-checkbox-label-position;
  top: settings.$rkt-huge-checkbox-stylized-vertical-checkbox-label-top;
  padding: settings.$rkt-huge-checkbox-stylized-vertical-checkbox-label-padding;

  @media screen and (min-width: settings.$rkt-huge-checkbox-large-breakpoint) {
    padding: settings.$rkt-huge-checkbox-stylized-vertical-checkbox-label-large-padding;
  }

  .mat-icon {
    position: settings.$rkt-huge-checkbox-stylized-vertical-label-checkbox-icon-position;
    bottom: settings.$rkt-huge-checkbox-stylized-vertical-label-checkbox-icon-bottom;

    @media screen and (min-width: settings.$rkt-huge-checkbox-large-breakpoint) {
      bottom: settings.$rkt-huge-checkbox-stylized-vertical-label-checkbox-icon-large-bottom;
    }
  }
}

// stylized icon colors
.rkt-CheckboxHuge--stylized .rkt-CheckboxHuge__icon-target .mat-icon,
.rkt-CheckboxHuge--stylized .rkt-Checkbox .MuiSvgIcon-root {
  color: settings.$rkt-huge-checkbox-stylized-icon-color;
}

// stylized disabled icons
.rkt-CheckboxHuge--stylized.rkt-CheckboxHuge--is-disabled .rkt-CheckboxHuge__icon-target .mat-icon,
.rkt-CheckboxHuge--stylized.rkt-CheckboxHuge--is-disabled .rkt-Checkbox .MuiSvgIcon-root {
  color: settings.$rkt-huge-checkbox-stylized-icon-disabled-color;
}

// stylized icon react adjustments
.rkt-CheckboxHuge--stylized .rkt-CheckboxHuge__label .rkt-Checkbox {
  position: settings.$rkt-huge-checkbox-stylized-horizontal-react-checkbox-position;
  left: settings.$rkt-huge-checkbox-stylized-horizontal-react-checkbox-left;

  @media screen and (min-width: settings.$rkt-huge-checkbox-large-breakpoint) {
    left: settings.$rkt-huge-checkbox-stylized-horizontal-react-checkbox-large-left;
    bottom: settings.$rkt-huge-checkbox-stylized-horizontal-react-checkbox-large-bottom;
  }
}

.rkt-CheckboxHuge--stylized.rkt-CheckboxHuge--vertical .rkt-CheckboxHuge__label {
  flex-direction: settings.$rkt-huge-checkbox-stylized-vertical-label-flex-direction;
  position: settings.$rkt-huge-checkbox-stylized-vertical-label-position;
  padding: settings.$rkt-huge-checkbox-stylized-vertical-label-padding;
  left: settings.$rkt-huge-checkbox-stylized-vertical-label-left;

  @media screen and (min-width: settings.$rkt-huge-checkbox-large-breakpoint) {
    padding: settings.$rkt-huge-checkbox-stylized-vertical-label-large-padding;
  }

  .rkt-Checkbox {
    left: settings.$rkt-huge-checkbox-stylized-vertical-label-checkbox-left;
    right: settings.$rkt-huge-checkbox-stylized-vertical-label-checkbox-right;
    bottom: settings.$rkt-huge-checkbox-stylized-vertical-label-checkbox-bottom;

    @media screen and (min-width: settings.$rkt-huge-checkbox-large-breakpoint) {
      left: settings.$rkt-huge-checkbox-stylized-vertical-label-checkbox-large-left;
      bottom: settings.$rkt-huge-checkbox-stylized-vertical-label-checkbox-large-bottom;
    }
  }
}

.rkt-CheckboxHuge:not(.rkt-CheckboxHuge--vertical) .rkt-CheckboxHuge__label .MuiFormControlLabel-label {
  padding-right: settings.$rkt-huge-checkbox-label-react-padding-right;
  width: settings.$rkt-huge-checkbox-label-react-width;
}