@use '../web/scss/settings';

// sets input border radius
.rkt-FormField .mat-mdc-text-field-wrapper,
.rkt-FormField .rkt-Input.MuiInputBase-root,
.rkt-FormField.rkt-FormField--has-textarea,
.rkt-FormField .rkt-Input--datepicker .MuiInputBase-root {
  border-radius: settings.$rkt-input-border-radius;
}

// adjustment for angular inputs
.rkt-FormField:not(.rkt-FormField--has-prefix) .rkt-Input.mat-mdc-input-element {
  top: settings.$rkt-input-angular-top;
  position: settings.$rkt-input-angular-position;
}

// adjustment for react inputs
.rkt-FormField:not(.rkt-FormField--has-prefix) .rkt-Input .MuiInputBase-input {
  position: settings.$rkt-form-field-react-input-position;
  top: settings.$rkt-form-field-react-input-top;
}

// input paddings - angular
.rkt-FormField:not(.rkt-FormField--has-textarea):not(.rkt-FormField--has-left-icon) .mat-mdc-text-field-wrapper {
  padding: settings.$rkt-input-angular-padding;
}

// input paddings - react
.rkt-FormField:not(.rkt-FormField--has-textarea):not(.rkt-FormField--has-left-icon) .MuiInputBase-root {
  padding: settings.$rkt-input-react-padding;
}

// input dimensions
.rkt-FormField:not(.rkt-FormField--has-textarea) .mat-mdc-text-field-wrapper,
.rkt-FormField:not(.rkt-FormField--has-textarea) .MuiInputBase-root {
  height: settings.$rkt-input-height;
  width: settings.$rkt-input-width;
}

.rkt-FormField.rkt-FormField--full-width,
// angular selector
.rkt-FormField.rkt-FormField--full-width .mat-mdc-text-field-wrapper,
// react selector
.rkt-FormField.rkt-FormField--full-width .rkt-Input.MuiInputBase-root,
.rkt-FormField.rkt-FormField--full-width:not(.rkt-FormField--has-textarea) .MuiInputBase-fullWidth {
  width: settings.$rkt-form-field-full-width-input-width;
}

// textarea styles
.rkt-FormField.rkt-FormField--has-textarea .MuiInputBase-root {
  background-color: unset;
}

// textarea padding for Angular
.rkt-FormField.rkt-FormField--has-textarea .mat-mdc-text-field-wrapper {
  padding: settings.$rkt-form-field-has-textarea-angular-text-field-wrapper-padding;
}

// textarea padding for React
.rkt-FormField.rkt-FormField--has-textarea.MuiFormControl-root {
  padding: settings.$rkt-form-field-has-textarea-react-padding;
}

.rkt-FormField.rkt-FormField--has-textarea .MuiInputBase-root {
  padding-left: settings.$rkt-form-field-has-textarea-react-input-padding-left;
  padding-right: settings.$rkt-form-field-has-textarea-react-input-padding-right;
  margin-top: settings.$rkt-form-field-has-textarea-react-input-margin-top;
}

.rkt-FormField .mat-mdc-text-field-wrapper {
  align-items: settings.$rkt-form-field-text-field-wrapper-align-items;
}

// remove bottom border from inputs
.rkt-FormField .mdc-line-ripple::before,
.rkt-FormField .mdc-line-ripple::after,
.rkt-FormField .rkt-Input.MuiFilledInput-underline.MuiFilledInput-root.MuiInputBase-formControl::before,
.rkt-FormField .rkt-Input.MuiFilledInput-underline.MuiFilledInput-root.MuiInputBase-formControl::after,
.rkt-FormField .rkt-Input.rkt-Input--textarea .MuiInputBase-root::before,
.rkt-FormField .rkt-Input.rkt-Input--textarea .MuiInputBase-root::after,
.rkt-FormField .rkt-Input .MuiInputBase-root::before,
.rkt-FormField .rkt-Input .MuiInputBase-root::after,
.rkt-FormField.rkt-FormField--has-textarea .MuiInputBase-root::before,
.rkt-FormField.rkt-FormField--has-textarea .MuiInputBase-root::after {
  border-bottom: settings.$rkt-input-border-bottom;
}

// default label styles
.rkt-FormField.mat-mdc-form-field .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label,
.rkt-FormField.mat-mdc-form-field .mdc-text-field.mdc-text-field--disabled .mdc-floating-label,
.rkt-FormField .MuiFormLabel-root {
  color: settings.$rkt-input-label-color;
  font-size: settings.$rkt-input-label-font-size;
  font-weight: settings.$rkt-input-label-font-weight;
  line-height: settings.$rkt-input-label-line-height;
}

.rkt-FormField.mat-mdc-form-field .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label,
.rkt-FormField.mat-mdc-form-field .mdc-text-field.mdc-text-field--disabled .mdc-floating-label {
  transform: settings.$rkt-input-angular-floating-label-transform;
}

.rkt-FormField .MuiFormLabel-root {
  transform: settings.$rkt-input-react-floating-label-transform;
}

// adjust angular label
.rkt-FormField:not(.rkt-FormField--has-textarea) .mat-mdc-text-field-wrapper .mat-mdc-form-field-infix .mat-mdc-floating-label {
  padding-bottom: settings.$rkt-input-angular-label-padding-bottom;
}

// adjust react floating label
.rkt-FormField:not(.rkt-FormField--has-textarea) .MuiFormLabel-root {
  top: settings.$rkt-input-react-label-top;
  left: settings.$rkt-input-react-label-left;
}

// adjust angular floating label on has textarea
.rkt-FormField.rkt-FormField--has-textarea .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: settings.$rkt-form-field-has-textarea-angular-label-top;
}

.rkt-FormField.rkt-FormField--has-textarea .MuiFormLabel-root {
  transform: settings.$rkt-input-react-textarea-floating-label-transform;
}

// disabled label
.rkt-FormField.mat-mdc-form-field .mdc-text-field.mdc-text-field--disabled .mdc-floating-label,
.rkt-FormField .MuiInputLabel-root.Mui-disabled {
  color: settings.$rkt-input-label-disabled-color;
}

// default input background color
.rkt-FormField .mdc-text-field--filled:not(.mdc-text-field--disabled),
.rkt-FormField .rkt-Input.MuiInputBase-root,
.rkt-FormField.rkt-FormField--has-textarea.MuiFormControl-root,
.rkt-FormField .rkt-Input--datepicker .MuiInputBase-root {
  background-color: settings.$rkt-input-background-color;
}

// default input hover and focus background color
// angular selectors
.rkt-FormField.mat-focused .mdc-text-field--filled:not(.mdc-text-field--disabled),
.rkt-FormField:hover .mdc-text-field--filled:not(.mdc-text-field--disabled),
// react selectors
.rkt-FormField .rkt-Input.MuiInputBase-root:not(.Mui-disabled):hover,
.rkt-FormField .rkt-Input.MuiInputBase-root:not(.Mui-disabled).Mui-focused,
// react textarea selectors
.rkt-FormField.rkt-FormField--has-textarea:has(.MuiInputBase-input:hover),
.rkt-FormField.rkt-FormField--has-textarea:has(.rkt-Input .MuiInputBase-root.Mui-focused),
.rkt-FormField .rkt-Input--datepicker:hover .MuiInputBase-root,
.rkt-FormField .rkt-Input--datepicker .MuiInputBase-root.Mui-focused,
.MuiFormControl-root:has(.rkt-Autocomplete) .MuiFormControl-root:hover,
.MuiFormControl-root:has(.rkt-Autocomplete.Mui-focused) .MuiFormControl-root,
.rkt-FormField.rkt-Autocomplete .mdc-text-field--filled:not(.mdc-text-field--disabled):hover,
.rkt-FormField.rkt-Autocomplete.mat-focused .mdc-text-field--filled:not(.mdc-text-field--disabled),
.rkt-FormField:has(input:not(:disabled):-webkit-autofill):hover .rkt-Input.MuiInputBase-root {
  background-color: settings.$rkt-input-hover-background-color;
}

// disabled input background color
.rkt-FormField .mdc-text-field--filled.mdc-text-field--disabled,
.rkt-FormField .rkt-Input.Mui-disabled,
.rkt-FormField .rkt-Input--datepicker .MuiInputBase-root.Mui-disabled {
  background-color: settings.$rkt-input-disabled-background-color;
}

.rkt-FormField .rkt-Input.Mui-disabled .rkt-Icon {
  pointer-events: none;
}

// this overlay applies some opacity so we just hide it and control it
// directly in the mdc-text-field
.rkt-FormField .mat-mdc-form-field-focus-overlay {
  display: none;
}

// default placeholder styles react
.rkt-Input .MuiFilledInput-input::-webkit-input-placeholder {
  color: settings.$rkt-input-placeholder-color;
  font-size: settings.$rkt-input-placeholder-font-size;
  font-weight: settings.$rkt-input-placeholder-font-weight;
  line-height: settings.$rkt-input-placeholder-line-height;
}

.rkt-Input .MuiInputBase-root .MuiInputBase-input::-webkit-input-placeholder {
  color: settings.$rkt-input-placeholder-color;
}

// hover placeholder styles React
.rkt-Input:hover .MuiFilledInput-input::-webkit-input-placeholder,
.rkt-Input:hover .MuiInputBase-root .MuiInputBase-input::-webkit-input-placeholder {
  color: settings.$rkt-input-hover-placeholder-color;
}

// default placeholder styles angular
 // TODO - figure out why these selectors don't work when added to the block above
.rkt-FormField .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder,
.rkt-FormField .mdc-text-field.mdc-text-field--disabled .mdc-text-field__input::placeholder {
  color: settings.$rkt-input-placeholder-color;
  font-size: settings.$rkt-input-placeholder-font-size;
  font-weight: settings.$rkt-input-placeholder-font-weight;
  line-height: settings.$rkt-input-placeholder-line-height;
}

// hover placeholder styles Angular
.rkt-FormField:hover .mdc-text-field:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__input::placeholder {
  color: settings.$rkt-input-hover-placeholder-color;
}

// focused placeholder styles
.rkt-FormField .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-text-field__input::-webkit-input-placeholder,
.rkt-Input.Mui-focused .MuiFilledInput-input::-webkit-input-placeholder,
.rkt-Input .MuiFilledInput-root.Mui-focused .MuiFilledInput-input::-webkit-input-placeholder {
  color: settings.$rkt-input-placeholder-focus-color;
 }

 // focused placeholder styles angular
 // TODO - figure out why these selectors don't work when added to the block above
 .rkt-FormField .mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
  color: settings.$rkt-input-placeholder-focus-color;
 }

// disabled placeholder styles
.rkt-Input.Mui-disabled .MuiFilledInput-input::-webkit-input-placeholder,
.rkt-FormField .mdc-text-field.mdc-text-field--disabled .mdc-text-field__input::placeholder {
  color: settings.$rkt-input-placeholder-disabled-color;
}

.rkt-FormField .MuiInputAdornment-root .rkt-ButtonIcon {
  left: settings.$rkt-form-field-button-icon-left;
}

// default prefix styles
.rkt-FormField .rkt-FormField__prefix:not(.MuiSvgIcon-root),
.rkt-FormField .rkt-FormField__prefix .MuiTypography-root {
  color: settings.$rkt-input-prefix-color;
  font-size: settings.$rkt-input-prefix-font-size;
  font-weight: settings.$rkt-input-prefix-font-weight;
  line-height: settings.$rkt-input-prefix-line-height;
}

// default prefix and suffix color
.rkt-FormField .rkt-FormField__suffix,
.rkt-FormField .rkt-FormField__prefix,
.rkt-FormField .rkt-Input--datepicker .MuiInputBase-root .MuiSvgIcon-root {
  color: settings.$rkt-input-prefix-color;
}

// text styles
.rkt-FormField .rkt-Input .MuiInputBase-input,
.rkt-FormField .rkt-Input.mat-mdc-input-element {
  color: settings.$rkt-input-text-color;
  font-size: settings.$rkt-input-text-font-size;
  font-weight: settings.$rkt-input-text-font-weight;
  line-height: settings.$rkt-input-text-line-height;
}

// react adjustment to text area
.rkt-FormField--has-textarea .rkt-Input--textarea.MuiFormControl-root {
  position: settings.$rkt-form-field-has-textarea-react-textarea-position;
  bottom: settings.$rkt-form-field-has-textarea-react-textarea-bottom;
}

.rkt-FormField--has-textarea:has(.MuiFormLabel-filled) .MuiInputBase-root {
  margin-top: settings.$rkt-form-field-has-textarea-react-filled-input-margin-top;
}

// filled text styles
.rkt-FormField .MuiInputBase-input, .rkt-FormField .rkt-Input.mat-mdc-input-element {
  color: settings.$rkt-input-text-filled-color;
}

// filled hover text styles
.rkt-FormField:has(.MuiFormLabel-filled):hover .MuiInputBase-root:not(.Mui-focused) .MuiInputBase-input {
  color: settings.$rkt-input-text-filled-hover-color;
}

// focused text styles
.rkt-FormField .MuiInputBase-root.Mui-focused .MuiInputBase-input,
.rkt-FormField.mat-focused .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
  color: settings.$rkt-input-text-focus-color;
}
// adjustment for react labels when formfield has left icon
.rkt-FormField--has-left-icon:not(.rkt-FormField--has-textarea) .MuiFormLabel-root {
  left: settings.$rkt-form-field-has-left-icon-label-left;
}

// adjustment for inputs in has left icon in react
.rkt-FormField--has-left-icon:not(.rkt-FormField--has-textarea) .MuiInputBase-root {
  padding-left: settings.$rkt-form-field-has-left-icon-react-input-padding-left;
}

.rkt-FormField--has-left-icon .MuiInputAdornment-root.MuiInputAdornment-positionStart:not(.MuiInputAdornment-hiddenLabel) {
  margin-top: settings.$rkt-form-field-has-left-icon-input-adornment-margin-top;
}

// Hard override because MUI dims the opacity of placeholder text
.rkt-FormField .MuiInputBase-input::-moz-placeholder {
  opacity: settings.$rkt-form-field-input-placeholder-opacity;
}
// FF specific, cannot group together with above due to browser issues
// Hard override because MUI dims the opacity of placeholder text
.rkt-FormField .MuiInputBase-input::-webkit-input-placeholder {
  opacity: settings.$rkt-form-field-input-placeholder-opacity;
}

// disabled placeholder styles
.rkt-FormField .mdc-text-field--disabled .rkt-Input::placeholder,
// disabled prefix styles
.rkt-FormField .mdc-text-field--disabled .rkt-FormField__prefix,
.rkt-FormField .rkt-Input.Mui-disabled .rkt-FormField__prefix,
.rkt-FormField .rkt-Input.Mui-disabled .rkt-FormField__prefix .MuiTypography-root,
// disabled suffix styles
.rkt-FormField .mdc-text-field--disabled .rkt-FormField__suffix,
.rkt-FormField .rkt-Input.Mui-disabled .rkt-FormField__suffix,
.rkt-FormField .rkt-Input.Mui-disabled .rkt-FormField__suffix .MuiTypography-root,
.rkt-FormField .rkt-Input--datepicker .MuiInputBase-root.Mui-disabled .MuiSvgIcon-root {
  color: settings.$rkt-input-placeholder-disabled-color;
}

// disabled input text styles
.rkt-FormField .rkt-Input.Mui-disabled .MuiInputBase-input,
.rkt-FormField.mat-form-field-disabled .rkt-Input,
.rkt-FormField.rkt-FormField--has-textarea .rkt-Input--textarea .MuiInput-input.Mui-disabled,
.rkt-FormField .rkt-Input--datepicker .MuiInputBase-input.Mui-disabled {
  -webkit-text-fill-color: settings.$rkt-input-text-disabled-color;
  color: settings.$rkt-input-text-disabled-color;
}

// adjust react prefix
.rkt-FormField.rkt-FormField--has-prefix .rkt-FormField__prefix.MuiInputAdornment-root {
  left: settings.$rkt-form-field-has-prefix-react-prefix-left;
  position: settings.$rkt-form-field-has-prefix-react-prefix-position;
}

// adjustment to react inputs when formfield has prefix
.rkt-FormField.rkt-FormField--has-prefix:not(.rkt-FormField--has-tooltip) .MuiInputBase-input {
  left: settings.$rkt-form-field-has-prefix-react-input-left;
  position: settings.$rkt-form-field-has-prefix-react-input-position;
  bottom: settings.$rkt-form-field-has-prefix-react-input-bottom;
}

.rkt-FormField.rkt-FormField--has-prefix.rkt-FormField--has-tooltip .MuiInputBase-input {
  left: settings.$rkt-form-field-has-prefix-react-has-tooltip-input-left;
}

.rkt-FormField.rkt-FormField--has-prefix .rkt-Input.mat-mdc-input-element {
  left: settings.$rkt-form-field-has-prefix-angular-input-left;
  position: settings.$rkt-form-field-has-prefix-angular-input-position;
}

.rkt-FormField.rkt-FormField--has-tooltip.rkt-FormField--has-prefix .MuiInputBase-input {
  position: settings.$rkt-form-field-has-tooltip-react-input-position;
  bottom: settings.$rkt-form-field-has-tooltip-react-input-bottom;
}

.rkt-FormField.rkt-FormField--has-prefix input:-webkit-autofill {
  width: calc(100% - settings.$rkt-form-field-has-prefix-react-input-offset-width);
}

// angular tooltip popper
.rkt-FormField.rkt-FormField--has-tooltip .rkt-TooltipPopper {
  fill: settings.$rkt-form-field-has-tooltip-tooltip-popper-fill;
  left: settings.$rkt-form-field-has-tooltip-tooltip-popper-angular-left;
  outline: settings.$rkt-form-field-has-tooltip-tooltip-popper-angular-outline;
}

// react tooltip popper
.rkt-FormField.rkt-FormField.rkt-FormField--has-tooltip .rkt-TooltipPopper .MuiSvgIcon-root {
  fill: settings.$rkt-form-field-has-tooltip-tooltip-popper-fill;
  right: settings.$rkt-form-field-has-tooltip-tooltip-popper-react-right;
}

// add padding to React suffixes
.rkt-FormField .rkt-Input.MuiFilledInput-root .rkt-FormField__suffix {
  padding-right: settings.$rkt-input-suffix-react-padding-right;
}

// add padding to react tooltips
.rkt-FormField--has-tooltip .MuiInputAdornment-root {
  padding-right: settings.$rkt-form-field-has-tooltip-react-adornment-padding-right;
}

.rkt-FormField .mat-mdc-form-field.mat-accent .mdc-text-field__input,
.mat-mdc-form-field.mat-accent .mdc-text-field--invalid .mdc-text-field__input,
.rkt-FormField .rkt-Input {
  caret-color: settings.$rkt-input-caret-color;
}

// in some patterns where the prefix is an icon, we need to trim the padding - angular
.rkt-FormField--has-prefix .mat-mdc-form-field-icon-prefix > .rkt-FormField__prefix {
  padding-left: settings.$rkt-form-field-has-prefix-angular-prefix-padding-left;
  padding-right: settings.$rkt-form-field-has-prefix-angular-prefix-padding-right;
}

// invalid styles
.rkt-FormField:not(.rkt-FormField--has-textarea).mat-form-field-invalid .mat-mdc-text-field-wrapper,
.rkt-FormField:not(.rkt-FormField--has-textarea) .rkt-Input.rkt-Input--has-error,
.rkt-FormField.rkt-FormField--has-textarea.mat-form-field-invalid .mat-mdc-text-field-wrapper,
.rkt-FormField.rkt-FormField--has-textarea:has(.rkt-Input.rkt-Input--has-error),
.rkt-FormField .rkt-Input--datepicker .MuiInputBase-root.Mui-error {
  box-shadow: settings.$rkt-form-field-input-has-error-box-shadow;
}

// invalid styles on focus
.rkt-FormField:not(.rkt-FormField--has-textarea).mat-form-field-invalid .mat-mdc-text-field-wrapper:has(.rkt-Input:focus-visible),
.rkt-FormField:not(.rkt-FormField--has-textarea) .rkt-Input.rkt-Input--has-error.Mui-focused,
.rkt-FormField.rkt-FormField--has-textarea .mat-mdc-text-field-wrapper.mdc-text-field--invalid:has(.rkt-Input:focus-visible),
.rkt-FormField.rkt-FormField--has-textarea:has(.rkt-Input.rkt-Input--has-error .MuiInputBase-root.Mui-focused),
.rkt-FormField .rkt-Input--datepicker .MuiInputBase-root.Mui-error.Mui-focused {
  box-shadow: settings.$rkt-form-field-input-has-error-focus-box-shadow;
}

// error color for prefixes and suffixes
.rkt-FormField .rkt-Input.Mui-error .rkt-FormField__prefix,
.rkt-FormField .rkt-Input.Mui-error .rkt-FormField__suffix,
.rkt-FormField .rkt-Input.Mui-error .rkt-FormField__prefix .MuiTypography-root,
.rkt-FormField .rkt-Input.Mui-error .rkt-FormField__suffix .MuiTypography-root,
.rkt-FormField.mat-form-field-invalid .rkt-FormField__prefix,
.rkt-FormField.mat-form-field-invalid .rkt-FormField__suffix,
.rkt-FormField.mat-form-field-invalid .rkt-ButtonIcon.rkt-ButtonIcon--in-inputs .mat-icon,
.rkt-FormField .rkt-Input.Mui-error .rkt-ButtonIcon.rkt-ButtonIcon--in-inputs .rkt-Icon,
.rkt-FormField .rkt-Input--datepicker .MuiInputBase-root.Mui-error .rkt-Icon {
  color: settings.$rkt-form-field-input-has-error-prefix-color;
}

// error labels
.rkt-FormField.mat-form-field-invalid .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label,
.rkt-FormField .MuiFormLabel-root.Mui-error {
  color: settings.$rkt-form-field-invalid-label-color;
}

.rkt-FormField--has-left-icon .mat-mdc-form-field-icon-prefix > .mat-icon {
  padding-right: settings.$rkt-form-field-has-left-icon-angular-icon-padding-right;
}

.rkt-FormField.rkt-FormField--has-tooltip .mat-mdc-form-field-icon-suffix .mat-icon {
  padding: settings.$rkt-input-suffix-angular-icon-padding;
}

.rkt-ErrorText, .rkt-ErrorText.Mui-error {
  font-family: settings.$rkt-error-text-font-family;
  font-size: settings.$rkt-error-text-font-size;
  font-weight: settings.$rkt-error-text-font-weight;
  line-height: settings.$rkt-error-text-line-height;
  // simulate margin 0 reset thats on caption 12
  margin: settings.$rkt-error-text-margin;
  color: settings.$rkt-error-text-color;
}

// adjust angular error text
.rkt-FormField .rkt-ErrorText.mat-mdc-form-field-error {
  margin-top: settings.$rkt-error-text-angular-margin-top;
  left: settings.$rkt-error-text-angular-left;
  position: settings.$rkt-error-text-angular-position;
}

// adjust react error text
.rkt-FormField .rkt-ErrorText.MuiFormHelperText-root {
  margin-top: settings.$rkt-error-text-react-margin-top;
  left: settings.$rkt-error-text-react-left;
  position: settings.$rkt-error-text-react-position;
}

// adjust angular error text on textarea
.rkt-FormField.rkt-FormField--has-textarea .rkt-ErrorText.mat-mdc-form-field-error {
  margin-top: settings.$rkt-form-field-has-textarea-angular-error-text-margin-top;
  left: settings.$rkt-form-field-has-textarea-angular-error-text-left;
  top: settings.$rkt-form-field-has-textarea-angular-error-text-top;
  position: settings.$rkt-form-field-has-textarea-angular-error-text-position;
}

// angular min-height for error message
.rkt-FormField .mat-mdc-form-field-subscript-wrapper {
  min-height: settings.$rkt-error-text-angular-min-height;
}

// react min-height for error message
.rkt-FormField .MuiFormHelperText-root {
  min-height: settings.$rkt-error-text-react-min-height
}

.rkt-HintText {
  font-family: settings.$rkt-hint-text-font-family;
  font-size: settings.$rkt-hint-text-font-size;
  font-weight: settings.$rkt-hint-text-font-weight;
  line-height: settings.$rkt-hint-text-line-height;
  color: settings.$rkt-hint-text-color;
  // simulate margin 0 reset thats on caption 12
  margin: settings.$rkt-hint-text-margin;
}

.rkt-FormField .rkt-HintText {
  margin-top: settings.$rkt-hint-text-margin-top;
}

.rkt-FormField .rkt-HintText.MuiFormHelperText-root {
  margin-top: settings.$rkt-form-field-hint-text-react-margin-top;
}

.rkt-FormField .mat-mdc-form-field-hint-wrapper:has(.rkt-HintText) {
  padding-left: settings.$rkt-form-field-angular-wrapper-has-hint-text-padding-left;
}

.rkt-FormField .mat-mdc-form-field-hint-wrapper:has(.rkt-HintText .rkt-HintText__link) {
  padding-left:  settings.$rkt-form-field-angular-wrapper-has-hint-text-has-hint-text-link-padding-left;
}

.rkt-FormField .rkt-HintText .rkt-HintText__link {
  text-decoration: settings.$rkt-hint-text-link-text-decoration;
  color: settings.$rkt-hint-text-color;
}

// add more right padding to angular icons
.rkt-FormField:not(.rkt-FormField--has-textarea) .mat-mdc-form-field-icon-suffix {
  padding-right: settings.$rkt-input-suffix-angular-padding-right;
  position: settings.$rkt-input-suffix-angular-position;
  left: settings.$rkt-input-suffix-angular-left;
}

.rkt-FormLabel {
  font-family: settings.$rkt-form-label-font-family;
  font-size: settings.$rkt-form-label-font-size;
  font-weight: settings.$rkt-form-label-font-weight;
  line-height: settings.$rkt-form-label-line-height;
  color: settings.$rkt-form-label-color;
}

// Resets Material styles
.rkt-Fieldset,
.rkt-Legend {
  margin: 0;
  padding: 0;
  border: 0;
}

.rkt-Legend {
  color: settings.$rkt-legend-color;
  font-size: settings.$rkt-legend-font-size;
  font-weight: settings.$rkt-legend-font-weight;
  font-family: settings.$rkt-legend-font-family;
  line-height: settings.$rkt-legend-line-height;
}

.rkt-Legend--invalid {
  color: settings.$rkt-legend-invalid-color;
}

.rkt-Input--textarea.mat-mdc-form-field-textarea-control {
  resize: settings.$rkt-input-textarea-textarea-control-resize;
}

.rkt-FormField--has-textarea .mat-mdc-form-field-icon-suffix,
.rkt-FormField--has-textarea .MuiInputAdornment-root {
  align-self: settings.$rkt-form-field-has-textarea-icon-align-self;
  padding: settings.$rkt-form-field-has-textarea-icon-padding;
}

// textarea with icon right angular adjustments
.rkt-FormField--has-textarea .rkt-FormField__suffix.mat-icon {
  padding: settings.$rkt-form-field-has-textarea-with-icon-right-angular-icon-padding;
}

// textarea with icon right react adjustments
.rkt-FormField--has-textarea .rkt-FormField__suffix.MuiSvgIcon-root {
  bottom: settings.$rkt-form-field-has-textarea-with-icon-right-react-icon-bottom;
  position: settings.$rkt-form-field-has-textarea-with-icon-right-react-icon-position;
}

.rkt-FormField--has-textarea .rkt-FormField__suffix.mat-icon,
.rkt-FormField--has-textarea .rkt-FormField__suffix.MuiSvgIcon-root {
  color: settings.$rkt-input-textarea-icon-color;
}

// custom width for textarea TODO tokens
.rkt-FormField.rkt-FormField--has-textarea {
  width: settings.$rkt-form-field-has-textarea-width;
  max-width: settings.$rkt-form-field-has-textarea-max-width;
  box-sizing: settings.$rkt-form-field-has-textarea-box-sizing;
  min-height: settings.$rkt-form-field-has-textarea-min-height;
}

// custom height for textarea
.rkt-FormField.rkt-FormField--has-textarea {
  .rkt-Input--textarea:not(.MuiTextField-root) {
    min-height: settings.$rkt-form-field-has-textarea-angular-textarea-min-height;
  }

  .MuiInputBase-inputMultiline {
    min-height: settings.$rkt-form-field-has-textarea-react-textarea-min-height;
  }
}

// removes the blue x from search inputs
.rkt-Input .MuiInputBase-inputTypeSearch::-webkit-search-cancel-button,
.rkt-Input.mat-mdc-input-element::-webkit-search-cancel-button  {
  -webkit-appearance: settings.$rkt-input-search-cancel-button-appearance;
}

// adjustment for tooltips in inputs (Angular)
.rkt-Tooltip.rkt-Tooltip--in-input.mdc-tooltip {
  bottom: settings.$rkt-tooltip-angular-bottom;
  left: settings.$rkt-tooltip-angular-left;
}

// reset styles on input autofill
.rkt-FormField {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: settings.$rkt-input-autofill-background-color-reset;
  }
}

// reset styles on input autofill when hovered and focused
.rkt-Input.MuiInputBase-root:hover,
.rkt-FormField .mat-mdc-text-field-wrapper:has(.rkt-Input:focus-visible),
.rkt-FormField .rkt-Input.Mui-focused,
.rkt-FormField:hover {
  input:not(:disabled):-webkit-autofill,
  input:not(:disabled):-webkit-autofill:hover,
  input:not(:disabled):-webkit-autofill:focus,
  input:not(:disabled):-webkit-autofill:active {
    -webkit-box-shadow: settings.$rkt-input-autofill-hover-background-color-reset;
  }
}

.rkt-FormField:has(input:-webkit-autofill) .mdc-text-field--filled:not(.mdc-text-field--disabled),
.rkt-FormField:has(input:-webkit-autofill) .rkt-Input.MuiInputBase-root {
  overflow: settings.$rkt-input-autofill-input-container-overflow;
}

// scope to React
.rkt-FormField .rkt-ButtonIcon.rkt-ButtonIcon--in-inputs.MuiButtonBase-root {
  left: settings.$rkt-form-field-button-icon-in-inputs-left;
}