@use './color' as *;
@use './color-dark' as *;
@use './backdrop' as *;
$rkt-typography-breakpoint-medium: 768px !default;
$rkt-typography-color-button: $rkt-gray-800 !default;
$rkt-typography-color-input: $rkt-gray-800 !default;
$rkt-typography-color-subheading-one: $rkt-gray-800 !default;
$rkt-typography-color-subheading-two: $rkt-gray-800 !default;
$rkt-typography-color-title: $rkt-gray-800 !default;
$rkt-typography-color-headline: $rkt-gray-800 !default;
$rkt-typography-color-caption: $rkt-gray-800 !default;
$rkt-typography-color-heading-18: $rkt-gray-800 !default;
$rkt-typography-color-heading-20: $rkt-gray-800 !default;
$rkt-typography-color-heading-24: $rkt-gray-800 !default;
$rkt-typography-color-heading-28: $rkt-gray-800 !default;
$rkt-typography-color-heading-32: $rkt-gray-800 !default;
$rkt-typography-color-heading-36: $rkt-gray-800 !default;
$rkt-typography-color-heading-40: $rkt-gray-800 !default;
$rkt-typography-color-heading-48: $rkt-gray-800 !default;
$rkt-typography-color-heading-56: $rkt-gray-800 !default;
$rkt-typography-color-label-14: $rkt-gray-800 !default;
$rkt-typography-color-label-16: $rkt-gray-800 !default;
$rkt-typography-color-label-18: $rkt-gray-800 !default;
$rkt-typography-color-label-20: $rkt-gray-800 !default;
$rkt-typography-color-body-14: $rkt-gray-800 !default;
$rkt-typography-color-body-16: $rkt-gray-800 !default;
$rkt-typography-color-body-18: $rkt-gray-800 !default;
$rkt-typography-color-caption-12: $rkt-gray-800 !default;
$rkt-typography-color-caption-10: $rkt-gray-800 !default;
$rkt-typography-color-tracked-12: $rkt-gray-800 !default;
$rkt-typography-color-tracked-16: $rkt-gray-800 !default;
$rkt-typography-color-decorative-68: $rkt-gray-800 !default;
$rkt-typography-color-decorative-80: $rkt-gray-800 !default;
$rkt-typography-color-decorative-112: $rkt-gray-800 !default;
$rkt-typography-font-family-button: 'Wintle Text', Helvetica, 'Helvetica Neue', Arial, sans-serif !default;
$rkt-typography-font-family-caption: 'Wintle Text', Helvetica, 'Helvetica Neue', Arial, sans-serif !default;
$rkt-typography-font-family-input: 'Wintle Text', Helvetica, 'Helvetica Neue', Arial, sans-serif !default;
$rkt-typography-font-family-subheading-one: 'Wintle Text', Helvetica, 'Helvetica Neue', Arial, sans-serif !default;
$rkt-typography-font-family-subheading-two: 'Wintle Text', Helvetica, 'Helvetica Neue', Arial, sans-serif !default;
$rkt-typography-font-family-title: 'Wintle Text', Helvetica, 'Helvetica Neue', Arial, sans-serif !default;
$rkt-typography-font-family-headline: 'Wintle Text', Helvetica, 'Helvetica Neue', Arial, sans-serif !default;
$rkt-typography-font-family-rocket: 'Wintle Text', Helvetica, 'Helvetica Neue', Arial, sans-serif !default;
$rkt-typography-font-family-heading-18: 'Wintle Text', Helvetica, 'Helvetica Neue', Arial, sans-serif !default;
$rkt-typography-font-family-heading-20: 'Wintle Text', Helvetica, 'Helvetica Neue', Arial, sans-serif !default;
$rkt-typography-font-family-heading-24: 'Wintle Text', Helvetica, 'Helvetica Neue', Arial, sans-serif !default;
$rkt-typography-font-family-heading-28: 'Wintle Text', Helvetica, 'Helvetica Neue', Arial, sans-serif !default;
$rkt-typography-font-family-heading-32: 'Wintle Text', Helvetica, 'Helvetica Neue', Arial, sans-serif !default;
$rkt-typography-font-family-heading-36: 'Wintle Text', Helvetica, 'Helvetica Neue', Arial, sans-serif !default;
$rkt-typography-font-family-heading-40: 'Wintle Text', Helvetica, 'Helvetica Neue', Arial, sans-serif !default;
$rkt-typography-font-family-heading-48: 'Wintle Text', Helvetica, 'Helvetica Neue', Arial, sans-serif !default;
$rkt-typography-font-family-heading-56: 'Wintle Text', Helvetica, 'Helvetica Neue', Arial, sans-serif !default;
$rkt-typography-font-family-label-14: 'Wintle Text', Helvetica, 'Helvetica Neue', Arial, sans-serif !default;
$rkt-typography-font-family-label-16: 'Wintle Text', Helvetica, 'Helvetica Neue', Arial, sans-serif !default;
$rkt-typography-font-family-label-18: 'Wintle Text', Helvetica, 'Helvetica Neue', Arial, sans-serif !default;
$rkt-typography-font-family-label-20: 'Wintle Text', Helvetica, 'Helvetica Neue', Arial, sans-serif !default;
$rkt-typography-font-family-body-14: 'Wintle Text', Helvetica, 'Helvetica Neue', Arial, sans-serif !default;
$rkt-typography-font-family-body-16: 'Wintle Text', Helvetica, 'Helvetica Neue', Arial, sans-serif !default;
$rkt-typography-font-family-body-18: 'Wintle Text', Helvetica, 'Helvetica Neue', Arial, sans-serif !default;
$rkt-typography-font-family-caption-12: 'Wintle Text', Helvetica, 'Helvetica Neue', Arial, sans-serif !default;
$rkt-typography-font-family-caption-10: 'Wintle Text', Helvetica, 'Helvetica Neue', Arial, sans-serif !default;
$rkt-typography-font-family-tracked-12: 'Wintle Text', Helvetica, 'Helvetica Neue', Arial, sans-serif !default;
$rkt-typography-font-family-tracked-16: 'Wintle Text', Helvetica, 'Helvetica Neue', Arial, sans-serif !default;
$rkt-typography-font-family-decorative-68: 'Wintle Text', Helvetica, 'Helvetica Neue', Arial, sans-serif !default;
$rkt-typography-font-family-decorative-80: 'Wintle Text', Helvetica, 'Helvetica Neue', Arial, sans-serif !default;
$rkt-typography-font-family-decorative-112: 'Wintle Text', Helvetica, 'Helvetica Neue', Arial, sans-serif !default;
$rkt-typography-font-size-caption: 12px !default;
$rkt-typography-font-size-subheading-one: 18px !default;
$rkt-typography-font-size-subheading-two: 18px !default;
$rkt-typography-font-size-button: 14px !default;
$rkt-typography-font-size-headline: 1rem !default;
$rkt-typography-font-size-title: 20px !default;
$rkt-typography-font-size-input: 1rem !default;
$rkt-typography-font-size-heading-18: 1.125rem !default;
$rkt-typography-font-size-heading-20: 1.25rem !default;
$rkt-typography-font-size-heading-24: 1.5rem !default;
$rkt-typography-font-size-heading-28: 1.75rem !default;
$rkt-typography-font-size-heading-32: 2rem !default;
$rkt-typography-font-size-heading-36: 2.25rem !default;
$rkt-typography-font-size-heading-40: 2.5rem !default;
$rkt-typography-font-size-heading-48: 3rem !default;
$rkt-typography-font-size-heading-56: 3.5rem !default;
$rkt-typography-font-size-label-14: 0.875rem !default;
$rkt-typography-font-size-label-16: 1rem !default;
$rkt-typography-font-size-label-18: 1.125rem !default;
$rkt-typography-font-size-label-20: 1.25rem !default;
$rkt-typography-font-size-body-14: 0.875rem !default;
$rkt-typography-font-size-body-16: 1rem !default;
$rkt-typography-font-size-body-18: 1.125rem !default;
$rkt-typography-font-size-caption-12: 0.75rem !default;
$rkt-typography-font-size-caption-10: 0.625rem !default;
$rkt-typography-font-size-tracked-12: 0.75rem !default;
$rkt-typography-font-size-tracked-16: 1rem !default;
$rkt-typography-font-size-decorative-68: 4.25rem !default;
$rkt-typography-font-size-decorative-80: 5rem !default;
$rkt-typography-font-size-decorative-112: 7rem !default;
$rkt-typography-font-weight-300: 300 !default;
$rkt-typography-font-weight-400: 400 !default;
$rkt-typography-font-weight-500: 500 !default;
$rkt-typography-font-weight-600: 600 !default;
$rkt-typography-font-weight-700: 700 !default;
$rkt-typography-font-weight-subheading-one: 300 !default;
$rkt-typography-font-weight-subheading-two: 500 !default;
$rkt-typography-font-weight-caption: 400 !default;
$rkt-typography-font-weight-button: 500 !default;
$rkt-typography-font-weight-headline: 500 !default;
$rkt-typography-font-weight-title: 400 !default;
$rkt-typography-font-weight-input: 400 !default;
$rkt-typography-font-weight-heading-18: 500 !default;
$rkt-typography-font-weight-heading-20: 500 !default;
$rkt-typography-font-weight-heading-24: 500 !default;
$rkt-typography-font-weight-heading-28: 500 !default;
$rkt-typography-font-weight-heading-32: 500 !default;
$rkt-typography-font-weight-heading-36: 500 !default;
$rkt-typography-font-weight-heading-40: 500 !default;
$rkt-typography-font-weight-heading-48: 500 !default;
$rkt-typography-font-weight-heading-56: 500 !default;
$rkt-typography-font-weight-label-14: 500 !default;
$rkt-typography-font-weight-label-16: 500 !default;
$rkt-typography-font-weight-label-18: 500 !default;
$rkt-typography-font-weight-label-20: 500 !default;
$rkt-typography-font-weight-body-14: 400 !default;
$rkt-typography-font-weight-body-16: 400 !default;
$rkt-typography-font-weight-body-18: 400 !default;
$rkt-typography-font-weight-caption-12: 400 !default;
$rkt-typography-font-weight-caption-10: 400 !default;
$rkt-typography-font-weight-tracked-12: 400 !default;
$rkt-typography-font-weight-tracked-16: 400 !default;
$rkt-typography-font-weight-decorative-68: 500 !default;
$rkt-typography-font-weight-decorative-80: 500 !default;
$rkt-typography-font-weight-decorative-112: 500 !default;
$rkt-typography-line-height-heading-18: 1.5rem !default;
$rkt-typography-line-height-heading-20: 1.75rem !default;
$rkt-typography-line-height-heading-24: 2rem !default;
$rkt-typography-line-height-heading-28: 2.25rem !default;
$rkt-typography-line-height-heading-32: 2.5rem !default;
$rkt-typography-line-height-heading-36: 2.75rem !default;
$rkt-typography-line-height-heading-40: 3rem !default;
$rkt-typography-line-height-heading-48: 3.5rem !default;
$rkt-typography-line-height-heading-56: 4rem !default;
$rkt-typography-line-height-label-14: 1.25rem !default;
$rkt-typography-line-height-label-16: 1.5rem !default;
$rkt-typography-line-height-label-18: 1.5rem !default;
$rkt-typography-line-height-label-20: 1.625rem !default;
$rkt-typography-line-height-body-14: 1.25rem !default;
$rkt-typography-line-height-body-16: 1.5rem !default;
$rkt-typography-line-height-body-18: 1.75rem !default;
$rkt-typography-line-height-caption-12: 1rem !default;
$rkt-typography-line-height-caption-10: 0.938rem !default;
$rkt-typography-line-height-tracked-12: 0.75rem !default;
$rkt-typography-line-height-tracked-16: 1rem !default;
$rkt-typography-line-height-decorative-68: 4.5rem !default;
$rkt-typography-line-height-decorative-80: 5.5rem !default;
$rkt-typography-line-height-decorative-112: 7.75rem !default;
$rkt-typography-line-height-subheading-one: 1.33 !default;
$rkt-typography-line-height-subheading-two: 1.33 !default;
$rkt-typography-line-height-title: 1.4 !default;
$rkt-typography-line-height-caption: 1.33 !default;
$rkt-typography-line-height-headline: 1.33 !default;
$rkt-typography-line-height-button: 24px !default;
$rkt-typography-line-height-input: 1.5em !default;
$rkt-typography-letter-spacing-tracked-12: 0.06em !default;
$rkt-typography-letter-spacing-tracked-16: 0.15em !default;
$rkt-typography-letter-spacing-heading-36: -0.02em !default;
$rkt-typography-letter-spacing-heading-40: -1px !default;
$rkt-typography-letter-spacing-heading-48: -1px !default;
$rkt-typography-letter-spacing-heading-56: -1px !default;
$rkt-typography-letter-spacing-decorative-68: -1px !default;
$rkt-typography-letter-spacing-decorative-80: -1px !default;
$rkt-typography-letter-spacing-decorative-112: -1px !default;
$rkt-typography-text-transform-tracked-12: uppercase !default;
$rkt-typography-text-transform-tracked-16: uppercase !default;