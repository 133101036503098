@use '../web/scss/settings';

.rkt-List,
.rkt-List.mdc-list {
  margin: settings.$rkt-list-margin;
  padding: settings.$rkt-list-padding;
  list-style-type: settings.$rkt-list-list-style-type;
}

// Icon styles
.rkt-List .mat-mdc-list-item .mat-icon.rkt-List__item-icon,
.rkt-List .mat-icon.rkt-List__item-icon,
.rkt-List .rkt-List__item-icon {
  color: settings.$rkt-list-item-icon-color;
  margin-right: settings.$rkt-list-item-icon-margin-right;
  margin-top: settings.$rkt-list-item-icon-margin-top;
  align-self: settings.$rkt-list-item-icon-align-self;
  margin-left: settings.$rkt-list-item-icon-margin-left;
}

// title text styles
.rkt-List .mat-mdc-list-item .mat-mdc-list-item-title.rkt-List__item-title,
.rkt-List .rkt-List__item-title,
.rkt-List .MuiListItemText-primary {
  color: settings.$rkt-list-item-title-color;
  font-family: settings.$rkt-list-item-title-font-family;
  font-size: settings.$rkt-list-item-title-font-size;
  font-weight: settings.$rkt-list-item-title-font-weight;
  line-height: settings.$rkt-list-item-title-line-height;
  margin: settings.$rkt-list-item-title-margin;
}

// description (line) text styles
.rkt-List .mat-mdc-list-item .mat-mdc-list-item-line.rkt-List__item-line,
.rkt-List .rkt-List__item-line,
.rkt-List .MuiListItemText-secondary {
  color: settings.$rkt-list-item-line-color;
  font-family: settings.$rkt-list-item-line-font-family;
  font-size: settings.$rkt-list-item-line-font-size;
  font-weight: settings.$rkt-list-item-line-font-weight;
  line-height: settings.$rkt-list-item-line-line-height;
  margin: settings.$rkt-list-item-line-margin;
}

.rkt-List__item,
.rkt-List__item.mdc-list-item,
.rkt-List__button.mdc-list-item  {
  overflow: settings.$rkt-list-item-overflow;
  padding: settings.$rkt-list-item-padding;
}

// item text padding
.rkt-List__item .MuiListItemText-root,
.rkt-List__item .mdc-list-item__content .rkt-List__item-line,
.rkt-List__item .mdc-list-item__content .rkt-List__item-title,
.rkt-List__button .mdc-list-item__content .rkt-List__item-line,
.rkt-List__button .mdc-list-item__content .rkt-List__item-title {
  padding-right: settings.$rkt-list-item-text-padding-right;
}

// main item structure and padding
.rkt-List__item,
.rkt-List .rkt-List__item.mdc-list-item,
.rkt-List__button,
.rkt-List__button.mat-mdc-list-item,
.rkt-List__button.mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines,
.rkt-List__button.mdc-list-item.mdc-list-item--with-two-lines,
.rkt-List__button.mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  box-sizing: settings.$rkt-list-item-box-sizing;
  flex: settings.$rkt-list-item-flex;
  max-width: settings.$rkt-list-item-max-width;
  width: settings.$rkt-list-item-width;
  border-radius: settings.$rkt-list-item-border-radius;
  min-height: settings.$rkt-list-item-min-height;
  height: settings.$rkt-list-item-height;
}

.rkt-List.rkt-List--not-selectable .rkt-List__item,
.rkt-List:not(.rkt-List--not-selectable) .rkt-List__button,
.rkt-List:not(.rkt-List--not-selectable) .rkt-List__button.mat-mdc-list-item,
.rkt-List:not(.rkt-List--not-selectable) .rkt-List__button.mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines,
.rkt-List:not(.rkt-List--not-selectable) .rkt-List__button.mdc-list-item.mdc-list-item--with-two-lines,
.rkt-List:not(.rkt-List--not-selectable) .rkt-List__button.mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  padding: settings.$rkt-list-button-padding;
  background-color: settings.$rkt-list-button-background-color;
}

.rkt-List:not(.rkt-List--not-selectable) .rkt-List__button:focus-visible,
.rkt-List:not(.rkt-List--not-selectable) .rkt-List__button.mat-mdc-list-item:focus-visible {
  box-shadow: settings.$rkt-list-button-focus-visible-box-shadow;
  outline: settings.$rkt-list-button-focus-visible-outline;
}

.rkt-List:not(.rkt-List--not-selectable) .rkt-List__button,
.rkt-List:not(.rkt-List--not-selectable) .rkt-List__button.mat-mdc-list-item,
.rkt-List:not(.rkt-List--not-selectable) .rkt-List__button.mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines,
.rkt-List:not(.rkt-List--not-selectable) .rkt-List__button.mdc-list-item.mdc-list-item--with-two-lines,
.rkt-List:not(.rkt-List--not-selectable) .rkt-List__button.mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  .MuiListItemButton-root:hover {
    background-color: settings.$rkt-list-button-hover-background-color;
  }

  &:hover {
    background-color: settings.$rkt-list-button-hover-item-background-color;
  }
}

// selectable item styles
.rkt-List--no-checkbox .rkt-List__button.rkt-List__button--is-selected,
.rkt-List--no-checkbox .rkt-List__button.rkt-List__button--is-selected:hover,
.rkt-List--no-checkbox .rkt-List__button.rkt-List__button--is-selected.mdc-list-item.mdc-list-item--selected,
.rkt-List--no-checkbox .rkt-List__button.rkt-List__button--is-selected.mdc-list-item.mdc-list-item--with-two-lines,
.rkt-List--no-checkbox:not(.rkt-List--not-selectable) .rkt-List__button.rkt-List__button--is-selected.mdc-list-item.mdc-list-item--with-two-lines:hover,
.rkt-List--no-checkbox .rkt-List__button.rkt-List__button--is-selected.mat-mdc-list-item.mdc-list-item--with-one-line,
.rkt-List--no-checkbox:not(.rkt-List--not-selectable) .rkt-List__button.rkt-List__button--is-selected.mat-mdc-list-item.mdc-list-item--with-one-line:hover,
.rkt-List--no-checkbox:not(.rkt-List--not-selectable) .rkt-List__button.rkt-List__button--is-selected:hover,
.rkt-List--no-checkbox:not(.rkt-List--not-selectable) .rkt-List__button.rkt-List__button--is-selected.mat-mdc-list-item:hover {
  background-color: settings.$rkt-list-button-selected-background-color;

  .rkt-List__item-title,
  .rkt-Icon,
  + .MuiListItemSecondaryAction-root .rkt-Icon {
    color: settings.$rkt-list-button-selected-color;
  }
  .MuiListItemButton-root.Mui-selected {
    background-color: settings.$rkt-list-button-selected-item-background-color;
  }
}

// with icon selected styles
.rkt-List--no-checkbox .rkt-List__button.rkt-List__button--is-selected.mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line,
.rkt-List--no-checkbox .rkt-List__button.rkt-List__button--is-selected.mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines,
.rkt-List--no-checkbox:not(.rkt-List--not-selectable) .rkt-List__button.rkt-List__button--is-selected.mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line:hover,
.rkt-List--no-checkbox:not(.rkt-List--not-selectable) .rkt-List__button.rkt-List__button--is-selected.mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines:hover,
.rkt-List--no-checkbox:not(.rkt-List--not-selectable) .rkt-List__button.rkt-List__button--is-selected:has(.MuiListItemIcon-root) {
  background-color: settings.$rkt-list-button-with-icon-selected-background-color;

  .rkt-List__item-title,
  .rkt-Icon,
  + .MuiListItemSecondaryAction-root .rkt-Icon {
    color: settings.$rkt-list-button-with-icon-selected-color;
  }
}

// shows hidden text, and removes ellipsis
.rkt-List .rkt-List__item.mat-mdc-list-item .mdc-list-item__secondary-text,
.rkt-List .rkt-List__item.mdc-list-item .mdc-list-item__primary-text,
.rkt-List .rkt-List__button.mat-mdc-list-item .mdc-list-item__secondary-text,
.rkt-List .rkt-List__button.mdc-list-item .mdc-list-item__primary-text {
  overflow: settings.$rkt-list-item-text-overflow;
  text-overflow: settings.$rkt-list-item-text-text-overflow;
  white-space: settings.$rkt-list-item-text-white-space;
}

.rkt-List__button .mdc-list-item__content,
.rkt-List__item .mdc-list-item__content,
.rkt-List .MuiListItemText-root {
  display: settings.$rkt-list-item-content-display;
  flex-direction: settings.$rkt-list-item-content-flex-direction;
  gap: settings.$rkt-list-item-content-gap;
  margin: settings.$rkt-list-item-content-margin;
}

.rkt-List__button .MuiListItemIcon-root {
  min-width: settings.$rkt-list-button-icon-min-width;
}

// checkbox styles
.rkt-List .MuiListItemSecondaryAction-root {
  width: settings.$rkt-list-checkbox-width;
  height: settings.$rkt-list-checkbox-height;
  position: settings.$rkt-list-checkbox-position;
  right: settings.$rkt-list-checkbox-right;
  top: settings.$rkt-list-checkbox-top;
  transform: settings.$rkt-list-checkbox-transform;
}

.rkt-List .rkt-Checkbox {
  width: settings.$rkt-list-checkbox-item-width;
  height: settings.$rkt-list-checkbox-item-height;
}

.rkt-List .rkt-List__button .mdc-checkbox,
.rkt-List .rkt-Checkbox {
  margin: settings.$rkt-list-checkbox-margin;
  padding: settings.$rkt-list-checkbox-padding;
}

.rkt-List .mdc-list-item--with-trailing-checkbox .mdc-list-item__end {
  margin-left: settings.$rkt-list-checkbox-margin-left;
  margin-right: settings.$rkt-list-checkbox-margin-right;
}

// adds the checkmark icon to the selected item in angular
.rkt-List.rkt-List--no-checkbox .rkt-List__button.mat-mdc-list-option:not(.mdc-list-item--with-leading-icon).rkt-List__button--is-selected::after {
  content: " ";
  display: settings.$rkt-list-checkbox-icon-display;
  background-image: settings.$rkt-list-checkbox-icon-background-image;
  width: settings.$rkt-list-checkbox-icon-width;
  height: settings.$rkt-list-checkbox-icon-height;
  position: settings.$rkt-list-checkbox-icon-position;
  right: settings.$rkt-list-checkbox-icon-right;
  background-repeat: settings.$rkt-list-checkbox-icon-background-repeat;
  top: settings.$rkt-list-checkbox-icon-top;
  transform: settings.$rkt-list-checkbox-icon-transform;
}

.rkt-List.rkt-List--no-checkbox .MuiListItemSecondaryAction-root .MuiSvgIcon-root {
  width: settings.$rkt-list-checkbox-icon-svg-width;
}

// adds the "divider"
.rkt-List.rkt-List--not-selectable .rkt-List__item::before,
.rkt-List .rkt-List__button.rkt-List__button--is-selected.mdc-list-item.mdc-list-item--selected::before,
.rkt-List .rkt-List__button.mdc-list-item::before,
.rkt-List .mdc-list-item:not(.mdc-list-item--selected):focus::before,
.rkt-List .mdc-list-item.mdc-ripple-upgraded--background-focused::before,
.rkt-List.MuiList-root .rkt-List__item.MuiListItem-root::before,
.rkt-List.MuiList-root .rkt-List__item.MuiListItem-root:active::before {
  content: ' ';
  display: settings.$rkt-list-divider-display;
  position: settings.$rkt-list-divider-position;
  top: settings.$rkt-list-divider-top;
  right: settings.$rkt-list-divider-right;
  bottom: settings.$rkt-list-divider-bottom;
  left: settings.$rkt-list-divider-left;
  background-color: settings.$rkt-list-divider-background-color;
  width: settings.$rkt-list-divider-width;
  height: settings.$rkt-list-divider-height;
  opacity: settings.$rkt-list-divider-opacity;
  border: settings.$rkt-list-divider-border;
}

// hide ripples and other elements that material adds
.rkt-List__button.mdc-list-item:focus-visible + .rkt-List__button.mdc-list-item::before,
.rkt-List__item.mdc-list-item--with-two-lines .mdc-list-item__primary-text::before,
.rkt-List__item.mdc-list-item--with-two-lines .mdc-list-item__primary-text::after,
.rkt-List__item .mat-mdc-list-item-unscoped-content,
.rkt-List__item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines .mdc-list-item__primary-text::before,
.rkt-List__item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines .mdc-list-item__primary-text::after,
.rkt-List__button.mdc-list-item--with-two-lines .mdc-list-item__primary-text::before,
.rkt-List__button.mdc-list-item--with-two-lines .mdc-list-item__primary-text::after,
.rkt-List__button .mat-mdc-list-item-unscoped-content,
.rkt-List__button.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines .mdc-list-item__primary-text::before,
.rkt-List__button.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines .mdc-list-item__primary-text::after,
.rkt-List__button .mdc-list-item__secondary-text::before,
.rkt-List__item .mdc-list-item__secondary-text::before,
.rkt-List--no-checkbox .mat-mdc-list-item.mat-mdc-list-option .mdc-list-item__end,
.rkt-List.MuiList-root .rkt-List__item.MuiListItem-root:first-child::before,
.rkt-List.MuiList-root .rkt-List__item.MuiListItem-root:active:first-child::before,
.rkt-List.MuiList-root .rkt-List__item.MuiListItem-root:has(.rkt-List__button:focus-visible) + .rkt-List__item.MuiListItem-root::before,
.rkt-List.rkt-List--not-selectable .rkt-List__item:first-child::before,
.rkt-List .mdc-list-item.mdc-list-item--selected:first-child::before,
.rkt-List .rkt-List__button.mdc-list-item:first-child::before,
.rkt-List .mdc-list-item:not(.mdc-list-item--selected):focus:first-child::before,
.rkt-List .mdc-list-item.mdc-ripple-upgraded--background-focused:first-child::before,
.rkt-List .rkt-List__button.rkt-List__button--is-selected.mdc-list-item.mdc-list-item--selected:first-child::before {
  display: settings.$rkt-list-display;
}