@use '../web/scss/settings';

.rkt-Autocomplete {
  width: settings.$rkt-autocomplete-width;

  &.rkt-FormField:hover .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background: settings.$rkt-autocomplete-hover-background;
  }
}

.rkt-Autocomplete--with-chips.rkt-FormField {
  .mat-mdc-text-field-wrapper.mdc-text-field {
    width: settings.$rkt-autocomplete-with-chips-width;
  }
}

.rkt-Autocomplete__panel--with-chips .mdc-list-item__primary-text {
  display: settings.$rkt-autocomplete-with-chips-mdc-list-item-primary-text-display;
  align-items: settings.$rkt-autocomplete-with-chips-mdc-list-item-primary-text-align-items;
  justify-content: settings.$rkt-autocomplete-with-chips-mdc-list-item-primary-text-justify-content;
  width: settings.$rkt-autocomplete-with-chips-mdc-list-item-primary-text-width;
}

// Creates space between input and list
.cdk-overlay-pane:has(.rkt-Autocomplete__panel) {
  background-color: settings.$rkt-autocomplete-pane-background-color;
  overflow: settings.$rkt-autocomplete-pane-overflow;
  border-radius: settings.$rkt-autocomplete-pane-container-border-radius;
  margin: settings.$rkt-autocomplete-pane-margin;
  box-shadow: settings.$rkt-autocomplete-pane-box-shadow;
  padding: settings.$rkt-autocomplete-pane-padding;
}

.rkt-Autocomplete__panel.mdc-menu-surface.mat-mdc-autocomplete-panel {
  max-width: settings.$rkt-autocomplete-panel-max-width;
  box-shadow: settings.$rkt-autocomplete-panel-box-shadow;
  padding: settings.$rkt-autocomplete-panel-padding;
  overflow-y: settings.$rkt-autocomplete-panel-overflow-y;

  .mat-mdc-option {
    border-radius: settings.$rkt-autocomplete-option-border-radius;
    min-height: settings.$rkt-autocomplete-option-min-height;
    padding: settings.$rkt-autocomplete-option-padding;
  }

  .rkt-Autocomplete__option-content {
    display: settings.$rkt-autocomplete-option-content-display;
    padding: settings.$rkt-autocomplete-option-content-padding;
    width: settings.$rkt-autocomplete-option-content-width;
    justify-content: settings.$rkt-autocomplete-option-content-justify-content;
    margin-right: settings.$rkt-autocomplete-option-content-margin-right;
  }

  .rkt-Autocomplete__label-with-image {
    display: settings.$rkt-autocomplete-option-label-with-image-display;
    align-items: settings.$rkt-autocomplete-option-label-with-image-align-items;
  }

  // focus visible
  .mat-mdc-option.mat-mdc-option-active {
    box-shadow: settings.$rkt-autocomplete-option-focus-visible-box-shadow;
    background: settings.$rkt-autocomplete-option-focus-visible-background;

    // Cancel out background
    &:not(.mdc-list-item--disabled):hover {
      background: settings.$rkt-autocomplete-option-focus-visible-background;
    }

    &::after {
      visibility: settings.$rkt-autocomplete-option-focus-visible-after-visibility;
    }
  }

  // selected single
  .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(
      .mdc-list-item--disabled
    ) {
    background: settings.$rkt-autocomplete-option-single-selected-background;

    .rkt-Autocomplete__option,
    .rkt-Autocomplete__option-image,
    .mdc-list-item__primary-text {
      color: settings.$rkt-autocomplete-option-single-selected-color;
    }
  }

  // hover
  .mat-mdc-option:hover:not(.mdc-list-item--disabled) {
    background: settings.$rkt-autocomplete-option-single-hover-background;
  }

  // fake checkbox
  .mat-pseudo-checkbox {
    display: settings.$rkt-autocomplete-option-single-pseudo-checkbox-display;
    width: settings.$rkt-autocomplete-option-single-pseudo-checkbox-width;
    height: settings.$rkt-autocomplete-option-single-pseudo-checkbox-height;
    margin: settings.$rkt-autocomplete-option-single-pseudo-checkbox-margin;

    &::after {
      border-radius: settings.$rkt-autocomplete-option-single-pseudo-checkbox-after-border-radius;
      color: settings.$rkt-autocomplete-option-single-pseudo-checkbox-after-color;
      width: settings.$rkt-autocomplete-option-single-pseudo-checkbox-after-width;
      height: settings.$rkt-autocomplete-option-single-pseudo-checkbox-after-height;
      top: settings.$rkt-autocomplete-option-single-pseudo-checkbox-after-top;
    }
  }
}

// with chips
.rkt-Autocomplete__panel.rkt-Autocomplete__panel--with-chips.mdc-menu-surface.mat-mdc-autocomplete-panel {
  // selected
  .mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(
      .mdc-list-item--disabled
    ) {
    background: settings.$rkt-autocomplete-with-chips-selected-background;

    .mdc-list-item__primary-text {
      color: settings.$rkt-autocomplete-with-chips-selected-color;
    }

    .rkt-Autocomplete__option-image {
      color: settings.$rkt-autocomplete-with-chips-selected-color;
    }
  }

  .mat-pseudo-checkbox {
    display: settings.$rkt-autocomplete-with-chips-pseudo-checkbox-display;
  }

  .rkt-Checkbox .mdc-checkbox,
  .rkt-Checkbox.mat-mdc-checkbox .mdc-checkbox {
    padding: settings.$rkt-autocomplete-with-chips-checkbox-padding;
    margin: settings.$rkt-autocomplete-with-chips-checkbox-margin;
  }

  .rkt-Checkbox.mat-mdc-checkbox {
    height: settings.$rkt-autocomplete-with-chips-checkbox-height;
    width: settings.$rkt-autocomplete-with-chips-checkbox-width;
  }
}

.rkt-Autocomplete__option,
.rkt-Autocomplete__option.mat-mdc-option.mat-mdc-option-active {
  display: settings.$rkt-autocomplete-option-display;
  align-items: settings.$rkt-autocomplete-option-align-items;
  font-family: settings.$rkt-autocomplete-option-font-family;
  font-size: settings.$rkt-autocomplete-option-font-size;
  font-weight: settings.$rkt-autocomplete-option-font-weight;
  color: settings.$rkt-autocomplete-option-color;
  line-height: settings.$rkt-autocomplete-option-line-height;
  position: settings.$rkt-autocomplete-option-position;

  // border
  &::after {
    content: '';
    display: settings.$rkt-autocomplete-option-after-display;
    width: settings.$rkt-autocomplete-option-after-width;
    height: settings.$rkt-autocomplete-option-after-height;
    background-color: settings.$rkt-autocomplete-option-after-background-color;
    position: settings.$rkt-autocomplete-option-after-position;
    bottom: settings.$rkt-autocomplete-option-after-bottom;
    left: settings.$rkt-autocomplete-option-after-left;
    transform: settings.$rkt-autocomplete-option-after-transform;
  }

  // Do not show ::after border
  &.mdc-list-item--selected::after,
  &:last-of-type::after,
  &:has(+ .mdc-list-item--selected)::after {
    visibility: settings.$rkt-autocomplete-option-after-last-item;
  }
}

// Essential to functionality, will not tokenize
.rkt-Autocomplete__panel.rkt-Autocomplete__panel--with-chips
  .rkt-Autocomplete__option {
  &::after {
    display: block;
  }

  // Do not show ::after border
  &.mdc-list-item--selected::after,
  &:last-of-type::after,
  &:has(+ .mdc-list-item--selected)::after {
    display: none;
  }
}

// Option Hover
.rkt-Autocomplete__panel.mdc-menu-surface.mat-mdc-autocomplete-panel
  .mat-mdc-option:hover:not(.mdc-list-item--disabled) {
  background: settings.$rkt-autocomplete-option-hover-background;
}

.rkt-Autocomplete__option-group-label {
  &.mat-mdc-optgroup .mat-mdc-optgroup-label {
    padding: settings.$rkt-autocomplete-option-group-label-padding;

    .mdc-list-item__primary-text {
      font-weight: settings.$rkt-autocomplete-option-group-label-font-weight;
      color: settings.$rkt-autocomplete-option-group-label-color;
      font-size: settings.$rkt-autocomplete-option-group-label-font-size;
    }
  }
}

.rkt-Autocomplete__option-image {
  margin: settings.$rkt-autocomplete-option-image-margin;
}

.rkt-Autocomplete .mat-mdc-input-element::placeholder {
  font-weight: settings.$rkt-autocomplete-placeholder-font-weight;
}

.rkt-Autocomplete--with-chips .mat-mdc-input-element {
  margin: settings.$rkt-autocomplete-with-chips-input-margin;
}

.rkt-Autocomplete__chips[role='grid'] {
  margin: settings.$rkt-autocomplete-chips-margin;
}

.rkt-Autocomplete__chips[role='grid']:has(
    .mdc-evolution-chip-set__chips:empty
  ) {
  margin: settings.$rkt-autocomplete-chips-no-chips-margin;
}

.rkt-Autocomplete--with-chips.rkt-FormField:not(.rkt-FormField--has-textarea)
  .mat-mdc-text-field-wrapper {
  height: settings.$rkt-autocomplete-with-chips-input-height;
}

// Spacing between container
.rkt-Autocomplete--with-chips.rkt-FormField:not(
    .rkt-FormField--has-textarea
  ):not(.rkt-FormField--has-left-icon)
  .mat-mdc-text-field-wrapper {
  padding: settings.$rkt-autocomplete-with-chips-input-padding;
  min-height: settings.$rkt-autocomplete-with-chips-input-min-height;
}

.rkt-Autocomplete .rkt-Autocomplete__chips {
  flex-shrink: settings.$rkt-autocomplete-chips-flex-shrink;
}

// React
.MuiFormControl-root:has(.rkt-Autocomplete) .MuiFormControl-root {
  border-radius: settings.$rkt-autocomplete-border-radius;
  background: settings.$rkt-autocomplete-background;
}

.rkt-FormField.MuiFormControl-root:has(.rkt-Autocomplete) .Mui-disabled .MuiFormControl-root:hover {
  background: settings.$rkt-autocomplete-background;
}

.rkt-Autocomplete .Mui-disabled {
  color: settings.$rkt-autocomplete-disabled-color;
  -webkit-text-fill-color: settings.$rkt-autocomplete-disabled-color;
}

.rkt-Autocomplete .MuiInputBase-root {
  background: settings.$rkt-autocomplete-react-background;

  .MuiAutocomplete-popupIndicator {
    display: settings.$rkt-autocomplete-react-popup-indicator-display;
  }

  .MuiInputLabel-root {
    top: settings.$rkt-autocomplete-react-label-top;
  }
}

// Options
.rkt-Autocomplete + .MuiPopper-root .MuiAutocomplete-paper,
.rkt-Autocomplete + .MuiAutocomplete-popper .MuiAutocomplete-paper {
  margin: settings.$rkt-autocomplete-react-options-margin;
  border-radius: settings.$rkt-autocomplete-border-radius;
  box-shadow: settings.$rkt-autocomplete-pane-box-shadow;
  padding: settings.$rkt-autocomplete-react-options-padding;

  .MuiListSubheader-root {
    padding: settings.$rkt-autocomplete-react-options-subheader-padding;
    font-size: settings.$rkt-autocomplete-react-options-subheader-font-size;
    font-weight: settings.$rkt-autocomplete-react-options-subheader-font-weight;
  }

  .MuiAutocomplete-listbox {
    padding: settings.$rkt-autocomplete-react-options-list-padding;
    max-height: settings.$rkt-autocomplete-react-options-list-max-height;
  }

  .MuiAutocomplete-option {
    min-width: settings.$rkt-autocomplete-react-options-option-min-width;
    padding: settings.$rkt-autocomplete-react-options-option-padding;

    &.Mui-focused {
      background: settings.$rkt-autocomplete-react-options-option-focused-background;
    }

    &.Mui-focusVisible {
      box-shadow: settings.$rkt-autocomplete-option-focus-visible-box-shadow;
      background: settings.$rkt-autocomplete-option-focus-visible-background;

      &::after {
        visibility: settings.$rkt-autocomplete-react-options-option-focus-visible-after-visibility;
      }
    }

    // ::after border
    &::after {
      content: '';
      display: settings.$rkt-autocomplete-option-after-display;
      width: settings.$rkt-autocomplete-option-after-width;
      height: settings.$rkt-autocomplete-option-after-height;
      background-color: settings.$rkt-autocomplete-option-after-background-color;
      position: settings.$rkt-autocomplete-option-after-position;
      bottom: settings.$rkt-autocomplete-option-after-bottom;
      left: settings.$rkt-autocomplete-option-after-left;
      transform: settings.$rkt-autocomplete-option-after-transform;
    }

    // Do not show ::after border
    &[aria-selected='true']::after,
    &:has(+ [aria-selected='true'])::after,
    &:last-of-type::after {
      visibility: settings.$rkt-autocomplete-option-after-last-item;
    }

    .MuiListItemIcon-root {
      color: settings.$rkt-autocomplete-react-options-option-icon-color;
      min-width: settings.$rkt-autocomplete-react-options-option-icon-min-width;
      margin: settings.$rkt-autocomplete-react-options-option-icon-margin;
    }
  }

  // Selected single option
  .MuiAutocomplete-option[aria-selected='true'] {
    background: settings.$rkt-autocomplete-option-single-selected-background;
    color: settings.$rkt-autocomplete-option-single-selected-color;

    .MuiListItemIcon-root {
      color: settings.$rkt-autocomplete-option-single-selected-color;
    }

    .MuiListItemText-primary {
      color: settings.$rkt-autocomplete-option-single-selected-color;
    }
  }

  // Group Labels
  .MuiListItemSecondaryAction-root {
    right: settings.$rkt-autocomplete-react-group-label-right;
  }

  .rkt-List__item .MuiListItemText-root {
    margin: settings.$rkt-autocomplete-react-label-text-margin;
  }

  .rkt-Checkbox.MuiCheckbox-root {
    margin: settings.$rkt-autocomplete-react-checkbox-margin;
    padding: settings.$rkt-autocomplete-react-checkbox-padding;
    visibility: settings.$rkt-autocomplete-react-checkbox-visibility;
  }

  .MuiListItem-root[aria-selected='true'] {
    .rkt-Checkbox.MuiCheckbox-root {
      color: settings.$rkt-autocomplete-react-checkbox-selected-color;
      visibility: settings.$rkt-autocomplete-react-checkbox-selected-visibility;
      width: settings.$rkt-autocomplete-react-checkbox-selected-width;
      height: settings.$rkt-autocomplete-react-checkbox-selected-height;
      path:nth-of-type(2) {
        stroke: settings.$rkt-autocomplete-option-single-selected-color;
      }
    }
  }
}

.rkt-FormField:not(.rkt-FormField--has-textarea):not(
    .rkt-FormField--has-left-icon
  )
  .rkt-Autocomplete--with-chips
  .MuiInputBase-root {
  width: settings.$rkt-autocomplete-with-chips-width;
  height: settings.$rkt-autocomplete-react-input-base-height;
  min-height: settings.$rkt-autocomplete-with-chips-input-min-height;
  padding-bottom: settings.$rkt-autocomplete-react-input-base-padding-bottom;
  top: settings.$rkt-autocomplete-react-input-base-top;
}

.rkt-FormField:not(.rkt-FormField--has-prefix):has(.rkt-Autocomplete)
  .rkt-Input
  .MuiInputBase-input {
  left: settings.$rkt-autocomplete-react-input-left;
  top: settings.$rkt-autocomplete-react-input-top;
}

.rkt-FormField:not(.rkt-FormField--has-textarea):has(.rkt-Autocomplete)
  .mat-mdc-text-field-wrapper
  .mat-mdc-form-field-infix
  .mat-mdc-floating-label {
  left: settings.$rkt-autocomplete-react-label-left;
}

// With Chips
// Label
.rkt-FormField:not(.rkt-FormField--has-textarea):has(
    .rkt-Autocomplete--with-chips
  )
  .MuiFormLabel-root {
  left: settings.$rkt-autocomplete-react-with-chips-input-label-left;
  top: settings.$rkt-autocomplete-react-with-chips-input-label-top;
}

// Positions popper under input
.rkt-Autocomplete--with-chips + .MuiPopper-root .MuiAutocomplete-paper {
  position: settings.$rkt-autocomplete-react-with-chips-paper-position;
  width: settings.$rkt-autocomplete-react-with-chips-paper-width;
  top: settings.$rkt-autocomplete-react-with-chips-paper-top;
}

// Label in the presence of chips
.rkt-FormField
  .MuiFormLabel-root:has(+ .rkt-Autocomplete--with-chips .rkt-Chip) {
  top: settings.$rkt-autocomplete-react-with-chips-input-label-chips-present-top;
}

// Input
.rkt-FormField:not(.rkt-FormField--has-prefix):has(
    .rkt-Autocomplete--with-chips
  )
  .rkt-Input
  .MuiInputBase-input {
  left: settings.$rkt-autocomplete-react-with-chips-input-left;
  top: settings.$rkt-autocomplete-react-with-chips-input-top;
  padding: settings.$rkt-autocomplete-react-with-chips-input-padding;
}

.rkt-Autocomplete--with-chips .rkt-Autocomplete__chip {
  top: settings.$rkt-autocomplete-react-with-chips-chip-top;
  left: settings.$rkt-autocomplete-react-with-chips-chip-left;
}

// Resets placeholder hover style
.MuiAutocomplete-root.rkt-Autocomplete .MuiFormControl-root:hover {
  .MuiAutocomplete-input::placeholder {
    color: settings.$rkt-autocomplete-react-input-placeholder-color;
  }
}

.rkt-Autocomplete--with-chips + .MuiAutocomplete-popper {
  .MuiAutocomplete-option[aria-selected='true'] {
    background: settings.$rkt-autocomplete-react-options-option-selected-background;
    color: settings.$rkt-autocomplete-react-options-option-selected-color;
  }
}

/// With chips
.rkt-Autocomplete--with-chips
  + .MuiPopper-root
  .MuiAutocomplete-paper
  .rkt-Checkbox.MuiCheckbox-root {
  visibility: settings.$rkt-autocomplete-react-with-chips-checkbox-visibility;
}

.rkt-Autocomplete--with-chips
  + .MuiPopper-root
  .MuiAutocomplete-paper
  .MuiListItem-root[aria-selected='true']
  .rkt-Checkbox.MuiCheckbox-root {
  color: settings.$rkt-autocomplete-react-with-chips-checkbox-color;
}

.rkt-Autocomplete--with-chips
  + .MuiPopper-root
  .MuiAutocomplete-paper
  .MuiListItem-root[aria-selected='true']
  .rkt-Checkbox.MuiCheckbox-root
  path:nth-of-type(2) {
  stroke: settings.$rkt-autocomplete-react-with-chips-checkbox-stroke;
}

.rkt-Autocomplete--with-chips
  + .MuiAutocomplete-popper
  .rkt-Checkbox.MuiCheckbox-root {
  visibility: settings.$rkt-autocomplete-react-with-chips-checkbox-visibility;
  height: settings.$rkt-autocomplete-with-chips-checkbox-height;
  width: settings.$rkt-autocomplete-with-chips-checkbox-width;
}

.rkt-Autocomplete--with-chips + .MuiPopper-root .MuiAutocomplete-paper {
  // Selected chips option
  .MuiAutocomplete-option[aria-selected='true'] {
    background: settings.$rkt-autocomplete-react-options-option-selected-background;
    color: settings.$rkt-autocomplete-react-options-option-selected-color;

    .MuiListItemIcon-root {
      color: settings.$rkt-autocomplete-react-options-option-selected-color;
    }

    .MuiListItemText-primary {
      color: settings.$rkt-autocomplete-react-options-option-selected-color;
    }
  }

  .rkt-Autocomplete__option {
    // ::after border
    &::after {
      content: '';
      display: settings.$rkt-autocomplete-option-after-display;
      width: settings.$rkt-autocomplete-option-after-width;
      height: settings.$rkt-autocomplete-option-after-height;
      background-color: settings.$rkt-autocomplete-option-after-background-color;
      position: settings.$rkt-autocomplete-option-after-position;
      bottom: settings.$rkt-autocomplete-option-after-bottom;
      left: settings.$rkt-autocomplete-option-after-left;
      transform: settings.$rkt-autocomplete-option-after-transform;
    }
  }
}

// Make borders visible
.rkt-Autocomplete
  + .MuiPopper-root
  .MuiAutocomplete-paper
  .MuiAutocomplete-option:has(+ [aria-selected='true'])::after,
.rkt-Autocomplete
  + .MuiPopper-root
  .MuiAutocomplete-paper
  .MuiAutocomplete-option[aria-selected='true']::after {
  // visibility: visible;
  visibility: settings.$rkt-autocomplete-react-options-option-selected-after-visibility;
}

// Do not show divider when selected and hovered
.rkt-Autocomplete.rkt-Autocomplete--with-chips
  + .MuiPopper-root
  .MuiAutocomplete-paper
  .MuiAutocomplete-option.Mui-focusVisible:has(+ [aria-selected='true'])::after,
.rkt-Autocomplete.rkt-Autocomplete--with-chips
  + .MuiPopper-root
  .MuiAutocomplete-paper
  .MuiAutocomplete-option.Mui-focusVisible[aria-selected='true']::after {
  visibility: settings.$rkt-autocomplete-react-options-option-selected-hover-after-visibility;
}

.rkt-Autocomplete--with-chips
  + .MuiPopper-root
  .MuiAutocomplete-paper
  .MuiAutocomplete-option.Mui-focused[aria-selected='true'] {
  background: settings.$rkt-autocomplete-react-options-option-focused-background;
}

.rkt-Autocomplete .MuiTextField-root:has(.Mui-error) {
  box-shadow: settings.$rkt-autocomplete-react-error-box-shadow;
}

.rkt-Autocomplete--with-chips + .MuiAutocomplete-popper {
  transform: settings.$rkt-autocomplete-react-popper-transform;
}
