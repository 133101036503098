@use '../web/scss/settings';

.rkt-ButtonToggle,
.rkt-ButtonToggle.mat-button-toggle-group {
  background-color: settings.$rkt-button-toggle-background-color;
  border-radius: settings.$rkt-button-toggle-border-radius;
  border: settings.$rkt-button-toggle-border;
  box-sizing: settings.$rkt-button-toggle-box-sizing;
  padding: settings.$rkt-button-toggle-padding;
  gap: settings.$rkt-button-toggle-gap;
  align-items: settings.$rkt-button-toggle-align-items;
  min-height: settings.$rkt-button-toggle-min-height;
  display: settings.$rkt-button-toggle-display;

  .mat-button-toggle-checked .mat-pseudo-checkbox {
    display: settings.$rkt-button-toggle-button-selected-checkbox-display;
  }
}

// react container requires the gap to be 1px more than angular/figma for it to match
.rkt-ButtonToggle.MuiToggleButtonGroup-root {
  gap: settings.$rkt-button-toggle-button-group-gap;
}

.rkt-ButtonToggle .rkt-ButtonToggle__button .mat-button-toggle-button,
.rkt-ButtonToggle .rkt-ButtonToggle__button.MuiToggleButton-root,
.rkt-ButtonToggle .MuiToggleButtonGroup-grouped:not(:last-of-type) {
  box-sizing: settings.$rkt-button-toggle-button-box-sizing;
  background-color: settings.$rkt-button-toggle-background-color;
  border-radius: settings.$rkt-button-toggle-button-border-radius;
  padding: settings.$rkt-button-toggle-button-padding;
  align-items: settings.$rkt-button-toggle-button-align-items;
  display: settings.$rkt-button-toggle-button-display;
  border: settings.$rkt-button-toggle-button-border;

  &:hover {
    background-color: settings.$rkt-button-toggle-button-hover-background-color;
  }

  &:focus-visible {
    background-color: settings.$rkt-button-toggle-button-keyboard-focus-background-color;
    box-shadow: settings.$rkt-button-toggle-button-keyboard-focus-box-shadow;
  }
}

.rkt-ButtonToggle .rkt-ButtonToggle__button .mat-button-toggle-button {
  padding: settings.$rkt-button-toggle-button-padding;
}

.rkt-ButtonToggle .rkt-ButtonToggle__button.MuiToggleButton-root,
.rkt-ButtonToggle .MuiToggleButtonGroup-grouped:not(:last-of-type) {
  padding: settings.$rkt-button-toggle-button-root-padding;
}

.rkt-ButtonToggle .rkt-ButtonToggle__button .mat-button-toggle-button,
.rkt-ButtonToggle .rkt-ButtonToggle__button.MuiToggleButton-root,
.rkt-ButtonToggle .MuiToggleButtonGroup-grouped:not(:last-of-type),
.rkt-ButtonToggle__button-text {
  font-size: settings.$rkt-button-toggle-font-size;
  font-weight: settings.$rkt-button-toggle-font-weight;
  font-family: settings.$rkt-button-toggle-font-family;
  color: settings.$rkt-button-toggle-color;
}

// angular resets
.rkt-ButtonToggle.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle,
.rkt-ButtonToggle.mat-button-toggle-group-appearance-standard .mat-button-toggle,
.rkt-ButtonToggle.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle:hover,
.rkt-ButtonToggle.mat-button-toggle-group-appearance-standard .mat-button-toggle:hover,
.rkt-ButtonToggle.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle:focus,
.rkt-ButtonToggle.mat-button-toggle-group-appearance-standard .mat-button-toggle:focus,
.rkt-ButtonToggle.mat-button-toggle-group-appearance-standard .mat-button-toggle-appearance-standard.cdk-keyboard-focused:not(.mat-button-toggle-disabled) {
  border-left: settings.$rkt-button-toggle-button-border-left;
  background-color: settings.$rkt-button-toggle-button-background-color;
  color: settings.$rkt-button-toggle-button-color;

  & .mat-button-toggle-focus-overlay {
    opacity: settings.$rkt-button-toggle-button-overlay-opacity;
  }
}

// angular resets
.rkt-ButtonToggle__button.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  line-height: settings.$rkt-button-toggle-button-content-line-height;
  padding: settings.$rkt-button-toggle-button-content-padding;
}

// angular resets
.rkt-ButtonToggle .rkt-ButtonToggle__button .mat-button-toggle-button {
  line-height: settings.$rkt-button-toggle-button-line-height;
}

.rkt-ButtonToggle__button-text {
  line-height: settings.$rkt-button-toggle-text-line-height;
  vertical-align: settings.$rkt-button-toggle-text-vertical-align;
}

// selected button toggle button
.rkt-ButtonToggle .mat-button-toggle-checked .mat-button-toggle-button,
.rkt-ButtonToggle .mat-button-toggle-appearance-standard.mat-button-toggle-checked .mat-button-toggle-button,
.rkt-ButtonToggle .MuiButtonBase-root.MuiToggleButton-root.Mui-selected.rkt-ButtonToggle__button {
  background-color: settings.$rkt-button-toggle-button-selected-background-color;
  box-shadow: settings.$rkt-button-toggle-button-selected-box-shadow;
  border: settings.$rkt-button-toggle-button-selected-border;
  color: settings.$rkt-button-toggle-button-selected-color;

  .rkt-ButtonToggle__button-text {
    color: settings.$rkt-button-toggle-button-selected-color;
  }

  &:hover {
    background-color: settings.$rkt-button-toggle-button-selected-hover-background-color;
    border-color: settings.$rkt-button-toggle-button-selected-hover-border-color;
    box-shadow: settings.$rkt-button-toggle-button-selected-hover-box-shadow;
  }

  &:focus-visible {
    box-shadow: settings.$rkt-button-toggle-button-selected-hover-keyboard-focus-box-shadow;
  }
}

// On dark styles
// Main on dark border
.rkt-ButtonToggle.rkt-ButtonToggle--on-dark {
  border-color: settings.$rkt-button-toggle-on-dark-border-color;
}

// Background color of the on dark buttons
.rkt-ButtonToggle--on-dark .rkt-ButtonToggle__button {
  background-color: settings.$rkt-button-toggle-on-dark-background-color;
}

// Hover/Focus Background color of the on dark buttons
.rkt-ButtonToggle--on-dark .rkt-ButtonToggle__button:hover,
.rkt-ButtonToggle--on-dark .rkt-ButtonToggle__button:focus,
.rkt-ButtonToggle--on-dark .rkt-ButtonToggle__button.cdk-focused {
  background-color: settings.$rkt-button-toggle-on-dark-hover-background-color;
}

// Color of text and icon for on dark buttons
.rkt-ButtonToggle--on-dark .rkt-ButtonToggle__button,
.rkt-ButtonToggle--on-dark .rkt-ButtonToggle__button-text,
.rkt-ButtonToggle--on-dark .mat-button-toggle-label-content {
  color: settings.$rkt-button-toggle-on-dark-color;
}

// For a selected button toggle button
.rkt-ButtonToggle--on-dark .rkt-ButtonToggle__button.MuiButtonBase-root.MuiToggleButton-root.Mui-selected,
.rkt-ButtonToggle--on-dark .rkt-ButtonToggle__button.mat-button-toggle-checked {
  background-color: settings.$rkt-button-toggle-on-dark-button-selected-background-color;
}

// For a selected button toggle button text and icon color
.rkt-ButtonToggle--on-dark .rkt-ButtonToggle__button.mat-button-toggle-checked .mat-button-toggle-label-content,
.rkt-ButtonToggle--on-dark,
.rkt-ButtonToggle__button.MuiButtonBase-root.MuiToggleButton-root.Mui-selected .rkt-ButtonToggle__button-text {
  color: settings.$rkt-button-toggle-color;
}