@use '../web/scss/settings';

.rkt-Table,
// mat15 added a border: 0 so we have to override
.rkt-Table.mdc-data-table__table {
  width: settings.$rkt-table-width;
  border-collapse: settings.$rkt-table-border-collapse;
}

.rkt-Table .rkt-Table__header.MuiTableCell-head,
.rkt-Table__header.mat-mdc-header-cell,
.mat-mdc-table .rkt-Table__header,
.mat-mdc-table .rkt-Table__header.mat-mdc-header-cell {
  color: settings.$rkt-table-header-color;
  font-weight: settings.$rkt-table-header-font-weight;
  font-size: settings.$rkt-table-header-font-size;
  line-height: settings.$rkt-table-header-line-height;
  background-color: settings.$rkt-table-header-background-color;
  height: settings.$rkt-table-header-height;
  border-bottom: settings.$rkt-table-header-border-bottom;
  padding: settings.$rkt-table-header-padding;
}

// need to add the angular class here when done
.rkt-Table .rkt-Table__header.MuiTableCell-head,
.rkt-Table .MuiTableCell-root.rkt-Table__cell,
.rkt-Table .rkt-Table__header.mat-mdc-header-cell,
.rkt-Table .rkt-Table__cell.mat-mdc-cell.mdc-data-table__cell {
  &:first-child {
    padding-left: settings.$rkt-table-cell-first-child-padding-left;
  }
}

.mat-mdc-table .rkt-Table__header-row.mdc-data-table__header-row {
  height: settings.$rkt-table-header-row-height;
}

.rkt-Table__header-row {
  th:first-child {
    border-top-left-radius: settings.$rkt-table-header-row-first-child-border-top-left-radius;
    border-bottom-left-radius: settings.$rkt-table-header-row-first-child-border-bottom-left-radius;
    background-color: settings.$rkt-table-header-row-first-child-background-color;
  }

  th:last-child {
    border-top-right-radius: settings.$rkt-table-header-row-last-child-border-top-right-radius;
    border-bottom-right-radius: settings.$rkt-table-header-row-last-child-border-bottom-right-radius;
  }
}

.rkt-Table__cell {
  color: settings.$rkt-table-cell-color;
  font-family: settings.$rkt-table-cell-font-family;
  font-weight: settings.$rkt-table-cell-font-weight;
  font-size: settings.$rkt-table-cell-font-size;
  line-height: settings.$rkt-table-cell-line-height;
}

.rkt-Table__cell--last {
  display: settings.$rkt-table-cell-last-display;
  justify-content: settings.$rkt-table-cell-last-justify-content;
  align-items: settings.$rkt-table-cell-last-align-items;
  height: settings.$rkt-table-cell-last-height;
}

.rkt-Table__cell--footer {
  font-weight: settings.$rkt-table-cell-footer-font-weight;
}

.rkt-Table__header-tooltip {
  display: settings.$rkt-table-header-tooltip-display;
  align-items: settings.$rkt-table-header-tooltip-align-items;
}

.rkt-Table__header-tooltip .rkt-TooltipPopper,
.rkt-Table__header .rkt-TooltipPopper .MuiSvgIcon-root {
  height: settings.$rkt-table-header-tooltip-height;
  width: settings.$rkt-table-header-tooltip-width;
}

.rkt-Table .MuiTableCell-root:not(.MuiTableCell-head) {
  padding: settings.$rkt-table-cell-padding;
}

.rkt-Table .rkt-Table__cell.mat-mdc-cell.mdc-data-table__cell.mat-column-select,
.rkt-Table .rkt-Table__header.mat-mdc-header-cell.mdc-data-table__header-cell.mat-column-select,
.rkt-Table .MuiTableCell-root.MuiTableCell-paddingCheckbox,
.rkt-Table .rkt-Table__cell.MuiTableCell-root.MuiTableCell-paddingCheckbox {
  padding: settings.$rkt-table-cell-padding-checkbox;
  border-bottom: settings.$rkt-table-cell-select-border-bottom;
  width: settings.$rkt-table-cell-select-width;
}

.rkt-Table .MuiTableRow-root.MuiTableRow-hover:hover,
.rkt-Table .MuiTableRow-root.Mui-selected:hover,
.rkt-Table .MuiTableRow-root.Mui-selected {
  background-color: settings.$rkt-table-row-background-color-hover;
}

.rkt-Table .MuiTableRow-root.rkt-BackgroundColor--gray-50.Mui-selected:hover,
.rkt-Table .MuiTableRow-root.rkt-BackgroundColor--gray-50.MuiTableRow-hover:hover,
.rkt-Table .MuiTableRow-root.rkt-BackgroundColor--gray-50.Mui-selected  {
  background-color: settings.$rkt-table-row-background-color-hover-gray;
}

// No height token because this is needed for content to disappear/appear
.rkt-Table__row--expandable-content.mdc-data-table__row {
  height: 0;
}

.rkt-Table.mat-mdc-table .rkt-Table__row.mdc-data-table__row {
  height: settings.$rkt-table-row-height;
}

// hover for expandable rows
.mdc-data-table__content
.mat-mdc-row.mdc-data-table__row.rkt-Table__row--expandable:hover,
.rkt-Table__row--expandable:hover {
  cursor: settings.$rkt-table-row-expandable-hover-cursor; 

  td:first-child {
    border-top-left-radius: settings.$rkt-table-row-expandable-hover-first-child-border-top-left-radius;
    border-bottom-left-radius: settings.$rkt-table-row-expandable-hover-first-child-border-bottom-left-radius;
    background-color: settings.$rkt-table-row-expandable-hover-first-child-background-color;
  }

  td:last-child {
    border-top-right-radius: settings.$rkt-table-row-expandable-hover-last-child-border-top-right-radius;
    border-bottom-right-radius: settings.$rkt-table-row-expandable-hover-last-child-border-bottom-right-radius;
    background-color: settings.$rkt-table-row-expandable-hover-last-child-background-color;
  }

  .rkt-Table__cell {
    background-color: settings.$rkt-table-row-expandable-hover-background-color;
  }
}

.rkt-Table__row--expandable .rkt-Table__cell.mat-mdc-cell,
.rkt-Table__row--is-expanded .rkt-Table__cell,
.rkt-Table .rkt-Table__cell--is-collapsible.MuiTableCell-root:not(.MuiTableCell-head),
.rkt-Table .rkt-Table__cell.rkt-Table__cell--is-collapsible.mat-mdc-cell.mdc-data-table__cell {
  border-bottom-width: settings.$rkt-table-row-expandable-border-bottom-width;
  padding: settings.$rkt-table-row-expandable-padding;
}

// React tables are currently height 49px for table heads and 45px for table rows.
// This makes the expandable variant padding less to keep the same exact 45px height as Default tables
.rkt-Table__row--expandable .rkt-Table__cell.MuiTableCell-root {
  height: settings.$rkt-table-row-expandable-height;
  box-sizing: settings.$rkt-table-row-expandable-box-sizing;
}

.rkt-Table .rkt-Table__cell--is-collapsible.MuiTableCell-root:not(.MuiTableCell-head) {
  padding: settings.$rkt-table-cell-collapsible-padding;
}

// Ensures Tables use the RDS border color
.rkt-Table .MuiTableCell-root.rkt-Table__cell,
.rkt-Table .rkt-Table__cell,
.rkt-Table .rkt-Table__cell.mat-mdc-cell.mdc-data-table__cell {
  padding: settings.$rkt-table-cell-padding;
  border-bottom: settings.$rkt-table-cell-border-bottom;
  box-sizing: settings.$rkt-table-cell-box-sizing;
}

.mdc-data-table__row:last-child .mdc-data-table__cell.rkt-Table__cell--expanded,
.rkt-Table__cell.MuiTableCell-root
.rkt-Table__collapsible-content {
  border-bottom: settings.$rkt-table-collapsible-content-border-bottom;
}

// No height token because this is needed for content to disappear/appear
.rkt-Table__collapsible-row.mdc-data-table__row {
  height: 0;
}

.rkt-Table__collapsible-content,
.rkt-Table__cell.MuiTableCell-root .rkt-Table__collapsible-content {
  padding: settings.$rkt-table-collapsible-content-padding;
  border-bottom: settings.$rkt-table-collapsible-content-border-bottom;
}

// whole container
.rkt-TableContainer {
  max-width: settings.$rkt-table-container-max-width;
  padding: settings.$rkt-table-container-padding;
  display: settings.$rkt-table-container-display;
  box-sizing: settings.$rkt-table-container-box-sizing;
  width: settings.$rkt-table-container-width;
  overflow: settings.$rkt-table-container-overflow;
}

// border
.rkt-TableContainer--has-border {
  border: settings.$rkt-table-container-border;
  border-radius: settings.$rkt-table-container-border-radius;
}

.rkt-Table__title {
  padding: settings.$rkt-table-title-padding;
  margin-bottom: settings.$rkt-table-title-margin-bottom;
  font-weight: settings.$rkt-table-title-font-weight;
  font-size: settings.$rkt-table-title-font-size;
  line-height: settings.$rkt-table-title-line-height;
}

.rkt-Table__pagination {
  display: settings.$rkt-table-pagination-display;
  justify-content: settings.$rkt-table-pagination-justify-content;
  margin-top: settings.$rkt-table-pagination-margin-top;
  height: settings.$rkt-table-pagination-height;

  .mat-mdc-paginator-container {
    height: settings.$rkt-table-pagination-height;
    justify-content: settings.$rkt-table-pagination-justify-content;
  }
}

.rkt-Table__row {
  height: settings.$rkt-table-row-height;

  &:focus-visible {
    outline: settings.$rkt-table-row-focus-visible-outline;
    border-radius: settings.$rkt-table-row-focus-visible-border-radius;
    background: settings.$rkt-table-row-focus-visible-background;

    .rkt-Table__expand-icon {
      visibility: settings.$rkt-table-row-focus-visible-expand-icon-visibility;
    }
  }
}

.rkt-Table__expand-icon {
  visibility: settings.$rkt-table-row-expand-icon-visibility;
}

.rkt-Table__row:hover .rkt-Table__expand-icon,
.rkt-Table__row--is-expanded .rkt-Table__expand-icon {
  visibility: settings.$rkt-table-row-hover-expand-icon-visibility;
}

.rkt-Table__checkbox.rkt-Checkbox.MuiCheckbox-root {
  margin-left: settings.$rkt-table-checkbox-margin-left;
  height: settings.$rkt-table-checkbox-height;
  width: settings.$rkt-table-checkbox-width;
}

.rkt-Table__checkbox.mat-mdc-checkbox .mdc-form-field {
  width: settings.$rkt-table-checkbox-container-width;
}

.rkt-Table__checkbox .mdc-form-field .mdc-checkbox {
  margin: settings.$rkt-table-checkbox-margin;
}

.rkt-Table__row .rkt-Table__cell.mat-mdc-footer-cell.mdc-data-table__cell.rkt-Table__cell--footer,
.rkt-Table__row .rkt-Table__cell.MuiTableCell-root.MuiTableCell-body.rkt-Table__cell--footer {  
  border-top: settings.$rkt-table-cell-footer-border-top;
  border-bottom: settings.$rkt-table-cell-footer-border-bottom;
}

.rkt-Table__header .mat-sort-header-arrow {
  margin: settings.$rkt-table-header-sort-arrow-margin;
}

.rkt-Table__header.mat-sort-header.mat-mdc-header-cell.cdk-keyboard-focused .mat-sort-header-container .mat-sort-header-arrow,  
.rkt-Table__header.MuiTableSortLabel-root:focus-visible .MuiTableSortLabel-icon {
  outline: settings.$rkt-table-header-focus-visible-sort-arrow-outline;
  border-radius: settings.$rkt-table-header-focus-visible-sort-arrow-border-radius;
}

// The 'important' tags are needed here because Angular is adding these styles 
// and a higher specificity can't be achieved.
.rkt-Table__header.mat-sort-header.mat-mdc-header-cell.cdk-keyboard-focused .mat-sort-header-container .mat-sort-header-arrow {
  opacity: unset !important;
  transform: unset !important;
  outline-offset: settings.$rkt-table-header-focus-visible-sort-arrow-outline-offset;
}

.rkt-Table__header.MuiTableSortLabel-root .MuiTableSortLabel-icon {
  margin-left: settings.$rkt-table-header-sort-label-icon-margin-left;
  margin-right: settings.$rkt-table-header-sort-label-icon-margin-right;
}

.rkt-Table__header.mat-sort-header.mat-mdc-header-cell.cdk-keyboard-focused .mat-sort-header-container {
  border-bottom: settings.$rkt-table-header-focus-visible-border-bottom;
}

.rkt-Table__header .Mui-focusVisible.MuiIconButton-root.rkt-TooltipPopper .rkt-Icon,
.rkt-Table__header-tooltip .rkt-TooltipPopper:focus-visible.mat-mdc-tooltip-trigger.mat-icon svg {
  outline: settings.$rkt-table-header-tooltip-popper-focus-visible-icon-outline;
  outline-offset: settings.$rkt-table-header-tooltip-popper-focus-visible-icon-outline-offset;
  border-radius: settings.$rkt-table-header-tooltip-popper-focus-visible-icon-border-radius;
}

.rkt-Table__header .rkt-TooltipPopper:hover {
  background: settings.$rkt-table-header-tooltip-popper-hover-background;
}

.rkt-Table__header .rkt-TooltipPopper:focus-visible {
  background: settings.$rkt-table-header-tooltip-popper-focus-visible-background;
  outline: settings.$rkt-table-header-tooltip-popper-focus-visible-outline;
}

.rkt-Table__collapsible-content.rkt-Table__collapsible-content--with-select {
  margin-left: settings.$rkt-table-collapsible-content-with-select-margin-left;
}

.rkt-Table__header.Mui-focusVisible {
  .MuiTableSortLabel-icon {
    opacity: settings.$rkt-table-header-focus-visible-sort-label-icon-opacity;
  }
}

.rkt-Table__header-row .rkt-Table__header .mat-sort-header-container .mat-sort-header-content:focus-within + .mat-sort-header-arrow {
  outline: settings.$rkt-table-header-row-focus-visible-sort-arrow-outline;
  visibility: settings.$rkt-table-header-row-focus-visible-sort-arrow-visibility;
}