@use '../web/scss/settings';

// We add .MuiTabs-root to scope these changes to React only
.rkt-Tabs.MuiTabs-root {
  min-height: settings.$rkt-tabs-min-height;
}

// Override MUI behavior that hides scroll buttons in responsive view
.rkt-Tabs .MuiTabScrollButton-root,
.rkt-Tabs .mat-mdc-tab-header-pagination {
  opacity: settings.$rkt-tabs-scroll-button-opacity;
  pointer-events: settings.$rkt-tabs-scroll-button-pointer-events;
  cursor: settings.$rkt-tabs-scroll-button-cursor;
  padding: settings.$rkt-tabs-scroll-button-padding;
  min-width: settings.$rkt-tabs-scroll-button-width;
  width: settings.$rkt-tabs-scroll-button-width;
}

.rkt-Tabs.MuiTabs-root .MuiTabs-flexContainer,
.rkt-Tabs.MuiTabs-root .MuiTabs-fixed,
.rkt-Tabs .mat-mdc-tab-list { 
  height: settings.$rkt-tabs-min-height;
  margin-top: settings.$rkt-tabs-margin-top;
  margin-bottom: settings.$rkt-tabs-margin-bottom;
}

.rkt-Tabs__tab--with-icon .MuiTab-iconWrapper {
  margin-right: settings.$rkt-tabs-icon-margin-right;
}

// Deprecated selector in v4, removal in v6 - task #3532294
.rkt-Tabs .mat-tab-label,
.rkt-Tabs .rkt-Tabs__label,
.rkt-Tabs .mat-mdc-tab .mdc-tab__text-label {
  color: settings.$rkt-tabs-label-color;
  opacity: unset;
  font-weight: settings.$rkt-tabs-font-weight;
  font-size: settings.$rkt-tabs-font-size;
}

// Deprecated selector in v4, removal in v6 - task #3532294
.rkt-Tabs .mat-tab-label-active,
.rkt-Tabs .rkt-Tabs__tab.Mui-selected .rkt-Tabs__label,
.rkt-Tabs .mat-mdc-tab.mdc-tab--active .mdc-tab__text-label {
  color: settings.$rkt-tabs-label-color-active;
  opacity: unset;
  font-weight: settings.$rkt-tabs-font-weight-active;
  font-size: settings.$rkt-tabs-font-size-active;
}

// Deprecated selector in v4, removal in v6 - task #3532294
.rkt-Tabs .mat-tab-header,
.rkt-Tabs .mat-mdc-tab-header {
  border-bottom: settings.$rkt-tabs-border-bottom;
}

// Widths - Default, 2 tabs on mobile, > 2 tabs on mobile, > 3 tabs on mobile without icons
.rkt-Tabs__tab,
.rkt-Tabs__tab.mdc-tab {
  min-width: settings.$rkt-tabs-wide-min-width;
  flex: settings.$rkt-tabs-flex;
}

@media only screen and (max-width: settings.$rkt-tabs-narrow-breakpoint) {
  .rkt-Tabs .MuiTabs-flexContainer:has(> :nth-child(2)) .rkt-Tabs__tab,
  .rkt-Tabs .mat-mdc-tab-labels:has(> :nth-child(2)) .rkt-Tabs__tab.mdc-tab {
    min-width: settings.$rkt-tabs-narrow-two-tabs-min-width;
  }

  .rkt-Tabs .MuiTabs-flexContainer:has(> :nth-child(3)) .rkt-Tabs__tab,
  .rkt-Tabs .mat-mdc-tab-labels:has(> :nth-child(3)) .rkt-Tabs__tab.mdc-tab {
    min-width: settings.$rkt-tabs-narrow-min-width;
  }

  .rkt-Tabs .MuiTabs-flexContainer:has(> :nth-child(4)) .rkt-Tabs__tab:not(.rkt-Tabs__tab--with-icon),
  .rkt-Tabs .mat-mdc-tab-labels:has(> :nth-child(4)) .rkt-Tabs__tab.mdc-tab:not(.rkt-Tabs__tab--with-icon) {
    min-width: settings.$rkt-tabs-narrow-many-tabs-without-icon-min-width;
  }
}

.rkt-Tabs .mdc-tab {
  padding-left: settings.$rkt-tabs-button-horizontal-padding;
  padding-right: settings.$rkt-tabs-button-horizontal-padding;
}

// Tabs focus state
.rkt-Tabs .rkt-Tabs__tab:focus-visible {
  border-radius: settings.$rkt-tabs-focus-outline-border-radius;
  outline-offset: settings.$rkt-tabs-focus-outline-offset;
}

.rkt-Tabs:not(.rkt-Tabs--on-dark) .rkt-Tabs__tab:focus-visible {
  outline: settings.$rkt-tabs-focus-outline;
}

// Tabs hover state
.rkt-Tabs .rkt-Tabs__tab:hover {
  border-radius: settings.$rkt-tabs-hover-border-radius;
}

.rkt-Tabs:not(.rkt-Tabs--on-dark) .rkt-Tabs__tab:hover {
  background-color: settings.$rkt-tabs-hover-background-color;
}

// Tabs buttons
.rkt-Tabs .MuiButtonBase-root {
  min-height: settings.$rkt-tabs-button-height;
}

.rkt-Tabs .MuiButtonBase-root:not(.MuiTabScrollButton-root) {
  padding-left: settings.$rkt-tabs-button-horizontal-padding;
  padding-right: settings.$rkt-tabs-button-horizontal-padding;
}

.rkt-Tabs .mat-mdc-tab.mdc-tab {
  height: settings.$rkt-tabs-button-height;
}

// Tabs underline
.rkt-Tabs .mdc-tab-indicator__content--underline {
  transition: settings.$rkt-tabs-underline-transition;
}

.rkt-Tabs .MuiTabs-indicator,
.rkt-Tabs .mdc-tab-indicator .mdc-tab-indicator__content--underline {
  background-color: settings.$rkt-tabs-underline-background-color;
  border-radius: settings.$rkt-tabs-underline-border-radius;
  height: settings.$rkt-tabs-underline-height;
  border-top-width: settings.$rkt-tabs-underline-border-top-width;
}

.rkt-Tabs .MuiTabs-indicator {
  position: settings.$rkt-tabs-underline-position;
  top: settings.$rkt-tabs-react-underline-top;
}

.rkt-Tabs .mdc-tab-indicator__content--underline {
  position: settings.$rkt-tabs-underline-position;
  top: settings.$rkt-tabs-angular-underline-top;
}

.rkt-Tabs .MuiTouchRipple-root, .rkt-Tabs .mdc-tab__ripple {
  display: settings.$rkt-tabs-ripple-display;
}

// Override MUI scroll button icons - Angular
.rkt-Tabs .mat-mdc-tab-header-pagination-chevron {
  border: settings.$rkt-tabs-angular-pagination-border;
  width: settings.$rkt-tabs-angular-pagination-background-image-width;
  height: settings.$rkt-tabs-angular-pagination-background-image-height;
}

.rkt-Tabs .mat-mdc-tab-header-pagination-before .mat-mdc-tab-header-pagination-chevron {
  transform: settings.$rkt-tabs-angular-pagination-before-transform;
  background-image: settings.$rkt-tabs-angular-pagination-before-background-image;
}

.rkt-Tabs .mat-mdc-tab-header-pagination-after .mat-mdc-tab-header-pagination-chevron {
  transform: settings.$rkt-tabs-angular-pagination-after-transform;
  background-image: settings.$rkt-tabs-angular-pagination-after-background-image;
}

.rkt-Tabs .mat-mdc-tab-header-pagination-disabled .mat-mdc-tab-header-pagination-chevron {
  opacity: settings.$rkt-tabs-angular-pagination-disabled-opacity;
}

.rkt-Tabs:not(.rkt-Tabs--on-dark) .MuiTabs-indicator {
  background-color: settings.$rkt-tabs-underline-background-color;
}

.rkt-Tabs .mat-mdc-tab:hover .mdc-tab__text-label,
.rkt-Tabs .mat-mdc-tab:focus .mdc-tab__text-label,
.rkt-Tabs .rkt-Tabs__tab:hover .rkt-Tabs__label,
.rkt-Tabs .rkt-Tabs__tab:active .rkt-Tabs__label {
  color: settings.$rkt-tabs-label-color-active;
}

.rkt-Tabs__tab--with-icon:not(.Mui-selected) .MuiSvgIcon-root {
  color: settings.$rkt-tabs-icon-color;
}

.rkt-Tabs__tab--with-icon.Mui-selected .MuiSvgIcon-root,
.rkt-Tabs__tab--with-icon:hover .MuiSvgIcon-root,
.rkt-Tabs__tab--with-icon:active .MuiSvgIcon-root {
  color: settings.$rkt-tabs-icon-color-active;
}

// On Dark Tab styles start

.rkt-Tabs--on-dark .mat-mdc-tab .mdc-tab__text-label,
.rkt-Tabs--on-dark .rkt-Tabs__label {
  color: settings.$rkt-tabs-on-dark-label-color;
}

.rkt-Tabs--on-dark .mat-mdc-tab:hover .mdc-tab__text-label,
.rkt-Tabs--on-dark .mat-mdc-tab:focus .mdc-tab__text-label,
.rkt-Tabs--on-dark .mat-mdc-tab.mdc-tab--active .mdc-tab__text-label,
.rkt-Tabs--on-dark .mat-mdc-tab.mdc-tab--active:focus .mdc-tab__text-label,
.rkt-Tabs--on-dark .mat-mdc-tab.mdc-tab--active:hover .mdc-tab__text-label,
.rkt-Tabs--on-dark .rkt-Tabs__tab.Mui-selected .rkt-Tabs__label,
.rkt-Tabs--on-dark .rkt-Tabs__tab:hover .rkt-Tabs__label,
.rkt-Tabs--on-dark .rkt-Tabs__tab:active .rkt-Tabs__label {
  color: settings.$rkt-tabs-on-dark-label-color-active;
}

.rkt-Tabs--on-dark .mat-mdc-tab:not(.mat-mdc-tab-disabled) .mdc-tab-indicator__content--underline,
.rkt-Tabs--on-dark .mat-mdc-tab.mdc-tab--active:focus .mdc-tab-indicator__content--underline,
.rkt-Tabs--on-dark .mat-mdc-tab.mdc-tab--active:hover .mdc-tab-indicator__content--underline {
  border-color: settings.$rkt-tabs-on-dark-underline;
}

.rkt-Tabs--on-dark .MuiTabs-indicator {
  background-color: settings.$rkt-tabs-on-dark-underline;
}

.rkt-Tabs--on-dark .mat-mdc-tab-header-pagination-chevron {
  border-color: settings.$rkt-tabs-on-dark-icon-color;
}

.rkt-Tabs--on-dark .rkt-Tabs__tab--with-icon .MuiSvgIcon-root {
  color: settings.$rkt-tabs-on-dark-icon-color;
}

.rkt-Tabs--on-dark .MuiTabs-scrollButtons .MuiSvgIcon-root,
.rkt-Tabs--on-dark .rkt-Tabs__tab--with-icon.Mui-selected .MuiSvgIcon-root,
.rkt-Tabs--on-dark .rkt-Tabs__tab--with-icon:hover .MuiSvgIcon-root,
.rkt-Tabs--on-dark .rkt-Tabs__tab--with-icon:active .MuiSvgIcon-root {
  color: settings.$rkt-tabs-on-dark-icon-active-color;
}

.rkt-Tabs--on-dark .mat-mdc-tab-header-pagination-disabled .mat-mdc-tab-header-pagination-chevron {
  border-color: settings.$rkt-tabs-on-dark-disabled-icon-color;
}

.rkt-Tabs--on-dark .mat-ripple-element,
.rkt-Tabs--on-dark .MuiTouchRipple-root {
  color: settings.$rkt-tabs-on-dark-ripple;
}

.rkt-Tabs--on-dark .mat-mdc-tab.mdc-tab--active .mdc-tab__ripple::before,
.rkt-Tabs--on-dark .mat-mdc-tab-header-pagination .mat-ripple-element,
.rkt-Tabs--on-dark .mat-mdc-tab.cdk-focused .mat-ripple-element,
.rkt-Tabs--on-dark .mat-mdc-tab.cdk-focused .mdc-tab__ripple::before {
  background-color: settings.$rkt-tabs-on-dark-ripple;
}