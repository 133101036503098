// ==================================================================
// Color
// ==================================================================
@use '../web/scss/settings' as *;
@use 'sass:map';

// Define The Names of the Color Categories
$rkt-color-categories: map.keys($rkt-color-map);

// For Each Color Category Get the key value pairs for color number and hex value
@each $rkt-color-category in $rkt-color-categories {
  @if $rkt-color-category == 'white' {
    $rkt-plain-colors: map-get($rkt-color-map, $rkt-color-category);
    // get key and value info for each pair
    @each $rkt-color-value in $rkt-plain-colors {
      //   Set Utility Classes based on Colors in Map File
      .rkt-Color--#{$rkt-color-category} {
        color: $rkt-color-value;
      }
      .rkt-BackgroundColor--#{$rkt-color-category} {
        background-color: $rkt-color-value;
      }
      .rkt-BorderColor--#{$rkt-color-category} {
        border-color: $rkt-color-value;
      }
    }
  } @else if $rkt-color-category == 'black' {
    $rkt-plain-colors: map-get($rkt-color-map, $rkt-color-category);
    // get key and value info for each pair
    @each $rkt-color-value in $rkt-plain-colors {
      //   Set Utility Classes based on Colors in Map File
      .rkt-Color--#{$rkt-color-category} {
        color: $rkt-color-value;
      }
      .rkt-BackgroundColor--#{$rkt-color-category} {
        background-color: $rkt-color-value;
      }
      .rkt-BorderColor--#{$rkt-color-category} {
        border-color: $rkt-color-value;
      }
    }
  } @else {
    $rkt-colors: map-get($rkt-color-map, $rkt-color-category);
    // get key and value info for each pair
    @each $rkt-color-number, $rkt-color-value in $rkt-colors {
      //   Set Utility Classes based on Colors in Map File
      .rkt-Color--#{$rkt-color-category}-#{$rkt-color-number} {
        color: $rkt-color-value;
      }
      .rkt-BackgroundColor--#{$rkt-color-category}-#{$rkt-color-number} {
        background-color: $rkt-color-value;
      }
      .rkt-BorderColor--#{$rkt-color-category}-#{$rkt-color-number} {
        border-color: $rkt-color-value;
      }
    }
  }
}