@use '../web/scss/settings' as *;

.rkt-Toggle__trigger,
.rkt-Toggle__trigger > .rkt-Toggle__summary {
  align-items: $rkt-toggle-trigger-align-items;
  box-sizing: $rkt-toggle-trigger-box-sizing;
  display: $rkt-toggle-trigger-display;
  flex-direction: $rkt-toggle-trigger-flex-direction;
  color: $rkt-toggle-trigger-color;
}

.rkt-Toggle__trigger {
  &:hover,
  &:active {
    color: $rkt-toggle-trigger-hover-color;
    background-color: $rkt-toggle-trigger-hover-background-color;

    .rkt-Toggle__summary {
      color: $rkt-toggle-trigger-hover-color;
      background-color: $rkt-toggle-trigger-hover-background-color;
    }
  }
}


.rkt-Toggle__trigger {
  transition: $rkt-toggle-trigger-transition;
  gap: $rkt-toggle-trigger-gap;

  // Button User Agent Resets
  background-color: $rkt-toggle-trigger-background-color;
  border-top: $rkt-toggle-trigger-border-top;
  border-bottom: $rkt-toggle-trigger-border-bottom;
  border-left: $rkt-toggle-trigger-border-left;
  border-right: $rkt-toggle-trigger-border-right;
  cursor: pointer;
  padding-top: $rkt-toggle-trigger-padding-top;
  padding-right: $rkt-toggle-trigger-padding-right;
  padding-bottom: $rkt-toggle-trigger-padding-bottom;
  padding-left: $rkt-toggle-trigger-padding-left;
  text-align: $rkt-toggle-trigger-text-align;

  &:hover .rkt-Toggle__trigger-icon-container::after,
  &:active .rkt-Toggle__trigger-icon-container::after {
    width: $rkt-toggle-trigger-icon-container-hover-width;
    height: $rkt-toggle-trigger-icon-container-hover-height;
  }
}

.rkt-Toggle .rkt-Toggle__trigger {
  &:focus-visible {
    outline: $rkt-toggle-trigger-focus-outline;
    outline-offset: $rkt-toggle-trigger-focus-outline-offset;
    border-radius: $rkt-toggle-trigger-focus-border-radius;
  }
}

.rkt-Toggle .rkt-Toggle__trigger--icon-right {
  padding-left: $rkt-toggle-trigger-icon-right-padding-left;
  padding-right: $rkt-toggle-trigger-icon-right-padding-right;
}

.rkt-Toggle__summary {
  font-family: $rkt-toggle-summary-font-family;
  font-size: $rkt-toggle-summary-font-size;
  font-weight: $rkt-toggle-summary-font-weight;
  line-height: $rkt-toggle-summary-line-height;
}

.rkt-Toggle__content {
  padding-top: $rkt-toggle-content-padding-top;
  padding-bottom: $rkt-toggle-content-padding-bottom;
}

.rkt-Toggle__content-text {
  font-family: $rkt-toggle-content-text-font-family;
  font-size: $rkt-toggle-content-text-font-size;
  font-weight: $rkt-toggle-content-text-font-weight;
  line-height: $rkt-toggle-content-text-line-height;
  color: $rkt-toggle-content-text-color;
  margin: $rkt-toggle-content-text-margin;
}

.rkt-Toggle--on-dark .rkt-Toggle__content-text {
  color: $rkt-toggle-content-text-on-dark-color;
}

.rkt-Toggle__trigger-icon-container {
  align-items: $rkt-toggle-trigger-icon-container-align-items;
  flex: $rkt-toggle-trigger-icon-container-flex;
  width: $rkt-toggle-trigger-icon-container-width;
  height: $rkt-toggle-trigger-icon-container-height;
  display: $rkt-toggle-trigger-icon-container-display;
  justify-content: $rkt-toggle-trigger-icon-container-justify-content;
  position: relative;

  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    height: $rkt-toggle-trigger-icon-container-height;
    width: $rkt-toggle-trigger-icon-container-width;
    background-color: $rkt-toggle-trigger-icon-container-background-color;
    border-radius: $rkt-toggle-trigger-icon-container-border-radius;
  }
}

.rkt-Toggle__trigger-icon,
.rkt-Toggle__trigger-icon.rkt-Icon--toggle,
.rkt-Toggle__trigger-icon.mat-icon {
  fill: $rkt-toggle-trigger-icon-fill;
  box-sizing: $rkt-toggle-trigger-icon-box-sizing;
}

/// Large variant
.rkt-Toggle--large .rkt-Toggle__summary {
  font-size: $rkt-toggle-large-summary-font-size;
  font-style: $rkt-toggle-large-summary-font-style;
  line-height: $rkt-toggle-large-summary-line-height;
}

.rkt-Toggle--large .rkt-Toggle__content-text {
  font-size: $rkt-toggle-large-content-text-font-size;
}

.rkt-Toggle--large .rkt-Toggle__trigger:hover .rkt-Toggle__trigger-icon-container::after {
  width: $rkt-toggle-large-trigger-icon-container-hover-width;
  height: $rkt-toggle-large-trigger-icon-container-hover-height;
}

/// Small variant
.rkt-Toggle--small .rkt-Toggle__trigger-icon-container {
  background-color: unset;
  height: $rkt-toggle-small-trigger-icon-container-height;
  width: $rkt-toggle-small-trigger-icon-container-width;

  &::after {
    display: $rkt-toggle-small-trigger-icon-container-after-display;
  }
}

.rkt-Toggle--small .rkt-Toggle__trigger,
.rkt-Toggle--small .rkt-Toggle__trigger > .rkt-Toggle__summary {
  gap: $rkt-toggle-small-trigger-gap;
}

.rkt-Toggle--small .rkt-Toggle__trigger {
  padding-right: $rkt-toggle-small-trigger-padding-right;
  padding-left: $rkt-toggle-small-trigger-padding-left;
}

.rkt-Toggle--small .rkt-Toggle__trigger--icon-right {
  padding-right: $rkt-toggle-small-trigger-icon-right-padding-right;
  padding-left: $rkt-toggle-small-trigger-icon-right-padding-left;
}

.rkt-Toggle--small .rkt-Toggle__trigger-icon {
  fill: $rkt-toggle-small-trigger-icon-fill;
}

.rkt-Toggle--small .rkt-Toggle__content {
  padding-top: $rkt-toggle-small-content-padding-top;
  padding-bottom: $rkt-toggle-small-content-padding-bottom;
}

.rkt-Toggle--small .rkt-Toggle__content-text {
  font-size: $rkt-toggle-small-content-text-font-size;
}

/// On Dark
.rkt-Toggle .rkt-Toggle__trigger--on-dark,
.rkt-Toggle .rkt-Toggle__trigger--on-dark > .rkt-Toggle__summary,
.rkt-Heading-20.rkt-Toggle__trigger--on-dark {
  color: $rkt-toggle-trigger-on-dark-color;

  &:hover,
  &:active,
  &:hover > .rkt-Toggle__summary,
  &:active > .rkt-Toggle__summary {
    color: $rkt-toggle-trigger-on-dark-hover-color;
  }

  &:focus-visible {
    outline-color: $rkt-toggle-trigger-on-dark-focus-color;
  }
}
