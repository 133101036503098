 @use '../web/scss/skeleton';

@keyframes rkt-Skeleton--pulse {
  0% {
    background-color: skeleton.$rkt-skeleton-background-color;
  }
  50% {
    background-color: skeleton.$rkt-skeleton-background-color-pulse;
  }
  100% {
    background-color: skeleton.$rkt-skeleton-background-color;
  }
}

@keyframes rkt-Skeleton--on-dark-pulse {
  0% {
    background-color: skeleton.$rkt-skeleton-on-dark-background-color;
  }
  50% {
    background-color: skeleton.$rkt-skeleton-on-dark-background-color-pulse;
  }
  100% {
    background-color: skeleton.$rkt-skeleton-on-dark-background-color;
  }
}

.rkt-Skeleton {
  background-color: skeleton.$rkt-skeleton-background-color;
  display: skeleton.$rkt-skeleton-display;
}

.rkt-Skeleton--has-pulse {
  animation: skeleton.$rkt-skeleton-pulse-animation;
}

.rkt-Skeleton--on-dark {
  background-color: skeleton.$rkt-skeleton-on-dark-background-color;
}

.rkt-Skeleton--on-dark.rkt-Skeleton--has-pulse {
  animation: skeleton.$rkt-skeleton-on-dark-pulse-animation;
}

.rkt-Skeleton--rectangular {
  border-radius: skeleton.$rkt-skeleton-rectangular-border-radius;
  height: skeleton.$rkt-skeleton-rectangular-height;
  width: skeleton.$rkt-skeleton-rectangular-width;
}

.rkt-Skeleton--circular {
  border-radius: skeleton.$rkt-skeleton-circular-border-radius;
  height: skeleton.$rkt-skeleton-circular-height;
  width: skeleton.$rkt-skeleton-circular-width;
}

.rkt-Skeleton--text {
  border-radius: skeleton.$rkt-skeleton-text-border-radius;
  height: skeleton.$rkt-skeleton-text-height;
  width: skeleton.$rkt-skeleton-text-width;
}

// Predefine widths for skeleton items
// Users can use these or the percentage utils
.rkt-Skeleton--headline {
  width: skeleton.$rkt-skeleton-text-width;
}

.rkt-Skeleton--overline {
  width: skeleton.$rkt-skeleton-overline-width;
}

.rkt-Skeleton--cta {
  width: skeleton.$rkt-skeleton-cta-width;
}
