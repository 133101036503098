@use '../web/scss/settings' as *;

//
// Display and Visibility
///

.rkt-Opacity--1 {
  opacity: 1;
}

/// Turns off the display of an element so that it has no effect on layout.
.rkt-Display--none {
  display: none;
}

/// The element generates a block element box.
.rkt-Display--block {
  display: block;
}

/// The element generates one or more inline element boxes.
.rkt-Display--inline {
  display: inline;
}

/// The element generates a block element box that will be flowed with
/// surrounding content as if it were a single inline box.
.rkt-Display--inline-block {
  display: inline-block;
}

/// The element box is invisible, but still affects layout as normal.
/// Screen readers will still announce this element.
.rkt-Visibility--hidden {
  visibility: hidden;
}

/// The element is visible.
.rkt-Visibility--visible {
  visibility: visible;
}

// Sets to Flex
.rkt-Display--flex {
  display: flex;
}

// Sets to Flex 1
.rkt-Display--flex-1 {
  flex: 1;
}

.rkt-FlexDirection--column {
  flex-direction: column;
}

// Display @ Breakpoint
.rkt-Display--none\@1 {
  @media (min-width: $rkt-split-breakpoint-1) {
    display: none;
  }
}

.rkt-Display--none\@2 {
  @media (min-width: $rkt-split-breakpoint-2) {
    display: none;
  }
}

.rkt-Display--none\@3 {
  @media (min-width: $rkt-split-breakpoint-3) {
    display: none;
  }
}

.rkt-Display--none\@4 {
  @media (min-width: $rkt-split-breakpoint-4) {
    display: none;
  }
}

.rkt-Display--none\@5 {
  @media (min-width: $rkt-split-breakpoint-5) {
    display: none;
  }
}

.rkt-Display--none\@6 {
  @media (min-width: $rkt-split-breakpoint-6) {
    display: none;
  }
}

.rkt-Display--none\@7 {
  @media (min-width: $rkt-split-breakpoint-7) {
    display: none;
  }
}

.rkt-Display--none\@8 {
  @media (min-width: $rkt-split-breakpoint-8) {
    display: none;
  }
}

.rkt-Display--none\@9 {
  @media (min-width: $rkt-split-breakpoint-9) {
    display: none;
  }
}

.rkt-Display--none\@10 {
  @media (min-width: $rkt-split-breakpoint-10) {
    display: none;
  }
}

.rkt-Display--block\@1 {
  @media (min-width: $rkt-split-breakpoint-1) {
    display: block;
  }
}

.rkt-Display--block\@2 {
  @media (min-width: $rkt-split-breakpoint-2) {
    display: block;
  }
}

.rkt-Display--block\@3 {
  @media (min-width: $rkt-split-breakpoint-3) {
    display: block;
  }
}

.rkt-Display--block\@4 {
  @media (min-width: $rkt-split-breakpoint-4) {
    display: block;
  }
}

.rkt-Display--block\@5 {
  @media (min-width: $rkt-split-breakpoint-5) {
    display: block;
  }
}

.rkt-Display--block\@6 {
  @media (min-width: $rkt-split-breakpoint-6) {
    display: block;
  }
}

.rkt-Display--block\@7 {
  @media (min-width: $rkt-split-breakpoint-7) {
    display: block;
  }
}

.rkt-Display--block\@8 {
  @media (min-width: $rkt-split-breakpoint-8) {
    display: block;
  }
}

.rkt-Display--block\@9 {
  @media (min-width: $rkt-split-breakpoint-9) {
    display: block;
  }
}

.rkt-Display--block\@10 {
  @media (min-width: $rkt-split-breakpoint-10) {
    display: block;
  }
}

.rkt-Display--inline\@1 {
  @media (min-width: $rkt-split-breakpoint-1) {
    display: inline;
  }
}

.rkt-Display--inline\@2 {
  @media (min-width: $rkt-split-breakpoint-2) {
    display: inline;
  }
}

.rkt-Display--inline\@3 {
  @media (min-width: $rkt-split-breakpoint-3) {
    display: inline;
  }
}

.rkt-Display--inline\@4 {
  @media (min-width: $rkt-split-breakpoint-4) {
    display: inline;
  }
}

.rkt-Display--inline\@5 {
  @media (min-width: $rkt-split-breakpoint-5) {
    display: inline;
  }
}

.rkt-Display--inline\@6 {
  @media (min-width: $rkt-split-breakpoint-6) {
    display: inline;
  }
}

.rkt-Display--inline\@7 {
  @media (min-width: $rkt-split-breakpoint-7) {
    display: inline;
  }
}

.rkt-Display--inline\@8 {
  @media (min-width: $rkt-split-breakpoint-8) {
    display: inline;
  }
}

.rkt-Display--inline\@9 {
  @media (min-width: $rkt-split-breakpoint-9) {
    display: inline;
  }
}

.rkt-Display--inline\@10 {
  @media (min-width: $rkt-split-breakpoint-10) {
    display: inline;
  }
}

.rkt-Display--inline-block\@1 {
  @media (min-width: $rkt-split-breakpoint-1) {
    display: inline-block;
  }
}

.rkt-Display--inline-block\@2 {
  @media (min-width: $rkt-split-breakpoint-2) {
    display: inline-block;
  }
}

.rkt-Display--inline-block\@3 {
  @media (min-width: $rkt-split-breakpoint-3) {
    display: inline-block;
  }
}

.rkt-Display--inline-block\@4 {
  @media (min-width: $rkt-split-breakpoint-4) {
    display: inline-block;
  }
}

.rkt-Display--inline-block\@5 {
  @media (min-width: $rkt-split-breakpoint-5) {
    display: inline-block;
  }
}

.rkt-Display--inline-block\@6 {
  @media (min-width: $rkt-split-breakpoint-6) {
    display: inline-block;
  }
}

.rkt-Display--inline-block\@7 {
  @media (min-width: $rkt-split-breakpoint-7) {
    display: inline-block;
  }
}

.rkt-Display--inline-block\@8 {
  @media (min-width: $rkt-split-breakpoint-8) {
    display: inline-block;
  }
}

.rkt-Display--inline-block\@9 {
  @media (min-width: $rkt-split-breakpoint-9) {
    display: inline-block;
  }
}

.rkt-Display--inline-block\@10 {
  @media (min-width: $rkt-split-breakpoint-10) {
    display: inline-block;
  }
}

.rkt-Display--flex\@1 {
  @media (min-width: $rkt-split-breakpoint-1) {
    display: flex;
  }
}

.rkt-Display--flex\@2 {
  @media (min-width: $rkt-split-breakpoint-2) {
    display: flex;
  }
}

.rkt-Display--flex\@3 {
  @media (min-width: $rkt-split-breakpoint-3) {
    display: flex;
  }
}

.rkt-Display--flex\@4 {
  @media (min-width: $rkt-split-breakpoint-4) {
    display: flex;
  }
}

.rkt-Display--flex\@5 {
  @media (min-width: $rkt-split-breakpoint-5) {
    display: flex;
  }
}

.rkt-Display--flex\@6 {
  @media (min-width: $rkt-split-breakpoint-6) {
    display: flex;
  }
}

.rkt-Display--flex\@7 {
  @media (min-width: $rkt-split-breakpoint-7) {
    display: flex;
  }
}

.rkt-Display--flex\@8 {
  @media (min-width: $rkt-split-breakpoint-8) {
    display: flex;
  }
}

.rkt-Display--flex\@9 {
  @media (min-width: $rkt-split-breakpoint-9) {
    display: flex;
  }
}

.rkt-Display--flex\@10 {
  @media (min-width: $rkt-split-breakpoint-10) {
    display: flex;
  }
}

.rkt-Visibility--hidden\@1 {
  @media (min-width: $rkt-split-breakpoint-1) {
    visibility: hidden;
  }
}

.rkt-Visibility--hidden\@2 {
  @media (min-width: $rkt-split-breakpoint-2) {
    visibility: hidden;
  }
}

.rkt-Visibility--hidden\@3 {
  @media (min-width: $rkt-split-breakpoint-3) {
    visibility: hidden;
  }
}

.rkt-Visibility--hidden\@4 {
  @media (min-width: $rkt-split-breakpoint-4) {
    visibility: hidden;
  }
}

.rkt-Visibility--hidden\@5 {
  @media (min-width: $rkt-split-breakpoint-5) {
    visibility: hidden;
  }
}

.rkt-Visibility--hidden\@6 {
  @media (min-width: $rkt-split-breakpoint-6) {
    visibility: hidden;
  }
}

.rkt-Visibility--hidden\@7 {
  @media (min-width: $rkt-split-breakpoint-7) {
    visibility: hidden;
  }
}

.rkt-Visibility--hidden\@8 {
  @media (min-width: $rkt-split-breakpoint-8) {
    visibility: hidden;
  }
}

.rkt-Visibility--hidden\@9 {
  @media (min-width: $rkt-split-breakpoint-9) {
    visibility: hidden;
  }
}

.rkt-Visibility--hidden\@10 {
  @media (min-width: $rkt-split-breakpoint-10) {
    visibility: hidden;
  }
}

.rkt-Visibility--visible\@1 {
  @media (min-width: $rkt-split-breakpoint-1) {
    visibility: visible;
  }
}

.rkt-Visibility--visible\@2 {
  @media (min-width: $rkt-split-breakpoint-2) {
    visibility: visible;
  }
}

.rkt-Visibility--visible\@3 {
  @media (min-width: $rkt-split-breakpoint-3) {
    visibility: visible;
  }
}

.rkt-Visibility--visible\@4 {
  @media (min-width: $rkt-split-breakpoint-4) {
    visibility: visible;
  }
}

.rkt-Visibility--visible\@5 {
  @media (min-width: $rkt-split-breakpoint-5) {
    visibility: visible;
  }
}

.rkt-Visibility--visible\@6 {
  @media (min-width: $rkt-split-breakpoint-6) {
    visibility: visible;
  }
}

.rkt-Visibility--visible\@7 {
  @media (min-width: $rkt-split-breakpoint-7) {
    visibility: visible;
  }
}

.rkt-Visibility--visible\@8 {
  @media (min-width: $rkt-split-breakpoint-8) {
    visibility: visible;
  }
}

.rkt-Visibility--visible\@9 {
  @media (min-width: $rkt-split-breakpoint-9) {
    visibility: visible;
  }
}

.rkt-Visibility--visible\@10 {
  @media (min-width: $rkt-split-breakpoint-10) {
    visibility: visible;
  }
}

//
// Positioning
///

/// The element is positioned according to the normal flow of the
/// document, and then offset relative to itself.
.rkt-Position--relative {
  position: relative;
}

/// The element is removed from the normal document flow, and no
/// space is created for the element in the page layout. It is
/// positioned relative to its closest positioned ancestor.
.rkt-Position--absolute {
  position: absolute;
}

/// The element is removed from the normal document flow, and no
/// space is created for the element in the page layout. It is
/// positioned relative to the initial containing block established
/// by the viewport.
.rkt-Position--fixed {
  position: fixed;
}

/// The element is positioned according to the normal flow of the
/// document.
.rkt-Position--static {
  position: static;
}

/// Resets an element's top offset back to zero.
.rkt-Top--zero {
  top: 0;
}

/// Resets an element's bottom offset back to zero.
.rkt-Bottom--zero {
  bottom: 0;
}

/// Resets an element's left offset back to zero.
.rkt-Left--zero {
  left: 0;
}

/// Resets an element's right offset back to zero.
.rkt-Right--zero {
  right: 0;
}

//
// Height
///

/// Changes the height of the element to 100 percent.
.rkt-Height--100 {
  height: 100%;
}

/// Changes the height of the element to auto.
.rkt-Height--auto {
  height: auto;
}

//
// Width
///

/// Changes the max-width of the element to 100 percent.
.rkt-MaxWidth--100 {
  max-width: 100%;
}

/// Changes the width of the element to `auto`.
.rkt-Width--auto {
  width: auto;
}

//
// Alignment
///

/// Aligns text to the left of the container.
.rkt-TextAlign--left {
  text-align: left;
}

/// Aligns text to the right of the container.
.rkt-TextAlign--right {
  text-align: right;
}

/// Aligns text in the horizontal center of the container.
.rkt-TextAlign--center {
  text-align: center;
}

/// Aligns the contents of a box to the top if it's display
/// property is `inline` or `table-cell`.
.rkt-VerticalAlign--top {
  vertical-align: top;
}

/// Aligns the contents of a box to the vertical middle if
/// it's display property is `inline` or `table-cell`.
.rkt-VerticalAlign--middle {
  vertical-align: middle;
}

/// Aligns the contents of a box to the bottom if it's
/// display property is `inline` or `table-cell`.
.rkt-VerticalAlign--bottom {
  vertical-align: bottom;
}

/// Aligns the contents of a box to the baseline if it's
/// display property is `inline` or `table-cell`.
.rkt-VerticalAlign--baseline {
  vertical-align: baseline;
}

// Positioning Utils @ Breakpoints
.rkt-TextAlign--left\@1 {
  @media (min-width: $rkt-split-breakpoint-1) {
    text-align: left;
  }
}

.rkt-TextAlign--left\@2 {
  @media (min-width: $rkt-split-breakpoint-2) {
    text-align: left;
  }
}

.rkt-TextAlign--left\@3 {
  @media (min-width: $rkt-split-breakpoint-3) {
    text-align: left;
  }
}

.rkt-TextAlign--left\@4 {
  @media (min-width: $rkt-split-breakpoint-4) {
    text-align: left;
  }
}

.rkt-TextAlign--left\@5 {
  @media (min-width: $rkt-split-breakpoint-5) {
    text-align: left;
  }
}

.rkt-TextAlign--left\@6 {
  @media (min-width: $rkt-split-breakpoint-6) {
    text-align: left;
  }
}

.rkt-TextAlign--left\@7 {
  @media (min-width: $rkt-split-breakpoint-7) {
    text-align: left;
  }
}

.rkt-TextAlign--left\@8 {
  @media (min-width: $rkt-split-breakpoint-8) {
    text-align: left;
  }
}

.rkt-TextAlign--left\@9 {
  @media (min-width: $rkt-split-breakpoint-9) {
    text-align: left;
  }
}

.rkt-TextAlign--left\@10 {
  @media (min-width: $rkt-split-breakpoint-10) {
    text-align: left;
  }
}

.rkt-TextAlign--right\@1 {
  @media (min-width: $rkt-split-breakpoint-1) {
    text-align: right;
  }
}

.rkt-TextAlign--right\@2 {
  @media (min-width: $rkt-split-breakpoint-2) {
    text-align: right;
  }
}

.rkt-TextAlign--right\@3 {
  @media (min-width: $rkt-split-breakpoint-3) {
    text-align: right;
  }
}

.rkt-TextAlign--right\@4 {
  @media (min-width: $rkt-split-breakpoint-4) {
    text-align: right;
  }
}

.rkt-TextAlign--right\@5 {
  @media (min-width: $rkt-split-breakpoint-5) {
    text-align: right;
  }
}

.rkt-TextAlign--right\@6 {
  @media (min-width: $rkt-split-breakpoint-6) {
    text-align: right;
  }
}

.rkt-TextAlign--right\@7 {
  @media (min-width: $rkt-split-breakpoint-7) {
    text-align: right;
  }
}

.rkt-TextAlign--right\@8 {
  @media (min-width: $rkt-split-breakpoint-8) {
    text-align: right;
  }
}

.rkt-TextAlign--right\@9 {
  @media (min-width: $rkt-split-breakpoint-9) {
    text-align: right;
  }
}

.rkt-TextAlign--right\@10 {
  @media (min-width: $rkt-split-breakpoint-10) {
    text-align: right;
  }
}

.rkt-TextAlign--center\@1 {
  @media (min-width: $rkt-split-breakpoint-1) {
    text-align: center;
  }
}

.rkt-TextAlign--center\@2 {
  @media (min-width: $rkt-split-breakpoint-2) {
    text-align: center;
  }
}

.rkt-TextAlign--center\@3 {
  @media (min-width: $rkt-split-breakpoint-3) {
    text-align: center;
  }
}

.rkt-TextAlign--center\@4 {
  @media (min-width: $rkt-split-breakpoint-4) {
    text-align: center;
  }
}

.rkt-TextAlign--center\@5 {
  @media (min-width: $rkt-split-breakpoint-5) {
    text-align: center;
  }
}

.rkt-TextAlign--center\@6 {
  @media (min-width: $rkt-split-breakpoint-6) {
    text-align: center;
  }
}

.rkt-TextAlign--center\@7 {
  @media (min-width: $rkt-split-breakpoint-7) {
    text-align: center;
  }
}

.rkt-TextAlign--center\@8 {
  @media (min-width: $rkt-split-breakpoint-8) {
    text-align: center;
  }
}

.rkt-TextAlign--center\@9 {
  @media (min-width: $rkt-split-breakpoint-9) {
    text-align: center;
  }
}

.rkt-TextAlign--center\@10 {
  @media (min-width: $rkt-split-breakpoint-10) {
    text-align: center;
  }
}

//
// Typography
///

/// Sets the font weight to bold.
.rkt-FontWeight--bold {
  font-weight: bold;
}

/// Sets the font weight to normal.
.rkt-FontWeight--normal {
  font-weight: normal;
}

/// Sets the font style to italic.
.rkt-FontStyle--italic {
  font-style: italic;
}

/// Sets the font style to normal.
.rkt-FontStyle--normal {
  font-style: normal;
}

// Light
.rkt-FontWeight--300 {
  font-weight: $rkt-typography-font-weight-300;
}

// Regular
.rkt-FontWeight--400 {
  font-weight: $rkt-typography-font-weight-400;
}

// Medium
.rkt-FontWeight--500 {
  font-weight: $rkt-typography-font-weight-500;
}

.rkt-FontWeight--600 {
  font-weight: $rkt-typography-font-weight-600;
}

// Bold
.rkt-FontWeight--700 {
  font-weight: $rkt-typography-font-weight-700;
}

/// Sets the text decoration to underline.
.rkt-TextDecoration--underline {
  text-decoration: underline;
}

/// Sets the text decoration to none.
.rkt-TextDecoration--none {
  text-decoration: none;
}

/// Transforms text to uppercase.
.rkt-TextTransform--uppercase {
  text-transform: uppercase;
}

/// Removes text transformation for text.
.rkt-TextTransform--none {
  text-transform: none;
}

/// Sets `word-wrap` to `break-word`.
.rkt-WordWrap--break-word {
  word-wrap: break-word;
}

/// Sets `white-space` to `nowrap`.
.rkt-WhiteSpace--nowrap {
  white-space: nowrap;
}

/// Sets `white-space` to `normal`.
.rkt-WhiteSpace--normal {
  white-space: normal;
}

//
// Overflow
///

/// Content is not clipped and may be rendered outside
/// the padding box.
.rkt-Overflow--visible {
  overflow: visible;
}

/// Content is clipped if necessary to fit the padding
/// box. No scrollbars are provided.
.rkt-Overflow--hidden {
  overflow: hidden;
}

/// Content is clipped if necessary to fit the padding
/// box. Browsers display scrollbars whether or not any
/// content is actually clipped.
.rkt-Overflow--scroll {
  overflow: scroll;
}

/// If content fits inside the padding box, it looks the
/// same as visible, but still establishes a new
/// block-formatting context.
.rkt-Overflow--auto {
  overflow: auto;
}

// Resets button elements
.rkt-ButtonReset {
  box-sizing: border-box;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;
}

.rkt-Cursor--pointer {
  cursor: pointer;
}

//
// Accessibility
///

/// Makes content invisible while still being read by a
/// screen reader.
.rkt-ScreenReaderText {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
}

/// When combined with `.rkt-ScreenReaderText` the element
/// will become visible on focus.
.rkt-ShowOnFocus:focus {
  position: static;
  width: auto;
  height: auto;
}

//
// layout
/// When placed on a container, its children will align
/// in the absolute center (vertically and horizontally)
/// of the box.
.rkt-AbsoluteCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

/// When placed on a container, its children will align
/// to the flex-end
.rkt-AlignItems--flex-end {
  align-items: flex-end;
}

// When placed on an item with a direct flex container parent
// the item will align itself to the vertical center
// of that parent container
.rkt-AlignSelf--center {
  align-self: center;
}

/// When placed on an element that contains text, it will
/// limit the text to one line and adds an ellipsis when
/// it overflows.
.rkt-Truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// These are some base styles to
// make images more responsive
.rkt-ImgResponsive {
  max-width: 100%;
  font-style: italic;
  vertical-align: middle;
}

.rkt-ListStyle--none {
  list-style: none;
}

.rkt-JustifyContent--space-between {
  justify-content: space-between;
}

.rkt-JustifyContent--start {
  justify-content: start;
}

.rkt-JustifyContent--end {
  justify-content: end;
}

.rkt-FlexGrow--1 {
  flex-grow: 1;
}

.rkt-FlexShrink--0 {
  flex-shrink: 0;
}

//
// Margin Auto
///
.rkt-MarginTop--auto {
  margin-top: auto;
}

.rkt-MarginBottom--auto {
  margin-bottom: auto;
}

.rkt-MarginRight--auto {
  margin-right: auto;
}

.rkt-MarginLeft--auto {
  margin-left: auto;
}
