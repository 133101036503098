@use '../web/scss/object' as *;

$width-range-start: 5;
$width-range-end: 100;
$width-step-value: 5;

//
// Width classes
//
// This will generate a list of classes in the format `.rkt-Width-[percent]`.
// It will generate a class for every 5% step from 5 to 100.
///

/// Changes the width of the element to 25 percent.
/// @group width
/// @name .rkt-Width--25

/// Changes the width of the element to 50 percent.
/// @group width
/// @name .rkt-Width--50

/// Changes the width of the element to 5 percent.
/// @group width
/// @name .rkt-Width--5

$width-step-tracker: $width-range-start;

// New width with naming convention that matches the rest of RDS
@while $width-step-tracker <= $width-range-end {
  .rkt-Width--#{$width-step-tracker} {
    width: unquote($string: '#{$width-step-tracker}%');
  }

  $width-step-tracker: $width-step-tracker + $width-step-value;
}
// Outputed as rkt-Width--#

// Add responsive suffixes to width 100%.
// @deprecated in v4, removal in v6. - task #3552469
// These utils are named incorrectly. They are 100 width and then
// turn to auto width at the breakpoints. Please use the new
// name of .rkt-Width--auto@[#]. If you want something to be 100% width
// and then auto, use the combo of rkt-Width--100 and rkt-Width--auto@1.
// This reads as, it is width 100% and turns to auto at the 1st breakpoint size.
.rkt-Width--100\@1,
.rkt-Width--100\@2,
.rkt-Width--100\@3,
.rkt-Width--100\@4,
.rkt-Width--100\@5,
.rkt-Width--100\@6,
.rkt-Width--100\@7,
.rkt-Width--100\@8,
.rkt-Width--100\@9,
.rkt-Width--100\@10 {
  width: 100%;
}

// @deprecated in v4, removal in v6. - task #3552469 Please use rkt-Width--auto@1
.rkt-Width--100\@1,
.rkt-Width--auto\@1 {
  @media (min-width: $rkt-split-breakpoint-1) {
    width: auto;
  }
}

// @deprecated in v4, removal in v6. - task #3552469 Please use rkt-Width--auto@2
.rkt-Width--100\@2,
.rkt-Width--auto\@2 {
  @media (min-width: $rkt-split-breakpoint-2) {
    width: auto;
  }
}

// @deprecated in v4, removal in v6. - task #3552469 Please use rkt-Width--auto@3
.rkt-Width--100\@3,
.rkt-Width--auto\@3 {
  @media (min-width: $rkt-split-breakpoint-3) {
    width: auto;
  }
}

// @deprecated in v4, removal in v6. - task #3552469 Please use rkt-Width--auto@4
.rkt-Width--100\@4,
.rkt-Width--auto\@4 {
  @media (min-width: $rkt-split-breakpoint-4) {
    width: auto;
  }
}

// @deprecated in v4, removal in v6. - task #3552469 Please use rkt-Width--auto@5
.rkt-Width--100\@5,
.rkt-Width--auto\@5 {
  @media (min-width: $rkt-split-breakpoint-5) {
    width: auto;
  }
}

// @deprecated in v4, removal in v6. - task #3552469 Please use rkt-Width--auto@6
.rkt-Width--100\@6,
.rkt-Width--auto\@6 {
  @media (min-width: $rkt-split-breakpoint-6) {
    width: auto;
  }
}

// @deprecated in v4, removal in v6. - task #3552469 Please use rkt-Width--auto@7
.rkt-Width--100\@7,
.rkt-Width--auto\@7 {
  @media (min-width: $rkt-split-breakpoint-7) {
    width: auto;
  }
}

// @deprecated in v4, removal in v6. - task #3552469 Please use rkt-Width--auto@8
.rkt-Width--100\@8,
.rkt-Width--auto\@8 {
  @media (min-width: $rkt-split-breakpoint-8) {
    width: auto;
  }
}

// @deprecated in v4, removal in v6. - task #3552469 Please use rkt-Width--auto@9
.rkt-Width--100\@9,
.rkt-Width--auto\@9 {
  @media (min-width: $rkt-split-breakpoint-9) {
    width: auto;
  }
}

// @deprecated in v4, removal in v6. - task #3552469 Please use rkt-Width--auto@10
.rkt-Width--100\@10,
.rkt-Width--auto\@10 {
  @media (min-width: $rkt-split-breakpoint-10) {
    width: auto;
  }
}
