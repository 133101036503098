@use '../web/scss/settings';

// ANGULAR
.rkt-Radio {
  // Label
  .mdc-form-field label {
    cursor: pointer;
    color: settings.$rkt-radio-label-color;
    padding: settings.$rkt-radio-label-padding;
    font-size: settings.$rkt-radio-label-font-size;
    font-weight: settings.$rkt-radio-label-font-weight;
    line-height: settings.$rkt-radio-label-line-height;
  }

  // Resting Circle
  &.mat-mdc-radio-button
    .mdc-radio:hover
    .mdc-radio__native-control:enabled:not(:checked)
    + .mdc-radio__background
    .mdc-radio__outer-circle,
  &.mat-mdc-radio-button
    .mdc-radio
    .mdc-radio__native-control:enabled:not(:checked)
    + .mdc-radio__background
    .mdc-radio__outer-circle {
    border-color: settings.$rkt-radio-circle-border-color;
    background: settings.$rkt-radio-circle-background;
    border-width: settings.$rkt-radio-circle-border-width;
  }

  // Disabled background
  &.mat-mdc-radio-button
    .mdc-radio
    .mdc-radio__native-control:disabled:not(:checked)
    + .mdc-radio__background
    .mdc-radio__outer-circle {
    background: settings.$rkt-radio-circle-background;
    opacity: 1;
    border-color: settings.$rkt-radio-circle-disabled-color;
  }

  // Padding of the radio circle
  .mdc-form-field .mdc-radio {
    padding: settings.$rkt-radio-circle-padding;
  }

  // Size of Circle
  .mdc-radio,
  .mdc-radio__background,
  &.mat-mdc-radio-button .mdc-radio,
  &.mat-mdc-radio-button .mdc-radio__background {
    height: settings.$rkt-radio-circle-size;
    width: settings.$rkt-radio-circle-size;
  }

  // Radio Right Label
  .mdc-form-field.mdc-form-field--align-end label {
    margin: settings.$rkt-radio-label-right-positioning-margin;
  }

  // Radio Right Circle
  .mdc-form-field.mdc-form-field--align-end .mdc-radio {
    padding: settings.$rkt-radio-label-padding;
  }

  // Clears out default ripple
  .mdc-radio .mat-ripple {
    display: none;
  }

  // Ripple
  &.mat-mdc-radio-button .mdc-radio .mdc-radio__background::before,
  &.mat-mdc-radio-button.mat-mdc-radio-checked .mdc-radio__background::before {
    background: settings.$rkt-radio-ripple-background-color;
    opacity: 1;
    // special positioning
    top: -8px;
    left: -8px;
  }

  // Focus state of ripple
  &.mat-mdc-radio-button
    .mdc-radio:hover
    .mdc-radio__native-control:not([disabled]):not(:focus)
    ~ .mdc-radio__background::before {
    opacity: 1;
    transform: scale(1);
  }

  // Ripple keyboard focused selected
  &.cdk-keyboard-focused.mat-mdc-radio-button
    .mdc-radio
    .mdc-radio__background::before {
    background: settings.$rkt-radio-ripple-focus-selected-background;
  }

  // Ripple focused selected
  &.cdk-mouse-focused.mat-mdc-radio-button
    .mdc-radio
    .mdc-radio__background::before {
    opacity: 0;
  }

    // Ripple hovered, focused, selected
    &.cdk-mouse-focused.mat-mdc-radio-button
    .mdc-radio:hover
    .mdc-radio__background::before {
    opacity: 1;
  }

  // Radio Circle
  // Active
  &.mat-mdc-radio-button .mdc-radio:not(:disabled):active .mdc-radio__native-control:enabled+.mdc-radio__background .mdc-radio__inner-circle,
  &.mat-mdc-radio-button .mdc-radio:not(:disabled):active .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__outer-circle,
  // Hover
  &.mat-mdc-radio-button .mdc-radio:hover .mdc-radio__native-control:enabled+.mdc-radio__background .mdc-radio__inner-circle,
  &.mat-mdc-radio-button .mdc-radio:hover .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__outer-circle,
  // Selected
  &.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__outer-circle,
  &.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled+.mdc-radio__background .mdc-radio__inner-circle {
    border-color: settings.$rkt-radio-circle-active-color;
  }

  &.mat-mdc-radio-button
    .mdc-radio
    .mdc-radio__native-control:checked
    + .mdc-radio__background
    .mdc-radio__inner-circle {
    border-width: settings.$rkt-radio-circle-active-inner-border-width;
    transform: settings.$rkt-radio-circle-active-inner-transform;
    background-color: settings.$rkt-radio-circle-active-inner-background-color;
  }

  // added specificity to override v18 styles. v18 sets a border color so we have to override this
  &.mat-mdc-radio-button .mdc-form-field {
    .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__inner-circle,
    .mdc-radio__native-control:enabled:focus:checked+.mdc-radio__background .mdc-radio__inner-circle,
    .mdc-radio:hover .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__inner-circle,
    .mdc-radio:hover .mdc-radio__native-control:enabled:focus:checked+.mdc-radio__background .mdc-radio__inner-circle {
      border-color: settings.$rkt-radio-circle-active-inner-border-color;
    }
  }

  // Radio Circle Selected
  &.mat-mdc-radio-button
    .mdc-radio
    .mdc-radio__native-control:enabled:checked
    + .mdc-radio__background
    .mdc-radio__outer-circle {
    border-width: settings.$rkt-radio-circle-active-border-width;
  }

  // Radio Circle hover not checked
  &.mat-mdc-radio-button
    .mdc-radio
    .mdc-radio__native-control:focus:enabled:not(:checked)
    ~ .mdc-radio__background
    .mdc-radio__outer-circle {
    border-color: settings.$rkt-radio-circle-hover-border-color;
    border-width: settings.$rkt-radio-circle-hover-border-width;
  }
}

.rkt-Radio--is-disabled {
  &.mat-mdc-radio-button,
  &.Mui-disabled {
    border-color: settings.$rkt-radio-circle-disabled-color;
    color: settings.$rkt-radio-circle-disabled-color;
  }

  // Disabled label
  &.mat-mdc-radio-button.mat-accent .mdc-radio--disabled + label {
  color: settings.$rkt-radio-disabled-label-color;
    cursor: default;
  }
}

// Ensures gray 400 class works so no token
.rkt-FormField .rkt-Color--gray-400.MuiFormControlLabel-label.Mui-disabled {
  color: settings.$rkt-gray-400;
}

// REACT MUI STYLES
.rkt-Radio {
  // LABEL
  + .MuiFormControlLabel-label {
    padding: settings.$rkt-radio-label-mui-padding;
    font-size: settings.$rkt-radio-label-font-size;
    font-weight: settings.$rkt-radio-label-font-weight;
    line-height: settings.$rkt-radio-label-line-height;
  }

  // Hover, not checked
  &.MuiButtonBase-root:hover svg {
    color: settings.$rkt-radio-circle-hover-border-color;
  }

  &.MuiButtonBase-root:hover {
    background: settings.$rkt-radio-ripple-background-color;
  }

  // resting
  &.MuiButtonBase-root:not(.Mui-checked) .MuiSvgIcon-root svg {
    fill: settings.$rkt-radio-circle-background;
  }

  // Focused
  &.MuiButtonBase-root.Mui-focusVisible svg g path {
    stroke: settings.$rkt-radio-circle-focus-stroke;
  }

  // Focused
  &.MuiButtonBase-root.Mui-focusVisible {
    background: settings.$rkt-radio-circle-focus-background;
    padding: settings.$rkt-radio-circle-focus-padding;
    margin-right: settings.$rkt-radio-circle-focus-margin-right;
    margin-left: settings.$rkt-radio-circle-focus-margin-left;
  }

  // Clears out ripple
  &.MuiButtonBase-root .MuiTouchRipple-root {
    display: none;
  }
}

// Radio Right Placement
.rkt-RadioGroup
  .MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementStart {
  justify-content: flex-end;
  margin: settings.$rkt-radio-right-positioning-label-margin;

  .MuiFormControlLabel-label {
    padding: settings.$rkt-radio-right-positioning-label-padding;
  }
}

.rkt-RadioGroup .MuiFormControlLabel-root {
  margin: settings.$rkt-radio-label-mui-margin;
  min-height: settings.$rkt-radio-label-min-height;
}

// radio focus styles for React
.rkt-Radio.Mui-focusVisible .MuiSvgIcon-root {
  border-radius: settings.$rkt-radio-circle-focus-border-radius;

  g path {
    stroke-width: settings.$rkt-radio-circle-focus-stroke-width;
  }
}