@use '../web/scss/settings';

.rkt-Stepper {
  .rkt-Stepper__step-label {
    font-family: settings.$rkt-stepper-step-label-font-family;
    font-size: settings.$rkt-stepper-step-label-font-size;
    font-style: settings.$rkt-stepper-step-label-font-style;
    font-weight: settings.$rkt-stepper-step-label-font-weight;
    color: settings.$rkt-stepper-step-label-color;
    line-height: settings.$rkt-stepper-step-label-line-height;
  }

  // step labels when step is active or completed
  .mat-step-header:has(.mat-step-icon-selected),
  .mat-step-header:has(.mat-step-icon-state-edit),
  .MuiStep-root:has(.Mui-active),
  .MuiStep-root:has(.Mui-completed) {
    .rkt-Stepper__step-label {
      color: settings.$rkt-stepper-step-label-active-color;
    }
  }

  .mat-vertical-stepper-header,
  .MuiStep-root.MuiStep-vertical .MuiButtonBase-root,
  .mat-horizontal-stepper-header,
  .MuiStep-root.MuiStep-horizontal .MuiStepButton-root {
    height: settings.$rkt-stepper-step-height;
    width: settings.$rkt-stepper-step-width;
  }

  &.MuiStepper-root.MuiStepper-horizontal {
    height: settings.$rkt-stepper-step-height;
  }

  .mat-step-header .mat-step-icon-content,
  .MuiStepLabel-root .MuiStepIcon-text {
    font-size: settings.$rkt-stepper-step-header-icon-text-font-size;
    font-style: settings.$rkt-stepper-step-header-icon-text-font-style;
    font-weight: settings.$rkt-stepper-step-header-icon-text-font-weight;
  }

  .mat-step-header .mat-step-icon:not(.mat-step-icon-selected):not(.mat-step-icon-state-edit) {
    background-color: settings.$rkt-stepper-step-icon-default-background-color;

    .mat-step-icon-content {
      color: settings.$rkt-stepper-step-icon-content-color;
    }
  }

  .MuiStepLabel-root:not(:has(.Mui-active)) .MuiSvgIcon-root:not(.Mui-completed) {
    fill: settings.$rkt-stepper-step-label-icon-fill;

    .MuiStepIcon-text {
      fill: settings.$rkt-stepper-step-label-icon-text-fill;
    }
  }

  .MuiStepLabel-iconContainer.Mui-active .MuiSvgIcon-root,
  .MuiStepLabel-iconContainer .MuiSvgIcon-root.Mui-completed {
    color: settings.$rkt-stepper-step-label-icon-completed-color;
  }

  // horizontal line angular selector
  .mat-horizontal-stepper-header-container .mat-stepper-horizontal-line,
  // horizontal and vertical selectors for react
  .MuiStepConnector-root .MuiStepConnector-line,
  // vertical line angular selector
  .mat-stepper-vertical-line::before {
    border-top-width: settings.$rkt-stepper-step-line-width;
    border-color: settings.$rkt-stepper-step-line-color;
    border-left-width: settings.$rkt-stepper-step-line-width;
  }

  .mat-stepper-horizontal-line {
    margin: settings.$rkt-stepper-horizontal-line-margin;
  }

  .mat-step {
    height: settings.$rkt-stepper-horizontal-step-height;
  }

  .mat-step-header,
  .mat-step-header:hover:not([aria-disabled]),
  .MuiStep-root .MuiButtonBase-root {
    border-radius: settings.$rkt-stepper-step-border-radius;
  }

  .mat-horizontal-stepper-header,
  .mat-vertical-stepper-header,
  .MuiStep-root .MuiButtonBase-root {
    padding: settings.$rkt-stepper-step-padding;
  }

  .mat-step-header .mat-step-icon {
    margin-right: settings.$rkt-stepper-step-header-icon-margin-right;
  }

  .MuiStepButton-root .MuiStepLabel-iconContainer {
    padding-right: settings.$rkt-stepper-step-header-icon-container-padding-right;
  }

  .mat-vertical-stepper-header {
    margin-bottom: settings.$rkt-stepper-vertical-stepper-header-margin-bottom;
  }

  .mat-vertical-content-container {
    margin-left: settings.$rkt-stepper-vertical-content-container-margin-left;
  }

  .mat-step-header.cdk-keyboard-focused,
  .mat-step-header.cdk-program-focused,
  .mat-step-header:hover:not([aria-disabled]),
  .mat-step-header:hover[aria-disabled=false],
  .MuiStep-root .MuiButtonBase-root.Mui-focusVisible,
  .MuiStep-root .MuiButtonBase-root:hover {
    background-color: settings.$rkt-stepper-step-hover-background-color;
  }

  // hide ripples
  .MuiStepButton-touchRipple,
  .mat-ripple {
    display: settings.$rkt-stepper-step-ripple-display;
  }

  .mat-stepper-vertical-line::before {
    top: settings.$rkt-stepper-vertical-line-top;
    height: settings.$rkt-stepper-vertical-line-height;
  }

  .MuiStep-vertical {
    padding: settings.$rkt-stepper-step-vertical-padding;
  }

  .MuiStep-horizontal {
    padding: settings.$rkt-stepper-step-horizontal-padding;
  }

  .MuiStepConnector-vertical {
    margin-left: settings.$rkt-stepper-step-connector-vertical-margin-left;
  }

  .mat-step-header, .MuiStep-root {
    z-index: settings.$rkt-stepper-step-z-index;
  }

  .mat-step-header.cdk-keyboard-focused,
  .MuiStep-root .MuiButtonBase-root.Mui-focusVisible {
    box-shadow: settings.$rkt-stepper-step-focus-box-shadow;
  }
}

.rkt-StepperContainer {
  .rkt-StepperProgress {
    display: settings.$rkt-stepper-progress-display;
    flex-direction:  settings.$rkt-stepper-progress-flex-direction;

    &__heading {
      font-family: settings.$rkt-stepper-progress-heading-font-family;
      font-size: settings.$rkt-stepper-progress-heading-font-size;
      font-style: settings.$rkt-stepper-progress-heading-font-style;
      font-weight: settings.$rkt-stepper-progress-heading-font-weight;
      line-height: settings.$rkt-stepper-progress-heading-line-height;
    }

    &__link {
      font-family: settings.$rkt-stepper-progress-link-font-family;
      font-size: settings.$rkt-stepper-progress-link-font-size;
      font-style: settings.$rkt-stepper-progress-link-font-style;
      font-weight: settings.$rkt-stepper-progress-link-font-weight;
      line-height: settings.$rkt-stepper-progress-link-line-height
    }
  }
}