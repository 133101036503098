@use '../web/scss/settings';

/// @deprecated selector in v4, removal in v6 - task #3520091
.rkt-SpinnerLight.mat-progress-spinner circle,
/// @deprecated selector in v4.2, removal in v6 - task #3520091
.rkt-SpinnerLight.mat-mdc-progress-spinner circle,
.rkt-Spinner.mat-mdc-progress-spinner circle {
  stroke: settings.$rkt-spinner-stroke;
}

.rkt-ProgressSpinner circle {
  stroke-linecap: settings.$rkt-spinner-stroke-linecap;
  stroke-linejoin: settings.$rkt-spinner-stroke-linejoin;
  stroke: settings.$rkt-spinner-stroke;
}
