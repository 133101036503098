@use '../web/scss/settings';

.rkt-ButtonIcon,
.rkt-ButtonIcon.mat-mdc-icon-button {
  height: settings.$rkt-button-icon-height;
  width: settings.$rkt-button-icon-width;
  padding: settings.$rkt-button-icon-padding;
  display: settings.$rkt-button-icon-display;
  align-items: settings.$rkt-button-icon-align-items;
  justify-content: settings.$rkt-button-icon-justify-content;
  cursor: settings.$rkt-button-icon-cursor;

  // This avoids layout shift when we change size on hover
  &::after {
    content: settings.$rkt-button-icon-content;
    position: settings.$rkt-button-icon-position;
    // we need the background circle to be underneath the icon
    z-index: settings.$rkt-button-icon-z-index;
    height: settings.$rkt-button-icon-height;
    width: settings.$rkt-button-icon-width;
    background-color: settings.$rkt-button-icon-background-color;
    border-radius: settings.$rkt-button-icon-border-radius;
  }

  .MuiSvgIcon-root {
    color: settings.$rkt-button-icon-color;
    // we need the icon to go on top of the background circle
    z-index: settings.$rkt-button-icon-svg-icon-root-z-index;
  }

  .mat-icon {
    z-index: settings.$rkt-button-icon-mat-icon-z-index;
  }

  .mat-mdc-icon,
  .MuiCircularProgress-svg {
    color: settings.$rkt-button-icon-color;
  }

  .rkt-Spinner.mat-mdc-progress-spinner circle {
    stroke: settings.$rkt-button-icon-color;
  }

  &:hover::after {
    height: settings.$rkt-button-icon-hover-height;
    width: settings.$rkt-button-icon-hover-width;
  }

  // Reset MUI background on hover
  &.MuiButtonBase-root.MuiIconButton-root:hover {
    background-color: transparent;
  }

  // Resetting height prevents shrink if hovered and focused.
  &:focus-visible::after {
    height: settings.$rkt-button-icon-height;
    width: settings.$rkt-button-icon-width;
    box-shadow: settings.$rkt-button-icon-focus-box-shadow;
  }

  &:active::after {
    background-color: settings.$rkt-button-icon-active-background-color;
  }

  &.rkt-ButtonIcon--large {
    height: settings.$rkt-button-icon-large-height;
    width: settings.$rkt-button-icon-large-width;

    &::after {
      height: settings.$rkt-button-icon-large-height;
      width: settings.$rkt-button-icon-large-width;
    }

    &:hover::after {
      height: settings.$rkt-button-icon-large-hover-height;
      width: settings.$rkt-button-icon-large-hover-width;
    }

    // Resetting height prevents shrink if hovered and focused.
    &:focus-visible::after {
      height: settings.$rkt-button-icon-large-height;
      width: settings.$rkt-button-icon-large-width;
    }
  }

  // Remove Ripples
  .mat-mdc-button-persistent-ripple,
  .mat-mdc-button-ripple,
  .MuiTouchRipple-root {
    display: none;
  }
}

.rkt-ButtonIcon.rkt-ButtonIcon--in-inputs::after,
.rkt-ButtonIcon.rkt-ButtonIcon--in-inputs.MuiButtonBase-root {
  background: settings.$rkt-button-icon-in-inputs-background;
}

.rkt-ButtonIcon.rkt-ButtonIcon--in-inputs:hover::after,
.rkt-ButtonIcon.rkt-ButtonIcon--in-inputs.MuiButtonBase-root:hover {
  background: settings.$rkt-button-icon-in-inputs-hover-background;
}

.rkt-ButtonIcon.rkt-ButtonIcon--in-inputs:active::after,
.rkt-ButtonIcon.rkt-ButtonIcon--in-inputs.MuiButtonBase-root:active {
  background: settings.$rkt-button-icon-in-inputs-active-background;
}

// remove transition on react button icons
.rkt-ButtonIcon.rkt-ButtonIcon--in-inputs.MuiButtonBase-root {
  transition: settings.$rkt-button-icon-in-inputs-transition;
}

// remove blue border and background on angular
.rkt-FormField .mat-mdc-form-field-icon-suffix .rkt-ButtonIcon.rkt-ButtonIcon--in-inputs {
  border: settings.$rkt-button-icon-in-inputs-angular-border;
  background: settings.$rkt-button-icon-in-inputs-angular-background;
}

.rkt-ButtonIcon.rkt-ButtonIcon--in-inputs {
  outline:  settings.$rkt-button-icon-in-inputs-outline;
}

.rkt-Button,
.rkt-Button.mat-mdc-button-base {
  background-color: settings.$rkt-button-primary-background-color;
  color: settings.$rkt-button-primary-color;
  padding: settings.$rkt-button-padding;
  line-height: settings.$rkt-button-line-height;
  border-radius: settings.$rkt-button-border-radius;
  height: settings.$rkt-button-height;
  font-weight: settings.$rkt-button-font-weight;
  border: settings.$rkt-button-border;
  font-size: settings.$rkt-button-font-size;

  &:hover {
    background-color: settings.$rkt-button-primary-hover-background-color;
  }

  &:focus-visible {
    box-shadow: settings.$rkt-button-focus-box-shadow;
  }

  &:active {
    background-color: settings.$rkt-button-primary-active-background-color;
  }

  // Remove Ripples
  .mat-mdc-button-persistent-ripple,
  .mat-mdc-button-ripple,
  .MuiTouchRipple-root {
    display: none;
  }
}

// Gets rid of a gray overlay on focus for Angular Buttons
.rkt-Button.cdk-focused.cdk-keyboard-focused .mat-mdc-button-focus-overlay {
  opacity: 0;
}

.rkt-Button--large,
.rkt-Button--large.mat-mdc-button-base {
  padding: settings.$rkt-button-large-padding;
  height: settings.$rkt-button-large-height;
}

.rkt-Button--has-icon,
.rkt-Button.rkt-Button--has-icon-left,
.rkt-Button.rkt-Button--has-icon-right,
.rkt-Button.rkt-Button--has-icon.mat-mdc-button-base {
  .rkt-Icon {
    height: settings.$rkt-button-has-icon-height;
    width: settings.$rkt-button-has-icon-width;
  }

  // Angular: centers the icon with the text.
  // React Material already does this.
  .mat-mdc-button-wrapper,
  .mat-mdc-icon,
  .rkt-Icon,
  .rkt-Spinner {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.rkt-Button.rkt-Button--has-icon-left {
  padding-left: settings.$rkt-button-has-left-icon-padding-left;

  .rkt-Icon {
    margin-right: settings.$rkt-button-has-left-icon-margin-right;
    margin-left: settings.$rkt-button-has-left-icon-margin-left;
  }
}

.rkt-Button.rkt-Button--has-icon-right {
  padding-right: settings.$rkt-button-has-right-icon-padding-right;

  .rkt-Icon,
  .mdc-button__label+.mat-icon {
    margin-right: settings.$rkt-button-has-right-icon-margin-right;
    margin-left: settings.$rkt-button-has-right-icon-margin-left;
  }
}

.rkt-Button--large.rkt-Button--has-icon-left {
  padding-left: settings.$rkt-button-large-has-left-icon-padding-left;
}

.rkt-Button--large.rkt-Button--has-icon-right {
  padding-right: settings.$rkt-button-large-has-right-icon-padding-right;
}

.rkt-Button.rkt-Button--is-spinning,
.rkt-Button.rkt-Button--is-spinning.MuiButton-root.Mui-disabled,
.rkt-Button.rkt-Button--is-spinning:disabled {
  background-color: settings.$rkt-button-primary-spinning-background-color;
  color: settings.$rkt-button-primary-spinning-color;

  .rkt-Spinner.mat-mdc-progress-spinner circle {
    stroke: settings.$rkt-button-primary-spinning-spinner-color;
  }
}

.rkt-Button--secondary,
.rkt-Button.rkt-Button--secondary,
.rkt-Button.rkt-Button--secondary.mat-mdc-button-base {
  background-color: settings.$rkt-button-secondary-background-color;
  box-shadow: settings.$rkt-button-secondary-box-shadow;
  color: settings.$rkt-button-secondary-color;

  &:hover {
    background-color: settings.$rkt-button-secondary-hover-background-color;
  }

  &:focus-visible {
    box-shadow: settings.$rkt-button-secondary-focus-box-shadow;
  }

  &:active {
    background-color: settings.$rkt-button-secondary-active-background-color;
  }
}

.rkt-Button--secondary.rkt-Button--is-spinning,
.rkt-Button--secondary.rkt-Button--is-spinning.MuiButton-root.Mui-disabled,
.rkt-Button--secondary.rkt-Button--is-spinning:disabled {
  background-color: settings.$rkt-button-secondary-spinning-background-color;
  color: settings.$rkt-button-secondary-spinning-color;

  .rkt-Spinner.mat-mdc-progress-spinner circle {
    stroke: settings.$rkt-button-secondary-spinning-spinner-color;
  }
}

.rkt-Button--tertiary,
.rkt-Button.rkt-Button--tertiary,
.rkt-Button.rkt-Button--tertiary.mat-mdc-button-base {
  background-color: settings.$rkt-button-tertiary-background-color;
  color: settings.$rkt-button-secondary-color;

  &:hover {
    background-color: settings.$rkt-button-tertiary-hover-background-color;
  }

  &:focus-visible {
    background-color: settings.$rkt-button-tertiary-focus-background-color;
    box-shadow: settings.$rkt-button-tertiary-focus-box-shadow;
  }

  &:active {
    background-color: settings.$rkt-button-tertiary-active-background-color;

  }
}

.rkt-Button--tertiary.rkt-Button--is-spinning,
.rkt-Button--tertiary.rkt-Button--is-spinning.MuiButton-root.Mui-disabled,
.rkt-Button--tertiary.rkt-Button--is-spinning:disabled {
  background-color: settings.$rkt-button-tertiary-spinning-background-color;
  color: settings.$rkt-button-tertiary-spinning-color;

  .rkt-Spinner.mat-mdc-progress-spinner circle {
    stroke: settings.$rkt-button-tertiary-spinning-spinner-color;
  }
}

.rkt-Button--warning,
.rkt-Button.rkt-Button--warning,
.rkt-Button.rkt-Button--warning.mat-mdc-button-base {
  background-color: settings.$rkt-button-warning-background-color;
  color: settings.$rkt-button-warning-color;

  &:hover {
    background-color: settings.$rkt-button-warning-hover-background-color;
  }

  &:focus-visible {
    box-shadow: settings.$rkt-button-warning-focus-box-shadow;
  }

  &:active {
    background-color: settings.$rkt-button-warning-active-background-color;
  }
}

.rkt-Button--warning.rkt-Button--is-spinning,
.rkt-Button--warning.rkt-Button--is-spinning.MuiButton-root.Mui-disabled,
.rkt-Button--warning.rkt-Button--is-spinning.mat-mdc-button-base:disabled {
  background-color: settings.$rkt-button-warning-spinning-background-color;
  color: settings.$rkt-button-warning-spinning-color;

  .rkt-Spinner.mat-mdc-progress-spinner circle {
    stroke: settings.$rkt-button-warning-spinning-spinner-color;
  }
}

.rkt-Button--warning-outlined,
.rkt-Button--warning-outlined.mat-mdc-outlined-button:not(:disabled) {
  box-shadow: settings.$rkt-button-warning-outlined-box-shadow;
  background-color: settings.$rkt-button-warning-outlined-background-color;
  color: settings.$rkt-button-warning-outlined-color;

  &:hover {
    background-color: settings.$rkt-button-warning-outlined-hover-background-color;
    box-shadow: settings.$rkt-button-warning-outlined-box-shadow;
  }

  &:focus-visible {
    box-shadow: settings.$rkt-button-warning-outlined-focus-box-shadow;
  }

  &:active {
    background-color: settings.$rkt-button-warning-outlined-active-background-color;
  }
}

.rkt-Button--warning-outlined.rkt-Button--is-spinning,
.rkt-Button--warning-outlined.rkt-Button--is-spinning.MuiButton-root.Mui-disabled,
.rkt-Button--warning-outlined.rkt-Button--is-spinning:disabled {
  background-color: settings.$rkt-button-warning-outlined-spinning-background-color;
  color: settings.$rkt-button-warning-outlined-spinning-color;

  .rkt-Spinner.mat-mdc-progress-spinner circle {
    stroke: settings.$rkt-button-warning-outlined-spinning-color;
  }
}

.rkt-Button.rkt-Button--large .rkt-Icon,
.rkt-Button.rkt-Button--large.mat-mdc-unelevated-button .rkt-Icon {
  width: settings.$rkt-button-large-icon-width;
  height: settings.$rkt-button-large-icon-height;
}

.rkt-ButtonIcon.rkt-ButtonIcon--is-disabled,
.rkt-ButtonIcon.rkt-ButtonIcon--is-disabled.mat-mdc-icon-button,
.rkt-ButtonIcon.rkt-ButtonIcon--is-disabled.MuiIconButton-root.Mui-disabled {
  background-color: settings.$rkt-button-icon-disabled-background-color;
  color: settings.$rkt-button-icon-disabled-color;

  &:hover,
  &:active,
  &:focus-visible {
    background-color: settings.$rkt-button-icon-disabled-background-color;
  }
}

.rkt-ButtonIcon.rkt-ButtonIcon--is-disabled::after {
  background-color: settings.$rkt-button-icon-disabled-background-color;
}

.rkt-ButtonIcon.rkt-ButtonIcon--is-disabled .MuiSvgIcon-root {
  color: settings.$rkt-button-icon-disabled-color;
}

.rkt-Button--is-disabled,
.rkt-Button--is-disabled.mat-mdc-button-base:disabled,
.rkt-Button--is-disabled.mat-mdc-button-base.mat-mdc-unelevated-button:disabled,
.rkt-Button.MuiButton-root.Mui-disabled {
  background-color: settings.$rkt-button-disabled-background-color;
  color: settings.$rkt-button-disabled-color;

  &:hover,
  &:active,
  &:focus-visible {
    background-color: settings.$rkt-button-disabled-background-color;
    color: settings.$rkt-button-disabled-color;
  }
}

.rkt-Button--secondary.rkt-Button--is-disabled,
.rkt-Button--secondary.MuiButton-root.Mui-disabled:not(.rkt-Button--is-spinning),
.rkt-Button--secondary.rkt-Button--is-disabled.mat-mdc-button-base:disabled {
  box-shadow: settings.$rkt-button-secondary-disabled-box-shadow;
  color: settings.$rkt-button-disabled-color;

  &:hover,
  &:active,
  &:focus-visible {
    background-color: settings.$rkt-button-disabled-background-color;
    color: settings.$rkt-button-disabled-color;
  }
}

.rkt-Button--tertiary.rkt-Button--is-disabled,
.rkt-Button--tertiary.MuiButton-root.Mui-disabled,
.rkt-Button--tertiary.rkt-Button--is-disabled.mat-mdc-button-base:disabled {
  background-color: settings.$rkt-button-tertiary-disabled-background-color;

  &:hover,
  &:active,
  &:focus-visible {
    background-color: settings.$rkt-button-tertiary-disabled-background-color;
  }
}

.rkt-Button--warning-outlined.rkt-Button--is-disabled,
.rkt-Button--warning-outlined.MuiButton-root.Mui-disabled,
.rkt-Button--warning-outlined.rkt-Button--is-disabled.mat-mdc-outlined-button:disabled,
.rkt-Button--warning-outlined.mat-mdc-stroked-button:disabled {
  background-color: settings.$rkt-button-warning-outlined-disabled-background-color;
  box-shadow: settings.$rkt-button-warning-outlined-disabled-box-shadow;

  &:hover,
  &:active,
  &:focus-visible {
    background-color: settings.$rkt-button-warning-outlined-disabled-background-color;
  }
}

.rkt-Button.rkt-Button--on-dark,
.rkt-Button.rkt-Button--on-dark.mat-mdc-button-base {
  background-color: settings.$rkt-button-primary-on-dark-background-color;
  color: settings.$rkt-button-primary-on-dark-color;

  &:hover {
    background-color: settings.$rkt-button-primary-on-dark-hover-background-color;
    color: settings.$rkt-button-primary-on-dark-hover-color;
  }

  &:focus-visible {
    background-color: settings.$rkt-button-primary-on-dark-focus-background-color;
    box-shadow: settings.$rkt-button-primary-on-dark-focus-box-shadow;
    color: settings.$rkt-button-primary-on-dark-focus-color;
  }

  &:active {
    background-color: settings.$rkt-button-primary-on-dark-active-background-color;
    color: settings.$rkt-button-primary-on-dark-active-color;
  }
}

.rkt-Button.rkt-Button--is-spinning.rkt-Button--on-dark,
.rkt-Button.rkt-Button--is-spinning.rkt-Button--on-dark.MuiButton-root.Mui-disabled {
  background-color: settings.$rkt-button-primary-on-dark-background-color;
  color: settings.$rkt-button-primary-on-dark-color;

  .rkt-Spinner.mat-mdc-progress-spinner circle {
    stroke: settings.$rkt-button-primary-on-dark-spinning-spinner-color;
  }
}

.rkt-Button--is-disabled.rkt-Button--on-dark,
.rkt-Button--is-disabled.rkt-Button--on-dark.mat-mdc-button-base:disabled,
.rkt-Button--is-disabled.rkt-Button--on-dark.mat-mdc-button-base.mat-mdc-unelevated-button:disabled,
.rkt-Button.rkt-Button--on-dark.MuiButton-root.Mui-disabled {
  background-color: settings.$rkt-button-disabled-on-dark-background-color;
  color: settings.$rkt-button-disabled-on-dark-color;

  &:hover,
  &:active,
  &:focus-visible {
    background-color: settings.$rkt-button-disabled-on-dark-background-color;
    color: settings.$rkt-button-disabled-on-dark-color;
  }
}

.rkt-Button--secondary.rkt-Button--on-dark,
.rkt-Button.rkt-Button--secondary.rkt-Button--on-dark.mat-mdc-button-base {
  background-color: settings.$rkt-button-secondary-on-dark-background-color;
  box-shadow: settings.$rkt-button-secondary-on-dark-box-shadow;
  color: settings.$rkt-button-secondary-on-dark-color;

  &:hover {
    background-color: settings.$rkt-button-secondary-on-dark-hover-background-color;
    color: settings.$rkt-button-secondary-on-dark-hover-color;
  }

  &:focus-visible {
    background-color: settings.$rkt-button-secondary-on-dark-focus-background-color;
    box-shadow: settings.$rkt-button-secondary-on-dark-focus-box-shadow;
    color: settings.$rkt-button-secondary-on-dark-focus-color;
  }

  &:active {
    background-color: settings.$rkt-button-secondary-on-dark-active-background-color;
    color: settings.$rkt-button-secondary-on-dark-active-color;
  }
}

.rkt-Button--secondary.rkt-Button--on-dark.mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple:before,
.rkt-Button--secondary.rkt-Button--on-dark:focus .MuiTouchRipple-root .MuiTouchRipple-child {
  background-color: settings.$rkt-button-secondary-on-dark-focus-background-color;
  opacity: settings.$rkt-button-secondary-on-dark-focus-opacity;
}

.rkt-Button--secondary.rkt-Button--is-spinning.rkt-Button--on-dark,
.rkt-Button--secondary.rkt-Button--is-spinning.rkt-Button--on-dark.MuiButton-root.Mui-disabled {
  background-color: settings.$rkt-button-secondary-on-dark-background-color;
  box-shadow: settings.$rkt-button-secondary-on-dark-spinning-box-shadow;
  color: settings.$rkt-button-secondary-on-dark-color;

  .rkt-Spinner.mat-mdc-progress-spinner circle {
    stroke: settings.$rkt-button-secondary-on-dark-spinning-spinner-color;
  }
}

.rkt-Button--secondary.rkt-Button--is-disabled.rkt-Button--on-dark,
.rkt-Button--secondary.rkt-Button--on-dark.MuiButton-root.Mui-disabled,
.rkt-Button--secondary.rkt-Button--is-disabled.rkt-Button--on-dark.mat-mdc-button-base:disabled {
  box-shadow: settings.$rkt-button-secondary-disabled-on-dark-box-shadow;
  color: settings.$rkt-button-secondary-disabled-on-dark-color;
  background-color: settings.$rkt-button-secondary-disabled-on-dark-background-color;

  &:hover,
  &:active,
  &:focus-visible {
    color: settings.$rkt-button-secondary-disabled-on-dark-color;
    background-color: settings.$rkt-button-secondary-disabled-on-dark-background-color;
  }
}

.rkt-ButtonIcon.rkt-ButtonIcon--on-dark,
.rkt-ButtonIcon.mat-mdc-icon-button.rkt-ButtonIcon--on-dark {
  &::after {
    background-color: settings.$rkt-button-icon-on-dark-background-color;
  }

  &:hover::after {
    background-color: settings.$rkt-button-icon-on-dark-hover-background-color
  }

  &:hover {
    .MuiSvgIcon-root,
    .mat-mdc-icon,
    .mat-icon,
    .MuiCircularProgress-svg {
      color: settings.$rkt-button-icon-on-dark-icon-hover-color;
    }
  }

  .rkt-Spinner.mat-mdc-progress-spinner circle {
    stroke: settings.$rkt-button-icon-on-dark-spinner-stroke;
  }

  &.rkt-ButtonIcon--is-disabled .MuiCircularProgress-svg {
    color: settings.$rkt-button-icon-on-dark-spinner-icon-color;
  }

  &:focus-visible::after,
  &:active::after {
    background-color: settings.$rkt-button-icon-on-dark-focus-background-color;
    box-shadow: settings.$rkt-button-icon-on-dark-focus-box-shadow;
  }

  &:focus-visible,
  &:active {
    .MuiSvgIcon-root,
    .mat-mdc-icon,
    .mat-icon,
    .MuiCircularProgress-svg {
      color: settings.$rkt-button-icon-on-dark-icon-focus-color;
    }
  }

  &:active::after {
    background-color: settings.$rkt-button-icon-on-dark-active-background-color;
  }
}

.rkt-ButtonIcon.rkt-ButtonIcon--on-dark.rkt-ButtonIcon--is-disabled,
.rkt-ButtonIcon.mat-mdc-icon-button.rkt-ButtonIcon--on-dark.rkt-ButtonIcon--is-disabled {
  color: settings.$rkt-button-icon-on-dark-disabled-color;

  &::after {
    background-color: settings.$rkt-button-icon-on-dark-disabled-background-color;
  }

  .MuiSvgIcon-root,
  .mat-mdc-icon,
  .mat-icon {
    color: settings.$rkt-button-icon-on-dark-disabled-icon-color;
  }
}