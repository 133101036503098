@use './color' as *;
@use './color-dark' as *;
@use './backdrop' as *;
$rkt-skeleton-pulse-animation: 1.5s ease-in-out 0.5s infinite rkt-Skeleton--pulse !default;
$rkt-skeleton-on-dark-pulse-animation: 1.5s ease-in-out 0.5s infinite rkt-Skeleton--on-dark-pulse !default;
$rkt-skeleton-display: block !default;
$rkt-skeleton-background-color: $rkt-black-opacity-08 !default;
$rkt-skeleton-background-color-pulse: $rkt-black-opacity-04 !default;
$rkt-skeleton-on-dark-background-color: $rkt-white-opacity-300 !default;
$rkt-skeleton-on-dark-background-color-pulse: $rkt-white-opacity-100 !default;
$rkt-skeleton-rectangular-width: 210px !default;
$rkt-skeleton-rectangular-height: 96px !default;
$rkt-skeleton-rectangular-border-radius: 0 !default;
$rkt-skeleton-circular-width: 48px !default;
$rkt-skeleton-circular-height: 48px !default;
$rkt-skeleton-circular-border-radius: 50% !default;
$rkt-skeleton-text-width: 210px !default;
$rkt-skeleton-text-height: 16px !default;
$rkt-skeleton-text-border-radius: 4px !default;
$rkt-skeleton-headline-width: 150px !default;
$rkt-skeleton-overline-width: 60px !default;
$rkt-skeleton-cta-width: 90px !default;