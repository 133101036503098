@use '../web/scss/settings' as *;

//
// Default Stack
//
.rkt-Stack {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.rkt-Stack--flex-wrap {
  flex-wrap: wrap;
}

/// Centers children of the Stack.
.rkt-Stack--center-all {
  align-items: center;
  justify-content: center;
}

/// When placed on a container, its children will be
/// centered along the main-axis of the container.
.rkt-Stack--center-row {
  justify-content: center;
}

/// When placed on a container, its children will be
/// aligned to the end of the container when flex-direction is row.
/// You can choose for this to be constant or add a responsive
/// modifier and have it start at a specific breakpoint
/// ex. rkt-Stack--end-row@2 (1, 2, s, m, l, xl).
.rkt-Stack--end-row {
  justify-content: flex-end;
}

.rkt-Stack--end-row\@1 {
  @media all and (min-width: $rkt-split-breakpoint-1) {
    justify-content: flex-end;
  }
}

.rkt-Stack--end-row\@2 {
  @media all and (min-width: $rkt-split-breakpoint-2) {
    justify-content: flex-end;
  }
}

.rkt-Stack--end-row\@3 {
  @media all and (min-width: $rkt-split-breakpoint-3) {
    justify-content: flex-end;
  }
}

.rkt-Stack--end-row\@4 {
  @media all and (min-width: $rkt-split-breakpoint-4) {
    justify-content: flex-end;
  }
}

.rkt-Stack--end-row\@5 {
  @media all and (min-width: $rkt-split-breakpoint-5) {
    justify-content: flex-end;
  }
}

.rkt-Stack--end-row\@6 {
  @media all and (min-width: $rkt-split-breakpoint-6) {
    justify-content: flex-end;
  }
}

.rkt-Stack--end-row\@7 {
  @media all and (min-width: $rkt-split-breakpoint-7) {
    justify-content: flex-end;
  }
}

.rkt-Stack--start-row {
  justify-content: flex-start;
}

.rkt-Stack--start-row\@1 {
  @media all and (min-width: $rkt-split-breakpoint-1) {
    justify-content: flex-start;
  }
}

.rkt-Stack--start-row\@2 {
  @media all and (min-width: $rkt-split-breakpoint-2) {
    justify-content: flex-start;
  }
}

.rkt-Stack--start-row\@3 {
  @media all and (min-width: $rkt-split-breakpoint-3) {
    justify-content: flex-start;
  }
}

.rkt-Stack--start-row\@4 {
  @media all and (min-width: $rkt-split-breakpoint-4) {
    justify-content: flex-start;
  }
}

.rkt-Stack--start-row\@5 {
  @media all and (min-width: $rkt-split-breakpoint-5) {
    justify-content: flex-start;
  }
}

.rkt-Stack--start-row\@6 {
  @media all and (min-width: $rkt-split-breakpoint-6) {
    justify-content: flex-start;
  }
}

.rkt-Stack--start-row\@7 {
  @media all and (min-width: $rkt-split-breakpoint-7) {
    justify-content: flex-start;
  }
}

/// When placed on a container, its children will be aligned
/// to the end of the container when flex-direction is column.
/// You can choose for this to be constant or add a responsive
/// modifier and have it start at a specific breakpoint
/// ex. rkt-Stack--end-row@2 (1, 2, s, m, l, xl).
.rkt-Stack--end-column {
  align-items: flex-end;
}

.rkt-Stack--end-column\@1 {
  @media all and (min-width: $rkt-split-breakpoint-1) {
    align-items: flex-end;
  }
}

.rkt-Stack--end-column\@2 {
  @media all and (min-width: $rkt-split-breakpoint-2) {
    align-items: flex-end;
  }
}

.rkt-Stack--end-column\@3 {
  @media all and (min-width: $rkt-split-breakpoint-3) {
    align-items: flex-end;
  }
}

.rkt-Stack--end-column\@4 {
  @media all and (min-width: $rkt-split-breakpoint-4) {
    align-items: flex-end;
  }
}

.rkt-Stack--end-column\@5 {
  @media all and (min-width: $rkt-split-breakpoint-5) {
    align-items: flex-end;
  }
}

.rkt-Stack--end-column\@6 {
  @media all and (min-width: $rkt-split-breakpoint-6) {
    align-items: flex-end;
  }
}

.rkt-Stack--end-column\@7 {
  @media all and (min-width: $rkt-split-breakpoint-7) {
    align-items: flex-end;
  }
}

.rkt-Stack--end-column\@8 {
  @media all and (min-width: $rkt-split-breakpoint-8) {
    align-items: flex-end;
  }
}

.rkt-Stack--end-column\@9 {
  @media all and (min-width: $rkt-split-breakpoint-9) {
    align-items: flex-end;
  }
}

.rkt-Stack--end-column\@10 {
  @media all and (min-width: $rkt-split-breakpoint-10) {
    align-items: flex-end;
  }
}

/// When placed on a container, its children will be centered
/// along the cross axis of the container.
.rkt-Stack--center-column {
  align-items: center;
}

// Stack items retain their original width by default
.rkt-Stack__item {
  flex: 0 0 auto;
}

/******
** START REPLACED WITH SCSS FUNCTION - Search Reference: #SpaceBetweenItems
** @deprecated "space" in v5, task #571764. Removal in v7.
*******/
/// This is used for spacing out items in the Stack container.
/// When placed on a container, its children will have "1"
/// space on the bottom (on the side if they are in "split" position).
/// The last item will not have space.
// @deprecated "space" in v5, task #571764. Removal in v7.
.rkt-Stack--space-1 > .rkt-Stack__item,
rkt-stack[isstackitem='true'][itemspacing='1'] {
  margin-bottom: $rkt-stack-space-1;
}

/// This is used for spacing out items in the Stack container.
/// When placed on a container, its children will have "2"
/// space on the bottom (on the side if they are in "split" position).
/// The last item will not have space.
// @deprecated "space" in v5, task #571764. Removal in v7.
.rkt-Stack--space-2 > .rkt-Stack__item,
rkt-stack[isstackitem='true'][itemspacing='2'] {
  margin-bottom: $rkt-stack-space-2;
}

/// This is used for spacing out items in the Stack container.
/// When placed on a container, its children will have "3"
/// space on the bottom (on the side if they are in "split" position).
/// The last item will not have space.
// @deprecated "space" in v5, task #571764. Removal in v7.
.rkt-Stack--space-3 > .rkt-Stack__item,
rkt-stack[isstackitem='true'][itemspacing='3'] {
  margin-bottom: $rkt-stack-space-3;
}

/// This is used for spacing out items in the Stack container.
/// When placed on a container, its children will have "5"
/// space on the bottom (on the side if they are in "split" position).
/// The last item will not have space.
// @deprecated "space" in v5, task #571764. Removal in v7.
.rkt-Stack--space-5 > .rkt-Stack__item,
rkt-stack[isstackitem='true'][itemspacing='5'] {
  margin-bottom: $rkt-stack-space-5;
}

/// This is used for spacing out items in the Stack container.
/// When placed on a container, its children will have "8"
/// space on the bottom (on the side if they are in "split" position).
/// The last item will not have space.
// @deprecated "space" in v5, task #571764. Removal in v7.
.rkt-Stack--space-8 > .rkt-Stack__item,
rkt-stack[isstackitem='true'][itemspacing='8'] {
  margin-bottom: $rkt-stack-space-8;
}

/* stylelint-disable selector-class-pattern */
// @deprecated "space" in v5, task #571764. Removal in v7.
.rkt-Stack--space-4 > .rkt-Stack__item,
rkt-stack[isstackitem='true'][itemspacing='4'] {
  margin-bottom: $rkt-stack-space-4;
}

// @deprecated "space" in v5, task #571764. Removal in v7.
.rkt-Stack--space-6 > .rkt-Stack__item,
rkt-stack[isstackitem='true'][itemspacing='6'] {
  margin-bottom: $rkt-stack-space-6;
}

// @deprecated "space" in v5, task #571764. Removal in v7.
.rkt-Stack--space-7 > .rkt-Stack__item,
rkt-stack[isstackitem='true'][itemspacing='7'] {
  margin-bottom: $rkt-stack-space-7;
}

// @deprecated "space" in v5, task #571764. Removal in v7.
.rkt-Stack--space-9 > .rkt-Stack__item,
rkt-stack[isstackitem='true'][itemspacing='9'] {
  margin-bottom: $rkt-stack-space-9;
}

/* stylelint-enable selector-class-pattern */
// @deprecated "space" in v5, task #571764. Removal in v7.
.rkt-Stack--space-1 > .rkt-Stack__item:last-child,
.rkt-Stack--space-2 > .rkt-Stack__item:last-child,
.rkt-Stack--space-3 > .rkt-Stack__item:last-child,
.rkt-Stack--space-5 > .rkt-Stack__item:last-child,
.rkt-Stack--space-8 > .rkt-Stack__item:last-child,
.rkt-Stack--space-4 > .rkt-Stack__item:last-child,
.rkt-Stack--space-6 > .rkt-Stack__item:last-child,
.rkt-Stack--space-7 > .rkt-Stack__item:last-child,
.rkt-Stack--space-9 > .rkt-Stack__item:last-child,
.rkt-Stack > rkt-stack[isstackitem='true'][itemspacing='1']:last-child,
.rkt-Stack > rkt-stack[isstackitem='true'][itemspacing='2']:last-child,
.rkt-Stack > rkt-stack[isstackitem='true'][itemspacing='3']:last-child,
.rkt-Stack > rkt-stack[isstackitem='true'][itemspacing='4']:last-child,
.rkt-Stack > rkt-stack[isstackitem='true'][itemspacing='5']:last-child,
.rkt-Stack > rkt-stack[isstackitem='true'][itemspacing='6']:last-child,
.rkt-Stack > rkt-stack[isstackitem='true'][itemspacing='7']:last-child,
.rkt-Stack > rkt-stack[isstackitem='true'][itemspacing='8']:last-child,
.rkt-Stack > rkt-stack[isstackitem='true'][itemspacing='9']:last-child {
  margin-bottom: 0;
}

/******
** END REPLACED WITH SCSS FUNCTION - Search Reference: #SpaceBetweenItems
** @deprecated "space" in v5, task #571764. Removal in v7.
*******/

[class*='rkt-Stack__item--sixth\@1'],
[class*='rkt-Stack__item--fifth\@1'],
[class*='rkt-Stack__item--fourth\@1'],
[class*='rkt-Stack__item--third\@1'],
[class*='rkt-Stack__item--half\@1'],
[class*='rkt-Stack__item--three-fifths\@1'],
[class*='rkt-Stack__item--three-tenths\@1'],
[class*='rkt-Stack__item--seven-tenths\@1'],
[class*='rkt-Stack__item--three-fourths\@1'],
[class*='rkt-Stack__item--two-fifths\@1'] {
  @media all and (min-width: $rkt-split-breakpoint-1) {
    margin: 0 !important;
  }
}

[class*='rkt-Stack__item--sixth\@2'],
[class*='rkt-Stack__item--fifth\@2'],
[class*='rkt-Stack__item--fourth\@2'],
[class*='rkt-Stack__item--third\@2'],
[class*='rkt-Stack__item--half\@2'],
[class*='rkt-Stack__item--three-fifths\@2'],
[class*='rkt-Stack__item--three-tenths\@2'],
[class*='rkt-Stack__item--seven-tenths\@2'],
[class*='rkt-Stack__item--three-fourths\@2'],
[class*='rkt-Stack__item--two-fifths\@2'] {
  @media all and (min-width: $rkt-split-breakpoint-2) {
    margin: 0 !important;
  }
}

[class*='rkt-Stack__item--sixth\@3'],
[class*='rkt-Stack__item--fifth\@3'],
[class*='rkt-Stack__item--fourth\@3'],
[class*='rkt-Stack__item--third\@3'],
[class*='rkt-Stack__item--half\@3'],
[class*='rkt-Stack__item--three-fifths\@3'],
[class*='rkt-Stack__item--three-tenths\@3'],
[class*='rkt-Stack__item--seven-tenths\@3'],
[class*='rkt-Stack__item--three-fourths\@3'],
[class*='rkt-Stack__item--two-fifths\@3'] {
  @media all and (min-width: $rkt-split-breakpoint-3) {
    margin: 0 !important;
  }
}

[class*='rkt-Stack__item--sixth\@4'],
[class*='rkt-Stack__item--fifth\@4'],
[class*='rkt-Stack__item--fourth\@4'],
[class*='rkt-Stack__item--third\@4'],
[class*='rkt-Stack__item--half\@4'],
[class*='rkt-Stack__item--three-fifths\@4'],
[class*='rkt-Stack__item--three-tenths\@4'],
[class*='rkt-Stack__item--seven-tenths\@4'],
[class*='rkt-Stack__item--three-fourths\@4'],
[class*='rkt-Stack__item--two-fifths\@4'] {
  @media all and (min-width: $rkt-split-breakpoint-4) {
    margin: 0 !important;
  }
}

[class*='rkt-Stack__item--sixth\@5'],
[class*='rkt-Stack__item--fifth\@5'],
[class*='rkt-Stack__item--fourth\@5'],
[class*='rkt-Stack__item--third\@5'],
[class*='rkt-Stack__item--half\@5'],
[class*='rkt-Stack__item--three-fifths\@5'],
[class*='rkt-Stack__item--three-tenths\@5'],
[class*='rkt-Stack__item--seven-tenths\@5'],
[class*='rkt-Stack__item--three-fourths\@5'],
[class*='rkt-Stack__item--two-fifths\@5'] {
  @media all and (min-width: $rkt-split-breakpoint-5) {
    margin: 0 !important;
  }
}

[class*='rkt-Stack__item--sixth\@6'],
[class*='rkt-Stack__item--fifth\@6'],
[class*='rkt-Stack__item--fourth\@6'],
[class*='rkt-Stack__item--third\@6'],
[class*='rkt-Stack__item--half\@6'],
[class*='rkt-Stack__item--three-fifths\@6'],
[class*='rkt-Stack__item--three-tenths\@6'],
[class*='rkt-Stack__item--seven-tenths\@6'],
[class*='rkt-Stack__item--three-fourths\@6'],
[class*='rkt-Stack__item--two-fifths\@6'] {
  @media all and (min-width: $rkt-split-breakpoint-6) {
    margin: 0 !important;
  }
}

[class*='rkt-Stack__item--sixth\@7'],
[class*='rkt-Stack__item--fifth\@7'],
[class*='rkt-Stack__item--fourth\@7'],
[class*='rkt-Stack__item--third\@7'],
[class*='rkt-Stack__item--half\@7'],
[class*='rkt-Stack__item--three-fifths\@7'],
[class*='rkt-Stack__item--three-tenths\@7'],
[class*='rkt-Stack__item--seven-tenths\@7'],
[class*='rkt-Stack__item--three-fourths\@7'],
[class*='rkt-Stack__item--two-fifths\@7'] {
  @media all and (min-width: $rkt-split-breakpoint-7) {
    margin: 0 !important;
  }
}

[class*='rkt-Stack__item--sixth\@8'],
[class*='rkt-Stack__item--fifth\@8'],
[class*='rkt-Stack__item--fourth\@8'],
[class*='rkt-Stack__item--third\@8'],
[class*='rkt-Stack__item--half\@8'],
[class*='rkt-Stack__item--three-fifths\@8'],
[class*='rkt-Stack__item--three-tenths\@8'],
[class*='rkt-Stack__item--seven-tenths\@8'],
[class*='rkt-Stack__item--three-fourths\@8'],
[class*='rkt-Stack__item--two-fifths\@8'] {
  @media all and (min-width: $rkt-split-breakpoint-8) {
    margin: 0 !important;
  }
}

[class*='rkt-Stack__item--sixth\@9'],
[class*='rkt-Stack__item--fifth\@9'],
[class*='rkt-Stack__item--fourth\@9'],
[class*='rkt-Stack__item--third\@9'],
[class*='rkt-Stack__item--half\@9'],
[class*='rkt-Stack__item--three-fifths\@9'],
[class*='rkt-Stack__item--three-tenths\@9'],
[class*='rkt-Stack__item--seven-tenths\@9'],
[class*='rkt-Stack__item--three-fourths\@9'],
[class*='rkt-Stack__item--two-fifths\@9'] {
  @media all and (min-width: $rkt-split-breakpoint-9) {
    margin: 0 !important;
  }
}

[class*='rkt-Stack__item--sixth\@10'],
[class*='rkt-Stack__item--fifth\@10'],
[class*='rkt-Stack__item--fourth\@10'],
[class*='rkt-Stack__item--third\@10'],
[class*='rkt-Stack__item--half\@10'],
[class*='rkt-Stack__item--three-fifths\@10'],
[class*='rkt-Stack__item--three-tenths\@10'],
[class*='rkt-Stack__item--seven-tenths\@10'],
[class*='rkt-Stack__item--three-fourths\@10'],
[class*='rkt-Stack__item--two-fifths\@10'] {
  @media all and (min-width: $rkt-split-breakpoint-10) {
    margin: 0 !important;
  }
}

//
// Split at extra extra 2 breakpoint
//

/// When placed on an item, its width will be 1/6
/// of its container starting at the breakpoint
/// specified (1, 2, s, m, l, xl).
/// @name .rkt-Stack__item--sixth@1

/// When placed on an item, its width will be 1/5
/// of its container starting at the breakpoint
/// specified (1, 2, s, m, l, xl).
/// @name .rkt-Stack__item--fifth@1

/// When placed on an item, its width will be 1/4
/// of its container starting at the breakpoint
/// specified (1, 2, s, m, l, xl).
/// @name .rkt-Stack__item--fourth@1

// When placed on an item, its width will be 1/3
/// of its container starting at the breakpoint
/// specified (1, 2, s, m, l, xl).
/// @name .rkt-Stack__item--third@1

/// When placed on an item, its width will be 1/2
/// of its container starting at the breakpoint
/// specified (1, 2, s, m, l, xl).
/// @name .rkt-Stack__item--half@1

/// When placed on an item, its width will be 3/4
/// of its container starting at the breakpoint
/// specified (1, 2, s, m, l, xl).
/// @name .rkt-Stack__item--three-fourths@1

/// When placed on an item, its width will be 2/5
/// of its container starting at the breakpoint
/// specified (1, 2, s, m, l, xl).
/// @name .rkt-Stack__item--two-fifths@1

/// When placed on an item, its width will be 3/5
/// of its container starting at the breakpoint
/// specified (1, 2, s, m, l, xl).
/// @name .rkt-Stack__item--three-fifths@1

/// When placed on an item, its width will be 3/10
/// of its container starting at the breakpoint
/// specified (1, 2, s, m, l, xl).
/// @name .rkt-Stack__item--three-tenths@1

/// When placed on an item, its width will be 7/10
/// of its container starting at the breakpoint
/// specified (1, 2, s, m, l, xl).
/// @name .rkt-Stack__item--seven-tenths@1

/******
** START REPLACED PARTS WITH SCSS FUNCTION
** Search Reference: #SplitSpacing
*******/
// @deprecated "space" in v5, task #571764. Removal in v7.
.rkt-Stack--split\@1,
.rkt-Stack--split-reverse\@1 {
  @media all and (min-width: $rkt-split-breakpoint-1) {
    // At extra 2 breakpoint we switch from column to row
    flex-direction: row;

    // Remove bottom margin in new row formation
    > .rkt-Stack__item {
      margin-bottom: 0;
    }

    // Add space between items based on space size class
    &.rkt-Stack--space-1 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-1;
    }

    &.rkt-Stack--space-2 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-2;
    }

    &.rkt-Stack--space-3 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-3;
    }

    &.rkt-Stack--space-5 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-5;
    }

    &.rkt-Stack--space-8 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-8;
    }

    /* stylelint-disable selector-class-pattern */
    &.rkt-Stack--space-4 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-4;
    }

    &.rkt-Stack--space-6 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-6;
    }

    &.rkt-Stack--space-7 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-7;
    }

    &.rkt-Stack--space-9 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-9;
    }

    /* stylelint-enable selector-class-pattern */
    > .rkt-Stack__item--sixth\@1 {
      flex: 0 0 16.666666667%;
      max-width: 16.666666667%;
    }

    > .rkt-Stack__item--fifth\@1 {
      flex: 0 0 20%;
      max-width: 20%;
    }

    > .rkt-Stack__item--fourth\@1 {
      flex: 0 0 25%;
      max-width: 25%;
    }

    > .rkt-Stack__item--third\@1 {
      flex: 0 0 33.333333333%;
      max-width: 33.333333333%;
    }

    > .rkt-Stack__item--half\@1 {
      flex: 0 0 50%;
      max-width: 50%;
    }

    > .rkt-Stack__item--three-fourths\@1 {
      flex: 0 0 75%;
      max-width: 75%;
    }

    > .rkt-Stack__item--three-fifths\@1 {
      flex: 0 0 60%;
      max-width: 60%;
    }

    > .rkt-Stack__item--three-tenths\@1 {
      flex: 0 0 30%;
      max-width: 30%;
    }

    > .rkt-Stack__item--seven-tenths\@1 {
      flex: 0 0 70%;
      max-width: 70%;
    }

    > .rkt-Stack__item--two-fifths\@1 {
      flex: 0 0 40%;
      max-width: 40%;
    }
  }
}

/// When placed on a container, its children will go from a
/// stacked layout to a side by side layout at the extra extra
/// 2 split breakpoint.
// @deprecated "space" in v5, task #571764. Removal in v7.
// Replaced with scss function. Search Reference: #SplitSpacing
.rkt-Stack--split\@1 {
  @media all and (min-width: $rkt-split-breakpoint-1) {
    // We want the last item to have zero margin on the right
    &.rkt-Stack--space-1 > .rkt-Stack__item:last-child,
    &.rkt-Stack--space-2 > .rkt-Stack__item:last-child,
    &.rkt-Stack--space-3 > .rkt-Stack__item:last-child,
    &.rkt-Stack--space-5 > .rkt-Stack__item:last-child,
    &.rkt-Stack--space-8 > .rkt-Stack__item:last-child,
    &.rkt-Stack--space-4 > .rkt-Stack__item:last-child,
    &.rkt-Stack--space-6 > .rkt-Stack__item:last-child,
    &.rkt-Stack--space-7 > .rkt-Stack__item:last-child,
    &.rkt-Stack--space-9 > .rkt-Stack__item:last-child {
      margin-right: 0;
    }
  }
}

//
// Split at extra 2 breakpoint
//
.rkt-Stack--split\@2,
.rkt-Stack--split-reverse\@2 {
  @media all and (min-width: $rkt-split-breakpoint-2) {
    // At extra 2 breakpoint we switch from column to row
    flex-direction: row;

    // Remove bottom margin in new row formation
    > .rkt-Stack__item {
      margin-bottom: 0;
    }

    // Add space between items based on space size class
    // @deprecated "space" in v5, task #571764. Removal in v7.
    &.rkt-Stack--space-1 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-1;
    }

    &.rkt-Stack--space-2 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-2;
    }

    &.rkt-Stack--space-3 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-3;
    }

    &.rkt-Stack--space-5 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-5;
    }

    &.rkt-Stack--space-8 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-8;
    }

    /* stylelint-disable selector-class-pattern */
    &.rkt-Stack--space-4 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-4;
    }

    &.rkt-Stack--space-6 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-6;
    }

    &.rkt-Stack--space-7 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-7;
    }

    &.rkt-Stack--space-9 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-9;
    }

    /* stylelint-enable selector-class-pattern */
    > .rkt-Stack__item--sixth\@2 {
      flex: 0 0 16.666666667%;
      max-width: 16.666666667%;
    }

    > .rkt-Stack__item--fifth\@2 {
      flex: 0 0 20%;
      max-width: 20%;
    }

    > .rkt-Stack__item--fourth\@2 {
      flex: 0 0 25%;
      max-width: 25%;
    }

    > .rkt-Stack__item--third\@2 {
      flex: 0 0 33.333333333%;
      max-width: 33.333333333%;
    }

    > .rkt-Stack__item--half\@2 {
      flex: 0 0 50%;
      max-width: 50%;
    }

    > .rkt-Stack__item--three-fourths\@2 {
      flex: 0 0 75%;
      max-width: 75%;
    }

    > .rkt-Stack__item--three-fifths\@2 {
      flex: 0 0 60%;
      max-width: 60%;
    }

    > .rkt-Stack__item--three-tenths\@2 {
      flex: 0 0 30%;
      max-width: 30%;
    }

    > .rkt-Stack__item--seven-tenths\@2 {
      flex: 0 0 70%;
      max-width: 70%;
    }

    > .rkt-Stack__item--two-fifths\@2 {
      flex: 0 0 40%;
      max-width: 40%;
    }
  }
}

/// When placed on a container, its children will go from a
/// stacked layout to a side by side layout at the extra
/// 2 split breakpoint.
.rkt-Stack--split\@2 {
  @media all and (min-width: $rkt-split-breakpoint-2) {
    // We want the last item to have zero margin on the right
    // @deprecated "space" in v5, task #571764. Removal in v7.
    &.rkt-Stack--space-1 > .rkt-Stack__item:last-child,
    &.rkt-Stack--space-2 > .rkt-Stack__item:last-child,
    &.rkt-Stack--space-3 > .rkt-Stack__item:last-child,
    &.rkt-Stack--space-5 > .rkt-Stack__item:last-child,
    &.rkt-Stack--space-8 > .rkt-Stack__item:last-child,
    &.rkt-Stack--space-4 > .rkt-Stack__item:last-child,
    &.rkt-Stack--space-6 > .rkt-Stack__item:last-child,
    &.rkt-Stack--space-7 > .rkt-Stack__item:last-child,
    &.rkt-Stack--space-9 > .rkt-Stack__item:last-child {
      margin-right: 0;
    }
  }
}

//
// Split at  2 breakpoint
//
.rkt-Stack--split\@3,
.rkt-Stack--split-reverse\@3 {
  @media all and (min-width: $rkt-split-breakpoint-3) {
    // At 2 breakpoint we switch from column to row
    flex-direction: row;

    // Remove bottom margin in new row formation
    > .rkt-Stack__item {
      margin-bottom: 0;
    }

    // Add space between items based on space size class
    &.rkt-Stack--space-1 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-1;
    }

    &.rkt-Stack--space-2 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-2;
    }

    &.rkt-Stack--space-3 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-3;
    }

    &.rkt-Stack--space-5 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-5;
    }

    &.rkt-Stack--space-8 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-8;
    }

    /* stylelint-disable selector-class-pattern */
    &.rkt-Stack--space-4 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-4;
    }

    &.rkt-Stack--space-6 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-6;
    }

    &.rkt-Stack--space-7 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-7;
    }

    &.rkt-Stack--space-9 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-9;
    }

    /* stylelint-enable selector-class-pattern */
    > .rkt-Stack__item--sixth\@3 {
      flex: 0 0 16.666666667%;
      max-width: 16.666666667%;
    }

    > .rkt-Stack__item--fifth\@3 {
      flex: 0 0 20%;
      max-width: 20%;
    }

    > .rkt-Stack__item--fourth\@3 {
      flex: 0 0 25%;
      max-width: 25%;
    }

    > .rkt-Stack__item--third\@3 {
      flex: 0 0 33.333333333%;
      max-width: 33.333333333%;
    }

    > .rkt-Stack__item--half\@3 {
      flex: 0 0 50%;
      max-width: 50%;
    }

    > .rkt-Stack__item--three-fourths\@3 {
      flex: 0 0 75%;
      max-width: 75%;
    }

    > .rkt-Stack__item--three-fifths\@3 {
      flex: 0 0 60%;
      max-width: 60%;
    }

    > .rkt-Stack__item--three-tenths\@3 {
      flex: 0 0 30%;
      max-width: 30%;
    }

    > .rkt-Stack__item--seven-tenths\@3 {
      flex: 0 0 70%;
      max-width: 70%;
    }

    > .rkt-Stack__item--two-fifths\@3 {
      flex: 0 0 40%;
      max-width: 40%;
    }
  }
}

/// When placed on a container, its children will go from a
/// stacked layout to a side by side layout at the
/// 2 split breakpoint.
.rkt-Stack--split\@3 {
  @media all and (min-width: $rkt-split-breakpoint-3) {
    // We want the last item to have zero margin on the right
    &.rkt-Stack--space-1 > .rkt-Stack__item:last-child,
    &.rkt-Stack--space-2 > .rkt-Stack__item:last-child,
    &.rkt-Stack--space-3 > .rkt-Stack__item:last-child,
    &.rkt-Stack--space-5 > .rkt-Stack__item:last-child,
    &.rkt-Stack--space-8 > .rkt-Stack__item:last-child,
    &.rkt-Stack--space-4 > .rkt-Stack__item:last-child,
    &.rkt-Stack--space-6 > .rkt-Stack__item:last-child,
    &.rkt-Stack--space-7 > .rkt-Stack__item:last-child,
    &.rkt-Stack--space-9 > .rkt-Stack__item:last-child {
      margin-right: 0;
    }
  }
}

//
// Split at 4 breakpoint
//
.rkt-Stack--split\@4,
.rkt-Stack--split-reverse\@4 {
  @media all and (min-width: $rkt-split-breakpoint-4) {
    // At 3 breakpoint we switch from column to row
    flex-direction: row;

    // Remove bottom margin in new row formation
    > .rkt-Stack__item {
      margin-bottom: 0;
    }

    // Add space between items based on space size class
    &.rkt-Stack--space-1 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-1;
    }

    &.rkt-Stack--space-2 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-2;
    }

    &.rkt-Stack--space-3 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-3;
    }

    &.rkt-Stack--space-5 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-5;
    }

    &.rkt-Stack--space-8 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-8;
    }

    /* stylelint-disable selector-class-pattern */
    &.rkt-Stack--space-4 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-4;
    }

    &.rkt-Stack--space-6 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-6;
    }

    &.rkt-Stack--space-7 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-7;
    }

    &.rkt-Stack--space-9 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-9;
    }

    /* stylelint-enable selector-class-pattern */
    > .rkt-Stack__item--sixth\@4 {
      flex: 0 0 16.666666667%;
      max-width: 16.666666667%;
    }

    > .rkt-Stack__item--fifth\@4 {
      flex: 0 0 20%;
      max-width: 20%;
    }

    > .rkt-Stack__item--fourth\@4 {
      flex: 0 0 25%;
      max-width: 25%;
    }

    > .rkt-Stack__item--third\@4 {
      flex: 0 0 33.333333333%;
      max-width: 33.333333333%;
    }

    > .rkt-Stack__item--half\@4 {
      flex: 0 0 50%;
      max-width: 50%;
    }

    > .rkt-Stack__item--three-fourths\@4 {
      flex: 0 0 75%;
      max-width: 75%;
    }

    > .rkt-Stack__item--three-fifths\@4 {
      flex: 0 0 60%;
      max-width: 60%;
    }

    > .rkt-Stack__item--three-tenths\@4 {
      flex: 0 0 30%;
      max-width: 30%;
    }

    > .rkt-Stack__item--seven-tenths\@4 {
      flex: 0 0 70%;
      max-width: 70%;
    }

    > .rkt-Stack__item--two-fifths\@4 {
      flex: 0 0 40%;
      max-width: 40%;
    }
  }
}

/// When placed on a container, its children will go from a
/// stacked layout to a side by side layout at the
/// 3 split breakpoint.
.rkt-Stack--split\@4 {
  @media all and (min-width: $rkt-split-breakpoint-4) {
    // We want the last item to have zero margin on the right
    &.rkt-Stack--space-1 > .rkt-Stack__item:last-child,
    &.rkt-Stack--space-2 > .rkt-Stack__item:last-child,
    &.rkt-Stack--space-3 > .rkt-Stack__item:last-child,
    &.rkt-Stack--space-5 > .rkt-Stack__item:last-child,
    &.rkt-Stack--space-8 > .rkt-Stack__item:last-child,
    &.rkt-Stack--space-4 > .rkt-Stack__item:last-child,
    &.rkt-Stack--space-6 > .rkt-Stack__item:last-child,
    &.rkt-Stack--space-7 > .rkt-Stack__item:last-child,
    &.rkt-Stack--space-9 > .rkt-Stack__item:last-child {
      margin-right: 0;
    }
  }
}

//
// Split at 5 breakpoint
//
.rkt-Stack--split\@5,
.rkt-Stack--split-reverse\@5 {
  @media all and (min-width: $rkt-split-breakpoint-5) {
    // At 5 breakpoint we switch from column to row
    flex-direction: row;

    // Remove bottom margin in new row formation
    > .rkt-Stack__item {
      margin-bottom: 0;
    }

    // Add space between items based on space size class
    &.rkt-Stack--space-1 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-1;
    }

    &.rkt-Stack--space-2 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-2;
    }

    &.rkt-Stack--space-3 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-3;
    }

    &.rkt-Stack--space-5 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-5;
    }

    &.rkt-Stack--space-8 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-8;
    }

    /* stylelint-disable selector-class-pattern */
    &.rkt-Stack--space-4 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-4;
    }

    &.rkt-Stack--space-6 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-6;
    }

    &.rkt-Stack--space-7 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-7;
    }

    &.rkt-Stack--space-9 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-9;
    }

    /* stylelint-enable selector-class-pattern */
    > .rkt-Stack__item--sixth\@5 {
      flex: 0 0 16.666666667%;
      max-width: 16.666666667%;
    }

    > .rkt-Stack__item--fifth\@5 {
      flex: 0 0 20%;
      max-width: 20%;
    }

    > .rkt-Stack__item--fourth\@5 {
      flex: 0 0 25%;
      max-width: 25%;
    }

    > .rkt-Stack__item--third\@5 {
      flex: 0 0 33.333333333%;
      max-width: 33.333333333%;
    }

    > .rkt-Stack__item--half\@5 {
      flex: 0 0 50%;
      max-width: 50%;
    }

    > .rkt-Stack__item--three-fourths\@5 {
      flex: 0 0 75%;
      max-width: 75%;
    }

    > .rkt-Stack__item--three-fifths\@5 {
      flex: 0 0 60%;
      max-width: 60%;
    }

    > .rkt-Stack__item--three-tenths\@5 {
      flex: 0 0 30%;
      max-width: 30%;
    }

    > .rkt-Stack__item--seven-tenths\@5 {
      flex: 0 0 70%;
      max-width: 70%;
    }

    > .rkt-Stack__item--two-fifths\@5 {
      flex: 0 0 40%;
      max-width: 40%;
    }
  }
}

/// When placed on a container, its children will go from a
/// stacked layout to a side by side layout at the
/// 5 split breakpoint.
.rkt-Stack--split\@5 {
  @media all and (min-width: $rkt-split-breakpoint-5) {
    // We want the last item to have zero margin on the right
    &.rkt-Stack--space-1 > .rkt-Stack__item:last-child,
    &.rkt-Stack--space-2 > .rkt-Stack__item:last-child,
    &.rkt-Stack--space-3 > .rkt-Stack__item:last-child,
    &.rkt-Stack--space-5 > .rkt-Stack__item:last-child,
    &.rkt-Stack--space-8 > .rkt-Stack__item:last-child,
    &.rkt-Stack--space-4 > .rkt-Stack__item:last-child,
    &.rkt-Stack--space-6 > .rkt-Stack__item:last-child,
    &.rkt-Stack--space-7 > .rkt-Stack__item:last-child,
    &.rkt-Stack--space-9 > .rkt-Stack__item:last-child {
      margin-right: 0;
    }
  }
}

//
// Split at 6 breakpoint
//
.rkt-Stack--split\@6,
.rkt-Stack--split-reverse\@6 {
  @media all and (min-width: $rkt-split-breakpoint-6) {
    // At extra 5 breakpoint we switch from column to row
    flex-direction: row;

    // Remove bottom margin in new row formation
    > .rkt-Stack__item {
      margin-bottom: 0;
    }

    // Add space between items based on space size class
    &.rkt-Stack--space-1 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-1;
    }

    &.rkt-Stack--space-2 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-2;
    }

    &.rkt-Stack--space-3 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-3;
    }

    &.rkt-Stack--space-5 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-5;
    }

    &.rkt-Stack--space-8 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-8;
    }

    /* stylelint-disable selector-class-pattern */
    &.rkt-Stack--space-4 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-4;
    }

    &.rkt-Stack--space-6 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-6;
    }

    &.rkt-Stack--space-7 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-7;
    }

    &.rkt-Stack--space-9 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-9;
    }

    /* stylelint-enable selector-class-pattern */
    > .rkt-Stack__item--sixth\@6 {
      flex: 0 0 16.666666667%;
      max-width: 16.666666667%;
    }

    > .rkt-Stack__item--fifth\@6 {
      flex: 0 0 20%;
      max-width: 20%;
    }

    > .rkt-Stack__item--fourth\@6 {
      flex: 0 0 25%;
      max-width: 25%;
    }

    > .rkt-Stack__item--third\@6 {
      flex: 0 0 33.333333333%;
      max-width: 33.333333333%;
    }

    > .rkt-Stack__item--half\@6 {
      flex: 0 0 50%;
      max-width: 50%;
    }

    > .rkt-Stack__item--three-fourths\@6 {
      flex: 0 0 75%;
      max-width: 75%;
    }

    > .rkt-Stack__item--three-fifths\@6 {
      flex: 0 0 60%;
      max-width: 60%;
    }

    > .rkt-Stack__item--three-tenths\@6 {
      flex: 0 0 30%;
      max-width: 30%;
    }

    > .rkt-Stack__item--seven-tenths\@6 {
      flex: 0 0 70%;
      max-width: 70%;
    }

    > .rkt-Stack__item--two-fifths\@6 {
      flex: 0 0 40%;
      max-width: 40%;
    }
  }
}

/// When placed on a container, its children will go from a
/// stacked layout to a side by side layout at the
/// 6 split breakpoint.
.rkt-Stack--split\@6 {
  @media all and (min-width: $rkt-split-breakpoint-6) {
    // We want the last item to have zero margin on the right
    &.rkt-Stack--space-1 > .rkt-Stack__item:last-child,
    &.rkt-Stack--space-2 > .rkt-Stack__item:last-child,
    &.rkt-Stack--space-3 > .rkt-Stack__item:last-child,
    &.rkt-Stack--space-5 > .rkt-Stack__item:last-child,
    &.rkt-Stack--space-8 > .rkt-Stack__item:last-child,
    &.rkt-Stack--space-4 > .rkt-Stack__item:last-child,
    &.rkt-Stack--space-6 > .rkt-Stack__item:last-child,
    &.rkt-Stack--space-7 > .rkt-Stack__item:last-child,
    &.rkt-Stack--space-9 > .rkt-Stack__item:last-child {
      margin-right: 0;
    }
  }
}

//
// Split at 7 breakpoint
//
.rkt-Stack--split\@7,
.rkt-Stack--split-reverse\@7 {
  @media all and (min-width: $rkt-split-breakpoint-7) {
    // At extra 5 breakpoint we switch from column to row
    flex-direction: row;

    // Remove bottom margin in new row formation
    > .rkt-Stack__item {
      margin-bottom: 0;
    }

    // Add space between items based on space size class
    &.rkt-Stack--space-1 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-1;
    }

    &.rkt-Stack--space-2 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-2;
    }

    &.rkt-Stack--space-3 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-3;
    }

    &.rkt-Stack--space-5 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-5;
    }

    &.rkt-Stack--space-8 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-8;
    }

    /* stylelint-disable selector-class-pattern */
    &.rkt-Stack--space-4 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-4;
    }

    &.rkt-Stack--space-6 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-6;
    }

    &.rkt-Stack--space-7 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-7;
    }

    &.rkt-Stack--space-9 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-9;
    }

    /* stylelint-enable selector-class-pattern */
    > .rkt-Stack__item--sixth\@7 {
      flex: 0 0 16.666666667%;
      max-width: 16.666666667%;
    }

    > .rkt-Stack__item--fifth\@7 {
      flex: 0 0 20%;
      max-width: 20%;
    }

    > .rkt-Stack__item--fourth\@7 {
      flex: 0 0 25%;
      max-width: 25%;
    }

    > .rkt-Stack__item--third\@7 {
      flex: 0 0 33.333333333%;
      max-width: 33.333333333%;
    }

    > .rkt-Stack__item--half\@7 {
      flex: 0 0 50%;
      max-width: 50%;
    }

    > .rkt-Stack__item--three-fourths\@7 {
      flex: 0 0 75%;
      max-width: 75%;
    }

    > .rkt-Stack__item--three-fifths\@7 {
      flex: 0 0 60%;
      max-width: 60%;
    }

    > .rkt-Stack__item--three-tenths\@7 {
      flex: 0 0 30%;
      max-width: 30%;
    }

    > .rkt-Stack__item--seven-tenths\@7 {
      flex: 0 0 70%;
      max-width: 70%;
    }

    > .rkt-Stack__item--two-fifths\@7 {
      flex: 0 0 40%;
      max-width: 40%;
    }
  }
}

/// When placed on a container, its children will go from a
/// stacked layout to a side by side layout at the
/// 7 split breakpoint.
.rkt-Stack--split\@7 {
  @media all and (min-width: $rkt-split-breakpoint-7) {
    // We want the last item to have zero margin on the right
    &.rkt-Stack--space-1 > .rkt-Stack__item:last-child,
    &.rkt-Stack--space-2 > .rkt-Stack__item:last-child,
    &.rkt-Stack--space-3 > .rkt-Stack__item:last-child,
    &.rkt-Stack--space-5 > .rkt-Stack__item:last-child,
    &.rkt-Stack--space-8 > .rkt-Stack__item:last-child,
    &.rkt-Stack--space-4 > .rkt-Stack__item:last-child,
    &.rkt-Stack--space-6 > .rkt-Stack__item:last-child,
    &.rkt-Stack--space-7 > .rkt-Stack__item:last-child,
    &.rkt-Stack--space-9 > .rkt-Stack__item:last-child {
      margin-right: 0;
    }
  }
}

//
// Split at 8 breakpoint
//
.rkt-Stack--split\@8,
.rkt-Stack--split-reverse\@8 {
  @media all and (min-width: $rkt-split-breakpoint-8) {
    // At 8 breakpoint we switch from column to row
    flex-direction: row;

    // Remove bottom margin in new row formation
    > .rkt-Stack__item {
      margin-bottom: 0;
    }

    // Add space between items based on space size class
    &.rkt-Stack--space-1 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-1;
    }

    &.rkt-Stack--space-2 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-2;
    }

    &.rkt-Stack--space-3 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-3;
    }

    &.rkt-Stack--space-5 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-5;
    }

    &.rkt-Stack--space-8 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-8;
    }

    /* stylelint-disable selector-class-pattern */
    &.rkt-Stack--space-4 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-4;
    }

    &.rkt-Stack--space-6 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-6;
    }

    &.rkt-Stack--space-7 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-7;
    }

    &.rkt-Stack--space-9 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-9;
    }

    /* stylelint-enable selector-class-pattern */
    > .rkt-Stack__item--sixth\@8 {
      flex: 0 0 16.666666667%;
      max-width: 16.666666667%;
    }

    > .rkt-Stack__item--fifth\@8 {
      flex: 0 0 20%;
      max-width: 20%;
    }

    > .rkt-Stack__item--fourth\@8 {
      flex: 0 0 25%;
      max-width: 25%;
    }

    > .rkt-Stack__item--third\@8 {
      flex: 0 0 33.333333333%;
      max-width: 33.333333333%;
    }

    > .rkt-Stack__item--half\@8 {
      flex: 0 0 50%;
      max-width: 50%;
    }

    > .rkt-Stack__item--three-fourths\@8 {
      flex: 0 0 75%;
      max-width: 75%;
    }

    > .rkt-Stack__item--three-fifths\@8 {
      flex: 0 0 60%;
      max-width: 60%;
    }

    > .rkt-Stack__item--three-tenths\@8 {
      flex: 0 0 30%;
      max-width: 30%;
    }

    > .rkt-Stack__item--seven-tenths\@8 {
      flex: 0 0 70%;
      max-width: 70%;
    }

    > .rkt-Stack__item--two-fifths\@8 {
      flex: 0 0 40%;
      max-width: 40%;
    }
  }
}

/// When placed on a container, its children will go from a
/// stacked layout to a side by side layout at the
/// 8 split breakpoint.
.rkt-Stack--split\@8 {
  @media all and (min-width: $rkt-split-breakpoint-8) {
    // We want the last item to have zero margin on the right
    &.rkt-Stack--space-1 > .rkt-Stack__item:last-child,
    &.rkt-Stack--space-2 > .rkt-Stack__item:last-child,
    &.rkt-Stack--space-3 > .rkt-Stack__item:last-child,
    &.rkt-Stack--space-5 > .rkt-Stack__item:last-child,
    &.rkt-Stack--space-8 > .rkt-Stack__item:last-child,
    &.rkt-Stack--space-4 > .rkt-Stack__item:last-child,
    &.rkt-Stack--space-6 > .rkt-Stack__item:last-child,
    &.rkt-Stack--space-7 > .rkt-Stack__item:last-child,
    &.rkt-Stack--space-9 > .rkt-Stack__item:last-child {
      margin-right: 0;
    }
  }
}

//
// Split at 9 breakpoint
//
.rkt-Stack--split\@9,
.rkt-Stack--split-reverse\@9 {
  @media all and (min-width: $rkt-split-breakpoint-9) {
    // At 9 breakpoint we switch from column to row
    flex-direction: row;

    // Remove bottom margin in new row formation
    > .rkt-Stack__item {
      margin-bottom: 0;
    }

    // Add space between items based on space size class
    &.rkt-Stack--space-1 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-1;
    }

    &.rkt-Stack--space-2 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-2;
    }

    &.rkt-Stack--space-3 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-3;
    }

    &.rkt-Stack--space-5 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-5;
    }

    &.rkt-Stack--space-8 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-8;
    }

    /* stylelint-disable selector-class-pattern */
    &.rkt-Stack--space-4 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-4;
    }

    &.rkt-Stack--space-6 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-6;
    }

    &.rkt-Stack--space-7 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-7;
    }

    &.rkt-Stack--space-9 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-9;
    }

    /* stylelint-enable selector-class-pattern */
    > .rkt-Stack__item--sixth\@9 {
      flex: 0 0 16.666666667%;
      max-width: 16.666666667%;
    }

    > .rkt-Stack__item--fifth\@9 {
      flex: 0 0 20%;
      max-width: 20%;
    }

    > .rkt-Stack__item--fourth\@9 {
      flex: 0 0 25%;
      max-width: 25%;
    }

    > .rkt-Stack__item--third\@9 {
      flex: 0 0 33.333333333%;
      max-width: 33.333333333%;
    }

    > .rkt-Stack__item--half\@9 {
      flex: 0 0 50%;
      max-width: 50%;
    }

    > .rkt-Stack__item--three-fourths\@9 {
      flex: 0 0 75%;
      max-width: 75%;
    }

    > .rkt-Stack__item--three-fifths\@9 {
      flex: 0 0 60%;
      max-width: 60%;
    }

    > .rkt-Stack__item--three-tenths\@9 {
      flex: 0 0 30%;
      max-width: 30%;
    }

    > .rkt-Stack__item--seven-tenths\@9 {
      flex: 0 0 70%;
      max-width: 70%;
    }

    > .rkt-Stack__item--two-fifths\@9 {
      flex: 0 0 40%;
      max-width: 40%;
    }
  }
}

/// When placed on a container, its children will go from a
/// stacked layout to a side by side layout at the 9 split breakpoint.
.rkt-Stack--split\@9 {
  @media all and (min-width: $rkt-split-breakpoint-9) {
    // We want the last item to have zero margin on the right
    &.rkt-Stack--space-1 > .rkt-Stack__item:last-child,
    &.rkt-Stack--space-2 > .rkt-Stack__item:last-child,
    &.rkt-Stack--space-3 > .rkt-Stack__item:last-child,
    &.rkt-Stack--space-5 > .rkt-Stack__item:last-child,
    &.rkt-Stack--space-8 > .rkt-Stack__item:last-child,
    &.rkt-Stack--space-4 > .rkt-Stack__item:last-child,
    &.rkt-Stack--space-6 > .rkt-Stack__item:last-child,
    &.rkt-Stack--space-7 > .rkt-Stack__item:last-child,
    &.rkt-Stack--space-9 > .rkt-Stack__item:last-child {
      margin-right: 0;
    }
  }
}

//
// Split at 10 breakpoint
//
.rkt-Stack--split\@10,
.rkt-Stack--split-reverse\@10 {
  @media all and (min-width: $rkt-split-breakpoint-10) {
    // At 10 breakpoint we switch from column to row
    flex-direction: row;

    // Remove bottom margin in new row formation
    > .rkt-Stack__item {
      margin-bottom: 0;
    }

    // Add space between items based on space size class
    &.rkt-Stack--space-1 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-1;
    }

    &.rkt-Stack--space-2 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-2;
    }

    &.rkt-Stack--space-3 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-3;
    }

    &.rkt-Stack--space-5 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-5;
    }

    &.rkt-Stack--space-8 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-8;
    }

    /* stylelint-disable selector-class-pattern */
    &.rkt-Stack--space-4 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-4;
    }

    &.rkt-Stack--space-6 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-6;
    }

    &.rkt-Stack--space-7 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-7;
    }

    &.rkt-Stack--space-9 > .rkt-Stack__item {
      margin-right: $rkt-stack-space-9;
    }

    /* stylelint-enable selector-class-pattern */
    > .rkt-Stack__item--sixth\@10 {
      flex: 0 0 16.666666667%;
      max-width: 16.666666667%;
    }

    > .rkt-Stack__item--fifth\@10 {
      flex: 0 0 20%;
      max-width: 20%;
    }

    > .rkt-Stack__item--fourth\@10 {
      flex: 0 0 25%;
      max-width: 25%;
    }

    > .rkt-Stack__item--third\@10 {
      flex: 0 0 33.333333333%;
      max-width: 33.333333333%;
    }

    > .rkt-Stack__item--half\@10 {
      flex: 0 0 50%;
      max-width: 50%;
    }

    > .rkt-Stack__item--three-fourths\@10 {
      flex: 0 0 75%;
      max-width: 75%;
    }

    > .rkt-Stack__item--three-fifths\@10 {
      flex: 0 0 60%;
      max-width: 60%;
    }

    > .rkt-Stack__item--three-tenths\@10 {
      flex: 0 0 30%;
      max-width: 30%;
    }

    > .rkt-Stack__item--seven-tenths\@10 {
      flex: 0 0 70%;
      max-width: 70%;
    }

    > .rkt-Stack__item--two-fifths\@10 {
      flex: 0 0 40%;
      max-width: 40%;
    }
  }
}

/// When placed on a container, its children will go from a
/// stacked layout to a side by side layout at the extra
/// 5 split breakpoint.
.rkt-Stack--split\@10 {
  @media all and (min-width: $rkt-split-breakpoint-10) {
    // We want the last item to have zero margin on the right
    &.rkt-Stack--space-1 > .rkt-Stack__item:last-child,
    &.rkt-Stack--space-2 > .rkt-Stack__item:last-child,
    &.rkt-Stack--space-3 > .rkt-Stack__item:last-child,
    &.rkt-Stack--space-5 > .rkt-Stack__item:last-child,
    &.rkt-Stack--space-8 > .rkt-Stack__item:last-child,
    &.rkt-Stack--space-4 > .rkt-Stack__item:last-child,
    &.rkt-Stack--space-6 > .rkt-Stack__item:last-child,
    &.rkt-Stack--space-7 > .rkt-Stack__item:last-child,
    &.rkt-Stack--space-9 > .rkt-Stack__item:last-child {
      margin-right: 0;
    }
  }
}
/******
** END REPLACED WITH SCSS FUNCTION - Reference for search: #SPLITSPACING
*******/

///	When placed on an item, it will flex to take up the remaining space
/// in its container starting at the split breakpoint specified.
.rkt-Stack__item--flex {
  flex: 1;
}

.rkt-Stack__item--flex\@1 {
  @media all and (min-width: $rkt-split-breakpoint-1) {
    flex: 1;
  }
}

.rkt-Stack__item--flex\@2 {
  @media all and (min-width: $rkt-split-breakpoint-2) {
    flex: 1;
  }
}

.rkt-Stack__item--flex\@3 {
  @media all and (min-width: $rkt-split-breakpoint-3) {
    flex: 1;
  }
}

.rkt-Stack__item--flex\@4 {
  @media all and (min-width: $rkt-split-breakpoint-4) {
    flex: 1;
  }
}

.rkt-Stack__item--flex\@5 {
  @media all and (min-width: $rkt-split-breakpoint-5) {
    flex: 1;
  }
}

.rkt-Stack__item--flex\@6 {
  @media all and (min-width: $rkt-split-breakpoint-6) {
    flex: 1;
  }
}

.rkt-Stack__item--flex\@7 {
  @media all and (min-width: $rkt-split-breakpoint-7) {
    flex: 1;
  }
}

.rkt-Stack__item--flex\@8 {
  @media all and (min-width: $rkt-split-breakpoint-8) {
    flex: 1;
  }
}

.rkt-Stack__item--flex\@9 {
  @media all and (min-width: $rkt-split-breakpoint-9) {
    flex: 1;
  }
}

.rkt-Stack__item--flex\@10 {
  @media all and (min-width: $rkt-split-breakpoint-10) {
    flex: 1;
  }
}

/// When placed on a specific item, it will be aligned to the end
/// of the container when flex-direction is column. You can also add
/// a responsive suffix to have it start at a breakpoint that you specify
.rkt-Stack__item--end-column {
  align-self: flex-end;
}

.rkt-Stack__item--end-column\@1 {
  @media all and (min-width: $rkt-split-breakpoint-2) {
    align-self: flex-end;
  }
}

.rkt-Stack__item--end-column\@2 {
  @media all and (min-width: $rkt-split-breakpoint-2) {
    align-self: flex-end;
  }
}

.rkt-Stack__item--end-column\@3 {
  @media all and (min-width: $rkt-split-breakpoint-3) {
    align-self: flex-end;
  }
}

.rkt-Stack__item--end-column\@4 {
  @media all and (min-width: $rkt-split-breakpoint-4) {
    align-self: flex-end;
  }
}

.rkt-Stack__item--end-column\@5 {
  @media all and (min-width: $rkt-split-breakpoint-5) {
    align-self: flex-end;
  }
}

.rkt-Stack__item--end-column\@6 {
  @media all and (min-width: $rkt-split-breakpoint-6) {
    align-self: flex-end;
  }
}

.rkt-Stack__item--end-column\@7 {
  @media all and (min-width: $rkt-split-breakpoint-7) {
    align-self: flex-end;
  }
}

.rkt-Stack__item--end-column\@8 {
  @media all and (min-width: $rkt-split-breakpoint-8) {
    align-self: flex-end;
  }
}

.rkt-Stack__item--end-column\@9 {
  @media all and (min-width: $rkt-split-breakpoint-9) {
    align-self: flex-end;
  }
}

.rkt-Stack__item--end-column\@10 {
  @media all and (min-width: $rkt-split-breakpoint-10) {
    align-self: flex-end;
  }
}

/// When placed on a specific item, it will be aligned to the
/// start of the container when flex-direction is column. You can
/// also add a responsive suffix to have it start at a specified breakpoint
.rkt-Stack__item--start-column {
  align-self: flex-start;
}

.rkt-Stack__item--start-column\@1 {
  @media all and (min-width: $rkt-split-breakpoint-1) {
    align-self: flex-start;
  }
}

.rkt-Stack__item--start-column\@2 {
  @media all and (min-width: $rkt-split-breakpoint-2) {
    align-self: flex-start;
  }
}

.rkt-Stack__item--start-column\@3 {
  @media all and (min-width: $rkt-split-breakpoint-3) {
    align-self: flex-start;
  }
}

.rkt-Stack__item--start-column\@4 {
  @media all and (min-width: $rkt-split-breakpoint-4) {
    align-self: flex-start;
  }
}

.rkt-Stack__item--start-column\@5 {
  @media all and (min-width: $rkt-split-breakpoint-5) {
    align-self: flex-start;
  }
}

.rkt-Stack__item--start-column\@6 {
  @media all and (min-width: $rkt-split-breakpoint-6) {
    align-self: flex-start;
  }
}

.rkt-Stack__item--start-column\@7 {
  @media all and (min-width: $rkt-split-breakpoint-7) {
    align-self: flex-start;
  }
}

.rkt-Stack__item--start-column\@8 {
  @media all and (min-width: $rkt-split-breakpoint-8) {
    align-self: flex-start;
  }
}

.rkt-Stack__item--start-column\@9 {
  @media all and (min-width: $rkt-split-breakpoint-9) {
    align-self: flex-start;
  }
}

.rkt-Stack__item--start-column\@10 {
  @media all and (min-width: $rkt-split-breakpoint-10) {
    align-self: flex-start;
  }
}

/// When placed on a specific item, it will be centered
/// along the cross axis of the container. You can also add a
/// responsive suffix to have it start at a breakpoint that
/// you specify.
.rkt-Stack__item--center-column {
  align-self: center;
}

.rkt-Stack__item--center-column\@1 {
  @media all and (min-width: $rkt-split-breakpoint-1) {
    align-self: center;
  }
}

.rkt-Stack__item--center-column\@2 {
  @media all and (min-width: $rkt-split-breakpoint-2) {
    align-self: center;
  }
}

.rkt-Stack__item--center-column\@3 {
  @media all and (min-width: $rkt-split-breakpoint-3) {
    align-self: center;
  }
}

.rkt-Stack__item--center-column\@4 {
  @media all and (min-width: $rkt-split-breakpoint-4) {
    align-self: center;
  }
}

.rkt-Stack__item--center-column\@5 {
  @media all and (min-width: $rkt-split-breakpoint-5) {
    align-self: center;
  }
}

.rkt-Stack__item--center-column\@6 {
  @media all and (min-width: $rkt-split-breakpoint-6) {
    align-self: center;
  }
}

.rkt-Stack__item--center-column\@7 {
  @media all and (min-width: $rkt-split-breakpoint-7) {
    align-self: center;
  }
}

.rkt-Stack__item--center-column\@8 {
  @media all and (min-width: $rkt-split-breakpoint-8) {
    align-self: center;
  }
}

.rkt-Stack__item--center-column\@9 {
  @media all and (min-width: $rkt-split-breakpoint-9) {
    align-self: center;
  }
}

.rkt-Stack__item--center-column\@10 {
  @media all and (min-width: $rkt-split-breakpoint-10) {
    align-self: center;
  }
}

/******
** START REPLACED PARTS WITH SCSS FUNCTION
** Search Reference: #SplitReverse
*******/
// @deprecated "space" in v5, task #571764. Removal in v7.
/// When placed on a container, its children's position in
/// the row will be reversed at the 1 split breakpoint.
.rkt-Stack--split-reverse\@1 {
  @media all and (min-width: $rkt-split-breakpoint-1) {
    flex-direction: row-reverse;

    // We want the last positioned item to have zero margin on the right
    &.rkt-Stack--space-1 > .rkt-Stack__item:first-child,
    &.rkt-Stack--space-2 > .rkt-Stack__item:first-child,
    &.rkt-Stack--space-3 > .rkt-Stack__item:first-child,
    &.rkt-Stack--space-5 > .rkt-Stack__item:first-child,
    &.rkt-Stack--space-8 > .rkt-Stack__item:first-child,
    &.rkt-Stack--space-4 > .rkt-Stack__item:first-child,
    &.rkt-Stack--space-6 > .rkt-Stack__item:first-child,
    &.rkt-Stack--space-7 > .rkt-Stack__item:first-child,
    &.rkt-Stack--space-9 > .rkt-Stack__item:first-child {
      margin-right: 0;
    }
  }
}

/// When placed on a container, its children's position in
/// the row will be reversed at the 2 split breakpoint.
.rkt-Stack--split-reverse\@2 {
  @media all and (min-width: $rkt-split-breakpoint-2) {
    flex-direction: row-reverse;

    // We want the last positioned item to have zero margin on the right
    &.rkt-Stack--space-1 > .rkt-Stack__item:first-child,
    &.rkt-Stack--space-2 > .rkt-Stack__item:first-child,
    &.rkt-Stack--space-3 > .rkt-Stack__item:first-child,
    &.rkt-Stack--space-5 > .rkt-Stack__item:first-child,
    &.rkt-Stack--space-8 > .rkt-Stack__item:first-child,
    &.rkt-Stack--space-4 > .rkt-Stack__item:first-child,
    &.rkt-Stack--space-6 > .rkt-Stack__item:first-child,
    &.rkt-Stack--space-7 > .rkt-Stack__item:first-child,
    &.rkt-Stack--space-9 > .rkt-Stack__item:first-child {
      margin-right: 0;
    }
  }
}

/// When placed on a container, its children's position in
/// the row will be reversed at the 3 split breakpoint.
.rkt-Stack--split-reverse\@3 {
  @media all and (min-width: $rkt-split-breakpoint-3) {
    flex-direction: row-reverse;

    // We want the last positioned item to have zero margin on the right
    &.rkt-Stack--space-1 > .rkt-Stack__item:first-child,
    &.rkt-Stack--space-2 > .rkt-Stack__item:first-child,
    &.rkt-Stack--space-3 > .rkt-Stack__item:first-child,
    &.rkt-Stack--space-5 > .rkt-Stack__item:first-child,
    &.rkt-Stack--space-8 > .rkt-Stack__item:first-child,
    &.rkt-Stack--space-4 > .rkt-Stack__item:first-child,
    &.rkt-Stack--space-6 > .rkt-Stack__item:first-child,
    &.rkt-Stack--space-7 > .rkt-Stack__item:first-child,
    &.rkt-Stack--space-9 > .rkt-Stack__item:first-child {
      margin-right: 0;
    }
  }
}

/// When placed on a container, its children's position in
/// the row will be reversed at the 34 split breakpoint.
.rkt-Stack--split-reverse\@4 {
  @media all and (min-width: $rkt-split-breakpoint-4) {
    flex-direction: row-reverse;

    // We want the last positioned item to have zero margin on the right
    &.rkt-Stack--space-1 > .rkt-Stack__item:first-child,
    &.rkt-Stack--space-2 > .rkt-Stack__item:first-child,
    &.rkt-Stack--space-3 > .rkt-Stack__item:first-child,
    &.rkt-Stack--space-5 > .rkt-Stack__item:first-child,
    &.rkt-Stack--space-8 > .rkt-Stack__item:first-child,
    &.rkt-Stack--space-4 > .rkt-Stack__item:first-child,
    &.rkt-Stack--space-6 > .rkt-Stack__item:first-child,
    &.rkt-Stack--space-7 > .rkt-Stack__item:first-child,
    &.rkt-Stack--space-9 > .rkt-Stack__item:first-child {
      margin-right: 0;
    }
  }
}

/// When placed on a container, its children's position in
/// the row will be reversed at the 5 split breakpoint.
.rkt-Stack--split-reverse\@5 {
  @media all and (min-width: $rkt-split-breakpoint-5) {
    flex-direction: row-reverse;

    // We want the last positioned item to have zero margin on the right
    &.rkt-Stack--space-1 > .rkt-Stack__item:first-child,
    &.rkt-Stack--space-2 > .rkt-Stack__item:first-child,
    &.rkt-Stack--space-3 > .rkt-Stack__item:first-child,
    &.rkt-Stack--space-5 > .rkt-Stack__item:first-child,
    &.rkt-Stack--space-8 > .rkt-Stack__item:first-child,
    &.rkt-Stack--space-4 > .rkt-Stack__item:first-child,
    &.rkt-Stack--space-6 > .rkt-Stack__item:first-child,
    &.rkt-Stack--space-7 > .rkt-Stack__item:first-child,
    &.rkt-Stack--space-9 > .rkt-Stack__item:first-child {
      margin-right: 0;
    }
  }
}

/// When placed on a container, its children's position in
/// the row will be reversed at the extra 6 split breakpoint.
.rkt-Stack--split-reverse\@6 {
  @media all and (min-width: $rkt-split-breakpoint-6) {
    flex-direction: row-reverse;

    // We want the last positioned item to have zero margin on the right
    &.rkt-Stack--space-1 > .rkt-Stack__item:first-child,
    &.rkt-Stack--space-2 > .rkt-Stack__item:first-child,
    &.rkt-Stack--space-3 > .rkt-Stack__item:first-child,
    &.rkt-Stack--space-5 > .rkt-Stack__item:first-child,
    &.rkt-Stack--space-8 > .rkt-Stack__item:first-child,
    &.rkt-Stack--space-4 > .rkt-Stack__item:first-child,
    &.rkt-Stack--space-6 > .rkt-Stack__item:first-child,
    &.rkt-Stack--space-7 > .rkt-Stack__item:first-child,
    &.rkt-Stack--space-9 > .rkt-Stack__item:first-child {
      margin-right: 0;
    }
  }
}

/// When placed on a container, its children's position in
/// the row will be reversed at the extra 7 split breakpoint.
.rkt-Stack--split-reverse\@7 {
  @media all and (min-width: $rkt-split-breakpoint-7) {
    flex-direction: row-reverse;

    // We want the last positioned item to have zero margin on the right
    &.rkt-Stack--space-1 > .rkt-Stack__item:first-child,
    &.rkt-Stack--space-2 > .rkt-Stack__item:first-child,
    &.rkt-Stack--space-3 > .rkt-Stack__item:first-child,
    &.rkt-Stack--space-5 > .rkt-Stack__item:first-child,
    &.rkt-Stack--space-8 > .rkt-Stack__item:first-child,
    &.rkt-Stack--space-4 > .rkt-Stack__item:first-child,
    &.rkt-Stack--space-6 > .rkt-Stack__item:first-child,
    &.rkt-Stack--space-7 > .rkt-Stack__item:first-child,
    &.rkt-Stack--space-9 > .rkt-Stack__item:first-child {
      margin-right: 0;
    }
  }
}

/// When placed on a container, its children's position in
/// the row will be reversed at the extra 8 split breakpoint.
.rkt-Stack--split-reverse\@8 {
  @media all and (min-width: $rkt-split-breakpoint-8) {
    flex-direction: row-reverse;

    // We want the last positioned item to have zero margin on the right
    &.rkt-Stack--space-1 > .rkt-Stack__item:first-child,
    &.rkt-Stack--space-2 > .rkt-Stack__item:first-child,
    &.rkt-Stack--space-3 > .rkt-Stack__item:first-child,
    &.rkt-Stack--space-5 > .rkt-Stack__item:first-child,
    &.rkt-Stack--space-8 > .rkt-Stack__item:first-child,
    &.rkt-Stack--space-4 > .rkt-Stack__item:first-child,
    &.rkt-Stack--space-6 > .rkt-Stack__item:first-child,
    &.rkt-Stack--space-7 > .rkt-Stack__item:first-child,
    &.rkt-Stack--space-9 > .rkt-Stack__item:first-child {
      margin-right: 0;
    }
  }
}

/// When placed on a container, its children's position in
/// the row will be reversed at the extra 9 split breakpoint.
.rkt-Stack--split-reverse\@9 {
  @media all and (min-width: $rkt-split-breakpoint-9) {
    flex-direction: row-reverse;

    // We want the last positioned item to have zero margin on the right
    &.rkt-Stack--space-1 > .rkt-Stack__item:first-child,
    &.rkt-Stack--space-2 > .rkt-Stack__item:first-child,
    &.rkt-Stack--space-3 > .rkt-Stack__item:first-child,
    &.rkt-Stack--space-5 > .rkt-Stack__item:first-child,
    &.rkt-Stack--space-8 > .rkt-Stack__item:first-child,
    &.rkt-Stack--space-4 > .rkt-Stack__item:first-child,
    &.rkt-Stack--space-6 > .rkt-Stack__item:first-child,
    &.rkt-Stack--space-7 > .rkt-Stack__item:first-child,
    &.rkt-Stack--space-9 > .rkt-Stack__item:first-child {
      margin-right: 0;
    }
  }
}

/// When placed on a container, its children's position in
/// the row will be reversed at the extra 10 split breakpoint.
.rkt-Stack--split-reverse\@10 {
  @media all and (min-width: $rkt-split-breakpoint-10) {
    flex-direction: row-reverse;

    // We want the last positioned item to have zero margin on the right
    &.rkt-Stack--space-1 > .rkt-Stack__item:first-child,
    &.rkt-Stack--space-2 > .rkt-Stack__item:first-child,
    &.rkt-Stack--space-3 > .rkt-Stack__item:first-child,
    &.rkt-Stack--space-5 > .rkt-Stack__item:first-child,
    &.rkt-Stack--space-8 > .rkt-Stack__item:first-child,
    &.rkt-Stack--space-4 > .rkt-Stack__item:first-child,
    &.rkt-Stack--space-6 > .rkt-Stack__item:first-child,
    &.rkt-Stack--space-7 > .rkt-Stack__item:first-child,
    &.rkt-Stack--space-9 > .rkt-Stack__item:first-child {
      margin-right: 0;
    }
  }
}
/******
** END REPLACED PARTS WITH SCSS FUNCTION
** Search Reference: #SplitReverse
*******/

/***
* START NEW SPACING CLASSES V5
**/
$rkt-stack-spacing: (
  '4': $rkt-stack-spacing-4,
  '8': $rkt-stack-spacing-8,
  '12': $rkt-stack-spacing-12,
  '16': $rkt-stack-spacing-16,
  '20': $rkt-stack-spacing-20,
  '24': $rkt-stack-spacing-24,
  '28': $rkt-stack-spacing-28,
  '32': $rkt-stack-spacing-32,
  '36': $rkt-stack-spacing-36,
  '40': $rkt-stack-spacing-40,
  '44': $rkt-stack-spacing-44,
  '48': $rkt-stack-spacing-48,
  '52': $rkt-stack-spacing-52,
  '56': $rkt-stack-spacing-56,
  '60': $rkt-stack-spacing-60,
  '64': $rkt-stack-spacing-64,
  '68': $rkt-stack-spacing-68,
  '72': $rkt-stack-spacing-72,
  '76': $rkt-stack-spacing-76,
  '80': $rkt-stack-spacing-80,
);

$rkt-stack-breakpoints: (
  '0': $rkt-split-breakpoint-0,
  '1': $rkt-split-breakpoint-1,
  '2': $rkt-split-breakpoint-2,
  '3': $rkt-split-breakpoint-3,
  '4': $rkt-split-breakpoint-4,
  '5': $rkt-split-breakpoint-5,
  '6': $rkt-split-breakpoint-6,
  '7': $rkt-split-breakpoint-7,
  '8': $rkt-split-breakpoint-8,
  '9': $rkt-split-breakpoint-9,
  '10': $rkt-split-breakpoint-10,
);

%rkt-stack-margin-bottom-0 {
  margin-bottom: 0;
}

// Search Reference: #SpaceBetweenItems
// Spacing between stack items.
@each $spacing, $value in $rkt-stack-spacing {
  .rkt-Stack--spacing-#{$spacing} > .rkt-Stack__item,
  rkt-stack[isstackitem='true'][spacing='#{$spacing}'] {
    margin-bottom: #{$value};
  }

  // Last item will not have a space.
  .rkt-Stack--spacing-#{$spacing} > .rkt-Stack__item:last-child,
  .rkt-Stack > rkt-stack[isstackitem='true'][spacing='#{$spacing}']:last-child {
    @extend %rkt-stack-margin-bottom-0;
  }
}

// Handles spacing class for when stack items split
// Generates classes for every spacing and breakpoint value
// Replaces `.rkt-Stack--split\@1, .rkt-Stack--split-reverse\@1` selectors that begin around line 459
// Search Reference: #SplitSpacing

// Here separately so it's not duplicated with above deprecated code
// and the scss functions
.rkt-Stack--split\@0 {
  flex-direction: row;
}

@each $breakpoint, $breakpoint-value in $rkt-stack-breakpoints {
  .rkt-Stack--split\@#{$breakpoint},
  .rkt-Stack--split-reverse\@#{$breakpoint} {
    @media all and (min-width: #{$breakpoint-value}) {
      // Necessary duplication for CSS cascade
      > .rkt-Stack__item {
        margin-bottom: 0;
      }

      // Iterate over spacing values within this breakpoint list
      @each $spacing, $spacing-value in $rkt-stack-spacing {
        // Adds right margin for stack items, as long as it's not the last child
        &.rkt-Stack--spacing-#{$spacing} > .rkt-Stack__item {
          margin-right: $spacing-value;
        }
      }
    }
  }
}

@each $breakpoint, $breakpoint-value in $rkt-stack-breakpoints {
  .rkt-Stack--split\@#{$breakpoint} {
    @media all and (min-width: #{$breakpoint-value}) {
      // Iterate over spacing values within this breakpoint list
      @each $spacing, $spacing-value in $rkt-stack-spacing {
        // Adds right margin for stack items for last child
        &.rkt-Stack--spacing-#{$spacing} > .rkt-Stack__item:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

// Removes margin right on first child on first-child if reversed
// Still needs other static classes when deprecated classes are removed. #571764. Removal in v7.
// Cannot use @extends because it's not allowed in media-queries.
// Search Reference: #SplitReverse

// @0 Added to allow stack to always be split
.rkt-Stack--split-reverse\@0 {
  flex-direction: row-reverse;

  &.rkt-Stack--spacing-4 > .rkt-Stack__item:first-child,
  &.rkt-Stack--spacing-8 > .rkt-Stack__item:first-child,
  &.rkt-Stack--spacing-12 > .rkt-Stack__item:first-child,
  &.rkt-Stack--spacing-16 > .rkt-Stack__item:first-child,
  &.rkt-Stack--spacing-20 > .rkt-Stack__item:first-child,
  &.rkt-Stack--spacing-24 > .rkt-Stack__item:first-child,
  &.rkt-Stack--spacing-28 > .rkt-Stack__item:first-child,
  &.rkt-Stack--spacing-32 > .rkt-Stack__item:first-child,
  &.rkt-Stack--spacing-36 > .rkt-Stack__item:first-child,
  &.rkt-Stack--spacing-40 > .rkt-Stack__item:first-child,
  &.rkt-Stack--spacing-44 > .rkt-Stack__item:first-child,
  &.rkt-Stack--spacing-48 > .rkt-Stack__item:first-child,
  &.rkt-Stack--spacing-52 > .rkt-Stack__item:first-child,
  &.rkt-Stack--spacing-56 > .rkt-Stack__item:first-child,
  &.rkt-Stack--spacing-60 > .rkt-Stack__item:first-child,
  &.rkt-Stack--spacing-64 > .rkt-Stack__item:first-child,
  &.rkt-Stack--spacing-68 > .rkt-Stack__item:first-child,
  &.rkt-Stack--spacing-72 > .rkt-Stack__item:first-child,
  &.rkt-Stack--spacing-76 > .rkt-Stack__item:first-child,
  &.rkt-Stack--spacing-80 > .rkt-Stack__item:first-child {
    margin-right: 0;
  }
}

.rkt-Stack--split-reverse\@1 {
  @media all and (min-width: $rkt-split-breakpoint-1) {
    // We want the last positioned item to have zero margin on the right
    &.rkt-Stack--spacing-4 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-8 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-12 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-16 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-20 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-24 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-28 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-32 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-36 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-40 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-44 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-48 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-52 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-56 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-60 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-64 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-68 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-72 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-76 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-80 > .rkt-Stack__item:first-child {
      margin-right: 0;
    }
  }
}

.rkt-Stack--split-reverse\@2 {
  @media all and (min-width: $rkt-split-breakpoint-2) {
    // We want the last positioned item to have zero margin on the right
    &.rkt-Stack--spacing-4 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-8 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-12 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-16 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-20 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-24 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-28 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-32 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-36 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-40 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-44 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-48 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-52 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-56 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-60 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-64 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-68 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-72 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-76 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-80 > .rkt-Stack__item:first-child {
      margin-right: 0;
    }
  }
}

.rkt-Stack--split-reverse\@3 {
  @media all and (min-width: $rkt-split-breakpoint-3) {
    // We want the last positioned item to have zero margin on the right
    &.rkt-Stack--spacing-4 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-8 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-12 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-16 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-20 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-24 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-28 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-32 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-36 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-40 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-44 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-48 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-52 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-56 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-60 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-64 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-68 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-72 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-76 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-80 > .rkt-Stack__item:first-child {
      margin-right: 0;
    }
  }
}

.rkt-Stack--split-reverse\@4 {
  @media all and (min-width: $rkt-split-breakpoint-4) {
    // We want the last positioned item to have zero margin on the right
    &.rkt-Stack--spacing-4 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-8 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-12 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-16 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-20 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-24 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-28 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-32 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-36 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-40 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-44 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-48 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-52 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-56 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-60 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-64 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-68 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-72 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-76 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-80 > .rkt-Stack__item:first-child {
      margin-right: 0;
    }
  }
}

.rkt-Stack--split-reverse\@5 {
  @media all and (min-width: $rkt-split-breakpoint-5) {
    // We want the last positioned item to have zero margin on the right
    &.rkt-Stack--spacing-4 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-8 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-12 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-16 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-20 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-24 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-28 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-32 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-36 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-40 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-44 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-48 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-52 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-56 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-60 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-64 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-68 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-72 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-76 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-80 > .rkt-Stack__item:first-child {
      margin-right: 0;
    }
  }
}

.rkt-Stack--split-reverse\@6 {
  @media all and (min-width: $rkt-split-breakpoint-6) {
    // We want the last positioned item to have zero margin on the right
    &.rkt-Stack--spacing-4 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-8 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-12 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-16 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-20 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-24 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-28 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-32 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-36 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-40 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-44 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-48 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-52 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-56 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-60 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-64 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-68 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-72 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-76 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-80 > .rkt-Stack__item:first-child {
      margin-right: 0;
    }
  }
}

.rkt-Stack--split-reverse\@7 {
  @media all and (min-width: $rkt-split-breakpoint-7) {
    // We want the last positioned item to have zero margin on the right
    &.rkt-Stack--spacing-4 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-8 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-12 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-16 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-20 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-24 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-28 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-32 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-36 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-40 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-44 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-48 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-52 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-56 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-60 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-64 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-68 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-72 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-76 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-80 > .rkt-Stack__item:first-child {
      margin-right: 0;
    }
  }
}

.rkt-Stack--split-reverse\@8 {
  @media all and (min-width: $rkt-split-breakpoint-8) {
    // We want the last positioned item to have zero margin on the right
    &.rkt-Stack--spacing-4 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-8 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-12 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-16 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-20 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-24 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-28 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-32 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-36 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-40 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-44 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-48 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-52 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-56 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-60 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-64 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-68 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-72 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-76 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-80 > .rkt-Stack__item:first-child {
      margin-right: 0;
    }
  }
}

.rkt-Stack--split-reverse\@9 {
  @media all and (min-width: $rkt-split-breakpoint-9) {
    // We want the last positioned item to have zero margin on the right
    &.rkt-Stack--spacing-4 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-8 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-12 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-16 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-20 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-24 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-28 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-32 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-36 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-40 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-44 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-48 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-52 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-56 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-60 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-64 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-68 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-72 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-76 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-80 > .rkt-Stack__item:first-child {
      margin-right: 0;
    }
  }
}

.rkt-Stack--split-reverse\@10 {
  @media all and (min-width: $rkt-split-breakpoint-10) {
    // We want the last positioned item to have zero margin on the right
    &.rkt-Stack--spacing-4 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-8 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-12 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-16 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-20 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-24 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-28 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-32 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-36 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-40 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-44 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-48 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-52 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-56 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-60 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-64 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-68 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-72 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-76 > .rkt-Stack__item:first-child,
    &.rkt-Stack--spacing-80 > .rkt-Stack__item:first-child {
      margin-right: 0;
    }
  }
}

/***
* END NEW SPACING CLASSES V5
**/
