@use '../web/scss/settings' as *;

.rkt-Alert {
  align-items: $rkt-alert-align-items;
  background-color: $rkt-alert-bg-color;
  border: $rkt-alert-border;
  box-shadow: $rkt-alert-box-shadow;
  box-sizing: $rkt-alert-box-sizing;
  border-radius: $rkt-alert-border-radius;
  display: $rkt-alert-display;
  flex-direction: $rkt-alert-flex-direction;
  padding: $rkt-alert-padding;
  font-family: $rkt-alert-font-family;
  max-width: $rkt-alert-max-width;

  @media only screen and (min-width: $rkt-alert-medium-breakpoint) {
    max-width: $rkt-alert-medium-breakpoint-max-width;
    padding: $rkt-alert-medium-breakpoint-padding;
  }

  @media only screen and (min-width: $rkt-alert-large-breakpoint) {
    max-width: $rkt-alert-large-breakpoint-max-width;
    padding: $rkt-alert-large-breakpoint-padding;
  }
}

// Styles the full width alert
.rkt-Alert.rkt-Alert--full-width {
  max-width: $rkt-alert-full-width-max-width;
}

// MUI alerts are built into MUI and need this
.rkt-Alert.MuiAlert-root {
  flex-direction: $rkt-alert-mui-flex-direction;
}

.rkt-Alert--is-hidden {
  display: $rkt-alert-display-hidden;
}

/// Styles the alert as an information alert.
.rkt-Alert--info {
  background-color: $rkt-alert-bg-color-info;
  border: $rkt-alert-border-info;
  color: $rkt-alert-text-color-info;

  // Deprecated in v4, remove in v6. task #513129
  .rkt-Alert__icon:first-child, .rkt-Alert__icon.rkt-Alert__icon--indicator {
    color: $rkt-alert-icon-color-info;
  }

  .rkt-Alert__text {
    color: $rkt-alert-text-color-info;
  }
}

/// Styles the alert as a success alert.
.rkt-Alert--success {
  background-color: $rkt-alert-bg-color-success;
  border: $rkt-alert-border-success;
  color: $rkt-alert-text-color-success;

  // Deprecated in v4 remove in v6. task #513129
  .rkt-Alert__icon:first-child,
  .rkt-Alert__icon.rkt-Alert__icon--indicator {
    color: $rkt-alert-icon-color-success;
  }

  .rkt-Alert__text {
    color: $rkt-alert-text-color-success;
  }
}

/// Styles the alert as a warn alert.
.rkt-Alert--warn {
  background-color: $rkt-alert-bg-color-warn;
  border: $rkt-alert-border-warn;
  color: $rkt-alert-text-color-warn;

  // Deprecated in v4 remove in v6. task #513129
  .rkt-Alert__icon:first-child,
  .rkt-Alert__icon.rkt-Alert__icon--indicator {
    color: $rkt-alert-icon-color-warn;
  }

  .rkt-Alert__text {
    color: $rkt-alert-text-color-warn;
  }
}

.rkt-Alert__content {
  box-sizing: border-box;
  display: flex;
  width: 100%;
  // Deprecated in v6 and change to 0, task #718031. Removal in v8, task #719270.
  padding-right: $rkt-alert-content-padding;
}

.rkt-Alert__text {
  align-items: $rkt-alert-text-align-items;
  color: $rkt-alert-color;
  font-size: $rkt-alert-font-size;
  font-weight: $rkt-alert-font-weight;
  line-height: $rkt-alert-line-height;
  margin: $rkt-alert-text-margin;

  @media only screen and (min-width: $rkt-alert-medium-breakpoint) {
    font-size: $rkt-alert-font-size-medium-breakpoint;
    line-height: $rkt-alert-medium-breakpoint-line-height;
  }

  @media only screen and (min-width: $rkt-alert-large-breakpoint) {
    font-size: $rkt-alert-font-size-large-breakpoint;
    line-height: $rkt-alert-large-breakpoint-line-height;
  }
}

.rkt-Alert__content,
.rkt-Alert__text {
  flex: $rkt-alert-text-flex;
}

.rkt-Alert__icon {
  align-self: $rkt-alert-icon-align-self;
  flex: $rkt-alert-icon-flex;

  &:first-child {
    margin: $rkt-alert-icon-margin;

    @media only screen and (min-width: $rkt-alert-medium-breakpoint) {
      margin: $rkt-alert-icon-medium-breakpoint-margin;
    }

    @media only screen and (min-width: $rkt-alert-large-breakpoint) {
      margin: $rkt-alert-icon-large-breakpoint-margin;
    }
  }
}

.rkt-Alert .rkt-Alert__icon {
  height: $rkt-alert-icon-height;
  width: $rkt-alert-icon-width;
}

/// Adds specific styles to the icon
/// that is used for closing the alert.
.rkt-Alert__icon-button {
  align-self: $rkt-alert-dismiss-button-align-self;
  box-sizing: $rkt-alert-dismiss-button-box-sizing;
  cursor: $rkt-alert-dismiss-button-cursor;
  display: $rkt-alert-dismiss-button-display;
  flex: $rkt-alert-dismiss-button-flex;
  background-color: $rkt-alert-dismiss-button-background-color;
  border: $rkt-alert-dismiss-button-border;
  padding: $rkt-alert-dismiss-button-padding;
  margin: $rkt-alert-dismiss-container-margin;
  border-radius: $rkt-alert-dismiss-button-border-radius;

  &:hover {
    background-color: $rkt-alert-dismiss-button-hover-background-color;
  }

  &:active {
    background-color: $rkt-alert-dismiss-button-active-background-color;
  }

  &:focus-visible {
    background-color: $rkt-alert-dismiss-button-focus-background-color;
    outline: $rkt-alert-dismiss-button-focus-outline;
  }

  &:-ms-keyboard-active {
    background-color: $rkt-alert-dismiss-button-focus-background-color;
    outline: $rkt-alert-dismiss-button-focus-outline;
  }

  @media only screen and (min-width: $rkt-alert-medium-breakpoint) {
    margin: $rkt-alert-dismiss-medium-breakpoint-container-margin;
  }

  @media only screen and (min-width: $rkt-alert-large-breakpoint) {
    margin: $rkt-alert-dismiss-large-breakpoint-container-margin;
  }
}

.rkt-Alert__icon.rkt-Alert__icon--dismiss:first-child {
  height: $rkt-alert-dismiss-icon-height;
  width: $rkt-alert-dismiss-icon-width;
  color: $rkt-alert-dismiss-icon-color;
  margin: $rkt-alert-dismiss-icon-margin;
}

.rkt-Alert .MuiAlert-action {
  height: $rkt-alert-dismiss-container-height;
  padding: $rkt-alert-dismiss-container-padding;
  margin: $rkt-alert-dismiss-container-margin;
  width: $rkt-alert-dismiss-container-width;
  display: $rkt-alert-dismiss-container-display;
  justify-content: $rkt-alert-dismiss-container-justify-content;
  align-self: $rkt-alert-dismiss-button-align-self;

  @media only screen and (min-width: $rkt-alert-medium-breakpoint) {
    margin: $rkt-alert-dismiss-medium-breakpoint-container-margin;
  }

  @media only screen and (min-width: $rkt-alert-large-breakpoint) {
    margin: $rkt-alert-dismiss-large-breakpoint-container-margin;
  }
}

.rkt-Alert .rkt-ButtonIcon.MuiButtonBase-root.MuiIconButton-root {
  padding: $rkt-alert-dismiss-padding;
  background-color: $rkt-alert-dismiss-button-background-color;
  border: $rkt-alert-dismiss-button-border;
  border-radius: $rkt-alert-dismiss-button-border-radius;
  height: $rkt-alert-dismiss-icon-height;
  width: $rkt-alert-dismiss-icon-width;
  color: $rkt-alert-dismiss-icon-color;

  &:hover {
    background-color: $rkt-alert-dismiss-button-hover-background-color;
  }

  &:active {
    background-color: $rkt-alert-dismiss-button-active-background-color;
  }

  &:focus-visible {
    background-color: $rkt-alert-dismiss-button-focus-background-color;
    outline: $rkt-alert-dismiss-button-focus-outline;
  }

  &:-ms-keyboard-active {
    background-color: $rkt-alert-dismiss-button-focus-background-color;
    outline: $rkt-alert-dismiss-button-focus-outline;
  }

  &::after {
    display: $rkt-alert-action-button-icon-display;
  }
}

.rkt-Alert .MuiTouchRipple-root {
  display: $rkt-alert-display-hidden;
}

.rkt-Alert .MuiAlert-message {
  width: $rkt-alert-text-width;
}

.rkt-Alert .MuiSvgIcon-root {
  width: $rkt-alert-dismiss-icon-width;
  height: $rkt-alert-dismiss-icon-height;
}

.rkt-Alert .MuiAlert-icon {
  align-self: $rkt-alert-icon-align-self;
}

.rkt-Alert__heading {
  color: $rkt-alert-heading-color;
  font-size: $rkt-alert-heading-font-size;
  font-family: $rkt-alert-heading-font-family;
  font-weight: $rkt-alert-heading-font-weight;
  line-height: $rkt-alert-heading-line-height;
  margin: $rkt-alert-heading-margin;

  @media only screen and (min-width: $rkt-alert-medium-breakpoint) {
    font-size: $rkt-alert-heading-medium-breakpoint-font-size;
  }

  @media only screen and (min-width: $rkt-alert-large-breakpoint) {
    font-size: $rkt-alert-heading-large-breakpoint-font-size;
  }
}

.rkt-Alert__button-group {
  margin: $rkt-alert-action-button-group-margin;
  align-self: $rkt-alert-action-button-group-align-self;

  @media only screen and (min-width: $rkt-alert-medium-breakpoint) {
    margin: $rkt-alert-action-button-group-medium-breakpoint-margin;
  }

  @media only screen and (min-width: $rkt-alert-large-breakpoint) {
    margin: $rkt-alert-action-button-group-large-breakpoint-margin;
  }

  .rkt-Button,
  .rkt-Button.mat-mdc-button-base {
    padding: $rkt-alert-action-button-padding;
    line-height: $rkt-alert-action-button-line-height;
  }

  .mdc-button {
    display: $rkt-alert-action-button-display;
  }
}