@use '../web/scss/settings';

.rkt-HugeInputContainer {
  max-width: settings.$rkt-huge-input-container-max-width;
  width: settings.$rkt-huge-input-container-width;
}

.rkt-RadioHuge {
  background-color: settings.$rkt-huge-radio-background-color;
  max-width: settings.$rkt-huge-radio-max-width;
  min-height: settings.$rkt-huge-radio-min-height;
  display: settings.$rkt-huge-radio-display;
  transition: settings.$rkt-huge-radio-transition;
  border-radius: settings.$rkt-huge-radio-border-radius;
  flex-basis: settings.$rkt-huge-radio-flex-basis;
  line-height: settings.$rkt-huge-radio-line-height;
  font-family: settings.$rkt-huge-radio-font-family;
  font-size: settings.$rkt-huge-radio-font-size;
  box-shadow: settings.$rkt-huge-radio-box-shadow;
  box-sizing: border-box;
  width: settings.$rkt-huge-radio-width;

  // If long label, prevents icon from shrinking
  .mat-icon,
  .rkt-Icon {
    flex-shrink: settings.$rkt-huge-radio-icon-flex-shrink;
  }

  &:active,
  &:focus,
  &:hover {
    box-shadow: settings.$rkt-huge-radio-hover-box-shadow;
    background-color: settings.$rkt-huge-radio-hover-background-color;
  }

  &.rkt-RadioHuge--is-selected {
    background-color: settings.$rkt-huge-radio-selected-background-color;
    box-shadow: settings.$rkt-huge-radio-selected-box-shadow;
  }

  &:has(:focus-visible) {
    box-shadow: settings.$rkt-huge-radio-keyboard-focus-box-shadow;
  }

  @media screen and (min-width: settings.$rkt-huge-radio-large-breakpoint) {
    font-size: settings.$rkt-huge-radio-large-breakpoint-font-size;
    min-height: settings.$rkt-huge-radio-large-breakpoint-min-height;
  }
}

// resting radio fill background
.rkt-RadioHuge .rkt-Radio:not(.Mui-focusVisible).MuiButtonBase-root {
  background: settings.$rkt-huge-radio-radio-background;
}

// hover radio background
.rkt-RadioHuge .rkt-Radio:not(.Mui-focusVisible):hover.MuiButtonBase-root {
  background: settings.$rkt-huge-radio-radio-hover-background;
}

// selected radio circle fill
.rkt-RadioHuge.rkt-RadioHuge--is-selected .rkt-Radio.MuiButtonBase-root svg rect {
  fill: settings.$rkt-huge-radio-radio-selected-fill;
}

.rkt-RadioHuge.rkt-RadioHuge--is-disabled .rkt-Radio.MuiButtonBase-root svg g path {
  stroke: settings.$rkt-huge-radio-disabled-stroke;
}

.rkt-RadioHuge__content {
  color: settings.$rkt-huge-radio-content-color;
  font-size: settings.$rkt-huge-radio-content-font-size;
  font-weight: settings.$rkt-huge-radio-content-font-weight;
  line-height: settings.$rkt-huge-radio-content-line-height;
  font-family: settings.$rkt-huge-radio-content-font-family;
  margin: settings.$rkt-huge-radio-content-margin;
}

.rkt-RadioHuge__label {
  box-sizing: border-box;
  flex: settings.$rkt-huge-radio-flex-input;
  display: settings.$rkt-huge-radio-display-input;
  align-items: settings.$rkt-huge-radio-align-items-input;
  padding: settings.$rkt-huge-radio-padding-input;
  width: settings.$rkt-huge-radio-width-input;
  margin: settings.$rkt-huge-radio-margin;
}

// angular radio button
.rkt-RadioHuge .rkt-Radio .mdc-form-field .mdc-radio,
.rkt-RadioHuge .rkt-Radio .mdc-form-field.mdc-form-field--align-end .mdc-radio {
  align-self: settings.$rkt-huge-radio-radio-align-self;
  padding: settings.$rkt-huge-radio-radio-padding;

  @media screen and (min-width: settings.$rkt-huge-radio-large-breakpoint) {
    padding: settings.$rkt-huge-radio-large-breakpoint-radio-padding;
  }
}

// react label
.rkt-RadioHuge__label.MuiFormControlLabel-root {
  padding: settings.$rkt-huge-radio-react-label-padding;

  .rkt-Radio {
    padding: settings.$rkt-huge-radio-input-padding;
  }

  .rkt-Radio:hover:not(.Mui-focusVisible) {
    padding: settings.$rkt-huge-radio-focus-input-padding;
    left: settings.$rkt-huge-radio-hover-input-left;
  }

  @media screen and (min-width: settings.$rkt-huge-radio-large-breakpoint) {
    padding: settings.$rkt-huge-radio-large-breakpoint-label-padding;
  }
}

// prevent label from growing in size when focused or hovered
.rkt-RadioHuge:not(.rkt-RadioHuge--stylized) .rkt-RadioHuge__label.MuiFormControlLabel-root:not(:has(.rkt-RadioHuge__content)) {
  padding:  settings.$rkt-huge-radio-react-label-padding-limit;

  @media screen and (min-width: settings.$rkt-huge-radio-large-breakpoint) {
    padding: settings.$rkt-huge-radio-large-breakpoint-label-padding;
  }
}

.rkt-RadioHuge__label .rkt-Radio + .MuiFormControlLabel-label {
  line-height: settings.$rkt-huge-radio-line-height;
  font-family: settings.$rkt-huge-radio-font-family;
  font-size: settings.$rkt-huge-radio-font-size;
  font-weight: settings.$rkt-huge-radio-font-weight;
  flex: settings.$rkt-huge-radio-flex-input;
  padding: settings.$rkt-huge-radio-input-padding;

  @media screen and (min-width: settings.$rkt-huge-radio-large-breakpoint) {
    font-size: settings.$rkt-huge-radio-large-breakpoint-font-size;
  }
}

// padding right to prevent long labels from touching checkbox
.rkt-RadioHuge .rkt-RadioHuge__icon-label,
.rkt-RadioHuge .rkt-RadioHuge__label .MuiFormControlLabel-label {
  padding-right: settings.$rkt-huge-radio-input-padding-right;
}

.rkt-RadioHuge--stylized .rkt-RadioHuge__label .rkt-Radio + .MuiFormControlLabel-label {
  padding-right: settings.$rkt-huge-radio-stylized-label-padding-right;
}

.rkt-RadioHuge .rkt-RadioHuge__input {
  flex: settings.$rkt-huge-radio-flex-input;
  display: settings.$rkt-huge-radio-display-input;
  width: settings.$rkt-huge-radio-width-input;
  line-height: settings.$rkt-huge-radio-line-height;
  font-family: settings.$rkt-huge-radio-font-family;
  font-size: settings.$rkt-huge-radio-font-size;
  font-weight: settings.$rkt-huge-radio-font-weight;
}

// angular form field
.rkt-RadioHuge .mdc-form-field {
  box-sizing: border-box;
  width: settings.$rkt-huge-radio-width;
  align-items: settings.$rkt-huge-radio-form-field-align-items;
}

// angular quirk with radio touch target, needs to be hard-coded to match min height
.rkt-RadioHuge .rkt-Radio .mdc-radio .mdc-radio__native-control {
  height: settings.$rkt-huge-radio-min-height;
}

.rkt-RadioHuge .rkt-Radio:hover .mdc-radio .mdc-radio__background::before {
  background: settings.$rkt-huge-radio-radio-hover-background;
}

// angular label
.rkt-RadioHuge .rkt-Radio .mdc-form-field label,
.rkt-RadioHuge .rkt-Radio .mdc-form-field.mdc-form-field--align-end label {
  box-sizing: border-box;
  width: settings.$rkt-huge-radio-width;
  color: settings.$rkt-huge-radio-color;
  cursor: pointer;
  padding: settings.$rkt-huge-radio-label-padding;
  line-height: settings.$rkt-huge-radio-line-height;
  font-family: settings.$rkt-huge-radio-font-family;
  font-size: settings.$rkt-huge-radio-font-size;
  font-weight: settings.$rkt-huge-radio-font-weight;
  display: settings.$rkt-huge-radio-display;
  flex-direction: settings.$rkt-huge-radio-flex-direction;
  justify-content: settings.$rkt-huge-radio-justify-content;
  margin: settings.$rkt-huge-radio-margin;

  @media screen and (min-width: settings.$rkt-huge-radio-large-breakpoint) {
    padding: settings.$rkt-huge-radio-large-breakpoint-angular-label-padding;
    font-size: settings.$rkt-huge-radio-large-breakpoint-font-size;
  }
}

// Icon instead of radio circle
.rkt-RadioHuge--stylized {
  // hides radio circle, cannot be display none for keyboard interactions
  .mat-mdc-radio-button .mdc-radio,
  .mdc-radio,
  .MuiFormControlLabel-root .rkt-Radio {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
  }

  .rkt-RadioHuge__icon-label {
    flex: settings.$rkt-huge-radio-stylized-icon-label-flex;
  }

  .rkt-RadioHuge__label .MuiFormControlLabel-label,
  .rkt-Radio .mdc-form-field label,
  .rkt-Radio .mdc-form-field.mdc-form-field--align-end label {
    align-items: settings.$rkt-huge-radio-align-items;
    flex-direction: settings.$rkt-huge-radio-stylized-label-flex-direction;
    justify-content: settings.$rkt-huge-radio-stylized-label-justify-content;
    display: settings.$rkt-huge-radio-display;
  }

  .rkt-Radio .mdc-form-field label,
  .rkt-Radio .mdc-form-field.mdc-form-field--align-end label {
    padding: settings.$rkt-huge-radio-stylized-label-padding;

    @media screen and (min-width: settings.$rkt-huge-radio-large-breakpoint) {
      padding: settings.$rkt-huge-radio-stylized-large-breakpoint-label-padding;
    }
  }

  .rkt-Icon {
    color: settings.$rkt-huge-radio-stylized-icon-color;
  }

  &.rkt-RadioHuge--is-disabled .mat-icon,
  &.rkt-RadioHuge--is-disabled .rkt-Icon {
    color: settings.$rkt-huge-radio-disabled-color;
  }
}

.rkt-RadioHuge--vertical {
  .rkt-RadioHuge__label .MuiFormControlLabel-label {
    flex-direction: settings.$rkt-huge-radio-stylized-vertical-flex-direction;
    text-align: settings.$rkt-huge-radio-stylized-vertical-text-align;
  }

  .rkt-RadioHuge__input .mdc-form-field label,
  .rkt-RadioHuge__input .mdc-form-field label.mdc-form-field--align-end {
    flex-direction: settings.$rkt-huge-radio-stylized-vertical-flex-direction;
    text-align: settings.$rkt-huge-radio-stylized-vertical-text-align;
  }

  .rkt-RadioHuge__input .mdc-form-field label,
  .rkt-RadioHuge__input .mdc-form-field label.mdc-form-field--align-end,
  .MuiFormControlLabel-root {
    justify-content: settings.$rkt-huge-radio-stylized-vertical-justify-content;
    padding: settings.$rkt-huge-radio-stylized-vertical-padding;

    @media screen and (min-width: settings.$rkt-huge-radio-large-breakpoint) {
      padding: settings.$rkt-huge-radio-stylized-vertical-large-breakpoint-padding;
    }
  }

  .rkt-RadioHuge__input .mdc-form-field {
    padding: settings.$rkt-huge-radio-stylized-vertical-form-field-padding;
  }

  .rkt-Icon {
    margin-bottom: settings.$rkt-huge-radio-stylized-vertical-icon-margin-bottom;
  }

  &.rkt-RadioHuge--stylized {
    min-height: settings.$rkt-huge-radio-stylized-vertical-min-height;

    @media screen and (min-width: settings.$rkt-huge-radio-large-breakpoint) {
      min-height: settings.$rkt-huge-radio-large-breakpoint-min-height;
    }
  }
}

// disabled styles
.rkt-RadioHuge--is-disabled,
.rkt-RadioHuge--is-disabled:hover {
  background-color: settings.$rkt-huge-radio-disabled-background-color;
  box-shadow: settings.$rkt-huge-radio-disabled-box-shadow;
}

.rkt-RadioHuge--is-disabled .rkt-RadioHuge__label,
.rkt-RadioHuge--is-disabled .rkt-RadioHuge__content,
.rkt-RadioHuge--is-disabled .rkt-Icon,
.rkt-RadioHuge--is-disabled .rkt-RadioHuge__input .mdc-form-field label,
.rkt-RadioHuge.rkt-RadioHuge--is-disabled .mdc-radio--disabled + label,
.rkt-RadioHuge.rkt-RadioHuge--is-disabled .rkt-RadioHuge__icon-label,
.rkt-RadioHuge.rkt-RadioHuge--is-disabled .rkt-Radio .mdc-form-field label,
.rkt-RadioHuge.rkt-RadioHuge--is-disabled .rkt-Radio .mdc-form-field.mdc-form-field--align-end label {
  color: settings.$rkt-huge-radio-disabled-color;
  cursor: default;
}

// This overrides active radio button cursors.
.rkt-RadioHuge.rkt-RadioHuge--is-disabled .mdc-form-field label {
  cursor: default;
}

.rkt-RadioHuge:not(.rkt-RadioHuge--stylized) .rkt-Radio.MuiButtonBase-root.Mui-focusVisible {
  left: settings.$rkt-huge-radio-radio-focus-visible-left;
}