@use '../web/scss/settings';

.rkt-Elevation-0 {
  box-shadow: settings.$rkt-elevation-0;
}

.rkt-Elevation-1 {
  box-shadow: settings.$rkt-elevation-1;
}

.rkt-Elevation-2 {
  box-shadow: settings.$rkt-elevation-2;
}

.rkt-Elevation-3 {
  box-shadow: settings.$rkt-elevation-3;
}

.rkt-Elevation-4 {
  box-shadow: settings.$rkt-elevation-4;
}

.rkt-Elevation-5 {
  box-shadow: settings.$rkt-elevation-5;
}

.rkt-Elevation-6 {
  box-shadow: settings.$rkt-elevation-6;
}

.rkt-Elevation-7 {
  box-shadow: settings.$rkt-elevation-7;
}

.rkt-Elevation-8 {
  box-shadow: settings.$rkt-elevation-8;
}

.rkt-Elevation-9 {
  box-shadow: settings.$rkt-elevation-9;
}
