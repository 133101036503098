@use '../web/scss/settings';

// label styles
.rkt-SlideToggle .rkt-SlideToggle__label,
.rkt-SlideToggle .mat-slide-toggle-content {
  color: settings.$rkt-slide-toggle-default-label-color;
  font-size: settings.$rkt-slide-toggle-label-font-size;
  font-weight: settings.$rkt-slide-toggle-label-font-weight;
}

// disabled label styles
.rkt-SlideToggle.Mui-disabled .rkt-SlideToggle__label,
.rkt-SlideToggle.mat-disabled .mat-slide-toggle-content {
  color: settings.$rkt-slide-toggle-disabled-label-color;
}

// default checked styles
.rkt-SlideToggle .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track,
.rkt-SlideToggle.mat-checked .mat-slide-toggle-bar {
  opacity: unset;
  background-color: settings.$rkt-slide-toggle-default-checked-track-color;
}

// disabled unchecked styles
.rkt-SlideToggle .MuiSwitch-switchBase.Mui-disabled + .MuiSwitch-track,
.rkt-SlideToggle.mat-disabled .mat-slide-toggle-bar {
  background-color: settings.$rkt-slide-toggle-disabled-track-color;
  border: settings.$rkt-slide-toggle-disabled-border;
}

// ANGULAR

// unset default angular opacity
.rkt-SlideToggle.mat-slide-toggle.mat-disabled,
.rkt-SlideToggle.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected.mdc-switch--disabled .mdc-switch__icons,
.rkt-SlideToggle.mat-mdc-slide-toggle .mdc-switch.mdc-switch--disabled .mdc-switch__track {
  opacity: unset;
}

// default unchecked styles
.rkt-SlideToggle.mat-disabled.mat-checked .mat-slide-toggle-thumb {
  background-color: settings.$rkt-slide-toggle-disabled-checked-thumb-color;
}

.rkt-SlideToggle.mat-mdc-slide-toggle .mdc-switch {
  width: settings.$rkt-slide-toggle-switch-width;
}

.rkt-SlideToggle.mat-mdc-slide-toggle .mdc-switch .mdc-switch__track {
  height: settings.$rkt-slide-toggle-switch-height;
  border-radius: settings.$rkt-slide-toggle-switch-border-radius;
}

// default unchecked track color
.rkt-SlideToggle.mat-mdc-slide-toggle .mdc-switch.mdc-switch--unselected .mdc-switch__track::before,
.rkt-SlideToggle.mat-mdc-slide-toggle .mdc-switch:enabled:active.mdc-switch--unselected .mdc-switch__track::before,
.rkt-SlideToggle.mat-mdc-slide-toggle .mdc-switch:enabled:focus.mdc-switch--unselected .mdc-switch__track::before,
.rkt-SlideToggle.mat-mdc-slide-toggle .mdc-switch:enabled:hover:not(:focus):not(:active).mdc-switch--unselected .mdc-switch__track::before {
  background-color: settings.$rkt-slide-toggle-default-unchecked-track-color;
  border: settings.$rkt-slide-toggle-default-border;
}

// default checked track color
.rkt-SlideToggle.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected.mdc-switch--checked .mdc-switch__track::after,
.rkt-SlideToggle.mat-mdc-slide-toggle .mdc-switch:enabled:active.mdc-switch--selected .mdc-switch__track::after,
.rkt-SlideToggle.mat-mdc-slide-toggle .mdc-switch:enabled:focus.mdc-switch--selected .mdc-switch__track::after,
.rkt-SlideToggle.mat-mdc-slide-toggle .mdc-switch:enabled:hover:not(:focus):not(:active).mdc-switch--selected .mdc-switch__track::after {
  background-color: settings.$rkt-slide-toggle-default-checked-track-color;
  border: settings.$rkt-slide-toggle-default-border;
}

// disabled track color
.rkt-SlideToggle.mat-mdc-slide-toggle .mdc-switch.mdc-switch--disabled.mdc-switch--checked .mdc-switch__track::after,
.rkt-SlideToggle.mat-mdc-slide-toggle .mdc-switch.mdc-switch--disabled.mdc-switch--checked .mdc-switch__track::before,
.rkt-SlideToggle.mat-mdc-slide-toggle .mdc-switch.mdc-switch--disabled .mdc-switch__track::after,
.rkt-SlideToggle.mat-mdc-slide-toggle .mdc-switch.mdc-switch--disabled .mdc-switch__track::before {
  background-color: settings.$rkt-slide-toggle-disabled-track-color;
  border: settings.$rkt-slide-toggle-disabled-border;
}

.rkt-SlideToggle .mdc-switch__icon--off,
.rkt-SlideToggle--theme.mat-mdc-slide-toggle .mdc-switch.mdc-switch--unselected .mdc-switch__icon--off,
.rkt-SlideToggle--theme.mat-mdc-slide-toggle .mdc-switch.mdc-switch--unselected .mdc-switch__icon--on,
.rkt-SlideToggle--theme.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected .mdc-switch__icon--off,
.rkt-SlideToggle--theme.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected .mdc-switch__icon--on {
  display: settings.$rkt-slide-toggle-unchecked-icon-display;
  opacity: settings.$rkt-slide-toggle-unchecked-icon-opacity;
}

.rkt-SlideToggle.mat-mdc-slide-toggle .mdc-switch:enabled.mdc-switch--selected .mdc-switch__handle::before,
.rkt-SlideToggle.mat-mdc-slide-toggle .mdc-switch:enabled:active.mdc-switch--selected .mdc-switch__handle::before,
.rkt-SlideToggle.mat-mdc-slide-toggle .mdc-switch:enabled:hover:not(:focus):not(:active).mdc-switch--selected .mdc-switch__handle::before,
.rkt-SlideToggle.mat-mdc-slide-toggle .mdc-switch:enabled.mdc-switch--unselected .mdc-switch__handle::after,
.rkt-SlideToggle.mat-mdc-slide-toggle .mdc-switch:enabled:active.mdc-switch--unselected .mdc-switch__handle::after,
.rkt-SlideToggle.mat-mdc-slide-toggle .mdc-switch:enabled:hover:not(:focus):not(:active).mdc-switch--unselected .mdc-switch__handle::after,
.rkt-SlideToggle .mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after,
.rkt-SlideToggle .mdc-switch.mdc-switch--selected:enabled:focus:not(:active) .mdc-switch__handle::after,
.rkt-SlideToggle .mdc-switch.mdc-switch--unselected:enabled:focus:not(:active) .mdc-switch__handle::after,
.rkt-SlideToggle .mdc-switch.mdc-switch--selected:enabled:focus .mdc-switch__handle::after,
.rkt-SlideToggle .mdc-switch.mdc-switch--selected:enabled:hover:not(:focus):not(:active) .mdc-switch__handle::after {
  background-color: settings.$rkt-slide-toggle-switch-handle-background-color;
}

.rkt-SlideToggle.mat-mdc-slide-toggle .mdc-switch.mdc-switch .mdc-switch__handle .mdc-elevation-overlay,
.rkt-SlideToggle.mat-mdc-slide-toggle .mdc-switch.mdc-switch:enabled:active .mdc-switch__handle .mdc-elevation-overlay,
.rkt-SlideToggle.mat-mdc-slide-toggle .mdc-switch.mdc-switch:enabled:focus .mdc-switch__handle .mdc-elevation-overlay,
.rkt-SlideToggle.mat-mdc-slide-toggle .mdc-switch.mdc-switch:enabled:hover:not(:focus):not(:active) .mdc-switch__handle .mdc-elevation-overlay {
  opacity: unset;
  transition: none;
}

.rkt-SlideToggle.mat-mdc-slide-toggle .mdc-switch--unselected .mdc-switch__handle-track {
  left: settings.$rkt-slide-toggle-unchecked-handle-left;
}

.rkt-SlideToggle.mat-mdc-slide-toggle .mdc-switch--selected .mdc-switch__handle-track {
  left: settings.$rkt-slide-toggle-checked-handle-left;
}

.rkt-SlideToggle.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled .mdc-switch__icon {
  fill: settings.$rkt-slide-toggle-checked-icon-fill;
}

.rkt-SlideToggle.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected.mdc-switch--disabled .mdc-switch__icon {
  fill: settings.$rkt-slide-toggle-disabled-icon-fill;
}

// REACT
// default unchecked
.rkt-SlideToggle .MuiSwitch-switchBase + .MuiSwitch-track {
  opacity: unset;
  background-color: settings.$rkt-slide-toggle-default-unchecked-track-color;
  height: settings.$rkt-slide-toggle-switch-height;
  width: settings.$rkt-slide-toggle-switch-width;
  border-radius: settings.$rkt-slide-toggle-switch-border-radius;
  border: settings.$rkt-slide-toggle-default-border;
  box-sizing: settings.$rkt-slide-toggle-default-box-sizing;
}

.rkt-SlideToggle .MuiSwitch-root {
  width: settings.$rkt-slide-toggle-width;
  height: settings.$rkt-slide-toggle-height;
  padding: settings.$rkt-slide-toggle-padding;
}

.rkt-SlideToggle .MuiButtonBase-root {
  padding: settings.$rkt-slide-toggle-switch-handle-padding;
  left: settings.$rkt-slide-toggle-switch-handle-left;
  top: settings.$rkt-slide-toggle-switch-handle-top;
}

// when hovered, change background color to 4% - background color is base MUI red style
.rkt-SlideToggle .MuiButtonBase-root:hover {
  background-color: settings.$rkt-slide-toggle-switch-ripple-hover-background-color;
}

// when pressed, change background color to 8% - when in hover, background color is already 4%
.rkt-SlideToggle .MuiButtonBase-root:active {
  background-color: settings.$rkt-slide-toggle-switch-ripple-active-background-color;
  transition: settings.$rkt-slide-toggle-switch-ripple-transition;
}

.rkt-SlideToggle .MuiButtonBase-root.Mui-checked {
  color: settings.$rkt-slide-toggle-checked-switch-handle-color;
  left: settings.$rkt-slide-toggle-checked-switch-handle-left;
}

// Add border on slide toggle handle when keyboard focused in React
.rkt-SlideToggle .MuiButtonBase-root.Mui-focusVisible .MuiSwitch-thumb {
  border: settings.$rkt-slide-toggle-switch-handle-keyboard-focused-border;
  box-sizing: settings.$rkt-slide-toggle-switch-handle-keyboard-focused-box-sizing;
}

// Remove ripple when keyboard focused in React
.rkt-SlideToggle .MuiButtonBase-root.Mui-focusVisible .MuiTouchRipple-root {
  display: settings.$rkt-slide-toggle-switch-ripple-keyboard-focused-display;
}

.rkt-SlideToggle .MuiButtonBase-root.Mui-checked .MuiSwitch-thumb::after {
  content:  "";
  width: settings.$rkt-slide-toggle-checked-icon-width;
  height: settings.$rkt-slide-toggle-checked-icon-height;
  position: absolute;
  top: settings.$rkt-slide-toggle-checked-icon-top;
  left: settings.$rkt-slide-toggle-checked-icon-left;
}

.rkt-SlideToggle .MuiButtonBase-root.Mui-disabled .MuiSwitch-thumb::after {
  display: settings.$rkt-slide-toggle-disabled-thumb-display;
}

// For the label to be after the toggle
.rkt-SlideToggle__label--end {
  margin-left: settings.$rkt-slide-toggle-label-right-margin-left;
}

// For the label to be before the toggle
.rkt-SlideToggle__label--start {
  margin-right: settings.$rkt-slide-toggle-label-left-margin-right;
}

// Theme Toggle Styles
// Positioning for Theme Toggle Icon
.rkt-SlideToggle--theme.mat-mdc-slide-toggle .mdc-switch.mdc-switch--unselected:enabled .mdc-switch__icons::after,
.rkt-SlideToggle--theme.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled .mdc-switch__icons::after,
.rkt-SlideToggle--theme.rkt-SlideToggle .MuiButtonBase-root .MuiSwitch-thumb::after,
.rkt-SlideToggle--theme .MuiSwitch-switchBase.Mui-disabled::after,
.rkt-SlideToggle--theme.mat-mdc-slide-toggle .mdc-switch.mdc-switch--unselected.mdc-switch--disabled .mdc-switch__icons::after,
.rkt-SlideToggle--theme.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected.mdc-switch--disabled .mdc-switch__icons::after {
  content:  settings.$rkt-slide-toggle-theme-icon-content;
  width: settings.$rkt-slide-toggle-theme-icon-width;
  height: settings.$rkt-slide-toggle-theme-icon-height;
  position: settings.$rkt-slide-toggle-theme-icon-position;
  top: settings.$rkt-slide-toggle-theme-icon-top;
  left: settings.$rkt-slide-toggle-theme-icon-left;
}

// Positioning for Theme Toggle Icons (angular)
.rkt-SlideToggle--theme.mat-mdc-slide-toggle .mdc-switch.mdc-switch--unselected:enabled .mdc-switch__icons::after,
.rkt-SlideToggle--theme.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled .mdc-switch__icons::after,
.rkt-SlideToggle--theme.mat-mdc-slide-toggle .mdc-switch.mdc-switch--unselected.mdc-switch--disabled .mdc-switch__icons::after,
.rkt-SlideToggle--theme.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected.mdc-switch--disabled .mdc-switch__icons::after {
  top: settings.$rkt-slide-toggle-theme-icons-top;
  left: settings.$rkt-slide-toggle-theme-icons-left;
}

// Slide toggle theme thumb background icon (light mode)
.rkt-SlideToggle--theme .MuiButtonBase-root .MuiSwitch-thumb::after,
.rkt-SlideToggle--theme.mat-mdc-slide-toggle .mdc-switch.mdc-switch--unselected:enabled .mdc-switch__icons::after,
.rkt-SlideToggle--theme .MuiSwitch-switchBase.Mui-disabled:not(.Mui-checked):after,
.rkt-SlideToggle--theme.mat-mdc-slide-toggle .mdc-switch.mdc-switch--unselected.mdc-switch--disabled .mdc-switch__icons::after {
  background: settings.$rkt-slide-toggle-theme-thumb-background;
}

// Make MUI match angular disabled opacity
.rkt-SlideToggle--theme .MuiSwitch-switchBase.Mui-disabled:not(.Mui-checked):after {
  opacity: settings.$rkt-slide-toggle-theme-disabled-thumb-opacity;
}

// Slide toggle theme thumb background when checked (dark mode)
.rkt-SlideToggle--theme .MuiButtonBase-root.Mui-checked .MuiSwitch-thumb::after,
.rkt-SlideToggle--theme .MuiSwitch-switchBase.Mui-disabled.Mui-checked:after,
.rkt-SlideToggle--theme.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected .mdc-switch__icons::after {
  background: settings.$rkt-slide-toggle-theme-checked-thumb-background;
}

// Slide toggle theme unchecked track color (light mode)
.rkt-SlideToggle--theme.rkt-SlideToggle.mat-mdc-slide-toggle .mdc-switch.mdc-switch--unselected:not(.mdc-switch--disabled) .mdc-switch__track::before,
.rkt-SlideToggle--theme.rkt-SlideToggle.mat-mdc-slide-toggle .mdc-switch:enabled:active.mdc-switch--unselected .mdc-switch__track::before,
.rkt-SlideToggle--theme.rkt-SlideToggle.mat-mdc-slide-toggle .mdc-switch:enabled:focus.mdc-switch--unselected .mdc-switch__track::before,
.rkt-SlideToggle--theme.rkt-SlideToggle.mat-mdc-slide-toggle .mdc-switch:enabled:hover:not(:focus):not(:active).mdc-switch--unselected .mdc-switch__track::before,
.rkt-SlideToggle--theme .MuiSwitch-switchBase + .MuiSwitch-track {
  background-color: settings.$rkt-slide-toggle-theme-unchecked-track-color;
}

// Stacks toggle on top of label for when it has a Bottom Label
.rkt-SlideToggle--has-bottom-label .mdc-form-field {
  flex-direction: settings.$rkt-slide-toggle-bottom-label-flex-direction;
}

.rkt-SlideToggle--has-bottom-label .mdc-switch {
  margin-bottom: settings.$rkt-slide-toggle-bottom-label-margin-bottom;
}

// New updates below for Slide Toggle

// Remove check mark when checked
.rkt-SlideToggle .mdc-switch.mdc-switch--selected .mdc-switch__icon {
  display: settings.$rkt-slide-toggle-switch-handle-checked-display;
}

// Remove shadow from handle/thumb in Angular when checked
.rkt-SlideToggle .mdc-switch.mdc-switch--selected .mdc-switch__handle .mdc-switch__shadow {
  box-shadow: settings.$rkt-slide-toggle-switch-handle-checked-box-shadow;
}

// Update shadow from handle/thumb in Angular when unchecked
.rkt-SlideToggle .mdc-switch.mdc-switch--unselected .mdc-switch__handle .mdc-switch__shadow {
  box-shadow: settings.$rkt-slide-toggle-switch-handle-unchecked-box-shadow;
}

// Remove shadow when disabled and unchecked in angular
.rkt-SlideToggle .mdc-switch.mdc-switch--disabled .mdc-switch__handle .mdc-switch__shadow {
  box-shadow: settings.$rkt-slide-toggle-disabled-switch-handle-unchecked-box-shadow;
}

// Adjust switch handle/thumb size for React 
.rkt-SlideToggle .MuiSwitch-thumb {
  width: settings.$rkt-slide-toggle-switch-handle-width;
  height: settings.$rkt-slide-toggle-switch-handle-height;
  box-shadow: settings.$rkt-slide-toggle-switch-handle-checked-box-shadow;
}

// Adjust switch handle/thumb size for React 
.rkt-SlideToggle .MuiSwitch-switchBase:not(.Mui-checked):not(.Mui-focusVisible) .MuiSwitch-thumb {
  box-shadow: settings.$rkt-slide-toggle-switch-handle-unchecked-box-shadow;
}

// Remove shadow from handle/thumb when disabled and unchecked, white handle/thumb
.rkt-SlideToggle .MuiSwitch-switchBase.Mui-disabled:not(.Mui-checked) .MuiSwitch-thumb {
  box-shadow: settings.$rkt-slide-toggle-disabled-switch-handle-unchecked-box-shadow;
  color: settings.$rkt-slide-toggle-disabled-switch-handle-unchecked-color;
}
// Adjust switch handle/thumb size for Angular
.rkt-SlideToggle.mat-mdc-slide-toggle .mdc-switch .mdc-switch__handle {
  width: settings.$rkt-slide-toggle-switch-handle-width;
  height: settings.$rkt-slide-toggle-switch-handle-height;
}

// Adjust ripple size for Angular
.rkt-SlideToggle.mat-mdc-slide-toggle .mdc-switch .mdc-switch__ripple {
  height: settings.$rkt-slide-toggle-switch-ripple-height;
  width: settings.$rkt-slide-toggle-switch-ripple-width;
}

// Adjust ripple color for Angular when hovered
.rkt-SlideToggle.mat-mdc-slide-toggle .mdc-switch:hover .mdc-switch__ripple {
  background-color: settings.$rkt-slide-toggle-switch-ripple-hover-background-color;
  border-radius: settings.$rkt-slide-toggle-switch-ripple-hover-border-radius;
}

// Adjust ripple color for Angular when active (pressed)
.rkt-SlideToggle.mat-mdc-slide-toggle .mdc-switch:active .mdc-switch__ripple {
  background-color: settings.$rkt-slide-toggle-switch-ripple-active-background-color;
  border-radius: settings.$rkt-slide-toggle-switch-ripple-active-border-radius;
}

// Add border on slide toggle handle when keyboard focused in Angular
.rkt-SlideToggle.mat-accent.cdk-focused.cdk-keyboard-focused.mat-mdc-slide-toggle-focused .mdc-switch__handle {
  border: settings.$rkt-slide-toggle-switch-handle-keyboard-focused-border;
  box-sizing: settings.$rkt-slide-toggle-switch-handle-keyboard-focused-box-sizing;
}

// Remove ripple when focused in Angular
.rkt-SlideToggle.mat-accent.cdk-focused.cdk-keyboard-focused.mat-mdc-slide-toggle-focused .mdc-switch__ripple {
  display: settings.$rkt-slide-toggle-switch-ripple-keyboard-focused-display;
}

// Remove MUI default red accent color from ripple in Angular
.rkt-SlideToggle.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled:active .mdc-switch__ripple::before, 
.rkt-SlideToggle.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled:active .mdc-switch__ripple::after,
.rkt-SlideToggle.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled:hover:not(:focus) .mdc-switch__ripple::before, 
.rkt-SlideToggle.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled:hover:not(:focus) .mdc-switch__ripple::after,
.rkt-SlideToggle.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled:focus .mdc-switch__ripple::before, 
.rkt-SlideToggle.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled:focus .mdc-switch__ripple::after {
  background-color: settings.$rkt-slide-toggle-switch-ripple-background-color;
}

// Remove MUI default red accent color from switch track in Angular
.rkt-SlideToggle.mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track::after,
.rkt-SlideToggle.mat-mdc-slide-toggle .mdc-switch:enabled:focus:not(:active) .mdc-switch__track::after {
  background-color: settings.$rkt-slide-toggle-default-checked-track-color;
}