$rkt-space-1: 4px !default;
$rkt-space-2: 8px !default;
$rkt-space-3: 16px !default;
$rkt-space-4: 24px !default;
$rkt-space-5: 32px !default;
$rkt-space-6: 40px !default;
$rkt-space-7: 48px !default;
$rkt-space-8: 64px !default;
$rkt-space-9: 80px !default;
$rkt-space-inset-1: 4px 4px 4px 4px !default;
$rkt-space-inset-2: 8px 8px 8px 8px !default;
$rkt-space-inset-3: 16px 16px 16px 16px !default;
$rkt-space-inset-4: 24px 24px 24px 24px !default;
$rkt-space-inset-5: 32px 32px 32px 32px !default;
$rkt-space-inset-6: 40px 40px 40px 40px !default;
$rkt-space-inset-7: 48px 48px 48px 48px !default;
$rkt-space-inset-8: 64px 64px 64px 64px !default;
$rkt-space-inset-9: 80px 80px 80px 80px !default;
$rkt-space-stack-1: 0 0 4px 0 !default;
$rkt-space-stack-2: 0 0 8px 0 !default;
$rkt-space-stack-3: 0 0 16px 0 !default;
$rkt-space-stack-4: 0 0 24px 0 !default;
$rkt-space-stack-5: 0 0 32px 0 !default;
$rkt-space-stack-6: 0 0 40px 0 !default;
$rkt-space-stack-7: 0 0 48px 0 !default;
$rkt-space-stack-8: 0 0 64px 0 !default;
$rkt-space-stack-9: 0 0 80px 0 !default;