@use '../web/scss/settings';

// Chip Spacing & Fonts
.rkt-Chip,
.rkt-Chip.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled),
.rkt-Chip.rkt-Chip--is-disabled,
.rkt-Chip.rkt-Chip--is-disabled.mat-mdc-standard-chip.mdc-evolution-chip--disabled {
  font-size: settings.$rkt-chip-font-size;
  font-weight: settings.$rkt-chip-font-weight;
  height: settings.$rkt-chip-height;
  padding: settings.$rkt-chip-padding;
  border-radius: settings.$rkt-chip-border-radius;

  .mdc-evolution-chip__action--primary,
  .MuiChip-label {
    padding: settings.$rkt-chip-label-padding;
  }

  .mat-mdc-chip-remove {
    opacity: unset;
    padding: settings.$rkt-chip-with-close-button-padding;
  }

  &.mat-mdc-chip-with-trailing-icon,
  &.MuiChip-deletable {
    padding-right: settings.$rkt-chip-with-close-padding-right;
  }

  &:focus-visible,
  &.Mui-focusVisible,
  &.cdk-focused:not(.cdk-mouse-focused) {
    outline: settings.$rkt-chip-focus-outline;
  }

  .mat-mdc-chip-remove:hover .mat-icon,
  .MuiChip-deleteIcon:hover,
  .mat-mdc-chip-remove:focus .mat-icon,
  .MuiChip-deleteIcon:focus-visible,
  .mat-mdc-chip-remove:active .mat-icon,
  .MuiChip-deleteIcon:active {
    border-radius: settings.$rkt-chip-hover-icon-border-radius;
  }

  // Remove Ripples, Focus overlay & Check mark
  .mat-mdc-chip-ripple,
  .MuiTouchRipple-root,
  .mat-mdc-chip-focus-overlay,
  .mdc-evolution-chip__graphic,
  .mdc-evolution-chip__checkmark {
    display: none;
  }

  .rkt-Chip__icon svg path {
    stroke-width: settings.$rkt-chip-icon-stroke-width;
  }
}

// Default Chip
.rkt-Chip,
.rkt-Chip.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
  background-color: settings.$rkt-chip-background-color;
  box-shadow: settings.$rkt-chip-box-shadow;

  .mdc-evolution-chip__text-label,
  .mat-mdc-chip-remove,
  .MuiChip-deleteIcon {
    color: settings.$rkt-chip-label-color;
  }

  .mat-mdc-chip-remove.mdc-evolution-chip__icon {
    padding-left: settings.$rkt-chip-with-close-icon-padding-left;
    padding-right: settings.$rkt-chip-with-close-icon-padding-right;
  }

  &:hover {
    background-color: settings.$rkt-chip-hover-background-color;
    cursor: settings.$rkt-chip-cursor;
    
    .rkt-Chip__label {
      cursor: settings.$rkt-chip-cursor;
    }

    .mat-mdc-chip-remove:hover .mat-icon,
    .MuiChip-deleteIcon:hover {
      color: settings.$rkt-chip-hover-icon-color; 
      background-color: settings.$rkt-chip-hover-icon-background-color;
      box-shadow: settings.$rkt-chip-hover-icon-box-shadow;
    }
  }

  &:focus-visible,
  &.Mui-focusVisible,
  &.cdk-focused:not(.cdk-mouse-focused) {
    background-color: settings.$rkt-chip-focus-background-color;
    box-shadow: settings.$rkt-chip-focus-box-shadow;

    .mat-mdc-chip-remove:focus .mat-icon,
    .mat-mdc-chip-remove .mat-icon:focus-visible,
    .MuiChip-deleteIcon:focus-visible {
      background-color: settings.$rkt-chip-focus-icon-background-color;
      box-shadow: settings.$rkt-chip-focus-icon-box-shadow;
    }

    .mat-mdc-chip-remove .mat-icon:focus-visible {
      outline: settings.$rkt-chip-focus-visible-icon-outline; 
      border-radius: settings.$rkt-chip-focus-visible-icon-border-radius;
    }
  }
  
  &:active {
    background-color: settings.$rkt-chip-active-background-color;
    box-shadow: settings.$rkt-chip-active-box-shadow;

    .mat-mdc-chip-remove:active .mat-icon,
    .MuiChip-deleteIcon:active {
      background-color: settings.$rkt-chip-active-icon-background-color;
      box-shadow: settings.$rkt-chip-active-icon-box-shadow;
    }
  }
}

// Default with Close Button - REACT SPECIFIC
.rkt-Chip.MuiChip-deletable {
  .rkt-Chip__label {
    margin-right: settings.$rkt-chip-with-close-label-margin-right;
  }
  .rkt-Chip__delete-button {
    margin: settings.$rkt-chip-with-close-icon-margin;
    padding: settings.$rkt-chip-with-close-icon-margin;
    .MuiSvgIcon-root {
      height: settings.$rkt-chip-with-close-icon-height;
      width: settings.$rkt-chip-with-close-icon-width;
    }
  }
}

// Selected
.rkt-Chip.rkt-Chip--is-selected,
.rkt-Chip.rkt-Chip--is-selected,
.rkt-Chip.rkt-Chip--is-selected.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
  background-color: settings.$rkt-chip-selected-background-color;
  box-shadow: settings.$rkt-chip-selected-box-shadow;

  .rkt-Chip__label,
  .mdc-evolution-chip__text-label {
    color: settings.$rkt-chip-selected-label-color;
  }

  &.rkt-Chip--has-icon .rkt-Chip__icon {
    color: settings.$rkt-chip-selected-icon-color;
  }

  &:hover {
    background-color: settings.$rkt-chip-selected-hover-background-color;
  }

  &:focus-visible,
  &.Mui-focusVisible,
  &.cdk-focused:not(.cdk-mouse-focused) {
    background-color: settings.$rkt-chip-selected-focus-background-color;
    box-shadow: settings.$rkt-chip-selected-focus-box-shadow;
  }

  &:active {
    background-color: settings.$rkt-chip-selected-active-background-color;
    box-shadow: settings.$rkt-chip-selected-active-box-shadow;

    .rkt-Chip__label,
    .mdc-evolution-chip__text-label {
      color: settings.$rkt-chip-selected-active-color;
    }
  }
}

// Has Icon
.rkt-Chip.rkt-Chip--has-icon,
.rkt-Chip.rkt-Chip--has-icon.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled),
.rkt-Chip.rkt-Chip--is-disabled.rkt-Chip--has-icon,
.rkt-Chip.rkt-Chip--is-disabled.rkt-Chip--has-icon.mat-mdc-standard-chip.mdc-evolution-chip--disabled,
.rkt-Chip.rkt-Chip--has-selected-icon.rkt-Chip--is-selected,
.rkt-Chip.rkt-Chip--has-selected-icon.rkt-Chip--is-selected.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
  padding-left: settings.$rkt-chip-has-icon-padding-left;

  .mdc-evolution-chip__icon--primary {
    opacity: 1;
  }

  .mdc-evolution-chip__icon--primary,
  .rkt-Chip__icon {
    height: settings.$rkt-chip-has-icon-icon-height;
    width: settings.$rkt-chip-has-icon-icon-width;
    color: settings.$rkt-chip-has-icon-icon-color;
  }

  .mdc-evolution-chip__graphic,
  .rkt-Chip__icon {
    display: settings.$rkt-chip-has-icon-display;
  }

  .mdc-evolution-chip__graphic {
    padding: settings.$rkt-chip-has-icon-graphic-padding;
    width: settings.$rkt-chip-has-icon-graphic-width;
  }

  .rkt-Chip__icon {
    padding: settings.$rkt-chip-has-icon-icon-padding;
    margin: settings.$rkt-chip-has-icon-icon-margin;
  }
}

.rkt-Chip.rkt-Chip--has-icon.rkt-Chip--is-selected,
.rkt-Chip.rkt-Chip--has-icon.rkt-Chip--is-selected.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
  .mdc-evolution-chip__icon--primary,
  .MuiSvgIcon--root {
    color: settings.$rkt-chip-has-icon-selected-icon-color;
  }
}

.rkt-Chip.rkt-Chip--has-selected-icon.rkt-Chip--is-selected,
.rkt-Chip.rkt-Chip--has-selected-icon.rkt-Chip--is-selected.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {

  .mdc-evolution-chip__graphic {
    padding: settings.$rkt-chip-has-selected-icon-graphic-padding;
  }
  
  .mdc-evolution-chip__graphic,
  .mdc-evolution-chip__checkmark,
  .rkt-Chip__selected-icon {
    display: settings.$rkt-chip-has-selected-icon-display;
  }

  .mdc-evolution-chip__graphic,
  .mdc-evolution-chip__checkmark {
    height: settings.$rkt-chip-has-selected-icon-height;
    width: settings.$rkt-chip-has-selected-icon-width;
  }

  .rkt-Chip__selected-icon {
    margin: settings.$rkt-chip-has-selected-icon-margin;

    .rkt-Chip__icon {
      color: settings.$rkt-chip-has-selected-icon-color;
    }
  }
}

// Primary 
.rkt-Chip.rkt-Chip--primary,
.rkt-Chip.rkt-Chip--secondary,
.rkt-Chip.rkt-Chip--primary.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled),
.rkt-Chip.rkt-Chip--secondary.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) {
  background-color: settings.$rkt-chip-primary-background-color;

  .rkt-Chip__label,
  .mdc-evolution-chip__text-label,
  .mat-mdc-chip-remove .mat-icon,
  .MuiSvgIcon--root,
  .rkt-Chip__delete-button,
  .rkt-Chip__icon,
  .mdc-evolution-chip__icon--primary {
    color: settings.$rkt-chip-primary-label-color;
  }

  &:hover {
    background-color: settings.$rkt-chip-primary-hover-background-color;
    box-shadow: settings.$rkt-chip-primary-hover-box-shadow;

    .mat-mdc-chip-remove:hover .mat-icon,
    .MuiChip-deleteIcon:hover {
      color: settings.$rkt-chip-primary-hover-icon-color;
      background-color: settings.$rkt-chip-primary-hover-icon-background-color;
      box-shadow: settings.$rkt-chip-primary-hover-icon-box-shadow;
    }
  }

  &:focus-visible,
  &.Mui-focusVisible,
  &.cdk-focused:not(.cdk-mouse-focused) {
    box-shadow: settings.$rkt-chip-primary-focus-box-shadow;

    .mat-mdc-chip-remove:focus-visible .mat-icon,
    .MuiChip-deleteIcon:focus-visible {
      background-color: settings.$rkt-chip-primary-focus-icon-background-color;
      box-shadow: settings.$rkt-chip-primary-focus-icon-box-shadow;
    }
  }
  
  &:active {
    background-color: settings.$rkt-chip-primary-active-background-color;
    box-shadow: settings.$rkt-chip-primary-active-box-shadow;

    .mat-mdc-chip-remove:active .mat-icon,
    .MuiChip-deleteIcon:active {
      background-color: settings.$rkt-chip-primary-active-icon-background-color;
      box-shadow: settings.$rkt-chip-primary-active-icon-box-shadow;
    }
  }
}

// Disabled
.rkt-Chip.rkt-Chip--is-disabled,
.rkt-Chip.rkt-Chip--is-disabled.mat-mdc-standard-chip.mdc-evolution-chip--disabled,
.rkt-Chip.rkt-Chip--is-disabled.rkt-Chip--has-icon,
.rkt-Chip.rkt-Chip--is-disabled.rkt-Chip--has-icon.mat-mdc-standard-chip.mdc-evolution-chip--disabled {
  background-color: settings.$rkt-chip-disabled-background-color;
  box-shadow: settings.$rkt-chip-disabled-box-shadow;
  opacity: unset;

  .rkt-Chip__label,
  .mdc-evolution-chip__text-label,
  .mat-mdc-chip-remove,
  .mdc-evolution-chip__icon--primary,
  .MuiSvgIcon--root,
  .MuiChip-deleteIcon,
  .rkt-Chip__icon {
    color: settings.$rkt-chip-disabled-label-color;
  }

  &.rkt-Chip--primary,
  &.rkt-Chip--secondary {
    box-shadow: settings.$rkt-chip-disabled-primary-box-shadow;
  }
}

// On Dark Styles START

.rkt-Chip.rkt-Chip--on-dark.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled),
.rkt-Chip.rkt-Chip--on-dark.MuiChip-root:not(.Mui-disabled) {
  background-color: settings.$rkt-chip-on-dark-background-color;
  border: settings.$rkt-chip-on-dark-border;
}

.rkt-Chip.rkt-Chip--on-dark.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled):hover,
.rkt-Chip.rkt-Chip--on-dark.MuiChip-root:not(.Mui-disabled):hover {
  background-color: settings.$rkt-chip-on-dark-hover-background-color;
}

.rkt-Chip.rkt-Chip--on-dark.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled):focus,
.rkt-Chip.rkt-Chip--on-dark.MuiChip-root:not(.Mui-disabled):focus,
.rkt-Chip.rkt-Chip--on-dark.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled):focus-visible,
.rkt-Chip.rkt-Chip--on-dark.MuiChip-root:not(.Mui-disabled):focus-visible,
.rkt-Chip.rkt-Chip--on-dark.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled).cdk-focused {
  background-color: settings.$rkt-chip-on-dark-focus-background-color;
  box-shadow: settings.$rkt-chip-on-dark-focus-box-shadow;
}

.rkt-Chip.rkt-Chip--on-dark.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) .mdc-evolution-chip__text-label,
.rkt-Chip.rkt-Chip--on-dark.MuiChip-root:not(.Mui-disabled) .rkt-Chip__label {
  color: settings.$rkt-chip-on-dark-text-color;
}

.rkt-Chip.rkt-Chip--on-dark.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled) .mat-icon,
.rkt-Chip.rkt-Chip--on-dark.MuiChip-root:not(.Mui-disabled) .rkt-Chip__icon {
  fill: settings.$rkt-chip-on-dark-icon-color;
}

// On Dark - Selected Classes
.rkt-Chip.rkt-Chip--on-dark.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled).mat-mdc-chip-selected,
.rkt-Chip.rkt-Chip--on-dark.MuiChip-root:not(.Mui-disabled).rkt-Chip--is-selected {
  background-color: settings.$rkt-chip-on-dark-selected-background-color;
}

.rkt-Chip.rkt-Chip--on-dark.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled).mat-mdc-chip-selected
  .mdc-evolution-chip__text-label,
.rkt-Chip.rkt-Chip--on-dark.MuiChip-root:not(.Mui-disabled).rkt-Chip--is-selected .rkt-Chip__label {
  color: settings.$rkt-chip-on-dark-selected-text-color;
}

.rkt-Chip.rkt-Chip--on-dark.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled).mat-mdc-chip-selected .mat-icon,
.rkt-Chip.rkt-Chip--on-dark.MuiChip-root:not(.Mui-disabled).rkt-Chip--is-selected .rkt-Chip__icon {
  fill: settings.$rkt-chip-on-dark-selected-icon-color;
}

// Angular selectable checkmark is an SVG and has to be styled by color
.rkt-Chip.rkt-Chip--on-dark.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled).mat-mdc-chip-selected
  .mdc-evolution-chip__checkmark {
  color: settings.$rkt-chip-on-dark-selected-icon-color;
}

.rkt-Chip.rkt-Chip--on-dark.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled).mat-mdc-chip-selected:hover,
.rkt-Chip.rkt-Chip--on-dark.MuiChip-root:not(.Mui-disabled).rkt-Chip--is-selected:hover {
  background-color: settings.$rkt-chip-on-dark-selected-hover-background-color;

  .mdc-evolution-chip__text-label,
  .mdc-evolution-chip__checkmark,
  .mdc-evolution-chip__icon,
  .rkt-Chip__label,
  .rkt-Chip__icon,
  .rkt-Chip__delete-button {
    color: settings.$rkt-chip-on-dark-selected-hover-color;
  }

  .mat-icon,
  .rkt-Chip__icon {
    fill: settings.$rkt-chip-on-dark-selected-hover-color;
  }
}

.rkt-Chip.rkt-Chip--on-dark.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled).mat-mdc-chip-selected:focus,
.rkt-Chip.rkt-Chip--on-dark.MuiChip-root:not(.Mui-disabled).rkt-Chip--is-selected:focus,
.rkt-Chip.rkt-Chip--on-dark.mat-mdc-standard-chip:not(.mdc-evolution-chip--disabled).mat-mdc-chip-selected.cdk-focused {
  background-color: settings.$rkt-chip-on-dark-selected-focus-background-color;
  box-shadow: settings.$rkt-chip-on-dark-selected-focus-box-shadow;
  border-color: settings.$rkt-chip-on-dark-selected-focus-border-color;

  .mdc-evolution-chip__text-label,
  .mdc-evolution-chip__checkmark,
  .mdc-evolution-chip__icon,
  .rkt-Chip__label,
  .rkt-Chip__icon,
  .rkt-Chip__delete-button {
    color: settings.$rkt-chip-on-dark-selected-focus-color;
  }

  .mat-icon,
  .rkt-Chip__icon {
    fill: settings.$rkt-chip-on-dark-selected-focus-color;
  }
}

// On Dark - Disabled Classes
.rkt-Chip.rkt-Chip--on-dark.mat-mdc-standard-chip.mdc-evolution-chip--disabled,
.rkt-Chip.rkt-Chip--on-dark.mat-mdc-standard-chip.rkt-Chip--is-disabled,
.rkt-Chip.rkt-Chip--on-dark.rkt-Chip--is-disabled {
  background-color: settings.$rkt-chip-on-dark-disabled-background-color;
  border: settings.$rkt-chip-on-dark-disabled-border;
}

// on React we have to unset MUI opacity set to 0.38
.rkt-Chip.rkt-Chip--on-dark.rkt-Chip--is-disabled {
  opacity: unset;
}

.rkt-Chip.rkt-Chip--on-dark.mat-mdc-standard-chip.mdc-evolution-chip--disabled .mdc-evolution-chip__text-label,
.rkt-Chip.rkt-Chip--on-dark.mat-mdc-standard-chip.rkt-Chip--is-disabled .mdc-evolution-chip__text-label,
.rkt-Chip.rkt-Chip--on-dark.rkt-Chip--is-disabled .rkt-Chip__label {
  color: settings.$rkt-chip-on-dark-disabled-text-color;
}

.rkt-Chip.rkt-Chip--on-dark.mat-mdc-standard-chip.mdc-evolution-chip--disabled .mat-icon,
.rkt-Chip.rkt-Chip--on-dark.mat-mdc-standard-chip.rkt-Chip--is-disabled .mat-icon,
.rkt-Chip.rkt-Chip--on-dark.rkt-Chip--is-disabled .rkt-Chip__icon {
  fill: settings.$rkt-chip-on-dark-disabled-icon-color;
}

// On Dark Styles END